import {Injectable} from "@angular/core";
import {ClientID} from "app/model/client/client";
import {Observable} from "rxjs";
import {ServerApi} from "#infrastructure/api/server-api";
import {ContactHistoryFactory} from "app/model/contact-history/contact-history.factory";
import {ContactHistoryContent} from "app/model/contact-history/contact-history";
import {OpportunityID} from "app/model/opportunity/opportunity";

@Injectable({providedIn: "root"})
export class ContactHistoryFactoryImpl extends ContactHistoryFactory {
  constructor(
    public readonly serverApi: ServerApi,
  ) {
    super();
  }

  create(clientID: ClientID, content: ContactHistoryContent, opportunityID?: OpportunityID): Observable<void> {
    return this.serverApi.contactHistoryApi.create(
      clientID.value,
      {content: content.value, opportunityID: opportunityID?.value}
    );
  }
}
