import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';

export type CreateClientRelationshipRequest = CreateClientRelationshipRequestData;

export interface CreateClientRelationshipRequestData {
  relevantClientID: string,
}

export type GetClientRelationshipsResponse = GetClientRelationshipsData[];

export interface GetClientRelationshipsData {
  id: string,
  relevantClientID: string,
  relevantClientName: string,
  relevantClientNumber: number,
  relevantClientTypeName: string,
  category: 'CORPORATION' | 'INDIVIDUAL',
}

export type CreateClientRelationshipNoteRequest = CreateClientRelationshipNoteRequestData;

export interface CreateClientRelationshipNoteRequestData {
  content: string,
}

export type GetClientRelationshipNotesResponse = GetClientRelationshipNoteData[];

export interface GetClientRelationshipNoteData {
  id: string,
  authorName: string,
  content: string,
  editedDateTime: string,
  isEdited: boolean,
}

export type UpdateClientRelationshipNoteRequest = UpdateClientRelationshipNoteRequestData;

export interface UpdateClientRelationshipNoteRequestData {
  content: string,
}

export class ClientRelationshipApi {
  constructor(private readonly http: HttpClient) {}

  create(clientID: string, request: CreateClientRelationshipRequest): Observable<void> {
    return this.http.post<void>(`/api/clients/${clientID}/client-relationship`, request);
  }

  list(clientID: string): Observable<GetClientRelationshipsResponse> {
    return this.http.get<GetClientRelationshipsResponse>(`/api/clients/${clientID}/client-relationships`);
  }

  delete(clientID: string, relationshipID: string): Observable<void>{
    return this.http.delete<void>(`/api/clients/${clientID}/client-relationship/${relationshipID}`);
  }

  createNote(clientID: string, relationshipID: string, request: CreateClientRelationshipNoteRequest): Observable<void> {
    return this.http.post<void>(`/api/clients/${clientID}/client-relationships/${relationshipID}/note`, request);
  }

  listNotes(clientID: string, relationshipID: string): Observable<GetClientRelationshipNotesResponse> {
    return this.http.get<GetClientRelationshipNotesResponse>(`/api/clients/${clientID}/client-relationships/${relationshipID}/notes`);
  }

  updateNote(clientID: string, relationshipID: string, noteID: string, request: UpdateClientRelationshipNoteRequest): Observable<void>{
    return this.http.put<void>(`/api/clients/${clientID}/client-relationships/${relationshipID}/note/${noteID}`, request);
  }

  deleteNote(clientID: string, relationshipID: string, noteID: string): Observable<void>{
    return this.http.delete<void>(`/api/clients/${clientID}/client-relationships/${relationshipID}/note/${noteID}`);
  }
}
