import { MessageDialogService } from "#application/services/message-dialog.service";
import { ListOpportunityOfReportInput, OpportunityListOfOpportunityReport, OpportunityListReportOpportunityQueryService } from "#application/services/opportunity-report-opportunity-query.service";
import { OpportunityTypeID, OpportunityTypeName } from "app/model/opportunity-type/opportunity-type";
import { OpportunityID, OpportunityName, OpportunityNumber } from "app/model/opportunity/opportunity";
import { PhaseID, PhaseName } from "app/model/sales-phase/sales-phase";
import { DisplayName, UserID } from "app/model/user/user";
import { ServerApi } from "#infrastructure/api/server-api";
import { ListOpportunityOfReportRequest } from "#infrastructure/api/server-opportunity-report-opportunity-api";
import { Target } from "#utils/messages";
import { Injectable, inject } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class OpportunityListReportOpportunityQueryServiceImpl implements OpportunityListReportOpportunityQueryService {
  private readonly serverApi = inject(ServerApi);
  private readonly messageDialogService = inject(MessageDialogService);

  list(input: ListOpportunityOfReportInput): Observable<OpportunityListOfOpportunityReport> {
    return this.serverApi.opportunityListReportOpportunityApi
    .list(convertInput(input))
    .pipe(
      map(r => ({
        totalCount: r.totalCount,
        results: r.results.map(result => ({
          opportunity: {
            id: new OpportunityID(result.opportunity.id),
            name: new OpportunityName(result.opportunity.name),
            number: new OpportunityNumber(result.opportunity.number),
            status: result.opportunity.status,
          },
          opportunityType: {
            id: new OpportunityTypeID(result.opportunityType.id),
            name: new OpportunityTypeName(result.opportunityType.name),
          },
          currentPhase: {
            id: new PhaseID(result.currentPhase.id),
            name: new PhaseName(result.currentPhase.name),
          },
          assignee: {
            id: new UserID(result.assignee.id),
            name: new DisplayName(result.assignee.name),
          },
        })),
      })),
      catchError(err => {
        this.messageDialogService.notice({
          errorTarget: `${Target.OPPORTUNITY}一覧の取得`
        });
        return throwError(err);
      })
    );
  }

  listBySalesRecord(input: ListOpportunityOfReportInput): Observable<OpportunityListOfOpportunityReport> {
    return this.serverApi.opportunityListReportOpportunityApi
    .listBySalesRecord(convertInput(input))
    .pipe(
      map(r => ({
        totalCount: r.totalCount,
        results: r.results.map(result => ({
          opportunity: {
            id: new OpportunityID(result.opportunity.id),
            name: new OpportunityName(result.opportunity.name),
            number: new OpportunityNumber(result.opportunity.number),
            status: result.opportunity.status,
          },
          opportunityType: {
            id: new OpportunityTypeID(result.opportunityType.id),
            name: new OpportunityTypeName(result.opportunityType.name),
          },
          currentPhase: {
            id: new PhaseID(result.currentPhase.id),
            name: new PhaseName(result.currentPhase.name),
          },
          assignee: {
            id: new UserID(result.assignee.id),
            name: new DisplayName(result.assignee.name),
          },
        })),
      })),
      catchError(err => {
        this.messageDialogService.notice({
          errorTarget: `${Target.OPPORTUNITY}一覧の取得`
        });
        return throwError(err);
      })
    );
  }
}

const convertInput = (input: ListOpportunityOfReportInput): ListOpportunityOfReportRequest => {
  let duration: ListOpportunityOfReportRequest['duration'];
  const durationType = input.duration.type;
  switch (durationType) {
    case 'MONTHLY':
      duration = {
        type: durationType,
        startYearMonth: input.duration.startYearMonth.toFormat('yyyy-MM'),
        months: input.duration.months,
      };
      break;
    case 'DAILY':
      duration = {
        type: durationType,
        startDate: input.duration.startDate.toFormat('yyyy-MM-dd HH:mm:ss'),
        days: input.duration.days,
      };
      break;
    default:
      const _: never = durationType;
      throw new Error(`Unexpected duration type: _`);
  }

  let assignee: ListOpportunityOfReportRequest['assignee'];
  const assigneeType = input.assignee.type;
  switch (assigneeType) {
    case 'USER':
      assignee = {
        type: assigneeType,
        userID: input.assignee.userID.value,
      };
      break;
    case 'USER_GROUP':
      assignee = {
        type: assigneeType,
        userGroupID: input.assignee.userGroupID.value,
      };
      break;
    case 'ALL_USERS':
      assignee = {
        type: assigneeType,
      };
      break;
    default:
      const _: never = assigneeType;
      throw new Error(`Unexpected assignee type: _`);
  }
  return {
    pagination: {
      perPage: input.pagination.perPage,
      page: input.pagination.page,
    },
    duration,
    assignee,
    opportunityTypeIDs: input.opportunityTypeIDs.map(id => id.value),
    statuses: input.statuses,
  };
}
