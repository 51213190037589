import { SearchSelectableUserListInput, UserDetail, UserList, UserListForSelectableSearch, UserQueryService } from '#application/services/user-query.service';
import { ServerApi } from '#infrastructure/api/server-api';
import { OrganizationID } from 'app/model/organization/organization';
import { DisplayName, LoginID, MailAddress, UserID } from 'app/model/user/user';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { GetUserListResponse } from '#infrastructure/api/user-api';
import { Injectable } from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import { DateTime } from 'luxon';

const convertUserList = (res: GetUserListResponse): UserList => {
  return res.map(item => ({
    id: new UserID(item.id),
    loginID: new LoginID(item.userName),
    name: new DisplayName(item.name),
    mailAddress: new MailAddress(item.mailAddress),
    isActive: item.isActive,
    lastLoginDatetime: item.lastLoginDatetime === undefined
      ? undefined
      : DateTime.fromFormat(item.lastLoginDatetime, 'yyyy-MM-dd HH:mm:ss', { zone: 'UTC' }),
    accessLevels: item.accessLevels,
  }));
}

@Injectable({
  providedIn: 'root',
})
export class UserQueryServiceImpl extends UserQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  getUserDetail(userID: string): Observable<UserDetail> {
    return this.serverApi.user.get(userID).pipe(
      map(user => ({
        organizationID: new OrganizationID(user.organizationID.value),
        id: new UserID(user.id.value),
        loginID: new LoginID(user.userName.value),
        name: new DisplayName(user.name.value),
        mailAddress: new MailAddress(user.mailAddress.value),
        accessLevels: user.accessLevels
      }))
    );
  }

  getUsers(includeInactive: boolean = false): Observable<UserList> {
    return this.serverApi.user.list(includeInactive).pipe(
      map(users => convertUserList(users))
    );
  }

  getUsersWithInactive(): Observable<UserList> {
    return this.serverApi.user.list(true).pipe(
      map(users => convertUserList(users))
    );
  }

  searchSelectable(input: SearchSelectableUserListInput): Observable<Result<
    UserListForSelectableSearch,
    typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.user.searchSelectable(input)
    .pipe(
      map(res => Success({
        totalCount: res.totalCount,
        list: res.results.map(item => ({
          id: new UserID(item.id),
          name: new DisplayName(item.name),
        }))
      })),
      catchError(() => of(Failure(GeneralFailure.Unexpected))),
    )
  }
}
