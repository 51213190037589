import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {InsightFeedbackOptionID} from "app/model/insight/insight-feedback";

export class InsightFeedbackOption {
  constructor(
    public readonly id: InsightFeedbackOptionID,
    public readonly content: string,
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export abstract class InsightFeedbackOptionQueryService {

  abstract get(): Observable<InsightFeedbackOption[]>;
}
