import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { SearchOpportunityContextLabelListRequest, SearchOpportunityContextLabelListResponse } from "./server-opportunity-context-label-api";

export class CommonOpportunityContextLabelApi {
  constructor(private readonly http: HttpClient) {}

  search(request: SearchOpportunityContextLabelListRequest): Observable<SearchOpportunityContextLabelListResponse> {
    return this.http.post<SearchOpportunityContextLabelListResponse>(`/api/common-opportunity-context-label-list/search`, request);
  }
}
