import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

export type ApiFormLayoutCategory =
  | 'corporation_client'
  | 'individual_client'
  | 'opportunity'
  | 'product'
  | 'proposal'
  | 'opportunity_group';

export type ApiFormLayoutWidth = 'HALF' | 'FULL';

export type ApiBuiltinFieldId =
  | 'CORPORATION_NAME'
  | 'CORPORATION_NAME_KANA'
  | 'CORPORATE_NUMBER'
  | 'CORPORATE_PHONE_NUMBER'
  | 'CORPORATE_ADDRESS'
  | 'ESTABLISHED'
  | 'NUMBER_OF_EMPLOYEES'
  | 'INITIAL_CAPITAL'
  | 'FISCAL_YEAR_END'
  | 'WEBSITE'
  | 'LAST_NAME'
  | 'FIRST_NAME'
  | 'LAST_NAME_KANA'
  | 'FIRST_NAME_KANA'
  | 'INDIVIDUAL_PHONE_NUMBER'
  | 'INDIVIDUAL_ADDRESS'
  | 'INDIVIDUAL_SNS1'
  | 'INDIVIDUAL_SNS2'
  | 'OPPORTUNITY_TITLE'
  | 'EXPECTED_AMOUNT'
  | 'ASSIGNEE'
  | 'EXPECTED_CLOSE_DATE'
  | 'OPPORTUNITY_ACCRUAL_DATE'
  | 'PRODUCT_NAME'
  | 'PRODUCT_PRICE'
  | 'PRODUCT_QUANTITY_UNIT'
  | 'PROPOSAL_TITLE'
  | 'OPPORTUNITY_GROUP_TITLE'
  ;

export interface SectionTitleItemData {
  type: 'SECTION_TITLE',
  width: 'FULL',
  title: string
}

export interface BuiltinFieldItemData {
  type: 'BUILTIN_FIELD',
  width: ApiFormLayoutWidth,
  builtinFieldID: ApiBuiltinFieldId
}

export interface CustomFieldItemData {
  type: 'CUSTOM_FIELD',
  width: ApiFormLayoutWidth,
  customFieldID: string
}

export type GetFormLayoutResponse = GetFormLayoutResponseData;

interface GetFormLayoutResponseData {
  id: string,
  category: ApiFormLayoutCategory,
  name: string,
  items: (
    | SectionTitleItemData
    | CustomFieldItemData
    | BuiltinFieldItemData
  )[]
}

export type ListFormLayoutResponse = ListFormLayoutResponseData[];

interface ListFormLayoutResponseData {
  id: string,
  category: ApiFormLayoutCategory,
  name: string,
  items: (
    | SectionTitleItemData
    | BuiltinFieldItemData
    | CustomFieldItemData
  )[]
}

export type CreateFormLayoutRequest = CreateFormLayoutRequestData;

interface CreateFormLayoutRequestData {
  name: string,
  items: (
    | SectionTitleItemData
    | CustomFieldItemData
    | BuiltinFieldItemData
  )[]
}

export type UpdateFormLayoutRequest = UpdateFormLayoutRequestData;

interface UpdateFormLayoutRequestData {
  name: string,
  items: (
    | SectionTitleItemData
    | CustomFieldItemData
    | BuiltinFieldItemData
  )[]
}

export class FormLayoutApi {
  constructor(private readonly http: HttpClient) {
  }

  get(category: ApiFormLayoutCategory, id: string): Observable<GetFormLayoutResponse> {
    return this.http.get<GetFormLayoutResponse>(`/api/form-layouts/${category}/${id}`);
  }

  list(category: ApiFormLayoutCategory): Observable<ListFormLayoutResponse> {
    return this.http.get<ListFormLayoutResponse>(`/api/form-layouts/${category}`);
  }

  create(category: ApiFormLayoutCategory, request: CreateFormLayoutRequest): Observable<void> {
    return this.http.post<void>(`/api/form-layouts/${category}`, request);
  }

  update(
    category: ApiFormLayoutCategory,
    id: string,
    request: UpdateFormLayoutRequest
  ): Observable<void> {
    return this.http.put<void>(`/api/form-layouts/${category}/${id}`, request);
  }

  delete(category: ApiFormLayoutCategory, id: string): Observable<void> {
    return this.http.delete<void>(`/api/form-layouts/${category}/${id}`);
  }
}
