import { HintDetail, HintSearchRange, SearchHintInputs, SearchHintListResult } from "#application/services/base-hint-query.service";
import { HintQueryService } from "#application/services/hint-query.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { HintContent, HintID, HintTitle } from "app/model/hint/hint";
import { ServerApi } from "#infrastructure/api/server-api";
import { SearchHintRequest } from "#infrastructure/api/server-hint-api";
import { Target } from "#utils/messages";
import { Injectable, inject } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class HintQueryServiceImpl implements HintQueryService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _messageDialogService = inject(MessageDialogService);

  search(inputs: SearchHintInputs): Observable<SearchHintListResult> {
    const request: SearchHintRequest = {
      pagination: {
        perPage: inputs.perPage,
        page: inputs.page,
      },
      searchString: inputs.filter.searchString.value,
      searchRange: filerCommonSearchRange(inputs.searchRange),
    };
    return this._serverApi.hintApi.search(request)
    .pipe(
      map(v => ({
        totalCount: v.totalCount,
        results: v.results.map(r => ({
          id: new HintID(r.id),
          title: new HintTitle(r.title),
          good: r.good,
          bad: r.bad
        }))
      })),
      catchError(e => {
        this._messageDialogService.notice({ errorTarget: `${Target.HINT}の検索・取得` });
        return throwError(e);
      })
    );
  }

  get(id: HintID): Observable<HintDetail> {
    return this._serverApi.hintApi.get(id.value)
    .pipe(
      map(v => ({
        id: new HintID(v.id),
        title: new HintTitle(v.title),
        content: new HintContent(v.content),
      })),
      catchError(e => {
        this._messageDialogService.notice({ errorTarget: `${Target.HINT}の取得` });
        return throwError(e);
      })
    );
  }
}

export const filerCommonSearchRange = (v: HintSearchRange): SearchHintRequest['searchRange'] => {
  switch (v) {
    case HintSearchRange.ORGANIZATION:
      return 'ORGANIZATION';
    case HintSearchRange.COMMON_AND_ORGANIZATION:
      return 'COMMON_AND_ORGANIZATION';
    case HintSearchRange.COMMON:
    default:
      const _commonOrNever: typeof HintSearchRange.COMMON | never = v;
      throw Error('検索対象範囲外の値が指定されています。')
  }
}
