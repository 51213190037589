import { HintContent, HintID, HintTitle } from "app/model/hint/hint";
import { FilterPredicateType } from "app/model/search-filter/search-filter";
import { Observable } from "rxjs";

export type SearchHintInputs = {
  perPage: number,
  page: number,
  filter: {
    searchString: {
      type: typeof FilterPredicateType.contains,
      value: string,
    },
  },
  searchRange: HintSearchRange,
};

export type SearchHintListResult = {
  totalCount: number,
  results: {
    id: HintID,
    title: HintTitle,
    good: number,
    bad: number,
  }[]
};

export type HintDetail = {
  id: HintID,
  title: HintTitle,
  content: HintContent,
}

export const HintSearchRange = {
  ORGANIZATION: 'ORGANIZATION',
  COMMON: 'COMMON',
  COMMON_AND_ORGANIZATION: 'COMMON_AND_ORGANIZATION',
} as const;
export type HintSearchRange = typeof HintSearchRange[keyof typeof HintSearchRange];

export abstract class BaseHintQueryService {
  abstract search(inputs: SearchHintInputs): Observable<SearchHintListResult>;

  abstract get(id: HintID): Observable<HintDetail>;
}
