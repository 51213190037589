import {ServerApi} from '#infrastructure/api/server-api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {
  ActionTypeDetail,
  ActionTypeList,
  ActionTypeQueryService
} from "#application/services/action-type-query.service";
import {ActionTypeDescription, ActionTypeID, ActionTypeName} from "app/model/action/action-type";

@Injectable({
  providedIn: 'root',
})
export class ActionTypeQueryServiceImpl extends ActionTypeQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  list(): Observable<ActionTypeList> {
    return this.serverApi.actionTypeApi.list().pipe(
      map(list => {
        return list.map(item => ({
          id: new ActionTypeID(item.id),
          name: new ActionTypeName(item.name),
          description: new ActionTypeDescription(item.description),
        }))
      })
    );
  }

  get(id: ActionTypeID): Observable<ActionTypeDetail> {
    return this.serverApi.actionTypeApi.get(id.value).pipe(
      map(actionType => ({
        id: new ActionTypeID(actionType.id),
        name: new ActionTypeName(actionType.name),
        description: new ActionTypeDescription(actionType.description),
      }))
    );
  }
}
