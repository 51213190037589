import { OpportunityContextID, OpportunityContextName } from 'app/model/opportunity-context/opportunity-context';
import { OpportunityTypeDescription, OpportunityTypeName, OpportunityTypeID } from 'app/model/opportunity-type/opportunity-type';
import { PhaseID, PhaseName } from 'app/model/sales-phase/sales-phase';
import {Observable} from 'rxjs';

export type OpportunityTypeOpportunityContextSettingList = {
  isCompleted: boolean,
  opportunityType: {
    id: OpportunityTypeID,
    name: OpportunityTypeName,
    description: OpportunityTypeDescription,
  }
}[];

export type PhaseOpportunityContextSettingList = {
  index: number,
  phase: {
    id: PhaseID,
    name: PhaseName,
  },
  opportunityContexts: {
    id: OpportunityContextID,
    name: OpportunityContextName,
  }[]
}[];

export abstract class OpportunityTypeOpportunityContextSettingQueryService {
  abstract list(): Observable<OpportunityTypeOpportunityContextSettingList>;

  abstract listByPhase(typeID: OpportunityTypeID): Observable<PhaseOpportunityContextSettingList>;
}
