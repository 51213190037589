import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type ListRoleResponse = {
  id : string,
  name : string,
  depth: number,
  parentRole? : {
    id : string,
    name : string,
  },
  membersCount : number,
  updatedAt : string,
}[];

export type GetRoleResponse = {
  id : string,
  name : string,
  parentRole?: {
    id : string,
    name : string,
  },
  ownedClientsCount: number,
  childRoles: {
    id : string,
    name : string,
  }[],
  members: {
    id : string,
    name : string,
  }[],
};

export type CreateRoleRequest = {
  name: string,
  parentRoleID?: string,
};

export type UpdateRoleRequest = {
  id: string
  name: string,
  parentRoleID?: string,
};

export class RoleApi {
  private readonly _url = '/api/roles';
  constructor(private readonly http: HttpClient) {}

  list(): Observable<ListRoleResponse> {
    return this.http.get<ListRoleResponse>(this._url);
  }

  get(id: string): Observable<GetRoleResponse> {
    return this.http.get<GetRoleResponse>(`${this._url}/${id}`);
  }

  create(request: CreateRoleRequest): Observable<void> {
    return this.http.post<void>(this._url, request);
  }

  update(request: UpdateRoleRequest): Observable<void> {
    return this.http.put<void>(`${this._url}/${request.id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this._url}/${id}`);
  }
}
