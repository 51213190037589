import {ProposalWorkflowQueryService} from "#application/services/proposal-workflow-query.service";
import {Observable, throwError} from "rxjs";
import {ProposalWorkflow} from "app/model/proposal-workflow/proposal-workflow";
import {Injectable} from "@angular/core";
import {catchError, map} from "rxjs/operators";
import {ServerApi} from "#infrastructure/api/server-api";
import {MessageDialogService} from "#application/services/message-dialog.service";
import {
  ProposalStatusDescription,
  ProposalStatusID,
  ProposalStatusName,
  ProposalStatusType
} from "app/model/proposal-status/proposal-status";

@Injectable({providedIn: 'root'})
export class ProposalWorkflowQueryServiceImpl extends ProposalWorkflowQueryService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly messageDialogService: MessageDialogService,
  ) {
    super();
  }

  get(): Observable<ProposalWorkflow> {
    return this.serverApi.proposalWorkflowApi.get()
      .pipe(
        map(res => res.statuses.map(v => ({
          id: new ProposalStatusID(v.id),
          type: this._convertProposalStatusType(v.type),
          name: new ProposalStatusName(v.name),
          description: new ProposalStatusDescription(v.description)
        }))),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '提案ワークフローの取得'
          });
          return throwError(err);
        })
      );
  }

  private _convertProposalStatusType(
    type: 'DRAFT' | 'READY' | 'PROPOSED' | 'ADOPTED' | 'DECLINED' | 'REPROPOSAL_REQUESTED'
  ) {
    switch (type) {
      case 'DRAFT':
        return ProposalStatusType.DRAFT;
      case 'READY':
        return ProposalStatusType.READY;
      case 'PROPOSED':
        return ProposalStatusType.PROPOSED;
      case 'ADOPTED':
        return ProposalStatusType.ADOPTED;
      case 'DECLINED':
        return ProposalStatusType.DECLINED;
      case 'REPROPOSAL_REQUESTED':
        return ProposalStatusType.REPROPOSAL_REQUESTED;
      default:
        const unexpected: never = type;
        throw Error(`${unexpected} is unexpected value`);
    }
  }

}
