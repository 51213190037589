import {Component, Inject, Injectable} from '@angular/core';

import {ConfirmData, ConfirmResult, ConfirmService,} from '#application/services/confirm.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef,} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {map} from "rxjs/operators";

/**
 * 確認ダイアログサービス
 */
@Injectable()
export class ConfirmServiceImpl implements ConfirmService {
  constructor(private readonly dialog: MatDialog) {
  }

  confirm(data: ConfirmData): Observable<ConfirmResult> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '480px',
      data: data,
    });
    return dialogRef.afterClosed().pipe(
      map(result => {
        if (result && result === Ok) {
          return ConfirmResult.resultOk();
        } else {
          return ConfirmResult.resultCancel();
        }
      })
    );
  }
}

const Ok = 'Ok';
const Cancel = 'Cancel';

@Component({
  selector: 'sn-confirm-dialog',
  template: `
    <h2 *ngIf="hasTitle()" mat-dialog-title>{{ title }}</h2>
    <mat-dialog-content>
      <ng-container *ngFor="let message of messages">
        <div>{{ message }}</div>
      </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-raised-button [mat-dialog-close]="Cancel">
        キャンセル
      </button>
      <button mat-button mat-raised-button color="primary" cdkFocusInitial [mat-dialog-close]="Ok">
        OK
      </button>
    </mat-dialog-actions>
  `,
})
export class ConfirmDialogComponent {
  Ok = Ok;
  Cancel = Cancel;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmData
  ) {
  }

  get title(): string {
    return this.data.title ? this.data.title : '';
  }

  get messages(): string[] {
    return this.data.message.split('\n');
  }

  hasTitle(): boolean {
    return this.data.title !== undefined;
  }
}
