import { FormLayoutId, FormLayoutName } from "app/model/form-layout/form-layout"
import {
  ProductDetailType,
  ProposalType,
  ProposalTypeDescription,
  ProposalTypeID,
  ProposalTypeName
} from "app/model/proposal-type/proposal-type"
import { Observable } from "rxjs"

export type ProposalTypeList = Readonly<{
  id: ProposalTypeID,
  name: ProposalTypeName,
  description: ProposalTypeDescription,
  productDetailType: ProductDetailType,
  formLayoutID?: FormLayoutId,
  formLayoutName?: FormLayoutName,
}>[];

export abstract class ProposalTypeQueryService {
  abstract list(): Observable<ProposalTypeList>;
  abstract get(id: ProposalTypeID): Observable<ProposalType>;
}
