import { CsvExportHistories, CsvExportHistoriesQueryService } from '#application/services/csv-export-histories-query.service';
import { CsvExportCategory } from '#application/services/csv-export.service';
import { DisplayName, UserID } from 'app/model/user/user';
import { ServerApi } from '#infrastructure/api/server-api';
import { CsvExportHistoriesResponse } from '#infrastructure/api/server-csv-export-histories-api';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CsvExportHistoriesQueryServiceImpl extends CsvExportHistoriesQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  getCsvExportHistories(
    category: CsvExportCategory,
    perPage: number,
    page: number
  ): Observable<CsvExportHistories> {
    return this.serverApi.csvExportHistoriesApi.list(category, perPage, page)
      .pipe(map((response) => convertToCsvExportHistories(response)));
  }
};

const convertToCsvExportHistories = (
  response: CsvExportHistoriesResponse
): CsvExportHistories => {
  return {
    totalCount: response.totalCount,
    results: response.results.map((v) => {
      return {
        id: v.id,
        status: v.status,
        category: v.category,
        typeName: v.typeName,
        userId: new UserID(v.userId),
        userName: new DisplayName(v.userName),
        size: v.size,
        exportStartAt: DateTime.fromISO(v.exportStartAt),
        exportEndAt: v.exportEndAt ? DateTime.fromISO(v.exportEndAt) : null,
      };
    })
  };
}