import {ContactFactory, CreateContactEntity} from "app/model/contact/contact.factory";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ServerApi} from "#infrastructure/api/server-api";
import {ContactID} from "../../model/contact/contact";
import { map } from 'rxjs/operators';

@Injectable({providedIn: "root"})
export class ContactFactoryImpl extends ContactFactory {
  constructor(
    public readonly serverApi: ServerApi,
  ) {
    super();
  }

  create(entity: CreateContactEntity): Observable<ContactID> {
    return this.serverApi.contactApi.create(
      {
        clientID: entity.clientID?.value,
        department: entity.departmentName.value,
        role: entity.roleName.value,
        displayName: entity.displayName.value,
        firstName: entity.firstName.value,
        firstNameKana: entity.firstNameKana.value,
        lastName: entity.lastName.value,
        lastNameKana: entity.lastNameKana.value,
        sex: entity.sex.value,
        emailAddresses: entity.emailInfoList.list.map(emailInfo => ({
          label: emailInfo.label.value,
          emailAddress: emailInfo.email.value
        })),
        phoneNumbers: entity.phoneNumberInfoList.list.map(phoneNumberInfo => ({
          label: phoneNumberInfo.label.value,
          phoneNumber: phoneNumberInfo.phoneNumber.value
        })),
        note: entity.note.value,
      }
    ).pipe(map(res => new ContactID(res.id)));
  }
}
