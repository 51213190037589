import { NurturingQueryService } from '#application/services/nurturing-query.service';
import { ServerApiError, ServerApiErrorCode } from '#infrastructure/api/error';
import { ServerApi } from '#infrastructure/api/server-api';
import { Injectable, inject } from '@angular/core';
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Failure, Result, Success } from "app/lib/result/result";
import {ClientID, ClientName, ClientNumber} from 'app/model/client/client';
import {ContactDisplayName, ContactID, ContactRoleName, DepartmentName} from 'app/model/contact/contact';
import { CustomItems } from 'app/model/custom-field/custom-field';
import { NurturingTypeDescription, NurturingTypeID, NurturingTypeName } from 'app/model/nurturing-type/nurturing-type';
import {
  Nurturing,
  NurturingID,
  NurturingMinutesDuration,
  NurturingNote,
  NurturingStartDateTime,
  NurturingTitle
} from 'app/model/nurturing/nurturing';
import { DisplayName, UserID } from 'app/model/user/user';
import { DateTime } from 'luxon';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NurturingQueryServiceImpl implements NurturingQueryService {
  private readonly _serverApi = inject(ServerApi);

  get(id: NurturingID): Observable<Result<
    Nurturing,
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >>{
    return this._serverApi.nurturingApi.get(id.value).pipe(
      map(res => Success({
        id: new NurturingID(res.id),
        owner: {
          id: new UserID(res.owner.id),
          name: new DisplayName(res.owner.name),
        },
        client: {
          id: new ClientID(res.client.id),
          name: new ClientName(res.client.name),
          number: new ClientNumber(res.client.number),
        },
        title: new NurturingTitle(res.title),
        duration: {
          startDateTime: new NurturingStartDateTime(DateTime.fromFormat(res.duration.startDateTime, 'yyyy-MM-dd HH:mm:ss').toJSDate()),
          minutes: new NurturingMinutesDuration(res.duration.minutes),
        },
        companions: res.companions.map(c => ({
          userID: new UserID(c.userID),
          userDisplayName: c.userDisplayName,
        })),
        attendees: res.attendees.map(a => ({
          contactID: new ContactID(a.contactID),
          contactDisplayName: new ContactDisplayName(a.contactDisplayName),
          departmentName: new DepartmentName(a.departmentName),
          roleName: new ContactRoleName(a.roleName),
        })),
        note: new NurturingNote(res.note),
        nurturingType: {
          id: new NurturingTypeID(res.nurturingType.id),
          name: new NurturingTypeName(res.nurturingType.name),
          description: new NurturingTypeDescription(res.nurturingType.description),
        },
        beforeHearing: CustomItems.convertFromRawKVObj(res.beforeHearing),
        afterHearing: CustomItems.convertFromRawKVObj(res.afterHearing),
      })),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.NotFound:
            return of(Failure(GeneralFailure.NotFound));
          default:
            return of(Failure(GeneralFailure.Unexpected));
        }
      }),
    );
  }
}
