export class ProposalStatusID {
  constructor(public readonly value: string) {}

  equals(another: ProposalStatusID): boolean {
    return this.value === another.value;
  }
}

export const ProposalStatusType = {
  DRAFT: 'DRAFT',
  READY: 'READY',
  PROPOSED: 'PROPOSED',
  ADOPTED: 'ADOPTED',
  DECLINED: 'DECLINED',
  REPROPOSAL_REQUESTED: 'REPROPOSAL_REQUESTED',
} as const;
export type ProposalStatusType = typeof ProposalStatusType[keyof typeof ProposalStatusType];

export class ProposalStatusName {
  static readonly MAX_LENGTH = 30;
  constructor(public readonly value: string) {}
}

export class ProposalStatusDescription {
  static readonly MAX_LENGTH = 100;
  constructor(public readonly value: string) {}
}

export type ProposalStatus = Readonly<{
  id: ProposalStatusID,
  type: ProposalStatusType,
  name: ProposalStatusName,
  description: ProposalStatusDescription,
}>

