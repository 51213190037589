import { HttpClient } from "@angular/common/http";
import { DateTime } from "luxon";
import { Observable } from "rxjs";

export type ListUnreportedActivityResponse = {
  id: string,
  type: 'ACTION' | 'NURTURING',
  name: string,
  duration: {
    startDateTime: string,
    minutes: number,
  },
}[];

export type UnreportedActivityRequest = {
  now: string,
}

export type CountUnreportedActivityResponse = {
  count: number,
};

export class UnreportedActivityApi {
  constructor(private readonly http: HttpClient) {}

  list(
    request: UnreportedActivityRequest = { now: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss') }
  ): Observable<ListUnreportedActivityResponse> {
    return this.http.post<ListUnreportedActivityResponse>(
      '/api/unreported-activity-list',
      request
    );
  }

  count(
    request: UnreportedActivityRequest = { now: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss') }
  ): Observable<CountUnreportedActivityResponse> {
    return this.http.post<CountUnreportedActivityResponse>(
      '/api/unreported-activity-list/count',
      request
    );
  }
}