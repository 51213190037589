import {
  PropertyType,
  SearchFilterPredicates,
  SearchFilterProperty,
  SearchFilterPropertyQueryService
} from '#application/services/search-filter-property-query.service';
import { ServerApi } from "#infrastructure/api/server-api";
import { FilterSchemaResponse } from "#infrastructure/api/server-filter-api";
import { Injectable } from '@angular/core';
import { SearchFilterCategory } from 'app/model/search-filter/search-filter';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

const convertToSearchFilterProperties = (response: FilterSchemaResponse): SearchFilterProperty[] => {
  return response.filter
    .filter(item => item.itemCategory === 'CUSTOM')
    .map(item => {
      if (item.itemCategory !== 'CUSTOM')
        throw new Error('itemCategory is not CUSTOM');

      let propertyType: PropertyType;
      let predicates: SearchFilterPredicates | undefined;
      switch (item.customField.type) {
        case 'NUMBER':
          propertyType = 'number';
          predicates = {
            type: 'contains',
            valueType: 'number',
            unit: item.customField.unit,
          }
          break;
        case 'SELECT':
          propertyType = 'list';
          predicates = {
            type: 'anyMatch',
            valueType: 'list',
            listValues: item.customField.options,
          }
          break;
        case 'USER':
          propertyType = 'user';
          break;
        case 'ATTACHMENT':
          propertyType = 'file';
          break;
        case 'DATE':
        case 'DATETIME':
          propertyType = 'date';
          break;
        case 'CLIENT':
          propertyType = 'client';
          break;
        case 'CHECKBOX':
          propertyType = 'checkbox';
          break;
        default:
          propertyType = 'text';
          break;
      }
      const property: SearchFilterProperty = {
        itemName: item.itemName,
        itemType: item.itemType,
        propertyId: `item:${item.customField.id}`,
        propertyName: item.displayName,
        propertyType: propertyType,
        predicates: predicates ? [predicates] : [],
      };
      return property
    })
}

@Injectable({
  providedIn: 'root'
})
export class SearchFilterPropertyQueryServiceImpl extends SearchFilterPropertyQueryService {

  constructor(
    private readonly serverApi: ServerApi,
  ) {
    super();
  }

  list(category: SearchFilterCategory): Observable<SearchFilterProperty[]> {
    switch (category) {
      case SearchFilterCategory.OPPORTUNITY:
        return this.serverApi.opportunityV2Api.getFilterSchema()
          .pipe(map(convertToSearchFilterProperties));
      case SearchFilterCategory.CLIENT:
        return this.serverApi.clientApi.getFilterSchema()
          .pipe(map(convertToSearchFilterProperties));
      case SearchFilterCategory.PRODUCT:
        return this.serverApi.productApi.getFilterSchema()
          .pipe(map(convertToSearchFilterProperties));
      case SearchFilterCategory.OPPORTUNITY_GROUP:
        return this.serverApi.opportunityGroupApi.getFilterSchema()
          .pipe(map(convertToSearchFilterProperties));
      case SearchFilterCategory.MEETING_BOARD_SETTING:
        return this.serverApi.meetingBoardSettingApi.getFilterSchema()
          .pipe(map(convertToSearchFilterProperties));
      case SearchFilterCategory.OPPORTUNITY_GROUP_OPPORTUNITY:
        return this.serverApi.opportunityV2Api.getFilterSchema()
          .pipe(map(convertToSearchFilterProperties));
      default:
        const unexpected: never = category;
        throw Error(`${unexpected} is unexpected value`);
    }
  }
}
