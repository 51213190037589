import { Injectable } from '@angular/core';
import {StorageKeyName} from "../../model/storage/storage-key-name";

/**
 * クライアントAPI
 *
 * `window.*`に当たるものは基本的にここにラップする
 * インフラ層からのみ参照し、他の層から参照しないこと
 */
@Injectable({ providedIn: 'root' })
export class ClientApi {
  storeLocalStorage(key: StorageKeyName, value: unknown): void {
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  lookupLocalStorage<T>(key: StorageKeyName): T | null {
    const info = window.localStorage.getItem(key);
    if (info === null) {
      return null;
    }
    return JSON.parse(info);
  }

  deleteLocalStorage(key: StorageKeyName): void {
    window.localStorage.removeItem(key);
  }

  storeSessionStorage(key: StorageKeyName, value: unknown): void {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }

  lookupSessionStorage<T>(key: StorageKeyName): T | null {
    const info = window.sessionStorage.getItem(key);
    if (info === null) {
      return null;
    }
    return JSON.parse(info);
  }

  deleteSessionStorage(key: StorageKeyName): void {
    window.sessionStorage.removeItem(key);
  }

}
