import { LocalizeKey } from "#application/services/authorize.service";
import { ConfirmService } from "#application/services/confirm.service";
import { LocalizedNameService } from "#application/services/localized-name.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { OpportunityGroupStatusCommandService, OpportunityGroupStatusInputs } from "#application/services/opportunity-group-status-command.service";
import { SnackBarService } from "#application/services/snack-bar.service";
import { OpportunityGroupStatusID } from "app/model/opportunity-group/opportunity-group-status";
import { ServerApiError, ServerApiErrorCode } from "#infrastructure/api/error";
import { ServerApi } from "#infrastructure/api/server-api";
import { messageFn } from "#utils/messages";
import { Injectable } from "@angular/core";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Failure, Result, Success } from "app/lib/result/result";
import { NEVER, Observable, catchError, concatMap, filter, map, of, tap } from "rxjs";

@Injectable({ providedIn: 'root' })
export class OpportunityGroupStatusCommandServiceImpl extends OpportunityGroupStatusCommandService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
    private readonly messageDialogService: MessageDialogService,
    private readonly confirmService: ConfirmService,
    private readonly localizedNameService: LocalizedNameService,
  ) {
    super();
  }

  add(inputs: OpportunityGroupStatusInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
  >>{
    const fieldName = `${this.localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}ステータス`
    return this.serverApi.opportunityGroupStatusApi.create({
      name: inputs.name,
      description: inputs.description,
    })
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(fieldName, 'CREATE_SUCCESS').message);
      }),
      map((v) => Success<void>(v)),
      catchError((e: ServerApiError) => {
        if (e.code === ServerApiErrorCode.BadRequest) {
          return of(Failure(GeneralFailure.BadRequest));
        }
        if (e.code === ServerApiErrorCode.Conflict) {
          return of(Failure(GeneralFailure.Conflict));
        }
        this.messageDialogService.notice({
          errorTarget: `${fieldName}の登録`
        });
        return NEVER;
      })
    );
  }
  update(id: OpportunityGroupStatusID, inputs: OpportunityGroupStatusInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.NotFound
  >>{
    const fieldName = `${this.localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}ステータス`
    return this.serverApi.opportunityGroupStatusApi.update(
      id,
      {
        name: inputs.name,
        description: inputs.description,
      }
    )
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(fieldName, 'UPDATE_SUCCESS').message);
      }),
      map((v) => Success<void>(v)),
      catchError((e: ServerApiError) => {
        if (e.code === ServerApiErrorCode.BadRequest) {
          return of(Failure(GeneralFailure.BadRequest));
        }
        if (e.code === ServerApiErrorCode.Conflict) {
          return of(Failure(GeneralFailure.Conflict));
        }
        if (e.code === ServerApiErrorCode.NotFound) {
          return of(Failure(GeneralFailure.NotFound));
        }
        this.messageDialogService.notice({
          errorTarget: `${fieldName}の更新`
        });
        return NEVER;
      })
    );
  }
  delete(id: OpportunityGroupStatusID): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.NotFound
  >>{
    const fieldName = `${this.localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}ステータス`
    return this.confirmService.confirm({
      title: messageFn(fieldName, 'DELETE_CONFIRM').title,
      message: messageFn(fieldName, 'DELETE_CONFIRM').message
    })
    .pipe(
      filter(r => r.isOK()),
      concatMap(() => this.serverApi.opportunityGroupStatusApi.delete(id)),
      tap(() => {
        this.snackBarService.show(messageFn(fieldName, 'DELETE_SUCCESS').message);
      }),
      map((v) => Success<void>(v)),
      catchError((e: ServerApiError) => {
        if (e.code === ServerApiErrorCode.BadRequest) {
          return of(Failure(GeneralFailure.BadRequest));
        }
        if (e.code === ServerApiErrorCode.NotFound) {
          return of(Failure(GeneralFailure.NotFound));
        }
        if (e.code === ServerApiErrorCode.Conflict) {
          return of(Failure(GeneralFailure.Conflict));
        }
        this.messageDialogService.notice({
          errorTarget: `${fieldName}の削除`
        });
        return NEVER;
      })
    );
  }
  sort(ids: OpportunityGroupStatusID[]): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>{
    const fieldName = `${this.localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}ステータス`
    return this.serverApi.opportunityGroupStatusApi.sort(
      {
        ids: ids.map(v => v)
      }
    )
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(fieldName, 'SORT_SUCCESS').message);
      }),
      map((v) => Success<void>(v)),
      catchError((e: ServerApiError) => {
        if (e.code === ServerApiErrorCode.BadRequest) {
          return of(Failure(GeneralFailure.BadRequest));
        }
        if (e.code === ServerApiErrorCode.NotFound) {
          return of(Failure(GeneralFailure.NotFound));
        }
        this.messageDialogService.notice({
          errorTarget: `${fieldName}の並び替え`
        });
        return NEVER;
      })
    );
  }
}
