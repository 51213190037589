import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { InfrastructureModule } from '#infrastructure/infrastructure.module';
import { AppComponent } from '#presentation/app.component';
import { PresentationModule } from '#presentation/presentation.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    PresentationModule,
    InfrastructureModule,
  ],
  declarations: [],
  bootstrap: [AppComponent],
})
export class AppModule {
}
