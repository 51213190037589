import { ApiFilterRequest, ApiPaginationRequest, FilterSchemaResponse } from "#infrastructure/api/server-filter-api";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export const ApiClientCategory = {
  CORPORATION: 'CORPORATION',
  INDIVIDUAL: 'INDIVIDUAL',
} as const;
export type ApiClientCategory = typeof ApiClientCategory[keyof typeof ApiClientCategory];

export type CreateCorporationRequest = CreateCorporationRequestData;

export interface CreateCorporationRequestData {
  name: string,
  clientTypeID: string,
  corporationName: string,
  corporationNameKana: string,
  corporateNumber?: string,
  address?: string,
  phoneNumber?: string,
  established?: number,
  numberOfEmployees?: number,
  initialCapital?: number,
  fiscalYearEnd?: number,
  website?: string,
  items?: {
    [customFieldId: string]: string,
  }
}

export type CreateIndividualRequest = CreateIndividualRequestData;

export interface CreateIndividualRequestData {
  name: string,
  clientTypeID: string,
  firstName: string,
  lastName: string,
  firstNameKana: string,
  lastNameKana: string,
  address?: string,
  phoneNumber?: string,
  sns1?: string,
  sns2?: string,
  items?: {
    [customFieldId: string]: string,
  }
}

export type CreateClientResponse = {
  clientID: string,
  clientNumber: number,
};

export type GetClientResponse =
  | GetCorporationResponseData
  | GetIndividualResponseData;

export interface GetCorporationResponseData {
  id: string;
  clientNumber: number;
  name: string;
  clientTypeID: string;
  corporationName: string;
  corporationNameKana: string;
  corporateNumber?: string;
  address?: string;
  phoneNumber?: string;
  established?: number;
  numberOfEmployees?: number;
  initialCapital?: number;
  fiscalYearEnd?: number;
  website?: string;
  items?: {
    [customFieldId: string]: string,
  },
  deleted: boolean,
}

export interface GetIndividualResponseData {
  id: string;
  clientNumber: number;
  name: string;
  clientTypeID: string;
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  address?: string;
  phoneNumber?: string;
  sns1?: string;
  sns2?: string;
  items?: {
    [customFieldId: string]: string,
  },
  deleted: boolean,
}

export type UpdateCorporationRequest = UpdateCorporationRequestData;

export interface UpdateCorporationRequestData {
  name: string;
  corporationName: string,
  corporationNameKana: string,
  corporateNumber?: string,
  address?: string,
  phoneNumber?: string,
  established?: number,
  numberOfEmployees?: number,
  initialCapital?: number,
  fiscalYearEnd?: number,
  website?: string,
  items?: {
    [customFieldId: string]: string,
  }
}

export type UpdateIndividualRequest = UpdateIndividualRequestData;

export interface UpdateIndividualRequestData {
  name: string;
  firstName: string,
  lastName: string,
  firstNameKana: string,
  lastNameKana: string,
  address?: string,
  phoneNumber?: string,
  sns1?: string,
  sns2?: string,
  items?: {
    [customFieldId: string]: string,
  }
}

export interface ClientListItemResponseData {
  totalCount: number,
  results: (
    | ClientListCorporationItemResponseData
    | ClientListIndividualItemResponseData
  )[],
}

export type ClientListCorporationItemResponseData = Omit<
  GetCorporationResponseData,
  'clientTypeID' | 'deleted'
> & {
  category: typeof ApiClientCategory.CORPORATION,
  clientType: {
    id: string,
    name: string,
  }
};

export type ClientListIndividualItemResponseData = Omit<
  GetIndividualResponseData,
  'clientTypeID' | 'deleted'
> & {
  category: typeof ApiClientCategory.INDIVIDUAL,
  clientType: {
    id: string,
    name: string,
  }
};

export type FilterClientResponse = {
  totalCount: number,
  results: (FilterCorporationResult | FilterIndividualResult)[]
}

export type FilterIndividualResult = {
  id: string,
  clientNumber: number,
  name: string,
  category: typeof ApiClientCategory.INDIVIDUAL,
  clientType: {
    id: string,
    name: string,
  },
  individual: {
    firstName: string,
    lastName: string,
    firstNameKana: string,
    lastNameKana: string,
    address?: string,
    phoneNumber?: string,
    sns1?: string,
    sns2?: string,
  },
  items?: {
    [customFieldID: string]: ApiCustomFieldItem
  }
}

export type FilterCorporationResult = {
  id: string;
  clientNumber: number;
  name: string,
  category: typeof ApiClientCategory.CORPORATION,
  clientType: {
    id: string,
    name: string,
  },
  corporation: {
    corporationName: string,
    corporationNameKana: string,
    corporateNumber?: string,
    address?: string,
    phoneNumber?: string,
    established?: number,
    numberOfEmployees?: number,
    initialCapital?: number,
    fiscalYearEnd?: number,
    website?: string,
  },
  items?: {
    [customFieldId: string]: ApiCustomFieldItem
  }
}

export type SearchSelectableClientListRequest = ApiPaginationRequest & {
  name: string,
}

export type SearchSelectableClientListResponse = {
  totalCount: number,
  results: {
    id: string,
    number: number,
    name: string,
    category: ApiClientCategory,
    address?: string,
  }[],
}

export type ApiCustomFieldItem = {
  type:
    | 'SINGLE_LINE_TEXT'
    | 'MULTI_LINE_TEXT'
    | 'URL'
    | 'MAIL_ADDRESS'
    | 'ADDRESS'
    | 'PHONE_NUMBER'
    | 'DATE'
    | 'DATETIME'
    | 'SELECT',
  name: string,
  value?: string,
} | {
  type: 'NUMBER',
  name: string,
  value?: number,
} | {
  type: 'USER',
  name: string,
  userID: string,
  userName?: string,
} | {
  type: 'ATTACHMENT',
  name: string,
  fileStatus: 'EXIST' | 'NOT_EXIST',
} | {
  type: 'CLIENT',
  name: string,
  clientID?: string,
  clientName?: string,
} | {
  type: 'CHECKBOX',
  name: string,
  value: boolean,
}

export class ClientApi {
  constructor(private readonly http: HttpClient) {
  }

  createCorporation(request: CreateCorporationRequest): Observable<CreateClientResponse> {
    return this.http.post<CreateClientResponse>('/api/client/corporation', request);
  }

  createIndividual(request: CreateIndividualRequest): Observable<CreateClientResponse> {
    return this.http.post<CreateClientResponse>('/api/client/individual', request);
  }

  get(clientID: string): Observable<GetClientResponse> {
    return this.http.get<GetClientResponse>(`/api/client/from-id/${clientID}`);
  }

  updateCorporation(
    clientID: string,
    request: UpdateCorporationRequest
  ): Observable<void> {
    return this.http.put<void>(`/api/client/corporation/${clientID}`, request);
  }

  updateIndividual(
    clientID: string,
    request: UpdateIndividualRequest
  ): Observable<void> {
    return this.http.put<void>(`/api/client/individual/${clientID}`, request);
  }

  delete(clientID: string): Observable<void> {
    return this.http.delete<void>(`/api/clients/${clientID}`);
  }

  filter(request: ApiPaginationRequest & ApiFilterRequest): Observable<FilterClientResponse> {
    return this.http.post<FilterClientResponse>(
      `/api/clients/filter`,
      request,
    );
  }

  filterList(request: ApiPaginationRequest & ApiFilterRequest): Observable<FilterClientResponse> {
    return this.http.post<FilterClientResponse>(
      `/api/client-list/filter`, request
    )
  }

  getFilterSchema(): Observable<FilterSchemaResponse> {
    return this.http.get<FilterSchemaResponse>(`/api/clients/filter2`);
  }

  searchSelectable(request: SearchSelectableClientListRequest): Observable<SearchSelectableClientListResponse> {
    return this.http.post<SearchSelectableClientListResponse>(
      `/api/clients/for-selectable-search`,
      request,
    );
  }
}
