import { Consultant, ConsultantID } from "app/model/consultant/consultant";
import { ConsultantRepository } from "app/model/consultant/consultant.repository";
import { ServerApi } from "#infrastructure/api/server-api";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ConsultantRepositoryImpl extends ConsultantRepository {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  save(consultant: Consultant): Observable<void> {
    return this.serverApi.consultantApi.update(consultant.id.value, {
      displayName: consultant.displayName.value,
      mailAddress: consultant.mailAddress.value,
      assignedOrganizationType: consultant.assignedOrganization.type,
      selectedOrganizations: consultant.assignedOrganization.list === undefined
        ? undefined
        : consultant.assignedOrganization.list.map(item => item.value),
    });
  }

  delete(id: ConsultantID): Observable<void> {
    return this.serverApi.consultantApi.delete(id.value);
  }

  resetPassword(id: ConsultantID): Observable<void> {
    return this.serverApi.consultantApi.resetPassword(id.value);
  }
}
