import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {sameAsBaseHost} from "#application/guard/domain-checker";
import { Page } from '#application/services/page-navigate.service';

@Injectable({
  providedIn: 'root'
})
export class DoesNotHaveSubDomainGuard  {

  constructor(
    private readonly router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (sameAsBaseHost(window.location.host)) return true;
    return this.router.parseUrl(Page.ErrorNotFound);
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (sameAsBaseHost(window.location.host)) return true;
    return this.router.parseUrl(Page.ErrorNotFound);
  }
}
