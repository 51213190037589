import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export type ListSalesPhaseResponse = {
  list: {
    id: string,
    name: string,
    isUsed: boolean,
    phaseList: {
      id: string,
      name: string,
    } [],
  } [],
};

export type GetSalesPhaseResponse = {
  id: string,
  name: string,
  isUsed: boolean,
  phaseList: {
    id: string,
    name: string,
    definition: string,
    successCriteria: string,
  } [],
};

export type AddSalesPhaseRequest = {
  name: string,
  phaseList: {
    name: string,
    definition: string,
    successCriteria: string,
  } [],
};

export type EditSalesPhaseRequest = {
  name: string,
  phaseList: {
    name: string,
    definition: string,
    successCriteria: string,
  } [],
};

export type FixSalesPhaseRequest = {
  name: string,
  phaseMap: {
    [phaseID: string]: {
      name: string,
      definition: string,
      successCriteria: string
    },
  },
};

export class SalesPhaseApi {
  private readonly _url = '/api/sales-phase';
  constructor(private readonly http: HttpClient) {}

  list(): Observable<ListSalesPhaseResponse> {
    return this.http.get<ListSalesPhaseResponse>(this._url);
  }

  get(salesPhaseID: string): Observable<GetSalesPhaseResponse> {
    return this.http.get<GetSalesPhaseResponse>(`${this._url}/${salesPhaseID}`);
  }

  add(request: AddSalesPhaseRequest): Observable<void> {
    return this.http.post<void>(this._url, request);
  }

  edit(salesPhaseID: string, request: EditSalesPhaseRequest): Observable<void> {
    return this.http.put<void>(`${this._url}/${salesPhaseID}`, request);
  }

  fix(salesPhaseID: string, request: FixSalesPhaseRequest): Observable<void> {
    return this.http.patch<void>(`${this._url}/${salesPhaseID}`, request);
  }
}
