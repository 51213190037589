import { HttpParams } from '@angular/common/http';

type paramValue = string | number | boolean | undefined;

interface HttpParamsObject {
  [key: string]: paramValue;
}

/**
 * オブジェクトからHttpParamsを生成する
 * 
 * @params paramsObject
 * @returns HttpParam
 */
export function buildHttpParams(paramsObject: HttpParamsObject): HttpParams {
  let httpParams = new HttpParams();
  for (const value in paramsObject) {
    const key: keyof typeof paramsObject = value;
    const data = paramsObject[key];
    if (data !== undefined) {
      httpParams = httpParams.set(key, data);
    }
  }
  return httpParams;
}
