import { FormLayoutId } from "app/model/form-layout/form-layout";
import { SalesPhaseID } from 'app/model/sales-phase/sales-phase';

export class OpportunityType {
  constructor(
    public readonly id: OpportunityTypeID,
    public readonly name: OpportunityTypeName,
    public readonly description: OpportunityTypeDescription,
    public readonly salesPhaseID: SalesPhaseID,
    public readonly formLayoutId?: FormLayoutId,
  ) {
  }
}

export class OpportunityTypeID {
  constructor(public readonly value: string) {
  }
  equals(obj?: OpportunityTypeID) {
    return obj?.value === this.value;
  }
}

export class OpportunityTypeName {
  static readonly MAX_LENGTH = 30;

  constructor(public readonly value: string) {
  }
}

export class OpportunityTypeDescription {
  static readonly MAX_LENGTH = 100;

  constructor(public readonly value: string) {
  }
}
