import { ActionDuration, ActionID } from "app/model/action/action"
import { ClientName } from "app/model/client/client";
import { NurturingDuration, NurturingID } from "app/model/nurturing/nurturing";
import { OpportunityName } from "app/model/opportunity/opportunity";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { Observable } from "rxjs";

export const UnreportedActivityType = {
  ACTION: 'ACTION',
  NURTURING: 'NURTURING',
} as const;

export type ActionItemOfUnreportedActivityList = Readonly<{
  id: ActionID,
  type: typeof UnreportedActivityType.ACTION,
  name: OpportunityName,
  duration: ActionDuration,
}>;

export type NurturingItemOfUnreportedActivityList = Readonly<{
  id: NurturingID,
  type: typeof UnreportedActivityType.NURTURING,
  name: ClientName,
  duration: NurturingDuration,
}>;

/** 未報告活動等一覧 */
export type UnreportedActivityList = ReadonlyArray<
  | ActionItemOfUnreportedActivityList
  | NurturingItemOfUnreportedActivityList
>;

export abstract class UnreportedActivityQueryService {
  abstract list(): Observable<Result<
    UnreportedActivityList,
    typeof GeneralFailure.Unexpected
  >>;
}