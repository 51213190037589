import {
  CsvFieldMapping,
  CsvFileFormat,
  CsvImportParam,
  ImportTypes,
} from '#application/services/csv-import.service';
import { ProductCsvImportService } from '#application/services/product-csv-import.service';
import { SnackBarService } from '#application/services/snack-bar.service';
import {
  FormLayoutCategory,
  FormLayoutId,
} from 'app/model/form-layout/form-layout';
import {
  ClientApi,
} from '#infrastructure/api/client-api';
import { ServerApi } from '#infrastructure/api/server-api';
import { CsvImportRequest } from '#infrastructure/api/server-csv-import-api';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {ApplicationHistoryType} from "../../model/storage/application-history";

@Injectable({ providedIn: 'root' })
export class ProductCsvImportServiceImpl extends ProductCsvImportService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
    private readonly clientApi: ClientApi
  ) {
    super();
  }

  getImportTypes(): Observable<ImportTypes> {
    return this.serverApi.productTypeApi.list().pipe(
      map((response) =>
        response.map((item) => {
          const importType = {
            typeId: item.id,
            typeName: item.name,
            category: FormLayoutCategory.PRODUCT,
            layoutId: item.formLayoutID
              ? new FormLayoutId(item.formLayoutID)
              : undefined,
          };
          return importType;
        })
      )
    );
  }

  importCsv(param: CsvImportParam): Observable<void> {
    const request: CsvImportRequest = {
      fileName: param.fileName,
      typeId: param.type.typeId,
      formatInfo: {
        encoding: param.formatDefinition.encoding,
        delimiter: param.formatDefinition.delimiter,
        dateFormat: param.formatDefinition.dateFormat,
        dateTimeFormat: param.formatDefinition.dateTimeFormat,
      },
      mapping: param.mapping.map((v) => ({ fieldId: v })),
      file: param.base64encodedCsvFile,
    };
    return this.serverApi.csvImportApi.importCsv('product', request).pipe(
      tap(() => this.snackBarService.show('CSVインポートを開始しました。')),
      map(() => {}),
      catchError((error) => {
        this.snackBarService.show('CSVインポートに失敗しました。');
        return throwError(error);
      })
    );
  }

  saveLastFileFormat(format: CsvFileFormat): void {
    this.clientApi.storeLocalStorage(
      ApplicationHistoryType.ProductCsvFileFormat,
      format
    );
  }

  getLastFileFormat(): CsvFileFormat | null {
    return this.clientApi.lookupLocalStorage(
      ApplicationHistoryType.ProductCsvFileFormat
    );
  }

  saveFieldMapping(mapping: CsvFieldMapping): void {
    this.clientApi.storeLocalStorage(
      ApplicationHistoryType.ProductCsvImport,
      mapping
    );
  }

  getLastFieldMapping(): CsvFieldMapping | null {
    return this.clientApi.lookupLocalStorage(
      ApplicationHistoryType.ProductCsvImport
    );
  }
}
