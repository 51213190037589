import { Injectable } from "@angular/core";
import { ClientID } from "app/model/client/client";
import { Observable } from "rxjs";
import { ServerApi } from "#infrastructure/api/server-api";
import { ClientRelationshipNoteFactory } from "app/model/client-relationship/client-relationship-note/client-relationship-note.factory";
import { ClientRelationshipID } from "app/model/client-relationship/client-relationship";
import { ClientRelationshipNoteContent } from "app/model/client-relationship/client-relationship-note/client-relationship-note";

@Injectable({providedIn: "root"})
export class ClientRelationshipNoteFactoryImpl extends ClientRelationshipNoteFactory {
  constructor(
    public readonly serverApi: ServerApi,
  ) {
    super();
  }

  create(clientID: ClientID, relationshipID: ClientRelationshipID, content: ClientRelationshipNoteContent): Observable<void> {
    return this.serverApi.clientRelationshipApi.createNote(
      clientID.value,
      relationshipID.value,
      { content: content.value }
    );
  }
}
