import { OrganizationID } from "app/model/organization/organization";

export class ConsultantID {
  constructor(public readonly value: string) {}
}

export class ConsultantLoginID {
  static readonly availableCharacter: RegExp = /^[a-zA-Z0-9-_\.]{1,50}$/;

  constructor(public readonly value: string) {}
}

export class DisplayName {
  static readonly maxLength: number = 50;

  constructor(public readonly value: string) {}
}

export class MailAddress {
  static readonly maxLength: number = 255;

  static readonly availableCharacter: RegExp =
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;

  constructor(public readonly value: string) {}
}

export const AssignedOrganizationType = {
  ALL: 'ALL',
  SELECTED: 'SELECTED',
} as const;
export type AssignedOrganizationType =
  typeof AssignedOrganizationType[keyof typeof AssignedOrganizationType];

export class AssignedOrganization {
  constructor(
    public readonly type: AssignedOrganizationType,
    public readonly list?: OrganizationID[],
  ) {}
}

export class Consultant {
  constructor(
    public readonly id: ConsultantID,
    public readonly loginID: ConsultantLoginID,
    public readonly displayName: DisplayName,
    public readonly mailAddress: MailAddress,
    public readonly assignedOrganization: AssignedOrganization,
  ) {}

  changeDisplayName(displayName: DisplayName): Consultant {
    return new Consultant(
      this.id,
      this.loginID,
      displayName,
      this.mailAddress,
      this.assignedOrganization,
    );
  }

  changeMailAddress(mailAddress: MailAddress): Consultant {
    return new Consultant(
      this.id,
      this.loginID,
      this.displayName,
      mailAddress,
      this.assignedOrganization,
    );
  }

  changeAssignedOrganization(assignedOrganization: AssignedOrganization): Consultant {
    return new Consultant(
      this.id,
      this.loginID,
      this.displayName,
      this.mailAddress,
      assignedOrganization,
    );
  }
}
