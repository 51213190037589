import {WebhookList, WebhookQueryService} from "#application/services/webhook-query.service";
import {Injectable} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {
  PayloadUrl,
  Webhook,
  WebhookCommands,
  WebhookID,
  WebhookName,
  WebhookObject,
  WebhookObjectCategory
} from "app/model/webhook/webhook";
import {ServerApi} from "#infrastructure/api/server-api";
import {MessageDialogService} from "#application/services/message-dialog.service";
import {catchError, map} from "rxjs/operators";
import {ApiWebhookObjectResponse} from "#infrastructure/api/server-webhook-api";
import {OpportunityTypeID, OpportunityTypeName} from "app/model/opportunity-type/opportunity-type";
import {ClientTypeID, ClientTypeName} from "app/model/client-info-type/client-type";

@Injectable({ providedIn: 'root' })
export class WebhookQueryServiceImpl extends WebhookQueryService {

  constructor(
    private readonly serverApi: ServerApi,
    private readonly messageDialogService: MessageDialogService,
  ) {
    super();
  }

  list(): Observable<WebhookList> {
    return this.serverApi.webhookApi.list()
      .pipe(
        map(res => res.map(v => ({
          id: new WebhookID(v.id),
          connectionStatus: v.connectionStatus,
          name: new WebhookName(v.name),
          uri: new PayloadUrl(v.uri),
        }))),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: 'Webhook一覧の取得'
          });
          return throwError(err);
        })
      );
  }

  get(id: WebhookID): Observable<Webhook> {
    return this.serverApi.webhookApi.get(id.value)
      .pipe(
        map(res => new Webhook(
          new WebhookID(res.id),
          new WebhookName(res.name),
          new PayloadUrl(res.uri),
          this._convertWebhookObject(res.object),
          new WebhookCommands(res.commands)
        )),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: 'Webhookの取得'
          });
          return throwError(err);
        })
      );
  }

  private _convertWebhookObject(object: ApiWebhookObjectResponse): WebhookObject {
    switch (object.category) {
      case 'OPPORTUNITY':
        return new WebhookObject(
          WebhookObjectCategory.OPPORTUNITY,
          new OpportunityTypeID(object.typeID),
          new OpportunityTypeName(object.typeName)
        );
      case 'CLIENT':
        return new WebhookObject(
          WebhookObjectCategory.CLIENT,
          new ClientTypeID(object.typeID),
          new ClientTypeName(object.typeName)
        );
      default:
        const unexpected: never = object.category;
        throw Error(`${unexpected} is unexpected value`);
    }
  }

}
