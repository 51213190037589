import {MessageDialogService} from '#application/services/message-dialog.service';
import {
  ListRestAuthTokenInputs,
  ListRestAuthTokenOutputs,
  RestAuthTokenQueryService
} from "#application/services/rest-auth-token-query.service";
import {
  RestAuthToken,
  RestAuthTokenCreatedAt,
  RestAuthTokenID,
  RestAuthTokenKeyCreatedAt,
  RestAuthTokenKeyTail,
  RestAuthTokenName,
} from 'app/model/rest-auth-token/rest-auth-token';
import { DisplayName, UserID, LoginID } from 'app/model/user/user';
import {ServerApi} from '#infrastructure/api/server-api';
import {ListRestAuthTokenResponse} from '#infrastructure/api/server-rest-auth-token-api';
import {fromUTCStrToDate} from '#utils/date';
import {inject, Injectable} from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class RestAuthTokenQueryServiceImpl implements RestAuthTokenQueryService {
  private readonly serverApi = inject(ServerApi);
  private readonly messageDialogService = inject(MessageDialogService);

  list(inputs: ListRestAuthTokenInputs): Observable<ListRestAuthTokenOutputs> {
    const req = {
      pagination: {
        perPage: inputs.perPage,
        page: inputs.page,
      },
    };
    return this.serverApi.restAuthTokenApi.list(req).pipe(
      map((res) => this.mapping(res)),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: '認証トークン一覧の取得',
        });
        return throwError(err);
      })
    );
  }

  get(id: RestAuthTokenID): Observable<Result<RestAuthToken, typeof GeneralFailure.Unexpected>> {
    return this.serverApi.restAuthTokenApi.get(id.value).pipe(
      map((res) => Success({
        id: new RestAuthTokenID(res.id),
        name: new RestAuthTokenName(res.name),
        subject: res.subjectID === undefined
          ? undefined
          : {
              id: new UserID(res.subjectID),
              loginID: new LoginID(res.subjectLoginID!),
              name: new DisplayName(res.subjectName!),
            },
      })),
      catchError(() => of(Failure(GeneralFailure.Unexpected)))
    );
  }

  private mapping(res: ListRestAuthTokenResponse): ListRestAuthTokenOutputs {
    const convertList = res.results.map((item) => {
      return {
        id: new RestAuthTokenID(item.id),
        name: new RestAuthTokenName(item.name),
        subject: item.subjectID === undefined
          ? undefined
          : {
              id: new UserID(item.subjectID),
              loginID: new LoginID(item.subjectLoginID!),
              name: new DisplayName(item.subjectName!),
            },
        createdAt: new RestAuthTokenCreatedAt(fromUTCStrToDate(item.createdAt)),
        keys: item.keys.map((key) => {
          return {
            tail: new RestAuthTokenKeyTail(key.tailRandomString),
            createdAt: new RestAuthTokenKeyCreatedAt(fromUTCStrToDate(key.createdAt)),
          }
        }),
      };
    });
    return {totalCount: res.totalCount, results: convertList};
  }
}
