import {inject, Injectable} from "@angular/core";
import {ServerApi} from "#infrastructure/api/server-api";
import {catchError, Observable, of} from "rxjs";
import {Failure, Result, Success} from "../../lib/result/result";
import {GeneralFailure} from "../../lib/general-failure/general-failure";
import {
  ClientForSelectFilterInput,
  ClientForSelectFilterOutput,
  ClientForSelectQueryService
} from "#application/services/client-for-select-query.service";
import {ClientID, ClientName} from "../../model/client/client";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ClientForSelectQueryServiceImpl implements ClientForSelectQueryService {
  private readonly _serverApi = inject(ServerApi);

  filter(input: ClientForSelectFilterInput): Observable<Result<
    ClientForSelectFilterOutput,
    | typeof GeneralFailure.Unexpected
  >> {
    return this._serverApi.clientForSelectApi
      .filter({
        pagination: {
          perPage: input.perPage,
          page: input.page,
        },
        filters: input.filterInput.filters,
        sorts: input.filterInput.sorts
      })
      .pipe(
        map(response => ({
          totalCount: response.totalCount,
          results: response.results.map(client => ({
            id: new ClientID(client.id),
            name: new ClientName(client.name),
          }))
        })),
        map(Success),
        catchError(() => of(Failure(GeneralFailure.Unexpected)))
      )
  }
}
