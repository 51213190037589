import { ServerApi } from '#infrastructure/api/server-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ContactHistoryTemplateQueryService, ContactHistoryTemplatesList, ContactHistoryTemplateDetail } from '#application/services/contact-history-template-query.service';
import { ContactHistoryTemplateID, ContactHistoryTemplateName, ContactHistoryTemplateContent } from 'app/model/contact-history-template/contact-history-template';

@Injectable({
  providedIn: 'root',
})
export class ContactHistoryTemplateQueryServiceImpl extends ContactHistoryTemplateQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  getContactHistoryTemplates(): Observable<ContactHistoryTemplatesList> {
    return this.serverApi.contactHistoryTemplateApi.list().pipe(
      map(templates =>
        templates.map(template => ({
          id: new ContactHistoryTemplateID(template.id),
          name: new ContactHistoryTemplateName(template.name),
          content: new ContactHistoryTemplateContent(template.content)
        }))
      )
    );
  }

  getContactHistoryTemplateByID(templateID: ContactHistoryTemplateID): Observable<ContactHistoryTemplateDetail> {
    return this.serverApi.contactHistoryTemplateApi.get(templateID.value).pipe(
      map(template => ({
        name: new ContactHistoryTemplateName(template.name),
        content: new ContactHistoryTemplateContent(template.content)
      }))
    );
  }
}
