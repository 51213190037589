import { HttpClient } from "@angular/common/http";
import { DateTime } from "luxon";
import { Observable } from "rxjs";

export type CreateFollowUpActionRequest = {
  opportunityID: string;
  due: string;
  followUpActionTypeID: string;
  assignee: string;
  contentsSummary: string;
  contentsDetail: string;
}

export type ListFollowUpActionData = {
  id: string,
  createdBy: {
    id: string,
    name: string,
  },
  opportunity: {
    id: string,
    name: string,
  },
  due: string,
  assignee: {
    id: string,
    name: string,
  },
  contentsSummary: string,
  isDone: boolean,
}

export type ListRequestedFollowUpActionData = Omit<ListFollowUpActionData, 'createdBy'>;

/** 未完了かつ自分が担当のフォロー一覧レスポンス */
export type ListUndoneAssignedFollowUpActionResponse = ListFollowUpActionData[];

/** 未完了かつ依頼した（＝他人担当）のフォロー一覧レスポンス */
export type ListUndoneRequestedFollowUpActionResponse = ListRequestedFollowUpActionData[];

/** 完了かつ自分が担当のフォロー一覧レスポンス */
export type ListDoneAssignedFollowUpActionResponse = {
  totalCount: number,
  results: ListFollowUpActionData[],
}

/** 完了かつ依頼した（＝他人担当）のフォロー一覧レスポンス */
export type ListDoneRequestedFollowUpActionResponse = {
  totalCount: number,
  results: ListRequestedFollowUpActionData[],
}

export type GetFollowUpActionResponse = {
  id: string,
  createdBy: {
    id: string,
    name: string,
  },
  opportunity: {
    id: string,
    name: string,
  },
  due: string,
  followUpActionType: {
    id: string,
    name: string,
  },
  assignee: {
    id: string,
    name: string,
  },
  contentsSummary: string,
  contentsDetail: string,
  isDone: boolean,
}

export type SaveFollowUpActionRequest = {
  opportunityID: string;
  due: string;
  followUpActionTypeID: string;
  assignee: string;
  contentsSummary: string;
  contentsDetail: string;
};

export type CountUndoneOverdueRequest = {
  now: string,
}

export type CountUndoneOverdueResponse = {
  count: number;
};

export class FollowUpActionApi {
  constructor(private readonly http: HttpClient) {
  }

  createFollowUpAction(request: CreateFollowUpActionRequest): Observable<void> {
    return this.http.post<void>('/api/follow-up-actions', request);
  }

  listUndoneAssigned(): Observable<ListUndoneAssignedFollowUpActionResponse> {
    return this.http.get<ListUndoneAssignedFollowUpActionResponse>(
      `/api/assigned-follow-up-actions/list/undone`
    );
  }

  listDoneAssigned(
    pagination: {perPage: number, page: number}
  ): Observable<ListDoneAssignedFollowUpActionResponse> {
    return this.http.post<ListDoneAssignedFollowUpActionResponse>(
      `/api/assigned-follow-up-actions/list/done`,
      pagination,
    );
  }

  listUndoneRequested(): Observable<ListUndoneRequestedFollowUpActionResponse> {
    return this.http.get<ListUndoneRequestedFollowUpActionResponse>(
      `/api/requested-follow-up-actions/list/undone`
    );
  }

  listDoneRequested(
    pagination: {perPage: number, page: number}
  ): Observable<ListDoneRequestedFollowUpActionResponse> {
    return this.http.post<ListDoneRequestedFollowUpActionResponse>(
      `/api/requested-follow-up-actions/list/done`,
      pagination,
    );
  }

  delete(followUpActionID: string): Observable<void> {
    return this.http.delete<void>(`/api/follow-up-actions/${followUpActionID}`);
  }

  save(followUpActionID: string, request: SaveFollowUpActionRequest): Observable<void> {
    return this.http.put<void>(`/api/follow-up-actions/${followUpActionID}`, request);
  }

  get(followUpActionID: string): Observable<GetFollowUpActionResponse> {
    return this.http.get<GetFollowUpActionResponse>(
      `/api/follow-up-actions/${followUpActionID}`
    );
  }

  done(followUpActionID: string): Observable<void> {
    return this.http.put<void>(`/api/follow-up-actions/${followUpActionID}/done`, {});
  }

  unDone(followUpActionID: string): Observable<void> {
    return this.http.put<void>(`/api/follow-up-actions/${followUpActionID}/undone`, {});
  }

  countUndoneOverdue(
    request: CountUndoneOverdueRequest = { now: DateTime.now().toFormat('yyyy-MM-dd 00:00:00') }
  ): Observable<CountUndoneOverdueResponse> {
    return this.http.post<CountUndoneOverdueResponse>(
      `/api/follow-up-actions/undone/overdue/count`, 
      request
    );
  }
}
