import { ServerApi } from '#infrastructure/api/server-api';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CreateOpportunityContextParams } from 'app/model/opportunity-context/opportunity-context.factory';
import { CreateOpportunityContextRequest } from '#infrastructure/api/server-opportunity-context-api';
import { CommonOpportunityContextFactory } from 'app/model/opportunity-context/common-opportunity-context.factory';

@Injectable({ providedIn: 'root' })
export class CommonOpportunityContextFactoryImpl extends CommonOpportunityContextFactory {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  create(params: CreateOpportunityContextParams): Observable<void> {
    const request: CreateOpportunityContextRequest = {
      name: params.name.value,
      description: params.description.value,
      labelNames: params.labelNames.map(v => v.value),
    };
    return this.serverApi.commonOpportunityContextApi.create(request);
  }
}
