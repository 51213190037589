import { ServerApi } from '#infrastructure/api/server-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ProductTypeDetail, ProductTypeList, ProductTypeQueryService } from '#application/services/product-type-query.service';
import { ProductTypeDescription, ProductTypeId, ProductTypeName } from 'app/model/product-type/product-type';
import { FormLayoutId, FormLayoutName } from 'app/model/form-layout/form-layout';

@Injectable({
  providedIn: 'root',
})
export class ProductTypeQueryServiceImpl extends ProductTypeQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  list(): Observable<ProductTypeList> {
    return this.serverApi.productTypeApi.list().pipe(
      map(list => {
        return list.map(item => ({
          id: new ProductTypeId(item.id),
          name: new ProductTypeName(item.name),
          description: new ProductTypeDescription(item.description),
          formLayoutID: item.formLayoutID ? new FormLayoutId(item.formLayoutID) : undefined,
          formLayoutName: item.formLayoutName ? new FormLayoutName(item.formLayoutName) : undefined,
        }))
      })
    );
  }

  get(id: ProductTypeId): Observable<ProductTypeDetail> {
    return this.serverApi.productTypeApi.get(id.value).pipe(
      map(productType => ({
        id: new ProductTypeId(productType.id),
        name: new ProductTypeName(productType.name),
        description: new ProductTypeDescription(productType.description),
        formLayoutID: productType.formLayoutID ? new FormLayoutId(productType.formLayoutID) : undefined,
      }))
    );
  }
}
