import { FilterMeetingBoardSettingInput, FilterMeetingBoardSettingOutput, GetMeetingBoardSettingOutput, MeetingBoardSettingQueryService } from '#application/services/meeting-board-setting-query.service';
import { ServerApiError, ServerApiErrorCode } from '#infrastructure/api/error';
import { ServerApi } from '#infrastructure/api/server-api';
import { fromUTCStrToDate } from '#utils/date';
import { Injectable, inject } from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import {
  MeetingBoardSettingBookmark, MeetingBoardSettingBookmarkCount,
  MeetingBoardSettingDescription,
  MeetingBoardSettingID,
  MeetingBoardSettingName
} from 'app/model/meeting-board/meeting-board';
import { MeetingBoardSettingFilterCondition } from 'app/model/meeting-board/meeting-board-setting-filter-conditon';
import { DisplayName, UserID } from 'app/model/user/user';
import { DateTime } from 'luxon';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeetingBoardSettingQueryServiceImpl implements MeetingBoardSettingQueryService {
  private readonly serverApi = inject(ServerApi);

  filter(input: FilterMeetingBoardSettingInput): Observable<Result<
    FilterMeetingBoardSettingOutput,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.meetingBoardSettingApi
      .filter({
        pagination: {
          perPage: input.perPage,
          page: input.page,
        },
        filters: input.filterInput.filters,
        sorts: input.filterInput.sorts,
      })
      .pipe(
        map(res => (Success({
          totalCount: res.totalCount,
          results: res.results.map(r => ({
            meetingBoardSettingID: MeetingBoardSettingID(r.meetingBoardSettingID),
            name: MeetingBoardSettingName(r.name),
            description: MeetingBoardSettingDescription(r.description),
            visibility: r.visibility,
            editability: r.editability,
            owner: {
              userID: new UserID(r.owner.userID),
              userDisplayName: new DisplayName(r.owner.userDisplayName),
            },
            createdAt: DateTime.fromJSDate(fromUTCStrToDate(r.createdAt)),
            updatedAt: DateTime.fromJSDate(fromUTCStrToDate(r.updatedAt)),
            bookmark: MeetingBoardSettingBookmark(r.bookmark),
            bookmarkCount: MeetingBoardSettingBookmarkCount(r.bookmarkCount),
          })),
        }))),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.BadRequest:
              return of(Failure(GeneralFailure.BadRequest));
            default:
              return of(Failure(GeneralFailure.Unexpected));
          }
        })
      );
  }

  get(id: MeetingBoardSettingID): Observable<Result<
    GetMeetingBoardSettingOutput,
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.meetingBoardSettingApi
      .get(id)
      .pipe(
        map(res => (Success({
          meetingBoardSettingID: MeetingBoardSettingID(res.meetingBoardSettingID),
          name: MeetingBoardSettingName(res.name),
          description: MeetingBoardSettingDescription(res.description),
          visibility: res.visibility,
          editability: res.editability,
          headerCondition: {
            isDisplayed: res.headerCondition.isDisplayed,
            displayUsers: res.headerCondition.displayUsers.map(u => ({
              userID: new UserID(u.userID),
              displayName: new DisplayName(u.userDisplayName),
            })),
          },
          filterCondition: MeetingBoardSettingFilterCondition.parse(res.filterConditionLiteral),
        } satisfies GetMeetingBoardSettingOutput))),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.NotFound:
              return of(Failure(GeneralFailure.NotFound));
            case ServerApiErrorCode.Conflict:
              return of(Failure(GeneralFailure.Conflict));
            default:
              return of(Failure(GeneralFailure.Unexpected));
          }
        })
      );
  }
}
