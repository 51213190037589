import {
  FiscalYearBegin,
  OrganizationCode,
  OrganizationID,
  OrganizationName
} from "app/model/organization/organization";
import {Observable} from "rxjs";

export type AssignedOrganizationList = {
  organizationID: OrganizationID;
  organizationCode: OrganizationCode;
  organizationName: OrganizationName;
  fiscalYearBegin: FiscalYearBegin;
}[];

export abstract class AssignedOrganizationQueryService {
  abstract list(): Observable<AssignedOrganizationList>;
}
