import {OpportunityTypeID} from 'app/model/opportunity-type/opportunity-type';
import {
  OpportunityTypeRepository,
  UpdateOpportunityTypeParam
} from 'app/model/opportunity-type/opportunity-type.repository';
import {ServerApi} from '#infrastructure/api/server-api';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class OpportunityTypeRepositoryImpl implements OpportunityTypeRepository {
  constructor(private readonly serverApi: ServerApi) {
  }

  remove(opportunityTypeID: OpportunityTypeID): Observable<void> {
    return this.serverApi.opportunityTypeApi.delete(opportunityTypeID.value);
  }

  saveOrders(ids: OpportunityTypeID[]): Observable<void> {
    const request = {ids: ids.map(id => id.value)};
    return this.serverApi.opportunityTypeApi.sort(request);
  }

  save(param: UpdateOpportunityTypeParam): Observable<void> {
    return this.serverApi.opportunityTypeApi.update(
      param.id.value,
      {
        name: param.name.value,
        description: param.description.value,
        formLayoutID: param.formLayoutID?.value,
      }
    );
  }
}
