import { UserGroupID, UserGroupName } from "app/model/user-group/user-group";
import { UserID } from "app/model/user/user";
import { Observable } from "rxjs";

export type AddUserGroupInputs = {
  name: UserGroupName,
  groups: UserGroupID[],
  users: UserID[],
}

export type UpdateUserGroupInputs = AddUserGroupInputs;

/**
 * ユーザーグループの上限件数を超える場合のエラー
 */
export class UserGroupTooManyError extends Error {}

/**
 * ユーザーグループの循環参照が発生する場合のエラー
 */
export class UserGroupAcyclicError extends Error {}

export abstract class UserGroupCommandService {
  abstract add(inputs: AddUserGroupInputs): Observable<void | UserGroupTooManyError | UserGroupAcyclicError>;
  abstract update(id: UserGroupID, inputs: UpdateUserGroupInputs): Observable<void | UserGroupAcyclicError>;
  abstract delete(id: UserGroupID): Observable<void>;
}
