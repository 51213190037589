import { Definition, Phase, PhaseID, PhaseName, SalesPhaseID, SalesPhaseName, SuccessCriteria } from "./sales-phase";

/**
 * 商談フェーズ（管理画面用）
 */
export class SalesPhaseSetting {
  static readonly PHASE_MAX_LENGTH = 10;
  static readonly PHASE_MIN_LENGTH = 1;

  constructor(
    public readonly id: SalesPhaseID,
    public readonly name: SalesPhaseName,
    public readonly isUsed: boolean,
    public readonly list: Phase[],
  ) {}

  static initialize(): SalesPhaseSetting {
    return new SalesPhaseSetting(
      new SalesPhaseID(''),
      new SalesPhaseName(''),
      false,
      [
        new Phase(
          new PhaseID(''),
          new PhaseName(''),
          new Definition(''),
          new SuccessCriteria(''),
        ),
      ],
    );
  }

  copy(): SalesPhaseSetting {
    return new SalesPhaseSetting(
      new SalesPhaseID(''),
      this.name,
      false,
      this.list.map((item) => new Phase(
        new PhaseID(''),
        item.name,
        item.definition,
        item.successCriteria,
      )),
    );
  }
}
