import { CoachingTiming } from 'app/model/coaching/coaching';
import { HintID } from 'app/model/hint/hint';
import { InsightAnswer, InsightAnswerID, InsightAnswerOption, InsightAnswerSelectionType, InsightAnswerTextType, InsightAnswerType, InsightID, InsightQuestion, InsightSupplement, InsightSupplementTitle } from 'app/model/insight/insight';
import { OpportunityContextID } from 'app/model/opportunity-context/opportunity-context';
import { Observable } from 'rxjs';

type AddInsightCommonInputs = Readonly<{
  coachingTiming: CoachingTiming,
  question: InsightQuestion,
  supplementTitle: InsightSupplementTitle,
  supplement: InsightSupplement,
  triggeringOpportunityContextIDs: readonly OpportunityContextID[],
}>;
type AddInsightSelectionTypeInputs = AddInsightCommonInputs & Readonly<{
  answerType: typeof InsightAnswerSelectionType.value,
  selection: {
    multiple: boolean,
    answerOptions: readonly Readonly<{
      answer: InsightAnswer,
      occurringOpportunityContextIDs?: readonly OpportunityContextID[]
      hintID: HintID | undefined,
    }>[],
  }
}>
type AddInsightTextTypeInputs = AddInsightCommonInputs & Readonly<{
  answerType: typeof InsightAnswerTextType.value,
}>
export type AddInsightInputs =
  | AddInsightSelectionTypeInputs
  | AddInsightTextTypeInputs

type UpdateInsightCommonInputs = Readonly<{
  question: InsightQuestion,
  supplementTitle: InsightSupplementTitle,
  supplement: InsightSupplement,
  triggeringOpportunityContextIDs: readonly OpportunityContextID[],
}>;
type UpdateInsightSelectionTypeInputs = UpdateInsightCommonInputs & Readonly<{
  answerType: typeof InsightAnswerSelectionType.value,
  selection: {
    multiple: boolean,
    answerOptions: readonly Readonly<{
      id?: InsightAnswerID,
      answer: InsightAnswer,
      occurringOpportunityContextIDs?: readonly OpportunityContextID[],
      hintID?: HintID
    }>[],
  }
}>
type UpdateInsightTextTypeInputs = UpdateInsightCommonInputs & Readonly<{
  answerType: typeof InsightAnswerTextType.value,
}>
export type UpdateInsightInputs =
  | UpdateInsightSelectionTypeInputs
  | UpdateInsightTextTypeInputs

export abstract class BaseInsightCommandService {

  abstract add(inputs: AddInsightInputs): Observable<void>;

  abstract update(id: InsightID, inputs: UpdateInsightInputs): Observable<void>;

  abstract delete(id: InsightID): Observable<void>;
}
