import { MessageDialogService } from "#application/services/message-dialog.service";
import { ProductCopyService } from "#application/services/product-copy.service";
import { SnackBarService } from "#application/services/snack-bar.service";
import { ProductId, ProductName, ProductNumber } from "app/model/product/product";
import { ServerApi } from "#infrastructure/api/server-api";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class ProductCopyServiceImpl extends ProductCopyService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
    private readonly messageDialogService: MessageDialogService,
  ) {
    super();
  }

  copy(id: ProductId, name: ProductName): Observable<ProductNumber> {
    return this.serverApi.productApi.copy(
      {
        id: id.value,
        name: name.value,
      }
    )
    .pipe(
      tap(() => this.snackBarService.show(`対象の商品を「${name.value}」として複製しました。`)),
      map(res => new ProductNumber(res.productNumber)),
      catchError(e => {
        this.messageDialogService.notice({ errorTarget: '複製' });
        return throwError(e);
      })
    );
  }
}
