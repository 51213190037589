import { DateTime } from "luxon";
import { ActionID } from "../action/action";
import { EventBaseStart, EventIDV2 } from "../event/event-v2";
import { NurturingID } from "../nurturing/nurturing";
import { ScheduleRecurringRule } from "./schedule-recurring-rule";

/** スケジュール項目の種別 */
export const ScheduleItemType = {
  ACTION: 'ACTION',
  NURTURING: 'NURTURING',
  EVENT: 'EVENT',
} as const;
/** スケジュール項目の種別 */
export type ScheduleItemType = typeof ScheduleItemType[keyof typeof ScheduleItemType];

export const ScheduleActionStatus = {
  DONE: 'DONE',
  UNDONE: 'UNDONE',
} as const;
export type ScheduleActionStatus = typeof ScheduleActionStatus[keyof typeof ScheduleActionStatus];

export const ScheduleNurturingStatus = {
  BEFORE: 'BEFORE',
  AFTER: 'AFTER',
} as const;
export type ScheduleNurturingStatus = typeof ScheduleNurturingStatus[keyof typeof ScheduleNurturingStatus];


export class ScheduleID {
  constructor(readonly value: string) {}

  toActionID(): ActionID {
    return new ActionID(this.value);
  }

  toNurturingID(): NurturingID {
    return new NurturingID(this.value);
  }

  toEventID(): EventIDV2 {
    return EventIDV2(this.value);
  }
}

export class ScheduleName {
  constructor(readonly value: string) {}
}

export class ScheduleStart {
  constructor(readonly value: Date) {}
}

export class ScheduleEnd {
  constructor(readonly value: Date) {}
}


/** PC表示用「分」単位スケジュール項目 */
export type PcScheduleMinutesItem = {
  readonly type: ScheduleItemType,
  readonly id: ScheduleID,
  readonly name: ScheduleName,
  readonly start: ScheduleStart,
  readonly end: ScheduleEnd,
  readonly originalStart: ScheduleStart,
  readonly originalEnd: ScheduleEnd,
  isOverlap: (item: PcScheduleMinutesItem) => boolean,
  equals: (item: PcScheduleMinutesItem) => boolean,
}

/** PC表示用「日」単位スケジュール項目 */
export type PcScheduleDaysItem = {
  readonly type: ScheduleItemType,
  readonly id: ScheduleID,
  readonly name: ScheduleName,
  readonly start: ScheduleStart,
  readonly end: ScheduleEnd,
  readonly originalStart: ScheduleStart,
  readonly originalEnd: ScheduleEnd,
  readonly baseStart: EventBaseStart, // この型に周期設定が混じるのは適切でなく、Eventの項目型にあるべきだが、他で使用されているためやむを得ない
  readonly recurring: ScheduleRecurringRule, // 申し訳ないが、根本のモデルの組み方が悪かった…
  isOverlap: (item: PcScheduleDaysItem) => boolean,
  notMatchStart: () => boolean,
  notMatchEnd: () => boolean,
  equals: (item: PcScheduleDaysItem) => boolean,
};

/** PC表示用スケジュールの活動項目 */
export type PcScheduleActionItem = {
  readonly type: typeof ScheduleItemType.ACTION;
  readonly id: ScheduleID,
  readonly status: ScheduleActionStatus,
  readonly name: ScheduleName,
  readonly start: ScheduleStart,
  readonly end: ScheduleEnd,
  readonly originalStart: ScheduleStart,
  readonly originalEnd: ScheduleEnd,
}

/** PC表示用スケジュールのナーチャリング項目 */
export type PcScheduleNurturingItem = {
  readonly type: typeof ScheduleItemType.NURTURING;
  readonly id: ScheduleID,
  readonly status: ScheduleNurturingStatus,
  readonly name: ScheduleName,
  readonly start: ScheduleStart,
  readonly end: ScheduleEnd,
  readonly originalStart: ScheduleStart,
  readonly originalEnd: ScheduleEnd,
}

/** PC表示用スケジュールの分単位活動項目 */
export class PcScheduleMinutesActionItem implements PcScheduleMinutesItem, PcScheduleActionItem {
  readonly type = ScheduleItemType.ACTION;

  constructor(
    public readonly id: ScheduleID,
    public readonly status: ScheduleActionStatus,
    public readonly name: ScheduleName,
    public readonly start: ScheduleStart,
    public readonly end: ScheduleEnd,
    public readonly originalStart: ScheduleStart,
    public readonly originalEnd: ScheduleEnd,
  ) {}

  /**
   * アイテム同士の期間が重なっているか
   * @param item 比較対象のアイテム
   */
  isOverlap(item: PcScheduleMinutesItem): boolean {
    return (
      item.start.value >= this.start.value
      && item.start.value < this.end.value
      ||
      item.end.value > this.start.value
      && item.end.value <= this.end.value
      ||
      item.start.value <= this.start.value
      && item.end.value >= this.end.value
    );
  }

  equals(item: PcScheduleMinutesItem): boolean {
    return this.id.value === item.id.value;
  }
}

/** PC表示用スケジュールの分単位ナーチャリング項目 */
export class PcScheduleMinutesNurturingItem implements PcScheduleMinutesItem, PcScheduleNurturingItem {
  readonly type = ScheduleItemType.NURTURING;

  constructor(
    public readonly id: ScheduleID,
    public readonly status: ScheduleNurturingStatus,
    public readonly name: ScheduleName,
    public readonly start: ScheduleStart,
    public readonly end: ScheduleEnd,
    public readonly originalStart: ScheduleStart,
    public readonly originalEnd: ScheduleEnd,
  ) {}

  /**
   * アイテム同士の期間が重なっているか
   * @param item 比較対象のアイテム
   */
  isOverlap(item: PcScheduleMinutesItem): boolean {
    return (
      item.start.value >= this.start.value
      && item.start.value < this.end.value
      ||
      item.end.value > this.start.value
      && item.end.value <= this.end.value
      ||
      item.start.value <= this.start.value
      && item.end.value >= this.end.value
    );
  }

  equals(item: PcScheduleMinutesItem): boolean {
    return this.id.value === item.id.value;
  }
}


/** PC表示用スケジュールのイベント項目 */
export type PcScheduleEventItem = {
  readonly type: typeof ScheduleItemType.EVENT;
  readonly id: ScheduleID,
  readonly name: ScheduleName,
  readonly start: ScheduleStart,
  readonly end: ScheduleEnd,
  readonly originalStart: ScheduleStart,
  readonly originalEnd: ScheduleEnd,
  readonly baseStart: EventBaseStart,
  readonly recurring: ScheduleRecurringRule,
}

/** PC表示用スケジュールの分単位イベント項目 */
export class PcScheduleMinutesEventItem implements PcScheduleMinutesItem, PcScheduleEventItem {
  readonly type = ScheduleItemType.EVENT;

  constructor(
    public readonly id: ScheduleID,
    public readonly name: ScheduleName,
    public readonly start: ScheduleStart,
    public readonly end: ScheduleEnd,
    public readonly originalStart: ScheduleStart,
    public readonly originalEnd: ScheduleEnd,
    public readonly baseStart: EventBaseStart,
    public readonly recurring: ScheduleRecurringRule,
  ) {}

  /**
   * アイテム同士の期間が重なっているか
   * @param item 比較対象のアイテム
   */
  isOverlap(item: PcScheduleMinutesItem): boolean {
    return (
      item.start.value >= this.start.value
      && item.start.value < this.end.value
      ||
      item.end.value > this.start.value
      && item.end.value <= this.end.value
      ||
      item.start.value <= this.start.value
      && item.end.value >= this.end.value
    );
  }

  equals(item: PcScheduleMinutesItem): boolean {
    return this.type === item.type
      ? this.id.value === item.id.value
        && this.baseStart.value === (item as this).baseStart.value
      : false
  }
}

/** PC表示用スケジュールの日単位イベント項目 */
export class PcScheduleDaysEventItem implements PcScheduleDaysItem, PcScheduleEventItem {
  readonly type = ScheduleItemType.EVENT;

  constructor(
    public readonly id: ScheduleID,
    public readonly name: ScheduleName,
    public readonly start: ScheduleStart,
    public readonly end: ScheduleEnd,
    public readonly originalStart: ScheduleStart,
    public readonly originalEnd: ScheduleEnd,
    public readonly baseStart: EventBaseStart,
    public readonly recurring: ScheduleRecurringRule,
  ) {}

  /**
   * アイテム同士の期間が重なっているか
   * @param item 比較対象のアイテム
   */
  isOverlap(item: PcScheduleDaysItem): boolean {
    return (
      item.start.value >= this.start.value
      && item.start.value <= this.end.value
      ||
      item.end.value >= this.start.value
      && item.end.value <= this.end.value
      ||
      item.start.value <= this.start.value
      && item.end.value >= this.end.value
    );
  }

  /**
   * 元の開始日と表示の開始日が一致しないか
   */
  notMatchStart(): boolean {
    return !DateTime.fromJSDate(this.start.value)
    .equals(DateTime.fromJSDate(this.originalStart.value));
  }

  /**
   * 元の終了日と表示の終了日が一致しないか
   */
  notMatchEnd(): boolean {
    return !DateTime.fromJSDate(this.end.value)
    .equals(DateTime.fromJSDate(this.originalEnd.value));
  }

  equals(item: PcScheduleDaysItem): boolean {
    return this.type === item.type
      ? this.id.value === item.id.value
        && this.baseStart.value === item.baseStart.value
      : false
  }
}

/** 「分」単位活動項目か判定 */
export const isMinutesActionItem = (item: PcScheduleMinutesItem): boolean => {
  return item.type === ScheduleItemType.ACTION;
}

/** 「分」単位ナーチャリング項目か判定 */
export const isMinutesNurturingItem = (item: PcScheduleMinutesItem): boolean => {
  return item.type === ScheduleItemType.NURTURING;
}

/** 「分」単位イベント項目か判定 */
export const isMinutesEventItem = (item: PcScheduleMinutesItem): boolean => {
  return item.type === ScheduleItemType.EVENT;
}

/** 「日」単位イベント項目か判定 */
export const isDaysEventItem = (item: PcScheduleDaysItem): boolean => {
  return item.type === ScheduleItemType.EVENT;
}
