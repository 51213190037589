import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type UserGroupForSelectResponse = {
  userGroupID: string,
  userGroupName: string,
  userIDs: string[],
}[]

export class UserGroupForSelectApi {

  constructor(private readonly http: HttpClient) {}

  all(): Observable<UserGroupForSelectResponse> {
    return this.http.get<UserGroupForSelectResponse>(`/api/user-group-for-select/all`);
  }
}
