import { UserAccessLevel } from "app/model/user/user";

export const UserSecurityRole = UserAccessLevel;
export type UserSecurityRole = UserAccessLevel;

export const MetaUserSecurityRole = {
  CONSULTANT: 'CONSULTANT',
  ORGANIZATION_CONSULTANT: 'ORGANIZATION_CONSULTANT',
  ADMIN: 'ADMIN',
} as const;
export type MetaUserSecurityRole = typeof MetaUserSecurityRole[keyof typeof MetaUserSecurityRole];

export const SecurityRole = {
  ...UserSecurityRole,
  ...MetaUserSecurityRole,
} as const;
export type SecurityRole =
  | UserSecurityRole
  | MetaUserSecurityRole
