import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

import { SnackBarService } from "#application/services/snack-bar.service";

/**
 * スナックバーサービス
 */
@Injectable({ providedIn: 'root' })
export class SnackBarServiceImpl implements SnackBarService {
    constructor(private readonly snackBar: MatSnackBar) {
    }
    show(message: string): void {
        this.snackBar.open(message, 'close', { duration: 4000 });
    }
}
