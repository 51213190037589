import {ClientID, ClientName, ClientNumber} from "app/model/client/client";
import { Companions } from "app/model/companion/companion";
import {ContactDisplayName, ContactID, ContactRoleName, DepartmentName} from "app/model/contact/contact";
import { CustomItems } from "app/model/custom-field/custom-field";
import { NurturingTypeDescription, NurturingTypeID, NurturingTypeName } from "app/model/nurturing-type/nurturing-type";
import { DisplayName, UserID } from "app/model/user/user";
import { DateTime } from "luxon";

/**
 * ナーチャリングID
 */
export class NurturingID {
  constructor(readonly value: string) {}

  equals(other: NurturingID): boolean {
    return this.value === other.value;
  }
}

/**
 * ナーチャリングタイトル
 */
export class NurturingTitle {
  constructor(readonly value: string) {}

  static readonly MAX_LENGTH = 255;
}

/**
 * ナーチャリングメモ
 */
export class NurturingNote {
  constructor(readonly value: string) {}

  static readonly MAX_LENGTH = 3000;
}

/**
 * ナーチャリング開始日時
 */
export class NurturingStartDateTime {
  constructor(readonly value: Date) {}
}

/**
 * ナーチャリング終了日時
 */
export class NurturingEndDateTime {
  constructor(readonly value: Date) {}
}

/**
 * ナーチャリングの実施期間（単位：分）
 */
export class NurturingMinutesDuration {
  constructor(readonly value: number) {}

  static readonly MAX_VALUE = 1439;
}

/**
 * ナーチャリングの期間で表現した日時
 */
export type NurturingDuration = Readonly<{
  startDateTime: NurturingStartDateTime,
  minutes: NurturingMinutesDuration,
}>
export namespace NurturingDuration {
  export const parseEndDate = (duration: NurturingDuration): Date => {
    return DateTime.fromJSDate(duration.startDateTime.value)
    .plus({ minutes: duration.minutes.value })
    .toJSDate();
  }
}

/**
 * ナーチャリングの同席者（＝顧客の担当者（連絡先））
 */
export type NurturingAttendee = Readonly<{
  contactID: ContactID,
  contactDisplayName: ContactDisplayName,
  departmentName: DepartmentName,
  roleName: ContactRoleName,
}>
export namespace NurturingAttendee {
  export const equals = (a: NurturingAttendee, b: NurturingAttendee) => a.contactID.equals(b.contactID)
}

/**
 * ナーチャリングの同席者（＝顧客の担当者（連絡先））のリスト
 */
export type NurturingAttendees = ReadonlyArray<NurturingAttendee>
export namespace NurturingAttendees {
  export const includes = (attendees: NurturingAttendees, contactID: ContactID) => {
    return attendees.some(attendee => attendee.contactID.equals(contactID));
  }
}

export const NurturingStatus = {
  UNREPORTED: 'UNREPORTED',
  REPORTED: 'REPORTED',
} as const;
export type NurturingStatus = typeof NurturingStatus[keyof typeof NurturingStatus];

/**
 * ナーチャリング
 */
export type Nurturing = Readonly<{
  id: NurturingID,
  owner: {
    id: UserID,
    name: DisplayName,
  },
  client: {
    id: ClientID,
    name: ClientName,
    number: ClientNumber,
  },
  title: NurturingTitle,
  duration: NurturingDuration,
  companions: Companions,
  attendees: NurturingAttendees,
  note: NurturingNote,
  nurturingType: {
    id: NurturingTypeID,
    name: NurturingTypeName,
    description: NurturingTypeDescription,
  },
  beforeHearing: CustomItems,
  afterHearing: CustomItems,
}>
