import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { buildHttpParams } from './http-params-builder';
import { CsvExportCategory, CsvExportRequestCategory, CsvExportStatus } from './server-csv-export';

interface CsvExportHistoryData {
  id: string;
  status: CsvExportStatus;
  category: CsvExportRequestCategory;
  typeName: string,
  userId: string;
  userName: string;
  size: number;
  exportStartAt: string;
  exportEndAt?: string;
}

interface CsvExportHistoriesResponseData {
  totalCount: number;
  results: CsvExportHistoryData[];
}

export type CsvExportHistoriesResponse =
  CsvExportHistoriesResponseData;

export class CsvExportHistoriesApi {
  constructor(private readonly http: HttpClient) {}

  list(
    category: CsvExportCategory,
    perPage: number,
    page: number
  ): Observable<CsvExportHistoriesResponse> {
    const params = buildHttpParams({ perPage: perPage, page: page });
    return this.http.get<CsvExportHistoriesResponse>(
      `/api/csv-exports/${category}`,
      { params: params }
    );
  }
}
