import {ClientID} from "app/model/client/client";
import {ClientProductRelationshipID} from "app/model/client-product-relationship/client-product-relationship";
import {ProductId, ProductName, ProductNumber} from "app/model/product/product";
import {ProductTypeName} from "app/model/product-type/product-type";
import {Observable} from "rxjs";

export type ClientRelatedProductsInput = {
  perPage: number;
  page: number;
};

export interface ClientRelatedProductList {
  totalCount: number,
  results: ClientRelatedProductListItem[]
}

export interface ClientRelatedProductListItem {
  clientProductRelationshipID: ClientProductRelationshipID,
  productID: ProductId,
  productNumber: ProductNumber,
  productTypeName: ProductTypeName,
  productName: ProductName
}

export abstract class ClientRelatedProductsQueryService {
  abstract getClientRelatedProductList(clientID: ClientID, input: ClientRelatedProductsInput): Observable<ClientRelatedProductList>;
}
