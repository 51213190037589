import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ServerApi} from '#infrastructure/api/server-api';
import { OpportunityContext, OpportunityContextID } from 'app/model/opportunity-context/opportunity-context';
import { UpdateOpportunityContextRequest } from '#infrastructure/api/server-opportunity-context-api';
import { CommonOpportunityContextRepository } from 'app/model/opportunity-context/common-opportunity-context.repository';

@Injectable({ providedIn: 'root' })
export class CommonOpportunityContextRepositoryImpl extends CommonOpportunityContextRepository {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  save(context: OpportunityContext): Observable<void> {
    const id = context.id.value;
    const request: UpdateOpportunityContextRequest = {
      name: context.name.value,
      description: context.description.value,
      labelNames: context.labelNames.map(v => v.value),
    };
    return this.serverApi.commonOpportunityContextApi.update(id, request);
  }

  delete(id: OpportunityContextID): Observable<void> {
    return this.serverApi.commonOpportunityContextApi.delete(id.value);
  }
}
