import { Observable } from 'rxjs';

import { UserFactory } from 'app/model/user/user.factory';
import { ServerApi } from '#infrastructure/api/server-api';
import { Injectable } from '@angular/core';

import { DisplayName, MailAddress, UserAccessLevel, LoginID } from 'app/model/user/user';

@Injectable({providedIn: 'root'})
export class UserFactoryImpl implements UserFactory {
  constructor(private readonly serverApi: ServerApi) {
  }
  create(loginID: LoginID, name: DisplayName, mailAddress: MailAddress, accessLevels: UserAccessLevel[]): Observable<void>{
    return this.serverApi.user.create({
      userName: loginID.value,
      name: name.value,
      mailAddress: mailAddress.value,
      accessLevels
    });
  }
}
