import {CalendarStoreService} from "#application/services/calendar-store.service";
import {inject, Injectable} from "@angular/core";
import {ClientApi} from "#infrastructure/api/client-api";
import {CALENDAR_KEY_NAME, Calendar, DailyGroupCalendar, WeeklyGroupCalendar} from "../../model/storage/calendar";

const DEFAULT_CALENDAR: Calendar = {
  daily: {
    group: {
      enableDisplayTimeLimit: false,
    },
  },
  weekly: {
    group: {
      enableDisplayTimeLimit: false,
    },
  },
}

@Injectable({providedIn: 'root'})
export class CalendarStoreServiceImpl implements CalendarStoreService {
  private readonly _clientApi = inject(ClientApi);
  get(): Calendar {
    const calendar = this._clientApi.lookupLocalStorage<Calendar>(CALENDAR_KEY_NAME);
    return {
      ...DEFAULT_CALENDAR,
      ...calendar,
    };
  }
  mergeAndSaveDailyGroupCalendar(value: DailyGroupCalendar): void {
    const calendar = this.get();
    const merged: Calendar = {
      ...DEFAULT_CALENDAR,
      ...calendar,
      daily: {
        group: value,
      },
    }
    this._clientApi.storeLocalStorage(CALENDAR_KEY_NAME, merged);
  }

  mergeAndSaveWeeklyGroupCalendar(value: WeeklyGroupCalendar): void {
    const calendar = this.get();
    const merged: Calendar = {
      ...DEFAULT_CALENDAR,
      ...calendar,
      weekly: {
        group: value,
      },
    }
    this._clientApi.storeLocalStorage(CALENDAR_KEY_NAME, merged);
  }
}
