import {OpportunityTypeID, OpportunityTypeName} from "app/model/opportunity-type/opportunity-type";
import {ClientTypeID, ClientTypeName} from "app/model/client-info-type/client-type";

export class WebhookID {
  constructor(public readonly value: string) {}
}

export class WebhookName {
  public static MAX_LENGTH = 30;
  constructor(public readonly value: string) {}
}

export class PayloadUrl {
  constructor(public readonly value: string) {}
}

export const WebhookObjectCategory = {
  OPPORTUNITY: 'OPPORTUNITY',
  CLIENT: 'CLIENT'
} as const
export type WebhookObjectCategory =
  typeof WebhookObjectCategory[keyof typeof WebhookObjectCategory]


export type WebhookObjectTypeID =
  | OpportunityTypeID
  | ClientTypeID

export type WebhookObjectTypeName =
  | OpportunityTypeName
  | ClientTypeName

export class WebhookObject {
  constructor(
    public readonly category: WebhookObjectCategory,
    public readonly typeID: WebhookObjectTypeID,
    public readonly typeName: WebhookObjectTypeName, // タイプが論理削除されていた場合でもフロントで表示するために名前を保持している
  ) {}
}

export const WebhookCommand = {
  CREATED: 'CREATED',
  UPDATED: 'UPDATED',
  DELETED: 'DELETED',
} as const
export type WebhookCommand = typeof WebhookCommand[keyof typeof WebhookCommand]

export class WebhookCommands {
  constructor(public readonly list: Readonly<WebhookCommand[]>) {}
}

export class Webhook {
  constructor(
    public readonly id: WebhookID | undefined,
    public readonly name: WebhookName,
    public readonly payloadUrl: PayloadUrl,
    public readonly object: WebhookObject,
    public readonly commands: WebhookCommands,
  ) {}
}
