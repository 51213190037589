import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

interface AssignedOrganizationResponseData {
  organizationID: string,
  organizationCode: string,
  organizationName: string,
  fiscalYearBegin: number,
}

export type ListAssignedOrganizationResponse = AssignedOrganizationResponseData[];

export class AssignedOrganizationApi {
  constructor(private readonly http: HttpClient) {
  }

  list(): Observable<ListAssignedOrganizationResponse> {
    return this.http.get<AssignedOrganizationResponseData[]>('/api/assigned-organization-list');
  }
}
