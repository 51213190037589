import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ServerApi} from '#infrastructure/api/server-api';
import { ClientID } from 'app/model/client/client';
import { ClientRelationshipID } from 'app/model/client-relationship/client-relationship';
import { ClientRelationshipNoteRepository } from 'app/model/client-relationship/client-relationship-note/client-relationship-note.repository';
import { ClientRelationshipNote, ClientRelationshipNoteID } from 'app/model/client-relationship/client-relationship-note/client-relationship-note';

@Injectable({providedIn: 'root'})
export class ClientRelationshipNoteRepositoryImpl implements ClientRelationshipNoteRepository {
  constructor(
    private readonly serverApi: ServerApi
  ) {
  }

  save(note: ClientRelationshipNote): Observable<void> {
    return this.serverApi.clientRelationshipApi.updateNote(
      note.clientID.value,
      note.relationshipID.value,
      note.id.value,
      { content: note.content.value }
    )
  }

  delete(clientID: ClientID, relationshipID: ClientRelationshipID, noteID: ClientRelationshipNoteID): Observable<void> {
    return this.serverApi.clientRelationshipApi.deleteNote(
      clientID.value,
      relationshipID.value,
      noteID.value
    );
  }
}
