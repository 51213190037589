import { Brand } from "app/lib/brand/brand";

export type OpportunityGroupStatus = Readonly<{
  id: OpportunityGroupStatusID,
  name: OpportunityGroupStatusName,
  description?: OpportunityGroupStatusDescription,
}>;

export type OpportunityGroupStatusID = Brand<string, 'OpportunityGroupStatusID'>;
export function OpportunityGroupStatusID(value: string) {
  return value as OpportunityGroupStatusID;
}

export type OpportunityGroupStatusName = Brand<string, 'OpportunityGroupStatusName'>;
export function OpportunityGroupStatusName(value: string) {
  return value as OpportunityGroupStatusName;
}
export namespace OpportunityGroupStatusName {
  export const MAX_LENGTH = 30
}

export type OpportunityGroupStatusDescription = Brand<string, 'OpportunityGroupStatusDescription'>;
export function OpportunityGroupStatusDescription(value: string) {
  return value as OpportunityGroupStatusDescription;
}
export namespace OpportunityGroupStatusDescription {
  export const MAX_LENGTH = 100
}
