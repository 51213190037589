import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";


export type LocalizationSettingListResponse = {
  lang: string,
  list: {
    id: string,
    originalName: string,
    localizedName: string,
  }[]
}


export type LocalizationSettingSaveRequest = {
  list: {
    id: string,
    localizedName: string,
  }[]
}

export class LocalizationSettingApi {

  constructor(private readonly http: HttpClient) {
  }

  list(lang: string): Observable<LocalizationSettingListResponse> {
    return this.http.get<LocalizationSettingListResponse>(`/api/localizations/list/${lang}`);
  }

  save(lang: string, request: LocalizationSettingSaveRequest): Observable<void> {
    return this.http.patch<void>(`/api/localizations/${lang}`, request);
  }
}
