import {ServerApi} from '#infrastructure/api/server-api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {FormLayoutId, FormLayoutName} from 'app/model/form-layout/form-layout';
import {
  ClientTypeDetail,
  ClientTypeList,
  ClientTypeListItem,
  ClientTypeQueryService
} from '#application/services/client-type-query.service';
import {
  ClientCategory,
  ClientTypeDescription,
  ClientTypeID,
  ClientTypeName
} from 'app/model/client-info-type/client-type';
import {ListClientTypeResponse} from "#infrastructure/api/server-client-type-api";

@Injectable({
  providedIn: 'root',
})
export class ClientTypeQueryServiceImpl extends ClientTypeQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  get(id: ClientTypeID): Observable<ClientTypeDetail> {
    return this.serverApi.clientTypeApi.get(id.value).pipe(
      map(clientType => ({
        id: new ClientTypeID(clientType.id),
        name: new ClientTypeName(clientType.name),
        description: new ClientTypeDescription(clientType.description),
        formLayoutID: clientType.formLayoutID ? new FormLayoutId(clientType.formLayoutID) : undefined,
      }))
    );
  }

  listClientType(): Observable<ClientTypeList> {
    return this.serverApi.clientTypeApi.list()
      .pipe(
        map(response => response.map(item =>
          new ClientTypeListItem(
            new ClientTypeID(item.id),
            categories[item.category],
            new ClientTypeName(item.name),
            new ClientTypeDescription(item.description),
            item.formLayoutID === undefined ? undefined : new FormLayoutId(item.formLayoutID),
            item.formLayoutName === undefined ? undefined : new FormLayoutName(item.formLayoutName)
          )
        )),
        map(items => new ClientTypeList(items))
      );
  }
}

const categories: Record<ListClientTypeResponse[number]['category'], ClientCategory> = {
  CORPORATION: ClientCategory.Corporation,
  INDIVIDUAL: ClientCategory.Individual,
}
