import { AuthorizeService } from "#application/services/authorize.service";
import { FollowUpActionAuthority, FollowUpActionAuthorityService, UsersOfFollowUpAction } from "#application/services/follow-up-action-authority.service";
import { Injectable, inject } from "@angular/core";

/**
 * フォローの権限の種類
 */
export const FollowUpActionAuthorityType = {
  CREATOR_ASSIGNEE: 'CREATOR_ASSIGNEE',
  CREATOR: 'CREATOR',
  ASSIGNEE: 'ASSIGNEE',
  NONE: 'NONE',
} as const;
export type FollowUpActionAuthorityType =
  typeof FollowUpActionAuthorityType[keyof typeof FollowUpActionAuthorityType];

/**
 * 種類ごとのフォローの操作権限の集合
 */
export type FollowUpActionAuthoritiesByType = Record<
  FollowUpActionAuthorityType,
  FollowUpActionAuthority
>

/**
 * フォロー操作権限
 * 暫定的にここに置くが、将来的にはローカルストレージ等に保存されている
 * 認証情報等の外部から取得するようにする
 */
export const followUpActionAuthorities: FollowUpActionAuthoritiesByType = {
  CREATOR_ASSIGNEE: {
    updatable: true,
    deletable: true,
    enableDone: true,
    enableUndone: true,
  },
  CREATOR: {
    updatable: true,
    deletable: true,
    enableDone: true,
    enableUndone: true,
  },
  ASSIGNEE: {
    updatable: true,
    deletable: false,
    enableDone: true,
    enableUndone: true,
  },
  NONE: {
    updatable: false,
    deletable: false,
    enableDone: false,
    enableUndone: false,
  },
} as const;

@Injectable({ providedIn: 'root' })
export class FollowUpActionAuthorityServiceImpl implements FollowUpActionAuthorityService {
  private readonly authorizeService = inject(AuthorizeService);

  getAuthorityBy(usersOfFollow: UsersOfFollowUpAction): FollowUpActionAuthority {
    const { creatorID, assigneeID } = usersOfFollow;
    const thisUserID = this.authorizeService.getAuthInfo().id;

    if (thisUserID === creatorID.value && thisUserID === assigneeID.value) {
      return followUpActionAuthorities.CREATOR_ASSIGNEE;
    }
    if (thisUserID === creatorID.value) {
      return followUpActionAuthorities.CREATOR;
    }
    if (thisUserID === assigneeID.value) {
      return followUpActionAuthorities.ASSIGNEE;
    }
    return followUpActionAuthorities.NONE;
  }
}
