import { FormLayoutId, FormLayoutName } from "app/model/form-layout/form-layout";
import { Brand } from "app/lib/brand/brand";
import { OpportunityGroupWorkflowID, OpportunityGroupWorkflowName } from "./opportunity-group-workflow";

export type OpportunityGroupType = Readonly<{
  id: OpportunityGroupTypeID,
  name: OpportunityGroupTypeName,
  description: OpportunityGroupTypeDescription,
  // レイアウトのみBrandedTypeではない
  formLayout?: {
    id: FormLayoutId,
    name: FormLayoutName,
  },
  workflow: {
    id: OpportunityGroupWorkflowID,
    name: OpportunityGroupWorkflowName,
  },
}>;

export type OpportunityGroupTypeID = Brand<string, 'OpportunityGroupTypeID'>;
export function OpportunityGroupTypeID(value: string) {
  return value as OpportunityGroupTypeID;
}

export type OpportunityGroupTypeName = Brand<string, 'OpportunityGroupTypeName'>;
export function OpportunityGroupTypeName(value: string) {
  return value as OpportunityGroupTypeName;
}
export namespace OpportunityGroupTypeName {
  export const MAX_LENGTH: number = 30;
}

export type OpportunityGroupTypeDescription = Brand<string, 'OpportunityGroupTypeDescription'>;
export function OpportunityGroupTypeDescription(value: string) {
  return value as OpportunityGroupTypeDescription;
}
export namespace OpportunityGroupTypeDescription {
  export const MAX_LENGTH: number = 100;
}
