import { Observable } from "rxjs";

import { Organization, OrganizationID } from "./organization";

/**
 * 組織リポジトリ
 */
export abstract class OrganizationRepository {
    abstract findByID(id: OrganizationID): Observable<Organization>;
    abstract findAll(): Observable<Organization[]>;
    abstract delete(id: OrganizationID): Observable<void>;
    abstract save(organization: Organization): Observable<void>;
}
