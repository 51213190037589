import { ConfirmService } from "#application/services/confirm.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import {
  ProposalTypeCommandService,
  AddProposalTypeInputs,
  UpdateProposalTypeInputs
} from "#application/services/proposal-type-command.service";
import { SnackBarService } from "#application/services/snack-bar.service";
import { ProposalTypeID } from "app/model/proposal-type/proposal-type";
import { ServerApi } from "#infrastructure/api/server-api";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, concatMap, filter, tap } from "rxjs/operators";
import {messageFn, Target} from "#utils/messages";

@Injectable({ providedIn: 'root' })
export class ProposalTypeCommandServiceImpl extends ProposalTypeCommandService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
    private readonly messageDialogService: MessageDialogService,
    private readonly confirmService: ConfirmService,
  ) {
    super();
  }

  add(inputs: AddProposalTypeInputs): Observable<void> {
    return this.serverApi.proposalTypeApi.create({
      name: inputs.name.value,
      description: inputs.description.value,
      productDetailType: inputs.productDetailType,
      formLayoutID: inputs.formLayoutID === undefined ? undefined : inputs.formLayoutID.value,
    })
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(Target.PROPOSAL_TYPE, 'CREATE_SUCCESS').message);
      }),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: '提案タイプの登録'
        });
        return throwError(err);
      })
    );
  }

  update(id: ProposalTypeID, inputs: UpdateProposalTypeInputs): Observable<void> {
    return this.serverApi.proposalTypeApi.update(
      id.value,
      {
        name: inputs.name.value,
        description: inputs.description.value,
        formLayoutID: inputs.formLayoutID === undefined ? undefined : inputs.formLayoutID.value,
      }
    )
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(Target.PROPOSAL_TYPE, 'UPDATE_SUCCESS').message);
      }),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: '提案タイプの更新'
        });
        return throwError(err);
      })
    );
  }

  delete(id: ProposalTypeID): Observable<void> {
    return this.confirmService.confirm({
      title: messageFn(Target.PROPOSAL_TYPE, 'DELETE_CONFIRM').title,
      message: messageFn(Target.PROPOSAL_TYPE, 'DELETE_CONFIRM').message
    })
    .pipe(
      filter(r => r.isOK()),
      concatMap(() => this.serverApi.proposalTypeApi.delete(id.value)),
      tap(() => {
        this.snackBarService.show(messageFn(Target.PROPOSAL_TYPE, 'DELETE_SUCCESS').message);
      }),
      catchError((err) => {
        this.messageDialogService.notice({
          title: messageFn(Target.PROPOSAL_TYPE, 'DELETE_ERROR').title,
          message: messageFn(Target.PROPOSAL_TYPE, 'DELETE_ERROR').message
        });
        return throwError(err);
      })
    );
  }

  sort(ids: ProposalTypeID[]): Observable<void> {
    return this.serverApi.proposalTypeApi.sort({ ids: ids.map(v => v.value)})
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(Target.PROPOSAL_TYPE, 'SORT_SUCCESS').message);
      }),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: '提案タイプの並び替え'
        });
        return throwError(err);
      })
    );
  }
}
