import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { SingleSignOnComponent } from './single-sign-on.component';
import { MaterialModule } from '#presentation/material.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SingleSignOnRoutingModule } from './single-sign-on-routing.module';
import { TopBarModule } from '#presentation/components/top-bar/top-bar.module';

@NgModule({
  declarations: [
    SingleSignOnComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    MatProgressSpinnerModule,
    SingleSignOnRoutingModule,
    TopBarModule,
  ],
  exports: [
    SingleSignOnComponent
  ]
})
export class SingleSignOnModule { }
