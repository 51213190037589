import {ClientID} from "app/model/client/client";
import {
  DepartmentName,
  EmailInfoList,
  ContactDisplayName,
  FirstName,
  FirstNameKana,
  LastName,
  LastNameKana,
  Note,
  PhoneNumberInfoList,
  ContactRoleName,
  Sex,
  ContactID
} from "app/model/contact/contact";
import {Observable} from "rxjs";

export type CreateContactEntity = {
  clientID: ClientID | undefined,
  departmentName: DepartmentName,
  roleName: ContactRoleName,
  displayName: ContactDisplayName,
  firstName: FirstName,
  firstNameKana: FirstNameKana,
  lastName: LastName,
  lastNameKana: LastNameKana,
  sex: Sex,
  phoneNumberInfoList: PhoneNumberInfoList,
  emailInfoList: EmailInfoList,
  note: Note,
};

export abstract class ContactFactory {
  abstract create(entity: CreateContactEntity): Observable<ContactID>;
}
