import {MessageDialogService} from "#application/services/message-dialog.service";
import {ProposalTypeList, ProposalTypeQueryService} from "#application/services/proposal-type-query.service";
import {FormLayoutId, FormLayoutName} from "app/model/form-layout/form-layout";
import {
  ProductDetailType,
  ProposalType,
  ProposalTypeDescription,
  ProposalTypeID,
  ProposalTypeName
} from "app/model/proposal-type/proposal-type";
import {ServerApi} from "#infrastructure/api/server-api";
import {Injectable} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class ProposalTypeQueryServiceImpl extends ProposalTypeQueryService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly messageDialogService: MessageDialogService,
  ) {
    super();
  }

  list(): Observable<ProposalTypeList> {
    return this.serverApi.proposalTypeApi.list()
    .pipe(
      map(res => res.map(v => ({
        id: new ProposalTypeID(v.id),
        name: new ProposalTypeName(v.name),
        description: new ProposalTypeDescription(v.description),
        productDetailType: this._productDetailTypeValueOf(v.productDetailType),
        formLayoutID: v.formLayoutID === undefined ? undefined : new FormLayoutId(v.formLayoutID),
        formLayoutName: v.formLayoutName === undefined ? undefined : new FormLayoutName(v.formLayoutName),
      }))),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: '提案タイプ一覧の取得'
        });
        return throwError(err);
      })
    );
  }

  get(id: ProposalTypeID): Observable<ProposalType> {
    return this.serverApi.proposalTypeApi.get(id.value)
    .pipe(
      map(res => ({
        id: new ProposalTypeID(res.id),
        name: new ProposalTypeName(res.name),
        description: new ProposalTypeDescription(res.description),
        productDetailType: this._productDetailTypeValueOf(res.productDetailType),
        formLayoutID: res.formLayoutID === undefined ? undefined : new FormLayoutId(res.formLayoutID),
      })),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: '提案タイプの取得'
        });
        return throwError(err);
      })
    );
  }

  private _productDetailTypeValueOf(value: 'SINGLE' | 'MULTIPLE'): ProductDetailType {
    switch (value) {
      case 'SINGLE':
        return ProductDetailType.SINGLE;
      case 'MULTIPLE':
        return ProductDetailType.MULTIPLE;
      default:
        const unexpected: never = value;
        throw Error(`${unexpected} is unexpected value`);
    }
  }
}
