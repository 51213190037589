import { FilterInput } from "#infrastructure/application/filter.service";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { MeetingBoardHeader, MeetingBoardPropsForSettingUp, MeetingBoardSettingID } from "app/model/meeting-board/meeting-board";
import { DateTime } from "luxon";
import { Observable } from "rxjs";
import { OpportunityListV2 } from "./opportunity-query-v2.service";

export type GetHeadersInput = Readonly<{
  id: MeetingBoardSettingID,
  dateSpan: {
    start: DateTime,
    months: number,
  },
}>

export type GetInitialOpportunitiesOfUsersInput = Readonly<{
  headers: MeetingBoardHeader[],
  perPage: number,
  filterInput: FilterInput,
}>
export type GetInitialOpportunitiesOfUsersOutput = ReadonlyArray<{
  userID: string,
} & OpportunityListV2>

export type FilterOpportunitiesPerUserInput = Readonly<{
  userID: string,
  perPage: number,
  page: number,
  filterInput: FilterInput,
}>

export type RangeOpportunitiesPerUserInput = Readonly<{
  userID: string,
  perPage: number,
  pages: number[],
  filterInput: FilterInput,
}>

export class NotFoundMTGBoardSettingError extends Error {
  readonly type = 'NOT_FOUND';
  constructor() {
    super('ミーティングボード設定が見つかりません');
  }
}

export class PrivateVisibilityMTGBoardError extends Error {
  readonly type = 'PRIVATE_VISIBILITY';
  constructor() {
    super('閲覧権限が非公開に設定されています');
  }
}

export class UnexpectedMTGBoardSetUpError extends Error {
  readonly type = 'UNEXPECTED';
  constructor() {
    super('予期しないエラーが発生しました');
  }
}

export type MeetingBoardSetUpError =
  | NotFoundMTGBoardSettingError
  | PrivateVisibilityMTGBoardError
  | UnexpectedMTGBoardSetUpError

export abstract class MeetingBoardQueryService {
  // ミーティングボードの設定取得・組み立てに関するメソッドについては、
  // 一連の処理として提供する都合から、FailureではなくErrorを投げることとした。

  /**
   * ミーティングボードおよびその組み立てのための情報を取得する。
   * @param id ミーティングボード設定ID
   * @returns ミーティングボードおよびその組み立てのための情報
   * @throws NotFoundMTGBoardSettingError ミーティングボード設定が見つからない場合
   * @throws PrivateVisibilityMTGBoardError 閲覧権限が非公開に設定されている場合（URL直アクセスを想定）
   * @throws UnexpectedMTGBoardSetUpError 予期しないエラーが発生した場合
   */
  abstract getForSettingUp(id: MeetingBoardSettingID): Observable<MeetingBoardPropsForSettingUp>

  /**
   * 指定の期間に基づきミーティングボードのヘッダーを取得する。
   * @param input ミーティングボード設定IDとヘッダーの集計期間
   * @returns ミーティングボードのヘッダーの一覧
   * @throws NotFoundMTGBoardSettingError ミーティングボード設定が見つからない場合
   * @throws UnexpectedMTGBoardSetUpError 予期しないエラーが発生した場合
   */
  abstract getHeaders(input: GetHeadersInput): Observable<MeetingBoardHeader[]>

  /**
   * 指定のユーザーの商談一覧を取得する。
   * @param input ユーザーIDとパジネーションとフィルター条件
   * @returns 商談一覧
   */
  abstract filterOpportunitiesPerUser(input: FilterOpportunitiesPerUserInput): Observable<Result<
    OpportunityListV2,
    typeof GeneralFailure.Unexpected
  >>;

  /**
   * パジネーション指定範囲のユーザーの商談一覧を取得する。
   * @param input ユーザーIDとパジネーション(ページは取得したい範囲の配列)とフィルター条件
   * @returns 商談一覧
   */
  abstract rangeOpportunitiesPerUser(input: RangeOpportunitiesPerUserInput): Observable<Result<
    OpportunityListV2,
    typeof GeneralFailure.Unexpected
  >>;
}
