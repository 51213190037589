import {Observable} from "rxjs";
import {CustomField, CustomFieldCategory, CustomFieldId} from "app/model/custom-field/custom-field";

export abstract class CustomFieldRepository {
  abstract save(category: CustomFieldCategory, customField: CustomField): Observable<void>;

  abstract remove(id: CustomFieldId, category: CustomFieldCategory): Observable<void>;

  abstract saveOrders(category: CustomFieldCategory, idList: CustomFieldId[]): Observable<void>;
}
