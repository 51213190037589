import {PhaseID} from 'app/model/sales-phase/sales-phase';
import {CustomFieldId} from "app/model/custom-field/custom-field";

export class OpportunityHearingSettings {
  constructor(public readonly list: OpportunityHearingSetting[]) {}
}

/**
 * 商談項目毎のヒアリング設定
 */
export class OpportunityHearingSetting {
  constructor(
    public readonly id: CustomFieldId,
    public readonly settings: OpportunityHearingSettingPerPhase[]
  ) {}
}

/**
 * 商談フェーズ毎のヒアリング設定
 */
export class OpportunityHearingSettingPerPhase {
  constructor(
    public readonly phaseID: PhaseID,
    public readonly before: HearingType,
    public readonly after: HearingType
  ) {}
}

/**
 * ヒアリングのタイミング（事前か事後か）
 */
export const Timing = {
  BEFORE: 'before',
  AFTER: 'after',
} as const;
export type Timing = typeof Timing[keyof typeof Timing];

/**
 * ヒアリング種別
 */
export const HearingType = {
  None: 'none',
  Hearing: 'hearing',
} as const;
export type HearingType = typeof HearingType[keyof typeof HearingType];

export const hearingTypeValueOf = (value: HearingType): HearingType => {
  switch (value) {
    case 'none':
      return HearingType.None;
    case 'hearing':
      return HearingType.Hearing;
    default:
      const unexpected: never = value;
      throw Error(`${unexpected} is unexpected value`);
  }
}
