import {
  RestAuthToken,
  RestAuthTokenCreatedAt,
  RestAuthTokenID,
  RestAuthTokenKeyCreatedAt,
  RestAuthTokenKeyTail,
  RestAuthTokenName,
  RestAuthTokenSubjectID,
  RestAuthTokenSubjectLoginID,
  RestAuthTokenSubjectName
} from "app/model/rest-auth-token/rest-auth-token";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import {Observable} from "rxjs";

export type ListRestAuthTokenInputs = {
  perPage: number,
  page: number,
}

export type ListRestAuthTokenOutputs = {
  totalCount: number,
  results: {
    id: RestAuthTokenID,
    name: RestAuthTokenName,
    subject: {
      id: RestAuthTokenSubjectID,
      loginID: RestAuthTokenSubjectLoginID,
      name: RestAuthTokenSubjectName,
    } | undefined,
    createdAt: RestAuthTokenCreatedAt,
    keys:
      {
        tail: RestAuthTokenKeyTail,
        createdAt: RestAuthTokenKeyCreatedAt,
      } [],
  } [],
}

export abstract class RestAuthTokenQueryService {
  abstract list(inputs: ListRestAuthTokenInputs): Observable<ListRestAuthTokenOutputs>;
  abstract get(id: RestAuthTokenID): Observable<Result<
    RestAuthToken,
    typeof GeneralFailure.Unexpected
  >>;
}
