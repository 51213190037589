import {Observable} from 'rxjs';
import {ActionTypeDescription, ActionTypeID, ActionTypeName} from "app/model/action/action-type";

export interface ActionTypeListItem {
  id: ActionTypeID,
  name: ActionTypeName,
  description: ActionTypeDescription,
}

export type ActionTypeList = ActionTypeListItem[];

export interface ActionTypeDetail {
  id: ActionTypeID,
  name: ActionTypeName,
  description: ActionTypeDescription,
}

export abstract class ActionTypeQueryService {
  abstract list(): Observable<ActionTypeList>;

  abstract get(id: ActionTypeID): Observable<ActionTypeDetail>
}
