import { ActionID } from "app/model/action/action";
import { CoachingStatus, CoachingID, CoachingTextAnswer, CoachingTiming } from "app/model/coaching/coaching";
import { Hint } from "app/model/hint/hint";
import { InsightAnswerID, InsightAnswer, InsightID, InsightQuestion, InsightSupplementTitle, InsightSupplement, InsightAnswerTextType, InsightAnswerSelectionType } from "app/model/insight/insight";
import { Observable } from "rxjs";

export type StartCoachingInputs = {
  actionID: ActionID,
  coachingTiming: CoachingTiming,
}

export type FinishedCoachingData = {
  status: typeof CoachingStatus.FINISHED,
}

export type ContinueCoachingData = {
  status: typeof CoachingStatus.CONTINUE,
  coachingID: CoachingID,
  index: number,
  insight: {
    id: InsightID,
    question: InsightQuestion,
    supplementTitle: InsightSupplementTitle,
    supplement: InsightSupplement,
  } & (TextTypeAnswer | SelectionTypeAnswer),
};
export type TextTypeAnswer = {
  answerType: typeof InsightAnswerTextType['value'],
}
export type SelectionTypeAnswer = {
  answerType: typeof InsightAnswerSelectionType['value'],
  multiple: boolean,
  answerOptions: {
    id: InsightAnswerID,
    answer: InsightAnswer,
    hint: Hint | undefined,
  }[],
}

export type CoachingData = FinishedCoachingData | ContinueCoachingData;

export type CoachingReplyInputs = TextTypeReply | SelectionTypeReply;

type TextTypeReply = {
  type: typeof InsightAnswerTextType['value'],
  value: CoachingTextAnswer,
};
type SelectionTypeReply = {
  type: typeof InsightAnswerSelectionType['value'],
  value: InsightAnswerID[],
};

export const isFirstCoaching = (coaching: ContinueCoachingData): boolean => {
  return coaching.index === 1;
}
export const isContinueCoaching =
(coaching: CoachingData): coaching is ContinueCoachingData => {
  return coaching.status === CoachingStatus.CONTINUE;
}

export abstract class CoachingService {
  abstract start(inputs: StartCoachingInputs): Observable<ContinueCoachingData | void>;

  abstract reply(coachingID: CoachingID, inputs: CoachingReplyInputs): Observable<CoachingData>;

  abstract undo(coachingID: CoachingID): Observable<ContinueCoachingData>;
}
