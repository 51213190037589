import { DownloadFile, FileListByParent, FileQueryService, ListFileInputs } from "#application/services/file-query.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { categoryOfParent, FileID, FileName, FileProfile, FileSize, FileUpdatedAt } from "app/model/file/file";
import { DisplayName } from "app/model/user/user";
import { ServerApi } from "#infrastructure/api/server-api";
import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class FileQueryServiceImpl extends FileQueryService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly messageDialogService: MessageDialogService,
  ) {
    super();
  }

  getProfile(id: FileID): Observable<FileProfile> {
    return this.serverApi.fileApi.getProfile(id.value)
    .pipe(
      map(res => ({
        id: new FileID(res.fileID),
        fileName: new FileName(res.fileName),
        fileSize: new FileSize(res.fileSize),
      })),
      catchError(e => {
        this.messageDialogService.notice({
          errorTarget: 'ファイル情報の取得'
        });
        return throwError(e);
      })
    );
  }

  download(id: FileID): Observable<DownloadFile> {
    return this.serverApi.fileApi.download(id.value)
    .pipe(
      catchError(e => {
        this.messageDialogService.notice({
          errorTarget: 'ファイルのダウンロード'
        });
        return throwError(e);
      })
    );
  }

  list(inputs: ListFileInputs): Observable<FileListByParent> {
    return this.serverApi.fileApi.list({
      parentID: inputs.parentID.value,
      category: categoryOfParent(inputs.parentID),
      perPage: inputs.perPage,
      page: inputs.page,
    })
    .pipe(
      map(res => ({
        totalCount: res.totalCount,
        results: res.results.map(v => ({
          fileID: new FileID(v.fileID),
          fileName: new FileName(v.fileName),
          updatedAt: FileUpdatedAt.ofUTC(v.updatedAt),
          displayUserName: new DisplayName(v.userDisplayName)
        }))
      })),
      catchError(e => {
        this.messageDialogService.notice({
          errorTarget: 'ファイルの一覧取得'
        });
        return throwError(e);
      })
    );
  }
}
