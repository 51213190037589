import { OpportunityContext, OpportunityContextDescription, OpportunityContextID, OpportunityContextLabelName, OpportunityContextName } from 'app/model/opportunity-context/opportunity-context';
import { FilterPredicateType } from 'app/model/search-filter/search-filter';
import { Observable } from 'rxjs';

export type SearchOpportunityContextListParams = {
  perPage: number,
  page: number,
  filter?: {
    name: {
      type: typeof FilterPredicateType.contains,
      value: string,
    },
    labelName: {
      type: typeof FilterPredicateType.anyMatch,
      values: string[],
    }
  }
};

export type OpportunityContextList = {
  totalCount: number;
  results: OpportunityContextListResult[]
}
export type OpportunityContextListResult = {
  id: OpportunityContextID,
  name: OpportunityContextName,
  description: OpportunityContextDescription,
  readonly?: boolean,
  labelNames: OpportunityContextLabelName[],
}

export abstract class BaseOpportunityContextQueryService {
  abstract findBy(params: SearchOpportunityContextListParams): Observable<OpportunityContextList>;
  abstract get(id: OpportunityContextID): Observable<OpportunityContext>;
}
