import { ServerApi } from '#infrastructure/api/server-api';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CreateProductTypeParam, ProductTypeFactory } from 'app/model/product-type/product-type.factory';

@Injectable({ providedIn: 'root' })
export class ProductTypeFactoryImpl extends ProductTypeFactory {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  create(param: CreateProductTypeParam): Observable<void> {
    return this.serverApi.productTypeApi.create(
      {
        name: param.name.value,
        description: param.description.value,
        formLayoutID: param.formLayoutID ? param.formLayoutID.value : undefined
      }
    );
  }
}
