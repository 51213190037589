import { GetNurturingReportInput, NurturingReport, PerUserNurturingReport, NurturingReportPerUserGroup, ReportNurturingQueryService, GetNurturingReportPerUserInput, NurturingReportPerUser, NurturingListOfUser, GetPersonalNurturingReportInput, PersonalNurturingReport } from '#application/services/report-nurturing-query.service';
import { ClientID, ClientName } from 'app/model/client/client';
import { NurturingTypeID, NurturingTypeName } from 'app/model/nurturing-type/nurturing-type';
import {
  NurturingID,
  NurturingStartDateTime,
  NurturingEndDateTime,
  NurturingMinutesDuration,
  NurturingTitle
} from 'app/model/nurturing/nurturing';
import { DisplayName, UserID } from 'app/model/user/user';
import { ServerApi } from '#infrastructure/api/server-api';
import { GetNurturingReportRequest, NurturingListResponse, NurturingReportPerUserResponse, NurturingReportResponse } from '#infrastructure/api/server-report-nurturing-api';
import { Injectable, inject } from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import { DateTime } from 'luxon';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportNurturingQueryServiceImpl implements ReportNurturingQueryService {
  private readonly serverApi = inject(ServerApi);

  get(input: GetNurturingReportInput): Observable<Result<
    NurturingReportPerUserGroup,
    typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.reportNurturingApi.get({
      duration: toDurationRequest(input.duration),
      assignee: toAssigneeRequest(input.assignee),
      nurturingTypeIDs: input.nurturingTypeIDs.map(id => id.value),
      nurturingStates: input.nurturingStates,
    })
    .pipe(
      map(r => {
        const report: NurturingReportPerUserGroup = {
          times: toNurturingReport(r.times),
          timesPerUser: r.timesPerUser.map(toNurturingReportPerUser),
          counts: toNurturingReport(r.counts),
          countsPerUser: r.countsPerUser.map(toNurturingReportPerUser),
        };
        return Success(report);
      }),
      catchError((err) => {
        return of(Failure(GeneralFailure.Unexpected));
      }),
    );
  }

  getPerUser(input: GetNurturingReportPerUserInput): Observable<Result<
    NurturingReportPerUser,
    typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.reportNurturingApi.getPerUser({
      duration: toDurationRequest(input.duration),
      nurturingTypeIDs: input.nurturingTypeIDs.map(id => id.value),
      nurturingStates: input.nurturingStates,
      userID: input.userID.value,
      pagination: {
        perPage: input.pagination.perPage,
        page: input.pagination.page,
      },
    })
    .pipe(
      map(r => {
        const report: NurturingReportPerUser = {
          times: toNurturingReportPerUser(r.times),
          counts: toNurturingReportPerUser(r.counts),
          nurturings: toNurturingListOfUser(r.nurturings),
        };
        return Success(report);
      }),
      catchError((err) => {
        return of(Failure(GeneralFailure.Unexpected));
      }),
    )
  }

  getPersonal(input: GetPersonalNurturingReportInput): Observable<Result<
    PersonalNurturingReport,
    typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.reportNurturingApi.getPersonal({
      duration: toDurationRequest(input.duration),
      nurturingTypeIDs: input.nurturingTypeIDs.map(id => id.value),
      nurturingStates: input.nurturingStates,
      pagination: {
        perPage: input.pagination.perPage,
        page: input.pagination.page,
      },
    })
    .pipe(
      map(r => {
        const report: PersonalNurturingReport = {
          times: toNurturingReport(r.times),
          counts: toNurturingReport(r.counts),
          nurturings: toNurturingListOfUser(r.nurturings),
        };
        return Success(report);
      }),
      catchError((err) => {
        return of(Failure(GeneralFailure.Unexpected));
      }),
    );
  }
}

const toDurationRequest = (
  duration: GetNurturingReportInput['duration']
): GetNurturingReportRequest['duration'] => {
  const type = duration.type;
  switch (type) {
    case 'MONTHLY':
      return {
        type: 'MONTHLY',
        startYearMonth: duration.startYearMonth.toFormat('yyyy-MM'),
        months: duration.months,
      };
    case 'DAILY':
      return {
        type: 'DAILY',
        startDate: duration.startDate.toFormat('yyyy-MM-dd HH:mm:ss'),
        days: duration.days,
      };
    default:
      const unexpected: never = type;
      throw new Error(`Unexpected duration type: ${unexpected}`);
  }
}

const toAssigneeRequest = (
  assignee: GetNurturingReportInput['assignee']
): GetNurturingReportRequest['assignee'] => {
  const type = assignee.type;
  switch (type) {
    case 'USER_GROUP':
      return {
        type: 'USER_GROUP',
        userGroupID: assignee.userGroupID.value,
      };
    case 'ALL_USERS':
      return {
        type: 'ALL_USERS',
      };
    default:
      const unexpected: never = type;
      throw new Error(`Unexpected assignee type: ${unexpected}`);
  }
}

const toNurturingReport = (res: NurturingReportResponse): NurturingReport => {
  return {
    perDate: res.perDate.map(r => ({
      date: DateTime.fromFormat(r.date, 'yyyy-MM-dd HH:mm:ss'),
      values: r.values.reduce((acc, v) => {
          acc[v.nurturingTypeID] = v.value;
          return acc;
        },
        {} as { [nurturingTypeID: string]: number }
      ),
      total: r.total,
    })),
    perType: res.perNurturingType.reduce((acc, v) => {
        acc[v.nurturingTypeID] = v.total;
        return acc;
      },
      {} as { [nurturingTypeID: string]: number }
    ),
    total: res.total,
  };
}

const toNurturingReportPerUser = (res: NurturingReportPerUserResponse): PerUserNurturingReport => {
  return {
    user: {
      id: new UserID(res.user.id),
      name: new DisplayName(res.user.name),
      isActive: res.user.isActive,
    },
    ...toNurturingReport(res),
  };
}

const toNurturingListOfUser = (res: NurturingListResponse): NurturingListOfUser => {
  return {
    totalCount: res.totalCount,
    results: res.results.map(r => ({
      nurturingID: new NurturingID(r.nurturingID),
      nurturingTitle: new NurturingTitle(r.nurturingTitle),
      clientID: new ClientID(r.clientID),
      clientName: new ClientName(r.clientName),
      nurturingTypeID: new NurturingTypeID(r.nurturingTypeID),
      nurturingTypeName: new NurturingTypeName(r.nurturingTypeName),
      nurturingStartDateTime: new NurturingStartDateTime(new Date(r.nurturingStartDateTime)),
      nurturingEndDateTime: new NurturingEndDateTime(new Date(r.nurturingEndDateTime)),
      nurturingTime: new NurturingMinutesDuration(r.nurturingTime),
      nurturingState: r.nurturingState,
    })),
  };
}
