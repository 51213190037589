import {ServerApi} from '#infrastructure/api/server-api';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActionTypeFactory, CreateActionTypeParam} from "app/model/action/action-type.factory";

@Injectable({ providedIn: 'root' })
export class ActionTypeFactoryImpl extends ActionTypeFactory {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  create(param: CreateActionTypeParam): Observable<void> {
    return this.serverApi.actionTypeApi.create(
      {
        name: param.name.value,
        description: param.description.value,
      }
    );
  }
}
