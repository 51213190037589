import {Injectable} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {ServerApi} from "#infrastructure/api/server-api";
import {catchError, concatMap, filter, tap} from "rxjs/operators";
import {SnackBarService} from "#application/services/snack-bar.service";
import {MessageDialogService} from "#application/services/message-dialog.service";
import {ConfirmService} from "#application/services/confirm.service";
import {AdoptedReasonCommandService, AdoptedReasonInputs} from "#application/services/adopted-reason-command.service";
import {AdoptedReasonID} from "app/model/adopted-reason/adopted-reason";
import {messageFn, Target} from "#utils/messages";

@Injectable({
  providedIn: 'root'
})
export class AdoptedReasonCommandServiceImpl extends AdoptedReasonCommandService {

  constructor(
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
    private readonly messageDialogService: MessageDialogService,
    private readonly confirmService: ConfirmService,
  ) {
    super();
  }

  add(inputs: AdoptedReasonInputs): Observable<void> {
    return this.serverApi.adoptedReasonApi
      .create({
        name: inputs.name.value,
        description: inputs.description.value,
      })
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(Target.ADOPTED_REASON, 'CREATE_SUCCESS').message);
        }),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '採択理由の登録'
          });
          return throwError(err);
        })
      );
  }

  update(id: AdoptedReasonID, inputs: AdoptedReasonInputs): Observable<void> {
    return this.serverApi.adoptedReasonApi
      .update(
        id.value,
        {
          name: inputs.name.value,
          description: inputs.description.value
        }
      )
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(Target.ADOPTED_REASON, 'UPDATE_SUCCESS').message);
        }),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '採択理由の更新'
          });
          return throwError(err);
        })
      );
  }

  delete(id: AdoptedReasonID): Observable<void> {
    return this.confirmService.confirm({
      title: messageFn(Target.ADOPTED_REASON, 'DELETE_CONFIRM').title,
      message: messageFn(Target.ADOPTED_REASON, 'DELETE_CONFIRM').message
    })
      .pipe(
        filter(r => r.isOK()),
        concatMap(() => this.serverApi.adoptedReasonApi.delete(id.value)),
        tap(() => {
          this.snackBarService.show(messageFn(Target.ADOPTED_REASON, 'DELETE_SUCCESS').message);
        }),
        catchError((err) => {
          this.messageDialogService.notice({
            title: messageFn(Target.ADOPTED_REASON, 'DELETE_ERROR').title,
            message: messageFn(Target.ADOPTED_REASON, 'DELETE_ERROR').message
          });
          return throwError(err);
        })
      );
  }

  sort(ids: AdoptedReasonID[]): Observable<void> {
    return this.serverApi.adoptedReasonApi
      .sort({
        ids: ids.map(id => id.value)
      })
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(Target.ADOPTED_REASON, 'SORT_SUCCESS').message);
        }),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '採択理由の並び替え'
          });
          return throwError(err);
        })
      );
  }


}
