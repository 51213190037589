import { Page, PageNavigateService } from '#application/services/page-navigate.service';
import { OnetimeTokenData, OnetimeTokenDataKey, SingleSignOnService } from '#application/services/single-sign-on.service';
import {Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'sn-single-sign-on',
  template: `
    <sn-top-bar [showMenuToggle]="false"></sn-top-bar>
    <div class="sso-container">
      <div class="loading-wrapper" *ngIf="hasNoError; else hasError">
        <div class="loading-message">サインイン処理中です…</div>
        <mat-progress-spinner
          [mode]="'indeterminate'"
        ></mat-progress-spinner>
      </div>
      <ng-template #hasError>
        <div class="error-message">
          サインインに失敗しました。
        </div>
      </ng-template>
    </div>
  `,
  styleUrls: ['./single-sign-on.component.scss']
})
export class SingleSignOnComponent implements OnInit {

  hasNoError = true;

  constructor(
    private readonly singleSignOnService: SingleSignOnService,
    public readonly route: ActivatedRoute,
    private readonly pageNavigateService: PageNavigateService,
  ) {}

  ngOnInit(): void {
    this._fetchTokenData().subscribe(data => {
      if (data === NoToken) {
        this.pageNavigateService.navigate(Page.ErrorNotFound);
      } else {
        this.singleSignOnService.signOn(data).subscribe(result => {
          if (result === true) {
            this.pageNavigateService.navigate(Page.ConfigUser);
          } else {
            this.hasNoError = false;
          }
        });
      }
    });
  }

  private _fetchTokenData(): Observable<OnetimeTokenData | NoToken> {
    const idKey: OnetimeTokenDataKey = 'x-sn-user-id';
    const tokenKey: OnetimeTokenDataKey = 'x-sn-sso-token';
    return this.route.queryParams
    .pipe(
      concatMap(params => {
        const userId = params[idKey];
        const ssoToken = params[tokenKey];
        if (userId === undefined || ssoToken === undefined) {
          return of<NoToken>(NoToken);
        }
        const data: OnetimeTokenData = {
          'x-sn-user-id': userId,
          'x-sn-sso-token': ssoToken,
        };
        return of(data);
      })
    );
  }
}

export const NoToken = 'NO_TOKEN';
export type NoToken = typeof NoToken;
