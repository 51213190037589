import {Component} from '@angular/core';

@Component({
  selector: 'sn-admin-container',
  template: `
    <div class="admin-container">
      <div class="title">
        <ng-content select="[titleArea]"></ng-content>
      </div>
      <div class="header">
        <ng-content select="[headerArea]"></ng-content>
      </div>
      <div class="contents">
        <ng-content select="[contentsArea]"></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./admin-container.component.scss'],
})
export class AdminContainerComponent {
}
