import { CustomFieldId } from 'app/model/custom-field/custom-field';
import { FieldType } from 'app/model/custom-field/field-type';

/**
 * フォームレイアウトで使用されるカテゴリ
 */
export const FormLayoutCategory = {
  CORPORATION_CLIENT: 'corporation_client',
  INDIVIDUAL_CLIENT: 'individual_client',
  OPPORTUNITY: 'opportunity',
  PRODUCT: 'product',
  PROPOSAL: 'proposal',
  OPPORTUNITY_GROUP: 'opportunity_group',
} as const;
export type FormLayoutCategory =
  typeof FormLayoutCategory[keyof typeof FormLayoutCategory];

/**
 * フォームレイアウトカテゴリ「法人顧客」用組み込み項目ID
 */
export const CorporationClientBuiltinFieldId = {
  CORPORATION_NAME: 'CORPORATION_NAME',
  CORPORATION_NAME_KANA: 'CORPORATION_NAME_KANA',
  CORPORATE_NUMBER: 'CORPORATE_NUMBER',
  CORPORATE_PHONE_NUMBER: 'CORPORATE_PHONE_NUMBER',
  CORPORATE_ADDRESS: 'CORPORATE_ADDRESS',
  ESTABLISHED: 'ESTABLISHED',
  NUMBER_OF_EMPLOYEES: 'NUMBER_OF_EMPLOYEES',
  INITIAL_CAPITAL: 'INITIAL_CAPITAL',
  FISCAL_YEAR_END: 'FISCAL_YEAR_END',
  WEBSITE: 'WEBSITE',
} as const;
export type CorporationClientBuiltinFieldId =
  typeof CorporationClientBuiltinFieldId[keyof typeof CorporationClientBuiltinFieldId];

/**
 * フォームレイアウトカテゴリ「個人顧客」用組み込み項目ID
 */
export const IndividualClientBuiltinFieldId = {
  LAST_NAME: 'LAST_NAME',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME_KANA: 'LAST_NAME_KANA',
  FIRST_NAME_KANA: 'FIRST_NAME_KANA',
  INDIVIDUAL_PHONE_NUMBER: 'INDIVIDUAL_PHONE_NUMBER',
  INDIVIDUAL_ADDRESS: 'INDIVIDUAL_ADDRESS',
  INDIVIDUAL_SNS1: 'INDIVIDUAL_SNS1',
  INDIVIDUAL_SNS2: 'INDIVIDUAL_SNS2',
} as const;
export type IndividualClientBuiltinFieldId =
  typeof IndividualClientBuiltinFieldId[keyof typeof IndividualClientBuiltinFieldId];

/**
 * フォームレイアウトカテゴリ「商談」用組み込み項目ID
 */
export const OpportunityBuiltinFieldId = {
  OPPORTUNITY_TITLE: 'OPPORTUNITY_TITLE',
  EXPECTED_AMOUNT: 'EXPECTED_AMOUNT',
  ASSIGNEE: 'ASSIGNEE',
  EXPECTED_CLOSE_DATE: 'EXPECTED_CLOSE_DATE',
  OPPORTUNITY_ACCRUAL_DATE: 'OPPORTUNITY_ACCRUAL_DATE',
} as const;
export type OpportunityBuiltinFieldId =
  typeof OpportunityBuiltinFieldId[keyof typeof OpportunityBuiltinFieldId];

/**
 * フォームレイアウトカテゴリ「商品」用組み込み項目ID
 */
export const ProductBuiltinFieldId = {
  PRODUCT_NAME: 'PRODUCT_NAME',
  PRODUCT_PRICE: 'PRODUCT_PRICE',
  PRODUCT_QUANTITY_UNIT: 'PRODUCT_QUANTITY_UNIT',
} as const;
export type ProductBuiltinFieldId =
  typeof ProductBuiltinFieldId[keyof typeof ProductBuiltinFieldId];

/**
 * フォームレイアウトカテゴリ「提案」用組み込み項目ID
 */
export const ProposalBuiltinFieldId = {
  PROPOSAL_TITLE: 'PROPOSAL_TITLE',
} as const;
export type ProposalBuiltinFieldId =
  typeof ProposalBuiltinFieldId[keyof typeof ProposalBuiltinFieldId];

/**
 * フォームレイアウトカテゴリ「商談グループ」用組み込み項目ID
 */
export const OpportunityGroupBuiltinFieldId = {
  OPPORTUNITY_GROUP_TITLE: 'OPPORTUNITY_GROUP_TITLE',
} as const;
export type OpportunityGroupBuiltinFieldId =
  typeof OpportunityGroupBuiltinFieldId[keyof typeof OpportunityGroupBuiltinFieldId];

/**
 * 組み込み項目のID
 */
export type BuiltinFieldId =
  | CorporationClientBuiltinFieldId
  | IndividualClientBuiltinFieldId
  | OpportunityBuiltinFieldId
  | ProductBuiltinFieldId
  | ProposalBuiltinFieldId
  | OpportunityGroupBuiltinFieldId;

export namespace BuiltinFieldId {
  export function valueOf(value: string, category: FormLayoutCategory): BuiltinFieldId {
    switch (category) {
      case FormLayoutCategory.CORPORATION_CLIENT:
        return CorporationClientBuiltinFieldId[value as keyof typeof CorporationClientBuiltinFieldId];
      case FormLayoutCategory.INDIVIDUAL_CLIENT:
        return IndividualClientBuiltinFieldId[value as keyof typeof IndividualClientBuiltinFieldId];
      case FormLayoutCategory.OPPORTUNITY:
        return OpportunityBuiltinFieldId[value as keyof typeof OpportunityBuiltinFieldId];
      case FormLayoutCategory.PRODUCT:
        return ProductBuiltinFieldId[value as keyof typeof ProductBuiltinFieldId];
      case FormLayoutCategory.PROPOSAL:
        return ProposalBuiltinFieldId[value as keyof typeof ProposalBuiltinFieldId];
      case FormLayoutCategory.OPPORTUNITY_GROUP:
        return OpportunityGroupBuiltinFieldId[value as keyof typeof OpportunityGroupBuiltinFieldId];
      default:
        const unexpected: never = category;
        throw Error(`${unexpected} is unexpected value`);
    }
  }

  export const value = {
    ...CorporationClientBuiltinFieldId,
    ...IndividualClientBuiltinFieldId,
    ...OpportunityBuiltinFieldId,
    ...ProductBuiltinFieldId,
    ...ProposalBuiltinFieldId,
    ...OpportunityGroupBuiltinFieldId,
  } as const;
}

/**
 * フォームレイアウト幅
 */
export const FormLayoutWidth = {
  HALF: 'HALF',
  FULL: 'FULL',
} as const;

export type FormLayoutWidth =
  typeof FormLayoutWidth[keyof typeof FormLayoutWidth];

/**
 * フォームレイアウトアイテム種別
 */
export const FormLayoutItemType = {
  SECTION_TITLE: 'SECTION_TITLE',
  BUILTIN_FIELD: 'BUILTIN_FIELD',
  CUSTOM_FIELD: 'CUSTOM_FIELD',
} as const;

export type FormLayoutItemType =
  typeof FormLayoutItemType[keyof typeof FormLayoutItemType];

/**
 * フォームレイアウトのID
 */
export class FormLayoutId {
  constructor(public readonly value: string) {
  }

  equals(id: FormLayoutId): boolean {
    return this.value === id.value;
  }
}

/**
 * フォームレイアウトの名称
 */
export class FormLayoutName {
  static readonly MAX_LENGTH = 30;

  constructor(public readonly value: string) {
  }
}

/**
 * レイアウト項目
 */
abstract class FormLayoutItem {
  constructor(
    public readonly type: FormLayoutItemType,
    public readonly width: FormLayoutWidth
  ) {
  }

  isFullSize() {
    return this.width === FormLayoutWidth.FULL;
  }

  isHalfSize() {
    return this.width === FormLayoutWidth.HALF;
  }
}

/**
 * セクションのタイトル
 */
export class SectionTitle {
  static readonly MAX_LENGTH = 30;

  constructor(public readonly value: string) {
  }
}

/**
 * セクション
 */
export class SectionTitleItem extends FormLayoutItem {
  constructor(
    public readonly title: SectionTitle,
  ) {
    super(FormLayoutItemType.SECTION_TITLE, FormLayoutWidth.FULL);
  }
}

/**
 * 項目
 */
export class CustomFieldItem extends FormLayoutItem {
  constructor(
    public readonly width: FormLayoutWidth,
    public readonly fieldId: CustomFieldId
  ) {
    super(FormLayoutItemType.CUSTOM_FIELD, width);
  }
}

/**
 * 項目レイアウト
 */
export class FormLayout {
  constructor(
    public readonly id: FormLayoutId,
    public readonly name: FormLayoutName,
    public readonly items: (SectionTitleItem | BuiltinFieldItem | CustomFieldItem)[]
  ) {
  }
}

/**
 * 組み込み項目レイアウト
 */
export class BuiltinFieldItem extends FormLayoutItem {
  constructor(
    public readonly width: FormLayoutWidth,
    public readonly builtinFieldId: BuiltinFieldId
  ) {
    super(FormLayoutItemType.BUILTIN_FIELD, width);
  }
}

/**
 * 組み込み項目
 */
export class BuiltinField {
  constructor(
    public readonly id: BuiltinFieldId,
    public readonly fieldType: FieldType,
    public readonly needsToDisplay: boolean,
  ) {
  }
}

/**
 * デフォルトフォームレイアウト
 */
export class DefaultFormLayout {
  constructor(public readonly items: (SectionTitleItem | BuiltinFieldItem)[]) {
  }
}
