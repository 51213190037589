import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

export type CreateRestAuthTokenRequest = {
  name: string,
  subjectID: string,
};

export type UpdateRestAuthTokenRequest = {
  name: string,
  subjectID: string,
};

export type CreateRestAuthTokenResponse = {
  tokenString: string,
};

export type ListRestAuthTokenRequest = {
  pagination: {
    perPage: number,
    page: number,
  },
};

export type ListRestAuthTokenResponse = {
  totalCount: number,
  results: {
    id: string,
    name: string,
    subjectID?: string,
    subjectLoginID?: string,
    subjectName?: string,
    createdAt: string,
    keys:
      {
        tailRandomString: string,
        createdAt: string,
      } [],
  } [],
};

export type CreateRestAuthTokenKeyResponse = {
  tokenString: string,
};

export type GetRestAuthTokenResponse = {
  id: string,
  name: string,
  subjectID?: string,
  subjectLoginID?: string,
  subjectName?: string,
};

export class RestAuthTokenApi {
  constructor(private readonly http: HttpClient) {
  }

  /** トークン生成 */
  create(request: CreateRestAuthTokenRequest): Observable<CreateRestAuthTokenResponse> {
    return this.http.post<CreateRestAuthTokenResponse>(`/api/rest-auth-tokens`, request);
  }

  /** トークン更新 */
  update(id: string, request: UpdateRestAuthTokenRequest): Observable<void> {
    return this.http.put<void>(`/api/rest-auth-tokens/${id}`, request);
  }

  /** トークン一覧取得 */
  list(request: ListRestAuthTokenRequest): Observable<ListRestAuthTokenResponse> {
    return this.http.post<ListRestAuthTokenResponse>(`/api/rest-auth-tokens/list`, request);
  }

  /** トークン削除 */
  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/rest-auth-tokens/${id}`);
  }

  /** トークンキー生成 */
  createKey(id: string): Observable<CreateRestAuthTokenKeyResponse> {
    return this.http.post<CreateRestAuthTokenKeyResponse>(`/api/rest-auth-tokens/${id}`, {});
  }

  /** トークンキー削除 */
  deleteKey(id: string, key: string): Observable<void> {
    return this.http.delete<void>(`/api/rest-auth-tokens/${id}/${key}`, {});
  }

  /** トークン取得 */
  get(id: string): Observable<GetRestAuthTokenResponse> {
    return this.http.get<GetRestAuthTokenResponse>(`/api/rest-auth-tokens/${id}`);
  }
}
