import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { MbScheduleMinutesItem, MbScheduleDaysItem } from "app/model/schedule/mobile-schedule";
import { PcScheduleMinutesItem, PcScheduleDaysItem } from "app/model/schedule/pc-schedule";
import { UserID, DisplayName, LoginID } from "app/model/user/user";
import { DateTime } from "luxon";
import { Observable } from "rxjs";

export type GetScheduleInput = Readonly<{
  targetDate: DateTime,
  userIDs: UserID[],
}>

export type GetGroupScheduleInput = Readonly<{
  targetDate: DateTime,
  users: {
    id: UserID,
    loginID: LoginID,
    name: DisplayName,
  }[],
}>

/**
 * PC版個人スケジュール一覧
 */
export type PcIndividualScheduleItemsV2 = {
  minutes: {
    date: DateTime,
    items: PcScheduleMinutesItem[],
  }[],
  days: PcScheduleDaysItem[]
}

/**
 * PC版グループスケジュール一覧
 */
export type PcGroupScheduleItemsV2 = ReadonlyArray<PcGroupScheduleItemV2>
export type PcGroupScheduleItemV2 = PcIndividualScheduleItemsV2 & {
  user: {
    id: UserID,
    name: DisplayName,
  },
}

export type MbScheduleItemsByDateV2 = {
  date: DateTime,
  minutes: MbScheduleMinutesItem[],
  days: MbScheduleDaysItem[]
}

/**
 * モバイル版個人スケジュール一覧
 */
export class MbScheduleItemsV2 {
  constructor(
    public readonly list: MbScheduleItemsByDateV2[]
  ) {}

  getFirstDate(): DateTime {
    return this.list[0].date;
  }

  getLastDate(): DateTime {
    return this.list.slice(-1)[0].date;
  }
}

export abstract class ScheduleV2QueryService {
  abstract individualWeek(input: GetScheduleInput): Observable<Result<
    PcIndividualScheduleItemsV2,
    typeof GeneralFailure.Unexpected
  >>
  abstract individualMonth(input: GetScheduleInput): Observable<Result<
    PcIndividualScheduleItemsV2,
    typeof GeneralFailure.Unexpected
  >>
  abstract groupDay(input: GetGroupScheduleInput): Observable<Result<
    PcGroupScheduleItemsV2,
    typeof GeneralFailure.Unexpected
  >>
  abstract groupWeek(input: GetGroupScheduleInput): Observable<Result<
    PcGroupScheduleItemsV2,
    typeof GeneralFailure.Unexpected
  >>
  abstract mobile(input: GetScheduleInput): Observable<Result<
    MbScheduleItemsV2,
    typeof GeneralFailure.Unexpected
  >>
  abstract day(input: GetScheduleInput): Observable<Result<
    PcIndividualScheduleItemsV2,
    typeof GeneralFailure.Unexpected
  >>
}