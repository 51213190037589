import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

type ProposalTypeData = {
  name: string,
  description: string,
  productDetailType: 'SINGLE' | 'MULTIPLE',
  formLayoutID?: string,
}

export type CreateProposalTypeRequest = ProposalTypeData;

export type UpdateProposalTypeRequest = {
  name: string,
  description: string,
  formLayoutID?: string,
};

export type ListProposalTypeResponse = {
  id: string,
  name: string,
  description: string,
  productDetailType: 'SINGLE' | 'MULTIPLE'
  formLayoutID?: string,
  formLayoutName?: string,
}[]

export type GetProposalTypeResponse = {
  id: string
} & ProposalTypeData

export type SortProposalTypeRequest = {
  ids: string[]
}

export class ProposalTypeApi {
  constructor(private readonly http: HttpClient) {}

  create(request: CreateProposalTypeRequest): Observable<void> {
    return this.http.post<void>(`/api/proposal-types`, request);
  }

  list(): Observable<ListProposalTypeResponse> {
    return this.http.get<ListProposalTypeResponse>(`/api/proposal-type-list`);
  }

  get(id: string): Observable<GetProposalTypeResponse> {
    return this.http.get<GetProposalTypeResponse>(`/api/proposal-types/${id}`);
  }

  update(id: string, request: UpdateProposalTypeRequest): Observable<void> {
    return this.http.put<void>(`/api/proposal-types/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/proposal-types/${id}`);
  }

  sort(request: SortProposalTypeRequest): Observable<void> {
    return this.http.patch<void>(`/api/proposal-types/sort`, request);
  }
}
