import {environment} from 'environments/environment';

/**
 * neverであることをチェック
 * @param _value チェックしたい値
 * @param alternative 条件が満たされなかったときの代替値
 * @returns 代替値
 */
export const checkNever = <T>(_value: never, alternative: T): T => {
  if (!environment.production) {
    console.error('checkNever failed');
    debugger;
  }
  return alternative;
};

export function assert(condition: unknown, msg?: string): asserts condition {
  if (!condition) {
    throw new AssertionError(msg);
  }
}

export function assertIsDefined<T>(value: T, msg?: string): asserts value is NonNullable<T> {
  const message = msg !== undefined ? msg : `Expected 'value' to be defined, but received ${value}`;
  return assert(value !== undefined && value !== null, message);
}

export class AssertionError extends Error {

}
