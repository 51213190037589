import { MessageDialogService } from '#application/services/message-dialog.service';
import { GetNurturingHearingSettingOutputs, NurturingHearingSettingQueryService } from '#application/services/nurturing-hearing-setting-query.service';
import { CustomFieldDescription, CustomFieldId, CustomFieldName, CustomFieldNumberUnit, CustomFieldNumberValue, NumberSettings, SelectItemValue, SelectSettings } from 'app/model/custom-field/custom-field';
import { FieldType } from 'app/model/custom-field/field-type';
import { NurturingHearingItem } from 'app/model/nurturing-hearing/nurturing-hearing';
import { NurturingTypeID } from 'app/model/nurturing-type/nurturing-type';
import { ServerApi } from '#infrastructure/api/server-api';
import { NurturingHearingItemResponse } from '#infrastructure/api/server-nurturing-hearing-setting-api';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import {LocalizedNameService} from "#application/services/localized-name.service";
import {LocalizeKey} from "#application/services/authorize.service";

@Injectable({ providedIn: 'root' })
export class NurturingHearingSettingQueryServiceImpl implements NurturingHearingSettingQueryService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _messageDialogService = inject(MessageDialogService);
  private readonly _localizedNameService = inject(LocalizedNameService);

  get(nurturingTypeID: NurturingTypeID): Observable<GetNurturingHearingSettingOutputs> {
    const nurturingAlias = this._localizedNameService.get(LocalizeKey.NURTURING);
    return this._serverApi.nurturingHearingSettingApi
      .get(nurturingTypeID.value)
      .pipe(
        map(res => ({
          nurturingTypeID: new NurturingTypeID(res.nurturingTypeID),
          beforeHearingItems: res.beforeHearingItems.map(this._convert),
          afterHearingItems: res.afterHearingItems.map(this._convert),
        })),
        catchError((err) => {
          this._messageDialogService.notice({
            errorTarget: `${nurturingAlias}ヒアリングの取得`,
          });
          return throwError(() => err);
        }),
      );
  }

  private _convert(res: NurturingHearingItemResponse): NurturingHearingItem {
    return {
      id: new CustomFieldId(res.customFieldID),
      name: new CustomFieldName(res.customFieldName),
      description: new CustomFieldDescription(res.customFieldDescription),
      fieldType: res.customFieldType as FieldType,
      required: res.required,
      selectSetting: res.selectSetting
        ? new SelectSettings(
          res.selectSetting.multiple,
          res.selectSetting.allowInput,
          res.selectSetting.options.map(option => {
            return new SelectItemValue(option)
          }))
        : undefined,
      numberSetting: res.numberSetting
        ? new NumberSettings(
          new CustomFieldNumberValue(res.numberSetting.min),
          new CustomFieldNumberValue(res.numberSetting.max),
          new CustomFieldNumberValue(res.numberSetting.defaultValue),
          new CustomFieldNumberUnit(res.numberSetting.unit),
        )
        : undefined,
    };
  }
}
