import { ClientCategory, ClientTypeName } from 'app/model/client-info-type/client-type';
import { ClientRelationshipID } from 'app/model/client-relationship/client-relationship';
import { ClientRelationshipNoteContent, ClientRelationshipNoteID } from 'app/model/client-relationship/client-relationship-note/client-relationship-note';
import { ClientID, ClientName, ClientNumber } from 'app/model/client/client';
import { LoginID } from 'app/model/user/user';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

export interface ClientRelationshipsListItem {
  id: ClientRelationshipID;
  relevantClientID: ClientID;
  relevantClientName: ClientName;
  relevantClientNumber: ClientNumber;
  relevantClientTypeName: ClientTypeName;
  category: ClientCategory;
}

export type ClientRelationshipsList = ClientRelationshipsListItem[];

export interface ClientRelationshipNotesListItem {
  noteID: ClientRelationshipNoteID;
  authorName: LoginID;
  content: ClientRelationshipNoteContent;
  editedDateTime: DateTime;
  isEdited: boolean;
}

export type ClientRelationshipNotesList = ClientRelationshipNotesListItem[];

export abstract class ClientRelationshipQueryService {
  abstract getClientRelationships(clientID: ClientID): Observable<ClientRelationshipsList>;

  abstract getClientRelationshipNotes(clientID: ClientID, relationshipID: ClientRelationshipID): Observable<ClientRelationshipNotesList>;
}
