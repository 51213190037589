import { FollowUpActionID } from "app/model/follow-up-action/follow-up-action";
import { FollowUpActionRepository, SaveFollowUpActionDto } from "app/model/follow-up-action/follow-up-action.repository";
import { ServerApi } from "#infrastructure/api/server-api";
import { SaveFollowUpActionRequest } from "#infrastructure/api/server-follow-up-action-api";
import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { Observable } from "rxjs";

const formatDate = (date: Date, format: string): string => {
  return DateTime.fromJSDate(date).toFormat(format);
};

@Injectable({ providedIn: 'root' })
export class FollowUpActionRepositoryImpl implements FollowUpActionRepository {
  constructor(private readonly serverApi: ServerApi) {}

  delete(followUpActionID: FollowUpActionID): Observable<void> {
    return this.serverApi.followUpActionApi.delete(followUpActionID.value);
  }

  save(dto: SaveFollowUpActionDto): Observable<void> {
    const due = formatDate(dto.due.value, 'yyyy-MM-dd HH:mm:ss');
    const request: SaveFollowUpActionRequest = {
      opportunityID: dto.opportunityID.value,
      due,
      followUpActionTypeID: dto.followUpActionTypeID.value,
      assignee: dto.assigneeID.value,
      contentsSummary: dto.summary.value,
      contentsDetail: dto.detail.value,
    };
    return this.serverApi.followUpActionApi.save(dto.id.value, request);
  }

  done(followUpActionID: FollowUpActionID): Observable<void> {
    return this.serverApi.followUpActionApi.done(followUpActionID.value);
  }

  unDone(followUpActionID: FollowUpActionID): Observable<void> {
    return this.serverApi.followUpActionApi.unDone(followUpActionID.value);
  }
}
