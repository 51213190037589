import { CoachingData, CoachingReplyInputs, CoachingService, ContinueCoachingData, StartCoachingInputs } from "#application/services/coaching.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { Page, PageNavigateService } from "#application/services/page-navigate.service";
import { CoachingID, CoachingStatus } from "app/model/coaching/coaching";
import { HintContent, HintID, HintReview, HintTitle } from "app/model/hint/hint";
import { InsightAnswer, InsightAnswerID, InsightAnswerSelectionType, InsightAnswerTextType, InsightID, InsightQuestion, InsightSupplement, InsightSupplementTitle } from "app/model/insight/insight";
import { ServerApi } from "#infrastructure/api/server-api";
import { ContinueCoachingResponse, ReplyCoachingRequest, TalkCoachingRequest } from "#infrastructure/api/server-coaching-api";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class CoachingServiceImpl extends CoachingService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly messageDialogService: MessageDialogService,
    private readonly pageNavigateService: PageNavigateService,
  ) {
    super();
  }

  start(inputs: StartCoachingInputs): Observable<ContinueCoachingData | void> {
    const request: TalkCoachingRequest = {
      actionID: inputs.actionID.value,
      coachingTiming: inputs.coachingTiming
    };
    return this.serverApi.coachingApi.talk(request).pipe(
      map(data => {
        const status = data.status;
        switch (status) {
          case 'FINISHED':
            this.pageNavigateService.navigate(Page.Coaching);
            this.messageDialogService.notice(
              {
                message: 'このコーチングはすでに完了しています。'
              }
            );
            break;
          case 'CONTINUE':
            return convertContinueData(data);
          default:
            const unexpected: never = status;
            throw Error(`${unexpected}は予期せぬコーチングステータスです。`);
        }
      }),
      catchError(e => {
        this.messageDialogService.notice({ errorTarget: 'コーチングの開始' });
        return throwError(e);
      })
    );
  }

  reply(coachingID: CoachingID, inputs: CoachingReplyInputs): Observable<CoachingData> {
    let request: ReplyCoachingRequest;
    const type = inputs.type;
    switch (type)  {
      case InsightAnswerTextType.value:
        request = {
          answer: {
            text: {
              value: inputs.value.value,
            },
          },
        };
        break;
      case InsightAnswerSelectionType.value:
        request = {
          answer: {
            selection: {
              values: inputs.value.map(v => v.value),
            },
          },
        };
        break;
      default:
        const unexpected: never = type;
        throw Error(`${unexpected}は予期せぬ回答種別です。`);
    }
    return this.serverApi.coachingApi.reply(coachingID.value, request).pipe(
      map(data => {
        switch (data.status) {
          case 'FINISHED':
            return {
              status: CoachingStatus.FINISHED,
            }
          case 'CONTINUE':
            return convertContinueData(data);
          default:
            throw Error;
        }
      }),
      catchError(e => {
        this.messageDialogService.notice({ errorTarget: '回答' });
        return throwError(e);
      })
    );
  }

  undo(coachingID: CoachingID): Observable<ContinueCoachingData> {
    return this.serverApi.coachingApi.undo(coachingID.value).pipe(
      map(data => convertContinueData(data)),
      catchError(e => {
        this.messageDialogService.notice({ errorTarget: 'コーチングを1つ前に戻す処理' });
        return throwError(e);
      })
    );
  }
}

const convertContinueData =
(data: ContinueCoachingResponse): ContinueCoachingData => {
  return {
    status: CoachingStatus.CONTINUE,
    coachingID: new CoachingID(data.coachingID),
    index: data.index,
    insight: convertInsightData(data.insight),
  }
};

const convertInsightData =
(ins: ContinueCoachingResponse['insight']): ContinueCoachingData['insight'] => {
  const answerType = ins.answerType;
  switch (answerType) {
    case InsightAnswerTextType.value:
      return {
        id: new InsightID(ins.id),
        question: new InsightQuestion(ins.question),
        supplementTitle: new InsightSupplementTitle(ins.supplementTitle),
        supplement: new InsightSupplement(ins.supplement),
        answerType: InsightAnswerTextType.value
      };
    case InsightAnswerSelectionType.value:
      return {
        id: new InsightID(ins.id),
        question: new InsightQuestion(ins.question),
        supplementTitle: new InsightSupplementTitle(ins.supplementTitle),
        supplement: new InsightSupplement(ins.supplement),
        answerType: InsightAnswerSelectionType.value,
        multiple: ins.selection.multiple,
        answerOptions: ins.selection.answerOptions.map(v => ({
          id: new InsightAnswerID(v.id),
          answer: new InsightAnswer(v.answer),
          hint: v.hint === undefined
            ? undefined
            : {
              id: new HintID(v.hint.id),
              title: new HintTitle(v.hint.title),
              content: new HintContent(v.hint.content),
              review: HintReview.create(v.hint.review),
            },
        }))
      };
    default:
      const unexpected: never = answerType;
      throw Error(`${unexpected}は予期せぬ回答種別です。`);
  }
};
