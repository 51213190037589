import { Observable } from 'rxjs';

export interface ConfirmData {
  title?: string;
  message: string;
}

/**
 * 確認結果
 */
enum Result {
  OK,
  Cancel,
}

/**
 * 確認結果
 */
export class ConfirmResult {
  private constructor(private readonly result: Result) {}
  isOK() {
    return this.result === Result.OK;
  }
  isCancel() {
    return this.result === Result.Cancel;
  }
  static resultOk(): ConfirmResult {
    return new ConfirmResult(Result.OK);
  }

  static resultCancel(): ConfirmResult {
    return new ConfirmResult(Result.Cancel);
  }
}

/**
 * 確認ダイアログ表示サービス
 */
export abstract class ConfirmService {
  /**
   * 確認ダイアログ表示
   * @param data 確認ダイアログ表示用データ
   */
  abstract confirm(data: ConfirmData): Observable<ConfirmResult>;
}
