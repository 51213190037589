/**
 * 受注理由ID
 */
export class WonReasonID {
  constructor(public readonly value: string) {}
}

/**
 * 受注理由名
 */
export class WonReasonName {
  static readonly MAX_LENGTH = 30;

  constructor(public readonly value: string) {}
}

/**
 * 受注理由説明
 */
export class WonReasonDescription {
  static readonly MAX_LENGTH = 100;

  constructor(public readonly value: string) {}
}

/**
 * 受注理由
 */
export type WonReason = Readonly<{
  id: WonReasonID,
  name: WonReasonName,
  description: WonReasonDescription,
}>

/**
 * 受注理由リスト
 */
export type WonReasonList = WonReason[];
