export class ContactHistoryTemplate {
  constructor(
    public readonly id: ContactHistoryTemplateID,
    public readonly name: ContactHistoryTemplateName,
    public readonly content: ContactHistoryTemplateContent,
  ) {}
}

export class ContactHistoryTemplateID {
  constructor(public readonly value: string) {}
}

export class ContactHistoryTemplateName {
  constructor(public readonly value: string) {}
}

export class ContactHistoryTemplateContent {
  constructor(public readonly value: string) {}
}
