import { LocalizeKey } from "#application/services/authorize.service";
import { LocalizedNameService } from "#application/services/localized-name.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { NurturingTypeQueryService } from "#application/services/nurturing-type-query.service";
import {
  NurturingType,
  NurturingTypeDescription,
  NurturingTypeID,
  NurturingTypeName
} from "app/model/nurturing-type/nurturing-type";
import { ServerApiError, ServerApiErrorCode } from "#infrastructure/api/error";
import { ServerApi } from "#infrastructure/api/server-api";
import { NurturingTypeResponse } from "#infrastructure/api/server-nurturing-type-api";
import { messageFn } from "#utils/messages";
import { Injectable, inject } from "@angular/core";
import { Observable, catchError, map, throwError } from "rxjs";

@Injectable({ providedIn: 'root' })
export class NurturingTypeQueryServiceImpl implements NurturingTypeQueryService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _messageDialogService = inject(MessageDialogService);
  private readonly _localizedNameService = inject(LocalizedNameService);

  list(): Observable<NurturingType[]> {
    const nurturingType = `${this._localizedNameService.get(LocalizeKey.NURTURING)}タイプ`;
    return this._serverApi.nurturingTypeApi
      .list()
      .pipe(
        map(list => list.map(type => this._toNurturingType(type))),
        catchError((err) => {
          this._messageDialogService.notice({
            errorTarget: `${nurturingType}一覧の取得`,
          });
          return throwError(() => err);
        }),
      );
  }

  get(nurturingTypeID:NurturingTypeID): Observable<NurturingType> {
    const nurturingType = `${this._localizedNameService.get(LocalizeKey.NURTURING)}タイプ`;
    return this._serverApi.nurturingTypeApi
      .get(nurturingTypeID.value)
      .pipe(
        map(type => this._toNurturingType(type)),
        catchError((err) => {
          if (err instanceof ServerApiError && err.code == ServerApiErrorCode.NotFound) {
            this._messageDialogService.notice({
              title: messageFn(`${nurturingType}の取得`, 'FAILED').message,
              message: `${nurturingType}は、すでに削除されています。`,
            });
            return throwError(() => err);
          }
          this._messageDialogService.notice({
            errorTarget: `${nurturingType}の取得`,
          });
          return throwError(() => err);
        }),
      );
  }

  private _toNurturingType(type: NurturingTypeResponse): NurturingType {
    return {
      id: new NurturingTypeID(type.id),
      name: new NurturingTypeName(type.name),
      description: new NurturingTypeDescription(type.description),
    };
  }
}
