import { Brand } from "app/lib/brand/brand";
import { NumericDaysOfWeek } from "./numeric-days-of-week";

export type CalendarSettings = Readonly<{
  monday: CalendarSettingEnableDayOfWeek;
  tuesday: CalendarSettingEnableDayOfWeek;
  wednesday: CalendarSettingEnableDayOfWeek;
  thursday: CalendarSettingEnableDayOfWeek;
  friday: CalendarSettingEnableDayOfWeek;
  saturday: CalendarSettingEnableDayOfWeek;
  sunday: CalendarSettingEnableDayOfWeek;
  startTime: CalendarSettingStartTime;
  closeTime: CalendarSettingCloseTime;
}>;
export function CalendarSettings(value: {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  startTime: number;
  closeTime: number;
}): CalendarSettings {
  return {
    monday: CalendarSettingEnableDayOfWeek(value.monday),
    tuesday: CalendarSettingEnableDayOfWeek(value.tuesday),
    wednesday: CalendarSettingEnableDayOfWeek(value.wednesday),
    thursday: CalendarSettingEnableDayOfWeek(value.thursday),
    friday: CalendarSettingEnableDayOfWeek(value.friday),
    saturday: CalendarSettingEnableDayOfWeek(value.saturday),
    sunday: CalendarSettingEnableDayOfWeek(value.sunday),
    startTime: CalendarSettingStartTime(value.startTime),
    closeTime: CalendarSettingCloseTime(value.closeTime),
  };
}
export namespace CalendarSettings {
  export function fromNumericDaysOfWeek(
    numericDaysOfWeek: NumericDaysOfWeek,
    startTime: number,
    closeTime: number,
  ): CalendarSettings {
    return {
      monday: CalendarSettingEnableDayOfWeek(numericDaysOfWeek.includes(1)),
      tuesday: CalendarSettingEnableDayOfWeek(numericDaysOfWeek.includes(2)),
      wednesday: CalendarSettingEnableDayOfWeek(numericDaysOfWeek.includes(3)),
      thursday: CalendarSettingEnableDayOfWeek(numericDaysOfWeek.includes(4)),
      friday: CalendarSettingEnableDayOfWeek(numericDaysOfWeek.includes(5)),
      saturday: CalendarSettingEnableDayOfWeek(numericDaysOfWeek.includes(6)),
      sunday: CalendarSettingEnableDayOfWeek(numericDaysOfWeek.includes(7)),
      startTime: CalendarSettingStartTime(startTime),
      closeTime: CalendarSettingCloseTime(closeTime),
    };
  }
}

export type CalendarSettingEnableDayOfWeek = Brand<boolean, 'CalendarSettingEnableDayOfWeek'>;
export function CalendarSettingEnableDayOfWeek(value: boolean) {
  return value as CalendarSettingEnableDayOfWeek;
}

export type CalendarSettingStartTime = Brand<number, 'CalendarSettingStartTime'>;
export function CalendarSettingStartTime(value: number) {
  return value as CalendarSettingStartTime;
}

export type CalendarSettingCloseTime = Brand<number, 'CalendarSettingCloseTime'>;
export function CalendarSettingCloseTime(value: number) {
  return value as CalendarSettingCloseTime;
}