import { OpportunityGroupWorkflowQueryService } from '#application/services/opportunity-group-workflow-query.service';
import { OpportunityGroupStatusID, OpportunityGroupStatusName } from 'app/model/opportunity-group/opportunity-group-status';
import { OpportunityGroupWorkflow, OpportunityGroupWorkflowDescription, OpportunityGroupWorkflowID, OpportunityGroupWorkflowName } from 'app/model/opportunity-group/opportunity-group-workflow';
import { ServerApi } from '#infrastructure/api/server-api';
import { GetOpportunityGroupWorkflowResponse } from '#infrastructure/api/server-opportunity-group-workflow-api';
import { Injectable, inject } from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpportunityGroupWorkflowQueryServiceImpl implements OpportunityGroupWorkflowQueryService {
  private readonly _serverApi = inject(ServerApi);

  list(): Observable<Result<
    OpportunityGroupWorkflow[],
    typeof GeneralFailure.Unexpected
  >> {
    return this._serverApi.opportunityGroupWorkflowApi
      .list()
      .pipe(
        map(res => Success(res.map(v => this._toDomain(v)))),
        catchError(() => of(Failure(GeneralFailure.Unexpected))),
      );
  }

  get(id: OpportunityGroupWorkflowID): Observable<Result<
    OpportunityGroupWorkflow,
    typeof GeneralFailure.Unexpected
  >> {
    return this._serverApi.opportunityGroupWorkflowApi
      .get(id)
      .pipe(
        map(res => Success(this._toDomain(res))),
        catchError(() => of(Failure(GeneralFailure.Unexpected))),
      );
  }

  private _toDomain(response: GetOpportunityGroupWorkflowResponse): OpportunityGroupWorkflow {
    return {
      id: OpportunityGroupWorkflowID(response.id),
      name: OpportunityGroupWorkflowName(response.name),
      description: OpportunityGroupWorkflowDescription(response.description),
      statuses: response.statuses
        .map(v => ({
          id: OpportunityGroupStatusID(v.id),
          name: OpportunityGroupStatusName(v.name),
        })),
    }
  }
}
