import { MessageDialogService } from "#application/services/message-dialog.service";
import { FollowUpActionListOfOpportunity, OpportunityFollowUpActionQueryService } from "#application/services/opportunity-follow-up-action-query.service";
import { FollowUpActionDue, FollowUpActionID, FollowUpActionIsDone, FollowUpActionSummary } from "app/model/follow-up-action/follow-up-action";
import { FollowUpActionTypeID, FollowUpActionTypeName } from "app/model/follow-up-action/follow-up-action-type";
import { OpportunityID, OpportunityName } from "app/model/opportunity/opportunity";
import { DisplayName, UserID } from "app/model/user/user";
import { ServerApi } from "#infrastructure/api/server-api";
import { Target } from "#utils/messages";
import { Injectable, inject } from "@angular/core";
import { DateTime } from "luxon";
import { Observable, catchError, map, throwError } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class OpportunityFollowUpActionQueryServiceImpl implements OpportunityFollowUpActionQueryService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _messageDialogService = inject(MessageDialogService);

  list(opportunityID: OpportunityID): Observable<FollowUpActionListOfOpportunity> {
    return this._serverApi.opportunityFollowUpActionApi.list(opportunityID.value)
      .pipe(
        map(res => res.map(data => ({
          id: new FollowUpActionID(data.id),
          createdBy: {
            id: new UserID(data.createdBy.id),
            name: new DisplayName(data.createdBy.name),
          },
          opportunity: {
            id: new OpportunityID(data.opportunity.id),
            name: new OpportunityName(data.opportunity.name),
          },
          due: new FollowUpActionDue(DateTime.fromFormat(data.due, 'yyyy-MM-dd HH:mm:ss').toJSDate()),
          followUpActionType: {
            id: new FollowUpActionTypeID(data.followUpActionType.id),
            name: new FollowUpActionTypeName(data.followUpActionType.name),
          },
          assignee: {
            id: new UserID(data.assignee.id),
            name: new DisplayName(data.assignee.name),
          },
          contentsSummary: new FollowUpActionSummary(data.contentsSummary),
          isDone: new FollowUpActionIsDone(data.isDone),
        }))),
        catchError(e => {
          this._messageDialogService.notice({
            errorTarget: `${Target.FOLLOW_UP_ACTION}一覧の取得`
          });
          return throwError(() => e);
        })
      );
  }
}
