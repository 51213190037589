import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ConsultantAddComponent } from "./add/add.component";
import { ConsultantDetailComponent } from "./detail/detail.component";
import { ConsultantListComponent } from "./list/list.component";

const routes: Routes = [
  {
    path: '',
    component: ConsultantListComponent,
  },
  {
    path: 'add',
    component: ConsultantAddComponent,
  },
  {
    path: 'detail/:consultantID',
    component: ConsultantDetailComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConsultantSettingRoutingModule {}
