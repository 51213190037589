import { ClientID } from "app/model/client/client";
import { Observable } from "rxjs";
import { ClientRelationshipID } from "../client-relationship";
import { ClientRelationshipNoteContent } from "./client-relationship-note";

export abstract class ClientRelationshipNoteFactory {
  abstract create(
    clientID: ClientID,
    relationshipID: ClientRelationshipID,
    content: ClientRelationshipNoteContent
  ): Observable<void>;
}
