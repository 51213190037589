import {ProductTypeId, ProductTypeName} from 'app/model/product-type/product-type';
import {
  Product,
  ProductId,
  ProductItem,
  ProductName,
  ProductNumber,
  ProductPrice,
  ProductQuantityUnit
} from 'app/model/product/product';
import {FilterPredicate} from 'app/model/search-filter/search-filter';
import {Observable} from 'rxjs';
import {FilterInput} from "#infrastructure/application/filter.service";

export interface SearchProductsListParam {
  perPage: number,
  page: number,
  filter: {
    searchString?: FilterPredicate,
    productNumber?: FilterPredicate,
    productName?: FilterPredicate,
    productTypes?: FilterPredicate,
    [propertyName: `item:${string}`]: FilterPredicate | undefined,
  }
}

export type ProductFilterInput = {
  perPage: number,
  page: number,
  filterInput: FilterInput,
}

export interface ProductsList {
  totalCount: number;
  results: ProductsListResult[]
}

export interface ProductsListResult {
  id: ProductId,
  productNumber: ProductNumber,
  name: ProductName,
  price: ProductPrice,
  unit: ProductQuantityUnit,
  productTypeID: ProductTypeId,
  productTypeName: ProductTypeName,
  items?: ProductItem[],
}


export abstract class ProductQueryService {
  abstract findBy(param: SearchProductsListParam): Observable<ProductsList>;
  abstract filter(input: ProductFilterInput): Observable<ProductsList>;
  abstract get(id: ProductId): Observable<Product>
}
