import { WonReasonDescription, WonReasonID, WonReasonName } from "app/model/opportunity/won-reason/won-reason";
import { Observable } from "rxjs";

export type WonReasonInputs = {
  name: WonReasonName,
  description: WonReasonDescription,
}

export abstract class WonReasonCommandService {
  abstract add(inputs: WonReasonInputs): Observable<void>;
  abstract update(id: WonReasonID, inputs: WonReasonInputs): Observable<void>;
  abstract delete(id: WonReasonID): Observable<void>;
  abstract sort(ids: WonReasonID[]): Observable<void>;
}
