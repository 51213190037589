import { HintReviewCommandService } from "#application/services/hint-review-command.service";
import { HintID, HintReviewStatus } from "app/model/hint/hint";
import { ServerApi } from "#infrastructure/api/server-api";
import { Injectable, inject } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class HintReviewCommandServiceImpl implements HintReviewCommandService {
  private readonly _serverApi = inject(ServerApi);

  /**
   * エラーが起きた場合、コーチングに大きな影響は無く、
   * 回答を続行させる方がよいため、エラーは握りつぶす
   */
  review(id: HintID, status: HintReviewStatus): Observable<void> {
    return this._serverApi.hintReviewApi.review(id.value, status)
    .pipe(
      catchError(() => of(undefined))
    );
  }
}
