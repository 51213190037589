import {
  AssigneeOutput,
  ClientOutput,
  CustomFieldOutput,
  GetLostInfoOutputV2,
  GetOpportunityListInput,
  GetWonInfoOutputV2,
  OpportunityListItem,
  OpportunityListV2,
  OpportunityOutput,
  OpportunityQueryV2Service, OpportunityTypeOutput, PhaseOutput, SalesPhaseOutput,
  StatusOutput
} from '#application/services/opportunity-query-v2.service';
import { ServerApiError, ServerApiErrorCode } from '#infrastructure/api/error';
import { ServerApi } from '#infrastructure/api/server-api';
import {
  ApiAssigneeItem,
  ApiClientCategory,
  ApiClientItem,
  ApiCustomFieldItem, ApiOpportunityItem,
  ApiOpportunityListItem, ApiOpportunityTypeItem, ApiPhaseItem, ApiSalesPhaseItem,
  ApiStatusItem,
  GetLostInfoResponseV2,
  GetOpportunityResponseV2,
  GetWonInfoResponseV2
} from '#infrastructure/api/server-opportunity-v2-api';
import { Injectable, inject } from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import { ClientCategory } from "app/model/client-info-type/client-type";
import { ClientID, ClientName, ClientNumber } from 'app/model/client/client';
import { CustomFieldId, CustomFieldName, CustomFieldValue } from 'app/model/custom-field/custom-field';
import { OpportunityTypeID, OpportunityTypeName } from 'app/model/opportunity-type/opportunity-type';
import {
  LostReason,
  LostReasonDescription,
  LostReasonID,
  LostReasonName
} from 'app/model/opportunity/lost-reason/lost-reason';
import {
  ActualAmount,
  ActualCloseDate,
  ExpectedAmount,
  ExpectedCloseDate,
  LostNote,
  LostOrderDate,
  OpportunityAccrualDate,
  OpportunityID,
  OpportunityItem,
  OpportunityName,
  OpportunityNumber,
  OpportunityStatus,
  OpportunityV2,
  SalesRecordingAmount,
  SalesRecordingDate,
  WonNote
} from 'app/model/opportunity/opportunity';
import { WonReasonDescription, WonReasonID, WonReasonName } from 'app/model/opportunity/won-reason/won-reason';
import { PhaseID, PhaseName, SalesPhaseID, SalesPhaseName } from 'app/model/sales-phase/sales-phase';
import { DisplayName, UserID } from 'app/model/user/user';
import { DateTime } from 'luxon';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpportunityQueryV2ServiceImpl implements OpportunityQueryV2Service {
  private readonly _serverApi = inject(ServerApi);

  getByID(id: OpportunityID): Observable<OpportunityV2> {
    return this._serverApi.opportunityV2Api
      .getByID(id.value)
      .pipe(
        map(v => this.toOpportunity(v)),
      );
  }

  getByIDInResult(id: OpportunityID): Observable<Result<
    OpportunityV2,
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >> {
    return this._serverApi.opportunityV2Api
      .getByID(id.value)
      .pipe(
        map(v => Success(this.toOpportunity(v))),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.NotFound:
              return of(Failure(GeneralFailure.NotFound));
            default:
              return of(Failure(GeneralFailure.Unexpected));
          }
        }),
      );
  }

  getByNumber(number: OpportunityNumber): Observable<OpportunityV2> {
    return this._serverApi.opportunityV2Api
      .getByNumber(number.value)
      .pipe(
        map(v => this.toOpportunity(v)),
      );
  }

  getWonInfo(id: OpportunityID): Observable<GetWonInfoOutputV2> {
    return this._serverApi.opportunityV2Api
      .getWonInfo(id.value)
      .pipe(
        map(v => this.toWonInfo(v)),
      );
  }

  getLostInfo(id: OpportunityID): Observable<GetLostInfoOutputV2> {
    return this._serverApi.opportunityV2Api
      .getLostInfo(id.value)
      .pipe(
        map(v => this.toLostInfo(v)),
      );
  }

  getOpportunityList(input: GetOpportunityListInput): Observable<Result<
    OpportunityListV2,
    typeof GeneralFailure.Unexpected
  >> {
    return this._serverApi.opportunityV2Api
      .getOpportunityList({
        pagination: {
          perPage: input.perPage,
          page: input.page,
        },
        filters: input.filterInput.filters,
        sorts: input.filterInput.sorts
      })
      .pipe(
        map(v => Success({
          totalCount: v.totalCount,
          results: v.results.map(OpportunityListConverter.toOpportunityListItem)
        })),
        catchError(() => of(Failure(GeneralFailure.Unexpected))),
      );
  }

  private toOpportunity(res: GetOpportunityResponseV2): OpportunityV2 {
    return {
      opportunityID: new OpportunityID(res.id),
      opportunityNumber: new OpportunityNumber(res.opportunityNumber),
      opportunityName: new OpportunityName(res.opportunityName),
      clientID: new ClientID(res.clientID),
      opportunityTypeID: new OpportunityTypeID(res.opportunityTypeID),
      phaseID: new PhaseID(res.phaseID),
      assignee: new UserID(res.assignee),
      expectedAmount: res.expectedAmount === undefined
        ? undefined
        : new ExpectedAmount(res.expectedAmount),
      expectedCloseDate: res.expectedCloseDate === undefined
        ? undefined
        : new ExpectedCloseDate(
          DateTime.fromFormat(res.expectedCloseDate, 'yyyy-MM-dd').toJSDate()
        ),
      opportunityAccrualDate: res.opportunityAccrualDate === undefined
        ? undefined
        : new OpportunityAccrualDate(
          DateTime.fromFormat(res.opportunityAccrualDate, 'yyyy-MM-dd').toJSDate()
        ),
      items: res.items === undefined
        ? undefined
        : this.convertKeyValueObjToOpportunityItemList(res.items),
      status: this.convertToOpportunityStatus(res.status),
    };
  }

  private convertKeyValueObjToOpportunityItemList(items: { [key: string]: string }): OpportunityItem[] {
    return Object.keys(items)
      .map(key => new OpportunityItem(
        new CustomFieldId(key),
        new CustomFieldValue(items[key]),
      ));
  }

  private convertToOpportunityStatus(status: GetOpportunityResponseV2['status']): OpportunityV2['status'] {
    const type = status.type;
    switch (type) {
      case 'OPEN':
        return {
          type: OpportunityStatus.OPEN,
        };
      case 'WON':
        return {
          type: OpportunityStatus.WON,
          actualAmount: new ActualAmount(status.actualAmount),
          actualCloseDate: new ActualCloseDate(
            DateTime.fromFormat(status.actualCloseDate, 'yyyy-MM-dd').toJSDate()
          ),
          salesRecords: status.salesRecords.map(v => ({
            date: new SalesRecordingDate(
              DateTime.fromFormat(v.date, 'yyyy-MM-dd').toJSDate()
            ),
            amount: new SalesRecordingAmount(v.amount),
          })),
          wonReasons: status.wonReasons.map(v => ({
            id: new WonReasonID(v.id),
            name: new WonReasonName(v.name),
          })),
          wonNote: new WonNote(status.wonNote),
        };
      case 'LOST':
        return {
          type: OpportunityStatus.LOST,
          lostOrderDate: new LostOrderDate(
            DateTime.fromFormat(status.lostOrderDate, 'yyyy-MM-dd').toJSDate()
          ),
          lostReasons: status.lostReasons.map(v => ({
            id: new LostReasonID(v.id),
            name: new LostReasonName(v.name),
          })),
          lostNote: new LostNote(status.lostNote),
        };
      case 'DELETED':
        return {
          type: OpportunityStatus.DELETED,
        };
      default:
        const unexpected: never = type;
        throw Error(`${unexpected}は予期せぬ値です`);
    }
  }

  private toWonInfo(res: GetWonInfoResponseV2): GetWonInfoOutputV2 {
    return {
      opportunityID: new OpportunityID(res.opportunityID),
      actualCloseDate: res.actualCloseDate
        ? new ActualCloseDate(
          DateTime.fromFormat(res.actualCloseDate, 'yyyy-MM-dd').toJSDate()
        )
        : undefined,
      salesRecords: res.salesRecords
        ? res.salesRecords.map(v => ({
          date: new SalesRecordingDate(
            DateTime.fromFormat(v.date, 'yyyy-MM-dd').toJSDate()
          ),
          amount: new SalesRecordingAmount(v.amount),
        }))
        : undefined,
      wonReasons: res.wonReasons
        ? res.wonReasons.map(v => ({
          id: new WonReasonID(v.id),
          name: new WonReasonName(v.name),
        }))
        : undefined,
      wonNote: res.wonNote
        ? new WonNote(res.wonNote)
        : undefined,
    } as GetWonInfoOutputV2;
  }

  private toLostInfo(res: GetLostInfoResponseV2): GetLostInfoOutputV2 {
    return {
      opportunityID: new OpportunityID(res.opportunityID),
      lostOrderDate: res.lostOrderDate
        ? new LostOrderDate(
          DateTime.fromFormat(res.lostOrderDate, 'yyyy-MM-dd').toJSDate()
        )
        : undefined,
      lostReasons: res.lostReasons
        ? res.lostReasons.map(v => ({
          id: new LostReasonID(v.id),
          name: new LostReasonName(v.name),
        }))
        : undefined,
      lostNote: res.lostNote
        ? new LostNote(res.lostNote)
        : undefined,
    } as GetLostInfoOutputV2;
  }
}

export namespace OpportunityListConverter {
  export const toOpportunityListItem = (item: ApiOpportunityListItem): OpportunityListItem => {
    return {
      opportunity: toOpportunityOutput(item.opportunity),
      client: toClientItemOutput(item.client),
      opportunityType: toOpportunityTypeOutput(item.opportunityType),
      salesPhase: toSalesPhaseOutput(item.salesPhase),
      phase: toPhaseOutput(item.phase),
      assignee: toAssigneeOutput(item.assignee),
      status: toStatusOutput(item.status),
      customFields: Object
        .entries(item.items)
        .map(([id, customField]) => toCustomFieldOutput(id, customField))
    };
  }

  export const toOpportunityOutput = (opportunity: ApiOpportunityItem): OpportunityOutput => {
    return {
      id: new OpportunityID(opportunity.id),
      number: new OpportunityNumber(opportunity.number),
      name: new OpportunityName(opportunity.name),
      expectedAmount: opportunity.expectedAmount === undefined
        ? undefined
        : new ExpectedAmount(opportunity.expectedAmount),
      expectedCloseDate: opportunity.expectedCloseDate === undefined
        ? undefined
        : new ExpectedCloseDate(
          DateTime.fromFormat(opportunity.expectedCloseDate, 'yyyy-MM-dd').toJSDate()
        ),
      opportunityAccrualDate: opportunity.accrualDate === undefined
        ? undefined
        : new OpportunityAccrualDate(
          DateTime.fromFormat(opportunity.accrualDate, 'yyyy-MM-dd').toJSDate()
        ),
    }
  }

  const toClientItemOutput = (client: ApiClientItem): ClientOutput => {
    return {
      id: new ClientID(client.id),
      number: new ClientNumber(client.number),
      name: new ClientName(client.name),
      category: toClientCategory(client.category),
    }
  }

  export const toClientCategory = (category: ApiClientCategory): ClientCategory => {
    switch (category) {
      case 'INDIVIDUAL':
        return ClientCategory.Individual;
      case 'CORPORATION':
        return ClientCategory.Corporation;
      default:
        const unexpected: never = category;
        throw Error(`${unexpected}は予期せぬ値です`);
    }
  }

  const toOpportunityTypeOutput = (opportunityType: ApiOpportunityTypeItem): OpportunityTypeOutput => {
    return {
      id: new OpportunityTypeID(opportunityType.id),
      name: new OpportunityTypeName(opportunityType.name),
    }
  }

  const toSalesPhaseOutput = (salesPhase: ApiSalesPhaseItem): SalesPhaseOutput => {
    return {
      id: new SalesPhaseID(salesPhase.id),
      name: new SalesPhaseName(salesPhase.name),
    }
  }

  const toPhaseOutput = (phase: ApiPhaseItem): PhaseOutput => {
    return {
      id: new PhaseID(phase.id),
      name: new PhaseName(phase.name),
    }
  }

  const toAssigneeOutput = (assignee: ApiAssigneeItem): AssigneeOutput => {
    return {
      id: new UserID(assignee.id),
      name: new DisplayName(assignee.name),
    }
  }
  const toStatusOutput = (status: ApiStatusItem): StatusOutput => {
    const statusType = status.type;
    switch (statusType) {
      case 'WON':
        return {
          type: OpportunityStatus.WON,
          actualAmount: new ActualAmount(status.actualAmount),
          actualCloseDate: new ActualCloseDate(
            DateTime.fromFormat(status.actualCloseDate, 'yyyy-MM-dd').toJSDate()
          ),
          note: new WonNote(status.note),
          wonReasons: status.wonReasons.map(v =>
            ({
              id: new WonReasonID(v.id),
              name: new WonReasonName(v.name),
              description: new WonReasonDescription(''),
            })
          ),
          salesRecords: status.salesRecords.map(v =>
            ({
              date: new SalesRecordingDate(
                DateTime.fromFormat(v.date, 'yyyy-MM-dd').toJSDate()
              ),
              amount: new SalesRecordingAmount(v.amount),
            })
          ),
        };
      case 'LOST':
        return {
          type: OpportunityStatus.LOST,
          lostOrderDate: new LostOrderDate(
            DateTime.fromFormat(status.lostOrderDate, 'yyyy-MM-dd').toJSDate()
          ),
          note: new LostNote(status.note),
          lostReasons: status.lostReasons.map(v =>
            new LostReason(
              new LostReasonID(v.id),
              new LostReasonName(v.name),
              new LostReasonDescription(''),
            )
          ),
        };
      case 'OPEN':
        return {
          type: OpportunityStatus.OPEN,
        };
      case 'DELETED':
        return {
          type: OpportunityStatus.DELETED,
        };
      default:
        const unexpected: never = statusType;
        throw Error(`${unexpected}は予期せぬ値です`);
    }
  }

  const toCustomFieldOutput = (customFieldID: string, customField: ApiCustomFieldItem): CustomFieldOutput => {
    const customFieldType = customField.type;
    switch (customFieldType) {
      case 'SINGLE_LINE_TEXT':
      case 'MULTI_LINE_TEXT':
      case 'URL':
      case 'MAIL_ADDRESS':
      case 'ADDRESS':
      case 'PHONE_NUMBER':
        return {
          type: customFieldType,
          id: new CustomFieldId(customFieldID),
          name: new CustomFieldName(customField.name),
          value: customField.value,
        };
      case 'NUMBER':
        return {
          type: customFieldType,
          id: new CustomFieldId(customFieldID),
          name: new CustomFieldName(customField.name),
          value: customField.value,
        };
      case 'DATE':
        return {
          type: customFieldType,
          id: new CustomFieldId(customFieldID),
          name: new CustomFieldName(customField.name),
          value: (customField.value === undefined || customField.value === '')  ?
            undefined :
            DateTime.fromFormat(customField.value, 'yyyy-MM-dd').toJSDate(),
        };
      case 'DATETIME':
        return {
          type: customFieldType,
          id: new CustomFieldId(customFieldID),
          name: new CustomFieldName(customField.name),
          value: (customField.value === undefined || customField.value === '')  ?
            undefined :
            DateTime.fromFormat(customField.value, 'yyyy-MM-dd HH:mm:ss').toJSDate(),
        };
      case 'SELECT':
        return {
          type: customFieldType,
          id: new CustomFieldId(customFieldID),
          name: new CustomFieldName(customField.name),
          values: customField.value === undefined ? [] : customField.value.split(/\t/).filter(v => v != ''),
        };
      case 'USER':
        return {
          type: customFieldType,
          id: new CustomFieldId(customFieldID),
          name: new CustomFieldName(customField.name),
          userID: new UserID(customField.userID),
          userName: customField.userName === undefined ? undefined : new DisplayName(customField.userName),
        };
      case 'ATTACHMENT':
        return {
          type: customFieldType,
          id: new CustomFieldId(customFieldID),
          name: new CustomFieldName(customField.name),
          fileStatus: customField.fileStatus,
        };
      case 'CLIENT':
        return {
          type: customFieldType,
          id: new CustomFieldId(customFieldID),
          name: new CustomFieldName(customField.name),
          clientID: customField.clientID === undefined ? undefined : new ClientID(customField.clientID),
          clientName: customField.clientName === undefined ? undefined : new ClientName(customField.clientName),
        };
      case 'CHECKBOX':
        return {
          type: customFieldType,
          id: new CustomFieldId(customFieldID),
          name: new CustomFieldName(customField.name),
          value: customField.value,
        };
      default:
        const unexpected: never = customFieldType;
        throw Error(`${unexpected}は予期せぬ値です`);
    }
  }

}
