import { MessageDialogService } from '#application/services/message-dialog.service';
import { NurturingHearingSettingCommandService, SaveNurturingHearingSettingInputs } from '#application/services/nurturing-hearing-setting-command.service';
import { SnackBarService } from '#application/services/snack-bar.service';
import { ServerApi } from '#infrastructure/api/server-api';
import { messageFn } from '#utils/messages';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';
import {LocalizedNameService} from "#application/services/localized-name.service";
import {LocalizeKey} from "#application/services/authorize.service";

@Injectable({
  providedIn: 'root'
})
export class NurturingHearingSettingCommandServiceImpl implements NurturingHearingSettingCommandService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _snackBarService = inject(SnackBarService);
  private readonly _messageDialogService = inject(MessageDialogService);
  private readonly _localizedNameService = inject(LocalizedNameService);

  save(inputs: SaveNurturingHearingSettingInputs): Observable<void> {
    const nurturingAlias = this._localizedNameService.get(LocalizeKey.NURTURING);
    return this._serverApi.nurturingHearingSettingApi
      .save({
        nurturingTypeID: inputs.nurturingTypeID.value,
        requestBody: {
          beforeHearing: inputs.beforeHearingItems.map(item => item.value),
          afterHearing: inputs.afterHearingItems.map(item => item.value),
        },
      })
      .pipe(
        tap(() => this._snackBarService.show(
          messageFn(`${nurturingAlias}ヒアリング`, 'SAVE_SUCCESS').message
        )),
        catchError((err) => {
          this._messageDialogService.notice({
            errorTarget: `${nurturingAlias}ヒアリングの保存`,
          });
          return throwError(() => err);
        }),
      );
  }
}
