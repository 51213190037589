import {
  ProductFilterInput,
  ProductQueryService,
  ProductsList,
  SearchProductsListParam
} from '#application/services/product-query.service';
import { ServerApi } from '#infrastructure/api/server-api';
import { FilterRequestConverter, FilterService } from "#infrastructure/application/filter.service";
import { inject, Injectable } from '@angular/core';
import { CustomFieldId, CustomFieldValue } from 'app/model/custom-field/custom-field';
import { ProductTypeId, ProductTypeName } from 'app/model/product-type/product-type';
import {
  Product,
  ProductDeleted,
  ProductId,
  ProductItem,
  ProductName,
  ProductNumber,
  ProductPrice,
  ProductQuantityUnit
} from 'app/model/product/product';
import { mergeMap, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductQueryServiceImpl extends ProductQueryService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _filterService = inject(FilterService);

  findBy(param: SearchProductsListParam): Observable<ProductsList> {
    return this._filterService.getSchema('products')
      .pipe(
        mergeMap(schema => {
          const filterRequest = FilterRequestConverter.toFilterRequest(schema, param);
          return this._serverApi.productApi.filter(filterRequest);
        }),
        map(data => ({
          totalCount: data.totalCount,
          results: data.results.map(result => ({
            id: new ProductId(result.id),
            productNumber: new ProductNumber(result.productNumber),
            name: new ProductName(result.name),
            price: new ProductPrice(result.price),
            unit: new ProductQuantityUnit(result.unit),
            productTypeID: new ProductTypeId(result.productTypeID),
            productTypeName: new ProductTypeName(result.productTypeName),
            items: result.items !== undefined
              ? convertKeyValueObjToProductItemList(result.items)
              : undefined,
          }))
        }))
      );
  }

  filter(input: ProductFilterInput): Observable<ProductsList> {
    return this._serverApi.productApi
      .filter({
        pagination: {
          perPage: input.perPage,
          page: input.page,
        },
        filters: input.filterInput.filters,
        sorts: input.filterInput.sorts
      })
      .pipe(
        map(data => ({
          totalCount: data.totalCount,
          results: data.results.map(result => ({
            id: new ProductId(result.id),
            productNumber: new ProductNumber(result.productNumber),
            name: new ProductName(result.name),
            price: new ProductPrice(result.price),
            unit: new ProductQuantityUnit(result.unit),
            productTypeID: new ProductTypeId(result.productTypeID),
            productTypeName: new ProductTypeName(result.productTypeName),
            items: result.items !== undefined
              ? convertKeyValueObjToProductItemList(result.items)
              : undefined,
          }))
        }))
      );
  }

  get(id: ProductId): Observable<Product> {
    return this._serverApi.productApi.get(id.value).pipe(
      map(data => new Product(
        new ProductId(data.id),
        new ProductNumber(data.productNumber),
        new ProductName(data.name),
        new ProductTypeId(data.productTypeID),
        new ProductPrice(data.price),
        new ProductQuantityUnit(data.unit),
        data.items !== undefined
          ? convertKeyValueObjToProductItemList(data.items)
          : undefined,
        new ProductDeleted(data.deleted),
      ))
    )
  }
}

type keyValueItem = {
  [key: string]: string
};

const convertKeyValueObjToProductItemList = (items: keyValueItem): ProductItem[] => {
  const keys = Object.keys(items);
  const productItems: ProductItem[] = [];
  keys.forEach(key => {
    productItems.push(new ProductItem(
      new CustomFieldId(key),
      new CustomFieldValue(items[key])
    ));
  });
  return productItems;
};
