import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type UserProfileCalendarSettingsData = {
  monday: boolean,
  tuesday: boolean,
  wednesday: boolean,
  thursday: boolean,
  friday: boolean,
  saturday: boolean,
  sunday: boolean,
  startTime: number,
  closeTime: number,
};

export type UpdateUserProfileCalendarSettingsRequest = UserProfileCalendarSettingsData;

export class UserProfileCalendarSettingsApi {
  constructor(private readonly http: HttpClient) {}

  update(id: string, request: UpdateUserProfileCalendarSettingsRequest): Observable<void> {
    return this.http.post<void>(`/api/user-profile-calendar-settings/${id}`, request);
  }
}
