import {Observable} from "rxjs";
import {
  LostReason,
  LostReasonID,
  LostReasonList,
} from "app/model/opportunity/lost-reason/lost-reason";

export abstract class LostReasonQueryService {
  abstract list(): Observable<LostReasonList>;

  abstract get(id: LostReasonID): Observable<LostReason>
}
