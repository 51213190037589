import { ServerApi } from '#infrastructure/api/server-api';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { CreateCustomFieldParam, CustomFieldFactory } from 'app/model/custom-field/custom-field.factory';

@Injectable({ providedIn: 'root' })
export class CustomFieldFactoryImpl extends CustomFieldFactory {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  create(param: CreateCustomFieldParam): Observable<void> {
    return this.serverApi.customFieldApi.create(param.category, {
      name: param.name.value,
      description: param.description.value,
      type: param.type,
      required: param.required,
      numberSetting:
        param.numberSetting
        ? {
            min: param.numberSetting.min?.value,
            max: param.numberSetting.max?.value,
            defaultValue: param.numberSetting.defaultValue?.value,
            unit: param.numberSetting.unit?.value
          }
        : undefined,
      selectSetting:
        param.selectSetting
        ? {
            multiple: param.selectSetting.multiple,
            allowInput: param.selectSetting.allowInput,
            options: param.selectSetting.options.map(option => option.value)
          }
        : undefined
    });
  }
}
