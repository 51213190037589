import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Page, PageNavigateService, PathOptions } from '#application/services/page-navigate.service';

@Injectable({ providedIn: 'root'})
export class PageNavigateServiceImpl implements PageNavigateService {
  constructor(
    private readonly router: Router,
  ) {
  }

  navigate(page: Page, options?: PathOptions): Promise<boolean> {
    return this.router.navigateByUrl(
      combinePageWithOptions(page, options)
    );
  }

  openInAnotherTab(page: Page, options?: PathOptions): void {
    const url = `${window.location.origin}/${page}`;
    window.open(
      combinePageWithOptions(url, options)
    );
  }
}

const combinePageWithOptions = (page: string, options?: PathOptions): string => {
  if (options?.requiredParam) {
    page = `${page}/${options.requiredParam}`;
  }
  if (options?.optionalParams) {
    const params = new URLSearchParams(options.optionalParams).toString();
    page = `${page}?${params}`;
  }
  return page;
}
