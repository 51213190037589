import { GoalSettingQueryService, FilterByPersonalGoalSettingInput, FilterByPersonalGoalSettingOutput, FilterByUserGroupGoalSettingInput, FilterByUserGroupGoalSettingOutput } from '#application/services/goal-setting-query.service';
import { ServerApiError, ServerApiErrorCode } from '#infrastructure/api/error';
import { ServerApi } from '#infrastructure/api/server-api';
import { FilterByPersonalGoalSettingResponse, GoalPerUserGoalSetting, GoalsPeriodTotalGoalSetting } from '#infrastructure/api/server-goal-setting-api';
import { Injectable, inject } from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import { GoalCategory, MonthlyGoal } from 'app/model/report/report-goal';
import { DisplayName, UserID } from 'app/model/user/user';
import { DateTime } from 'luxon';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoalSettingQueryServiceImpl implements GoalSettingQueryService {
  private readonly serverApi = inject(ServerApi);

  filterByUserGroup(category: GoalCategory, input: FilterByUserGroupGoalSettingInput): Observable<Result<
    FilterByUserGroupGoalSettingOutput,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.goalSettingApi
      .filterByUserGroup(
        category,
        {
          fiscalYear: input.fiscalYear.year.toString(),
          goalPeriodType: input.goalPeriodType,
          userIDs: input.userIDs?.map(v => v.value),
        },
      )
      .pipe(
        map(res => (Success({
          goalsMonthlyTotal: this._convertToGoalsMonthlyTotal(res.goalsPeriodTotal),
          goalsPerUsers: this._convertToGoalsPerUsers(res.goalsPerUser),
        }))),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.BadRequest:
              return of(Failure(GeneralFailure.BadRequest));
            default:
              return of(Failure(GeneralFailure.Unexpected));
          }
        })
      );
  }

  filterByPersonal(category: GoalCategory, input: FilterByPersonalGoalSettingInput): Observable<Result<
    FilterByPersonalGoalSettingOutput,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.goalSettingApi
      .filterByPersonal(
        category,
        {fiscalYear: input.fiscalYear.year.toString()},
      )
      .pipe(
        map(res => (Success({
          goalSettings: this._convertToFilterByPersonalGoalSettingOutput(res),
        }))),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.BadRequest:
              return of(Failure(GeneralFailure.BadRequest));
            default:
              return of(Failure(GeneralFailure.Unexpected));
          }
        })
      );
  }

  private _convertToFilterByPersonalGoalSettingOutput(response: FilterByPersonalGoalSettingResponse): MonthlyGoal[] {
    if (response.goalsPerPeriodType.length === 0) {
      return [];
    }
    return response.goalsPerPeriodType
      .filter(v => v.goalPeriodType === 'MONTHLY')
      .map(v => v.goals)
      .flatMap(v => Object.entries(v))
      .map(([key, value]) => ({
        yearMonth: DateTime.fromFormat(key, 'yyyy-MM-dd'),
        goal: value,
      }));
  }

  private _convertToGoalsMonthlyTotal(goalsPeriodTotal: GoalsPeriodTotalGoalSetting): FilterByUserGroupGoalSettingOutput['goalsMonthlyTotal'] {
    return {
      monthlyGoals: Object
        .entries(goalsPeriodTotal.periodGoals)
        .map(([key, value]) => ({
          yearMonth: DateTime.fromFormat(key, 'yyyy-MM-dd'),
          goal: value,
        })),
      totalGoal: goalsPeriodTotal.total,
    };
  }

  private _convertToGoalsPerUsers(goalPerUser: GoalPerUserGoalSetting[]): FilterByUserGroupGoalSettingOutput['goalsPerUsers'] {
    return goalPerUser.map(v => ({
      user: {
        id: new UserID(v.user.id),
        name: new DisplayName(v.user.name),
        isActive: v.user.isActive,
      },
      monthlyGoals: Object
        .entries(v.periodGoals)
        .map(([key, value]) => ({
          yearMonth: DateTime.fromFormat(key, 'yyyy-MM-dd'),
          goal: value,
        }))
        .sort((a, b) => a.yearMonth.toMillis() - b.yearMonth.toMillis())
    }));
  }
}
