import { FilterOpportunitiesPerPhaseInputs, OpportunityPerPhaseQueryService } from '#application/services/opportunity-per-phase-query.service';
import { ServerApi } from '#infrastructure/api/server-api';
import { Injectable, inject } from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import { LatestActionDate } from 'app/model/action/latest-action-date';
import { OpportunitiesWithTotalCountPerPhase } from 'app/model/opportunity-board/opportunity-board';
import { ExpectedAmount, OpportunityID, OpportunityName, OpportunityNumber } from 'app/model/opportunity/opportunity';
import { PhaseID } from 'app/model/sales-phase/sales-phase';
import { Observable, catchError, concatMap, from, map, of, toArray } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpportunityPerPhaseQueryServiceImpl implements OpportunityPerPhaseQueryService {
  private readonly serverApi = inject(ServerApi);

  filter(
    inputs: FilterOpportunitiesPerPhaseInputs,
    phaseIDs: PhaseID[]
  ): Observable<Result<
    OpportunitiesWithTotalCountPerPhase,
    | typeof GeneralFailure.Unexpected
  >> {
    return from(phaseIDs.map(phaseID => phaseID.value))
      .pipe(
        concatMap(phaseID => this.serverApi.opportunityPerPhaseApi
          .filter({
            pagination: {
              perPage: inputs.perPage,
              page: inputs.page,
            },
            filters: {
              ...inputs.filterInput.filters,
              phaseID: {
                type: "EXISTS_AND_FILTERED",
                operator: {
                  type: "IN",
                  values: [phaseID],
                },
              },
            },
            sorts: inputs.filterInput.sorts,
          })
          .pipe(
            map(res => ({
              phaseID,
              totalCount: res.totalCount,
              opportunities: res.results.map(v => ({
                opportunityID: new OpportunityID(v.opportunityID),
                opportunityNumber: new OpportunityNumber(v.opportunityNumber),
                opportunityName: new OpportunityName(v.opportunityName),
                expectedAmount: v.expectedAmount === undefined
                  ? undefined
                  : new ExpectedAmount(v.expectedAmount),
                latestActionDate: LatestActionDate.create(v.latestActionDate),
              }))
            }))
          )
        ),
        toArray(),
        map(opportunities => opportunities.reduce((acc, curr) => {
          acc[curr.phaseID] = {
            totalCount: curr.totalCount,
            opportunities: curr.opportunities,
          };
          return acc;
        }, {} as OpportunitiesWithTotalCountPerPhase)),
        map(opportunitiesPerPhase => Success(opportunitiesPerPhase)),
        catchError(() => of(Failure(GeneralFailure.Unexpected))),
      );
  }
}
