import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ServerApi} from '#infrastructure/api/server-api';
import {
  OpportunityHearingSettings,
} from 'app/model/opportunity-hearing-setting/opportunity-hearing-setting';
import {
  OpportunityHearingSettingRepository
} from 'app/model/opportunity-hearing-setting/opportunity-hearing-setting.repository';
import {OpportunityTypeID} from 'app/model/opportunity-type/opportunity-type';
import {
  SaveOpportunityHearingSettingRequest,
} from '#infrastructure/api/server-opportunity-hearing-setting-api';

@Injectable({ providedIn: 'root' })
export class OpportunityHearingSettingRepositoryImpl extends OpportunityHearingSettingRepository {
  constructor(private readonly server: ServerApi) {
    super();
  }

  /**
   * 商談タイプに紐づく商談レイアウト設定を保存する
   *
   * @param opportunityTypeID
   * @param hearingSettings
   * @returns
   */
  save(
    opportunityTypeID: OpportunityTypeID,
    hearingSettings: OpportunityHearingSettings
  ): Observable<void> {
    const request = OpportunityHearingSettingRepositoryImpl._convertToRequest(hearingSettings);
    return this.server.opportunityHearingSettingApi.saveAll(
      opportunityTypeID.value,
      request
    );
  }

  /**
   * 商談ヒアリング設定のモデルをサーバリクエストに変換する
   *
   * @param hearingSettings
   * @returns
   */
  private static _convertToRequest(
    hearingSettings: OpportunityHearingSettings
  ): SaveOpportunityHearingSettingRequest {
    return hearingSettings.list.map((v) => {
      const values = v.settings.map((setting) => {
        return {
          phaseID: setting.phaseID.value,
          before: setting.before,
          after: setting.after,
        };
      });
      return {
        customFieldID: v.id.value,
        values: values,
      };
    });
  }
}
