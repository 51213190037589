import { GoalConfirmationQueryService, SearchGoalConfirmationInputs, SearchGoalConfirmationOutputs } from "#application/services/goal-confirmation-query.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { DisplayName, UserID } from "app/model/user/user";
import { ServerApi } from "#infrastructure/api/server-api";
import { SearchGoalConfirmationRequest, SearchGoalConfirmationResponse } from "#infrastructure/api/server-goal-confirmation-api";
import { Injectable, inject } from "@angular/core";
import { DateTime } from "luxon";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

type Assignee = {
  type: string,
  userGroupID?: string,
};

@Injectable({
  providedIn: 'root',
})
export class GoalConfirmationQueryServiceImpl extends GoalConfirmationQueryService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _messageDialogService = inject(MessageDialogService);

  search(inputs: SearchGoalConfirmationInputs): Observable<SearchGoalConfirmationOutputs> {
    return this._serverApi.goalConfirmationApi
      .search(this._toRequestBody(inputs))
      .pipe(
        map((res: SearchGoalConfirmationResponse) => this._toOutputs(res)),
        catchError((err) => {
          this._messageDialogService.notice({
            errorTarget: '目標一覧の取得',
          });
          return throwError(err);
        }),
      );
  }

  private _toRequestBody(inputs: SearchGoalConfirmationInputs): SearchGoalConfirmationRequest {
    const assignee: Assignee = { type: inputs.assignee.type };
    if (inputs.assignee.type === 'USER_GROUP') {
      assignee.userGroupID = inputs.assignee.userGroupID.value;
    }
    return {
      yearMonth: inputs.yearMonth.toFormat('yyyy-MM'),
      assignee: assignee,
    };
  }

  private _toOutputs(res: SearchGoalConfirmationResponse): SearchGoalConfirmationOutputs {
    return {
      goalsMonthlyTotal: {
        monthlyGoals: res.goalsMonthlyTotal.monthlyGoals.map((monthlyGoal) => ({
          yearMonth: DateTime.fromFormat(monthlyGoal.yearMonth, 'yyyy-MM'),
          goal: monthlyGoal.goal,
        })),
        totalGoal: res.goalsMonthlyTotal.totalGoal,
      },
      goalsPerUsers: res.goalsPerUsers.map((goalsPerUser) => ({
        user: {
          id: new UserID(goalsPerUser.user.id),
          name: new DisplayName(goalsPerUser.user.name),
          isActive: goalsPerUser.user.isActive,
        },
        monthlyGoals: goalsPerUser.monthlyGoals.map((monthlyGoal) => ({
          yearMonth: DateTime.fromFormat(monthlyGoal.yearMonth, 'yyyy-MM'),
          goal: monthlyGoal.goal,
        })),
      })),
    };
  }
}
