import { ClientID } from "app/model/client/client";
import { OpportunityTypeID } from 'app/model/opportunity-type/opportunity-type';
import { ExpectedAmount, ExpectedCloseDate, OpportunityAccrualDate, OpportunityID, OpportunityItem, OpportunityName, OpportunityNumber, OpportunitySource } from 'app/model/opportunity/opportunity';
import { PhaseID } from 'app/model/sales-phase/sales-phase';
import { UserID } from 'app/model/user/user';
import { CreateOpportunityResponseData } from "#infrastructure/api/server-opportunity-api";
import { Observable } from 'rxjs';

export interface CreateOpportunityParam {
  clientID: ClientID,
  opportunityName: OpportunityName,
  opportunityTypeID: OpportunityTypeID,
  phaseID: PhaseID,
  assignee: UserID,
  items?: OpportunityItem[],
  expectedAmount?: ExpectedAmount,
  expectedCloseDate?: ExpectedCloseDate,
  opportunityAccrualDate?: OpportunityAccrualDate,
  opportunitySource?: OpportunitySource,
}

export interface CreateOpportunityResponse {
  opportunityID: OpportunityID,
  opportunityNumber: OpportunityNumber,
}

export interface CreateQuickOpportunityParam {
  clientID: ClientID,
  opportunityName: OpportunityName,
  opportunityTypeID: OpportunityTypeID,
  phaseID: PhaseID,
  assignee: UserID,
  opportunitySource?: OpportunitySource,
}

export abstract class OpportunityFactory {

  abstract create(param:CreateOpportunityParam): Observable<CreateOpportunityResponse>;

  abstract createQuick(param: CreateQuickOpportunityParam): Observable<CreateOpportunityResponseData>;
}
