import { Companions } from 'app/model/companion/companion';
import { OpportunityID, OpportunityItem } from 'app/model/opportunity/opportunity';
import { PhaseID } from 'app/model/sales-phase/sales-phase';
import { Observable } from 'rxjs';
import { ActionAttendees, ActionEndDateTime, ActionID, ActionNote, ActionStartDateTime } from './action';
import { ActionTypeID } from './action-type';

export abstract class ActionFactory {

  abstract create(
    opportunityID: OpportunityID,
    companions: Companions,
    attendees: ActionAttendees,
    currentPhaseID: PhaseID,
    startPhaseID: PhaseID,
    targetPhaseID: PhaseID,
    actionTypeID: ActionTypeID,
    actionStartDateTime: ActionStartDateTime,
    actionEndDateTime: ActionEndDateTime,
    note: ActionNote,
    items: OpportunityItem[]
  ): Observable<ActionID>;
}
