import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import { ApiBuiltinFieldId, ApiFormLayoutWidth, ApiFormLayoutCategory } from './server-form-layout-api';
import { ApiCustomFieldNumberSettingData, ApiCustomFieldSelectSettingData, ApiFieldType } from './server-custom-field-api';

export type GetFormItemsResponse = GetFormItemsResponseData;

export interface GetFormItemsResponseData {
  items: (
    | SectionFormItemData
    | BuiltinFormItemData
    | CustomFormItemData
  )[]
}

/**
 * セクション項目
 */
export interface SectionFormItemData {
  layoutItemType: 'SECTION_TITLE',
  width: 'FULL',
  title: string
}

/**
 * ビルトイン項目
 */
export interface BuiltinFormItemData {
  layoutItemType: 'BUILTIN_FIELD',
  builtinFieldID: ApiBuiltinFieldId,
  width: ApiFormLayoutWidth,
  fieldType: ApiFieldType,
}

/**
 * カスタム項目
 */
export interface CustomFormItemData {
  layoutItemType: 'CUSTOM_FIELD',
  customFieldID: string,
  width: ApiFormLayoutWidth,
  name: string,
  description: string,
  fieldType: ApiFieldType,
  required: boolean,
  numberSetting?: ApiCustomFieldNumberSettingData,
  selectSetting?: ApiCustomFieldSelectSettingData
}

export class FormItemsApi {
  constructor(
    private readonly http: HttpClient
  ) {
  }

  get(category: ApiFormLayoutCategory, layoutID: string): Observable<GetFormItemsResponse> {
    return this.http.get<GetFormItemsResponse>(`/api/form-items/${category}/${layoutID}`);
  }
}
