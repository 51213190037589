import { OpportunityGroupStatusID } from "app/model/opportunity-group/opportunity-group-status";
import { OpportunityGroupWorkflowDescription, OpportunityGroupWorkflowID, OpportunityGroupWorkflowName } from "app/model/opportunity-group/opportunity-group-workflow";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { Observable } from "rxjs";

export type OpportunityGroupWorkflowInputs = {
  name: OpportunityGroupWorkflowName,
  description?: OpportunityGroupWorkflowDescription,
  statusIDs: OpportunityGroupStatusID[],
};

export type SortOpportunityGroupWorkflowInputs = {
  ids: OpportunityGroupWorkflowID[],
};

export abstract class OpportunityGroupWorkflowCommandService {
  abstract add(inputs: OpportunityGroupWorkflowInputs): Observable<Result<
    OpportunityGroupWorkflowID,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
  >>;

  abstract update(id: OpportunityGroupWorkflowID, inputs: OpportunityGroupWorkflowInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.NotFound
  >>;

  abstract delete(id: OpportunityGroupWorkflowID): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.NotFound
  >>;

  abstract sort(inputs: SortOpportunityGroupWorkflowInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>;
}
