import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type CreateOpportunityGroupStatusRequest = {
  name: string,
  description: string,
}

export type OpportunityGroupStatusData = {
  id: string,
  name: string,
  description?: string,
}

export type ListOpportunityGroupStatusResponse = OpportunityGroupStatusData[]

export type GetOpportunityGroupStatusResponse = OpportunityGroupStatusData;

export type UpdateOpportunityGroupStatusRequest = CreateOpportunityGroupStatusRequest;

export type SortOpportunityGroupStatusRequest = {
  ids: string[]
}

export class OpportunityGroupStatusApi {
  constructor(private readonly http: HttpClient) {}

  create(request: CreateOpportunityGroupStatusRequest): Observable<void> {
    return this.http.post<void>(`/api/opportunity-group-statuses`, request);
  }

  list(): Observable<ListOpportunityGroupStatusResponse> {
    return this.http.get<ListOpportunityGroupStatusResponse>(`/api/opportunity-group-statuses/all`);
  }

  get(id: string): Observable<GetOpportunityGroupStatusResponse> {
    return this.http.get<GetOpportunityGroupStatusResponse>(`/api/opportunity-group-statuses/${id}`);
  }

  update(id: string, request: UpdateOpportunityGroupStatusRequest): Observable<void> {
    return this.http.put<void>(`/api/opportunity-group-statuses/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/opportunity-group-statuses/${id}`);
  }
  sort(request: SortOpportunityGroupStatusRequest): Observable<void> {
    return this.http.patch<void>(`/api/opportunity-group-statuses/sort`, request);
  }
}
