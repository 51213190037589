import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type ListOpportunityTypeOpportunityContextSettingResponse = {
  isCompleted: boolean,
  opportunityType: {
    id: string,
    name: string,
    description: string,
  }
}[];

export type ListPhaseOpportunityContextSettingResponse = {
  phase: {
    id: string,
    name: string,
  },
  opportunityContexts: {
    id: string,
    name: string,
  }[]
}[];

export type PatchPhaseOpportunityContextSettingRequest = {
  phaseID: string,
  opportunityContextIDs: string[] | undefined,
};

export class OpportunityTypeOpportunityContextSettingApi {
  constructor(private readonly http: HttpClient) {}

  list(): Observable<ListOpportunityTypeOpportunityContextSettingResponse> {
    return this.http.get<ListOpportunityTypeOpportunityContextSettingResponse>(`/api/opportunity-type-opportunity-context-setting-list`);
  }

  listByPhase(typeID: string): Observable<ListPhaseOpportunityContextSettingResponse> {
    return this.http.get<ListPhaseOpportunityContextSettingResponse>(`/api/opportunity-types/${typeID}/opportunity-context-setting-list`);
  }

  patchByPhase(typeID: string, request: PatchPhaseOpportunityContextSettingRequest): Observable<void> {
    return this.http.patch<void>(`/api/opportunity-types/${typeID}/phase-opportunity-context-setting`, request);
  }
}
