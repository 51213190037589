import { DateTime } from 'luxon';

export abstract class ActionManagementStorageService {
  abstract storeAddActionDateTime(datetime?: DateTime): void;
  abstract lookupAddActionDateTime(): DateTime | undefined;

  abstract storeAddNurturingDateTime(datetime?: DateTime): void;
  abstract lookupAddNurturingDateTime(): DateTime | undefined;

  abstract storeAddEventDateTime(datetime?: DateTime): void;
  abstract lookupAddEventDateTime(): DateTime | undefined;
}
