import {
    CsvFieldMapping,
    CsvFileFormat,
    CsvImportParam,
    ImportTypes,
} from '#application/services/csv-import.service';
import {SnackBarService} from '#application/services/snack-bar.service';
import {
    FormLayoutCategory,
    FormLayoutId,
} from 'app/model/form-layout/form-layout';
import {
    ClientApi,
} from '#infrastructure/api/client-api';
import {ServerApi} from '#infrastructure/api/server-api';
import {CsvImportRequest} from '#infrastructure/api/server-csv-import-api';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {OpportunityCsvImportService} from "#application/services/opportunity-csv-import.service";
import {ApplicationHistoryType} from "../../model/storage/application-history";

@Injectable({providedIn: 'root'})
export class OpportunityCsvImportServiceImpl extends OpportunityCsvImportService {
    constructor(
        private readonly serverApi: ServerApi,
        private readonly snackBarService: SnackBarService,
        private readonly clientApi: ClientApi
    ) {
        super();
    }

    getImportTypes(): Observable<ImportTypes> {
        return this.serverApi.opportunityTypeApi.list().pipe(
            map((response) =>
                response.map((item) => (
                    {
                        typeId: item.id,
                        typeName: item.name,
                        category: FormLayoutCategory.OPPORTUNITY,
                        layoutId: item.formLayoutID
                            ? new FormLayoutId(item.formLayoutID)
                            : undefined,
                    }
                ))
            )
        );
    }

    importCsv(param: CsvImportParam): Observable<void> {
        const request: CsvImportRequest = {
            fileName: param.fileName,
            typeId: param.type.typeId,
            formatInfo: {
                encoding: param.formatDefinition.encoding,
                delimiter: param.formatDefinition.delimiter,
                dateFormat: param.formatDefinition.dateFormat,
                dateTimeFormat: param.formatDefinition.dateTimeFormat,
            },
            mapping: param.mapping.map((v) => ({fieldId: v})),
            file: param.base64encodedCsvFile,
            uniqueFieldId: param.uniqueFieldId,
        };
        return this.serverApi.csvImportApi.importCsv('opportunity', request).pipe(
            tap(() => this.snackBarService.show('CSVインポートを開始しました。')),
            map(() => {
            }),
            catchError((error) => {
                this.snackBarService.show('CSVインポートに失敗しました。');
                return throwError(error);
            })
        );
    }

    saveLastFileFormat(format: CsvFileFormat): void {
        this.clientApi.storeLocalStorage(
            ApplicationHistoryType.OpportunityCsvFileFormat,
            format
        );
    }

    getLastFileFormat(): CsvFileFormat | null {
        return this.clientApi.lookupLocalStorage(
            ApplicationHistoryType.OpportunityCsvFileFormat
        );
    }

    saveFieldMapping(mapping: CsvFieldMapping): void {
        this.clientApi.storeLocalStorage(
            ApplicationHistoryType.OpportunityCsvImport,
            mapping
        );
    }

    getLastFieldMapping(): CsvFieldMapping | null {
        return this.clientApi.lookupLocalStorage(
            ApplicationHistoryType.OpportunityCsvImport
        );
    }
}
