import { DateTime } from "luxon";

/**
 * 活動自体が無い状態の日付
 */
export class NoLatestActionDate {
  constructor(private readonly _value: undefined) {}
  get value(): undefined {
    return this._value;
  }
};

/**
 * 時間経過が良好な状態の最新活動日付
 */
export class GoodElapsedLatestActionDate {
  constructor(private readonly _value: Date) {}
  get value(): Date {
    return this._value;
  }
};

/**
 * 時間経過に注意が必要な状態の最新活動日付
 */
export class WarningElapsedLatestActionDate {
  constructor(private readonly _value: Date) {}
  get value(): Date {
    return this._value;
  }
};

/**
 * 時間経過が危険な状態の最新活動日付
 */
export class DangerElapsedLatestActionDate {
  constructor(private readonly _value: Date) {}
  get value(): Date {
    return this._value;
  }
};

/**
 * 最新活動日付
 */
export type LatestActionDate =
  | NoLatestActionDate
  | GoodElapsedLatestActionDate
  | WarningElapsedLatestActionDate
  | DangerElapsedLatestActionDate

export namespace LatestActionDate {
  /**
   * LatestActionDate型のクラスインスタンスを作成する関数。
   * 第2引数以降は今後外部から設定値を指定する可能性があるが、現在その機能は無いのでデフォルト値を設定している。
   *
   * @param dateStr {string | undefined} LocalDateTime形式の日付文字列
   * @param maxDaysOfGood {number} GOODの上限日数 {default: 7}
   * @param maxDaysOfWarning {number} WARNINGの上限日数 {default: 20}
   * @param nowOrDate {DateTime} 現在日付または指定基準日付 {default: DateTime.now()}
   * @returns LatestActionDate型のクラスインスタンス
   */
  export const create = (
    dateStr: string | undefined,
    maxDaysOfGood = 7,
    maxDaysOfWarning = 20,
    nowOr = DateTime.now(),
  ): LatestActionDate => {
    if (dateStr === undefined) {
      return new NoLatestActionDate(undefined);
    }
    const latestActionDate = DateTime.fromFormat(dateStr, 'yyyy-MM-dd HH:mm:ss');
    const elapsedDays = Math.round(latestActionDate.diff(nowOr, 'day').days) * -1;
    if (elapsedDays <= maxDaysOfGood) {
      return new GoodElapsedLatestActionDate(latestActionDate.toJSDate());
    }
    if (elapsedDays <= maxDaysOfWarning) {
      return new WarningElapsedLatestActionDate(latestActionDate.toJSDate());
    }
    return new DangerElapsedLatestActionDate(latestActionDate.toJSDate());
  }
}
