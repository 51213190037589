import {
  CreateOpportunityTypeParam,
  OpportunityTypeFactory
} from "app/model/opportunity-type/opportunity-type.factory";
import {ServerApi} from "#infrastructure/api/server-api";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class OpportunityTypeFactoryImpl extends OpportunityTypeFactory {
  constructor(private readonly api: ServerApi){
    super();
  }
  create(param: CreateOpportunityTypeParam): Observable<void> {
    return this.api.opportunityTypeApi.create({
      name: param.name.value,
      description: param.description.value,
      salesPhaseID: param.salesPhaseId.value,
      formLayoutID: param.formLayoutId?.value
    })
  }
}
