import {
  BuiltinField,
  DefaultFormLayout,
  FormLayout,
  FormLayoutId,
  FormLayoutName,
} from 'app/model/form-layout/form-layout';
import { Observable } from 'rxjs';

export interface FormLayoutOutline {
  id: FormLayoutId;
  name: FormLayoutName;
}

export abstract class FormLayoutQueryService {
  abstract getDefaultLayout(): Observable<DefaultFormLayout>;
  abstract get(id: FormLayoutId): Observable<FormLayout>;
  abstract list(): Observable<FormLayoutOutline[]>;
}

export abstract class BuiltinFieldQueryService {
  abstract list(): Observable<BuiltinField[]>
}
