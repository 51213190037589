import {ValidationError} from 'app/model/errors/validation-error';
import {assert} from '#utils/assert';

export class Organization {
  constructor(
    public readonly id: OrganizationID,
    public readonly code: OrganizationCode,
    public readonly name: OrganizationName,
    public readonly fiscalYearBegin: FiscalYearBegin,
    public readonly isCoachingAvailable: IsCoachingAvailable,
    public readonly isProposalEnabled: IsProposalEnabled,
    public readonly isLinkageEnabled: IsLinkageEnabled,
    public readonly isOpportunityGroupEnabled: IsOpportunityGroupEnabled,
  ) {
  }

  rename(newName: OrganizationName): Organization {
    assert(newName.validate() === null);
    return new Organization(
      this.id,
      this.code,
      newName,
      this.fiscalYearBegin,
      this.isCoachingAvailable,
      this.isProposalEnabled,
      this.isLinkageEnabled,
      this.isOpportunityGroupEnabled,
    );
  }

  changeFiscalYearBegin(newFiscalYearBegin: FiscalYearBegin) {
    return new Organization(
      this.id,
      this.code,
      this.name,
      newFiscalYearBegin,
      this.isCoachingAvailable,
      this.isProposalEnabled,
      this.isLinkageEnabled,
      this.isOpportunityGroupEnabled,
    );
  }

  changeCoachingAvailable(newIsCoachingAvailable: IsCoachingAvailable) {
    return new Organization(
      this.id,
      this.code,
      this.name,
      this.fiscalYearBegin,
      newIsCoachingAvailable,
      this.isProposalEnabled,
      this.isLinkageEnabled,
      this.isOpportunityGroupEnabled,
    );
  }

  changeProposalEnabled(newIsProposalEnabled: IsProposalEnabled) {
    return new Organization(
      this.id,
      this.code,
      this.name,
      this.fiscalYearBegin,
      this.isCoachingAvailable,
      newIsProposalEnabled,
      this.isLinkageEnabled,
      this.isOpportunityGroupEnabled,
    );
  }

  changeLinkageEnabled(newIsLinkageEnabled: IsLinkageEnabled) {
    return new Organization(
      this.id,
      this.code,
      this.name,
      this.fiscalYearBegin,
      this.isCoachingAvailable,
      this.isProposalEnabled,
      newIsLinkageEnabled,
      this.isOpportunityGroupEnabled,
    );
  }

  changeOpportunityGroupEnabled(newIsOpportunityGroupEnabled: IsOpportunityGroupEnabled) {
    return new Organization(
      this.id,
      this.code,
      this.name,
      this.fiscalYearBegin,
      this.isCoachingAvailable,
      this.isProposalEnabled,
      this.isLinkageEnabled,
      newIsOpportunityGroupEnabled,
    );
  }
}

export class OrganizationID {
  constructor(public readonly value: string) {
  }

  equals(obj: OrganizationID): boolean {
    return this.value === obj.value;
  }
}

export class OrganizationCode {
  constructor(public readonly value: string) {
  }
  validate(): ValidationError | null {
    if (this.value.length === 0) {
      return new ValidationError('組織コードは必須項目です。');
    }
    if (this.value.length > 255) {
      return new ValidationError('組織コードは255文字以下で設定して下さい。');
    }
    if (this.value.match(/^[a-z0-9]+([a-z0-9\-]*[a-z0-9]+)?$/) === null) {
      return new ValidationError('組織コードは半角英小文字、数字、ハイフンで設定して下さい。ただし、ハイフンは最初と最後には使用出来ません。');
    }
    return null;
  }
}

export class OrganizationName {
  constructor(public readonly value: string) {
  }

  validate(): ValidationError | null {
    if (this.value.length === 0) {
      return new ValidationError('組織名は必須項目です。');
    }
    return null;
  }
}

export class FiscalYearBegin {

  static rangeValidation = {
    pattern: /^[1-9]$|^1[0-2]$/,
    message: '期首は1から12の整数で設定してください。'
  } as const;

  static readonly list = Array(12).fill('').map((_, i) => i + 1);

  constructor(
    public readonly value: number,
  ) {
    if (!FiscalYearBegin.rangeValidation.pattern.test(this.value.toString())) {
      throw new ValidationError(FiscalYearBegin.rangeValidation.message);
    }
  }
}

export class IsCoachingAvailable {
  constructor(public readonly value: boolean) {}
}

export class IsProposalEnabled {
  constructor(public readonly value: boolean) {}
}

export class IsLinkageEnabled {
  constructor(public readonly value: boolean) {}
}

export class IsOpportunityGroupEnabled {
  constructor(public readonly value: boolean) {}
}
