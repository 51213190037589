import {Injectable} from '@angular/core';
import {ClientTypeRepository} from 'app/model/client-info-type/client-type.repository';
import {Observable} from 'rxjs';
import {ClientType} from 'app/model/client-info-type/client-type';
import {ServerApi} from '#infrastructure/api/server-api';

@Injectable({providedIn: 'root'})
export class ClientTypeRepositoryImpl implements ClientTypeRepository {
  constructor(
    private readonly serverApi: ServerApi
  ) {
  }

  save(clientType: ClientType): Observable<void> {
    return this.serverApi.clientTypeApi.update(clientType.id.value, {
      name: clientType.name.value,
      description: clientType.description.value,
      formLayoutID: clientType.formLayoutId?.value
    });
  }

  saveClientTypeOrder(clientTypes: ClientType[]): Observable<void> {
    return this.serverApi.clientTypeApi.sortOrders({
      ids: clientTypes.map(clientType => clientType.id.value)
    });
  }

  delete(clientType: ClientType): Observable<void> {
    return this.serverApi.clientTypeApi.delete(clientType.id.value);
  }
}
