import { Injectable } from "@angular/core";
import { ClientID } from "app/model/client/client";
import { Observable } from "rxjs";
import { ServerApi } from "#infrastructure/api/server-api";
import { ClientRelationshipFactory } from "app/model/client-relationship/client-relationship.factory";

@Injectable({providedIn: "root"})
export class ClientRelationshipFactoryImpl extends ClientRelationshipFactory {
  constructor(
    public readonly serverApi: ServerApi,
  ) {
    super();
  }

  create(clientID: ClientID, param: ClientID): Observable<void> {
    return this.serverApi.clientRelationshipApi.create(
      clientID.value,
      { relevantClientID: param.value }
    );
  }
}
