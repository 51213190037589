import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';

export type CreateContactHistoryTemplateRequest = CreateContactHistoryTemplateRequestData;

export interface CreateContactHistoryTemplateRequestData {
  name: string;
  content: string;
}

export type GetContactHistoryTemplatesResponse = GetContactHistoryTemplatesData[];

export interface GetContactHistoryTemplatesData {
  id: string;
  name: string;
  content: string;
}

export type GetContactHistoryTemplateByIDResponse = GetContactHistoryTemplateData;

export interface GetContactHistoryTemplateData {
  name: string;
  content: string;
}

export type UpdateContactHistoryTemplateRequest = UpdateContactHistoryTemplateRequestData;

export interface UpdateContactHistoryTemplateRequestData {
  name: string;
  content: string;
}

export type SortOrdersContactHistoryTemplateRequest = SortOrdersContactHistoryTemplateRequestData

export interface SortOrdersContactHistoryTemplateRequestData {
  ids: string[]
}

export class ContactHistoryTemplateApi {
  constructor(private readonly http: HttpClient) {}

  create(request: CreateContactHistoryTemplateRequest): Observable<void> {
    return this.http.post<void>('/api/contact-history-template', request);
  }

  list(): Observable<GetContactHistoryTemplatesResponse> {
    return this.http.get<GetContactHistoryTemplatesResponse>('/api/contact-history-templates');
  }

  get(templateID: string): Observable<GetContactHistoryTemplateByIDResponse> {
    return this.http.get<GetContactHistoryTemplateByIDResponse>(`/api/contact-history-template/${templateID}`);
  }

  update(templateID: string, request: UpdateContactHistoryTemplateRequest): Observable<void> {
    return this.http.put<void>(`/api/contact-history-template/${templateID}`, request);
  }

  delete(templateID: string): Observable<void> {
    return this.http.delete<void>(`/api/contact-history-template/${templateID}`);
  }

  sortOrders(request: SortOrdersContactHistoryTemplateRequest): Observable<void> {
    return this.http.post<void>('/api/contact-history-templates/sort-order', request);
  }
}
