import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type NurturingTypeResponse = {
  id: string,
  name: string,
  description: string,
}

export type ListNurturingTypeResponse = NurturingTypeResponse[];

export type CreateNurturingTypeRequest = {
  name: string,
  description: string,
};

export type UpdateNurturingTypeRequest = CreateNurturingTypeRequest;

export type SortNurturingTypeRequest = {
  ids: string[]
};

export class NurturingTypeApi {
  constructor(private readonly http: HttpClient) { }

  list(): Observable<ListNurturingTypeResponse> {
    return this.http.get<ListNurturingTypeResponse>(`/api/nurturing-types/list`);
  }

  get(id: string): Observable<NurturingTypeResponse> {
    return this.http.get<NurturingTypeResponse>(`/api/nurturing-types/${id}`);
  }

  create(request: CreateNurturingTypeRequest): Observable<void> {
    return this.http.post<void>('/api/nurturing-types', request);
  }

  update(id: string, request: UpdateNurturingTypeRequest): Observable<void> {
    return this.http.put<void>(`/api/nurturing-types/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/nurturing-types/${id}`);
  }

  saveOrders(request: SortNurturingTypeRequest): Observable<void> {
    return this.http.patch<void>(`/api/nurturing-types/sort`, request);
  }

}