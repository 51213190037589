import {
  OpportunityTypeDescription,
  OpportunityTypeName,
  OpportunityTypeID,
} from 'app/model/opportunity-type/opportunity-type';
import {ServerApi} from '#infrastructure/api/server-api';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { OpportunityTypeOpportunityContextSettingList, OpportunityTypeOpportunityContextSettingQueryService, PhaseOpportunityContextSettingList } from '#application/services/opportunity-type-opportunity-context-setting-query.service';
import { PhaseID, PhaseName } from 'app/model/sales-phase/sales-phase';
import { OpportunityContextID, OpportunityContextName } from 'app/model/opportunity-context/opportunity-context';

@Injectable({
  providedIn: 'root',
})
export class OpportunityTypeOpportunityContextSettingQueryServiceImpl extends OpportunityTypeOpportunityContextSettingQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  list(): Observable<OpportunityTypeOpportunityContextSettingList> {
    return this.serverApi.opportunityTypeOpportunityContextSettingApi
    .list()
    .pipe(
      map(response => response.map(item => ({
        isCompleted: item.isCompleted,
        opportunityType: {
          id: new OpportunityTypeID(item.opportunityType.id),
          name: new OpportunityTypeName(item.opportunityType.name),
          description: new OpportunityTypeDescription(item.opportunityType.description),
        }
      })))
    );
  }

  listByPhase(typeID: OpportunityTypeID): Observable<PhaseOpportunityContextSettingList> {
    return this.serverApi.opportunityTypeOpportunityContextSettingApi
    .listByPhase(typeID.value)
    .pipe(
      map(response => response.map((item, index) => ({
        index: index + 1,
        phase: {
          id: new PhaseID(item.phase.id),
          name: new PhaseName(item.phase.name),
        },
        opportunityContexts: item.opportunityContexts.map(v => ({
          id: new OpportunityContextID(v.id),
          name: new OpportunityContextName(v.name)
        }))
      })))
    );
  }
}
