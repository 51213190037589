import { OpportunityID } from 'app/model/opportunity/opportunity';
import { Observable } from 'rxjs';
import { FollowUpActionDetail, FollowUpActionDue, FollowUpActionSummary } from './follow-up-action';
import { FollowUpActionTypeID } from './follow-up-action-type';
import { UserID } from 'app/model/user/user';

export abstract class FollowUpActionFactory {
  abstract create(
    opportunityID: OpportunityID,
    followUpActionDue: FollowUpActionDue,
    followUpActionTypeID: FollowUpActionTypeID,
    assigneeID: UserID,
    followUpActionSummary: FollowUpActionSummary,
    followUpActionDetail: FollowUpActionDetail
  ): Observable<void>;
}
