/**
 * - Branded Typesの一意性を保証するために渡す文字列リテラル（= Branded Typeの型名）を列挙する
 * - 命名は'モデル名 + 値の型名'のアッパーキャメルケースとする
 * - ex) UserモデルのName値は'UserName'となる
 *
 * @property {string} - プロパティ名と値名は同じ
 */
export const BrandedModelTypes = {
  /**
   * 商談グループ
   */
  // 商談グループ
  OpportunityGroupID: 'OpportunityGroupID',
  OpportunityGroupNumber: 'OpportunityGroupNumber',
  OpportunityGroupTitle: 'OpportunityGroupTitle',
  OpportunityGroupItem: 'OpportunityGroupItem',
  // 商談グループタイプ
  OpportunityGroupTypeID: 'OpportunityGroupTypeID',
  OpportunityGroupTypeName: 'OpportunityGroupTypeName',
  OpportunityGroupTypeDescription: 'OpportunityGroupTypeDescription',
  // 商談グループワークフロー
  OpportunityGroupWorkflowID: 'OpportunityGroupWorkflowID',
  OpportunityGroupWorkflowName: 'OpportunityGroupWorkflowName',
  OpportunityGroupWorkflowDescription: 'OpportunityGroupWorkflowDescription',
  // 商談グループステータス
  OpportunityGroupStatusID: 'OpportunityGroupStatusID',
  OpportunityGroupStatusName: 'OpportunityGroupStatusName',
  OpportunityGroupStatusDescription: 'OpportunityGroupStatusDescription',
  /**
   * 表示項目
   */
  DisplayableItemName: 'DisplayableItemName',
  /**
   * 並び替え
   */
  SortableItemName: 'SortableItemName',
  /**
   * カレンダー設定
   */
  CalendarSettingEnableDayOfWeek: 'CalendarSettingEnableDayOfWeek',
  CalendarSettingStartTime: 'CalendarSettingStartTime',
  CalendarSettingCloseTime: 'CalendarSettingCloseTime',
  /**
   * イベント
   */
  EventID: 'EventID',
  EventBaseStart: 'EventBaseStart',
  EventVersion: 'EventVersion',
  ExceptionalEventVersion: 'ExceptionalEventVersion',
  EventName: 'EventName',
  EventStart: 'EventStart',
  EventEnd: 'EventEnd',
  EventRecurringUntil: 'EventRecurringUntil',
  EventPlace: 'EventPlace',
  EventUrl: 'EventUrl',
  EventNote: 'EventNote',
  /**
   * ミーティングボード設定
   */
  MeetingBoardSettingID: 'MeetingBoardSettingID',
  MeetingBoardSettingName: 'MeetingBoardSettingName',
  MeetingBoardSettingDescription: 'MeetingBoardSettingDescription',
  MeetingBoardSettingBookmark: 'MeetingBoardSettingBookmark',
  MeetingBoardSettingBookmarkCount: 'MeetingBoardSettingBookmarkCount',
} as const;
export type BrandedModelTypes = typeof BrandedModelTypes[keyof typeof BrandedModelTypes];
