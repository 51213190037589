import { Injectable } from '@angular/core';
import {ServerApi} from "#infrastructure/api/server-api";
import {MessageDialogService} from "#application/services/message-dialog.service";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {AdoptedReasonList, AdoptedReasonQueryService} from "#application/services/adopted-reason-query.service";
import {
  AdoptedReason,
  AdoptedReasonDescription,
  AdoptedReasonID,
  AdoptedReasonName
} from "app/model/adopted-reason/adopted-reason";

@Injectable({
  providedIn: 'root'
})
export class AdoptedReasonQueryServiceImpl extends AdoptedReasonQueryService {

  constructor(
    private readonly serverApi: ServerApi,
    private readonly messageDialogService: MessageDialogService,
  ) {
    super();
  }

  list(): Observable<AdoptedReasonList> {
    return this.serverApi.adoptedReasonApi.list()
      .pipe(
        map(res => res.map(v => ({
          id: new AdoptedReasonID(v.id),
          name: new AdoptedReasonName(v.name),
          description: new AdoptedReasonDescription(v.description)
        }))),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '採択理由一覧の取得'
          });
          return throwError(err);
        })
      );
  }

  get(id: AdoptedReasonID): Observable<AdoptedReason> {
    return this.serverApi.adoptedReasonApi.get(id.value)
      .pipe(
        map(res => ({
          id: new AdoptedReasonID(res.id),
          name: new AdoptedReasonName(res.name),
          description: new AdoptedReasonDescription(res.description)
        })),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '採択理由の取得'
          });
          return throwError(err);
        })
      );
  }
}
