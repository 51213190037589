import {ClientID} from 'app/model/client/client';
import {ContactHistoryContent, ContactHistoryID} from 'app/model/contact-history/contact-history';
import {LoginID} from 'app/model/user/user';
import {DateTime} from 'luxon';
import {Observable} from 'rxjs';
import {OpportunityID, OpportunityName, OpportunityNumber} from "app/model/opportunity/opportunity";

export interface ContactHistoriesListItem {
  id: ContactHistoryID,
  authorName: LoginID,
  content: ContactHistoryContent,
  opportunityID?: OpportunityID,
  opportunityNumber?: OpportunityNumber,
  opportunityName?: OpportunityName,
  createdDateTime: DateTime,
  isEdited: boolean,
  editedDateTime: DateTime
}

export type ContactHistoriesList = ContactHistoriesListItem[];
export type ContactHistoryData = ContactHistoriesListItem;

export abstract class ContactHistoryQueryService {
  abstract getContactHistories(clientID: ClientID): Observable<ContactHistoriesList>;

  abstract get(id: ContactHistoryID, clientID: ClientID): Observable<ContactHistoryData>;
}
