import {LocalizeKey} from '#application/services/authorize.service';
import {ConfirmService} from '#application/services/confirm.service';
import {LocalizedNameService} from '#application/services/localized-name.service';
import {MessageDialogService} from '#application/services/message-dialog.service';
import {OpportunityGroupFieldCommandService} from '#application/services/opportunity-group-field-command.service';
import {SnackBarService} from '#application/services/snack-bar.service';
import {
  CustomFieldName,
  CustomFieldDescription,
  NumberSettings,
  SelectSettings,
  CustomFieldCategory,
  CustomFieldId,
  CustomField
} from 'app/model/custom-field/custom-field';
import {CustomFieldFactory, CreateCustomFieldParam} from 'app/model/custom-field/custom-field.factory';
import {CustomFieldRepository} from 'app/model/custom-field/custom-field.repository';
import {FieldType} from 'app/model/custom-field/field-type';
import {ServerApiError, ServerApiErrorCode} from '#infrastructure/api/error';
import {messageFn} from '#utils/messages';
import {Injectable, inject} from '@angular/core';
import {Observable, tap, catchError, throwError, filter, concatMap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpportunityGroupFieldCommandServiceImpl implements OpportunityGroupFieldCommandService {
  private readonly _customFieldFactory = inject(CustomFieldFactory);
  private readonly _customFieldRepository = inject(CustomFieldRepository);
  private readonly _snackBarService = inject(SnackBarService);
  private readonly _confirmService = inject(ConfirmService);
  private readonly _messageDialogService = inject(MessageDialogService);
  private readonly _localizedNameService = inject(LocalizedNameService);

  add(name: CustomFieldName, description: CustomFieldDescription, type: FieldType, required: boolean, numberSettings?: NumberSettings, selectSettings?: SelectSettings): Observable<void> {
    const param: CreateCustomFieldParam = {
      category: CustomFieldCategory.OPPORTUNITY_GROUP,
      name,
      type,
      description,
      required,
      numberSetting: numberSettings,
      selectSetting: selectSettings
    }
    const fieldName = `${this._localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}項目`
    return this._customFieldFactory.create(param)
      .pipe(
        tap(() => this._snackBarService.show(messageFn(fieldName, 'CREATE_SUCCESS').message)),
        catchError((err: ServerApiError) => {
          if (err.code === ServerApiErrorCode.Conflict) {
            this._messageDialogService.notice({
              title: messageFn(fieldName, 'CREATE_NAME_CONFLICT_ERROR').title,
              message: messageFn(fieldName, 'CREATE_NAME_CONFLICT_ERROR').message
            });
          } else {
            this._messageDialogService.notice({
              message: messageFn(fieldName, 'CREATE_ERROR').message
            });
          }
          return throwError(() => err);
        })
      );
  }

  remove(field: CustomFieldId): Observable<void> {
    const fieldName = `${this._localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}項目`
    return this._confirmService.confirm({
      title: messageFn(fieldName, 'DELETE_CONFIRM').title,
      message: messageFn(fieldName, 'DELETE_CONFIRM').message,
    })
      .pipe(
        filter(result => result.isOK()),
        concatMap(() => this._customFieldRepository.remove(field, CustomFieldCategory.OPPORTUNITY_GROUP)),
        tap(() => this._snackBarService.show(messageFn(fieldName, 'DELETE_SUCCESS').message)),
        catchError(err => {
          this._messageDialogService.notice({
            title: messageFn(fieldName, 'DELETE_ERROR').title,
            message: messageFn(fieldName, 'DELETE_ERROR').message
          });
          return throwError(() => err);
        })
      );
  }

  save(field: CustomField): Observable<void> {
    const fieldName = `${this._localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}項目`
    return this._customFieldRepository.save(CustomFieldCategory.OPPORTUNITY_GROUP, field)
      .pipe(
        tap(() => this._snackBarService.show(messageFn(fieldName, 'UPDATE_SUCCESS').message)),
        catchError((err: ServerApiError) => {
          if (err.code === ServerApiErrorCode.Conflict) {
            this._messageDialogService.notice({
              title: messageFn(fieldName, 'UPDATE_NAME_CONFLICT_ERROR').title,
              message: messageFn(fieldName, 'UPDATE_NAME_CONFLICT_ERROR').message
            });
          } else if (err.code === ServerApiErrorCode.NotFound) {
            this._messageDialogService.notice({
              title: messageFn(fieldName, 'UPDATE_NOT_FOUND_ERROR').title,
              message: messageFn(fieldName, 'UPDATE_NOT_FOUND_ERROR').message
            });
          } else {
            this._messageDialogService.notice({
              message: messageFn(fieldName, 'UPDATE_ERROR').message
            });
          }
          return throwError(() => err);
        })
      );
  }

  sort(fieldIdList: CustomFieldId[]): Observable<void> {
    const fieldName = `${this._localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}項目`
    return this._customFieldRepository.saveOrders(CustomFieldCategory.OPPORTUNITY_GROUP, fieldIdList)
      .pipe(
        tap(() => this._snackBarService.show(messageFn(fieldName, 'SORT_SUCCESS').message)),
        catchError(err => {
          if (err instanceof ServerApiError && err.code === ServerApiErrorCode.Conflict) {
            this._messageDialogService.notice({
              title: messageFn(fieldName, 'SORT_ERROR').message,
              message: `別のユーザーにより${fieldName}が更新されていたため、並び順の更新に失敗しました。\n${fieldName}の一覧を更新し、操作をやり直してください。`,
            });
          } else {
            this._snackBarService.show(messageFn(fieldName, 'SORT_ERROR').message);
          }
          return throwError(() => err);
        })
      );
  }
}
