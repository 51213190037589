import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CreateOpportunityContextRequest, GetOpportunityContextResponse, SearchOpportunityContextListRequest, SearchOpportunityContextListResponse, UpdateOpportunityContextRequest } from "./server-opportunity-context-api";

export class CommonOpportunityContextApi {
  constructor(private readonly http: HttpClient) {}

  create(request: CreateOpportunityContextRequest): Observable<void> {
    return this.http.post<void>(`/api/common-opportunity-contexts`, request);
  }

  search(request: SearchOpportunityContextListRequest): Observable<SearchOpportunityContextListResponse> {
    return this.http.post<SearchOpportunityContextListResponse>(`/api/common-opportunity-context-list/search`, request);
  }

  get(id: string): Observable<GetOpportunityContextResponse> {
    return this.http.get<GetOpportunityContextResponse>(`/api/common-opportunity-contexts/${id}`,);
  }

  update(id: string, request: UpdateOpportunityContextRequest): Observable<void> {
    return this.http.put<void>(`/api/common-opportunity-contexts/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/common-opportunity-contexts/${id}`);
  }
}
