import {Observable} from 'rxjs';
import { OpportunityContextDescription, OpportunityContextLabelName, OpportunityContextName } from './opportunity-context';

export type CreateOpportunityContextParams = {
  name: OpportunityContextName,
  description: OpportunityContextDescription,
  labelNames: OpportunityContextLabelName[],
}

export abstract class OpportunityContextFactory {
  abstract create(params: CreateOpportunityContextParams): Observable<void>;
}
