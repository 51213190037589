import {Observable} from 'rxjs';
import {
  FollowUpActionTypeDescription,
  FollowUpActionTypeID,
  FollowUpActionTypeName,
  FollowUpActionTypes
} from 'app/model/follow-up-action/follow-up-action-type';

export interface UpdateFollowUpActionTypeParam {
  id: FollowUpActionTypeID,
  name: FollowUpActionTypeName,
  description: FollowUpActionTypeDescription,
}

export abstract class FollowUpActionTypeRepository {
  abstract list(): Observable<FollowUpActionTypes>;

  abstract saveAll(followUpActionTypes: FollowUpActionTypes): Observable<void>;

  abstract save(param: UpdateFollowUpActionTypeParam): Observable<void>;

  abstract remove(id: FollowUpActionTypeID): Observable<void>;

  abstract saveOrders(ids: FollowUpActionTypeID[]): Observable<void>;
}
