import {Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {ServerApi} from "#infrastructure/api/server-api";
import {map} from "rxjs/operators";
import {
  ProductBuiltinFieldQueryService,
  ProductFormLayoutQueryService
} from '#application/services/product-form-layout-query.service';
import {
  BuiltinField,
  BuiltinFieldId,
  BuiltinFieldItem,
  CustomFieldItem,
  DefaultFormLayout,
  FormLayout,
  FormLayoutCategory,
  FormLayoutId,
  FormLayoutName,
  SectionTitle,
  SectionTitleItem
} from 'app/model/form-layout/form-layout';
import {FormLayoutOutline} from '#application/services/form-layout-query.service';
import {
  GetFormLayoutResponse,
  ListFormLayoutResponse
} from '#infrastructure/api/server-form-layout-api';
import {ListBuiltinFieldResponse} from '#infrastructure/api/server-builtin-field-api';
import {GetDefaultFormLayoutResponse} from '#infrastructure/api/server-default-form-layout-api';
import {CustomFieldId} from 'app/model/custom-field/custom-field';

const getDefaultResponseToDefaultFormLayout = (response: GetDefaultFormLayoutResponse): DefaultFormLayout => {
  return new DefaultFormLayout(response.items.map(item => {
    switch (item.type) {
      case 'SECTION_TITLE':
        return new SectionTitleItem(
          new SectionTitle(item.title)
        );
      case `BUILTIN_FIELD`:
        return new BuiltinFieldItem(
          item.width,
          BuiltinFieldId.valueOf(item.builtinFieldID, FormLayoutCategory.PRODUCT)
        );
      default:
        const unexpected: never = item;
        throw Error(`${unexpected} is unexpected value`);
    }
  }));
};

const getResponseToFormLayout = (response: GetFormLayoutResponse): FormLayout => {
  return new FormLayout(
    new FormLayoutId(response.id),
    new FormLayoutName(response.name),
    response.items.map(item => {
      switch (item.type) {
        case 'SECTION_TITLE':
          return new SectionTitleItem(
            new SectionTitle(item.title)
          );
        case `CUSTOM_FIELD`:
          return new CustomFieldItem(
            item.width,
            new CustomFieldId(item.customFieldID)
          );
        case `BUILTIN_FIELD`:
          return new BuiltinFieldItem(
            item.width,
            BuiltinFieldId.valueOf(item.builtinFieldID, FormLayoutCategory.PRODUCT)
          );
        default:
          const unexpected: never = item;
          throw Error(`${unexpected} is unexpected value`);
      }
  }));
};

const listResponseToOutline = (response: ListFormLayoutResponse): FormLayoutOutline[] => {
  return response.map(listItem => ({
    id: new FormLayoutId(listItem.id),
    name: new FormLayoutName(listItem.name),
  }));
};

@Injectable({providedIn: 'root'})
export class ProductFormLayoutQueryServiceImpl implements ProductFormLayoutQueryService {
  constructor(
    private readonly serverApi: ServerApi
  ) {
  }

  getDefaultLayout(): Observable<DefaultFormLayout>{
    return this.serverApi.defaultFormLayoutApi.get(FormLayoutCategory.PRODUCT).pipe(
      map(getDefaultResponseToDefaultFormLayout)
    );
  }

  get(id: FormLayoutId): Observable<FormLayout> {
    return this.serverApi.formLayoutApi.get(FormLayoutCategory.PRODUCT, id.value).pipe(
      map(getResponseToFormLayout)
    );
  }

  list(): Observable<FormLayoutOutline[]> {
    return this.serverApi.formLayoutApi.list(FormLayoutCategory.PRODUCT).pipe(
      map(listResponseToOutline)
    );
  }
}

const listResponseToBuiltinFieldList = (response: ListBuiltinFieldResponse): BuiltinField[] => {
  return response.map(listItem => new BuiltinField(
    BuiltinFieldId.valueOf(listItem.id, FormLayoutCategory.PRODUCT),
    listItem.fieldType,
    listItem.needsToDisplay,
  ));
};

@Injectable({providedIn: 'root'})
export class ProductBuiltinFieldQueryServiceImpl implements ProductBuiltinFieldQueryService {
  constructor(
    private readonly serverApi: ServerApi
  ) {
  }

  list(): Observable<BuiltinField[]> {
    return this.serverApi.builtinFieldApi.list(FormLayoutCategory.PRODUCT).pipe(
      map(listResponseToBuiltinFieldList)
    );
  }
}
