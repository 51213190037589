export const enum ServerApiErrorCode {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  Unknown = 1000,
}

export const ErrorResponseTitle = {
  ResourceNotFoundError: 'ResourceNotFoundError',
  DuplicatedError: 'DuplicatedError',
  SystemError: 'SystemError',
  TooManyUserGroupError: 'TooManyUserGroupError',
  AcyclicUserGroupError: 'AcyclicUserGroupError',
} as const;
export type ErrorResponseTitle = typeof ErrorResponseTitle[keyof typeof ErrorResponseTitle];

export type ErrorResponse = {
  title: ErrorResponseTitle,
  description: string,
  resources: string[],
}

export class ServerApiError extends Error {
  constructor(
    public readonly code: ServerApiErrorCode,
    public readonly response: ErrorResponse,
  ) {
    super();
  }
}
