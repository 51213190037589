export class CoachingID {
  constructor(public readonly value: string) {}
}

/** 受けている（た）コーチングの実施状況 */
export const CoachingStatus = {
  FINISHED: 'FINISHED',
  CONTINUE: 'CONTINUE',
} as const;
export type CoachingStatus = typeof CoachingStatus[keyof typeof CoachingStatus];

/**
 * コーチング中の自由記述回答
 */
export class CoachingTextAnswer {

  static readonly MAX_LENGTH = 1000;

  constructor(public readonly value: string) {}
}

/** コーチングを受ける（た）タイミング */
export const CoachingTiming = {
  PRE: 'PRE',
  POST: 'POST',
} as const;
export type CoachingTiming = typeof CoachingTiming[keyof typeof CoachingTiming];
