import {
    LocalizationSettingCommandService,
    SaveLocalizationSettingInput
} from "#application/services/localization-setting-command.service";
import {Observable, throwError} from "rxjs";
import {Injectable} from "@angular/core";
import {ServerApi} from "#infrastructure/api/server-api";
import {catchError, concatMap, filter, tap} from "rxjs/operators";
import {messageFn, Target} from "#utils/messages";
import {SnackBarService} from "#application/services/snack-bar.service";
import {ConfirmService} from "#application/services/confirm.service";


@Injectable({
    providedIn: 'root'
})
export class LocalizationSettingCommandServiceImpl extends LocalizationSettingCommandService {
    constructor(private readonly serverApi: ServerApi,
                private readonly confirmService: ConfirmService,
                private readonly snackBarService: SnackBarService) {
        super();
    }

    save(input: SaveLocalizationSettingInput): Observable<void> {
        return this.confirmService.confirm({
            title: messageFn(Target.LOCALIZE_SETTING, 'SAVE_CONFIRM').title,
            message: messageFn(Target.LOCALIZE_SETTING, 'SAVE_CONFIRM').message
        }).pipe(
            filter(result => result.isOK()),
            concatMap(() => this.serverApi.localizationSettingApi.save(input.lang,
                {
                    list: input.list.map(item => ({
                        id: item.id.value,
                        localizedName: item.localizedName.value,
                    }))
                }
            )),
            tap(() => {
                this.snackBarService.show(messageFn(Target.LOCALIZE_SETTING, 'SAVE_SUCCESS').message);
            }),
            catchError((err) => {
                this.snackBarService.show(messageFn(Target.LOCALIZE_SETTING, 'SAVE_ERROR').message);
                return throwError(err);
            })
        )
    }
}
