import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { Nurturing, NurturingID } from "app/model/nurturing/nurturing";
import { Observable } from "rxjs";

export abstract class NurturingQueryService {
  abstract get(id: NurturingID): Observable<Result<
    Nurturing,
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >>;
}
