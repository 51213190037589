import { CoachingResultClientAddData, CoachingResultOpportunityAddData, CoachingStorageService, CoachingTemporaryActionAddData, FlowStackPage } from '#application/services/coaching-storage.service';
import { Page } from '#application/services/page-navigate.service';
import { ClientApi } from '#infrastructure/api/client-api';
import { Injectable, inject } from '@angular/core';
import {CoachingStorageKey} from "../../model/storage/coaching-storage";

@Injectable({
  providedIn: 'root'
})
export class CoachingStorageServiceImpl extends CoachingStorageService {

  private readonly clientApi = inject(ClientApi);

  pushFlowStack(page: FlowStackPage): void {
    const stack = this.lookupCoaching(CoachingStorageKey.COACHING_FLOW_STACK) as FlowStackPage[] ?? [];
    stack.push(page);
    this.storeCoaching(CoachingStorageKey.COACHING_FLOW_STACK, stack);
  }

  popFlowStack(): Page | undefined {
    const stack = this.lookupCoaching(CoachingStorageKey.COACHING_FLOW_STACK) as FlowStackPage[] ?? [];
    if (stack.length === 0) {
      return undefined;
    }

    if (!stack.every((p) => p in Page)) {
      this.deleteCoaching(CoachingStorageKey.COACHING_FLOW_STACK);
      return undefined;
    }

    const page = stack.pop();
    if (stack.length === 0) {
      this.deleteCoaching(CoachingStorageKey.COACHING_FLOW_STACK);
    } else {
      this.storeCoaching(CoachingStorageKey.COACHING_FLOW_STACK, stack);
    }
    return Page[page!];
  }

  clearFlowStack(): void {
    this.deleteCoaching(CoachingStorageKey.COACHING_FLOW_STACK);
  }

  storeResultClientAddData(data: CoachingResultClientAddData): void {
    this.storeCoaching(CoachingStorageKey.COACHING_RESULT_CLIENT_ADD, data);
  }

  takeResultClientAddData(): CoachingResultClientAddData | undefined {
    const data = this.lookupCoaching(CoachingStorageKey.COACHING_RESULT_CLIENT_ADD) as CoachingResultClientAddData;
    if (data !== undefined) {
      this.deleteCoaching(CoachingStorageKey.COACHING_RESULT_CLIENT_ADD);
    }
    if (typeof data?.clientID?.value !== 'string' ||
      typeof data?.clientName?.value !== 'string' ||
      typeof data?.clientNumber?.value !== 'number') {
      return undefined;
    }
    return data;
  }

  storeResultOpportunityAddData(data: CoachingResultOpportunityAddData): void {
    this.storeCoaching(CoachingStorageKey.COACHING_RESULT_OPPORTUNITY_ADD, data);
  }

  takeResultOpportunityAddData(): CoachingResultOpportunityAddData | undefined {
    const data = this.lookupCoaching(CoachingStorageKey.COACHING_RESULT_OPPORTUNITY_ADD) as CoachingResultOpportunityAddData;
    if (data !== undefined) {
      this.deleteCoaching(CoachingStorageKey.COACHING_RESULT_OPPORTUNITY_ADD);
    }
    if (typeof data?.opportunityID?.value !== 'string' ||
      typeof data?.opportunityName?.value !== 'string' ||
      typeof data?.opportunityNumber?.value !== 'number') {
      return undefined;
    }
    return data;
  }

  storeTemporaryActionAddData(data: CoachingTemporaryActionAddData): void {
    this.storeCoaching(CoachingStorageKey.COACHING_TEMPORARY_ACTION_ADD, data);
  }

  lookupTemporaryActionAddData(): CoachingTemporaryActionAddData | undefined {
    return this.lookupCoaching(CoachingStorageKey.COACHING_TEMPORARY_ACTION_ADD) as CoachingTemporaryActionAddData;
  }

  deleteTemporaryActionAddData(): void {
    this.deleteCoaching(CoachingStorageKey.COACHING_TEMPORARY_ACTION_ADD);
  }

  private storeCoaching(key: CoachingStorageKey, value: any): void {
    this.clientApi.storeSessionStorage(key, value);
  }
  private lookupCoaching(key: CoachingStorageKey): any {
    return this.clientApi.lookupSessionStorage(key) ?? undefined;
  }
  private deleteCoaching(key: CoachingStorageKey): void {
    this.clientApi.deleteSessionStorage(key);
  }
}
