import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {ApiFilterRequest, ApiPaginationRequest, FilterSchemaResponse} from "#infrastructure/api/server-filter-api";


type ApiOpportunityStatus = 'OPEN' | 'WON' | 'LOST' | 'DELETED';

export type WinOpportunityRequestV2 = {
  actualCloseDate: string,
  salesRecords: {
    date: string,
    amount: number,
  }[],
  wonNote: string,
  wonReasonIDs: string[],
};

export type GetOpportunityResponseV2 = {
  id: string,
  opportunityNumber: number,
  opportunityName: string,
  clientID: string,
  opportunityTypeID: string,
  phaseID: string,
  assignee: string,
  expectedAmount?: number,
  expectedCloseDate?: string,
  opportunityAccrualDate?: string,
  items?: {
    [customFieldId: string]: string,
  },
  status: {
    type: Extract<ApiOpportunityStatus, 'OPEN'>,
  }
  | {
    type: Extract<ApiOpportunityStatus, 'WON'>,
    actualAmount: number,
    actualCloseDate: string,
    salesRecords: {
      date: string,
      amount: number,
    }[],
    wonReasons: {
      id: string,
      name: string,
    }[],
    wonNote: string,
  }
  | {
    type: Extract<ApiOpportunityStatus, 'LOST'>,
    lostOrderDate: string,
    lostReasons: {
      id: string,
      name: string,
    }[],
    lostNote: string,
  }
  | {
    type: Extract<ApiOpportunityStatus, 'DELETED'>,
  },
};

export type GetWonInfoResponseV2 = {
  opportunityID: string,
  actualCloseDate?: string,
  salesRecords?: {
    date: string,
    amount: number,
  }[],
  wonReasons?: {
    id: string,
    name: string,
  }[],
  wonNote?: string,
};

export type GetLostInfoResponseV2 = {
  opportunityID: string,
  lostOrderDate?: string,
  lostReasons?: {
    id: string,
    name: string,
  }[],
  lostNote?: string,
};

export type GetOpportunityListResponse = {
  totalCount: number,
  results: ApiOpportunityListItem[];
};

export type ApiOpportunityListItem = {
  opportunity: ApiOpportunityItem,
  client: ApiClientItem,
  opportunityType: ApiOpportunityTypeItem,
  salesPhase: ApiSalesPhaseItem,
  phase: ApiPhaseItem,
  assignee: ApiAssigneeItem,
  status: ApiStatusItem,
  items: {
    [customFieldID: string]: ApiCustomFieldItem
  },
}

export type ApiOpportunityItem = {
  id: string;
  number: number;
  name: string;
  expectedAmount?: number;
  expectedCloseDate?: string;
  accrualDate: string,
}
export type ApiClientCategory = 'INDIVIDUAL' | 'CORPORATION'

export type ApiClientItem = {
  id: string;
  number: number;
  name: string;
  category: ApiClientCategory
}

export type ApiOpportunityTypeItem = {
  id: string;
  name: string;
}

export type ApiSalesPhaseItem = {
  id: string,
  name: string,
}

export type ApiPhaseItem = {
  id: string,
  name: string,
}

export type ApiAssigneeItem = {
  id: string,
  name: string,
}

export type ApiStatusItem = {
  type: 'WON',
  actualAmount: number,
  actualCloseDate: string,
  note: string,
  wonReasons: {
    id: string,
    name: string,
  }[],
  salesRecords: {
    date: string,
    amount: number,
  }[],
} | {
  type: 'LOST',
  lostOrderDate: string,
  note: string,
  lostReasons: {
    id: string,
    name: string,
  }[],
} | {
  type: 'OPEN',
} | {
  type: 'DELETED',
}

export type ApiCustomFieldItem = {
  type:
    | 'SINGLE_LINE_TEXT'
    | 'MULTI_LINE_TEXT'
    | 'URL'
    | 'MAIL_ADDRESS'
    | 'ADDRESS'
    | 'PHONE_NUMBER'
    | 'DATE'
    | 'DATETIME'
    | 'SELECT',
  name: string,
  value?: string,
} | {
  type: 'NUMBER',
  name: string,
  value?: number,
} | {
  type: 'USER',
  name: string,
  userID: string,
  userName?: string,
} | {
  type: 'ATTACHMENT',
  name: string,
  fileStatus: 'EXIST' | 'NOT_EXIST',
} | {
  type: 'CLIENT',
  name: string,
  clientID?: string,
  clientName?: string,
} | {
  type: 'CHECKBOX',
  name: string,
  value: boolean,
}

export class OpportunityV2Api {
  constructor(private readonly http: HttpClient) {}

  getByID(opportunityId: string): Observable<GetOpportunityResponseV2> {
    return this.http.get<GetOpportunityResponseV2>(`/api/opportunities/from-id/${opportunityId}`);
  };

  getByNumber(opportunityNumber: number): Observable<GetOpportunityResponseV2> {
    return this.http.get<GetOpportunityResponseV2>(`/api/opportunities/from-number/${opportunityNumber}`);
  };

  getWonInfo(opportunityId: string): Observable<GetWonInfoResponseV2> {
    return this.http.get<GetWonInfoResponseV2>(`/api/opportunities/${opportunityId}/won`);
  };

  getLostInfo(opportunityId: string): Observable<GetLostInfoResponseV2> {
    return this.http.get<GetLostInfoResponseV2>(`/api/opportunities/${opportunityId}/lost`);
  };

  getOpportunityList(request: ApiPaginationRequest & ApiFilterRequest): Observable<GetOpportunityListResponse> {
    return this.http.post<GetOpportunityListResponse>(
      `/api/opportunity-list/filter`,
      request,
    );
  }

  getFilterSchema(): Observable<FilterSchemaResponse> {
    return this.http.get<FilterSchemaResponse>(`/api/opportunity-list/filter`);
  }

  win(opportunityID: string, request: WinOpportunityRequestV2): Observable<void> {
    return this.http.put<void>(`/api/opportunities/${opportunityID}/win`, request);
  }
}
