import {DeclineReasonDescription, DeclineReasonID, DeclineReasonName} from "app/model/decline-reason/decline-reason";
import {Observable} from "rxjs";

export type DeclineReasonInputs = {
  name: DeclineReasonName,
  description: DeclineReasonDescription,
}

export abstract class DeclineReasonCommandService {
  abstract add(inputs: DeclineReasonInputs): Observable<void>;
  abstract update(id: DeclineReasonID, inputs: DeclineReasonInputs): Observable<void>;
  abstract delete(id: DeclineReasonID): Observable<void>;
  abstract sort(ids: DeclineReasonID[]): Observable<void>;
}
