import {Observable} from "rxjs";
import {ActionTypeDescription, ActionTypeName} from "app/model/action/action-type";

export interface CreateActionTypeParam {
  name: ActionTypeName,
  description: ActionTypeDescription,
}

export abstract class ActionTypeFactory {
  abstract create(param: CreateActionTypeParam): Observable<void>;
}
