import { MessageDialogService } from "#application/services/message-dialog.service";
import { WonReasonList, WonReasonQueryService } from "#application/services/won-reason-query.service";
import { WonReason, WonReasonDescription, WonReasonID, WonReasonName } from "app/model/opportunity/won-reason/won-reason";
import { ServerApi } from "#infrastructure/api/server-api";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class WonReasonQueryServiceImpl extends WonReasonQueryService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly messageDialogService: MessageDialogService,
  ) {
    super();
  }

  list(): Observable<WonReasonList> {
    return this.serverApi.wonReasonApi.list()
    .pipe(
      map(res => res.wonReasons.map(v => ({
        id: new WonReasonID(v.id),
        name: new WonReasonName(v.name),
        description: new WonReasonDescription(v.description)
      }))),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: '受注理由一覧の取得'
        });
        return throwError(err);
      })
    );
  }

  get(id: WonReasonID): Observable<WonReason> {
    return this.serverApi.wonReasonApi.get(id.value)
    .pipe(
      map(res => ({
        id: new WonReasonID(res.id),
        name: new WonReasonName(res.name),
        description: new WonReasonDescription(res.description)
      })),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: '受注理由の取得'
        });
        return throwError(err);
      })
    );
  }
}
