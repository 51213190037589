import {OpportunityTypeID} from 'app/model/opportunity-type/opportunity-type';
import {UserID} from 'app/model/user/user';
import {PhaseID} from 'app/model/sales-phase/sales-phase';
import {ClientID} from "app/model/client/client";
import {CustomFieldId, CustomFieldValue} from 'app/model/custom-field/custom-field';
import { LostReasonID, LostReasonName } from './lost-reason/lost-reason';
import { WonReasonID, WonReasonName } from './won-reason/won-reason';
import { FieldType } from '../custom-field/field-type';

/**
 * 商談ID
 */
export class OpportunityID {
  constructor(public readonly value: string) {
  }

  equals(object: OpportunityID) {
    return object.value === this.value;
  }
}

/**
 * 商談番号
 */
export class OpportunityNumber {
  constructor(public readonly value: number) {
  }
}

/**
 * 商談名
 */
export class OpportunityName {
  constructor(public readonly value: string) {
  }

  readonly fieldType = FieldType.SINGLE_LINE_TEXT;
  static readonly MAX_LENGTH = 255;
}

/**
 * 商談金額（見込み）
 */
export class ExpectedAmount {
  constructor(public readonly value: number) {
  }

  readonly fieldType = FieldType.NUMBER;
  static readonly MIN = 1;
  static readonly MAX = 999999999999;
}

/**
 * 商談ステータス
 */
export const OpportunityStatus = {
  OPEN: 'OPEN',
  WON: 'WON',
  LOST: 'LOST',
  DELETED: 'DELETED',
} as const;
export type OpportunityStatus = typeof OpportunityStatus[keyof typeof OpportunityStatus];

export const getDisplayValueOfOpportunityStatus = (status?: OpportunityStatus): string => {
  switch (status) {
    case OpportunityStatus.OPEN:
      return '進行中';
    case OpportunityStatus.WON:
      return '受注';
    case OpportunityStatus.LOST:
      return '失注';
    case OpportunityStatus.DELETED:
      return '削除済';
    default:
      return '不明';
  }
}

/**
 * 進行中に戻せるか判定
 */
export const canReopen = (status: OpportunityStatus | undefined): boolean => {
  return status === OpportunityStatus.WON
      || status === OpportunityStatus.LOST
      || status === OpportunityStatus.DELETED
}

/**
 * 契約予定日
 */
export class ExpectedCloseDate {
  constructor(
    public readonly value: Date
  ) {
  }
  readonly fieldType = FieldType.DATE;
}

/**
 * 商談発生日
 */
export class OpportunityAccrualDate {
  constructor(
    public readonly value: Date
  ) {
  }
  readonly fieldType = FieldType.DATE;
}

/**
 * カスタム商談項目
 */
export class OpportunityItem {
  constructor(
    public readonly fieldId: CustomFieldId,
    public readonly fieldValue: CustomFieldValue,
  ) {
  }

  static of(id: string, value: string) {
    return new OpportunityItem(new CustomFieldId(id), new CustomFieldValue(value));
  }
}

/**
 * 受注日
 */
export class ActualCloseDate {
  constructor(
    public readonly value: Date
  ) {
  }
}

/**
 * 売上計上日
 */
export class SalesRecordingDate {
  constructor(
    public readonly value: Date
  ) {
  }
}

/**
 * 売上金額
 */
export class SalesRecordingAmount {
  constructor(
    public readonly value: number
  ) {
  }
}

/**
 * （実際の）受注金額
 */
export class ActualAmount {
  constructor(
    public readonly value: number
  ) {
  }
}

/**
 * 受注理由詳細
 */
export class WonNote {
  constructor(
    public readonly value: string
  ) {
  }
}

/**
 * 失注日
 */
export class LostOrderDate {
  constructor(
    public readonly value: Date
  ) {
  }
}

/**
 * 失注理由詳細
 */
export class LostNote {
  constructor(
    public readonly value: string
  ) {
  }
}

/**
 * 受注理由のリスト
 */
export type WonReasons = ReadonlyArray<{
  id: WonReasonID,
  name: WonReasonName,
}>

/**
 * 失注理由のリスト
 */
export type LostReasons = ReadonlyArray<{
  id: LostReasonID,
  name: LostReasonName,
}>

/**
 * 受注理由名又は失注理由名をカンマとスペースでつなげた文字列に変換する。
 *
 * @param {WonReasons | LostReasons} reasons - 受注理由又は失注理由のリスト
 * @returns {string} - 名前をカンマでつなげた文字列
 */
export const reasonNamesSeparatedByComma =
(reasons: WonReasons | LostReasons): string => {
  return reasons.map(v => v.name.value).join(', ');
}

/**
 * 売上計上
 */
export type SalesRecord = {
  date: SalesRecordingDate,
  amount: SalesRecordingAmount,
};

export type OpportunitySource = {
  type: 'NURTURING',
  nurturingID: string,
} | undefined;

/**
 * 商談情報
 */
export type OpportunityV2 = Readonly<{
  opportunityID: OpportunityID,
  opportunityNumber: OpportunityNumber,
  opportunityName: OpportunityName,
  clientID: ClientID,
  opportunityTypeID: OpportunityTypeID,
  phaseID: PhaseID,
  assignee: UserID,
  expectedAmount?: ExpectedAmount,
  expectedCloseDate?: ExpectedCloseDate,
  opportunityAccrualDate?: OpportunityAccrualDate,
  items?: OpportunityItem[],
  status: {
    type: typeof OpportunityStatus.OPEN,
  }
  | {
    type: typeof OpportunityStatus.WON,
    actualAmount: ActualAmount,
    actualCloseDate: ActualCloseDate,
    salesRecords: SalesRecord[],
    wonReasons: WonReasons,
    wonNote: WonNote,
  }
  | {
    type: typeof OpportunityStatus.LOST,
    lostOrderDate: LostOrderDate,
    lostReasons: LostReasons,
    lostNote: LostNote,
  }
  | {
    type: typeof OpportunityStatus.DELETED,
  },
}>
