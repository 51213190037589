import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type GetReportCvrRequest = {
  opportunityTypeID: string,
  duration: {
    startYearMonth: string,
    months: number,
  },
  assignee: {
    type: string,
    userGroupID?: string,
  }
};

export type GetReportCvrResponse = {
  wonCount: number,
  wonRate: number,
  totalLostCount: number,
  conversionRates: {
    phase: {
      name: string,
      countPerPhase: number,
      lostCount: number,
    },
    rate: number,
  }[],
};

export type PersonalCvrReportRequest = {
  opportunityTypeID: string,
  duration: {
    startYearMonth: string,
    months: number,
  }
};

export class ReportCvrApi {
  private readonly _url = '/api/report-cvr';
  constructor(private readonly http: HttpClient) {}

  get(request: GetReportCvrRequest): Observable<GetReportCvrResponse> {
    return this.http.post<GetReportCvrResponse>(this._url, request);
  }

  getPersonalCvrReport(request: PersonalCvrReportRequest): Observable<GetReportCvrResponse> {
    return this.http.post<GetReportCvrResponse>('/api/my-cvr', request);
  }
}
