import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

type OpportunityStatusType = 'WON_AND_OPEN'| 'WON' | 'OPEN' | 'LOST';

type User = {
  id: string,
  name: string,
  isActive: boolean,
};

type OpportunityStatusInAmount = {
  name: OpportunityStatusType,
  amounts: {
    date: string,
    value: number,
  }[],
  totalAmount: number,
};

type OpportunityStatusInCount = {
  name: OpportunityStatusType,
  counts: {
    date: string,
    value: number,
  }[],
  totalCount: number,
};

export type AmountInReportOpportunityResponse = {
  opportunityTypes: {
    id: string,
    name: string,
    opportunityStatuses: OpportunityStatusInAmount[],
  }[],
  opportunityTypeTotal: {
    opportunityStatuses: OpportunityStatusInAmount[],
  },
};

export type CountInReportOpportunityResponse = {
  opportunityTypes: {
    id: string,
    name: string,
    opportunityStatuses: OpportunityStatusInCount[],
  }[],
  opportunityTypeTotal: {
    opportunityStatuses: OpportunityStatusInCount[],
  },
};

export type GetReportOpportunityV2Request = {
  duration: {
    type: 'DAILY',
    startDate: string,
    days: number,
  } | {
    type: 'MONTHLY',
    startYearMonth: string,
    months: number,
  },
  opportunityTypeIDs: string[],
  assignee: {
    type: 'ALL_USERS',
  } | {
    type: 'USER_GROUP',
    userGroupID: string,
  },
  status: Exclude<OpportunityStatusType, 'WON_AND_OPEN'>[],
};

export type GetPerUserReportOpportunityV2Request = {
  duration: {
    type: 'DAILY',
    startDate: string,
    days: number,
  } | {
    type: 'MONTHLY',
    startYearMonth: string,
    months: number,
  },
  opportunityTypeIDs: string[],
  userID: string,
  status: Exclude<OpportunityStatusType, 'WON_AND_OPEN'>[],
};

export type GetReportOpportunityV2Response = {
  amount: AmountInReportOpportunityResponse,
  amountsPerUser: {
    user: User,
    amount: AmountInReportOpportunityResponse,
  }[],
  count: CountInReportOpportunityResponse,
  countsPerUser: {
    user: User,
    count: CountInReportOpportunityResponse,
  }[],
};

export type GetPerUserReportOpportunityV2Response = {
  amount: AmountInReportOpportunityResponse,
  count: CountInReportOpportunityResponse,
};

export type GetPersonalReportOpportunityRequest = Omit<GetPerUserReportOpportunityV2Request, 'userID'>;

export type GetPersonalReportOpportunityResponse = GetPerUserReportOpportunityV2Response;

export type GetBySalesRecordReportOpportunityV2Response = Omit<GetReportOpportunityV2Response, 'count' | 'countsPerUser'>;

export type GetPerUserBySalesRecordReportOpportunityV2Response = Omit<GetPerUserReportOpportunityV2Response, 'count'>;

export type GetPersonalBySalesRecordReportOpportunityResponse = Omit<GetPersonalReportOpportunityResponse, 'count'>;

export class ReportOpportunityV2Api {
  constructor(private readonly http: HttpClient) {}

  /** 受注単位 */
  get(request: GetReportOpportunityV2Request): Observable<GetReportOpportunityV2Response> {
    return this.http.post<GetReportOpportunityV2Response>('/api/report-opportunities/won', request);
  }

  getPerUser(request: GetPerUserReportOpportunityV2Request): Observable<GetPerUserReportOpportunityV2Response> {
    return this.http.post<GetPerUserReportOpportunityV2Response>(`/api/report-opportunities/user/won`, request);
  }

  getPersonal(request: GetPersonalReportOpportunityRequest): Observable<GetPersonalReportOpportunityResponse> {
    return this.http.post<GetPersonalReportOpportunityResponse>(`/api/my-opportunities/won`, request);
  }

  /** 売上単位 */
  getBySalesRecord(request: GetReportOpportunityV2Request): Observable<GetBySalesRecordReportOpportunityV2Response> {
    return this.http.post<GetBySalesRecordReportOpportunityV2Response>('/api/report-opportunities/sales-record', request);
  }

  getPerUserBySalesRecord(request: GetPerUserReportOpportunityV2Request): Observable<GetPerUserBySalesRecordReportOpportunityV2Response> {
    return this.http.post<GetPerUserBySalesRecordReportOpportunityV2Response>(`/api/report-opportunities/user/sales-record`, request);
  }

  getPersonalBySalesRecord(request: GetPersonalReportOpportunityRequest): Observable<GetPersonalBySalesRecordReportOpportunityResponse> {
    return this.http.post<GetPersonalBySalesRecordReportOpportunityResponse>(`/api/my-opportunities/sales-record`, request);
  }
}
