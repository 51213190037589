import { ApiFieldType } from "#infrastructure/api/server-custom-field-api";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export type ApiBoundaryType = 'INCLUSIVE_EXCLUSIVE' | 'INCLUSIVE_INCLUSIVE' | 'EXCLUSIVE_INCLUSIVE' | 'EXCLUSIVE_EXCLUSIVE'
export type ApiConditionOperatorType = 'AND' | 'OR'
export type ApiOrderType = 'ASC' | 'DESC'
export type ApiNullsType = 'FIRST' | 'LAST'
export type ApiFilterType = 'EXISTS_AND_FILTERED' | 'EMPTY_OR_FILTERED'

export type ApiPaginationRequest = {
  pagination: {
    perPage: number,
    page: number,
  }
}

export type ApiFilterRequest = {
  filters: {
    [itemName: string]: {
      type: ApiFilterType,
      operator: {
        type: 'GREATER' | 'GREATER_OR_EQUAL' | 'LESS' | 'LESS_OR_EQUAL',
        value: string | number,
      }
    } | {
      type: ApiFilterType,
      operator: {
        type: 'EQUAL',
        value: string | number | boolean,
      }
    } | {
      type: ApiFilterType,
      operator: {
        type: 'IN',
        values: string[],
      }
    } | {
      type: ApiFilterType,
      operator: {
        type: 'BETWEEN',
        from: number,
        to: number,
        bounds: ApiBoundaryType,
      }
    } | {
      type: ApiFilterType,
      operator: {
        type: 'RANGE'
        from: string,
        range: number,
        bounds: ApiBoundaryType,
      }
    } | {
      type: ApiFilterType,
      operator: {
        type: 'KEYWORDS',
        values: string[],
        condition: ApiConditionOperatorType,
      }
    } | {
      type: 'EMPTY',
    } | {
      type: 'EXISTS',
    } | {
      type: ApiFilterType,
      operator: {
        type: 'OVERLAP',
        value: string[],
      }
    }
  },
  sorts: {
    name: string,
    order: ApiOrderType,
    nulls: ApiNullsType,
  }[]
}

export type ApiItemType =
  | 'STRING'
  | 'NUMBER'
  | 'DATE'
  | 'REFERENCE'
  | 'BOOLEAN'
  | 'ARRAY_STRING'

export type SchemaResponse = {
  filter: {
    [itemName: string]: ApiItemType
  },
  sort: string[]
}

export type FilterSchemaResponse = {
  filter: (ApiBuiltinItem | ApiCustomItem)[],
  sort: string[]
}

type ApiBuiltinItem = {
  itemName: string,
  itemCategory: 'BUILTIN',
  itemType: ApiItemType,
  displayName: string,
}

type ApiCustomItem = {
  itemName: string,
  itemCategory: 'CUSTOM',
  itemType: ApiItemType,
  displayName: string,
  customField: {
    id: string,
    type: 'NUMBER',
    unit: string,
  } | {
    id: string,
    type: 'SELECT',
    options: string[],
  } | {
    id: string,
    type: Exclude<ApiFieldType, 'NUMBER' | 'SELECT'>
  }
}

export type ApiFilterCategory =
  | 'opportunities'
  | 'clients'
  | 'products'
  | 'contacts';

export class FilterApi {
  constructor(private readonly http: HttpClient) {}

  /**
   * @duplicate #findBy()が#filter()に移行したら削除する
   */
  getSchema(category: ApiFilterCategory): Observable<SchemaResponse> {
    return this.http.get<SchemaResponse>(`/api/${category}/filter`);
  }
}
