import {ClientID} from 'app/model/client/client';

/**
 * 担当者（連絡先）
 */
export class Contact {
  constructor(
    public readonly id: ContactID,
    public readonly clientID: ClientID | undefined,
    public readonly departmentName: DepartmentName,
    public readonly roleName: ContactRoleName,
    public readonly displayName: ContactDisplayName,
    public readonly lastName: LastName,
    public readonly firstName: FirstName,
    public readonly lastNameKana: LastNameKana,
    public readonly firstNameKana: FirstNameKana,
    public readonly sex: Sex,
    public readonly phoneNumberInfoList: PhoneNumberInfoList,
    public readonly emailInfoList: EmailInfoList,
    public readonly note: Note
  ) {
  }
}

/**
 * 担当者ID
 */
export class ContactID {
  constructor(public readonly value: string) {
  }

  equals(another: ContactID): boolean {
    return this.value === another.value;
  }
}
/** 担当者（連絡先）番号 */
export class ContactNumber {
  constructor(public readonly value: number) {
  }
}

/** 部署名 */
export class DepartmentName {
  constructor(public readonly value: string) {
  }
}

/** 役職名 */
export class ContactRoleName {
  constructor(public readonly value: string) {
  }
}

export class ContactDisplayName {
  constructor(public readonly value: string) {
  }
}

/** 名前（姓） */
 export class LastName {
  constructor(public readonly value: string) {
  }
}

/** 名前（名） */
export class FirstName {
  constructor(public readonly value: string) {
  }
}

/** 名前（姓）カナ */
 export class LastNameKana {
  constructor(public readonly value: string) {
  }
}

/** 名前（名）カナ */
export class FirstNameKana {
  constructor(public readonly value: string) {
  }
}

/** 表示名 **/
export class DisplayName {
  constructor(public readonly value: string) {
  }
}

/** 性別 */
export class Sex {
  constructor(public readonly value: string) {
  }
}

/** 担当者に紐づく電話番号情報の一覧 */
export class PhoneNumberInfoList {
  constructor(public readonly list: PhoneNumberInfo[]) {
  }
}

/** 電話番号情報 */
export class PhoneNumberInfo {
  constructor(
    public readonly label: PhoneNumberLabel,
    public readonly phoneNumber: PhoneNumber
  ) {
  }
}

/** 電話番号情報のラベル */
export class PhoneNumberLabel {
  constructor(
    public readonly value: string
  ) {
  }
}

/** 電話番号を表すオブジェクト */
export class PhoneNumber {
  constructor(public readonly value: string) {
  }
}

/** 担当者に紐づくメールアドレス情報の一覧 */
export class EmailInfoList {
  constructor(public readonly list: EmailInfo[]) {
  }
}

/** メールアドレス情報 */
export class EmailInfo {
  constructor(public readonly label: EmailLabel, public readonly email: Email) {
  }
}

/**
 * メールアドレス情報のラベル
 */
export class EmailLabel {
  constructor(
    public readonly value: string
  ) {
  }
}

/** メールアドレスを表すオブジェクト */
export class Email {
  constructor(public readonly value: string) {
  }
}

/** 担当者に関するメモ */
export class Note {
  constructor(public readonly value: string) {
  }
}

export const fullName = (lastName: LastName, firstName: FirstName, separator: string = ' '): string => {
  return `${lastName.value} ${firstName.value}`.trim();
}

export const fullNameKana = (lastNameKana: LastNameKana, firstNameKana: FirstNameKana, separator: string = ' '): string => {
  return `${lastNameKana.value} ${firstNameKana.value}`.trim();
}

export class CreatedBy {
  constructor(public readonly value: string) {
  }
}

export class UpdatedBy {
  constructor(public readonly value: string) {
  }
}
