import {inject, Injectable} from "@angular/core";
import {LocalizedNameService} from "#application/services/localized-name.service";
import {AuthorizeService, LocalizeKey} from "#application/services/authorize.service";


@Injectable({providedIn: 'root'})
export class LocalizedNameServiceImpl extends LocalizedNameService {
  private _authorizeService = inject(AuthorizeService);

  get(key: LocalizeKey): string {
    const authInfo = this._authorizeService.getAuthInfo();
    return authInfo?.localize === undefined || authInfo.localize[key] === undefined
      ? this._originalName(key)
      : authInfo.localize[key];
  }

  private _originalName(key: LocalizeKey): string {
    switch (key) {
      case LocalizeKey.NURTURING: return 'ナーチャリング';
      case LocalizeKey.OPPORTUNITY_GROUP: return '商談グループ';
      default:
        const unexpected: never = key;
        throw new Error(`Unexpected security role: ${unexpected}`);
    }
  }
}
