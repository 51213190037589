import { ServerApi } from '#infrastructure/api/server-api';
import { Injectable } from '@angular/core';
import {
  OpportunityID
} from 'app/model/opportunity/opportunity';
import { LoseOpportunityDto, OpportunityRepository, SaveOpportunityDto } from 'app/model/opportunity/opportunity.repository';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { convertOpportunityItemsToKeyValueItems } from './opportunity.factory';

@Injectable({providedIn: 'root'})
export class OpportunityRepositoryImpl implements OpportunityRepository {
  constructor(private readonly serverApi: ServerApi) {
  }

  save(opportunityID: OpportunityID, opportunity: SaveOpportunityDto): Observable<void> {
    let expectedCloseDate;
    if (opportunity.expectedCloseDate !== undefined) {
      expectedCloseDate = DateTime.fromJSDate(opportunity.expectedCloseDate.value).toFormat('yyyy-MM-dd');
    }
    let opportunityAccrualDate;
    if (opportunity.opportunityAccrualDate !== undefined) {
      opportunityAccrualDate = DateTime.fromJSDate(opportunity.opportunityAccrualDate.value).toFormat('yyyy-MM-dd');
    }
    return this.serverApi.opportunityApi.updateOpportunity(opportunityID.value, {
      opportunityName: opportunity.opportunityName.value,
      opportunityTypeID: opportunity.opportunityTypeID.value,
      phaseID: opportunity.phaseID.value,
      assignee: opportunity.assignee.value,
      items: opportunity.items === undefined ? undefined : convertOpportunityItemsToKeyValueItems(opportunity.items),
      expectedAmount: opportunity.expectedAmount?.value,
      expectedCloseDate: expectedCloseDate,
      opportunityAccrualDate: opportunityAccrualDate,
    });
  }

  lose(dto: LoseOpportunityDto): Observable<void> {
    return this.serverApi.opportunityApi.lose(dto.opportunityID.value, {
      lostOrderDate: DateTime.fromJSDate(dto.lostOrderDate.value).toFormat('yyyy-MM-dd'),
      lostReasonIDs: dto.lostReasonIDs.map(v => v.value),
      lostNote: dto.lostNote.value
    });
  }

  delete(opportunityID: OpportunityID): Observable<void> {
    return this.serverApi.opportunityApi.delete(opportunityID.value);
  }

  reopen(opportunityID: OpportunityID): Observable<void> {
    return this.serverApi.opportunityApi.reopen(opportunityID.value);
  }
}
