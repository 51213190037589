import { FilterInput } from "#infrastructure/application/filter.service";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { ClientCategory } from "app/model/client-info-type/client-type";
import { ClientID, ClientName, ClientNumber } from "app/model/client/client";
import { CustomFieldId, CustomFieldName } from "app/model/custom-field/custom-field";
import { FieldType } from "app/model/custom-field/field-type";
import { OpportunityTypeID, OpportunityTypeName } from "app/model/opportunity-type/opportunity-type";
import { LostReason } from "app/model/opportunity/lost-reason/lost-reason";
import {
  ActualAmount,
  ActualCloseDate,
  ExpectedAmount,
  ExpectedCloseDate,
  LostNote,
  LostOrderDate,
  LostReasons,
  OpportunityAccrualDate,
  OpportunityID,
  OpportunityName,
  OpportunityNumber,
  OpportunityStatus,
  OpportunityV2,
  SalesRecord,
  WonNote,
  WonReasons
} from "app/model/opportunity/opportunity";
import { WonReason } from "app/model/opportunity/won-reason/won-reason";
import { PhaseID, PhaseName, SalesPhaseID, SalesPhaseName } from "app/model/sales-phase/sales-phase";
import { DisplayName, UserID } from "app/model/user/user";
import { Observable } from "rxjs";

export type GetWonInfoOutputV2 = {
  opportunityID: OpportunityID,
  actualCloseDate?: ActualCloseDate,
  salesRecords?: SalesRecord[],
  wonReasons?: WonReasons,
  wonNote?: WonNote,
};

export type GetLostInfoOutputV2 = {
  opportunityID: OpportunityID,
  lostOrderDate?: LostOrderDate,
  lostReasons?: LostReasons,
  lostNote?: LostNote,
};

export type GetOpportunityListInput = {
  perPage: number,
  page: number,
  filterInput: FilterInput,
}

export type OpportunityListV2 = {
  totalCount: number,
  results: OpportunityListItem[];
};

export type OpportunityListItem = {
  opportunity: OpportunityOutput,
  client: ClientOutput,
  opportunityType: OpportunityTypeOutput,
  salesPhase: SalesPhaseOutput,
  phase: PhaseOutput,
  assignee: AssigneeOutput,
  status: StatusOutput,
  customFields: CustomFieldOutput[],
}

export type OpportunityOutput = {
  id: OpportunityID,
  number: OpportunityNumber,
  name: OpportunityName,
  expectedAmount?: ExpectedAmount | undefined,
  expectedCloseDate?: ExpectedCloseDate | undefined,
  opportunityAccrualDate?: OpportunityAccrualDate,
}

export type ClientOutput = {
  id: ClientID,
  number: ClientNumber,
  name: ClientName,
  category: ClientCategory,
}

export type OpportunityTypeOutput = {
  id: OpportunityTypeID,
  name: OpportunityTypeName,

}

export type SalesPhaseOutput = {
  id: SalesPhaseID,
  name: SalesPhaseName,
}

export type PhaseOutput = {
  id: PhaseID,
  name: PhaseName,
}

export type AssigneeOutput = {
  id: UserID,
  name: DisplayName,
}

export type StatusOutput = {
  type: typeof OpportunityStatus.WON,
  actualAmount: ActualAmount,
  actualCloseDate: ActualCloseDate,
  note: WonNote,
  wonReasons: WonReason[],
  salesRecords: SalesRecord[],
} | {
  type: typeof OpportunityStatus.LOST,
  lostOrderDate: LostOrderDate,
  note: LostNote,
  lostReasons: LostReason[],
} | {
  type: typeof OpportunityStatus.OPEN,
} | {
  type: typeof OpportunityStatus.DELETED,
}

export type CustomFieldOutput = {
  type:
    | typeof FieldType.SINGLE_LINE_TEXT
    | typeof FieldType.MULTI_LINE_TEXT
    | typeof FieldType.URL
    | typeof FieldType.MAIL_ADDRESS
    | typeof FieldType.ADDRESS
    | typeof FieldType.PHONE_NUMBER
  id: CustomFieldId,
  name: CustomFieldName,
  value?: string,
} | {
  type: typeof FieldType.NUMBER,
  id: CustomFieldId,
  name: CustomFieldName,
  value?: number,
} | {
  type:
    | typeof FieldType.DATE
    | typeof FieldType.DATETIME,
  id: CustomFieldId,
  name: CustomFieldName,
  value?: Date,
} | {
  type: typeof FieldType.SELECT,
  id: CustomFieldId,
  name: CustomFieldName,
  values: string[],
} | {
  type: typeof FieldType.USER,
  id: CustomFieldId,
  name: CustomFieldName,
  userID?: UserID,
  userName?: DisplayName,
} | {
  type: typeof FieldType.ATTACHMENT,
  id: CustomFieldId,
  name: CustomFieldName,
  fileStatus: 'EXIST' | 'NOT_EXIST',
} | {
  type: typeof FieldType.CLIENT,
  id: CustomFieldId,
  name: CustomFieldName,
  clientID?: ClientID,
  clientName?: ClientName,
} | {
  type: typeof FieldType.CHECKBOX,
  id: CustomFieldId,
  name: CustomFieldName,
  value: boolean,
}

export abstract class OpportunityQueryV2Service {
  abstract getByID(id: OpportunityID): Observable<OpportunityV2>;
  abstract getByIDInResult(id: OpportunityID): Observable<Result<
    OpportunityV2,
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >>;
  abstract getByNumber(number: OpportunityNumber): Observable<OpportunityV2>;
  abstract getWonInfo(id: OpportunityID): Observable<GetWonInfoOutputV2>;
  abstract getLostInfo(id: OpportunityID): Observable<GetLostInfoOutputV2>;
  abstract getOpportunityList(input: GetOpportunityListInput): Observable<Result<
    OpportunityListV2,
    typeof GeneralFailure.Unexpected
  >>;
}
