import {ClientID} from 'app/model/client/client';

export class ClientRelationship {
  constructor(
    public readonly id: ClientRelationshipID,
    public readonly clientID: ClientID,
    public readonly relevantClientID: ClientID,
  ) {
  }
}

export class ClientRelationshipID {
  constructor(public readonly value: string) {}
}
