import {LocalizeKey} from "#application/services/authorize.service";
import {ConfirmService} from "#application/services/confirm.service";
import {FormLayoutAddParam} from "#application/services/form-layout-command.service";
import {LocalizedNameService} from "#application/services/localized-name.service";
import {MessageDialogService} from "#application/services/message-dialog.service";
import {
  OpportunityGroupFormLayoutCommandService
} from "#application/services/opportunity-group-form-layout-command.service";
import {SnackBarService} from "#application/services/snack-bar.service";
import {FormLayout, FormLayoutCategory, FormLayoutId} from "app/model/form-layout/form-layout";
import {FormLayoutFactory} from "app/model/form-layout/form-layout.factory";
import {FormLayoutRepository} from "app/model/form-layout/form-layout.repository";
import {messageFn} from "#utils/messages";
import {Injectable} from "@angular/core";
import {catchError, concatMap, filter, Observable, tap, throwError} from "rxjs";
import {ServerApiError, ServerApiErrorCode} from "#infrastructure/api/error";

@Injectable({
  providedIn: 'root'
})
export class OpportunityGroupFormLayoutCommandServiceImpl extends OpportunityGroupFormLayoutCommandService {
  constructor(
    private readonly formLayoutFactory: FormLayoutFactory,
    private readonly formLayoutRepository: FormLayoutRepository,
    private readonly confirmService: ConfirmService,
    private readonly snackBarService: SnackBarService,
    private readonly messageDialogService: MessageDialogService,
    private readonly localizedNameService: LocalizedNameService,
  ) {
    super();
  }

  add(param: FormLayoutAddParam): Observable<void> {
    const fieldName = `${this.localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}入力フォームレイアウト`
    return this.formLayoutFactory.create({
      name: param.name,
      category: FormLayoutCategory.OPPORTUNITY_GROUP,
      items: param.items
    })
      .pipe(
        tap(() => this.snackBarService.show(messageFn(fieldName, 'CREATE_SUCCESS').message)),
        catchError((err: ServerApiError) => {
          if (err.code === ServerApiErrorCode.Conflict) {
            this.messageDialogService.notice({
              title: messageFn(fieldName, 'CREATE_NAME_CONFLICT_ERROR').title,
              message: messageFn(fieldName, 'CREATE_NAME_CONFLICT_ERROR').message
            });
          } else {
            this.messageDialogService.notice({
              title: messageFn(fieldName, 'CREATE_ERROR').title,
              message: messageFn(fieldName, 'CREATE_ERROR').message
            });
          }
          return throwError(() => err);
        })
      );
  }

  remove(formLayoutId: FormLayoutId): Observable<void> {
    const fieldName = `${this.localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}入力フォームレイアウト`
    return this.confirmService.confirm({
      title: messageFn(fieldName, 'DELETE_CONFIRM').title,
      message: messageFn(fieldName, 'DELETE_CONFIRM').message
    })
      .pipe(
        filter(value => value.isOK()),
        concatMap(() => this.formLayoutRepository.remove(FormLayoutCategory.OPPORTUNITY_GROUP, formLayoutId)),
        tap(() => this.snackBarService.show(messageFn(fieldName, 'DELETE_SUCCESS').message)),
        catchError(err => {
          this.messageDialogService.notice({
            title: messageFn(fieldName, 'DELETE_ERROR').title,
            message: messageFn(fieldName, 'DELETE_ERROR').message
          });
          return throwError(err);
        })
      );
  }

  save(formLayout: FormLayout): Observable<void> {
    const fieldName = `${this.localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}入力フォームレイアウト`
    return this.formLayoutRepository.save(FormLayoutCategory.OPPORTUNITY_GROUP, formLayout)
      .pipe(
        tap(() => this.snackBarService.show(messageFn(fieldName, 'UPDATE_SUCCESS').message)),
        catchError((err: ServerApiError) => {
          if (err.code === ServerApiErrorCode.NotFound) {
            this.messageDialogService.notice({
              title: messageFn(fieldName, 'UPDATE_NOT_FOUND_ERROR').title,
              message: messageFn(fieldName, 'UPDATE_NOT_FOUND_ERROR').message
            });
          } else if (err.code === ServerApiErrorCode.Conflict) {
            this.messageDialogService.notice({
              title: messageFn(fieldName, 'UPDATE_NAME_CONFLICT_ERROR').title,
              message: messageFn(fieldName, 'UPDATE_NAME_CONFLICT_ERROR').message
            });
          } else {
            this.messageDialogService.notice({
              message: messageFn(fieldName, 'UPDATE_ERROR').message
            });
          }
          return throwError(() => err);
        })
      );
  }
}
