import { FormLayoutId } from "app/model/form-layout/form-layout";

/**
 * 提案タイプID
 */
export class ProposalTypeID {
  constructor(public readonly value: string) {}

  equals(another: ProposalTypeID): boolean {
    return this.value === another.value;
  }
}

/**
 * 提案タイプ名
 */
export class ProposalTypeName {
  static readonly MAX_LENGTH = 30;

  constructor(public readonly value: string) {}
}

/**
 * 提案タイプ説明
 */
export class ProposalTypeDescription {
  static readonly MAX_LENGTH = 100;

  constructor(public readonly value: string) {}
}

export const ProductDetailType = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
} as const;
export type ProductDetailType =
  typeof ProductDetailType[keyof typeof ProductDetailType];

/**
 * 提案タイプ
 */
export type ProposalType = Readonly<{
  id: ProposalTypeID,
  name: ProposalTypeName,
  description: ProposalTypeDescription,
  productDetailType: ProductDetailType,
  formLayoutID?: FormLayoutId,
}>
