import {OpportunityTypeDescription, OpportunityTypeName, OpportunityType, OpportunityTypeID,} from 'app/model/opportunity-type/opportunity-type';
import {SalesPhaseID, SalesPhaseName} from 'app/model/sales-phase/sales-phase';
import {Observable} from 'rxjs';
import {FormLayoutId, FormLayoutName} from "app/model/form-layout/form-layout";

export interface OpportunityTypeListItem {
  id: OpportunityTypeID;
  name: OpportunityTypeName;
  description: OpportunityTypeDescription;
  salesPhaseID: SalesPhaseID;
  salesPhaseName: SalesPhaseName;
  formLayoutId?: FormLayoutId;
  formLayoutName?: FormLayoutName;
}

export type OpportunityTypeList = OpportunityTypeListItem[];

/**
 * 商談タイプクエリサービス
 */
export abstract class OpportunityTypeQueryService {
  abstract listWithLayoutName(): Observable<OpportunityTypeList>;

  /** 削除済みでも取得できる */
  abstract get(id: OpportunityTypeID): Observable<OpportunityType>;

  abstract list(): Observable<OpportunityType[]>;
}
