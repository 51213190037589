import { ClientID } from "app/model/client/client";
import { NurturingTypeID, NurturingTypeName } from "app/model/nurturing-type/nurturing-type";
import {NurturingDuration, NurturingID, NurturingStatus, NurturingTitle} from "app/model/nurturing/nurturing";
import { DisplayName, UserID } from "app/model/user/user";
import { Observable } from "rxjs";

export type NurturingListOfClient = ReadonlyArray<{
  id: NurturingID,
  title: NurturingTitle,
  status: NurturingStatus,
  duration: NurturingDuration,
  nurturingType: Readonly<{
    id: NurturingTypeID,
    name: NurturingTypeName,
  }>,
  owner: Readonly<{
    id: UserID,
    name: DisplayName,
  }>,
}>

export abstract class ClientNurturingQueryService {
  abstract list(clientID: ClientID): Observable<NurturingListOfClient>;
}
