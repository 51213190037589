import { DateTime } from "luxon";

// 「期末」「四半期」等追加する構想がある
export type GoalPeriodType =
  | 'MONTHLY';

export const GoalReportTypeV2 = {
  CONTAINS_ESTIMATION: 'CONTAINS_ESTIMATION',
  WITHOUT_ESTIMATION: 'WITHOUT_ESTIMATION',
} as const;
export type GoalReportTypeV2 = typeof GoalReportTypeV2[keyof typeof GoalReportTypeV2];

export const GoalCategory = {
  /** 受注件数 */
  OPPORTUNITY_WON_COUNT: 'opportunity_won_count',
  /** 受注金額 */
  ACTUAL_AMOUNT: 'actual_amount',
  /** 売上金額 */
  SALES_RECORDING_AMOUNT: 'sales_recording_amount',
  /** 活動件数 */
  ACTION_COUNT: 'action_count',
} as const;
export type GoalCategory = typeof GoalCategory[keyof typeof GoalCategory];

export const GoalCategoryForReport = {
  /** 受注件数 */
  OPPORTUNITY_WON_COUNT: 'opportunity-won-count',
  /** 受注金額 */
  ACTUAL_AMOUNT: 'actual-amount',
  /** 売上金額 */
  SALES_RECORDING_AMOUNT: 'sales-recording-amount',
  /** 活動件数 */
  ACTION_COUNT: 'action-count',
} as const;
export type GoalCategoryForReport = typeof GoalCategoryForReport[keyof typeof GoalCategoryForReport];

export type MonthlyGoal = {
  yearMonth: DateTime,
  goal: number,
};
