/**
 * 商談フェーズ（個別のフェーズを束ねた概念）
 */
export class SalesPhase {
  constructor(
    public readonly id: SalesPhaseID,
    public readonly name: SalesPhaseName,
    public readonly list: Phase[],
  ) {}

  findIndex(p: Phase): number {
    return this.list.findIndex((v) => v.equals(p));
  }

  findPhaseByID(id: PhaseID): Phase | undefined{
    return this.list.find((p)=> p.id.equals(id));
  }

  /**
   * 商談フェーズの順番を比較する
   *
   * - 戻り値が負の数：第1引数の方が手前
   * - 戻り値が0　　 ：同じ
   * - 戻り値が正の数：第2引数の方が手前
   * - undefined   ：引数で与えられたフェーズの一方または両方が商談フェーズ内に含まれていない
   *
   * @param a
   * @param b
   * @returns
   */
  compare(a: Phase, b: Phase): number | undefined{
    const ai = this.findIndex(a);
    const bi = this.findIndex(b);
    if (ai < 0 || bi < 0) return;
    return ai - bi;
  }

  getPhaseListBetween(startInclusive: PhaseID, endInclusive: PhaseID): Phase[] {
    const startPhase = this.list.find(phase => phase.id.value === startInclusive.value);
    const endPhase = this.list.find(phase => phase.id.value === endInclusive.value);
    if (startPhase === undefined || endPhase === undefined) return [];

    const indexes = [this.list.indexOf(startPhase), this.list.indexOf(endPhase)];
    const upperIndex = Math.max(...indexes);
    const lowerIndex = Math.min(...indexes);

    return this.list.filter(phase => {
      const index = this.list.indexOf(phase) ?? -1;
      return index >= lowerIndex && index <= upperIndex;
    });
  }
}

/**
 * 組織内で商談フェーズを一意に特定するID
 */
export class SalesPhaseID {
  constructor(public readonly value: string) {}
}

/**
 * 商談フェーズの名称
*/
export class SalesPhaseName {
  static readonly MAX_LENGTH = 20;
  constructor(public readonly value: string) {}
}

/**
 * 商談フェーズを構成する個別のフェーズ
 */
export class Phase {
  constructor(
    public readonly id: PhaseID,
    public readonly name: PhaseName,
    public readonly definition: Definition,
    public readonly successCriteria: SuccessCriteria,
  ) {}

  equals(arg: Phase) {
    return this.id.equals(arg.id);
  }
}

/**
 * 商談フェーズを構成する各フェーズを一意に識別するID
 */
export class PhaseID {
  constructor(public readonly value: string) {}

  equals(obj: PhaseID){
    return this.value === obj.value;
  }
}

/**
 * フェーズの名称
 */
export class PhaseName {
  static readonly MAX_LENGTH = 15;
  constructor(public readonly value: string) {}
}

/**
 * フェーズの定義
 */
export class Definition {
  static readonly MAX_LENGTH = 150;
  constructor(public readonly value: string) {}
}

/**
 * フェーズの達成条件
 */
export class SuccessCriteria {
  static readonly MAX_LENGTH = 300;
  constructor(public readonly value: string) {}
}
