import { AuthorizeService } from '#application/services/authorize.service';
import { MetaUserAuthorizeService } from '#application/services/meta-user-authorize.service';
import { Page, PageNavigateService } from '#application/services/page-navigate.service';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, Validators } from '@angular/forms';
import { SecurityRole } from 'app/model/security-role/security-role';
import { Observable } from 'rxjs';
import { LoginButtonComponent } from '../login-button/login-button.component';
import { LoginIdFormComponent } from '../login-id-form/login-id-form.component';
import { PasswordFormComponent } from '../password-form/password-form.component';
import { ResponsiveComponent } from '#presentation/components/responsive/responsive.component';
import { BreakpointObserver } from '@angular/cdk/layout';

export type LoginContainerUser = 'USER' | 'META_USER';

@Component({
  selector: 'sn-login-container',
  standalone: true,
  imports: [
    CommonModule,
    LoginIdFormComponent,
    PasswordFormComponent,
    LoginButtonComponent,
  ],
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class LoginContainerComponent extends ResponsiveComponent {
  private readonly _authorizeService = inject(AuthorizeService);
  private readonly _metaUserAuthorizeService = inject(MetaUserAuthorizeService);
  private readonly _pageNavigateService = inject(PageNavigateService);
  private readonly _destroyRef = inject(DestroyRef);

  @Input({ required: true })
  user: LoginContainerUser = 'USER';

  readonly loginIDControl = new FormControl<string>('', {
    validators: Validators.required,
    nonNullable: true,
  });
  readonly passwordControl = new FormControl<string>('', {
    validators: Validators.required,
    nonNullable: true,
  });

  constructor(
    breakpointObserver: BreakpointObserver
  ) {
    super(breakpointObserver);
    if (this._authorizeService.isAuthenticated()) {
      this._navigateBySecurityRole();
    }
  }

  login(): void {
    if (this.disableLogin()) return;
    this._login$(this.user, this.loginIDControl.value, this.passwordControl.value)
    .pipe(takeUntilDestroyed(this._destroyRef))
    .subscribe(succeeded => {
      if (succeeded) {
        this._navigateBySecurityRole();
      } else {
        this.loginIDControl.setErrors({ unauthorized: true });
        this.passwordControl.setErrors({ unauthorized: true });
        this.loginIDControl.markAsTouched();
        this.passwordControl.markAsTouched();
      }
    });
  }

  disableLogin(): boolean {
    return (this.loginIDControl.errors != null) || (this.passwordControl.errors != null);
  }

  private _login$(
    user: LoginContainerUser,
    loginID: string,
    password: string
  ): Observable<boolean> {
    switch (user) {
      case 'USER':
        return this._authorizeService.login(loginID, password);
      case 'META_USER':
        return this._metaUserAuthorizeService.login(loginID, password);
      default:
        const _: never = user;
        throw new Error(`Unknown user: ${_}`);
    }
  }

  private _navigateBySecurityRole(): void {
    const authInfo = this._authorizeService.getAuthInfo();
    if (authInfo.securityRoles.includes(SecurityRole.MEMBER)) {
      this._pageNavigateService.navigate(Page.Action)
      return;
    } else if (authInfo.securityRoles.includes(SecurityRole.MANAGER)) {
      this._pageNavigateService.navigate(Page.ReportsGoal);
      return;
    } else if (authInfo.securityRoles.includes(SecurityRole.SYSTEM_ADMINISTRATOR)) {
      this._pageNavigateService.navigate(Page.ConfigUser);
      return;
    } else if (authInfo.securityRoles.includes(SecurityRole.CONSULTANT)) {
      this._pageNavigateService.navigate(Page.ConsultingAssignedOrganizationList);
      return;
    } else if (authInfo.securityRoles.includes(SecurityRole.ADMIN)) {
      this._pageNavigateService.navigate(Page.AdminOrganization)
      return;
    } else {
      this._pageNavigateService.navigate(Page.ErrorNotFound);
    }
  }
}
