import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type SearchGoalConfirmationRequest = {
  yearMonth: String,
  assignee: {
    type: string,
    userGroupID?: string,
  },
};

export type SearchGoalConfirmationResponse = {
  goalsMonthlyTotal: {
    monthlyGoals: {
      yearMonth: string,
      goal: number,
    }[],
    totalGoal: number,
  },
  goalsPerUsers: {
    user: {
      id: string,
      name: string,
      isActive: boolean,
    },
    monthlyGoals: {
      yearMonth: string,
      goal: number,
    }[],
  }[],
};

export class GoalConfirmationApi {
  private readonly _url = '/api/goal-confirmation';
  constructor(private readonly http: HttpClient) {}

  search(request: SearchGoalConfirmationRequest): Observable<SearchGoalConfirmationResponse> {
    return this.http.post<SearchGoalConfirmationResponse>(this._url, request);
  }
}
