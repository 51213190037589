import { AuthorizeService, LocalizeKey } from "#application/services/authorize.service";
import {
  LocalizationSettingList,
  LocalizationSettingQueryService
} from "#application/services/localization-setting-query.service";
import { LocalizedName, NameId, OriginalName } from "app/model/localize/localize";
import { ServerApi } from "#infrastructure/api/server-api";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class LocalizationSettingQueryServiceImpl extends LocalizationSettingQueryService {
  private readonly _authorizeService = inject(AuthorizeService);

  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  list(lang: string): Observable<LocalizationSettingList> {
    return this.serverApi.localizationSettingApi
      .list(lang)
      .pipe(
        map(response => response.list.map(item => ({
          id: new NameId(item.id),
          originalName: new OriginalName(item.originalName),
          localizedName: new LocalizedName(item.localizedName),
        }))),
        map(settings => {
          if (this._authorizeService.isOpportunityGroupEnabled()) return settings;
          return settings.filter(setting => setting.id.value !== LocalizeKey.OPPORTUNITY_GROUP);
        }),
      );
  }
}
