import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Result } from 'app/lib/result/result';
import { OrganizationID } from 'app/model/organization/organization';
import { DisplayName, MailAddress, UserAccessLevel, UserID, LoginID } from 'app/model/user/user';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

export interface UserDetail {
  id: UserID,
  organizationID: OrganizationID,
  loginID: LoginID,
  name: DisplayName,
  mailAddress: MailAddress,
  accessLevels: UserAccessLevel[]
}

export interface UserListItem {
  id: UserID,
  loginID: LoginID,
  name: DisplayName,
  mailAddress: MailAddress,
  isActive: boolean,
  lastLoginDatetime?: DateTime,
  accessLevels: UserAccessLevel[]
}

export type UserList = UserListItem[];

export type SearchSelectableUserListInput = Readonly<{
  pagination: {
    perPage: number,
    page: number,
  },
  name: string,
}>

export type UserListItemForSelectableSearch = Readonly<{
  id: UserID,
  name: DisplayName,
}>
export type UserListForSelectableSearch = Readonly<{
  totalCount: number,
  list: ReadonlyArray<UserListItemForSelectableSearch>,
}>

export abstract class UserQueryService {
  abstract getUserDetail(userID: string): Observable<UserDetail>;

  abstract getUsers(includeInactive?: boolean): Observable<UserList>;

  abstract getUsersWithInactive(): Observable<UserList>;

  abstract searchSelectable(input: SearchSelectableUserListInput): Observable<Result<
    UserListForSelectableSearch,
    typeof GeneralFailure.Unexpected
  >>;
}
