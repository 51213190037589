import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

type RangeType = 'DAYS' | 'MINUTES';

type AttendeeReply = 'PRESENCE' | 'PENDING' | 'ABSENCE';

type ApiNeverRecurringRule = {
  type: 'NEVER',
};
type ApiRecurringRecurringRule = {
  type: 'RECURRING',
  until?: string,
  dayOfWeeks: (
    | 'MONDAY'
    | 'TUESDAY'
    | 'WEDNESDAY'
    | 'THURSDAY'
    | 'FRIDAY'
    | 'SATURDAY'
    | 'SUNDAY'
  )[],
};
export type ApiRecurringRule = ApiNeverRecurringRule | ApiRecurringRecurringRule;

export type CreateEventV2Request = {
  name: string,
  start: string,
  end: string,
  rangeType: RangeType,
  recurringRule: ApiRecurringRule,
  requiredAttendees: string[],
  optionalAttendees: string[],
  place: string,
  url: string,
  note: string,
};

export type GetEventV2Response = {
  id: string,
  eventVersion: number,
  name: string,
  ownerID: string,
  ownerName: string,
  start: string,
  end: string,
  rangeType: RangeType,
  recurringRule: ApiRecurringRule,
  requiredAttendees: {
    id: string,
    name: string
    isActive: boolean,
  }[],
  optionalAttendees: {
    id: string,
    name: string
    isActive: boolean,
  }[],
  place: string,
  url: string,
  note: string,
};

export type UpdateEventV2Request = CreateEventV2Request & {
  eventVersion: number,
};

export type UpdateEventResponse = {
  baseStart: string,
}

export type DeleteEventRequest = {
  eventVersion: number,
};

export type GetEventOnBaseStartResponse = {
  id: string,
  eventVersion: number,
  exceptionalEventVersion: number,
  name: string,
  ownerID: string,
  ownerName: string,
  ownerIsActive: boolean,
  start: string,
  end: string,
  rangeType: RangeType,
  recurringRule: ApiRecurringRule,
  requiredAttendees: {
    id: string,
    name: string,
    isActive: boolean,
    reply: AttendeeReply
  }[],
  optionalAttendees: {
    id: string,
    name: string,
    isActive: boolean,
    reply: AttendeeReply
  }[],
  place: string,
  url: string,
  note: string,
};

export type UpdateExceptionalEventRequest = {
  eventVersion: number,
  exceptionalEventVersion: number,
  name: string,
  start: string,
  end: string,
  rangeType: RangeType,
  requiredAttendees: string[],
  optionalAttendees: string[],
  place: string,
  url: string,
  note: string,
};

export type DeleteExceptionalEventRequest = {
  eventVersion: number,
  exceptionalEventVersion: number,
};

export type ReplyEventRequest = {
  eventVersion: number,
  exceptionalEventVersion?: number,
  status: AttendeeReply,
};

export class EventV2Api {
  constructor(private readonly http: HttpClient) {}

  create(request: CreateEventV2Request): Observable<string> {
    return this.http.post<string>(`/api/events`, request);
  }

  /**
   * イベント取得
   */
  get(id: string): Observable<GetEventV2Response> {
    return this.http.get<GetEventV2Response>(`/api/events/${id}`);
  }

  /**
   * 繰り返しの中の特定日のイベント取得
   */
  getOnBaseStart(id: string, baseStart: string): Observable<GetEventOnBaseStartResponse> {
    return this.http.get<GetEventOnBaseStartResponse>(`/api/events/${id}/${baseStart}`);
  }

  update(id: string, request: UpdateEventV2Request): Observable<UpdateEventResponse> {
    return this.http.put<UpdateEventResponse>(`/api/events/${id}`, request);
  }

  delete(id: string, request: DeleteEventRequest): Observable<void> {
    return this.http.request<void>('delete', `/api/events/${id}`, { body: request });
  }

  updateExceptionalEvent(id: string, baseStart: string, request: UpdateExceptionalEventRequest): Observable<void> {
    return this.http.put<void>(`/api/events/${id}/${baseStart}`, request);
  }

  deleteExceptionalEvent(id: string, baseStart: string, request: DeleteExceptionalEventRequest): Observable<void> {
    return this.http.request<void>('delete', `/api/events/${id}/${baseStart}`, { body: request });
  }

  reply(id: string, baseStart: string, request: ReplyEventRequest): Observable<void> {
    return this.http.post<void>(`/api/events/${id}/${baseStart}/replies`, request);
  }
}
