import { CoachingTiming } from "app/model/coaching/coaching";
import { InsightID } from "app/model/insight/insight";
import { FilterPredicateType } from "app/model/search-filter/search-filter";
import { Observable } from "rxjs";

export type IncorporatedInsightByFilterInputs = {
  resultTotal: number,
  filter: {
    question: {
      type: typeof FilterPredicateType.contains,
      value: string,
    },
    opportunityContextID: {
      type: typeof FilterPredicateType.anyMatch,
      values: string[],
    },
    incorporated: {
      type: typeof FilterPredicateType.anyMatch,
      values: ('TRUE' | 'FALSE')[],
    },
    coachingTiming: {
      type: typeof FilterPredicateType.anyMatch,
      values: CoachingTiming[],
    },
  }
}

export abstract class IncorporatedInsightCommandService {
  abstract register(id: InsightID): Observable<void>
  abstract unregister(id: InsightID): Observable<void>
  abstract registerInBulk(inputs: IncorporatedInsightByFilterInputs): Observable<void>
  abstract unregisterInBulk(inputs: IncorporatedInsightByFilterInputs): Observable<void>
}
