import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

export type CreateAdoptedReasonRequest = {
  name: string,
  description: string,
}

export type UpdateAdoptedReasonRequest = {
  name: string,
  description: string,
}

export type SortAdoptedReasonRequest = {
  ids: string[],
}

type AdoptedReasonData = {
  id: string,
  name: string,
  description: string,
}

export type ListAdoptedReasonResponse =  AdoptedReasonData[];

export type GetAdoptedReasonResponse = AdoptedReasonData;

export class AdoptedReasonApi {

  constructor(private readonly http: HttpClient) {}

  create(request: CreateAdoptedReasonRequest): Observable<void> {
    return this.http.post<void>(`/api/adopted-reasons`, request);
  }

  update(id: string, request: UpdateAdoptedReasonRequest): Observable<void> {
    return this.http.put<void>(`/api/adopted-reasons/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/adopted-reasons/${id}`);
  }

  sort(request: SortAdoptedReasonRequest): Observable<void> {
    return this.http.patch<void>(`/api/adopted-reasons/sort`, request);
  }

  list(): Observable<ListAdoptedReasonResponse> {
    return this.http.get<ListAdoptedReasonResponse>(`/api/adopted-reasons/list`);
  }

  get(id: string): Observable<GetAdoptedReasonResponse> {
    return this.http.get<GetAdoptedReasonResponse>(`/api/adopted-reasons/${id}`);
  }

}
