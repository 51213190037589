import { CustomFieldId } from "app/model/custom-field/custom-field";
import { NurturingTypeID } from "app/model/nurturing-type/nurturing-type";
import { Observable } from "rxjs";

export type SaveNurturingHearingSettingInputs = {
  nurturingTypeID: NurturingTypeID,
  beforeHearingItems: CustomFieldId[],
  afterHearingItems: CustomFieldId[],
};

export abstract class NurturingHearingSettingCommandService {
  abstract save(inputs: SaveNurturingHearingSettingInputs): Observable<void>;
}
