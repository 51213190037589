import { OpportunityGroupWorkflow, OpportunityGroupWorkflowID } from "app/model/opportunity-group/opportunity-group-workflow";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { Observable } from "rxjs";

export abstract class OpportunityGroupWorkflowQueryService {
  abstract list(): Observable<Result<
    OpportunityGroupWorkflow[],
    typeof GeneralFailure.Unexpected
  >>;

  abstract get(id: OpportunityGroupWorkflowID): Observable<Result<
    OpportunityGroupWorkflow,
    typeof GeneralFailure.Unexpected
  >>;
}
