import { ConsultantList, ConsultantQueryService } from "#application/services/consultant-query.service";
import { AssignedOrganization, Consultant, ConsultantID, DisplayName, MailAddress, ConsultantLoginID } from "app/model/consultant/consultant";
import { OrganizationID, OrganizationName } from "app/model/organization/organization";
import { ServerApi } from "#infrastructure/api/server-api";
import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ConsultantQueryServiceImpl extends ConsultantQueryService {
  constructor(
    private readonly serverApi: ServerApi,
  ) {
    super();
  }

  list(): Observable<ConsultantList> {
    return this.serverApi.consultantApi.list().pipe(
      map(list =>
        list.map(item => ({
          id: new ConsultantID(item.id),
          loginID: new ConsultantLoginID(item.userName),
          displayName: new DisplayName(item.displayName),
          mailAddress: new MailAddress(item.mailAddress),
          assignedOrganizationType: item.assignedOrganizationType,
          selectedOrganizationNames: item.selectedOrganizationNames === undefined
            ? undefined
            : item.selectedOrganizationNames.map(name => new OrganizationName(name)),
          lastLoginDateTime: item.lastLoginDateTime === undefined
            ? undefined
            : DateTime.fromFormat(item.lastLoginDateTime, 'yyyy-MM-dd HH:mm:ss', { zone: 'UTC' })
        }))
      )
    );
  }

  get(id: ConsultantID): Observable<Consultant> {
    return this.serverApi.consultantApi.get(id.value).pipe(
      map(data => new Consultant(
        new ConsultantID(data.id),
        new ConsultantLoginID(data.userName),
        new DisplayName(data.displayName),
        new MailAddress(data.mailAddress),
        new AssignedOrganization(
          data.assignedOrganizationType,
          data.selectedOrganizations === undefined
            ? undefined
            : data.selectedOrganizations.map(v => new OrganizationID(v))
        )
      ))
    );
  }
}
