import { AuthorizeInfo, AuthorizeService } from '#application/services/authorize.service';
import { Page, PageNavigateService } from '#application/services/page-navigate.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { SecurityRole } from 'app/model/security-role/security-role';
import { environment } from 'environments/environment';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'sn-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, OnDestroy {
  private readonly _authorizeService = inject(AuthorizeService);
  private readonly _onDestroy$ = new Subject<void>();
  private readonly pageNavigateService = inject(PageNavigateService);

  readonly env = environment;

  @Input()
  showMenuToggle = true;

  @Output()
  clickToggle: EventEmitter<void> = new EventEmitter<void>();

  login = false;

  showAccountMenu = false;

  authInfo?: AuthorizeInfo | null;

  showCalendarSetting = false;

  ngOnInit(): void {
    this.login = this._authorizeService.isAuthenticated();
    if(this.login) {
      this.authInfo = this._authorizeService.getAuthInfo();
      this.showCalendarSetting = this.authInfo.securityRoles.includes(SecurityRole.MEMBER);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
  }

  logout(): void {
    this._authorizeService.logout()
    .pipe(takeUntil(this._onDestroy$))
    .subscribe();
  }

  calendarSetting(): void {
    this.pageNavigateService.navigate(Page.CalendarSetting);
  }

  openUserManualPage() {
    window.open('https://doc.salesnavi.co.jp/manual/', '_blank');
  }

}
