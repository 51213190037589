/**
 * 却下理由ID
 */
export class DeclineReasonID {
  constructor(public readonly value: string) {}
}

/**
 * 却下理由名
 */
export class DeclineReasonName {
  static readonly MAX_LENGTH = 30;
  constructor(public readonly value: string) {}
}

/**
 * 却下理由説明
 */
export class DeclineReasonDescription {
  static readonly MAX_LENGTH = 100;
  constructor(public readonly value: string) {}
}

/**
 * 却下理由
 */
export type DeclineReason = Readonly<{
  id: DeclineReasonID,
  name: DeclineReasonName,
  description: DeclineReasonDescription,
}>
