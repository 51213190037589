import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { CalendarSettingCloseTime, CalendarSettingEnableDayOfWeek, CalendarSettingStartTime } from "app/model/calendar-settings/calendar-settings";
import { UserID } from "app/model/user/user";
import { Observable } from "rxjs";

export type UserProfileCalendarSettingsInputs = {
  monday: CalendarSettingEnableDayOfWeek,
  tuesday: CalendarSettingEnableDayOfWeek,
  wednesday: CalendarSettingEnableDayOfWeek,
  thursday: CalendarSettingEnableDayOfWeek,
  friday: CalendarSettingEnableDayOfWeek,
  saturday: CalendarSettingEnableDayOfWeek,
  sunday: CalendarSettingEnableDayOfWeek,
  startTime: CalendarSettingStartTime,
  closeTime: CalendarSettingCloseTime,
}

export abstract class UserProfileCalendarSettingsCommandService {
  abstract update(id: UserID, inputs: UserProfileCalendarSettingsInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
  >>;
}