import { HttpClient } from "@angular/common/http"
import { Observable } from "rxjs"

export type CreateNurturingRequest = {
  duration: {
    startDateTime: string,
    minutes: number
  },
  clientID: String,
  title: string,
  companions: string[],
  attendees: string[],
  note: string,
  nurturingTypeID: string,
  beforeHearing: {
    [id: string]: string
  },
  afterHearing: {
    [id: string]: string
  },
}

export type UpdateNurturingRequest = Omit<CreateNurturingRequest, 'clientID' | 'nurturingTypeID'>

export type ReportNurturingRequest = UpdateNurturingRequest

export type GetNurturingResponse = {
  id: string,
  owner: {
    id: string,
    name: string,
  },
  duration: {
    startDateTime: string,
    minutes: number
  },
  client: {
    id: string,
    name: string,
    number: number,
  },
  title: string,
  companions: {
    userID: string,
    userDisplayName: string,
  }[],
  attendees: {
    contactID: string,
    contactDisplayName: string,
    departmentName: string,
    roleName: string,
  }[],
  note: string,
  nurturingType: {
    id: string,
    name: string,
    description: string,
  },
  beforeHearing: {
    [id: string]: string
  },
  afterHearing: {
    [id: string]: string
  },
}

export class NurturingApi {
  constructor(private readonly http: HttpClient) { }

  create(request: CreateNurturingRequest): Observable<void> {
    return this.http.post<void>('/api/nurturings', request);
  }

  get(id: string): Observable<GetNurturingResponse> {
    return this.http.get<GetNurturingResponse>(`/api/nurturings/${id}`);
  }

  update(id: string, request: UpdateNurturingRequest): Observable<void> {
    return this.http.put<void>(`/api/nurturings/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/nurturings/${id}`);
  }

  report(id: string, request: ReportNurturingRequest): Observable<void> {
    return this.http.post<void>(`/api/nurturings/${id}/report`, request);
  }

  cancelReport(id: string): Observable<void> {
    return this.http.post<void>(`/api/nurturings/${id}/report-cancel`, undefined);
  }
}
