import {ConfirmService} from "#application/services/confirm.service";
import {MessageDialogService} from "#application/services/message-dialog.service";
import {
  AddNurturingTypeInput,
  NurturingTypeCommandService,
  UpdateNurturingTypeInput
} from "#application/services/nurturing-type-command.service";
import {SnackBarService} from "#application/services/snack-bar.service";
import {NurturingTypeID} from "app/model/nurturing-type/nurturing-type";
import {ServerApiError, ServerApiErrorCode} from "#infrastructure/api/error";
import {ServerApi} from "#infrastructure/api/server-api";
import {MessageActionType, messageFn} from "#utils/messages";
import {inject, Injectable} from "@angular/core";
import {catchError, concatMap, filter, Observable, tap, throwError} from "rxjs";
import {LocalizedNameService} from "#application/services/localized-name.service";
import {LocalizeKey} from "#application/services/authorize.service";

@Injectable({ providedIn: 'root' })
export class NurturingTypeCommandServiceImpl implements NurturingTypeCommandService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _messageDialogService = inject(MessageDialogService);
  private readonly _snackBarService = inject(SnackBarService);
  private readonly _confirmService = inject(ConfirmService);
  private readonly _localizedNameService = inject(LocalizedNameService);

  add(input: AddNurturingTypeInput): Observable<void> {
    const nurturingType = `${this._localizedNameService.get(LocalizeKey.NURTURING)}タイプ`;
    return this._serverApi.nurturingTypeApi.create({
      name: input.name.value,
      description: input.description.value
    }).pipe(
      tap(() => this._snackBarService.show(
        messageFn(nurturingType, 'CREATE_SUCCESS').message
      )),
      catchError((err: ServerApiError) => {
        return this._checkBadRequestError(err, nurturingType, 'CREATE_ERROR')
          || this._checkConflictError(err, nurturingType, 'CREATE_ERROR')
          || this._showError(err, nurturingType, 'CREATE_ERROR');
      })
    );
  }

  update(input: UpdateNurturingTypeInput): Observable<void> {
    const nurturingType = `${this._localizedNameService.get(LocalizeKey.NURTURING)}タイプ`;
    return this._serverApi.nurturingTypeApi.update(input.id.value, {
      name: input.name.value,
      description: input.description.value
    }).pipe(
      tap(() => this._snackBarService.show(
        messageFn(nurturingType, 'UPDATE_SUCCESS').message
      )),
      catchError((err: ServerApiError) => {
        return this._checkBadRequestError(err, nurturingType, 'UPDATE_ERROR')
          || this._checkConflictError(err, nurturingType, 'UPDATE_ERROR')
          || this._checkNotFoundError(err, nurturingType, 'UPDATE_ERROR')
          || this._showError(err, nurturingType, 'UPDATE_ERROR');
      })
    );
  }

  delete(id: NurturingTypeID): Observable<void> {
    const nurturingType = `${this._localizedNameService.get(LocalizeKey.NURTURING)}タイプ`;
    return this._confirmService.confirm({
      title: messageFn(nurturingType, 'DELETE_CONFIRM').title,
      message: messageFn(nurturingType, 'DELETE_CONFIRM').message
    }).pipe(
      filter(result => result.isOK()),
      concatMap(() => this._serverApi.nurturingTypeApi.delete(id.value)),
      tap(() => this._snackBarService.show(messageFn(nurturingType, 'DELETE_SUCCESS').message)),
      catchError(err => {
        this._messageDialogService.notice({
          title: messageFn(nurturingType, 'DELETE_ERROR').title,
          message: messageFn(nurturingType, 'DELETE_ERROR').message
        });
        return throwError(() => err);
      })
    );
  }

  sort(ids: NurturingTypeID[]): Observable<void> {
    const nurturingType = `${this._localizedNameService.get(LocalizeKey.NURTURING)}タイプ`;
    return this._serverApi.nurturingTypeApi.saveOrders({ ids: ids.map((id) => id.value) })
      .pipe(
        tap(() => this._snackBarService.show(
          messageFn(nurturingType, 'SORT_SUCCESS').message
        )),
        catchError(err => {
          if (err instanceof ServerApiError && err.code === ServerApiErrorCode.Conflict) {
            this._messageDialogService.notice({
              title: messageFn(nurturingType, 'SORT_ERROR').message,
              message: `別のユーザーにより${nurturingType}が更新されていたため、並び順の更新に失敗しました。\n${nurturingType}の一覧を更新し、操作をやり直してください。`,
            });
            return throwError(() => err);
          } else {
            return this._showError(err, nurturingType, 'SORT_ERROR');
          }
        })
      );
  }

  private _checkBadRequestError(err: Error, target: string, actionType: MessageActionType): Observable<never> | void {
    if (err instanceof ServerApiError && err.code == ServerApiErrorCode.BadRequest) {
      this._messageDialogService.notice({
        title: messageFn(target, actionType).message,
        message:
          '原因は以下のものが考えられます。\n' +
          '・入力に誤りがある場合\n' +
          `・${target}の登録数が上限を超えた場合\n`,
      });
      return throwError(() => err);
    }
  }

  private _checkConflictError(err: Error, target: string, actionType: MessageActionType): Observable<never> | void {
    if (err instanceof ServerApiError && err.code == ServerApiErrorCode.Conflict) {
      this._messageDialogService.notice({
        title: messageFn(target, actionType).message,
        message: `${target}名が重複しています。`,
      });
      return throwError(() => err);
    }
  }

  private _checkNotFoundError(err: Error, target: string, actionType: MessageActionType): Observable<never> | void {
    if (err instanceof ServerApiError && err.code == ServerApiErrorCode.NotFound) {
      this._messageDialogService.notice({
        title: messageFn(target, actionType).message,
        message: `${target}は、すでに削除されています。`,
      });
      return throwError(() => err);
    }
  }

  private _showError(err: Error, target: string, actionType: MessageActionType): Observable<never> {
    this._snackBarService.show(
      messageFn(target, actionType).message
    );
    return throwError(() => err);
  }
}
