import { UserGroupID } from "app/model/user-group/user-group";
import { DisplayName, UserID } from "app/model/user/user";
import { DateTime } from "luxon";
import { Observable } from "rxjs";

type Assignee = AsUserGroup | AsAllUsers;

type AsUserGroup = {
  type: 'USER_GROUP',
  userGroupID: UserGroupID,
};

type AsAllUsers = {
  type: 'ALL_USERS',
};

type MonthlyGoals = {
  yearMonth: DateTime,
  goal: number,
};

export type SearchGoalConfirmationInputs = {
  yearMonth: DateTime,
  assignee: Assignee,
};

export type SearchGoalConfirmationOutputs = {
  goalsMonthlyTotal: {
    monthlyGoals: MonthlyGoals[],
    totalGoal: number,
  },
  goalsPerUsers: {
    user: {
      id: UserID,
      name: DisplayName,
      isActive: boolean,
    },
    monthlyGoals: MonthlyGoals[],
  }[],
};

export abstract class GoalConfirmationQueryService {
  abstract search(inputs: SearchGoalConfirmationInputs): Observable<SearchGoalConfirmationOutputs>;
}
