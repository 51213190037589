import {ClientID} from "app/model/client/client";
import {Observable} from "rxjs";
import {ContactHistoryContent} from "./contact-history";
import {OpportunityID} from "app/model/opportunity/opportunity";

export abstract class ContactHistoryFactory {
  abstract create(
    clientID: ClientID,
    param: ContactHistoryContent,
    opportunityID?: OpportunityID
  ): Observable<void>;
}
