import {Injectable} from '@angular/core';
import {LostReasonQueryService} from "#application/services/lost-reason-query.service";
import {Observable} from "rxjs";
import {
  LostReason,
  LostReasonDescription,
  LostReasonID,
  LostReasonList,
  LostReasonName
} from "app/model/opportunity/lost-reason/lost-reason";
import {ServerApi} from "#infrastructure/api/server-api";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LostReasonQueryServiceImpl extends LostReasonQueryService {

  constructor(
    private readonly serverApi: ServerApi
  ) {
    super();
  }

  list(): Observable<LostReasonList> {
    return this.serverApi.lostReasonApi.list()
      .pipe(
        map(response => response
          .lostReasons
          .map(item => new LostReason(
            new LostReasonID(item.id),
            new LostReasonName(item.name),
            new LostReasonDescription(item.description)
          ))
        ),
      );
  }

  get(id: LostReasonID): Observable<LostReason> {
    return this.serverApi.lostReasonApi.get(id.value).pipe(
      map(lostReason => new LostReason(
        new LostReasonID(lostReason.id),
        new LostReasonName(lostReason.name),
        new LostReasonDescription(lostReason.description),
      ))
    );
  }
}
