import { ClientID } from "app/model/client/client";
import { ContactID } from "app/model/contact/contact";
import { CustomItems } from "app/model/custom-field/custom-field";
import { NurturingTypeID } from "app/model/nurturing-type/nurturing-type";
import { NurturingDuration, NurturingID, NurturingTitle, NurturingNote } from "app/model/nurturing/nurturing";
import { UserID } from "app/model/user/user";
import { Observable } from "rxjs";

export type AddNurturingInput = Readonly<{
  duration: NurturingDuration,
  clientID: ClientID,
  title: NurturingTitle,
  companions: UserID[],
  attendees: ContactID[],
  note: NurturingNote,
  nurturingTypeID: NurturingTypeID,
  beforeHearing: CustomItems,
  afterHearing: CustomItems,
}>

export type UpdateNurturingInput = Omit<AddNurturingInput, 'clientID' | 'nurturingTypeID'>

export type ReportNurturingInput = UpdateNurturingInput

export abstract class NurturingCommandService {
  abstract add(input: AddNurturingInput): Observable<void>;
  abstract update(id: NurturingID, input: UpdateNurturingInput): Observable<void>;
  abstract delete(id: NurturingID): Observable<void>;
  abstract report(id: NurturingID, input: ReportNurturingInput): Observable<void>;
  abstract cancelReport(id: NurturingID): Observable<void>;
}
