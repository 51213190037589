import {
  ClientRelatedProductList,
  ClientRelatedProductsInput,
  ClientRelatedProductsQueryService
} from "#application/services/client-related-products-query.service";
import {ClientID} from "app/model/client/client";
import {Observable} from "rxjs";
import {ServerApi} from "#infrastructure/api/server-api";
import {map} from "rxjs/operators";
import {ClientProductRelationshipID} from "app/model/client-product-relationship/client-product-relationship";
import {ProductId, ProductName, ProductNumber} from "app/model/product/product";
import {ProductTypeName} from "app/model/product-type/product-type";
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class ClientRelatedProductsQueryServiceImpl extends ClientRelatedProductsQueryService{

  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  getClientRelatedProductList(clientID: ClientID, input: ClientRelatedProductsInput): Observable<ClientRelatedProductList> {
    return this.serverApi.clientRelatedProductsApi.list(clientID.value, {
      perPage: input.perPage,
      page: input.page
    }).pipe(
      map(response => ({
        totalCount: response.totalCount,
        results: response.results.map(item => ({
          clientProductRelationshipID: new ClientProductRelationshipID(item.clientProductRelationshipID),
          productID: new ProductId(item.productID),
          productNumber: new ProductNumber(item.productNumber),
          productTypeName: new ProductTypeName(item.productTypeName),
          productName: new ProductName(item.productName)
        }))
      }))
    );
  }

}
