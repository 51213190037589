import {Injectable} from '@angular/core';
import {OpportunityFieldQueryService} from "#application/services/opportunity-field-query.service";
import {
  CustomFieldRequired,
  CustomField,
  CustomFieldDescription,
  CustomFieldId,
  CustomFieldName,
  CustomFieldNumberUnit,
  CustomFieldNumberValue,
  NumberSettings,
  SelectItemValue,
  SelectSettings
} from "app/model/custom-field/custom-field";
import {Observable} from "rxjs";
import {CustomFieldAlias} from "#application/services/custom-field-query.service";
import {ServerApi} from "#infrastructure/api/server-api";
import {map} from "rxjs/operators";
import {FieldType} from "app/model/custom-field/field-type";

@Injectable({
  providedIn: 'root'
})
export class OpportunityFieldQueryServiceImpl extends OpportunityFieldQueryService {

  constructor(
    private readonly serverApi: ServerApi,
  ) {
    super();
  }

  get(id: CustomFieldId): Observable<CustomField> {
    return this.serverApi.customFieldApi.get('opportunity', id.value)
      .pipe(
        map(response => new CustomField(
          new CustomFieldId(response.id),
          new CustomFieldName(response.name),
          FieldType[response.type],
          response.required,
          new CustomFieldDescription(response.description),
          response.numberSetting == undefined ? undefined : new NumberSettings(
            response.numberSetting.min == undefined ? undefined : new CustomFieldNumberValue(response.numberSetting.min),
            response.numberSetting.max == undefined ? undefined : new CustomFieldNumberValue(response.numberSetting.max),
            response.numberSetting.defaultValue == undefined ? undefined : new CustomFieldNumberValue(response.numberSetting.defaultValue),
            response.numberSetting.unit == undefined ? undefined : new CustomFieldNumberUnit(response.numberSetting.unit)
          ),
          response.selectSetting == undefined ? undefined : new SelectSettings(
            response.selectSetting.multiple, response.selectSetting.allowInput,
            response.selectSetting.options.map(value => new SelectItemValue(value))
          )
        ))
      );
  }

  list(): Observable<CustomFieldAlias[]> {
    return this.serverApi.customFieldApi.list('opportunity')
      .pipe(
        map(response => response.map(item => ({
          id: new CustomFieldId(item.id),
          name: new CustomFieldName(item.name),
          description: new CustomFieldDescription(item.description),
          type: FieldType[item.type],
          required: new CustomFieldRequired(item.required),
          numberSettings: item.numberSetting == undefined ? undefined : new NumberSettings(
            item.numberSetting.min == undefined ? undefined : new CustomFieldNumberValue(item.numberSetting.min),
            item.numberSetting.max == undefined ? undefined : new CustomFieldNumberValue(item.numberSetting.max),
            item.numberSetting.defaultValue == undefined ? undefined : new CustomFieldNumberValue(item.numberSetting.defaultValue),
            item.numberSetting.unit == undefined ? undefined : new CustomFieldNumberUnit(item.numberSetting.unit)
          ),
          selectSettings: item.selectSetting == undefined ? undefined : new SelectSettings(
            item.selectSetting.multiple, item.selectSetting.allowInput,
            item.selectSetting.options.map(value => new SelectItemValue(value))
          )
        })))
      );
  }
}
