import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { ActionTypeApi } from '#infrastructure/api/server-action-type-api';
import { AdoptedReasonApi } from "#infrastructure/api/server-adopted-reason-api";
import { AssignedOrganizationApi } from "#infrastructure/api/server-assigned-organization-api";
import { ClientApi } from '#infrastructure/api/server-client-api';
import { ClientForSelectApi } from "#infrastructure/api/server-client-for-select-api";
import { ClientProductRelationshipApi } from "#infrastructure/api/server-client-product-relationship-api";
import { ClientRelatedProductsApi } from "#infrastructure/api/server-client-related-products-api";
import { ClientTypeApi } from '#infrastructure/api/server-client-type-api';
import { ContactApi } from "#infrastructure/api/server-contact-api";
import { DeclineReasonApi } from "#infrastructure/api/server-decline-reason-api";
import { FilterApi } from "#infrastructure/api/server-filter-api";
import { FollowUpActionTypeApi } from '#infrastructure/api/server-follow-up-action-type-api';
import { InsightFeedbackOptionsApi } from "#infrastructure/api/server-insight-feedback-options-api";
import { LocalizationSettingApi } from "#infrastructure/api/server-localization-setting-api";
import { LostReasonApi } from '#infrastructure/api/server-lost-reason-api';
import { OpportunityContactHistoryApi } from "#infrastructure/api/server-opportunity-contact-history-api";
import { ProductApi } from '#infrastructure/api/server-product-api';
import { ProductRelatedClientsApi } from "#infrastructure/api/server-product-related-clients-api";
import { ProposalStatusApi } from "#infrastructure/api/server-proposal-status-api";
import { ProposalWorkflowApi } from "#infrastructure/api/server-proposal-workflow-api";
import { ReportActivityV2Api } from "#infrastructure/api/server-report-activity-v2-api";
import { RestAuthTokenApi } from "#infrastructure/api/server-rest-auth-token-api";
import { UserGroupForSelectApi } from "#infrastructure/api/server-user-group-for-select.api";
import { WebhookApi } from "#infrastructure/api/server-webhook-api";
import { WebhookEventHistoryApi } from "#infrastructure/api/server-webhook-event-history.api";
import { UserApi } from '#infrastructure/api/user-api';
import { ActionApi } from './server-action-api';
import { ActionListApi } from './server-action-list-api';
import { AuthApi } from './server-auth-api';
import { BuiltinFieldApi } from './server-builtin-field-api';
import { ClientNurturingApi } from './server-client-nurturing-api';
import { ClientRelationshipApi } from './server-client-relationship-api';
import { CoachingApi } from './server-coaching-api';
import { CommonHintApi } from './server-common-hint-api';
import { CommonInsightApi } from './server-common-insight-api';
import { CommonOpportunityContextApi } from './server-common-opportunity-context-api';
import { CommonOpportunityContextLabelApi } from './server-common-opportunity-context-label-api';
import { ConsultantApi } from './server-consultant-api';
import { ContactHistoryApi } from './server-contact-history-api';
import { ContactHistoryTemplateApi } from './server-contact-history-template-api';
import { CsvExportApi } from './server-csv-export-api';
import { CsvExportHistoriesApi } from './server-csv-export-histories-api';
import { CsvImportApi } from './server-csv-import-api';
import { CsvImportHistoriesApi } from './server-csv-import-histories-api';
import { CustomFieldApi } from './server-custom-field-api';
import { DefaultFormItemsApi } from './server-default-form-items-api';
import { DefaultFormLayoutApi } from './server-default-form-layout-api';
import { EventV2Api } from './server-event-v2-api';
import { FileApi } from './server-file-api';
import { FollowUpActionApi } from './server-follow-up-action-api';
import { FormItemsApi } from './server-form-items-api';
import { FormLayoutApi } from './server-form-layout-api';
import { GoalConfirmationApi } from './server-goal-confirmation-api';
import { GoalSettingApi } from './server-goal-setting-api';
import { HintApi } from './server-hint-api';
import { HintReviewApi } from './server-hint-review-api';
import { IncorporatedInsightApi } from './server-incorporated-insight-api';
import { InsightApi } from './server-insight-api';
import { MeetingBoardApi } from './server-meeting-board-api';
import { MeetingBoardSettingApi } from './server-meeting-board-setting-api';
import { MetaUserAuthApi } from './server-meta-user-auth-api';
import { NurturingApi } from './server-nurturing-api';
import { NurturingHearingSettingApi } from './server-nurturing-hearing-setting-api';
import { NurturingTypeApi } from './server-nurturing-type-api';
import { OnetimeTokenApi } from './server-onetime-token-api';
import { OpportunityApi } from './server-opportunity-api';
import { OpportunityCoachingHistoryApi } from './server-opportunity-coaching-history-api';
import { OpportunityContextApi } from './server-opportunity-context-api';
import { OpportunityContextLabelApi } from './server-opportunity-context-label-api';
import { OpportunityFollowUpActionApi } from './server-opportunity-follow-up-action-api';
import { OpportunityGroupApi } from './server-opportunity-group-api';
import { OpportunityGroupStatusApi } from './server-opportunity-group-status-api';
import { OpportunityGroupTypeApi } from './server-opportunity-group-type-api';
import { OpportunityGroupWorkflowApi } from './server-opportunity-group-workflow-api';
import { OpportunityHearingSettingApi } from './server-opportunity-hearing-setting-api';
import { OpportunityPerPhaseApi } from './server-opportunity-per-phase-api';
import { OpportunityListReportGoalApi } from './server-opportunity-report-goals-api';
import { OpportunityListReportOpportunityApi } from './server-opportunity-report-opportunity-api';
import { OpportunityTypeApi } from './server-opportunity-type-api';
import { OpportunityTypeOpportunityContextSettingApi } from './server-opportunity-type-opportunity-context-setting-api';
import { OpportunityV2Api } from './server-opportunity-v2-api';
import { OpportunityWithSettingsForActionApi } from './server-opportunity-with-settings-for-action-api';
import { OrganizationApi } from './server-organization-api';
import { ProductCsvExportApi } from './server-product-csv-export-api';
import { ProductTypeApi } from './server-product-type-api';
import { ProposalApi } from './server-proposal-api';
import { ProposalTypeApi } from './server-proposal-type-api';
import { ReportCvrApi } from './server-report-cvr-api';
import { ReportGoalV2Api } from './server-report-goal-v2-api';
import { ReportNurturingApi } from './server-report-nurturing-api';
import { ReportOpportunityV2Api } from './server-report-opportunity-v2-api';
import { RoleApi } from './server-role-api';
import { SalesPhaseApi } from './server-sales-phase-api';
import { ScheduleV2Api } from './server-schedule-v2-api';
import { SearchFilterApi } from './server-search-filter-api';
import { SearchFilterPropertyApi } from './server-search-filter-property-api';
import { SingleSignOnApi } from './server-single-sign-on-api';
import { UnreportedActivityApi } from './server-unreported-activity-api';
import { UserGroupApi } from './server-user-group-api';
import { UserProfileCalendarSettingsApi } from './server-user-profile-calendar-settings-api';
import { WonReasonApi } from './server-won-reason-api';

/**
 * サーバーAPI
 *
 * サーバーとの通信API。HTTPで通信していることはこのクラスだけが知っている
 * ロジックは入れず、APIと1:1対応させたものを提供する。
 * インフラ層からのみ参照し、他の層から参照しないこと
 */
@Injectable({providedIn: 'root'})
export class ServerApi {
  private readonly http = inject(HttpClient);

  readonly organization = new OrganizationApi(this.http);
  readonly user = new UserApi(this.http);
  readonly salesPhaseApi = new SalesPhaseApi(this.http);
  readonly auth = new AuthApi(this.http);
  readonly opportunityTypeApi = new OpportunityTypeApi(this.http);
  readonly opportunityApi = new OpportunityApi(this.http);
  readonly actionApi = new ActionApi(this.http);
  readonly actionTypeApi = new ActionTypeApi(this.http);
  readonly actionListApi = new ActionListApi(this.http);
  readonly followUpActionApi = new FollowUpActionApi(this.http);
  readonly followUpActionTypeApi = new FollowUpActionTypeApi(this.http);
  readonly productApi = new ProductApi(this.http);
  readonly lostReasonApi = new LostReasonApi(this.http);
  readonly clientTypeApi = new ClientTypeApi(this.http);
  readonly contactHistoryTemplateApi = new ContactHistoryTemplateApi(this.http);
  readonly clientApi = new ClientApi(this.http);
  readonly opportunityHearingSettingApi = new OpportunityHearingSettingApi(this.http);
  readonly contactApi = new ContactApi(this.http);
  readonly contactHistoryApi = new ContactHistoryApi(this.http);
  readonly clientRelationshipApi = new ClientRelationshipApi(this.http);
  readonly clientProductRelationshipApi = new ClientProductRelationshipApi(this.http);
  readonly clientRelatedProductsApi = new ClientRelatedProductsApi(this.http);
  readonly customFieldApi = new CustomFieldApi(this.http);
  readonly formLayoutApi = new FormLayoutApi(this.http);
  readonly builtinFieldApi = new BuiltinFieldApi(this.http);
  readonly defaultFormLayoutApi = new DefaultFormLayoutApi(this.http);
  readonly productTypeApi = new ProductTypeApi(this.http);
  readonly formItemsApi = new FormItemsApi(this.http);
  readonly defaultFormItemsApi = new DefaultFormItemsApi(this.http);
  readonly csvImportApi = new CsvImportApi(this.http);
  readonly csvImportHistoriesApi = new CsvImportHistoriesApi(this.http);
  readonly csvExportApi = new CsvExportApi(this.http);
  readonly csvExportHistoriesApi = new CsvExportHistoriesApi(this.http);
  readonly productCsvExportApi = new ProductCsvExportApi(this.http);
  readonly searchFilterApi = new SearchFilterApi(this.http);
  readonly searchFilterPropertyApi = new SearchFilterPropertyApi(this.http);
  readonly productRelatedClientsApi = new ProductRelatedClientsApi(this.http);
  readonly reportActivityV2Api = new ReportActivityV2Api(this.http);
  readonly consultantApi = new ConsultantApi(this.http);
  readonly assignedOrganizationApi = new AssignedOrganizationApi(this.http);
  readonly metaUserAuthApi = new MetaUserAuthApi(this.http);
  readonly onetimeTokenApi = new OnetimeTokenApi(this.http);
  readonly singleSignOnApi = new SingleSignOnApi(this.http);
  readonly opportunityContextApi = new OpportunityContextApi(this.http);
  readonly opportunityContextLabelApi = new OpportunityContextLabelApi(this.http);
  readonly commonOpportunityContextApi = new CommonOpportunityContextApi(this.http);
  readonly commonOpportunityContextLabelApi = new CommonOpportunityContextLabelApi(this.http);
  readonly insightApi = new InsightApi(this.http);
  readonly insightFeedbackOptionsApi = new InsightFeedbackOptionsApi(this.http);
  readonly commonInsightApi = new CommonInsightApi(this.http);
  readonly opportunityContactHistoryApi = new OpportunityContactHistoryApi(this.http);
  readonly opportunityTypeOpportunityContextSettingApi = new OpportunityTypeOpportunityContextSettingApi(this.http);
  readonly incorporatedInsightApi = new IncorporatedInsightApi(this.http);
  readonly coachingApi = new CoachingApi(this.http);
  readonly opportunityCoachingHistoryApi = new OpportunityCoachingHistoryApi(this.http);
  readonly commonHintApi = new CommonHintApi(this.http);
  readonly scheduleV2Api = new ScheduleV2Api(this.http);
  readonly wonReasonApi = new WonReasonApi(this.http);
  readonly opportunityWithSettingsForActionApi = new OpportunityWithSettingsForActionApi(this.http);
  readonly proposalTypeApi = new ProposalTypeApi(this.http);
  readonly fileApi = new FileApi(this.http);
  readonly declineReasonApi = new DeclineReasonApi(this.http);
  readonly adoptedReasonApi = new AdoptedReasonApi(this.http);
  readonly proposalStatusApi = new ProposalStatusApi(this.http);
  readonly proposalWorkflowApi = new ProposalWorkflowApi(this.http);
  readonly proposalApi = new ProposalApi(this.http);
  readonly webhookApi = new WebhookApi(this.http);
  readonly restAuthTokenApi = new RestAuthTokenApi(this.http);
  readonly webhookEventHistoryApi = new WebhookEventHistoryApi(this.http);
  readonly userGroupApi = new UserGroupApi(this.http);
  readonly opportunityReportGoalsApi = new OpportunityListReportGoalApi(this.http);
  readonly goalConfirmationApi = new GoalConfirmationApi(this.http);
  readonly reportCvrApi = new ReportCvrApi(this.http);
  readonly opportunityListReportOpportunityApi = new OpportunityListReportOpportunityApi(this.http);
  readonly reportOpportunityV2Api = new ReportOpportunityV2Api(this.http);
  readonly hintReviewApi = new HintReviewApi(this.http);
  readonly hintApi = new HintApi(this.http);
  readonly roleApi = new RoleApi(this.http);
  readonly nurturingHearingSettingApi = new NurturingHearingSettingApi(this.http);
  readonly nurturingTypeApi = new NurturingTypeApi(this.http);
  readonly nurturingApi = new NurturingApi(this.http);
  readonly localizationSettingApi = new LocalizationSettingApi(this.http);
  readonly clientNurturingApi = new ClientNurturingApi(this.http);
  readonly opportunityFollowUpActionApi = new OpportunityFollowUpActionApi(this.http);
  readonly filterApi = new FilterApi(this.http);
  readonly opportunityV2Api = new OpportunityV2Api(this.http);
  readonly reportNurturingApi = new ReportNurturingApi(this.http);
  readonly unreportedActivityApi = new UnreportedActivityApi(this.http);
  readonly opportunityGroupStatusApi = new OpportunityGroupStatusApi(this.http);
  readonly opportunityGroupWorkflowApi = new OpportunityGroupWorkflowApi(this.http);
  readonly opportunityGroupTypeApi = new OpportunityGroupTypeApi(this.http);
  readonly opportunityGroupApi = new OpportunityGroupApi(this.http);
  readonly userProfileCalendarSettingsApi = new UserProfileCalendarSettingsApi(this.http);
  readonly opportunityPerPhaseApi = new OpportunityPerPhaseApi(this.http);
  readonly userGroupForSelectApi = new UserGroupForSelectApi(this.http);
  readonly eventV2Api = new EventV2Api(this.http);
  readonly reportGoalV2Api = new ReportGoalV2Api(this.http);
  readonly goalSettingApi = new GoalSettingApi(this.http);
  readonly meetingBoardApi = new MeetingBoardApi(this.http);
  readonly meetingBoardSettingApi = new MeetingBoardSettingApi(this.http);
  readonly clientForSelectApi = new ClientForSelectApi(this.http);
}
