import {ServerApi} from '#infrastructure/api/server-api';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import { OpportunityContextID, OpportunityContextName } from 'app/model/opportunity-context/opportunity-context';
import { InsightList, SearchInsightListParams } from '#application/services/base-insight-query.service';
import { Insight, InsightAnswer, InsightAnswerID, InsightID, InsightQuestion, InsightSupplement, InsightSupplementTitle } from 'app/model/insight/insight';
import { CommonInsightQueryService } from '#application/services/common-insight-query.service';
import { HintID, HintTitle } from 'app/model/hint/hint';
import { convertCoachingTiming } from './insight-query.service';

@Injectable({
  providedIn: 'root',
})
export class CommonInsightQueryServiceImpl extends CommonInsightQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  findBy(params: SearchInsightListParams): Observable<InsightList> {
    return this.serverApi.commonInsightApi.search(params).pipe(
      map(data => ({
        totalCount: data.totalCount,
        results: data.results.map(result => ({
          id: new InsightID(result.id),
          coachingTiming: convertCoachingTiming(result.coachingTiming),
          question: new InsightQuestion(result.question),
          triggeringOpportunityContexts: result.triggeringOpportunityContexts.map(v => ({
            id: new OpportunityContextID(v.id),
            name: new OpportunityContextName(v.name),
          })),
        }))
      }))
    );
  }

  get(id: InsightID): Observable<Insight> {
    return this.serverApi.commonInsightApi.get(id.value).pipe(
      map(data => {
        switch (data.answerType) {
          case 'SELECTION':
            return {
              id: new InsightID(data.id),
              coachingTiming: convertCoachingTiming(data.coachingTiming),
              question: new InsightQuestion(data.question),
              supplementTitle: new InsightSupplementTitle(data.supplementTitle),
              supplement: new InsightSupplement(data.supplement),
              triggeringOpportunityContexts: data.triggeringOpportunityContexts.map(v => ({
                id: new OpportunityContextID(v.id),
                name: new OpportunityContextName(v.name),
              })),
              answerType: data.answerType,
              selection: {
                multiple: data.selection.multiple,
                answerOptions: data.selection.answerOptions.map(v => ({
                  id: new InsightAnswerID(v.id),
                  answer: new InsightAnswer(v.answer),
                  occurringOpportunityContexts: v.occurringOpportunityContexts.map(v => ({
                    id: new OpportunityContextID(v.id),
                    name: new OpportunityContextName(v.name),
                  })),
                  hint: v.hint === undefined
                    ? undefined
                    : {
                      id: new HintID(v.hint.id),
                      title: new HintTitle(v.hint.title),
                    }
                })),
              },
            };
          case 'TEXT':
            return {
              id: new InsightID(data.id),
              coachingTiming: convertCoachingTiming(data.coachingTiming),
              question: new InsightQuestion(data.question),
              supplementTitle: new InsightSupplementTitle(data.supplementTitle),
              supplement: new InsightSupplement(data.supplement),
              triggeringOpportunityContexts: data.triggeringOpportunityContexts.map(v => ({
                id: new OpportunityContextID(v.id),
                name: new OpportunityContextName(v.name),
              })),
              answerType: data.answerType,
            };
        }
      })
    );
  }
}
