import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type GoalCategoryRequest = {
  type: 'OPPORTUNITY_WON',
  amountType: 'ACHIEVEMENT_AND_ESTIMATION' | 'ACHIEVEMENT' | 'ESTIMATION',
} | {
  type: 'SALES_RECORDING',
};

export type ListOpportunityReportGoalsRequest = {
  pagination: {
    perPage: number,
    page: number,
  },
  goalCategory: GoalCategoryRequest,
  duration: {
    startYearMonth: string,
    months: number,
  },
  assignee: {
    type: string,
    userID?: string,
    userGroupID?: string,
  },
};

export type ListOpportunityReportGoalsResponse = {
  totalCount: number,
  results: {
    id: string,
    opportunityName: string,
    clientID: string,
    clientName: string,
    clientCategory: string,
    clientNumber: number,
    opportunityTypeID: string,
    opportunityTypeName: string,
    opportunityNumber: number,
    salesPhaseID: string,
    phaseID: string,
    phaseNumber: number,
    phaseName: string,
    status: string,
    assignee: string,
    assigneeName: string,
    expectedAmount?: number,
    expectedCloseDate?: string,
    opportunityAccrualDate?: string,
    items?: {
      [customFieldId: string]: string,
    },
  }[],
};

export class OpportunityListReportGoalApi {
  private readonly _url = '/api/opportunity-list/search/report-goals';
  constructor(private readonly http: HttpClient) {}

  list(request: ListOpportunityReportGoalsRequest): Observable<ListOpportunityReportGoalsResponse> {
    return this.http.post<ListOpportunityReportGoalsResponse>(this._url, request);
  }
}
