import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

interface CreateConsultantRequestData {
  userName: string,
  displayName: string,
  mailAddress: string,
  assignedOrganizationType: 'ALL' | 'SELECTED',
  selectedOrganizations?: string[],
}
export type CreateConsultantRequest = CreateConsultantRequestData;

interface ListConsultantsResponseData {
  id: string,
  userName: string
  displayName: string,
  mailAddress: string,
  assignedOrganizationType: 'ALL' | 'SELECTED',
  selectedOrganizationNames?: string[],
  lastLoginDateTime?: string,
}
export type ListConsultantsResponse = ListConsultantsResponseData[];

interface GetConsultantResponseData {
  id: string,
  userName: string
  displayName: string,
  mailAddress: string,
  assignedOrganizationType: 'ALL' | 'SELECTED',
  selectedOrganizations?: string[],
}
export type GetConsultantResponse = GetConsultantResponseData;

interface UpdateConsultantRequestData {
  displayName: string,
  mailAddress: string,
  assignedOrganizationType: 'ALL' | 'SELECTED',
  selectedOrganizations?: string[],
}
export type UpdateConsultantRequest = UpdateConsultantRequestData;

export class ConsultantApi {
  constructor(private readonly http: HttpClient) {}

  create(request: CreateConsultantRequest): Observable<void> {
    return this.http.post<void>(`/api/consultants`, request);
  }

  list(): Observable<ListConsultantsResponse> {
    return this.http.get<ListConsultantsResponseData[]>(`/api/consultant-list`);
  }

  get(id: string): Observable<GetConsultantResponse> {
    return this.http.get<GetConsultantResponseData>(`/api/consultants/${id}`);
  }

  update(id: string, request: UpdateConsultantRequest): Observable<void> {
    return this.http.put<void>(`/api/consultants/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/consultants/${id}`);
  }

  resetPassword(id: string): Observable<void> {
    return this.http.put<void>(`/api/consultants/${id}/reset-password`, null);
  }
}
