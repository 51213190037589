import { MessageDialogService } from '#application/services/message-dialog.service';
import {
  SalesPhaseSettingQueryService,
} from '#application/services/sales-phase-setting-query.service';
import {
  Definition,
  Phase,
  PhaseID,
  PhaseName,
  SalesPhaseID,
  SalesPhaseName,
  SuccessCriteria,
} from 'app/model/sales-phase/sales-phase';
import { SalesPhaseSetting } from 'app/model/sales-phase/sales-phase-setting';
import { ServerApi } from '#infrastructure/api/server-api';
import { GetSalesPhaseResponse, ListSalesPhaseResponse } from '#infrastructure/api/server-sales-phase-api';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SalesPhaseSettingQueryServiceImpl extends SalesPhaseSettingQueryService {
  private readonly serverApi = inject(ServerApi);
  private readonly messageDialogService = inject(MessageDialogService);

  list(): Observable<SalesPhaseSetting[]> {
    return this.serverApi.salesPhaseApi
      .list()
      .pipe(
        map((res: ListSalesPhaseResponse) => this._toDomainList(res)),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '商談フェーズ一覧の取得',
          });
          return throwError(err);
        }),
      );
  }

  get(salesPhaseID: SalesPhaseID): Observable<SalesPhaseSetting> {
    return this.serverApi.salesPhaseApi
      .get(salesPhaseID.value)
      .pipe(
        map((res: GetSalesPhaseResponse) => this._toDomain(res)),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '商談フェーズの取得',
          });
          return throwError(err);
        }),
      );
  }

  private _toDomainList(res: ListSalesPhaseResponse): SalesPhaseSetting[] {
    return res.list.map((salesPhase) =>
      new SalesPhaseSetting(
        new SalesPhaseID(salesPhase.id),
        new SalesPhaseName(salesPhase.name),
        salesPhase.isUsed,
        salesPhase.phaseList.map((phase) =>
          new Phase(
            new PhaseID(phase.id),
            new PhaseName(phase.name),
            new Definition(''),
            new SuccessCriteria(''),
          )
        ),
      )
    );
  }

  private _toDomain(res: GetSalesPhaseResponse): SalesPhaseSetting {
    return new SalesPhaseSetting(
      new SalesPhaseID(res.id),
      new SalesPhaseName(res.name),
      res.isUsed,
      res.phaseList.map((phase) =>
        new Phase(
          new PhaseID(phase.id),
          new PhaseName(phase.name),
          new Definition(phase.definition),
          new SuccessCriteria(phase.successCriteria),
        )
      ),
    );
  }
}
