import {SalesPhaseID} from 'app/model/sales-phase/sales-phase';
import {Observable} from 'rxjs';
import {OpportunityTypeDescription, OpportunityTypeName} from './opportunity-type';
import {FormLayoutId} from "app/model/form-layout/form-layout";

export interface CreateOpportunityTypeParam {
  name: OpportunityTypeName;
  description: OpportunityTypeDescription;
  salesPhaseId: SalesPhaseID;
  formLayoutId: FormLayoutId | undefined;
}

export abstract class OpportunityTypeFactory {
  abstract create(param: CreateOpportunityTypeParam): Observable<void>;
}
