export class OpportunityContextID {
  constructor(public readonly value: string) {}

  equals(id: OpportunityContextID): boolean {
    return this.value === id.value;
  }
}

export class OpportunityContextName {
  static readonly maxLength: number = 50;

  constructor(public readonly value: string) {}
}

export class OpportunityContextDescription {
  static readonly maxLength: number = 100;

  constructor(public readonly value: string) {}
}

export class OpportunityContextLabelName {
  static readonly maxLength: number = 30;

  constructor(public readonly value: string) {}
}

export class OpportunityContext {
  constructor(
    public readonly id: OpportunityContextID,
    public readonly name: OpportunityContextName,
    public readonly description: OpportunityContextDescription,
    public readonly labelNames: OpportunityContextLabelName[],
    public readonly readonly?: boolean,
  ) {}

  changeName(name: OpportunityContextName): OpportunityContext {
    return new OpportunityContext(
      this.id,
      name,
      this.description,
      this.labelNames,
      this.readonly,
    );
  }

  changeDescription(description: OpportunityContextDescription): OpportunityContext {
    return new OpportunityContext(
      this.id,
      this.name,
      description,
      this.labelNames,
      this.readonly,
    );
  }

  changeLabelNames(labelNames: OpportunityContextLabelName[]): OpportunityContext {
    return new OpportunityContext(
      this.id,
      this.name,
      this.description,
      labelNames,
      this.readonly,
    );
  }
}
