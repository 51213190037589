import { AddHintInputs, UpdateHintInputs } from "#application/services/base-hint-command.service";
import { CommonHintCommandService } from "#application/services/common-hint-command.service";
import { ConfirmService } from "#application/services/confirm.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { Page, PageNavigateService } from "#application/services/page-navigate.service";
import { SnackBarService } from "#application/services/snack-bar.service";
import { HintID } from "app/model/hint/hint";
import { ServerApi } from "#infrastructure/api/server-api";
import { Target, messageFn } from "#utils/messages";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, concatMap, filter, tap } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class CommonHintCommandServiceImpl implements CommonHintCommandService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
    private readonly pageNavigateService: PageNavigateService,
    private readonly confirmService: ConfirmService,
    private readonly messageDialogService: MessageDialogService,
  ) {}

  add(inputs: AddHintInputs): Observable<void> {
    return this.serverApi.commonHintApi.create(
      {
        title: inputs.title.value,
        content: inputs.content.value,
      }
    )
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(Target.COMMON_HINT, 'CREATE_SUCCESS').message);
        this.pageNavigateService.navigate(Page.ConsultingCommonHint);
      }),
      catchError((err) => {
        this.snackBarService.show(messageFn(Target.COMMON_HINT, 'CREATE_ERROR').message);
        return throwError(err);
      })
    );
  }

  update(inputs: UpdateHintInputs): Observable<void> {
    return this.serverApi.commonHintApi.update(
      inputs.id.value,
      {
        title: inputs.title.value,
        content: inputs.content.value,
      }
    )
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(Target.COMMON_HINT, 'UPDATE_SUCCESS').message);
        this.pageNavigateService.navigate(Page.ConsultingCommonHint);
      }),
      catchError((err) => {
        this.snackBarService.show(messageFn(Target.COMMON_HINT, 'UPDATE_ERROR').message);
        return throwError(err);
      })
    );
  }

  delete(id: HintID): Observable<void> {
    return this.confirmService.confirm({
      title: messageFn(Target.COMMON_HINT, 'DELETE_CONFIRM').title,
      message: messageFn(Target.COMMON_HINT, 'DELETE_CONFIRM').message
    })
    .pipe(
      filter(result => result.isOK()),
      concatMap(() => this.serverApi.commonHintApi.delete(id.value)),
      tap(() => {
        this.snackBarService.show(messageFn(Target.COMMON_HINT, 'DELETE_SUCCESS').message);
        this.pageNavigateService.navigate(Page.ConsultingCommonHint);
      }),
      catchError((err) => {
        this.messageDialogService.notice({
          title: messageFn(Target.COMMON_HINT, 'DELETE_ERROR').title,
          message: messageFn(Target.COMMON_HINT, 'DELETE_ERROR').message
        });
        return throwError(err);
      })
    );
  }
}
