import {
  GetActionResponse,
  ReportActionRequest,
  UpdateActionRequest
} from '#infrastructure/api/server-action-api';
import { SearchActionResponse } from '#infrastructure/api/server-action-list-api';
import { ServerApi } from '#infrastructure/api/server-api';
import { assertIsDefined } from "#utils/assert";
import { Injectable } from '@angular/core';
import {
  Action,
  ActionAttendee,
  ActionAttendees,
  ActionEndDateTime,
  ActionHistory,
  ActionHistoryItem,
  ActionID,
  ActionNote,
  ActionStartDateTime,
  IsDone,
  IsEditable
} from 'app/model/action/action';
import { ActionTypeID, ActionTypeName } from 'app/model/action/action-type';
import { ActionRepository } from 'app/model/action/action.repository';
import { ContactDisplayName, ContactID, ContactRoleName, DepartmentName } from "app/model/contact/contact";
import { OpportunityID, OpportunityItem, OpportunityName } from 'app/model/opportunity/opportunity';
import { PhaseID, PhaseName } from 'app/model/sales-phase/sales-phase';
import { DisplayName, UserID } from 'app/model/user/user';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { convertOpportunityItemsToKeyValueItems } from './opportunity.factory';

export const getActionResponseToAction = (response: GetActionResponse): Action => new Action(
    new ActionID(response.actionID),
    new UserID(response.userID),
    new DisplayName(response.userDisplayName),
    new OpportunityID(response.opportunityID),
    new PhaseID(response.currentPhaseID),
    new PhaseID(response.startPhaseID),
    new PhaseID(response.targetPhaseID),
    new ActionTypeID(response.actionTypeID),
    new ActionTypeName(response.actionTypeName),
    new ActionStartDateTime(DateTime.fromFormat(response.actionStartDateTime, 'yyyy-MM-dd HH:mm:ss').toJSDate()),
    new ActionEndDateTime(DateTime.fromFormat(response.actionEndDateTime, 'yyyy-MM-dd HH:mm:ss').toJSDate()),
    new IsDone(response.isDone),
    new ActionNote(response.actionNote),
    response.companions ?
      response.companions.map(companion => ({
        userID: new UserID(companion.userID),
        userDisplayName: companion.userDisplayName
      })) :
      undefined,
    response.attendees ?
      new ActionAttendees(response.attendees.map(attendee =>
        new ActionAttendee(
          new ContactID(attendee.contactID),
          new ContactDisplayName(attendee.contactDisplayName),
          new DepartmentName(attendee.departmentName),
          new ContactRoleName(attendee.roleName),
        )
      )) :
      undefined,
    response.actualStartPhaseID ? new PhaseID(response.actualStartPhaseID) : undefined,
    response.actualPhaseID ? new PhaseID(response.actualPhaseID) : undefined
  )
;

const searchActionResponseToActionHistory = (
  response: SearchActionResponse
): ActionHistory => {
  const list = response.map((v) => {
    return new ActionHistoryItem(
      new ActionID(v.id),
      new OpportunityName(v.opportunityName),
      new ActionStartDateTime(
        DateTime.fromFormat(v.startDateTime, 'yyyy-MM-dd HH:mm:ss').toJSDate()
      ),
      new ActionEndDateTime(
        DateTime.fromFormat(v.endDateTime, 'yyyy-MM-dd HH:mm:ss').toJSDate()
      ),
      new ActionTypeName(v.actionTypeName),
      new PhaseName(v.currentPhaseName),
      new PhaseName(v.startPhaseName),
      new PhaseName(v.targetPhaseName),
      new UserID(v.assignee),
      new DisplayName(v.assigneeName),
      new IsDone(v.isDone),
      new PhaseName(v.actualStartPhaseName ? v.actualStartPhaseName : ''),
      new PhaseName(v.actualPhaseName ? v.actualPhaseName : ''),
      new IsEditable(v.isEditable)
    );
  });
  return new ActionHistory(list);
};

const actionToUpdateActionRequest = (action: Action, items: OpportunityItem[]): UpdateActionRequest => {
  return {
    companions: action.companions ?
      action.companions.map(companion => ({userID: companion.userID.value})) :
      undefined,
    attendees: action.attendees ?
      action.attendees.list.map(attendee => ({contactID: attendee.contactID.value})) :
      undefined,
    startPhaseID: action.startPhaseID.value,
    targetPhaseID: action.targetPhaseID.value,
    actionTypeID: action.actionTypeID.value,
    actionStartDateTime: formatDate(action.startDateTime.value, 'yyyy-MM-dd HH:mm:ss'),
    actionEndDateTime: formatDate(action.endDateTime.value, 'yyyy-MM-dd HH:mm:ss'),
    actionNote: action.note.value,
    items: convertOpportunityItemsToKeyValueItems(items)
  };
};

const formatDate = (date: Date, format: string): string => {
  return DateTime.fromJSDate(date).toFormat(format);
}

@Injectable({providedIn: 'root'})
export class ActionRepositoryImpl implements ActionRepository {
  constructor(private readonly serverApi: ServerApi) {
  }

  /**
   * @deprecated action-query.service.tsのgetを使うこと
   */
  findByID(actionID: ActionID): Observable<Action> {
    return this.serverApi.actionApi.get(actionID.value).pipe(
      map(getActionResponseToAction)
    );
  }

  findByOpportunityID(opportunityID: OpportunityID): Observable<ActionHistory> {
    return this.serverApi.actionListApi.search(opportunityID.value).pipe(
      map(searchActionResponseToActionHistory)
    );
  }

  save(action: Action, items: OpportunityItem[]): Observable<void> {
    return this.serverApi.actionApi.update(action.actionID.value, actionToUpdateActionRequest(action, items));
  }

  report(action: Action, items: OpportunityItem[], shouldUpdatePhase: boolean): Observable<void> {
    assertIsDefined(action.actualStartPhaseID);
    assertIsDefined(action.actualPhaseID);
    const request: ReportActionRequest = {
      companions: action.companions ?
        action.companions.map(companion => ({userID: companion.userID.value})) :
        undefined,
      attendees: action.attendees ?
        action.attendees.list.map(attendee => ({contactID: attendee.contactID.value})) :
        undefined,
      actualStartPhaseID: action.actualStartPhaseID.value,
      actualPhaseID: action.actualPhaseID.value,
      actionTypeID: action.actionTypeID.value,
      actionStartDateTime: formatDate(action.startDateTime.value, 'yyyy-MM-dd HH:mm:ss'),
      actionEndDateTime: formatDate(action.endDateTime.value, 'yyyy-MM-dd HH:mm:ss'),
      actionNote: action.note.value,
      items: convertOpportunityItemsToKeyValueItems(items)
    };
    return this.serverApi.actionApi.report(action.actionID.value, request, shouldUpdatePhase);
  }

  delete(action: Action): Observable<void> {
    return this.serverApi.actionApi.delete(action.actionID.value);
  }

  cancelReport(actionID: ActionID): Observable<void> {
    return this.serverApi.actionApi.cancelReport(actionID.value);
  }
}
