import { GetPerUserReportGoalV2Input, GetPersonalReportGoalV2Input, GetReportGoalV2Input, GoalPerMonthContainsEstimationV2, GoalPerMonthWithoutEstimationV2, GoalReportContainsEstimationV2, GoalReportPerUserContainsEstimationV2, GoalReportPerUserV2, GoalReportPerUserWithoutEstimationV2, GoalReportUserDataV2, GoalReportV2, GoalReportWithoutEstimationV2, GoalsContainEstimationV2, GoalsWithoutEstimationV2, ReportGoalQueryV2Service } from '#application/services/report-goal-query-v2.service';
import { ServerApiError, ServerApiErrorCode } from '#infrastructure/api/error';
import { ServerApi } from '#infrastructure/api/server-api';
import { ContainsEstimationReportGoalV2, GetContainsEstimationReportGoalV2Response, GetPerUserContainsEstimationReportGoalV2Response, GetPerUserWithoutEstimationReportGoalV2Response, GetWithoutEstimationReportGoalV2Response, UserReportGoalV2, WithoutEstimationReportGoalV2 } from '#infrastructure/api/server-report-goal-v2-api';
import { Injectable, inject } from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import { GoalCategoryForReport, GoalReportTypeV2 } from 'app/model/report/report-goal';
import { DisplayName, UserID } from 'app/model/user/user';
import { DateTime } from 'luxon';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportGoalQueryV2ServiceImpl implements ReportGoalQueryV2Service {
  private readonly serverApi = inject(ServerApi);

  get(category: GoalCategoryForReport, input: GetReportGoalV2Input): Observable<Result<
    GoalReportV2,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.reportGoalV2Api
      .get(
        category,
        {
          duration: {
            goalPeriodType: input.duration.goalPeriodType,
            startYearMonth: input.duration.startYearMonth.toFormat('yyyy-MM'),
            months: input.duration.months
          },
          userIDs: input.userIDs?.map(id => id.value),
          containsEstimation: input.containsEstimation,
        },
      )
      .pipe(
        map(r => {
          if (input.containsEstimation) {
            const res = r as GetContainsEstimationReportGoalV2Response;
            return Success({
              type: GoalReportTypeV2.CONTAINS_ESTIMATION,
              goals: this._convertToGoalsContainEstimation(res.goals),
              goalsPerUser: this._convertToGoalsPerUserContainEstimation(res.goalsPerUser),
            } as GoalReportContainsEstimationV2)
          } else {
            const res = r as GetWithoutEstimationReportGoalV2Response;
            return Success({
              type: GoalReportTypeV2.WITHOUT_ESTIMATION,
              goals: this._convertToGoalsWithoutEstimation(res.goals),
              goalsPerUser: this._convertToGoalsPerUserWithoutEstimation(res.goalsPerUser),
            } as GoalReportWithoutEstimationV2);
          }
        }),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.BadRequest:
              return of(Failure(GeneralFailure.BadRequest));
            default:
              return of(Failure(GeneralFailure.Unexpected));
          }
        })
      );
  }

  getPerUser(category: GoalCategoryForReport, input: GetPerUserReportGoalV2Input): Observable<Result<
    GoalReportPerUserV2,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.reportGoalV2Api
      .getPerUser(
        category,
        {
          duration: {
            goalPeriodType: input.duration.goalPeriodType,
            startYearMonth: input.duration.startYearMonth.toFormat('yyyy-MM'),
            months: input.duration.months
          },
          userID: input.userID.value,
          containsEstimation: input.containsEstimation,
        },
      )
      .pipe(
        map(r => {
          if (input.containsEstimation) {
            const res = r as GetPerUserContainsEstimationReportGoalV2Response;
            return Success({
              type: GoalReportTypeV2.CONTAINS_ESTIMATION,
              user: this._convertGoalReportUserData(res.user),
              goals: this._convertToGoalsContainEstimation({
                unit: res.unit,
                total: res.total,
              }),
            } as GoalReportPerUserContainsEstimationV2)
          } else {
            const res = r as GetPerUserWithoutEstimationReportGoalV2Response;
            return Success({
              type: GoalReportTypeV2.WITHOUT_ESTIMATION,
              user: this._convertGoalReportUserData(res.user),
              goals: this._convertToGoalsWithoutEstimation({
                unit: res.unit,
                total: res.total,
              }),
            } as GoalReportPerUserWithoutEstimationV2);
          }
        }),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.BadRequest:
              return of(Failure(GeneralFailure.BadRequest));
            default:
              return of(Failure(GeneralFailure.Unexpected));
          }
        })
      );
  }

  getPersonal(category: GoalCategoryForReport, input: GetPersonalReportGoalV2Input): Observable<Result<
    GoalReportPerUserV2,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.reportGoalV2Api
      .getPersonal(
        category,
        {
          duration: {
            goalPeriodType: input.duration.goalPeriodType,
            startYearMonth: input.duration.startYearMonth.toFormat('yyyy-MM'),
            months: input.duration.months
          },
          containsEstimation: input.containsEstimation,
        },
      )
      .pipe(
        map(r => {
          if (input.containsEstimation) {
            const res = r as GetPerUserContainsEstimationReportGoalV2Response;
            return Success({
              type: GoalReportTypeV2.CONTAINS_ESTIMATION,
              user: this._convertGoalReportUserData(res.user),
              goals: this._convertToGoalsContainEstimation({
                unit: res.unit,
                total: res.total,
              }),
            } as GoalReportPerUserContainsEstimationV2)
          } else {
            const res = r as GetPerUserWithoutEstimationReportGoalV2Response;
            return Success({
              type: GoalReportTypeV2.WITHOUT_ESTIMATION,
              user: this._convertGoalReportUserData(res.user),
              goals: this._convertToGoalsWithoutEstimation({
                unit: res.unit,
                total: res.total,
              }),
            } as GoalReportPerUserWithoutEstimationV2);
          }
        }),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.BadRequest:
              return of(Failure(GeneralFailure.BadRequest));
            default:
              return of(Failure(GeneralFailure.Unexpected));
          }
        })
      );
  }

  private _convertToSingleGoalContainsEstimation(
    singleGoal: ContainsEstimationReportGoalV2
  ): GoalPerMonthContainsEstimationV2 {
    return {
      yearMonth: DateTime.fromFormat(singleGoal.yearMonth, 'yyyy-MM'),
      goal: singleGoal.goal,
      achievementAndEstimation: singleGoal.achievementAndEstimation,
      achievement: singleGoal.achievement,
      estimation: singleGoal.estimation,
      difference: singleGoal.difference,
      achievementRate: singleGoal.achievementRate,
    };
  }

  private _convertToSingleGoalWithoutEstimation(
    singleGoal: WithoutEstimationReportGoalV2
  ): GoalPerMonthWithoutEstimationV2 {
    return {
      yearMonth: DateTime.fromFormat(singleGoal.yearMonth, 'yyyy-MM'),
      goal: singleGoal.goal,
      achievement: singleGoal.achievement,
      difference: singleGoal.difference,
      achievementRate: singleGoal.achievementRate,
    };
  }

  private _convertToGoalsContainEstimation(
    goals: GetContainsEstimationReportGoalV2Response['goals']
  ): GoalsContainEstimationV2 {
    return {
      unit: goals.unit.map(v => this._convertToSingleGoalContainsEstimation(v)),
      total: goals.total.map(v => this._convertToSingleGoalContainsEstimation(v)),
    };
  }

  private _convertToGoalsWithoutEstimation(
    goals: GetWithoutEstimationReportGoalV2Response['goals']
  ): GoalsWithoutEstimationV2 {
    return {
      unit: goals.unit.map(v => this._convertToSingleGoalWithoutEstimation(v)),
      total: goals.total.map(v => this._convertToSingleGoalWithoutEstimation(v)),
    };
  }

  private _convertGoalReportUserData(user: UserReportGoalV2): GoalReportUserDataV2 {
    return {
      id: new UserID(user.id),
      name: new DisplayName(user.name),
      isActive: user.isActive,
    };
  }

  private _convertToGoalsPerUserContainEstimation(
    goalsPerUser: GetContainsEstimationReportGoalV2Response['goalsPerUser']
  ): GoalReportContainsEstimationV2['goalsPerUser'] {
    return goalsPerUser.map(g => ({
      user: this._convertGoalReportUserData(g.user),
      unit: g.unit.map(v => this._convertToSingleGoalContainsEstimation(v)),
      total: g.total.map(v => this._convertToSingleGoalContainsEstimation(v)),
    }));
  }

  private _convertToGoalsPerUserWithoutEstimation(
    goalsPerUser: GetWithoutEstimationReportGoalV2Response['goalsPerUser']
  ): GoalReportWithoutEstimationV2['goalsPerUser'] {
    return goalsPerUser.map(g => ({
      user: this._convertGoalReportUserData(g.user),
      unit: g.unit.map(v => this._convertToSingleGoalWithoutEstimation(v)),
      total: g.total.map(v => this._convertToSingleGoalWithoutEstimation(v)),
    }));
  }
}
