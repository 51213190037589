import {
  ClientFilterInput,
  ClientList,
  ClientListForSelectableSearch,
  ClientQueryService, CustomFieldItem,
  GetClientListInput,
  PaginatedClientList,
  SearchSelectableClientListInput
} from "#application/services/client-query.service";
import { MessageDialogService } from '#application/services/message-dialog.service';
import { ErrorResponseTitle, ServerApiError } from '#infrastructure/api/error';
import { ServerApi } from "#infrastructure/api/server-api";
import {
  ApiClientCategory, ApiCustomFieldItem,
  FilterClientResponse,
  GetClientResponse,
  GetCorporationResponseData,
  GetIndividualResponseData
} from "#infrastructure/api/server-client-api";
import { FilterRequestConverter, FilterService } from "#infrastructure/application/filter.service";
import { Target } from '#utils/messages';
import { Injectable, inject } from '@angular/core';
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Failure, Result, Success } from "app/lib/result/result";
import { ClientCategory, ClientTypeID, ClientTypeName } from "app/model/client-info-type/client-type";
import {
  Address,
  Client,
  ClientID,
  ClientItem,
  ClientName,
  ClientNumber,
  CorporateNumber,
  Corporation,
  CorporationName,
  CorporationNameKana,
  Established,
  FirstName,
  FirstNameKana,
  FiscalYearEnd,
  Individual,
  InitialCapital,
  LastName,
  LastNameKana,
  NumberOfEmployees,
  PhoneNumber,
  SNS,
  Website
} from "app/model/client/client";
import {CustomFieldId, CustomFieldName, CustomFieldValue} from 'app/model/custom-field/custom-field';
import { Observable, mergeMap, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {DateTime} from "luxon";
import {DisplayName, UserID} from "../../model/user/user";

@Injectable({
  providedIn: 'root'
})
export class ClientQueryServiceImpl implements ClientQueryService {

  private readonly _serverApi = inject(ServerApi);
  private readonly _messageDialogService = inject(MessageDialogService);
  private readonly _filterService = inject(FilterService);

  findBy(input: GetClientListInput): Observable<PaginatedClientList> {
    return this._filterService.getSchema('clients')
      .pipe(
        mergeMap(schema => {
          const filterRequest = FilterRequestConverter.toFilterRequest(schema, input);
          return this._serverApi.clientApi.filter(filterRequest);
        }),
        map(convertFilterClientResponseToClientList)
      );
  }

  get(id: ClientID): Observable<Client | undefined> {
    return this._serverApi.clientApi.get(id.value)
      .pipe(
        map(response => convertGetClientResponseToClient(response)),
        catchError((e: ServerApiError) => {
          if (e.response.title === ErrorResponseTitle.ResourceNotFoundError) {
            return of(undefined);
          } else {
            this._messageDialogService.notice({errorTarget: `${Target.CLIENT}の取得`});
            return throwError(Error(`unexpected error`));
          }
        })
      );
  }

  filter(input: ClientFilterInput): Observable<PaginatedClientList> {
    return this._serverApi.clientApi
      .filter({
        pagination: {
          perPage: input.perPage,
          page: input.page,
        },
        filters: input.filterInput.filters,
        sorts: input.filterInput.sorts
      })
      .pipe(
        map(convertFilterClientResponseToClientList)
      )
  }

  filterList(input: ClientFilterInput): Observable<PaginatedClientList> {
    return this._serverApi.clientApi.filterList(
      {
        pagination: {
          perPage: input.perPage, page: input.page
        },
        filters: input.filterInput.filters,
        sorts: input.filterInput.sorts
      }
    ).pipe(map(convertFilterClientResponseToClientList));
  }

  searchSelectable(input: SearchSelectableClientListInput): Observable<Result<
    ClientListForSelectableSearch,
    typeof GeneralFailure.Unexpected
  >> {
    return this._serverApi.clientApi.searchSelectable(input)
      .pipe(
        map(res => Success({
          totalCount: res.totalCount,
          list: res.results.map(item => ({
            id: new ClientID(item.id),
            number: new ClientNumber(item.number),
            name: new ClientName(item.name),
            category: categoryConverter(item.category),
            address: item.address === undefined
              ? undefined
              : new Address(item.address),
          }))
        })),
        catchError(() => of(Failure(GeneralFailure.Unexpected)))
      )
  }
}

const categoryConverter = (category: ApiClientCategory): ClientCategory => {
  switch (category) {
    case ApiClientCategory.CORPORATION:
      return ClientCategory.Corporation;
    case ApiClientCategory.INDIVIDUAL:
      return ClientCategory.Individual;
    default:
      const _exhaustiveCheck: never = category;
      throw Error(`client category is not supported`);
  }
}

const convertFilterClientResponseToClientList = (response: FilterClientResponse): PaginatedClientList => {
  const clientList: ClientList = response.results.map((item) => {
    const category = item.category;
    if (category === 'CORPORATION') {
      return {
        id: new ClientID(item.id),
        clientNumber: new ClientNumber(item.clientNumber),
        name: new ClientName(item.name),
        clientType: {
          id: new ClientTypeID(item.clientType.id),
          name: new ClientTypeName(item.clientType.name),
        },
        category: ClientCategory.Corporation,
        corporationName: new CorporationName(item.corporation.corporationName),
        corporationNameKana: new CorporationNameKana(item.corporation.corporationNameKana),
        corporateNumber: item.corporation.corporateNumber === undefined
          ? undefined
          : new CorporateNumber(item.corporation.corporateNumber),
        address: item.corporation.address === undefined
          ? undefined
          : new Address(item.corporation.address),
        phoneNumber: item.corporation.phoneNumber === undefined
          ? undefined
          : new PhoneNumber(item.corporation.phoneNumber),
        established: item.corporation.established === undefined
          ? undefined
          : new Established(item.corporation.established),
        numberOfEmployees: item.corporation.numberOfEmployees === undefined
          ? undefined
          : new NumberOfEmployees(item.corporation.numberOfEmployees),
        initialCapital: item.corporation.initialCapital === undefined
          ? undefined
          : new InitialCapital(item.corporation.initialCapital),
        fiscalYearEnd: item.corporation.fiscalYearEnd === undefined
          ? undefined
          : new FiscalYearEnd(item.corporation.fiscalYearEnd),
        website: item.corporation.website === undefined
          ? undefined
          : new Website(item.corporation.website),
        items: item.items === undefined ? undefined
          : Object.entries(item.items).map(([id, customField]) => toCustomFieldItem(id, customField)),
      };
    } else if (category === 'INDIVIDUAL') {
      return {
        id: new ClientID(item.id),
        clientNumber: new ClientNumber(item.clientNumber),
        name: new ClientName(item.name),
        clientType: {
          id: new ClientTypeID(item.clientType.id),
          name: new ClientTypeName(item.clientType.name),
        },
        category: ClientCategory.Individual,
        firstName: new FirstName(item.individual.firstName),
        lastName: new LastName(item.individual.lastName),
        firstNameKana: new FirstNameKana(item.individual.firstNameKana),
        lastNameKana: new LastNameKana(item.individual.lastNameKana),
        address: item.individual.address === undefined
          ? undefined
          : new Address(item.individual.address),
        phoneNumber: item.individual.phoneNumber === undefined
          ? undefined
          : new PhoneNumber(item.individual.phoneNumber),
        sns1: item.individual.sns1 === undefined
          ? undefined
          : new SNS(item.individual.sns1),
        sns2: item.individual.sns2 === undefined
          ? undefined
          : new SNS(item.individual.sns2),
        items: item.items === undefined ? undefined
          : Object.entries(item.items).map(([id, customField]) => toCustomFieldItem(id, customField)),
      };
    } else {
      const _exhaustiveCheck: never = category;
      throw Error(`client category is not supported`);
    }
  });
  return {
    totalCount: response.totalCount,
    list: clientList
  };
}

const toCustomFieldItem = (customFieldID: string, customField: ApiCustomFieldItem): CustomFieldItem => {
  switch (customField.type) {
    case 'SINGLE_LINE_TEXT':
    case 'MULTI_LINE_TEXT':
    case 'URL':
    case 'MAIL_ADDRESS':
    case 'ADDRESS':
    case 'PHONE_NUMBER':
      return {
        type: customField.type,
        id: new CustomFieldId(customFieldID),
        name: new CustomFieldName(customField.name),
        value: customField.value
      }
    case 'NUMBER':
      return {
        type: customField.type,
        id: new CustomFieldId(customFieldID),
        name: new CustomFieldName(customField.name),
        value: customField.value
      }
    case 'DATE':
    case 'DATETIME':
      const dateFormat = customField.type === 'DATE' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm:ss'
      return {
        type: customField.type,
        id: new CustomFieldId(customFieldID),
        name: new CustomFieldName(customField.name),
        value: (customField.value === undefined || customField.value === '') ? undefined
          : DateTime.fromFormat(customField.value, dateFormat).toJSDate()
      }
    case 'SELECT':
      return {
        type: customField.type,
        id: new CustomFieldId(customFieldID),
        name: new CustomFieldName(customField.name),
        values: customField.value === undefined ? [] : customField.value.split(/\t/).filter(v => v != ''),
      };
    case 'USER':
      return {
        type: customField.type,
        id: new CustomFieldId(customFieldID),
        name: new CustomFieldName(customField.name),
        userID: new UserID(customField.userID),
        userName: customField.userName === undefined ? undefined : new DisplayName(customField.userName),
      };
    case 'ATTACHMENT':
      return {
        type: customField.type,
        id: new CustomFieldId(customFieldID),
        name: new CustomFieldName(customField.name),
        fileStatus: customField.fileStatus,
      }
    case 'CLIENT':
      return {
        type: customField.type,
        id: new CustomFieldId(customFieldID),
        name: new CustomFieldName(customField.name),
        clientID: customField.clientID === undefined ? undefined : new ClientID(customField.clientID),
        clientName: customField.clientName === undefined ? undefined : new ClientName(customField.clientName),
      };
    case 'CHECKBOX':
      return {
        type: customField.type,
        id: new CustomFieldId(customFieldID),
        name: new CustomFieldName(customField.name),
        value: customField.value,
      };
    default: throw Error(`Unexpected custom field: ${customField}`)
  }
}

const isCorporationData = (
  response: GetClientResponse
): response is GetCorporationResponseData => {
  return typeof (response as any)?.corporationName === 'string';
};

const isIndividualData = (
  response: GetClientResponse
): response is GetIndividualResponseData => {
  return typeof (response as any)?.firstName === 'string';
};

type keyValueItem = {
  [key: string]: string
};

const convertKeyValueObjToClientItemList = (items: keyValueItem): ClientItem[] => {
  const keys = Object.keys(items);
  const clientItems: ClientItem[] = [];
  keys.forEach(key => {
    clientItems.push(new ClientItem(
      new CustomFieldId(key),
      new CustomFieldValue(items[key])
    ));
  });
  return clientItems;
};

const convertGetClientResponseToClient = (
  response: GetClientResponse
): Client => {
  if (isCorporationData(response)) {
    const id = new ClientID(response.id);
    const clientNumber = new ClientNumber(response.clientNumber);
    const name = new ClientName(response.name);
    const clientTypeID = new ClientTypeID(response.clientTypeID);
    const items =
      response.items !== undefined
        ? convertKeyValueObjToClientItemList(response.items)
        : undefined;
    return new Corporation(
      id,
      clientNumber,
      name,
      clientTypeID,
      new CorporationName(response.corporationName),
      new CorporationNameKana(response.corporationNameKana),
      response.corporateNumber !== undefined
        ? new CorporateNumber(response.corporateNumber)
        : undefined,
      response.address !== undefined
        ? new Address(response.address)
        : undefined,
      response.phoneNumber !== undefined
        ? new PhoneNumber(response.phoneNumber)
        : undefined,
      response.established !== undefined
        ? new Established(response.established)
        : undefined,
      response.numberOfEmployees !== undefined
        ? new NumberOfEmployees(response.numberOfEmployees)
        : undefined,
      response.initialCapital !== undefined
        ? new InitialCapital(response.initialCapital)
        : undefined,
      response.fiscalYearEnd !== undefined
        ? new FiscalYearEnd(response.fiscalYearEnd)
        : undefined,
      response.website !== undefined
        ? new Website(response.website)
        : undefined,
      items,
      response.deleted,
    );
  } else if (isIndividualData(response)) {
    const id = new ClientID(response.id);
    const clientNumber = new ClientNumber(response.clientNumber);
    const name = new ClientName(response.name);
    const clientTypeID = new ClientTypeID(response.clientTypeID);
    const items =
      response.items !== undefined
        ? convertKeyValueObjToClientItemList(response.items)
        : undefined;
    return new Individual(
      id,
      clientNumber,
      name,
      clientTypeID,
      new FirstName(response.firstName),
      new LastName(response.lastName),
      new FirstNameKana(response.firstNameKana),
      new LastNameKana(response.lastNameKana),
      response.address !== undefined
        ? new Address(response.address)
        : undefined,
      response.phoneNumber !== undefined
        ? new PhoneNumber(response.phoneNumber)
        : undefined,
      response.sns1 !== undefined
        ? new SNS(response.sns1)
        : undefined,
      response.sns2 !== undefined
        ? new SNS(response.sns2)
        : undefined,
      items,
      response.deleted,
    );
  } else {
    throw Error('invalid response type');
  }
};
