import { FilterOpportunitiesPerUserInput, GetHeadersInput, MeetingBoardQueryService, NotFoundMTGBoardSettingError, PrivateVisibilityMTGBoardError, RangeOpportunitiesPerUserInput, UnexpectedMTGBoardSetUpError } from '#application/services/meeting-board-query.service';
import { OpportunityListV2, OpportunityQueryV2Service } from '#application/services/opportunity-query-v2.service';
import { ServerApiError, ServerApiErrorCode } from '#infrastructure/api/error';
import { ServerApi } from '#infrastructure/api/server-api';
import { MeetingBoardApi } from '#infrastructure/api/server-meeting-board-api';
import { SearchFilters, convertToFilterInput } from '#presentation/pages/activity/shared/search-filter/search-filter';
import { Injectable, inject } from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import { MeetingBoardHeader, MeetingBoardPropsForSettingUp, MeetingBoardSettingID, MeetingBoardSettingName } from 'app/model/meeting-board/meeting-board';
import { MeetingBoardSettingFilterCondition } from 'app/model/meeting-board/meeting-board-setting-filter-conditon';
import { DisplayName, UserID } from 'app/model/user/user';
import { Observable, catchError, concatMap, from, map, of, toArray } from 'rxjs';
import { OpportunityListConverter } from './opportunity-query-v2.service';

@Injectable({
  providedIn: 'root'
})
export class MeetingBoardQueryServiceImpl implements MeetingBoardQueryService {
  private readonly serverApi = inject(ServerApi);
  private readonly opportunityQueryService = inject(OpportunityQueryV2Service);

  getForSettingUp(id: MeetingBoardSettingID): Observable<MeetingBoardPropsForSettingUp> {
    return this.serverApi.meetingBoardApi
      .getForSettingUp(id)
      .pipe(
        map((res) => {
          const filterCondition = MeetingBoardSettingFilterCondition.parse(res.filterConditionLiteral);
          return {
            id: MeetingBoardSettingID(id),
            name: MeetingBoardSettingName(res.name),
            editability: res.editability,
            ownerID: new UserID(res.ownerID),
            filterInput: convertToFilterInput(new SearchFilters(filterCondition.filters), filterCondition.sorts),
            displayItems: filterCondition.displayItems,
          }
        }),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.NotFound:
              throw new NotFoundMTGBoardSettingError();
            case ServerApiErrorCode.Conflict:
              throw new PrivateVisibilityMTGBoardError();
            default:
              throw new UnexpectedMTGBoardSetUpError();
          }
        }),
      );
  }

  getHeaders(input: GetHeadersInput): Observable<MeetingBoardHeader[]> {
    return this.serverApi.meetingBoardApi
      .getHeader(this._toGetHeaderApiParam(input))
      .pipe(
        map(res => res.headers.map(header => ({
          displayUser: {
            userID: new UserID(header.displayUser.userID),
            displayName: new DisplayName(header.displayUser.userDisplayName),
          },
          wonAmount: header.wonAmount,
          wonCount: header.wonCount,
          lostAmount: header.lostAmount,
          lostCount: header.lostCount,
          goals: header.goals,
        }))),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.NotFound:
              throw new NotFoundMTGBoardSettingError();
            default:
              throw new UnexpectedMTGBoardSetUpError();
          }
        }),
      );
  }

  private _toGetHeaderApiParam(
    input: GetHeadersInput,
  ): Parameters<MeetingBoardApi['getHeader']>[0] {
    return {
      meetingBoardSettingID: input.id,
      duration: {
        goalPeriodType: 'MONTHLY',
        startYearMonth: input.dateSpan.start.toFormat('yyyy-MM'),
        months: input.dateSpan.months,
      },
    };
  }

  /**
   * ユーザーごとの商談一覧の取得に特化させるため、
   * OpportunityQueryV2Service#getOpportunityListをラップしたメソッド。
   */
  filterOpportunitiesPerUser(input: FilterOpportunitiesPerUserInput): Observable<Result<
    OpportunityListV2,
    | typeof GeneralFailure.Unexpected
  >> {
    return this.opportunityQueryService.getOpportunityList({
      perPage: input.perPage,
      page: input.page,
      filterInput: {
        filters: {
          'builtin-assignee': {
            type: 'EXISTS_AND_FILTERED',
            operator: {
              type: 'IN',
              values: [input.userID],
            }
          },
          ...input.filterInput.filters,
        },
        sorts: input.filterInput.sorts,
      },
    });
  }

  rangeOpportunitiesPerUser(input: RangeOpportunitiesPerUserInput): Observable<Result<
    OpportunityListV2,
    | typeof GeneralFailure.Unexpected
  >> {
    return from(input.pages)
      .pipe(
        concatMap(page => this.serverApi.opportunityV2Api.getOpportunityList({
          pagination: {
            perPage: input.perPage,
            page,
          },
          filters: {
            'builtin-assignee': {
              type: 'EXISTS_AND_FILTERED',
              operator: {
                type: 'IN',
                values: [input.userID],
              }
            },
            ...input.filterInput.filters,
          },
          sorts: input.filterInput.sorts,
        })),
        toArray(),
        map(res => {
          const results = res.flatMap(r => r.results);
          return Success({
            totalCount: res[0].totalCount,
            results: results.map(OpportunityListConverter.toOpportunityListItem),
          });
        }),
        catchError(() => of(Failure(GeneralFailure.Unexpected))),
      );
  }
}
