import { AdminModule } from "#presentation/pages/admin/admin.module";
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorComponent } from "./pages/error/error.component";
import { SingleSignOnModule } from './pages/single-sign-on/single-sign-on.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ErrorComponent,
    AdminModule,
    SingleSignOnModule,
  ],
  exports: [AppComponent],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        disableClose: true,
        hasBackdrop: true,
        autoFocus: 'dialog',
      } as MatDialogConfig
    }
  ],
})
export class PresentationModule {
  private readonly _svgIcons: {name: string, path: string}[] = [
    { name: 'nurturing-icon', path: 'assets/img/nurturing-icon.svg' },
  ];
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    this._svgIcons.forEach(icon =>
      iconRegistry.addSvgIcon(icon.name, sanitizer.bypassSecurityTrustResourceUrl(icon.path))
    )
  }
}
