import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiMetaUserSecurityRole } from './server-meta-user-auth-api';
import {ApiLocalizeKey} from "#infrastructure/api/server-auth-api";

export interface SSORequestHeaders {
  [key: string]: string,
}

export interface SSOResponse {
  organizationName: string,
  userID: string,
  // FIXME バックエンドと同時にloginIDに変更する
  userName: string,
  displayName: string,
  mailAddress: string,
  fiscalYearBegin: number,
  securityRoles: ApiMetaUserSecurityRole[],
  isCoachingAvailable: boolean,
  isProposalEnabled: boolean,
  isLinkageEnabled: boolean,
  isOpportunityGroupEnabled: boolean,
  localize: Record<ApiLocalizeKey, string>,
}

export class SingleSignOnApi {
  constructor(private readonly http: HttpClient) {}

  signOn(headers: SSORequestHeaders): Observable<SSOResponse> {
    return this.http.post<SSOResponse>('/api/single-sign-on', {}, { headers });
  }
}
