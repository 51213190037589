export class HintID {
  constructor(public readonly value: string) {}

  equals(id: HintID): boolean {
    return id.value === this.value;
  }
}

export class HintTitle {
  constructor(public readonly value: string) {}

  static readonly MAX_LENGTH = 50;
}

export class HintContent {
  constructor(public readonly value: string) {}

  static readonly MAX_LENGTH = 300;
}

export const HintReviewStatus = {
  GOOD: 'GOOD',
  BAD: 'BAD',
  NONE: 'NONE',
} as const;
export type HintReviewStatus = typeof HintReviewStatus[keyof typeof HintReviewStatus];

export class HintReview {
  private constructor(
    private readonly status: HintReviewStatus,
  ) {}

  static create(status: HintReviewStatus): HintReview {
    return new HintReview(status);
  }

  changeReview(status: HintReviewStatus): HintReview {
    return new HintReview(status);
  }

  getStatus(): HintReviewStatus {
    return this.status;
  }
}

export type Hint = {
  id: HintID,
  title: HintTitle,
  content: HintContent,
  review: HintReview,
};

export type HintList = Hint[];

export const removeDuplicate = (list: HintList): HintList => {
  const idSet = new Set(list.map(hint => hint.id.value));
  return list.filter(hint => idSet.has(hint.id.value));
}
