import { Observable } from 'rxjs';
import { User, UserID } from 'app/model/user/user';

/**
 * ユーザーリポジトリ
 */
export abstract class UserRepository {

  abstract findById(userID: UserID): Observable<User>;

  abstract save(user: User): Observable<void>;

  abstract delete(userID: UserID): Observable<void>;

  abstract resetPassword(userId: UserID): Observable<void>;
}
