import { OpportunityGroupType, OpportunityGroupTypeID } from "app/model/opportunity-group/opportunity-group-type";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { Observable } from "rxjs";

export abstract class OpportunityGroupTypeQueryService {
  abstract list(): Observable<Result<
    OpportunityGroupType[],
    typeof GeneralFailure.Unexpected
  >>;

  abstract get(id: OpportunityGroupTypeID): Observable<Result<
    OpportunityGroupType,
    typeof GeneralFailure.Unexpected
  >>;
}
