import {MessageDialogService} from '#application/services/message-dialog.service';
import {
  GetPersonalCvrReportInput,
  GetPersonalCvrReportOutput,
  GetReportCvrInputs,
  GetReportCvrOutputs,
  ReportCvrQueryService
} from '#application/services/report-cvr-query.service';
import {PhaseName} from 'app/model/sales-phase/sales-phase';
import {ServerApi} from '#infrastructure/api/server-api';
import {
  GetReportCvrRequest,
  GetReportCvrResponse,
  PersonalCvrReportRequest
} from '#infrastructure/api/server-report-cvr-api';
import {inject, Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportCvrQueryServiceImpl extends ReportCvrQueryService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _messageDialogService = inject(MessageDialogService);

  get(inputs: GetReportCvrInputs): Observable<GetReportCvrOutputs> {
    return this._serverApi.reportCvrApi
      .get(ReportCvrQueryConverter.toReportCvrRequest(inputs))
      .pipe(
        map(ReportCvrQueryConverter.toOutputs),
        catchError((err) => {
          this._messageDialogService.notice({
            errorTarget: 'CVRレポートの取得',
          });
          return throwError(err);
        }),
      );
  }

  getPersonalCvrReport(input: GetPersonalCvrReportInput): Observable<GetPersonalCvrReportOutput> {
    return this._serverApi.reportCvrApi
      .getPersonalCvrReport(ReportCvrQueryConverter.toPersonalCvrReportRequest(input))
      .pipe(
        map(ReportCvrQueryConverter.toOutputs),
        catchError((err) => {
          this._messageDialogService.notice({
            errorTarget: 'マイデータ移行率の取得',
          });
          return throwError(err);
        }),
      );
  }
}


namespace ReportCvrQueryConverter {

  export const toReportCvrRequest = (inputs: GetReportCvrInputs): GetReportCvrRequest => {
    return {
      opportunityTypeID: inputs.opportunityTypeID.value,
      duration: {
        startYearMonth: inputs.duration.startYearMonth.toFormat('yyyy-MM'),
        months: inputs.duration.months,
      },
      assignee: {
        type: inputs.assignee.type,
        ...inputs.assignee.type === 'USER_GROUP'
          ? {userGroupID: inputs.assignee.userGroupID.value}
          : {},
      },
    };
  }

  export const toPersonalCvrReportRequest = (input: GetPersonalCvrReportInput): PersonalCvrReportRequest => {
    return {
      opportunityTypeID: input.opportunityTypeID.value,
      duration: {
        startYearMonth: input.duration.startYearMonth.toFormat('yyyy-MM'),
        months: input.duration.months,
      },
    };
  }

  export const toOutputs = (res: GetReportCvrResponse): GetReportCvrOutputs => {
    return {
      wonCount: res.wonCount,
      wonRate: res.wonRate,
      totalLostCount: res.totalLostCount,
      conversionRates: res.conversionRates.map(v => ({
        phase: {
          name: new PhaseName(v.phase.name),
          countPerPhase: v.phase.countPerPhase,
          lostCount: v.phase.lostCount,
        },
        rate: v.rate,
      })),
    };
  }
}
