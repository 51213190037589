import { LocalizeKey } from "#application/services/authorize.service";
import { LocalizedNameService } from "#application/services/localized-name.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { OpportunityGroupStatusList, OpportunityGroupStatusQueryService } from "#application/services/opportunity-group-status-query.service";
import { OpportunityGroupStatus, OpportunityGroupStatusDescription, OpportunityGroupStatusID, OpportunityGroupStatusName } from "app/model/opportunity-group/opportunity-group-status";
import { ServerApiError, ServerApiErrorCode } from "#infrastructure/api/error";
import { ServerApi } from "#infrastructure/api/server-api";
import { Injectable } from "@angular/core";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Failure, Result, Success } from "app/lib/result/result";
import { NEVER, Observable, catchError, map, of } from "rxjs";

@Injectable({ providedIn: 'root' })
export class OpportunityGroupStatusQueryServiceImpl extends OpportunityGroupStatusQueryService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly messageDialogService: MessageDialogService,
    private readonly localizedNameService: LocalizedNameService,
  ) {
    super();
  }

  list(): Observable<Result<
    OpportunityGroupStatusList,
    | typeof GeneralFailure.BadRequest
  >> {
    const fieldName = `${this.localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}ステータス`
    return this.serverApi.opportunityGroupStatusApi.list()
    .pipe(
      map(res => (Success(
        res.map(v => ({
          id: OpportunityGroupStatusID(v.id),
          name: OpportunityGroupStatusName(v.name),
          description: OpportunityGroupStatusDescription(v.description!)
        }))
      ))),
      catchError((e: ServerApiError) => {
        if (e.code === ServerApiErrorCode.BadRequest) {
          return of(Failure(GeneralFailure.BadRequest));
        }
        this.messageDialogService.notice({
          errorTarget: `${fieldName}の取得`
        });
        return NEVER;
      })
    );
  }

  get(id: OpportunityGroupStatusID): Observable<Result<
    OpportunityGroupStatus,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >> {
    const fieldName = `${this.localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}ステータス`
    return this.serverApi.opportunityGroupStatusApi.get(id)
    .pipe(
      map(res =>(Success({
        id: OpportunityGroupStatusID(res.id),
        name: OpportunityGroupStatusName(res.name),
        description: OpportunityGroupStatusDescription(res.description!)
      }))),
      catchError((e: ServerApiError) => {
        if (e.code === ServerApiErrorCode.BadRequest) {
          return of(Failure(GeneralFailure.BadRequest));
        }
        if (e.code === ServerApiErrorCode.NotFound) {
          return of(Failure(GeneralFailure.NotFound));
        }
        this.messageDialogService.notice({
          errorTarget: `${fieldName}の取得`
        });
        return NEVER;
      })
    );
  }
}
