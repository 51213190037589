import {ServerApi} from '#infrastructure/api/server-api';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {
  CreateFollowUpActionTypeParam,
  FollowUpActionTypeFactory
} from "app/model/follow-up-action/follow-up-action-type.factory";

@Injectable({providedIn: 'root'})
export class FollowUpActionTypeFactoryImpl extends FollowUpActionTypeFactory {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  create(param: CreateFollowUpActionTypeParam): Observable<void> {
    return this.serverApi.followUpActionTypeApi.create(
      {
        name: param.name.value,
        description: param.description.value,
      }
    );
  }
}
