import {Component, Inject, Injectable} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { MessageDialogData, MessageDialogService } from '#application/services/message-dialog.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessageDialogServiceImpl implements MessageDialogService {

  constructor(
    private readonly dialog: MatDialog,
  ) {}

  notice(data: MessageDialogData): void {
    this.dialog.open(MessageDialogComponent, {
      maxWidth: '480px',
      data,
    });
  }

  notice$(data: MessageDialogData): Observable<void> {
    return this.dialog.open(MessageDialogComponent, {
      maxWidth: '480px',
      data,
    })
    .afterClosed();
  }
}

@Component({
  template: `
    <h2 *ngIf="hasTitle()" mat-dialog-title>{{ title }}</h2>
    <mat-dialog-content>
      <ng-container *ngFor="let message of messages">
        <div>{{ message }}</div>
      </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-raised-button mat-dialog-close>
        閉じる
      </button>
    </mat-dialog-actions>
  `,
})
export class MessageDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: MessageDialogData,
  ) {}

  get title(): string {
    return this.data.title ? this.data.title : '';
  }

  get messages(): string[] {
    return this.data.errorTarget
    ? [
        `予期せぬエラーが発生したため、${this.data.errorTarget}に失敗しました。`,
        `時間を置いて再度お試しください。`
      ]
    : this.data.message
      ? this.data.message.split('\n')
      : ['']
  }

  hasTitle(): boolean {
    return this.data.title !== undefined;
  }
}
