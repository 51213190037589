import {ClientType} from 'app/model/client-info-type/client-type';
import {Observable} from 'rxjs';

export abstract class ClientTypeRepository {
  abstract save(clientType: ClientType): Observable<void>;

  abstract saveClientTypeOrder(clientTypes: ClientType[]): Observable<void>;

  abstract delete(clientType: ClientType): Observable<void>;
}
