import { ClientName } from "app/model/client/client";
import { CustomFieldId, CustomFieldValue } from "app/model/custom-field/custom-field";
import { OpportunityTypeName } from "app/model/opportunity-type/opportunity-type";
import { OpportunityID, OpportunityName, OpportunityNumber, OpportunityStatus } from "app/model/opportunity/opportunity";
import { PhaseName, SalesPhaseName } from "app/model/sales-phase/sales-phase";
import { DisplayName } from "app/model/user/user";
import { Brand } from "app/lib/brand/brand";
import { OpportunityGroupStatusID, OpportunityGroupStatusName } from "./opportunity-group-status";
import { OpportunityGroupTypeID, OpportunityGroupTypeName } from "./opportunity-group-type";

export type OpportunityGroup = Readonly<{
  id: OpportunityGroupID,
  number: OpportunityGroupNumber,
  title: OpportunityGroupTitle,
  opportunityGroupTypeID: OpportunityGroupTypeID,
  opportunityGroupTypeName: OpportunityGroupTypeName,
  opportunityGroupStatusID: OpportunityGroupStatusID,
  opportunityGroupStatusName: OpportunityGroupStatusName,
  items?: OpportunityGroupItem[],
}>;

export type Opportunity = Readonly<{
  id: OpportunityID,
  opportunityNumber: OpportunityNumber,
  opportunityName: OpportunityName,
  opportunityStatus: OpportunityStatus,
  clientName: ClientName,
  salesPhaseName: SalesPhaseName,
  phaseName: PhaseName,
  opportunityTypeName: OpportunityTypeName,
  assigneeName: DisplayName,
}>;

export type OpportunityGroupID = Brand<string, 'OpportunityGroupID'>;
export function OpportunityGroupID(value: string) {
  return value as OpportunityGroupID;
}

export type OpportunityGroupNumber = Brand<number, 'OpportunityGroupNumber'>;
export function OpportunityGroupNumber(value: number) {
  return value as OpportunityGroupNumber;
}

export type OpportunityGroupTitle = Brand<string, 'OpportunityGroupTitle'>;
export function OpportunityGroupTitle(value: string) {
  return value as OpportunityGroupTitle;
}
export namespace OpportunityGroupTitle {
  export const MAX_LENGTH = 30
}

export type OpportunityGroupItem = Readonly<{
  fieldId: CustomFieldId,
  fieldValue: CustomFieldValue
}>
export function OpportunityGroupItem(
  fieldId: CustomFieldId,
  fieldValue: CustomFieldValue
) {
  return {fieldId, fieldValue} as OpportunityGroupItem;
}
