import {Observable} from "rxjs";
import {AdoptedReason, AdoptedReasonID} from "app/model/adopted-reason/adopted-reason";

export type AdoptedReasonList = AdoptedReason[];
export abstract class AdoptedReasonQueryService {

  abstract list(): Observable<AdoptedReasonList>;

  abstract get(id: AdoptedReasonID): Observable<AdoptedReason>;
}
