import { LocalizeKey } from "#application/services/authorize.service";
import { ConfirmService } from "#application/services/confirm.service";
import { LocalizedNameService } from "#application/services/localized-name.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import {
  AddNurturingInput,
  NurturingCommandService,
  ReportNurturingInput,
  UpdateNurturingInput,
} from "#application/services/nurturing-command.service";
import { SnackBarService } from "#application/services/snack-bar.service";
import { UnreportedActivityCountStoreService } from "#application/services/unreported-activity-count-store.service";
import { CustomItems } from "app/model/custom-field/custom-field";
import { NurturingID } from "app/model/nurturing/nurturing";
import { ServerApiError, ServerApiErrorCode } from "#infrastructure/api/error";
import { ServerApi } from "#infrastructure/api/server-api";
import { fromDateToStr } from "#utils/date";
import { MessageActionType, messageFn } from "#utils/messages";
import { Injectable, inject } from "@angular/core";
import { Observable, catchError, concatMap, filter, tap, throwError } from "rxjs";
import {ScheduleChangeEmitterService} from "#application/services/schedule-change-emitter.service";

@Injectable({ providedIn: 'root' })
export class NurturingCommandServiceImpl implements NurturingCommandService {
  private readonly serverApi = inject(ServerApi);
  private readonly snackBarService = inject(SnackBarService);
  private readonly messageDialogService = inject(MessageDialogService);
  private readonly confirmService = inject(ConfirmService);
  private readonly _localizedNameService = inject(LocalizedNameService);
  private readonly _unreportedActivityCountStoreService = inject(UnreportedActivityCountStoreService);
  private readonly _scheduleChangeEmitter = inject(ScheduleChangeEmitterService);

  add(input: AddNurturingInput): Observable<void> {
    const nurturingAlias = this._localizedNameService.get(LocalizeKey.NURTURING);
    return this.serverApi.nurturingApi
      .create({
        duration: {
          startDateTime: fromDateToStr(input.duration.startDateTime.value),
          minutes: input.duration.minutes.value,
        },
        clientID: input.clientID.value,
        title: input.title.value,
        companions: input.companions.map(c => c.value),
        attendees: input.attendees.map(a => a.value),
        note: input.note.value,
        nurturingTypeID: input.nurturingTypeID.value,
        beforeHearing: CustomItems.convertToRawKVObj(input.beforeHearing),
        afterHearing: CustomItems.convertToRawKVObj(input.afterHearing),
      })
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(nurturingAlias, 'CREATE_SUCCESS').message);
          this._unreportedActivityCountStoreService.sync();
          this._scheduleChangeEmitter.emit();
        }),
        catchError(err => {
          return this._showError(err, `${nurturingAlias}の登録`);
        }),
      )
  }

  update(id: NurturingID, input: UpdateNurturingInput): Observable<void> {
    const nurturingAlias = this._localizedNameService.get(LocalizeKey.NURTURING);
    return this.serverApi.nurturingApi
      .update(id.value, {
        duration: {
          startDateTime: fromDateToStr(input.duration.startDateTime.value),
          minutes: input.duration.minutes.value,
        },
        title: input.title.value,
        companions: input.companions.map(c => c.value),
        attendees: input.attendees.map(a => a.value),
        note: input.note.value,
        beforeHearing: CustomItems.convertToRawKVObj(input.beforeHearing),
        afterHearing: CustomItems.convertToRawKVObj(input.afterHearing),
      })
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(nurturingAlias, 'UPDATE_SUCCESS').message);
          this._unreportedActivityCountStoreService.sync();
          this._scheduleChangeEmitter.emit();
        }),
        catchError(err => {
          return this._checkNotFoundError(err, nurturingAlias, nurturingAlias, 'UPDATE_ERROR')
            || this._showError(err, `${nurturingAlias}の更新`);
        }),
      )
  }

  delete(id: NurturingID): Observable<void> {
    const nurturingAlias = this._localizedNameService.get(LocalizeKey.NURTURING);
    return this.confirmService.confirm({
      title: messageFn(nurturingAlias, 'DELETE_CONFIRM').title,
      message: messageFn(nurturingAlias, 'DELETE_CONFIRM').message
    }).pipe(
      filter(result => result.isOK()),
      concatMap(() => this.serverApi.nurturingApi.delete(id.value)),
      tap(() => {
        this.snackBarService.show(messageFn(nurturingAlias, 'DELETE_SUCCESS').message);
        this._unreportedActivityCountStoreService.sync();
        this._scheduleChangeEmitter.emit();
      }),
      catchError(err => {
        this.messageDialogService.notice({
          title: messageFn(nurturingAlias, 'DELETE_ERROR').title,
          message: messageFn(nurturingAlias, 'DELETE_ERROR').message
        });
        return throwError(() => err);
      })
    );
  }

  report(id: NurturingID, input: ReportNurturingInput): Observable<void> {
    const nurturingAlias = this._localizedNameService.get(LocalizeKey.NURTURING);
    return this.serverApi.nurturingApi
      .report(id.value, {
        duration: {
          startDateTime: fromDateToStr(input.duration.startDateTime.value),
          minutes: input.duration.minutes.value,
        },
        title: input.title.value,
        companions: input.companions.map(c => c.value),
        attendees: input.attendees.map(a => a.value),
        note: input.note.value,
        beforeHearing: CustomItems.convertToRawKVObj(input.beforeHearing),
        afterHearing: CustomItems.convertToRawKVObj(input.afterHearing),
      })
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(`${nurturingAlias}の報告`, 'COMPLETED').message);
          this._unreportedActivityCountStoreService.sync();
          this._scheduleChangeEmitter.emit();
        }),
        catchError(err => {
          if (err instanceof ServerApiError && err.code === ServerApiErrorCode.Conflict) {
            this.snackBarService.show(`${nurturingAlias}は既に報告済みです。`);
            return throwError(() => err);
          } else {
            return this._checkNotFoundError(err, `${nurturingAlias}の報告`, nurturingAlias, 'FAILED')
              || this._showError(err, `${nurturingAlias}の報告`);
          }
        }),
      )
  }

  cancelReport(id: NurturingID): Observable<void> {
    const nurturingAlias = this._localizedNameService.get(LocalizeKey.NURTURING);
    return this.confirmService
      .confirm({
        message: messageFn(`${nurturingAlias}の報告`, 'CANCEL_CONFIRM').message,
      })
      .pipe(
        filter((result) => result.isOK()),
        concatMap(() => this.serverApi.nurturingApi.cancelReport(id.value)),
        tap(() => {
          this.snackBarService.show(messageFn(`${nurturingAlias}の報告`, 'CANCEL_SUCCESS').message);
          this._unreportedActivityCountStoreService.sync();
          this._scheduleChangeEmitter.emit();
        }),
        catchError((err) => {
          return this._checkNotFoundError(err, `${nurturingAlias}の報告`, nurturingAlias, 'CANCEL_ERROR')
            || this._showError(err, `${nurturingAlias}の報告の取り消し`);
        })
      );
  }

  private _checkNotFoundError(
    err: Error,
    titleTarget: string,
    messageTarget: string,
    actionType: MessageActionType
  ): Observable<never> | void {
    if (err instanceof ServerApiError && err.code == ServerApiErrorCode.NotFound) {
      this.messageDialogService.notice({
        title: messageFn(titleTarget, actionType).message,
        message: `${messageTarget}は、すでに削除されています。`,
      });
      return throwError(() => err);
    }
  }

  private _showError(err: Error, errorTarget: string): Observable<never> {
    this.messageDialogService.notice({
      errorTarget
    });
    return throwError(() => err);
  }
}
