import { UserProfileService } from "#application/services/user-profile.service";
import { ClientApi } from "#infrastructure/api/client-api";
import { Injectable, inject } from "@angular/core";
import { USER_PROFILE_KEY_NAME, UserProfileSettings } from "app/model/storage/user-profile-setting";

@Injectable({providedIn: 'root'})
export class UserProfileServiceImpl implements UserProfileService {
  private readonly _clientApi: ClientApi = inject(ClientApi);

  saveUserProfile(userProfile: UserProfileSettings): void {
    this._clientApi.storeLocalStorage(USER_PROFILE_KEY_NAME, {
      calendarSettings: userProfile.calendarSettings,
    });
  }

  getUserProfile(): UserProfileSettings | null {
    return this._clientApi.lookupLocalStorage(USER_PROFILE_KEY_NAME);
  }

  deleteUserProfile(): void {
    this._clientApi.deleteLocalStorage(USER_PROFILE_KEY_NAME);
  }
}
