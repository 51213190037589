import {
  FollowUpActionTypeRepository,
  UpdateFollowUpActionTypeParam
} from 'app/model/follow-up-action/follow-up-action-type.repository';
import {Observable} from 'rxjs';
import {
  FollowUpActionType,
  FollowUpActionTypeDescription,
  FollowUpActionTypeID,
  FollowUpActionTypeName,
  FollowUpActionTypes
} from 'app/model/follow-up-action/follow-up-action-type';
import {ServerApi} from '#infrastructure/api/server-api';
import {Injectable} from '@angular/core';
import {ListFollowUpActionTypeData} from '#infrastructure/api/server-follow-up-action-type-api';
import {map} from 'rxjs/operators';

const convertFromResponse = (data: ListFollowUpActionTypeData): FollowUpActionType => new FollowUpActionType(
  new FollowUpActionTypeID(data.id),
  new FollowUpActionTypeName(data.name),
  new FollowUpActionTypeDescription(data.description),
);

@Injectable({providedIn: 'root'})
export class FollowUpActionTypeRepositoryImpl extends FollowUpActionTypeRepository {
  constructor(
    private readonly serverApi: ServerApi
  ) {
    super();
  }

  list(): Observable<FollowUpActionTypes> {
    return this.serverApi.followUpActionTypeApi.list().pipe(
      map(followUpActionTypes => followUpActionTypes.map(convertFromResponse)),
      map(followUpActionTypes => new FollowUpActionTypes(followUpActionTypes))
    );
  }

  saveAll(followUpActionTypes: FollowUpActionTypes): Observable<void> {
    const request = followUpActionTypes.list.map(followUpActionType => {
      return {
        id: followUpActionType.isNewItem() ? undefined : followUpActionType.id.value,
        name: followUpActionType.name.value,
        description: followUpActionType.description.value
      };
    });

    return this.serverApi.followUpActionTypeApi.saveAll(request);
  }

  save(param: UpdateFollowUpActionTypeParam): Observable<void> {
    return this.serverApi.followUpActionTypeApi.update(
      param.id.value,
      {
        name: param.name.value,
        description: param.description.value,
      }
    );
  }

  remove(id: FollowUpActionTypeID): Observable<void> {
    return this.serverApi.followUpActionTypeApi.delete(id.value);
  }

  saveOrders(ids: FollowUpActionTypeID[]): Observable<void> {
    const request = {ids: ids.map(id => id.value)};
    return this.serverApi.followUpActionTypeApi.sort(request);
  }
}
