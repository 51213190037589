/**
 * 一般的に起こり得る失敗を表す値。
 * エラーハンドリングの判定に使用する。
 */
export const GeneralFailure = {
  BadRequest: 'BadRequest',
  Unauthorized: 'Unauthorized',
  Forbidden: 'Forbidden',
  NotFound: 'NotFound',
  Conflict: 'Conflict',
  Unexpected: 'Unexpected',
} as const;