import { OpportunityID, OpportunityItem, OpportunityNumber } from 'app/model/opportunity/opportunity';
import { CreateOpportunityParam, CreateOpportunityResponse, CreateQuickOpportunityParam, OpportunityFactory } from 'app/model/opportunity/opportunity.factory';
import { ServerApi } from '#infrastructure/api/server-api';
import { CreateOpportunityResponseData } from '#infrastructure/api/server-opportunity-api';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OpportunityFactoryImpl implements OpportunityFactory {
  constructor(private readonly serverApi: ServerApi) {
  }

  create(param: CreateOpportunityParam): Observable<CreateOpportunityResponse> {
    let ecd;
    if (param.expectedCloseDate !== undefined) {
      ecd = DateTime.fromJSDate(param.expectedCloseDate.value).toFormat('yyyy-MM-dd');
    }
    return this.serverApi.opportunityApi.createOpportunity({
      clientID: param.clientID.value,
      opportunityName: param.opportunityName.value,
      opportunityTypeID: param.opportunityTypeID.value,
      phaseID: param.phaseID.value,
      assignee: param.assignee.value,
      items: param.items === undefined ? undefined : convertOpportunityItemsToKeyValueItems(param.items),
      expectedAmount: param.expectedAmount?.value,
      expectedCloseDate: ecd,
      opportunityAccrualDate:
        (param.opportunityAccrualDate === undefined) ? undefined :
          DateTime.fromJSDate(param.opportunityAccrualDate.value).toFormat('yyyy-MM-dd'),
      opportunitySource: param.opportunitySource,
    })
      .pipe(
        map((res) => {
          return {
            opportunityID: new OpportunityID(res.opportunityID),
            opportunityNumber: new OpportunityNumber(res.opportunityNumber),
          }
        })
      );
  }

  createQuick(param: CreateQuickOpportunityParam): Observable<CreateOpportunityResponseData> {
    return this.serverApi.opportunityApi.createQuick({
      clientID: param.clientID.value,
      opportunityName: param.opportunityName.value,
      opportunityTypeID: param.opportunityTypeID.value,
      phaseID: param.phaseID.value,
      assignee: param.assignee.value,
      opportunitySource: param.opportunitySource,
    });
  }
}

type keyValueItem = {
  [key: string]: string
};

export const convertOpportunityItemsToKeyValueItems = (items: OpportunityItem[]) => {
  const itemObj: keyValueItem = {};
  items.forEach(item => {
    itemObj[`${item.fieldId.value}`] = item.fieldValue.value;
  });
  return itemObj;
};
