import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ServerApi} from '#infrastructure/api/server-api';
import {ContactHistoryRepository} from 'app/model/contact-history/contact-history.repository';
import {ContactHistory, ContactHistoryID} from 'app/model/contact-history/contact-history';
import {ClientID} from 'app/model/client/client';

@Injectable({providedIn: 'root'})
export class ContactHistoryRepositoryImpl implements ContactHistoryRepository {
  constructor(
    private readonly serverApi: ServerApi
  ) {
  }

  save(contactHistory: ContactHistory): Observable<void> {
    return this.serverApi.contactHistoryApi.update(
      contactHistory.clientID.value,
      contactHistory.id.value,
      {content: contactHistory.content.value, opportunityID: contactHistory.opportunityID?.value}
    );
  }

  delete(clientID: ClientID, historyID: ContactHistoryID): Observable<void> {
    return this.serverApi.contactHistoryApi.delete(clientID.value, historyID.value);
  }
}
