import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { GoalCategoryForReport, GoalPeriodType, GoalReportTypeV2 } from "app/model/report/report-goal";
import { DisplayName, UserID } from "app/model/user/user";
import { DateTime } from "luxon";
import { Observable } from "rxjs";

/* ユーザーグループ別目標レポートのInput */
export type GetReportGoalV2Input = {
  duration: {
    goalPeriodType: GoalPeriodType,
    startYearMonth: DateTime,
    months: number,
  },
  userIDs?: UserID[],
  containsEstimation: boolean,
};
/* ユーザー別目標レポートのInput */
export type GetPerUserReportGoalV2Input = {
  duration: {
    goalPeriodType: GoalPeriodType,
    startYearMonth: DateTime,
    months: number,
  },
  userID: UserID,
  containsEstimation: boolean,
};
/* 目標マイデータのInput */
export type GetPersonalReportGoalV2Input = {
  duration: {
    goalPeriodType: GoalPeriodType,
    startYearMonth: DateTime,
    months: number,
  },
  containsEstimation: boolean,
};

/* レポートデータ */
export type GoalPerMonthContainsEstimationV2 = Readonly<{
  yearMonth: DateTime,
  goal: number,
  achievementAndEstimation: number,
  achievement: number,
  estimation: number,
  difference: number,
  achievementRate: number | undefined,
}>;
export type GoalPerMonthWithoutEstimationV2 =
  Omit<GoalPerMonthContainsEstimationV2, 'achievementAndEstimation' | 'estimation'>;
export type GoalsContainEstimationV2 = Readonly<{
  unit: GoalPerMonthContainsEstimationV2[],
  total: GoalPerMonthContainsEstimationV2[],
}>;
export type GoalsWithoutEstimationV2 = Readonly<{
  unit: GoalPerMonthWithoutEstimationV2[],
  total: GoalPerMonthWithoutEstimationV2[],
}>;
export type GoalReportUserDataV2 = Readonly<{
  id: UserID,
  name: DisplayName,
  isActive: boolean,
}>;
export type GoalReportContainsEstimationV2 = Readonly<{
  type: typeof GoalReportTypeV2.CONTAINS_ESTIMATION,
  goals: GoalsContainEstimationV2,
  goalsPerUser: (
    { user: GoalReportUserDataV2 }
    & GoalsContainEstimationV2
  )[],
}>;
export type GoalReportWithoutEstimationV2 = Readonly<{
  type: typeof GoalReportTypeV2.WITHOUT_ESTIMATION,
  goals: GoalsWithoutEstimationV2,
  goalsPerUser: (
    { user: GoalReportUserDataV2 }
    & GoalsWithoutEstimationV2
  )[],
}>;
export type GoalReportPerUserContainsEstimationV2 = Readonly<{
  type: typeof GoalReportTypeV2.CONTAINS_ESTIMATION,
  user: GoalReportUserDataV2,
  goals: GoalsContainEstimationV2,
}>;
export type GoalReportPerUserWithoutEstimationV2 = Readonly<{
  type: typeof GoalReportTypeV2.WITHOUT_ESTIMATION,
  user: GoalReportUserDataV2,
  goals: GoalsWithoutEstimationV2,
}>;

/* ユーザーグループ別目標レポート */
export type GoalReportV2 =
  | GoalReportContainsEstimationV2
  | GoalReportWithoutEstimationV2;
/** ユーザー別目標レポート */
export type GoalReportPerUserV2 =
  | GoalReportPerUserContainsEstimationV2
  | GoalReportPerUserWithoutEstimationV2;

export abstract class ReportGoalQueryV2Service {
  abstract get(category: GoalCategoryForReport, input: GetReportGoalV2Input): Observable<Result<
    GoalReportV2,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >>;
  abstract getPerUser(category: GoalCategoryForReport, input: GetPerUserReportGoalV2Input): Observable<Result<
    GoalReportPerUserV2,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >>;
  abstract getPersonal(category: GoalCategoryForReport, input: GetPersonalReportGoalV2Input): Observable<Result<
    GoalReportPerUserV2,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >>;
}
