import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TopBarComponent } from "./top-bar.component";
import {MatMenuModule} from "@angular/material/menu";

@NgModule({
  declarations: [
    TopBarComponent,
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    OverlayModule,
  ],
  exports: [
    TopBarComponent,
  ]
})
export class TopBarModule { }
