import {OpportunityTypeList, OpportunityTypeQueryService,} from '#application/services/opportunity-type-query.service';
import {OpportunityTypeDescription, OpportunityTypeName, OpportunityType, OpportunityTypeID,} from 'app/model/opportunity-type/opportunity-type';
import {SalesPhaseID, SalesPhaseName} from 'app/model/sales-phase/sales-phase';
import {ServerApi} from '#infrastructure/api/server-api';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FormLayoutId, FormLayoutName} from "app/model/form-layout/form-layout";

@Injectable({
  providedIn: 'root',
})
export class OpportunityTypeQueryServiceImpl extends OpportunityTypeQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  listWithLayoutName(): Observable<OpportunityTypeList> {
    return this.serverApi.opportunityTypeApi.getOpportunityTypeList().pipe(
      map((response) => {
        return response.map((v) => {
          return {
            id: new OpportunityTypeID(v.id),
            name: new OpportunityTypeName(v.name),
            description: new OpportunityTypeDescription(v.description),
            salesPhaseID: new SalesPhaseID(v.salesPhaseID),
            salesPhaseName: new SalesPhaseName(v.salesPhaseName),
            formLayoutId: v.formLayoutID == undefined
              ? undefined
              : new FormLayoutId(v.formLayoutID),
            formLayoutName: v.formLayoutName == undefined
              ? undefined
              : new FormLayoutName(v.formLayoutName),
          };
        });
      })
    );
  }

  get(id: OpportunityTypeID): Observable<OpportunityType> {
    return this.serverApi.opportunityTypeApi.get(id.value)
      .pipe(
        map(response => new OpportunityType(
          new OpportunityTypeID(response.id),
          new OpportunityTypeName(response.name),
          new OpportunityTypeDescription(response.description),
          new SalesPhaseID(response.salesPhaseID),
          response.formLayoutID === undefined ? undefined : new FormLayoutId(response.formLayoutID)
        ))
      );
  }

  list(): Observable<OpportunityType[]> {
    return this.serverApi.opportunityTypeApi.list()
      .pipe(
        map(response => response.map(item => new OpportunityType(
          new OpportunityTypeID(item.id),
          new OpportunityTypeName(item.name),
          new OpportunityTypeDescription(item.description),
          new SalesPhaseID(item.salesPhaseID),
          item.formLayoutID == undefined ? undefined : new FormLayoutId(item.formLayoutID)
        )))
      );
  }
}
