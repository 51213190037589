import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ServerApi} from "#infrastructure/api/server-api";
import { ContactHistoryTemplateName, ContactHistoryTemplateContent } from "app/model/contact-history-template/contact-history-template";
import { ContactHistoryTemplateFactory } from "app/model/contact-history-template/contact-history-template.factory";


@Injectable({providedIn: "root"})
export class ContactHistoryTemplateFactoryImpl extends ContactHistoryTemplateFactory {
  constructor(
    private readonly serverApi: ServerApi
  ) {
    super();
  }

  create(name: ContactHistoryTemplateName, content: ContactHistoryTemplateContent): Observable<void> {
    return this.serverApi.contactHistoryTemplateApi.create({
      name: name.value,
      content: content.value
    });
  }
}
