import {BehaviorSubject, Observable} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({ providedIn: "root" })
export class ScheduleChangeEmitterService {
  private readonly _change = new BehaviorSubject<null>(null);
  emit(): void {
    this._change.next(null)
  }

  get change$(): Observable<null> {
    return this._change.asObservable();
  }
}
