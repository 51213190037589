<div class="assigned-org-container">
  <mat-error
    class="no-selected"
    *ngIf="orgOptionList.isNoSelected()"
  >
    以下の組織リストから1つ以上の組織を選択してください。
  </mat-error>

  <div class="search-wrapper">
    <sn-generic-string-search-form
      [formControl]="searchOrgNameControl"
      [placeholder]="'組織名で絞り込む'"
      (clear)="clearValues()"
      name="generic-string-search-form"
      ngDefaultControl
    ></sn-generic-string-search-form>
    <mat-checkbox
      color="primary"
      [(ngModel)]="isOnlySelected"
      (ngModelChange)="changeSearchState()"
      class="only-selected"
    >
      選択済みのみ表示
    </mat-checkbox>
  </div>

  <div class="table-wrapper">
    <table mat-table [dataSource]="orgOptionList.list">
      <ng-container matColumnDef="selected">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            class="selected"
            color="primary"
            (change)="onChangeSelectedOrg(element.id, element.selected)"
            [(ngModel)]="element.selected"
          ></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>
          <span class="code">組織コード</span>
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="code">{{ element.code.value }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>組織名</th>
        <td mat-cell *matCellDef="let element">
          <span class="name">{{ element.name.value }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
