import {ClientCategory, ClientTypeID, ClientTypeName} from "app/model/client-info-type/client-type";
import {Address, Client, ClientID, ClientName, ClientNumber, CorporateNumber, CorporationName, CorporationNameKana, Established, FirstName, FirstNameKana, FiscalYearEnd, InitialCapital, LastName, LastNameKana, NumberOfEmployees, PhoneNumber, SNS, Website} from "app/model/client/client";
import { FilterPredicate } from "app/model/search-filter/search-filter";
import {Observable} from "rxjs";
import {FilterInput} from "#infrastructure/application/filter.service";
import { Result } from "app/lib/result/result";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import {FieldType} from "../../model/custom-field/field-type";
import {CustomFieldId, CustomFieldName} from "../../model/custom-field/custom-field";
import {DisplayName, UserID} from "../../model/user/user";

type ClientListItemBase = {
  id: ClientID,
  clientNumber: ClientNumber,
  name: ClientName,
  clientType: {
    id: ClientTypeID,
    name: ClientTypeName,
  },
  category: ClientCategory,
}

export type CorporationItem = ClientListItemBase & {
  corporationName: CorporationName,
  corporationNameKana: CorporationNameKana,
  corporateNumber: CorporateNumber | undefined,
  address: Address | undefined,
  phoneNumber: PhoneNumber | undefined,
  established: Established | undefined,
  numberOfEmployees: NumberOfEmployees | undefined,
  initialCapital: InitialCapital | undefined,
  fiscalYearEnd: FiscalYearEnd | undefined,
  website: Website | undefined,
  items: CustomFieldItem[] | undefined,
}

export type IndividualItem = ClientListItemBase & {
  firstName: FirstName,
  lastName: LastName,
  firstNameKana: FirstNameKana,
  lastNameKana: LastNameKana,
  address: Address | undefined,
  phoneNumber: PhoneNumber | undefined,
  sns1: SNS | undefined,
  sns2: SNS | undefined,
  items: CustomFieldItem[] | undefined,
}

export type CustomFieldItem = {
  type:
    | typeof FieldType.SINGLE_LINE_TEXT
    | typeof FieldType.MULTI_LINE_TEXT
    | typeof FieldType.URL
    | typeof FieldType.MAIL_ADDRESS
    | typeof FieldType.ADDRESS
    | typeof FieldType.PHONE_NUMBER
  id: CustomFieldId,
  name: CustomFieldName,
  value?: string
} | {
  type: typeof FieldType.NUMBER,
  id: CustomFieldId,
  name: CustomFieldName,
  value?: number
} | {
  type: | typeof FieldType.DATE | typeof FieldType.DATETIME,
  id: CustomFieldId,
  name: CustomFieldName,
  value?: Date
} | {
  type: typeof FieldType.SELECT
  id: CustomFieldId,
  name: CustomFieldName,
  values: string[]
} | {
  type: typeof FieldType.USER,
  id: CustomFieldId,
  name: CustomFieldName,
  userID?: UserID,
  userName?: DisplayName
} | {
  type: typeof FieldType.ATTACHMENT,
  id: CustomFieldId,
  name: CustomFieldName,
  fileStatus: 'EXIST' | 'NOT_EXIST'
} | {
  type: typeof FieldType.CLIENT,
  id: CustomFieldId,
  name: CustomFieldName,
  clientID?: ClientID,
  clientName?: ClientName
} | {
  type: typeof FieldType.CHECKBOX,
  id: CustomFieldId,
  name: CustomFieldName,
  value: boolean
}

export type ClientListItem = CorporationItem | IndividualItem;

export type ClientList = ClientListItem[];

export interface PaginatedClientList {
  totalCount: number,
  list: ClientList
}

export type GetClientListInput = {
  perPage: number;
  page: number;
  filter: {
    name?: FilterPredicate,
    clientNumber?: FilterPredicate,
    clientType?: FilterPredicate,
    corporateNumber?: FilterPredicate,
    address?: FilterPredicate,
    phoneNumber?: FilterPredicate,
    established?: FilterPredicate,
    numberOfEmployees?: FilterPredicate,
    initialCapital?: FilterPredicate,
    fiscalYearEnd?: FilterPredicate,
    searchString?: FilterPredicate,
    [propertyName: `item:${string}`]: FilterPredicate | undefined,
  };
}

export type ClientFilterInput = {
  perPage: number,
  page: number,
  filterInput: FilterInput,
}

export type SearchSelectableClientListInput = Readonly<{
  pagination: {
    perPage: number,
    page: number,
  },
  name: string,
}>

export type ClientListItemForSelectableSearch = Readonly<{
  id: ClientID,
  number: ClientNumber,
  name: ClientName,
  category: ClientCategory,
  address: Address | undefined,
}>
export type ClientListForSelectableSearch = Readonly<{
  totalCount: number,
  list: ReadonlyArray<ClientListItemForSelectableSearch>,
}>

export abstract class ClientQueryService {
  /**
   * @deprecated 旧い実装のため#filterを使うこと
   */
  abstract findBy(input: GetClientListInput): Observable<PaginatedClientList>;
  abstract filter(input: ClientFilterInput): Observable<PaginatedClientList>;
  abstract filterList(input: ClientFilterInput): Observable<PaginatedClientList>;
  abstract searchSelectable(input: SearchSelectableClientListInput): Observable<Result<
    ClientListForSelectableSearch,
    typeof GeneralFailure.Unexpected
  >>;
  abstract get(id: ClientID): Observable<Client | undefined>;
}
