import { FilterInput } from "#infrastructure/application/filter.service";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { MeetingBoardSetting, MeetingBoardSettingDescription, MeetingBoardSettingID, MeetingBoardSettingName, MeetingBoardUser } from "app/model/meeting-board/meeting-board";
import { MeetingBoardSettingFilterCondition } from "app/model/meeting-board/meeting-board-setting-filter-conditon";
import { SearchFilterPermission } from "app/model/search-filter/search-filter";
import { Observable } from "rxjs";

export type FilterMeetingBoardSettingInput = {
  perPage: number,
  page: number,
  filterInput: FilterInput,
};

export type FilterMeetingBoardSettingOutput = {
  totalCount: number,
  results: MeetingBoardSetting[],
};

export type GetMeetingBoardSettingOutput = {
  meetingBoardSettingID: MeetingBoardSettingID,
  name: MeetingBoardSettingName,
  description: MeetingBoardSettingDescription,
  visibility: SearchFilterPermission,
  editability: SearchFilterPermission,
  headerCondition: {
    isDisplayed: {
      wonAmount: boolean,
      wonCount: boolean,
      lostAmount: boolean,
      lostCount: boolean,
      opportunityWonCount: boolean,
      actualAmount: boolean,
      salesRecordingAmount: boolean,
    },
    displayUsers: MeetingBoardUser[],
  },
  filterCondition: MeetingBoardSettingFilterCondition,
};

export abstract class MeetingBoardSettingQueryService {
  abstract filter(input: FilterMeetingBoardSettingInput): Observable<Result<
    FilterMeetingBoardSettingOutput,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >>;

  abstract get(id: MeetingBoardSettingID): Observable<Result<
    GetMeetingBoardSettingOutput,
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.Unexpected
  >>;
}
