import {Observable} from "rxjs";
import {DateTime} from "luxon";

export type SearchWebhookEventHistoryInput = Readonly<{
  perPage: number,
  page: number,
  filter: {
    webhookID: {
      type: 'equals',
      value: string,
    }
  }
}>

export type WebhookEventHistoryItem = Readonly<{
  success: boolean,
  eventID: string,
  timestamp: DateTime,
  request?: {
    headers: string,
    payload: string
  },
  response?: {
    statusCode: number,
    headers: string,
    body: string
  },
  error?: {
    message: string
  },
}>

export type WebhookEventHistory = Readonly<{
  totalCount: number,
  results: WebhookEventHistoryItem[]
}>

export abstract class WebhookEventHistoryQueryService {
  abstract search(input: SearchWebhookEventHistoryInput): Observable<WebhookEventHistory>;
}
