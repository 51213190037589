import { AssignedOrganizationType } from "app/model/consultant/consultant";
import { OrganizationID, OrganizationCode, OrganizationName } from "app/model/organization/organization";

export const assignedOrgTypeOptions = [
  {
    value: AssignedOrganizationType.ALL,
    name: '全組織'
  },
  {
    value: AssignedOrganizationType.SELECTED,
    name: '個別'
  },
] as const;

export type OrgOption = {
  selected: boolean,
  id: OrganizationID,
  code: OrganizationCode,
  name: OrganizationName,
};

export class OrgOptionList {
  constructor(
    public readonly list: OrgOption[],
  ) {}

  isNoSelected(): boolean {
    return this.list.filter(item => item.selected === true).length === 0;
  }

  clone(): OrgOptionList {
    return new OrgOptionList(this.list);
  }

  searchOrgName(str: string): OrgOptionList {
    return new OrgOptionList(
      this.list.filter(item => item.name.value.includes(str))
    );
  }

  searchOnlySelected(bool: boolean): OrgOptionList {
    return (bool
      ? new OrgOptionList(
        this.list.filter(item => item.selected == true)
      )
      : new OrgOptionList(
        this.list
      )
    );
  }

  reflectSelected(idList: OrganizationID[]): OrgOptionList {
    return new OrgOptionList(
      this.list.map(item => {
        if (idList.find(id => id.equals(item.id))) {
          return {
            selected: true,
            id: item.id,
            code: item.code,
            name: item.name,
          }
        } else {
          return {
            selected: false,
            id: item.id,
            code: item.code,
            name: item.name,
          }
        }
      })
    );
  }
}
