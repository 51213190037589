import { ConsultantList } from "#application/services/consultant-query.service";
import { ConsultantID, ConsultantLoginID, DisplayName, AssignedOrganizationType, MailAddress } from "app/model/consultant/consultant";
import { OrganizationName } from "app/model/organization/organization";
import { checkNever } from "#utils/assert";
import { DateTime } from "luxon";

export type ConsultantTableList = {
  id: ConsultantID,
  loginID: ConsultantLoginID,
  displayName: DisplayName,
  mailAddress: MailAddress,
  assignedOrganization: string,
  lastLoginDateTime: string,
}[];

const convertToOrgNameByType = (
  type: AssignedOrganizationType,
  names?: OrganizationName[]
): string => {
  switch (type) {
    case AssignedOrganizationType.ALL:
      return '全組織';
    case AssignedOrganizationType.SELECTED:
      return names!.map(name => name.value).join(', ');
    default:
      return checkNever(type, '');
  }
}

export const convertToTableData = (list: ConsultantList): ConsultantTableList => {
  return list.map(item => ({
    id: item.id,
    loginID: item.loginID,
    displayName: item.displayName,
    mailAddress: item.mailAddress,
    assignedOrganization: convertToOrgNameByType(
      item.assignedOrganizationType,
      item.selectedOrganizationNames
    ),
    lastLoginDateTime: item.lastLoginDateTime === undefined
    ? '一度もログインしていません。'
    : item.lastLoginDateTime
      .setZone('Asia/Tokyo')
      .setLocale('ja-JP')
      .toLocaleString(DateTime.DATETIME_SHORT),
  }));
}
