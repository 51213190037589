import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { SearchInsightListRequest, SearchInsightListResponse, GetInsightResponse, CreateInsightRequest, UpdateInsightRequest } from "./server-insight-api";

export class CommonInsightApi {
  constructor(private readonly http: HttpClient) {}

  create(request: CreateInsightRequest): Observable<void> {
    return this.http.post<void>(`/api/common-insights`, request);
  }

  update(id: string, request: UpdateInsightRequest): Observable<void> {
    return this.http.put<void>(`/api/common-insights/${id}`, request);
  }

  search(request: SearchInsightListRequest): Observable<SearchInsightListResponse> {
    return this.http.post<SearchInsightListResponse>(`/api/common-insight-list/search`, request);
  }

  get(id: string): Observable<GetInsightResponse> {
    return this.http.get<GetInsightResponse>(`/api/common-insights/${id}`,);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/common-insights/${id}`);
  }
}
