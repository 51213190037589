import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CreateHintRequest, GetHintResponse, SearchHintResponse, UpdateHintRequest } from "./server-hint-api";

export type SearchCommonHintRequest = {
  perPage: number,
  page: number,
  filter: {
    searchString: {
      type: 'contains',
      value: string
    }
  }
}

export class CommonHintApi {
  constructor(private readonly http: HttpClient) {}

  create(request: CreateHintRequest): Observable<void> {
    return this.http.post<void>(`/api/common-hints`, request);
  }

  search(request: SearchCommonHintRequest): Observable<SearchHintResponse> {
    return this.http.post<SearchHintResponse>(`/api/common-hint-list/search`, request);
  }

  get(id: string): Observable<GetHintResponse> {
    return this.http.get<GetHintResponse>(`/api/common-hints/${id}`);
  }

  update(id: string, request: UpdateHintRequest): Observable<void> {
    return this.http.put<void>(`/api/common-hints/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/common-hints/${id}`);
  }
}
