import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import { ApiUserAccessLevel } from './user-api';

export type ApiUserSecurityRole = ApiUserAccessLevel

export interface SignInRequest {
  userID: string,
  password: string,
}

export interface UserProfile {
  calendarSettings: {
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean,
    saturday: boolean,
    sunday: boolean,
    startTime: number,
    closeTime: number,
  },
}

export interface SignInResponse {
  userID: string,
  organizationName: string,
  // FIXME バックエンドと同時にloginIDに変更する
  userName: string,
  displayName: string,
  mailAddress: string,
  fiscalYearBegin: number,
  securityRoles: ApiUserSecurityRole[],
  isCoachingAvailable: boolean,
  isProposalEnabled: boolean,
  isLinkageEnabled: boolean,
  isOpportunityGroupEnabled: boolean,
  localize: Record<ApiLocalizeKey, string>,
  userProfile: UserProfile,
}

export const ApiLocalizeKey = {
  NURTURING: 'NURTURING',
  OPPORTUNITY_GROUP: 'OPPORTUNITY_GROUP',
} as const;
export type ApiLocalizeKey = typeof ApiLocalizeKey[keyof typeof ApiLocalizeKey];

export class AuthApi {
  constructor(private readonly http: HttpClient) {
  }

  signIn(request: SignInRequest): Observable<SignInResponse> {
    return this.http.post<SignInResponse>('/api/sign-in', request);
  }

  signOut(): Observable<void> {
    return this.http.post<void>('/api/sign-out', null);
  }
}
