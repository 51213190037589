import { CoachingTiming } from "app/model/coaching/coaching";
import { HintID, HintTitle } from "app/model/hint/hint";
import { OpportunityContextID, OpportunityContextName } from "app/model/opportunity-context/opportunity-context";

export class InsightID {
  constructor(public readonly value: string) { }
}

export class InsightQuestion {
  static readonly MAX_LENGTH = 200;

  constructor(public readonly value: string) { }
}

export class InsightSupplementTitle {
  static readonly MAX_LENGTH = 50;

  constructor(public readonly value: string) { }

  isEmpty(): boolean {
    return this.value.length === 0;
  }
}

export class InsightSupplement {
  static readonly MAX_LENGTH = 300;

  constructor(public readonly value: string) { }

  isEmpty(): boolean {
    return this.value.length === 0;
  }
}

export class InsightAnswerID {
  constructor(public readonly value: string) { }

  equals(id: InsightAnswerID): boolean {
    return this.value === id.value;
  }
}

export class InsightAnswer {
  static readonly MAX_LENGTH = 30;

  constructor(public readonly value: string) { }

  equals(answer: InsightAnswer): boolean {
    return this.value === answer.value;
  }
}

export type InsightAnswerOption = Readonly<{
  id: InsightAnswerID,
  answer: InsightAnswer,
  occurringOpportunityContexts: ReadonlyArray<Readonly<{
    id: OpportunityContextID,
    name: OpportunityContextName,
  }>>,
  hint: Readonly<{
    id: HintID,
    title: HintTitle,
  }> | undefined,
}>

export const InsightAnswerSelectionType = {
  label: '選択',
  value: 'SELECTION',
} as const;

export const InsightAnswerTextType = {
  label: '自由記述',
  value: 'TEXT',
} as const;

export type InsightAnswerType =
  | typeof InsightAnswerSelectionType.value
  | typeof InsightAnswerTextType.value

export type InsightBase = Readonly<{
  id: InsightID,
  coachingTiming: CoachingTiming,
  question: InsightQuestion,
  supplementTitle: InsightSupplementTitle,
  supplement: InsightSupplement,
  triggeringOpportunityContexts: ReadonlyArray<Readonly<{
    id: OpportunityContextID,
    name: OpportunityContextName,
  }>>,
}>
export type SelectionAnswerType = Readonly<{
  answerType: typeof InsightAnswerSelectionType.value,
  selection: Readonly<{
    multiple: boolean,
    answerOptions: ReadonlyArray<InsightAnswerOption>
  }>,
}>
export type TextAnswerType = Readonly<{
  answerType: typeof InsightAnswerTextType.value,
}>

export type Insight =
  | (InsightBase & SelectionAnswerType)
  | (InsightBase & TextAnswerType)
