import {Observable} from "rxjs";
import {PayloadUrl, Webhook, WebhookID, WebhookName} from "app/model/webhook/webhook";

export const ConnectionStatus = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  UNUSED: 'UNUSED',
} as const;
export type ConnectionStatus = typeof ConnectionStatus[keyof typeof ConnectionStatus];

export type WebhookList = Readonly<{
  id: WebhookID,
  connectionStatus: ConnectionStatus,
  name: WebhookName,
  uri: PayloadUrl,
}[]>

export abstract class WebhookQueryService {
  abstract list(): Observable<WebhookList>;
  abstract get(id: WebhookID): Observable<Webhook>;
}
