import { ApiFilterRequest } from "#infrastructure/api/server-filter-api";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export type ProductCsvExportFilterRequest = ApiFilterRequest & {
  outputColumns: {
    name: string,
    label: string,
  }[],
  charset: string
}

export type FilterProductCsvExportResponse = Blob;

export class ProductCsvExportApi {
  constructor(private readonly http: HttpClient) {}

  csvExport(request: ProductCsvExportFilterRequest): Observable<FilterProductCsvExportResponse> {
    return this.http.post(
      `/api/products/filter/csv-export`,
      request,
      { responseType: 'blob' }
    );
  }
}
