import { RoleID, RoleName } from "app/model/role/role";
import { Observable } from "rxjs";

export type CreateRoleInputs = Readonly<{
  name : RoleName,
  parentRoleID? : RoleID,
}>;

export type UpdateRoleInputs = Readonly<{
  id: RoleID,
  name: RoleName,
  parentRoleID?: RoleID,
}>;

/**
 * リクエストエラー
 * 以下の可能性がある
 *   - パラメータのバリデーションエラーがある場合
 *   - 最大ロール数を超えた場合
 *   - 最大階層数を超えた場合
 *   - 循環参照の場合
 */
export class RoleBadRequestError extends Error {}

/**
 * 組織内のロール名重複エラー
 */
export class RoleConflictError extends Error {}

export abstract class RoleCommandService {
  abstract add(inputs: CreateRoleInputs): Observable<void | RoleBadRequestError | RoleConflictError>;
  abstract update(inputs : UpdateRoleInputs): Observable<void | RoleBadRequestError | RoleConflictError>;
  abstract delete(id : RoleID): Observable<void | RoleBadRequestError | RoleConflictError>;
}
