export const environment = {
  production: true,
  urlHost: 'app-dev2.salesnavi.co.jp',
  msw: false,

  enableRoleConfig: true,
  enableOpportunityCsvImport: true,
  enableMeetingBoard: true,
  enableMTGBoardOpportunityEdit: true,
  replaceCoachingClientName: true,
};
