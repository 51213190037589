import { ClientID, ClientName } from 'app/model/client/client';
import { NurturingTypeID, NurturingTypeName } from 'app/model/nurturing-type/nurturing-type';
import {
  NurturingEndDateTime,
  NurturingID,
  NurturingMinutesDuration,
  NurturingStartDateTime,
  NurturingTitle
} from 'app/model/nurturing/nurturing';
import { UserGroupID } from "app/model/user-group/user-group";
import { DisplayName, UserID } from "app/model/user/user";
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Result } from 'app/lib/result/result';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

/** ナーチャリングレポート取得InputDTO */
export type GetNurturingReportInput = Readonly<{
  duration: {
    type: 'DAILY',
    startDate: DateTime,
    days: number,
  } | {
    type: 'MONTHLY',
    startYearMonth: DateTime,
    months: number,
  },
  assignee: {
    type: 'USER_GROUP',
    userGroupID: UserGroupID,
  } | {
    type: 'ALL_USERS',
  },
  nurturingTypeIDs: NurturingTypeID[],
  nurturingStates: ReadonlyArray<'BEFORE' | 'AFTER'>,
}>

/** ユーザー別ナーチャリングレポート取得InputDTO */
export type GetNurturingReportPerUserInput =
  Omit<GetNurturingReportInput, 'assignee'>
  & {
    userID: UserID,
    pagination: {
      perPage: number,
      page: number,
    },
  }

/** マイデータ・ナーチャリング取得InputDTO */
export type GetPersonalNurturingReportInput =
  Omit<GetNurturingReportInput, 'assignee'>
  & {
    pagination: {
      perPage: number,
      page: number,
    },
  }

export type NurturingReport = Readonly<{
  perDate: NurturingReportPerDate[],
  perType: NurturingReportPerNurturingType,
  total: number,
}>

export type PerUserNurturingReport = Readonly<{
  user: {
    id: UserID,
    name: DisplayName,
    isActive: boolean,
  },
  perDate: NurturingReportPerDate[],
  perType: NurturingReportPerNurturingType,
  total: number,
}>

type NurturingReportPerDate = Readonly<{
  date: DateTime,
  values: {
    [nurturingTypeID: string]: number
  },
  total: number,
}>

type NurturingReportPerNurturingType = Readonly<{
  [nurturingTypeID: string]: number
}>

export type NurturingListOfUser = Readonly<{
  totalCount: number,
  results: ReadonlyArray<{
    nurturingID: NurturingID,
    nurturingTitle: NurturingTitle,
    clientID: ClientID,
    clientName: ClientName,
    nurturingTypeID: NurturingTypeID,
    nurturingTypeName: NurturingTypeName,
    nurturingStartDateTime: NurturingStartDateTime,
    nurturingEndDateTime: NurturingEndDateTime,
    nurturingTime: NurturingMinutesDuration,
    nurturingState: 'BEFORE' | 'AFTER',
  }>,
}>

/** ユーザーグループ別ナーチャリングレポート */
export type NurturingReportPerUserGroup = Readonly<{
  times: NurturingReport,
  timesPerUser: PerUserNurturingReport[],
  counts: NurturingReport,
  countsPerUser: PerUserNurturingReport[],
}>

/** ユーザー別ナーチャリングレポート */
export type NurturingReportPerUser = Readonly<{
  times: PerUserNurturingReport,
  counts: PerUserNurturingReport,
  nurturings: NurturingListOfUser,
}>

/** マイデータ・ナーチャリング */
export type PersonalNurturingReport = Readonly<{
  times: NurturingReport,
  counts: NurturingReport,
  nurturings: NurturingListOfUser,
}>


export abstract class ReportNurturingQueryService {
  abstract get(input: GetNurturingReportInput): Observable<Result<
    NurturingReportPerUserGroup,
    typeof GeneralFailure.Unexpected
  >>;
  abstract getPerUser(input: GetNurturingReportPerUserInput): Observable<Result<
    NurturingReportPerUser,
    typeof GeneralFailure.Unexpected
  >>;
  abstract getPersonal(input: GetPersonalNurturingReportInput): Observable<Result<
    PersonalNurturingReport,
    typeof GeneralFailure.Unexpected
  >>;
}
