import {inject, Injectable} from '@angular/core';
import {UserGroupID, UserGroupName} from "../../model/user-group/user-group";
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {UserID} from "../../model/user/user";
import {UserGroupForSelectQueryService, UsersByOutputInUserGroupForSelect} from "#application/services/user-group-for-select-query.service";
import {AllUserGroupsForSelect} from "../../model/user-group-for-select/all-user-groups-for-select";
import {ServerApi} from "#infrastructure/api/server-api";
import {Failure, Result, Success} from "../../lib/result/result";
import {GeneralFailure} from "../../lib/general-failure/general-failure";
import { UserList } from '#application/services/user-query.service';

@Injectable({
  providedIn: 'root'
})
export class UserGroupForSelectQueryServiceImpl extends UserGroupForSelectQueryService {
  private readonly serverApi = inject(ServerApi);

  all(): Observable<Result<
    AllUserGroupsForSelect,
    | typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.userGroupForSelectApi.all()
      .pipe(
        map(r => {
          const ug: AllUserGroupsForSelect = r
            .map(v => ({
              userGroupID: new UserGroupID(v.userGroupID),
              userGroupName: new UserGroupName(v.userGroupName),
              userIDs: v.userIDs.map(id => new UserID(id)),
            }));
          return Success(ug);
        }),
        catchError(() => of(Failure(GeneralFailure.Unexpected))),
      );
  }

  usersBy(groupIDs: ReadonlyArray<UserGroupID>, userList: UserList): Observable<Result<
    UsersByOutputInUserGroupForSelect,
    | typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.userGroupForSelectApi.all()
      .pipe(
        map(r => {
          const userIDStrings = r
            .filter(v => groupIDs.some(id => id.value === v.userGroupID))
            .flatMap(v => v.userIDs);
          const userIDStringsDistinct = Array.from(new Set(userIDStrings));
          const users = userList
            .filter(u => userIDStringsDistinct.includes(u.id.value))
            .map(u => ({
              id: u.id,
              loginID: u.loginID,
              name: u.name,
            }));
          return Success(users);
        }),
        catchError(() => of(Failure(GeneralFailure.Unexpected))),
      );
  }
}
