import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { buildHttpParams } from "./http-params-builder";

export type UploadFileRequest = File;

export type UploadByParentFileRequest = {
  file: File,
  parentID: string,
  category: string,
};

export type UploadFileResponse = string;

export type DownloadFileResponse = Blob;

export type GetFileProfileResponse = {
  fileID: string,
  fileName: string,
  fileSize: number,
};

export type ListFileRequest = {
  parentID: string,
  category: string,
  perPage: number,
  page: number,
}

export type ListFileResponse = {
  totalCount: number,
  results: {
    fileID: string,
    fileName: string,
    updatedAt: string,
    userDisplayName: string,
  }[]
}

export class FileApi {
  constructor(private readonly http: HttpClient) {}

  upload(request: UploadFileRequest): Observable<UploadFileResponse> {
    // multipart/form-dataで送るために FormData へ変換する
    const formData = new FormData();
    formData.append('file', request);
    return this.http.post(
      `/api/files`,
      formData,
      {
        responseType: 'text',
      }
    );
  }

  uploadByParent(request: UploadByParentFileRequest): Observable<UploadFileResponse> {
    const formData = new FormData();
    formData.append('file', request.file);
    formData.append('parentID', request.parentID);
    formData.append('category', request.category);
    return this.http.post(
      `/api/files`,
      formData,
      {
        responseType: 'text',
      }
    );
  }

  download(id: string): Observable<DownloadFileResponse> {
    return this.http.get(
      `/api/files/${id}`,
      {
        observe: 'response',
        responseType: 'arraybuffer',
      }
    )
    .pipe(
      map(res => new Blob([res.body!], { type: res.type.toString() }))
    )
  }

  getProfile(id: string): Observable<GetFileProfileResponse> {
    return this.http.get<GetFileProfileResponse>(`/api/file-profiles/${id}`);
  }

  list(request: ListFileRequest): Observable<ListFileResponse> {
    const params = buildHttpParams({
      parentID: request.parentID,
      category: request.category,
      perPage: request.perPage,
      page: request.page,
    });
    return this.http.get<ListFileResponse>(`/api/files`, { params });
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/files/${id}`);
  }
}
