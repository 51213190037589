import {Observable} from "rxjs";
import {LostReasonDescription, LostReasonName} from "app/model/opportunity/lost-reason/lost-reason";

export interface CreateLostReasonParam {
  name: LostReasonName,
  description: LostReasonDescription,
}

export abstract class LostReasonFactory {
  abstract create(param: CreateLostReasonParam): Observable<void>;
}
