import {Injectable} from "@angular/core";
import {ClientTypeFactory} from "app/model/client-info-type/client-type.factory";
import {
  ClientCategory,
  ClientTypeDescription,
  ClientTypeName
} from "app/model/client-info-type/client-type";
import {Observable} from "rxjs";
import {ServerApi} from "#infrastructure/api/server-api";
import {FormLayoutId} from "app/model/form-layout/form-layout";


@Injectable({providedIn: "root"})
export class ClientTypeFactoryImpl extends ClientTypeFactory {
  constructor(
    private readonly serverApi: ServerApi
  ) {
    super();
  }

  create(category: ClientCategory, name: ClientTypeName, description: ClientTypeDescription, formLayoutId?: FormLayoutId): Observable<void> {
    let clientCategory: 'CORPORATION' | 'INDIVIDUAL';
    switch (category) {
      case ClientCategory.Corporation:
        clientCategory = 'CORPORATION'
        break;
      case ClientCategory.Individual:
        clientCategory = 'INDIVIDUAL'
        break;
      default:
        const unexpected: never = category;
        throw Error(`${unexpected} is unexpected value`);
    }
    return this.serverApi.clientTypeApi.create({
      category: clientCategory,
      name: name.value,
      description: description.value,
      formLayoutID: formLayoutId?.value
    });
  }
}
