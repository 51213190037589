import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

export type ClientRelatedProductsRequest = {
  perPage: number;
  page: number;
};

export interface ClientRelatedProductListResponse {
  totalCount: number,
  results: ClientRelatedProductListItem[]
}

export interface ClientRelatedProductListItem {
  clientProductRelationshipID: string,
  productID: string,
  productNumber: number,
  productTypeName: string,
  productName: string
}

export class ClientRelatedProductsApi {
  constructor(private readonly http: HttpClient) {}

  list(clientID: string, request: ClientRelatedProductsRequest): Observable<ClientRelatedProductListResponse> {
    return this.http.post<ClientRelatedProductListResponse>(`/api/clients/${clientID}/related-product-list`, request);
  }
}
