import { FormLayoutId } from "app/model/form-layout/form-layout";
import {Observable} from "rxjs";
import { ProductTypeDescription, ProductTypeId, ProductTypeName } from "./product-type";

export interface UpdateProductTypeParam {
  id: ProductTypeId,
  name: ProductTypeName,
  description: ProductTypeDescription,
  formLayoutID?: FormLayoutId,
}

export abstract class ProductTypeRepository {
  abstract save(param: UpdateProductTypeParam): Observable<void>;

  abstract remove(id: ProductTypeId): Observable<void>;

  abstract saveOrders(ids: ProductTypeId[]): Observable<void>;
}
