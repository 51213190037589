import { Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { ServerApi } from '#infrastructure/api/server-api';
import { OrganizationFactory } from 'app/model/organization/organization.factory';
import {
  IsCoachingAvailable,
  FiscalYearBegin,
  Organization,
  OrganizationCode,
  OrganizationID,
  OrganizationName, IsProposalEnabled, IsLinkageEnabled, IsOpportunityGroupEnabled,
} from 'app/model/organization/organization';

@Injectable({ providedIn: 'root' })
export class OrganizationFactoryImpl extends OrganizationFactory {
  constructor(private readonly api: ServerApi) {super()}

  create(
    organizationCode: OrganizationCode,
    organizationName: OrganizationName,
    fiscalYearBegin: FiscalYearBegin,
  ): Observable<Organization> {
    const request = {
      code: organizationCode.value,
      name: organizationName.value,
      fiscalYearBegin: fiscalYearBegin.value,
    };
    return this.api.organization.create(request).pipe(
      concatMap(() => this.api.organization.list()),
      map((organizations) => {
        const organization = organizations.find(
          (org) => org.code === organizationCode.value
        );
        if (organization == null) {
          throw new Error();
        }
        const id = new OrganizationID(organization.id);
        return new Organization(
          id,
          new OrganizationCode(organization.code),
          new OrganizationName(organization.name),
          new FiscalYearBegin(organization.fiscalYearBegin),
          new IsCoachingAvailable(organization.isCoachingAvailable),
          new IsProposalEnabled(organization.isProposalEnabled),
          new IsLinkageEnabled(organization.isLinkageEnabled),
          new IsOpportunityGroupEnabled(organization.isOpportunityGroupEnabled),
        );
      })
    );
  }
}
