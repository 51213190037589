import {ProposalStatusQueryService} from "#application/services/proposal-status-query.service";
import {
  ProposalStatus,
  ProposalStatusDescription,
  ProposalStatusID,
  ProposalStatusName,
  ProposalStatusType
} from "app/model/proposal-status/proposal-status";
import {Observable, throwError} from "rxjs";
import {Injectable} from "@angular/core";
import {ServerApi} from "#infrastructure/api/server-api";
import {MessageDialogService} from "#application/services/message-dialog.service";
import {catchError, map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class ProposalStatusQueryServiceImpl extends ProposalStatusQueryService {

  constructor(
    private readonly serverApi: ServerApi,
    private readonly messageDialogService: MessageDialogService,
  ) {
    super();
  }

  get(id: ProposalStatusID): Observable<ProposalStatus> {
    return this.serverApi.proposalStatusApi
      .get(id.value)
      .pipe(
        map(res => ({
          id: new ProposalStatusID(res.id),
          type: this._convertProposalStatusType(res.type),
          name: new ProposalStatusName(res.name),
          description: new ProposalStatusDescription(res.description)
        })),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '提案ステータスの取得'
          });
          return throwError(err);
        })
      );
  }

  private _convertProposalStatusType(
    type: 'DRAFT' | 'READY' | 'PROPOSED' | 'ADOPTED' | 'DECLINED' | 'REPROPOSAL_REQUESTED'
  ) {
    switch (type) {
      case 'DRAFT':
        return ProposalStatusType.DRAFT;
      case 'READY':
        return ProposalStatusType.READY;
      case 'PROPOSED':
        return ProposalStatusType.PROPOSED;
      case 'ADOPTED':
        return ProposalStatusType.ADOPTED;
      case 'DECLINED':
        return ProposalStatusType.DECLINED;
      case 'REPROPOSAL_REQUESTED':
        return ProposalStatusType.REPROPOSAL_REQUESTED;
      default:
        const unexpected: never = type;
        throw Error(`${unexpected} is unexpected value`);
    }
  }
}
