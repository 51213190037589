import { CoachingTiming } from 'app/model/coaching/coaching';
import { Insight, InsightID, InsightQuestion } from 'app/model/insight/insight';
import { OpportunityContextID, OpportunityContextName } from 'app/model/opportunity-context/opportunity-context';
import { FilterPredicateType } from 'app/model/search-filter/search-filter';
import { Observable } from 'rxjs';

export type SearchInsightListParams = {
  perPage: number,
  page: number,
  filter?: {
    question: {
      type: typeof FilterPredicateType.contains,
      value: string,
    },
    opportunityContextID: {
      type: typeof FilterPredicateType.anyMatch,
      values: string[],
    },
    coachingTiming: {
      type: typeof FilterPredicateType.anyMatch,
      values: CoachingTiming[],
    }
  }
};

export type InsightList = Readonly<{
  totalCount: number;
  results: readonly InsightListResult[]
}>
export type InsightListResult = Readonly<{
  id: InsightID,
  coachingTiming: CoachingTiming,
  question: InsightQuestion,
  triggeringOpportunityContexts: readonly Readonly<{
    id: OpportunityContextID,
    name: OpportunityContextName,
  }>[],
}>

export abstract class BaseInsightQueryService {
  abstract findBy(params: SearchInsightListParams): Observable<InsightList>;

  abstract get(id: InsightID): Observable<Insight>;
}
