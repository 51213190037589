import {
  FollowUpAction,
  FollowUpActionDue,
  FollowUpActionID,
  FollowUpActionIsDone,
  FollowUpActionSummary
} from 'app/model/follow-up-action/follow-up-action';
import { OpportunityID, OpportunityName, } from 'app/model/opportunity/opportunity';
import { DisplayName, UserID } from 'app/model/user/user';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Result } from 'app/lib/result/result';
import { Observable } from 'rxjs';

export type FollowUpActionListItem = Readonly<{
  id: FollowUpActionID,
  createdBy: {
    id: UserID,
    name: DisplayName,
  },
  opportunity: {
    id: OpportunityID,
    name: OpportunityName,
  },
  due: FollowUpActionDue,
  assignee: {
    id: UserID,
    name: DisplayName,
  },
  contentsSummary: FollowUpActionSummary,
  isDone: FollowUpActionIsDone,
}>

export type RequestedFollowUpActionListItem = Omit<FollowUpActionListItem, 'createdBy'>;

export type UndoneAssignedFollowUpActionList = ReadonlyArray<FollowUpActionListItem>;

export type UndoneRequestedFollowUpActionList = ReadonlyArray<RequestedFollowUpActionListItem>;

export type DoneAssignedFollowUpActionList = Readonly<{
  totalCount: number,
  results: ReadonlyArray<FollowUpActionListItem>,
}>

export type DoneRequestedFollowUpActionList = Readonly<{
  totalCount: number,
  results: ReadonlyArray<RequestedFollowUpActionListItem>,
}>

export namespace DoneFollowUpActionList {
  export function emptyAssigned(): DoneAssignedFollowUpActionList {
    return {
      totalCount: 0,
      results: [],
    };
  }

  export function emptyRequested(): DoneRequestedFollowUpActionList {
    return {
      totalCount: 0,
      results: [],
    };
  }
}

export const GetFollowUpActionNotFoundError = 'GetFollowUpActionNotFoundError' as const;
export const GetFollowUpActionUnexpectedError = 'GetFollowUpActionUnexpectedError' as const;
export type GetFollowUpActionError =
  | typeof GetFollowUpActionNotFoundError
  | typeof GetFollowUpActionUnexpectedError;

export abstract class FollowUpActionQueryService {
  /**
   * フォロー取得
   *
   * @param followUpActionID
   * @returns
   */
  abstract getFollowUpAction(
    followUpActionID: FollowUpActionID
  ): Observable<FollowUpAction | GetFollowUpActionError>;

  /** 
   * 未完了かつ自分が担当のフォロー一覧取得
   * @deprecated V2を使用すること
   */
  abstract listUndoneAssigned(): Observable<UndoneAssignedFollowUpActionList>;

  /** 
   * 完了かつ自分が担当のフォロー一覧取得
   * @deprecated V2を使用すること
   */
  abstract listDoneAssigned(pagination: {perPage: number, page: number}): Observable<DoneAssignedFollowUpActionList>;

  /** 未完了かつ自分が担当のフォロー一覧取得 */
  abstract listUndoneAssignedV2(): Observable<Result<
    UndoneAssignedFollowUpActionList,
    typeof GeneralFailure.Unexpected
  >>;

  /** 完了かつ自分が担当のフォロー一覧取得 */
  abstract listDoneAssignedV2(pagination: {perPage: number, page: number}): Observable<Result<
    DoneAssignedFollowUpActionList,
    typeof GeneralFailure.Unexpected
  >>;

  /** 未完了かつ依頼した（＝他人担当）のフォロー一覧取得 */
  abstract listUndoneRequested(): Observable<Result<
    UndoneRequestedFollowUpActionList,
    typeof GeneralFailure.Unexpected
  >>;

  /** 完了かつ依頼した（＝他人担当）のフォロー一覧取得 */
  abstract listDoneRequested(pagination: {perPage: number, page: number}): Observable<Result<
    DoneRequestedFollowUpActionList,
    typeof GeneralFailure.Unexpected
  >>;
}
