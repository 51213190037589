import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { OpportunityHearingItems, OpportunityHearingItemsV2 } from "app/model/opportunity-hearing-item/opportunity-hearing-item";
import { Timing } from "app/model/opportunity-hearing-setting/opportunity-hearing-setting";
import {OpportunityID, OpportunityItem, OpportunityName, OpportunityNumber} from "app/model/opportunity/opportunity";
import { PhaseID, SalesPhase } from "app/model/sales-phase/sales-phase";
import { Observable } from "rxjs";

/** @deprecated */
export type OpportunityWithSettings = {
  opportunityID: OpportunityID,
  opportunityName: OpportunityName,
  phaseID: PhaseID,
  items: OpportunityItem[],
  salesPhase: SalesPhase,
  hearingItems: OpportunityHearingItems,
}

export type OpportunityWithSettingsV2 = {
  opportunityID: OpportunityID,
  opportunityName: OpportunityName,
  opportunityNumber: OpportunityNumber,
  currentPhaseID: PhaseID,
  salesPhase: SalesPhase,
  hearingItems: OpportunityHearingItemsV2,
}

export abstract class OpportunityWithSettingsForActionQueryService {
  abstract get(opportunityID: OpportunityID, timing: Timing): Observable<OpportunityWithSettings>;
  abstract getV2(opportunityID: OpportunityID, timing: Timing): Observable<Result<
    OpportunityWithSettingsV2,
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >>;
}
