import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ServerApi} from "#infrastructure/api/server-api";
import { FormLayoutCategory, FormLayout, FormLayoutId, CustomFieldItem, FormLayoutItemType, FormLayoutWidth, SectionTitleItem } from "app/model/form-layout/form-layout";
import { FormLayoutRepository } from "app/model/form-layout/form-layout.repository";
import { SectionTitleItemData, CustomFieldItemData, BuiltinFieldItemData } from "#infrastructure/api/server-form-layout-api";

@Injectable({providedIn: 'root'})
export class FormLayoutRepositoryImpl extends FormLayoutRepository {
  constructor(
    private readonly serverApi: ServerApi,
  ) {
    super();
  }

  save(category: FormLayoutCategory, formLayout: FormLayout): Observable<void> {
    return this.serverApi.formLayoutApi.update(
      category,
      formLayout.id.value,
      {
        name: formLayout.name.value,
        items: formLayout.items.map(item => {
          if (item instanceof SectionTitleItem) {
            const section: SectionTitleItemData = {
              type: FormLayoutItemType.SECTION_TITLE,
              width: FormLayoutWidth.FULL,
              title: item.title.value
            }
            return section;
          } else if (item instanceof CustomFieldItem) {
            const custom: CustomFieldItemData = {
              type: FormLayoutItemType.CUSTOM_FIELD,
              width: item.width,
              customFieldID: item.fieldId.value
            }
            return custom;
          } else {
            const builtin: BuiltinFieldItemData = {
              type: FormLayoutItemType.BUILTIN_FIELD,
              width: item.width,
              builtinFieldID: item.builtinFieldId
            }
            return builtin;
          }
        })
      }
    );
  }

  remove(category: FormLayoutCategory, id: FormLayoutId): Observable<void> {
    return this.serverApi.formLayoutApi.delete(category, id.value);
  }
}
