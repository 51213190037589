import {
  OpportunityHearingSettingPerPhase
} from "app/model/opportunity-hearing-setting/opportunity-hearing-setting";
import {OpportunityTypeID} from "app/model/opportunity-type/opportunity-type";
import {Observable} from "rxjs";
import {CustomFieldId, CustomFieldName} from "app/model/custom-field/custom-field";

export type OpportunityHearingSettingList = OpportunityHearingSetting[];

interface OpportunityHearingSetting {
  id: CustomFieldId,
  name: CustomFieldName,
  settings: OpportunityHearingSettingPerPhase[]
}

export abstract class OpportunityHearingSettingQueryService {
  abstract get(opportunityTypeID: OpportunityTypeID): Observable<OpportunityHearingSettingList>;
}
