import {ServerApi} from '#infrastructure/api/server-api';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {
  FilteredSearchFilterList,
  SearchFilterDetail,
  SearchFilterQueryService,
  SearchSearchFilterListParams
} from '#application/services/search-filter-query.service';
import {
  FilterCondition,
  FilterPredicateType,
  SearchFilterBookmarkCount,
  SearchFilterCategory,
  SearchFilterDescription,
  SearchFilterEditability,
  SearchFilterId,
  SearchFilterName,
  SearchFilterVisibility
} from 'app/model/search-filter/search-filter';
import {DisplayName, UserID} from 'app/model/user/user';

@Injectable({
  providedIn: 'root',
})
export class SearchFilterQueryServiceImpl extends SearchFilterQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  get(category: SearchFilterCategory, id: SearchFilterId): Observable<SearchFilterDetail | undefined> {
    return this.serverApi.searchFilterApi.get(category, id.value).pipe(
      map(data => ({
        id: new SearchFilterId(data.id),
        filterName: new SearchFilterName(data.filterName),
        description: new SearchFilterDescription(data.description),
        owner: {
          id: new UserID(data.owner.id),
          name: new DisplayName(data.owner.name),
        },
        visibility: new SearchFilterVisibility(data.visibility.type),
        editability: new SearchFilterEditability(data.editability.type),
        // 本来はapiのレスポンスからundefinedを排除したいが、
        // 過去の登録済みフィルターはfilterが空配列の場合にundefined（バックエンドに送られるJSONはプロパティ省略）
        // で保存されているため、ここで[]に置き換えることで対処
        // displayItemsはComponentが持つ値が存在するため、登録がない場合をundefinedで表現する
        filter: data.filter === undefined
          ? []
          : convertToFilterConditions(data.filter),
        sorts: data.sort === undefined
          ? []
          : JSON.parse(data.sort),
        displayItems: data.displayField === undefined
          ? undefined
          : JSON.parse(data.displayField),
      })),
      catchError((e) => {
        // TODO フィルターIDが存在しない(404)を想定。この場合は処理を止めない。
        // エラーハンドリングは今後、要検討。
        return of(undefined);
      })
    );
  }

  findBy(
    category: SearchFilterCategory,
    params: SearchSearchFilterListParams
  ): Observable<FilteredSearchFilterList> {
    return this.serverApi.searchFilterApi.search(category, params).pipe(
      map(res => ({
        totalCount: res.totalCount,
        results: res.results.map(v => ({
          id: new SearchFilterId(v.id),
          filterName: new SearchFilterName(v.filterName),
          description: new SearchFilterDescription(v.description),
          owner: {
            id: new UserID(v.owner.id),
            name: new DisplayName(v.owner.name),
          },
          visibility: new SearchFilterVisibility(v.visibility.type),
          editability: new SearchFilterEditability(v.editability.type),
          bookmark: v.bookmark,
          bookmarkCount: new SearchFilterBookmarkCount(v.bookmarkCount),
        }))
      }))
    );
  }
}

export type JsonFilterConditions = (
  {
    propertyId: string,
    type: 'greaterThanOrEqualTo' | 'lessThanOrEqualTo' | 'equals' | 'empty',
    value: string,
  } | {
    propertyId: string,
    type: 'anyMatch',
    value: string[],
  } | {
    propertyId: string,
    type: 'contains',
    value: string,
    condition?: 'and' | 'or'
  } | {
    propertyId: string,
    type: 'range',
    from: string,
    range: number,
    bounds: 'INCLUSIVE_EXCLUSIVE' | 'INCLUSIVE_INCLUSIVE' | 'EXCLUSIVE_INCLUSIVE' | 'EXCLUSIVE_EXCLUSIVE',
  } | {
    propertyId: string,
    type: 'between',
    from: number,
    to: number,
    bounds: 'INCLUSIVE_EXCLUSIVE' | 'INCLUSIVE_INCLUSIVE' | 'EXCLUSIVE_INCLUSIVE' | 'EXCLUSIVE_EXCLUSIVE',
  }
)[]

const convertToFilterConditions = (filter: string): FilterCondition[] => {
  const jsonFilterConditions: JsonFilterConditions = JSON.parse(filter);
  const result: FilterCondition[] = [];
  jsonFilterConditions.map(v => {
    if (v.type === FilterPredicateType.anyMatch) {
      result.push(
        {
          propertyId: v.propertyId,
          type: v.type,
          value: v.value
        }
      );
    } else if (v.type === FilterPredicateType.contains) {
      result.push(
        {
          propertyId: v.propertyId,
          type: v.type,
          value: v.value,
          condition: (v.condition) ? v.condition : 'and'
        }
      );
    } else if (v.type === FilterPredicateType.range) {
      result.push(
        {
          propertyId: v.propertyId,
          type: v.type,
          from: v.from,
          range: v.range,
          bounds: v.bounds
        }
      );
    } else if (v.type === FilterPredicateType.between) {
      result.push(
        {
          propertyId: v.propertyId,
          type: v.type,
          from: v.from,
          to: v.to,
          bounds: v.bounds,
        }
      );
    } else {
      result.push(
        {
          propertyId: v.propertyId,
          type: v.type,
          value: v.value
        }
      );
    }
  });
  return result;
}
