import {Observable} from "rxjs";
import {FollowUpActionTypeDescription, FollowUpActionTypeName} from "app/model/follow-up-action/follow-up-action-type";

export interface CreateFollowUpActionTypeParam {
  name: FollowUpActionTypeName,
  description: FollowUpActionTypeDescription,
}

export abstract class FollowUpActionTypeFactory {
  abstract create(param: CreateFollowUpActionTypeParam): Observable<void>;
}
