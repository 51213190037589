import {InsightID} from "app/model/insight/insight";

export const InsightFeedbackOptionID = {
  NOT_APPLICABLE_TO_THE_COMPANY: "NOT_APPLICABLE_TO_THE_COMPANY",
  NOT_WELL_EXPRESSED: "NOT_WELL_EXPRESSED",
  DO_NOT_UNDERSTAND: "DO_NOT_UNDERSTAND",
  OTHER: "OTHER",
} as const;
export type InsightFeedbackOptionID = typeof InsightFeedbackOptionID[keyof typeof InsightFeedbackOptionID];

export class InsightFeedbackContent {
  constructor(public readonly value: string) {}
}

export class InsightFeedback {
  constructor(
    public readonly insightFeedbackOptionID: InsightFeedbackOptionID,
    public readonly insightID: InsightID,
    public readonly content: InsightFeedbackContent,
  ) {}
}
