import {ClientProductRelationshipID} from "app/model/client-product-relationship/client-product-relationship";
import {ProductId} from "app/model/product/product";
import {Observable} from "rxjs";
import {ClientID, ClientName, ClientNumber} from "app/model/client/client";
import {ClientCategory, ClientTypeName} from "app/model/client-info-type/client-type";

export type ProductRelatedClientsInput = {
  perPage: number;
  page: number;
};

export type ProductRelatedClientList = {
  totalCount: number;
  results: ProductRelatedClientListItem[];
}

export interface ProductRelatedClientListItem {
  clientProductRelationshipID: ClientProductRelationshipID,
  clientID: ClientID,
  clientNumber: ClientNumber,
  clientName: ClientName,
  clientTypeName: ClientTypeName,
  clientCategory: ClientCategory
}

export abstract class ProductRelatedClientsQueryService {
  abstract getProductRelatedClientList(
    productId: ProductId,
    input: ProductRelatedClientsInput
  ): Observable<ProductRelatedClientList>;
}
