import { OrganizationID } from "app/model/organization/organization";
import { Observable } from "rxjs";

export type OnetimeTokenData = {
  'x-sn-user-id': string,
  'x-sn-sso-token': string,
};

export type OnetimeTokenDataKey = keyof OnetimeTokenData;

export abstract class SingleSignOnService {
  abstract issueOnetimeToken(id: OrganizationID): Observable<OnetimeTokenData>;
  abstract signOn(data: OnetimeTokenData): Observable<boolean>;
}
