import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[snPreventMultipleClick]'
})
export class PreventMultipleClickDirective implements OnDestroy {

  @Input()
  waitTime = 1500;

  private readonly clicks = new Subject<void>();

  constructor(
    private readonly el: ElementRef,
  ) {
    this.clicks.pipe(
      debounceTime(this.waitTime)
    )
    .subscribe(() => {
      this.el.nativeElement.disabled = false;
      this.el.nativeElement.classList.remove('mat-button-disabled');
    });
  }

  @HostListener('click')
  onClick(): void {
    this.el.nativeElement.disabled = true;
    this.el.nativeElement.classList.add('mat-button-disabled');
    this.clicks.next();
  }

  ngOnDestroy(): void {
    this.clicks.unsubscribe();
  }
}
