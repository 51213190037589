import { GoalSettingCommandService, SaveGoalSettingInput } from '#application/services/goal-setting-command.service';
import { MessageDialogService } from '#application/services/message-dialog.service';
import { SnackBarService } from '#application/services/snack-bar.service';
import { ServerApiError, ServerApiErrorCode } from '#infrastructure/api/error';
import { ServerApi } from '#infrastructure/api/server-api';
import { SaveGoalSettingRequest } from '#infrastructure/api/server-goal-setting-api';
import { messageFn } from '#utils/messages';
import { Injectable, inject } from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import { GoalCategory, MonthlyGoal } from 'app/model/report/report-goal';
import { NEVER, Observable, catchError, map, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoalSettingCommandServiceImpl implements GoalSettingCommandService {
  private readonly serverApi = inject(ServerApi);
  private readonly snackBarService = inject(SnackBarService);
  private readonly messageDialogService = inject(MessageDialogService);

  private readonly FIELD_NAME = '目標設定';

  save(category: GoalCategory, input: SaveGoalSettingInput): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.goalSettingApi
      .save(
        category,
        {
          fiscalYear: input.fiscalYear.year.toString(),
          goalsPerPeriodType: this._convertToSaveInput(input.goals),
        },
      )
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(this.FIELD_NAME, 'SAVE_SUCCESS').message);
        }),
        map((v) => Success<void>(v)),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.BadRequest:
              return of(Failure(GeneralFailure.BadRequest));
          }
          this.messageDialogService.notice({
            message: messageFn(this.FIELD_NAME, 'SAVE_ERROR').message,
          });
          return NEVER;
        })
      );
  }

  private _convertToSaveInput(monthlyGoals: MonthlyGoal[]): SaveGoalSettingRequest['goalsPerPeriodType'] {
    const goals = monthlyGoals.reduce((accumulator, value, index) => ({
      ...accumulator,
      [value.yearMonth.toFormat('yyyy-MM-dd')]: value.goal,
    }), {});
    // TODO goalPeriodTypeにMONTHLY以外が追加された場合修正が必要
    return [
      {
        goalPeriodType: 'MONTHLY',
        goals: goals,
      },
    ];
  }
}
