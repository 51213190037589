import { Signal } from "@angular/core";
import { SchedulePcFilterState } from "app/model/storage/schedule-pc-filter";
import { Observable } from "rxjs";

export abstract class SchedulePcFilterStorageService {
  abstract readonly filter$: Observable<SchedulePcFilterState>;
  abstract readonly filter: Signal<SchedulePcFilterState>;
  abstract store(filter: SchedulePcFilterState): void;
  abstract reset(current: SchedulePcFilterState): void;
  abstract refresh(): void;
}
