import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

export interface ListOpportunityTypesResponseItemData {
  id: string;
  name: string;
  description: string;
  salesPhaseID: string;
  formLayoutID?: string | undefined;
}

export type ListOpportunityTypeResponse = ListOpportunityTypesResponseItemData[];

export interface OpportunityTypeListItemData {
  id: string;
  name: string;
  description: string;
  salesPhaseID: string;
  salesPhaseName: string;
  formLayoutID?: string;
  formLayoutName: string;
}

export type GetOpportunityTypeListResponse = OpportunityTypeListItemData[];

export interface CreateOpportunityTypeRequestData {
  name: string,
  description: string,
  salesPhaseID: string,
  formLayoutID?: string,
}

export type CreateOpportunityTypeRequest = CreateOpportunityTypeRequestData;

interface SortOpportunityTypeRequestData {
  ids: string[]
}

export type SortOpportunityTypeRequest = SortOpportunityTypeRequestData;

interface GetOpportunityTypeResponseData {
  id: string,
  name: string,
  description: string,
  salesPhaseID: string,
  formLayoutID?: string | undefined,
}

export type GetOpportunityTypeResponse = GetOpportunityTypeResponseData;

interface UpdateOpportunityTypeRequestData {
  name: string,
  description: string,
  formLayoutID?: string,
}

export type UpdateOpportunityTypeRequest = UpdateOpportunityTypeRequestData;

export class OpportunityTypeApi {
  constructor(private readonly http: HttpClient) {
  }

  list(): Observable<ListOpportunityTypeResponse> {
    return this.http.get<ListOpportunityTypeResponse>('/api/opportunity-types');
  }

  getOpportunityTypeList(): Observable<GetOpportunityTypeListResponse> {
    return this.http.get<GetOpportunityTypeListResponse>('/api/opportunity-type-list');
  }

  create(request: CreateOpportunityTypeRequest): Observable<void> {
    return this.http.post<void>('/api/opportunity-types', request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/opportunity-types/${id}`);
  }

  sort(request: SortOpportunityTypeRequest): Observable<void> {
    return this.http.patch<void>(`/api/opportunity-types/sort`, request);
  }

  get(id: string): Observable<GetOpportunityTypeResponse> {
    return this.http.get<GetOpportunityTypeResponse>(`/api/opportunity-types/${id}`)
  }

  update(id: string, request: UpdateOpportunityTypeRequest): Observable<void> {
    return this.http.put<void>(`/api/opportunity-types/${id}`, request);
  }

}
