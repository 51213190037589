import { ContactHistoryTemplateID, ContactHistoryTemplateName, ContactHistoryTemplateContent } from 'app/model/contact-history-template/contact-history-template';
import { Observable } from 'rxjs';

export interface ContactHistoryTemplatesListItem {
  id: ContactHistoryTemplateID,
  name: ContactHistoryTemplateName,
  content: ContactHistoryTemplateContent
}

export type ContactHistoryTemplatesList = ContactHistoryTemplatesListItem[];

export interface ContactHistoryTemplateDetail {
  name: ContactHistoryTemplateName,
  content: ContactHistoryTemplateContent
}

export abstract class ContactHistoryTemplateQueryService {
  abstract getContactHistoryTemplates(): Observable<ContactHistoryTemplatesList>;

  abstract getContactHistoryTemplateByID(templateID: ContactHistoryTemplateID): Observable<ContactHistoryTemplateDetail>;
}
