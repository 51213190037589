import { NurturingHearingItem } from "app/model/nurturing-hearing/nurturing-hearing";
import { NurturingTypeID } from "app/model/nurturing-type/nurturing-type";
import { Observable } from "rxjs";

export type GetNurturingHearingSettingOutputs = {
  nurturingTypeID: NurturingTypeID,
  beforeHearingItems: NurturingHearingItem[],
  afterHearingItems: NurturingHearingItem[],
};

export abstract class NurturingHearingSettingQueryService {
  abstract get(nurturingTypeID: NurturingTypeID): Observable<GetNurturingHearingSettingOutputs>;
}
