/**
 * スナックバーサービス
 */
export abstract class SnackBarService {
    /**
     * メッセージを表示
     * @param message メッセージ
     */
    abstract show(message: string): void;
}
