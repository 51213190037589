import { MeetingBoardSettingCommandService, SaveMeetingBoardSettingInput } from '#application/services/meeting-board-setting-command.service';
import { MessageDialogService } from '#application/services/message-dialog.service';
import { SnackBarService } from '#application/services/snack-bar.service';
import { ServerApiError, ServerApiErrorCode } from '#infrastructure/api/error';
import { ServerApi } from '#infrastructure/api/server-api';
import { SaveMeetingBoardSettingRequest } from '#infrastructure/api/server-meeting-board-setting-api';
import { messageFn } from '#utils/messages';
import { Injectable, inject } from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import { MeetingBoardSettingID } from 'app/model/meeting-board/meeting-board';
import { MeetingBoardSettingFilterCondition } from 'app/model/meeting-board/meeting-board-setting-filter-conditon';
import { NEVER, Observable, catchError, map, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeetingBoardSettingCommandServiceImpl implements MeetingBoardSettingCommandService {
  private readonly serverApi = inject(ServerApi);
  private readonly snackBarService = inject(SnackBarService);
  private readonly messageDialogService = inject(MessageDialogService);

  private readonly FIELD_NAME = 'ミーティングボード設定';

  save(input: SaveMeetingBoardSettingInput): Observable<Result<
    MeetingBoardSettingID,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.meetingBoardSettingApi
      .save(this._convertToSaveRequest(input))
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(this.FIELD_NAME, 'SAVE_SUCCESS').message);
        }),
        map((v) => Success<MeetingBoardSettingID>(MeetingBoardSettingID(v.meetingBoardSettingID))),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.BadRequest:
              return of(Failure(GeneralFailure.BadRequest));
          }
          this.messageDialogService.notice({
            message: messageFn(this.FIELD_NAME, 'SAVE_ERROR').message,
          });
          return NEVER;
        })
      );
  }

  delete(id: MeetingBoardSettingID): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.meetingBoardSettingApi
      .delete(id)
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(this.FIELD_NAME, 'DELETE_SUCCESS').message);
        }),
        map((v) => Success<void>(v)),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.BadRequest:
              return of(Failure(GeneralFailure.BadRequest));
          }
          this.messageDialogService.notice({
            errorTarget: `${ this.FIELD_NAME }の削除`
          });
          return NEVER;
        })
      );
  }

  bookmark(id: MeetingBoardSettingID): Observable<Result<
    void,
    | typeof GeneralFailure.NotFound
  >> {
    return this.serverApi.meetingBoardSettingApi
      .bookmark(id)
      .pipe(
        map((v) => Success<void>(v)),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.NotFound:
              return of(Failure(GeneralFailure.NotFound));
          }
          this.messageDialogService.notice({
            errorTarget: this.FIELD_NAME
          });
          return NEVER;
        })
      );
  }

  unbookmark(id: MeetingBoardSettingID): Observable<Result<
    void,
    | typeof GeneralFailure.NotFound
  >> {
    return this.serverApi.meetingBoardSettingApi
      .unbookmark(id)
      .pipe(
        map((v) => Success<void>(v)),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.NotFound:
              return of(Failure(GeneralFailure.NotFound));
          }
          this.messageDialogService.notice({
            errorTarget: this.FIELD_NAME
          });
          return NEVER;
        })
      );
  }

  private _convertToSaveRequest(input: SaveMeetingBoardSettingInput): SaveMeetingBoardSettingRequest {
    const id = input.meetingBoardSettingID
      ? { meetingBoardSettingID: input.meetingBoardSettingID }
      : { };
    return {
      ...id,
      name: input.name,
      description: input.description,
      visibility: input.visibility,
      editability: input.editability,
      headerCondition: {
        isDisplayed: input.headerCondition.isDisplayed,
        displayUserIDs: input.headerCondition.displayUserIDs.map((v) => v.value),
      },
      filterConditionLiteral: MeetingBoardSettingFilterCondition.stringify(input.filterCondition),
    };
  }
}
