/**
 * 顧客タイプのリスト
 */
import {FormLayoutId} from "app/model/form-layout/form-layout";

/**
 * 顧客タイプ
 */
export class ClientType {
  constructor(
    public readonly id: ClientTypeID,
    public readonly category: ClientCategory,
    public readonly name: ClientTypeName,
    public readonly description: ClientTypeDescription,
    public readonly formLayoutId?: FormLayoutId,
  ) {
  }

  static new(): ClientType {
    return new ClientType(
      new ClientTypeID(''),
      ClientCategory.Corporation,
      new ClientTypeName(''),
      new ClientTypeDescription('')
    );
  }

  isCorporation(): boolean {
    return this.category === ClientCategory.Corporation;
  }

  isIndividual(): boolean {
    return this.category === ClientCategory.Individual;
  }

  /**
   * 顧客カテゴリ変更処理
   * @param category 変更する顧客カテゴリ
   * @returns 顧客カテゴリが変更された顧客タイプ
   */
  changeCategory(category: ClientCategory): ClientType {
    return new ClientType(this.id, category, this.name, this.description, this.formLayoutId);
  }

  /**
   * 顧客名変更処理
   * @param name 変更する顧客名
   * @returns 顧客名が変更された顧客タイプ
   */
  changeName(name: ClientTypeName): ClientType {
    return new ClientType(this.id, this.category, name, this.description, this.formLayoutId);
  }

  /**
   * 顧客説明変更処理
   * @param description 変更する顧客説明
   * @returns 顧客説明が変更された顧客タイプ
   */
  changeDescription(description: ClientTypeDescription): ClientType {
    return new ClientType(this.id, this.category, this.name, description, this.formLayoutId);
  }
}

/**
 * 顧客タイプを一意に識別する値
 */
export class ClientTypeID {
  constructor(public readonly value: string) {
  }
}

/**
 * 顧客タイプの名称
 */
export class ClientTypeName {
  constructor(public readonly value: string) {
  }
}

/**
 * 顧客タイプの説明
 */
export class ClientTypeDescription {
  constructor(public readonly value: string) {
  }
}

/**
 * 顧客カテゴリー
 */
export const ClientCategory = {
  Corporation: 'Corporation',
  Individual: 'Individual',
} as const;
export type ClientCategory =
  typeof ClientCategory[keyof typeof ClientCategory];
