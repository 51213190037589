import { Injectable } from '@angular/core';
import {ServerApi} from "#infrastructure/api/server-api";
import {SnackBarService} from "#application/services/snack-bar.service";
import {InsightFeedback} from "app/model/insight/insight-feedback";
import {Observable, throwError} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {InsightFeedbackCommandService} from "#application/services/insight-feedback-command.service";
import {messageFn, Target} from "#utils/messages";

@Injectable({
  providedIn: 'root'
})
export class InsightFeedbackCommandServiceImpl extends InsightFeedbackCommandService{

  constructor(
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
  ) {
    super();
  }

  register(insightFeedback: InsightFeedback): Observable<void> {
    return this.serverApi.insightApi
      .feedback(
        insightFeedback.insightID.value,
        {
          feedbackOptionID: insightFeedback.insightFeedbackOptionID,
          feedbackContent: insightFeedback.content.value,
        }
      )
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(Target.INSIGHT_FEEDBACK, 'CREATE_SUCCESS').message);
        }),
        catchError((err) => {
          this.snackBarService.show(messageFn(Target.INSIGHT_FEEDBACK, 'CREATE_ERROR').message);
          return throwError(err);
        })
      );
  }
}
