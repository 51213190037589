import {Injectable} from '@angular/core';
import {
  InsightFeedbackOption,
  InsightFeedbackOptionQueryService
} from "#application/services/insight-feedback-option-query.service";
import {ServerApi} from "#infrastructure/api/server-api";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {MessageDialogService} from "#application/services/message-dialog.service";
import {InsightFeedbackOptionID} from "app/model/insight/insight-feedback";

@Injectable({
  providedIn: 'root'
})
export class InsightFeedbackOptionQueryServiceImpl extends InsightFeedbackOptionQueryService{

  constructor(
    private readonly serverApi: ServerApi,
    private readonly messageDialogService: MessageDialogService,
  ) {
    super();
  }

  get(): Observable<InsightFeedbackOption[]> {
    return this.serverApi.insightFeedbackOptionsApi.get()
      .pipe(
        map(res =>
          res.map(item => {
            let insightFeedbackOptionID;
            switch (item.id) {
              case 'NOT_APPLICABLE_TO_THE_COMPANY':
                insightFeedbackOptionID = InsightFeedbackOptionID.NOT_APPLICABLE_TO_THE_COMPANY;
                break;
              case 'NOT_WELL_EXPRESSED':
                insightFeedbackOptionID = InsightFeedbackOptionID.NOT_WELL_EXPRESSED;
                break;
              case 'DO_NOT_UNDERSTAND':
                insightFeedbackOptionID = InsightFeedbackOptionID.DO_NOT_UNDERSTAND;
                break;
              case 'OTHER':
                insightFeedbackOptionID = InsightFeedbackOptionID.OTHER;
                break;
              default:
                const unexpected: never = item.id;
                throw Error(`${unexpected} is unexpected value`);
            }
            return new InsightFeedbackOption(insightFeedbackOptionID, item.content)
          })
        ),
        catchError(e => {
          this.messageDialogService.notice({
            errorTarget: 'インサイトに対するフィードバックの選択肢取得'
          });
          return throwError(e);
        })
      );
  }

}
