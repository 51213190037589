import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type ListScheduleRequest = {
  start: string,
  end: string,
  userIDs: string[],
};

export type ListScheduleEventCategoryProps = {
  type: 'EVENT',
  id: string,
  baseStart: string,
  recurring: 'NEVER' | 'NON_EXCEPTIONAL' | 'EXCEPTIONAL',
};

export type ListScheduleResponse = {
  items: {
    relatedUsers: {
      id: string,
      name: string,
    }[],
    category:
      {
        type: 'ACTION',
        id: string,
        status: 'DONE' | 'UNDONE',
      } |
      {
        type: 'NURTURING',
        id: string,
        status: 'BEFORE' | 'AFTER',
      } |
      ListScheduleEventCategoryProps,
    name: string,
    start: string,
    end: string,
    rangeType: 'DAYS' | 'MINUTES',
  }[],
}

export class ScheduleV2Api {
  constructor(private readonly http: HttpClient) {}

  list(request: ListScheduleRequest): Observable<ListScheduleResponse> {
    return this.http.post<ListScheduleResponse>(`/api/schedule`, request);
  }
}
