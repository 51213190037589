import {ServerApi} from '#infrastructure/api/server-api';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { PhaseOpportunityContextSettingRepository } from 'app/model/opportunity-type/phase-opportunity-context-setting/phase-opportunity-context-setting.repository';
import { PhaseOpportunityContextSetting } from 'app/model/opportunity-type/phase-opportunity-context-setting/phase-opportunity-context-setting';
import { PatchPhaseOpportunityContextSettingRequest } from '#infrastructure/api/server-opportunity-type-opportunity-context-setting-api';

@Injectable({providedIn: 'root'})
export class PhaseOpportunityContextSettingRepositoryImpl implements PhaseOpportunityContextSettingRepository {
  constructor(private readonly serverApi: ServerApi) {
  }

  saveByPhase(setting: PhaseOpportunityContextSetting): Observable<void> {
    const request: PatchPhaseOpportunityContextSettingRequest = {
      phaseID: setting.phaseID.value,
      opportunityContextIDs: setting.opportunityContextIDs === undefined
        ? undefined
        : setting.opportunityContextIDs.map(v => v.value)
    };
    return this.serverApi.opportunityTypeOpportunityContextSettingApi
    .patchByPhase(
      setting.opportunityTypeID.value,
      request
    );
  }
}
