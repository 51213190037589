import { GetGroupScheduleInput, GetScheduleInput, MbScheduleItemsV2, PcGroupScheduleItemsV2, PcIndividualScheduleItemsV2, ScheduleV2QueryService } from "#application/services/schedule-v2-query.service";
import { ServerApi } from "#infrastructure/api/server-api";
import { ListScheduleEventCategoryProps, ListScheduleRequest, ListScheduleResponse } from "#infrastructure/api/server-schedule-v2-api";
import { Injectable, inject } from "@angular/core";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Failure, Result, Success } from "app/lib/result/result";
import { EventBaseStart } from "app/model/event/event-v2";
import { MbScheduleDaysEventItem, MbScheduleDaysItem, MbScheduleMinutesActionItem, MbScheduleMinutesEventItem, MbScheduleMinutesItem, MbScheduleMinutesNurturingItem } from "app/model/schedule/mobile-schedule";
import { PcScheduleDaysEventItem, PcScheduleDaysItem, PcScheduleMinutesActionItem, PcScheduleMinutesEventItem, PcScheduleMinutesItem, PcScheduleMinutesNurturingItem, ScheduleEnd, ScheduleID, ScheduleName, ScheduleStart } from "app/model/schedule/pc-schedule";
import { DateTime } from "luxon";
import { Observable, catchError, map, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ScheduleV2QueryServiceImpl implements ScheduleV2QueryService {
  private readonly _serverApi = inject(ServerApi);

  individualWeek(input: GetScheduleInput): Observable<Result<
    PcIndividualScheduleItemsV2,
    typeof GeneralFailure.Unexpected
  >> {
    const { start, startOfNextWeek } = this._getWeekStartAndNextWeekStart(input.targetDate);
    return this._serverApi.scheduleV2Api.list({
      start: start.toISO(),
      end: startOfNextWeek.toISO(),
      userIDs: input.userIDs.map(v => v.value),
    } satisfies ListScheduleRequest)
    .pipe(
      map(this._sortDateAscStartDescEnd),
      map(res => Success(this._toPcIndividualWeekItems(res, start, startOfNextWeek))),
      catchError(() => of(Failure(GeneralFailure.Unexpected)))
    );
  }

  individualMonth(input: GetScheduleInput): Observable<Result<
    PcIndividualScheduleItemsV2,
    typeof GeneralFailure.Unexpected
  >> {
    const start = input.targetDate.startOf('month').startOf('week').startOf('day');
    const endPlusOneDay = input.targetDate.endOf('month').endOf('week').startOf('day').plus({ days: 1 });
    return this._serverApi.scheduleV2Api.list({
      start: start.toISO(),
      end: endPlusOneDay.toISO(),
      userIDs: input.userIDs.map(v => v.value),
    } satisfies ListScheduleRequest)
    .pipe(
      map(this._sortDateAscStartDescEnd),
      map(res => Success(this._toPcIndividualMonthItems(res, start, endPlusOneDay))),
      catchError(() => of(Failure(GeneralFailure.Unexpected)))
    );
  }

  groupDay(input: GetGroupScheduleInput): Observable<Result<
    PcGroupScheduleItemsV2,
    typeof GeneralFailure.Unexpected
  >> {
    const start = input.targetDate.startOf('day');
    const end = start.plus({ days: 1 });
    return this._serverApi.scheduleV2Api.list({
      start: start.toISO(),
      end: end.toISO(),
      userIDs: input.users.map(v => v.id.value),
    } satisfies ListScheduleRequest)
    .pipe(
      map(res => Success(this._toPcGroupDayItems(res, start, this._sortUserLoginIDAsc(input.users)))),
      catchError(() => of(Failure(GeneralFailure.Unexpected)))
    );
  }

  groupWeek(input: GetGroupScheduleInput): Observable<Result<
    PcGroupScheduleItemsV2,
    typeof GeneralFailure.Unexpected
  >> {
    const { start, startOfNextWeek } = this._getWeekStartAndNextWeekStart(input.targetDate);
    return this._serverApi.scheduleV2Api.list({
      start: start.toISO(),
      end: startOfNextWeek.toISO(),
      userIDs: input.users.map(v => v.id.value),
    } satisfies ListScheduleRequest)
    .pipe(
      map(this._sortDateAscStartDescEnd),
      map(res => Success(this._toPcGroupWeekItems(res, start, startOfNextWeek, this._sortUserLoginIDAsc(input.users)))),
      catchError(() => of(Failure(GeneralFailure.Unexpected)))
    );
  }

  mobile(input: GetScheduleInput): Observable<Result<
    MbScheduleItemsV2,
    typeof GeneralFailure.Unexpected
  >> {
    const { start, startOfNextWeek } = this._getWeekStartAndNextWeekStart(input.targetDate);
    return this._serverApi.scheduleV2Api.list({
      start: start.toISO(),
      end: startOfNextWeek.toISO(),
      userIDs: input.userIDs.map(v => v.value),
    } satisfies ListScheduleRequest)
    .pipe(
      map(res => Success(this._toMobileItems(res, start, startOfNextWeek))),
      catchError(() => of(Failure(GeneralFailure.Unexpected)))
    );
  }

  day(input: GetScheduleInput): Observable<Result<
    PcIndividualScheduleItemsV2,
    typeof GeneralFailure.Unexpected
  >> {
    const start = input.targetDate.startOf('day');
    const end = start.plus({ days: 1 });
    return this._serverApi.scheduleV2Api.list({
      start: start.toISO(),
      end: end.toISO(),
      userIDs: input.userIDs.map(v => v.value),
    } satisfies ListScheduleRequest)
    .pipe(
      map(res => Success(this._toPcIndividualWeekItems(res, start, end))),
      catchError(() => of(Failure(GeneralFailure.Unexpected)))
    );
  }

  /**
   * 開始日昇順（古い順）、終了日降順（新しい順）でソートする。
   */
  private _sortDateAscStartDescEnd(res: ListScheduleResponse): ListScheduleResponse {
    return {
      items: res.items.sort((a, b) => {
        const aStart = new Date(a.start);
        const bStart = new Date(b.start);
        const aEnd = new Date(a.end);
        const bEnd = new Date(b.end);
        if (aStart < bStart) return -1;
        if (aStart > bStart) return 1;
        if (aEnd > bEnd) return -1;
        if (aEnd < bEnd) return 1;
        return 0;
      }),
    } satisfies ListScheduleResponse;
  }

  private _sortUserLoginIDAsc(users: GetGroupScheduleInput['users']): GetGroupScheduleInput['users'] {
    return users.sort((a, b) => a.loginID.value.localeCompare(b.loginID.value));
  }

  /**
   * 指定された日付の週の開始日と次の週の開始日を取得する。
   * （バックエンドAPIの仕様上、終端は含まれないため、終了日は次の週の開始日を指定する必要がある。）
   */
  private _getWeekStartAndNextWeekStart(targetDate: DateTime): { start: DateTime, startOfNextWeek: DateTime } {
    return {
      start: targetDate.startOf('week'),
      startOfNextWeek: targetDate.startOf('week').plus({ week: 1 }),
    };
  }

  private _toPcIndividualWeekItems(
    res: ListScheduleResponse,
    start: DateTime,
    end: DateTime
  ): PcIndividualScheduleItemsV2 {
    const dateTimes = start.until(end).splitBy({ days: 1 }).map(v => v.start);
    const minutesItems = this.__toPcWeekMinutesItems(res.items);
    return {
      minutes: dateTimes.map(dt => ({
        date: dt,
        items: minutesItems.filter(v => DateTime.fromJSDate(v.start.value).hasSame(dt, 'day')),
      })),
      days: this.__toPcWeekDaysItems(res.items, start, end),
    } satisfies PcIndividualScheduleItemsV2;
  }

  private _toPcIndividualMonthItems(
    res: ListScheduleResponse,
    start: DateTime,
    end: DateTime
  ): PcIndividualScheduleItemsV2 {
    const dateTimes = start.until(end).splitBy({ days: 1 }).map(v => v.start);
    return {
      minutes: dateTimes.map(dt => ({
        date: dt,
        items: this.__toPcMinutesItems(res.items).filter(v => DateTime.fromJSDate(v.start.value).hasSame(dt, 'day')),
      })),
      days: this.__toPcDaysItems(res.items),
    } satisfies PcIndividualScheduleItemsV2;
  }

  private _toPcGroupDayItems(
    res: ListScheduleResponse,
    start: DateTime,
    users: GetGroupScheduleInput['users']
  ): PcGroupScheduleItemsV2 {
    return users.map(u => {
      const itemsExtractedByUser = this.__extractResponseItemsByUser(res.items, u.id.value);
      return {
        user: {
          id: u.id,
          name: u.name,
        },
        minutes: [{
          date: start,
          items: this.__toPcMinutesItems(itemsExtractedByUser)
        }],
        days: this.__toPcDaysItems(itemsExtractedByUser),
      };
    }) satisfies PcGroupScheduleItemsV2;
  }

  private _toPcGroupWeekItems(
    res: ListScheduleResponse,
    start: DateTime,
    end: DateTime,
    users: GetGroupScheduleInput['users']
  ): PcGroupScheduleItemsV2 {
    const dateTimes = start.until(end).splitBy({ days: 1 }).map(v => v.start);
    return users.map(u => {
      const itemsExtractedByUser = this.__extractResponseItemsByUser(res.items, u.id.value);
      return {
        user: {
          id: u.id,
          name: u.name,
        },
        minutes: dateTimes.map(dt => ({
          date: dt,
          items: this.__toPcWeekMinutesItems(itemsExtractedByUser)
            .filter(v => DateTime.fromJSDate(v.start.value).hasSame(dt, 'day')),
        })),
        days: this.__toPcWeekDaysItems(itemsExtractedByUser, start, end),
      };
    }) satisfies PcGroupScheduleItemsV2;
  }

  private _toMobileItems(
    res: ListScheduleResponse,
    start: DateTime,
    end: DateTime
  ): MbScheduleItemsV2 {
    const dateTimes = start.until(end).splitBy({ days: 1 }).map(v => v.start);
    const minutesConverted = this.__toMinutesScheduleItemsConvertedDateTimeSplitByDay(res.items);
    const days = this.__toMbDaysItems(res.items);
    return new MbScheduleItemsV2(
      dateTimes.map(dt => ({
        date: dt,
        minutes: this.__toMbMinutesItems(minutesConverted.filter(v => v.start.hasSame(dt, 'day'))),
        days: days.filter(v => DateTime.fromJSDate(v.originalStart.value).hasSame(dt, 'day'))
      }))
    );
  }

  private __extractResponseItemsByUser(
    items: ListScheduleResponse['items'],
    userID: string
  ): ListScheduleResponse['items'] {
    return items.filter(v => v.relatedUsers.some(u => u.id === userID));
  }

  /**
   * APIレスポンスと目的の型を橋渡しする型に変換する。具体的には、
   * 1. 分単位予定を抽出
   * 2. 開始・終了の日付をDateTimeに変換したうえで、originalStartとoriginalEndを付与
   * 3. 日をまたぐ予定を分割
   */
  private __toMinutesScheduleItemsConvertedDateTimeSplitByDay(items: ListScheduleResponse['items']): ScheduleItemConvertedDateTime[] {
    return items
      .filter(v => v.rangeType === 'MINUTES')
      .map(toScheduleItemConvertedDateTime)
      .flatMap((item) => item.start.hasSame(item.end, 'day')
        ? item
        : [
            {
              ...item,
              end: item.start.endOf('day'),
            },
            {
              ...item,
              start: item.end.startOf('day'),
            }
          ]
      );
  }

  private __toPcWeekMinutesItems(items: ListScheduleResponse['items']): PcScheduleMinutesItem[] {
    return this.__toMinutesScheduleItemsConvertedDateTimeSplitByDay(items)
      .map((item) => {
        const categoryType = item.category.type;
        switch (categoryType) {
          case 'ACTION':
            return new PcScheduleMinutesActionItem(
              new ScheduleID(item.category.id),
              item.category.status,
              new ScheduleName(item.name),
              new ScheduleStart(item.start.toJSDate()),
              new ScheduleEnd(item.end.toJSDate()),
              new ScheduleStart(item.originalStart.toJSDate()),
              new ScheduleEnd(item.originalEnd.toJSDate()),
            );
          case 'NURTURING':
            return new PcScheduleMinutesNurturingItem(
              new ScheduleID(item.category.id),
              item.category.status,
              new ScheduleName(item.name),
              new ScheduleStart(item.start.toJSDate()),
              new ScheduleEnd(item.end.toJSDate()),
              new ScheduleStart(item.originalStart.toJSDate()),
              new ScheduleEnd(item.originalEnd.toJSDate()),
            );
          case 'EVENT':
            return new PcScheduleMinutesEventItem(
              new ScheduleID(item.category.id),
              new ScheduleName(item.name),
              new ScheduleStart(item.start.toJSDate()),
              new ScheduleEnd(item.end.toJSDate()),
              new ScheduleStart(item.originalStart.toJSDate()),
              new ScheduleEnd(item.originalEnd.toJSDate()),
              new EventBaseStart(new Date(item.category.baseStart)),
              item.category.recurring,
            );
          default:
            const unexpected: never = categoryType;
            throw Error(`${unexpected} is unexpected category type.`);
        }
      });
  }

  private __toPcWeekDaysItems(
    items: ListScheduleResponse['items'],
    start: DateTime,
    end: DateTime
  ): PcScheduleDaysItem[] {
    return items
      .filter(v => v.rangeType === 'DAYS' && v.category.type === 'EVENT')
      .map((item)  => {
        const startDTOfItem = DateTime.fromJSDate(new Date(item.start));
        const startDT = startDTOfItem < start ? start : startDTOfItem;
        const endDTOfItem = DateTime.fromJSDate(new Date(item.end));
        const endDT = endDTOfItem > end ? end : endDTOfItem;
        const category = item.category as ListScheduleEventCategoryProps;
        // endは実際に表示したい日 + 1の値が返ってくるため、- 1する
        return new PcScheduleDaysEventItem(
          new ScheduleID(category.id),
          new ScheduleName(item.name),
          new ScheduleStart(startDT.toJSDate()),
          new ScheduleEnd(endDT.minus({ days: 1 }).toJSDate()),
          new ScheduleStart(startDTOfItem.toJSDate()),
          new ScheduleEnd(endDTOfItem.minus({ days: 1 }).toJSDate()),
          new EventBaseStart(new Date(category.baseStart)),
          category.recurring,
        )
      });
  }

  private __toPcMinutesItems(items: ListScheduleResponse['items']): PcScheduleMinutesItem[] {
    return items
      .filter(v => v.rangeType === 'MINUTES')
      .map((item) => {
        const categoryType = item.category.type;
        switch (categoryType) {
          case 'ACTION':
            return new PcScheduleMinutesActionItem(
              new ScheduleID(item.category.id),
              item.category.status,
              new ScheduleName(item.name),
              new ScheduleStart(new Date(item.start)),
              new ScheduleEnd(new Date(item.end)),
              new ScheduleStart(new Date(item.start)),
              new ScheduleEnd(new Date(item.end)),
            );
          case 'NURTURING':
            return new PcScheduleMinutesNurturingItem(
              new ScheduleID(item.category.id),
              item.category.status,
              new ScheduleName(item.name),
              new ScheduleStart(new Date(item.start)),
              new ScheduleEnd(new Date(item.end)),
              new ScheduleStart(new Date(item.start)),
              new ScheduleEnd(new Date(item.end)),
            );
          case 'EVENT':
            return new PcScheduleMinutesEventItem(
              new ScheduleID(item.category.id),
              new ScheduleName(item.name),
              new ScheduleStart(new Date(item.start)),
              new ScheduleEnd(new Date(item.end)),
              new ScheduleStart(new Date(item.start)),
              new ScheduleEnd(new Date(item.end)),
              new EventBaseStart(new Date(item.category.baseStart)),
              item.category.recurring,
            );
          default:
            const unexpected: never = categoryType;
            throw Error(`${unexpected} is unexpected category type.`);
        }
      });
  }

  private __toPcDaysItems(items: ListScheduleResponse['items']): PcScheduleDaysItem[] {
    return items
      .filter(v => v.rangeType === 'DAYS' && v.category.type === 'EVENT')
      .map((item)  => {
        const endMinusOneDay = DateTime.fromJSDate(new Date(item.end)).minus({ days: 1 }).toJSDate();
        const category = item.category as ListScheduleEventCategoryProps;
        return new PcScheduleDaysEventItem(
          new ScheduleID(category.id),
          new ScheduleName(item.name),
          new ScheduleStart(new Date(item.start)),
          new ScheduleEnd(endMinusOneDay),
          new ScheduleStart(new Date(item.start)),
          new ScheduleEnd(endMinusOneDay),
          new EventBaseStart(new Date(category.baseStart)),
          category.recurring,
        )
      });
  }

  private __toMbMinutesItems(items: ScheduleItemConvertedDateTime[]): MbScheduleMinutesItem[] {
    return items
      .map((item) => {
        const categoryType = item.category.type;
        switch (categoryType) {
          case 'ACTION':
            return new MbScheduleMinutesActionItem(
              new ScheduleID(item.category.id),
              item.category.status,
              new ScheduleName(item.name),
              new ScheduleStart(new Date(item.originalStart.toJSDate())),
              new ScheduleEnd(new Date(item.originalEnd.toJSDate())),
            );
          case 'NURTURING':
            return new MbScheduleMinutesNurturingItem(
              new ScheduleID(item.category.id),
              item.category.status,
              new ScheduleName(item.name),
              new ScheduleStart(new Date(item.originalStart.toJSDate())),
              new ScheduleEnd(new Date(item.originalEnd.toJSDate())),
            );
          case 'EVENT':
            return new MbScheduleMinutesEventItem(
              new ScheduleID(item.category.id),
              new ScheduleName(item.name),
              new ScheduleStart(new Date(item.originalStart.toJSDate())),
              new ScheduleEnd(new Date(item.originalEnd.toJSDate())),
              new EventBaseStart(new Date(item.category.baseStart)),
              item.category.recurring,
            );
          default:
            const unexpected: never = categoryType;
            throw Error(`${unexpected} is unexpected category type.`);
        }
      });
  }

  private __toMbDaysItems(items: ListScheduleResponse['items']): MbScheduleDaysItem[] {
    return items
      .filter(v => v.rangeType === 'DAYS' && v.category.type === 'EVENT')
      .map((item)  => {
        const startDT = DateTime.fromJSDate(new Date(item.start));
        const endDTMinusOneDay = DateTime.fromJSDate(new Date(item.end)).minus({ days: 1 });
        const dayDiff = endDTMinusOneDay.diff(startDT, 'days').days;
        const category = item.category as ListScheduleEventCategoryProps;
        return Array.from({ length: dayDiff + 1 }).map((_, i) => {
          const start = startDT.plus({ days: i });
          return new MbScheduleDaysEventItem(
            new ScheduleID(category.id),
            new ScheduleName(item.name),
            new ScheduleStart(start.toJSDate()),
            new ScheduleEnd(start.toJSDate()),
            new EventBaseStart(new Date(category.baseStart)),
            category.recurring,
          );
        });
      })
      .flat();
  }
}

type ScheduleItemConvertedDateTime = Omit<
  ListScheduleResponse['items'][number],
  'start' | 'end'
> & {
  start: DateTime,
  end: DateTime,
  originalStart: DateTime,
  originalEnd: DateTime,
}
function toScheduleItemConvertedDateTime(item: ListScheduleResponse['items'][number]): ScheduleItemConvertedDateTime {
  const start = DateTime.fromJSDate(new Date(item.start));
  const end = DateTime.fromJSDate(new Date(item.end));
  return {
    ...item,
    start,
    end,
    originalStart: start,
    originalEnd: end,
  }
}
