import { CoachingHistories, CoachingHistoryInsight, OpportunityCoachingHistoryQueryService } from "#application/services/opportunity-coaching-history-query.service";
import { ActionEndDateTime, ActionID, ActionStartDateTime } from "app/model/action/action";
import { CoachingID, CoachingTextAnswer, CoachingTiming } from "app/model/coaching/coaching";
import { InsightAnswer, InsightAnswerID, InsightAnswerSelectionType, InsightAnswerTextType, InsightID, InsightQuestion } from "app/model/insight/insight";
import { OpportunityID } from "app/model/opportunity/opportunity";
import { ServerApi } from "#infrastructure/api/server-api";
import { ApiCoachingHistoryInsight } from "#infrastructure/api/server-opportunity-coaching-history-api";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class OpportunityCoachingHistoryQueryServiceImpl extends OpportunityCoachingHistoryQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  get(opportunityID: OpportunityID): Observable<CoachingHistories> {
    return this.serverApi.opportunityCoachingHistoryApi
    .get(opportunityID.value)
    .pipe(
      map(v => v.entries.map(his => ({
        actionID: new ActionID(his.actionID),
        actionStartDateTime: new ActionStartDateTime(new Date(his.actionStartDateTime)),
        actionEndDateTime: new ActionEndDateTime(new Date(his.actionEndDateTime)),
        coaching: {
          pre: his.preCoaching === undefined
          ? {
            coachingID: undefined,
            timing: CoachingTiming.PRE,
            insights: [],
          }
          : {
            coachingID: new CoachingID(his.preCoaching.coachingID),
            timing: CoachingTiming.PRE,
            insights: his.preCoaching.history.map(v => this._convertInsight(v)),
          },
          post: his.postCoaching === undefined
          ? {
            coachingID: undefined,
            timing: CoachingTiming.POST,
            insights: [],
          }
          : {
            coachingID: new CoachingID(his.postCoaching.coachingID),
            timing: CoachingTiming.POST,
            insights: his.postCoaching.history.map(v => this._convertInsight(v)),
          },
        },
      })))
    );
  }

  private _convertInsight(res: ApiCoachingHistoryInsight): CoachingHistoryInsight {
    return {
      insight: {
        id: new InsightID(res.insight.id),
        question: new InsightQuestion(res.insight.question),
      },
      reply: this._convertReplyByType(res.reply),
    };
  }

  private _convertReplyByType(reply: ApiCoachingHistoryInsight['reply']): CoachingHistoryInsight['reply'] {
    switch (reply.type) {
      case 'SELECTION':
        return {
          type: InsightAnswerSelectionType['value'],
          content: {
            answerOptions: reply.content.answerOptions.map(v => ({
              id: new InsightAnswerID(v.id),
              answer: new InsightAnswer(v.answer),
            })),
          },
        }
      case 'TEXT':
        return {
          type: InsightAnswerTextType['value'],
          content: {
            value: new CoachingTextAnswer(reply.content.value),
          },
        }
      default:
        const unexpected: never = reply;
        throw Error(`${unexpected}は設定されていない値です。`);
    }
  }
}

