import { ConfirmService } from "#application/services/confirm.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { SnackBarService } from "#application/services/snack-bar.service";
import { AddUserGroupInputs, UserGroupAcyclicError, UserGroupCommandService, UserGroupTooManyError } from "#application/services/user-group-command.service";
import { UserGroupID } from "app/model/user-group/user-group";
import { ErrorResponseTitle, ServerApiError } from "#infrastructure/api/error";
import { ServerApi } from "#infrastructure/api/server-api";
import { Target, messageFn } from "#utils/messages";
import { Injectable, inject } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, concatMap, filter, tap } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class UserGroupCommandServiceImpl implements UserGroupCommandService {

  private readonly serverApi = inject(ServerApi);
  private readonly snackBarService = inject(SnackBarService);
  private readonly messageDialogService = inject(MessageDialogService);
  private readonly confirmService = inject(ConfirmService);

  add(inputs: AddUserGroupInputs): Observable<void | UserGroupTooManyError | UserGroupAcyclicError> {
    return this.serverApi.userGroupApi.create({
      name: inputs.name.value,
      groups: inputs.groups.map(group => group.value),
      users: inputs.users.map(user => user.value),
    })
    .pipe(
      tap(() => this.snackBarService.show(
        messageFn(Target.USER_GROUP, 'CREATE_SUCCESS').message
      )),
      catchError((err: ServerApiError) => {
        if (err.response.title === ErrorResponseTitle.TooManyUserGroupError) {
          this.messageDialogService.notice({
            message: `${Target.USER_GROUP}の登録に失敗しました。\nユーザーグループ数の上限に達したため、これ以上登録できません。`,
          });
          return of(new UserGroupTooManyError());
        }
        if (err.response.title === ErrorResponseTitle.AcyclicUserGroupError) {
          this.messageDialogService.notice({
            message: `${Target.USER_GROUP}の登録に失敗しました。\nこのユーザーグループを配下に持つユーザーグループを、自身の配下に含めることはできません。`,
          });
          return of(new UserGroupAcyclicError());
        }
        this.messageDialogService.notice({
          errorTarget: `${Target.USER_GROUP}の登録`
        });
        return throwError(err);
      }),
    );
  }

  update(id: UserGroupID, inputs: AddUserGroupInputs): Observable<void | UserGroupAcyclicError> {
    return this.serverApi.userGroupApi.update(id.value, {
      name: inputs.name.value,
      groups: inputs.groups.map(group => group.value),
      users: inputs.users.map(user => user.value),
    })
    .pipe(
      tap(() => this.snackBarService.show(
        messageFn(Target.USER_GROUP, 'UPDATE_SUCCESS').message
      )),
      catchError((err: ServerApiError) => {
        if (err.response.title === ErrorResponseTitle.AcyclicUserGroupError) {
          this.messageDialogService.notice({
            message: `${Target.USER_GROUP}の更新に失敗しました。\nこのユーザーグループを配下に持つユーザーグループを、自身の配下に含めることはできません。`,
          });
          return of(new UserGroupAcyclicError());
        }
        this.messageDialogService.notice({
          errorTarget: `${Target.USER_GROUP}の更新`
        });
        return throwError(err);
      }),
    );
  }

  delete(id: UserGroupID): Observable<void> {
    return this.confirmService.confirm({
      title: messageFn(Target.USER_GROUP, 'DELETE_CONFIRM').title,
      message: `
        ${messageFn(Target.USER_GROUP, 'DELETE_CONFIRM').message}\n
        ※このグループを子として持つ親グループが存在する場合、その親グループからも削除されます。
      `,
    })
    .pipe(
      filter(r => r.isOK()),
      concatMap(() => this.serverApi.userGroupApi.delete(id.value)),
      tap(() => this.snackBarService.show(
        messageFn(Target.USER_GROUP, 'DELETE_SUCCESS').message
      )),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: `${Target.USER_GROUP}の削除`
        });
        return throwError(err);
      }),
    );
  }
}
