import {Injectable} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {WebhookID} from "app/model/webhook/webhook";
import {
  AddWebhookInput,
  UpdateWebhookInput,
  WebhookCommandService
} from "#application/services/webhook-command.service";
import {ServerApi} from "#infrastructure/api/server-api";
import {MessageDialogService} from "#application/services/message-dialog.service";
import {SnackBarService} from "#application/services/snack-bar.service";
import {ConfirmService} from "#application/services/confirm.service";
import {catchError, concatMap, filter, tap} from "rxjs/operators";
import {messageFn, Target} from "#utils/messages";

@Injectable({providedIn: 'root'})
export class WebhookCommandServiceImpl extends WebhookCommandService {

  constructor(
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
    private readonly messageDialogService: MessageDialogService,
    private readonly confirmService: ConfirmService,
  ) {
    super();
  }

  add(input: AddWebhookInput): Observable<void> {
    return this.serverApi.webhookApi.add({
      name: input.name.value,
      uri: input.uri.value,
      object: {
        category: input.object.category,
        typeID: input.object.typeID.value
      },
      commands: [...input.commands.list]
    })
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(Target.WEBHOOK, 'CREATE_SUCCESS').message);
        }),
        catchError((err) => {
          this.messageDialogService.notice({
            message: messageFn(Target.WEBHOOK, 'CREATE_ERROR').message
          });
          return throwError(err);
        })
      );
  }

  update(input: UpdateWebhookInput): Observable<void> {
    return this.serverApi.webhookApi.update(
      input.id.value,
      {
        name: input.name.value,
        uri: input.uri.value,
        object: {
          category: input.object.category,
          typeID: input.object.typeID.value
        },
        commands: [...input.commands.list]
      })
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(Target.WEBHOOK, 'UPDATE_SUCCESS').message);
        }),
        catchError((err) => {
          this.messageDialogService.notice({
            message: messageFn(Target.WEBHOOK, 'UPDATE_ERROR').message
          });
          return throwError(err);
        })
      );
  }

  delete(id: WebhookID): Observable<void> {
    return this.confirmService
      .confirm({
        title: messageFn(Target.WEBHOOK, 'DELETE_CONFIRM').title,
        message: messageFn(Target.WEBHOOK, 'DELETE_CONFIRM').message
      })
      .pipe(
        filter(r => r.isOK()),
        concatMap(() => this.serverApi.webhookApi.delete(id.value)),
        tap(() => {
          this.snackBarService.show(messageFn(Target.WEBHOOK, 'DELETE_SUCCESS').message);
        }),
        catchError((err) => {
          this.messageDialogService.notice({
            title: messageFn(Target.WEBHOOK, 'DELETE_ERROR').title,
            message: messageFn(Target.WEBHOOK, 'DELETE_ERROR').message
          });
          return throwError(err);
        })
      );
  }

}
