import { Observable } from 'rxjs';

import { OpportunityTypeID } from 'app/model/opportunity-type/opportunity-type';
import { OpportunityHearingSettings } from './opportunity-hearing-setting';

/**
 * 商談ヒアリング設定のリポジトリ
 */
export abstract class OpportunityHearingSettingRepository {
  /**
   * 商談タイプに紐づく商談レイアウト設定を保存する
   *
   * @param opportunityTypeID
   * @param hearingSettings
   */
  abstract save(
    opportunityTypeID: OpportunityTypeID,
    hearingSettings: OpportunityHearingSettings
  ): Observable<void>;
}
