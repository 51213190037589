import {ClientCategory, ClientTypeID} from 'app/model/client-info-type/client-type';
import {CustomFieldId, CustomFieldValue} from 'app/model/custom-field/custom-field';
import { FieldType } from '../custom-field/field-type';

export type Client = Corporation | Individual;

// 正規表現が適切でないかもしれないが、以前からの仕様を変えないためにこのままにしている
const urlPattern = /^$|^(http:\/\/.{1,1993})$|^(https:\/\/.{1,1992})$/;

const kanaPattern = /^[ァ-ヴー]*$/;

abstract class BaseClient {
  protected constructor(
    public readonly id: ClientID,
    public readonly clientNumber: ClientNumber,
    public readonly name: ClientName,
    public readonly clientCategory: ClientCategory,
    public readonly clientTypeID: ClientTypeID,
    public readonly items: ClientItem[] | undefined,
    public readonly deleted: boolean,
  ) {
  }

  isCorporation(): this is Corporation {
    return this.clientCategory === ClientCategory.Corporation;
  };

  isIndividual(): this is Individual {
    return this.clientCategory === ClientCategory.Individual;
  };

  abstract changeName(name: ClientName): Client;

  abstract changeItems(items: ClientItem[]): Client;
}

export class ClientID {
  constructor(public readonly value: string) {
  }

  equals(id: ClientID): boolean {
    return this.value === id.value;
  }
}

export class ClientNumber {
  constructor(public readonly value: number) {
  }
}

export class ClientName {
  constructor(public readonly value: string) {
  }

  static readonly MAX_LENGTH = 255;
}

export class ClientItem {
  constructor(
    public readonly fieldId: CustomFieldId,
    public readonly fieldValue: CustomFieldValue,
  ) {
  }
}

export class Corporation extends BaseClient {
  constructor(
    id: ClientID,
    clientNumber: ClientNumber,
    name: ClientName,
    clientTypeID: ClientTypeID,
    public readonly corporationName: CorporationName,
    public readonly corporationNameKana: CorporationNameKana,
    public readonly corporateNumber: CorporateNumber | undefined,
    public readonly address: Address | undefined,
    public readonly phoneNumber: PhoneNumber | undefined,
    public readonly established: Established | undefined,
    public readonly numberOfEmployees: NumberOfEmployees | undefined,
    public readonly initialCapital: InitialCapital | undefined,
    public readonly fiscalYearEnd: FiscalYearEnd | undefined,
    public readonly website: Website | undefined,
    items: ClientItem[] | undefined,
    deleted: boolean,
  ) {
    super(id, clientNumber, name, ClientCategory.Corporation, clientTypeID, items, deleted);
  }

  changeName(name: ClientName): Corporation {
    return new Corporation(
      this.id,
      this.clientNumber,
      name,
      this.clientTypeID,
      this.corporationName,
      this.corporationNameKana,
      this.corporateNumber,
      this.address,
      this.phoneNumber,
      this.established,
      this.numberOfEmployees,
      this.initialCapital,
      this.fiscalYearEnd,
      this.website,
      this.items,
      this.deleted,
    );
  }

  changeCorporationName(corporationName: CorporationName): Corporation {
    return new Corporation(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      corporationName,
      this.corporationNameKana,
      this.corporateNumber,
      this.address,
      this.phoneNumber,
      this.established,
      this.numberOfEmployees,
      this.initialCapital,
      this.fiscalYearEnd,
      this.website,
      this.items,
      this.deleted,
    );
  }

  changeCorporationNameKana(
    corporationNameKana: CorporationNameKana
  ): Corporation {
    return new Corporation(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.corporationName,
      corporationNameKana,
      this.corporateNumber,
      this.address,
      this.phoneNumber,
      this.established,
      this.numberOfEmployees,
      this.initialCapital,
      this.fiscalYearEnd,
      this.website,
      this.items,
      this.deleted,
    );
  }

  changeCorporateNumber(corporateNumber: CorporateNumber | undefined): Corporation {
    return new Corporation(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.corporationName,
      this.corporationNameKana,
      corporateNumber,
      this.address,
      this.phoneNumber,
      this.established,
      this.numberOfEmployees,
      this.initialCapital,
      this.fiscalYearEnd,
      this.website,
      this.items,
      this.deleted,
    );
  }

  changeAddress(address: Address | undefined): Corporation {
    return new Corporation(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.corporationName,
      this.corporationNameKana,
      this.corporateNumber,
      address,
      this.phoneNumber,
      this.established,
      this.numberOfEmployees,
      this.initialCapital,
      this.fiscalYearEnd,
      this.website,
      this.items,
      this.deleted,
    );
  }

  changePhoneNumber(phoneNumber: PhoneNumber | undefined): Corporation {
    return new Corporation(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.corporationName,
      this.corporationNameKana,
      this.corporateNumber,
      this.address,
      phoneNumber,
      this.established,
      this.numberOfEmployees,
      this.initialCapital,
      this.fiscalYearEnd,
      this.website,
      this.items,
      this.deleted,
    );
  }

  changeEstablished(established: Established | undefined): Corporation {
    return new Corporation(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.corporationName,
      this.corporationNameKana,
      this.corporateNumber,
      this.address,
      this.phoneNumber,
      established,
      this.numberOfEmployees,
      this.initialCapital,
      this.fiscalYearEnd,
      this.website,
      this.items,
      this.deleted,
    );
  }

  changeNumberOfEmployees(numberOfEmployees: NumberOfEmployees | undefined): Corporation {
    return new Corporation(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.corporationName,
      this.corporationNameKana,
      this.corporateNumber,
      this.address,
      this.phoneNumber,
      this.established,
      numberOfEmployees,
      this.initialCapital,
      this.fiscalYearEnd,
      this.website,
      this.items,
      this.deleted,
    );
  }

  changeInitialCapital(initialCapital: InitialCapital | undefined): Corporation {
    return new Corporation(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.corporationName,
      this.corporationNameKana,
      this.corporateNumber,
      this.address,
      this.phoneNumber,
      this.established,
      this.numberOfEmployees,
      initialCapital,
      this.fiscalYearEnd,
      this.website,
      this.items,
      this.deleted,
    );
  }

  changeFiscalYearEnd(fiscalYearEnd: FiscalYearEnd | undefined): Corporation {
    return new Corporation(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.corporationName,
      this.corporationNameKana,
      this.corporateNumber,
      this.address,
      this.phoneNumber,
      this.established,
      this.numberOfEmployees,
      this.initialCapital,
      fiscalYearEnd,
      this.website,
      this.items,
      this.deleted,
    );
  }

  changeWebsite(website: Website | undefined): Corporation {
    return new Corporation(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.corporationName,
      this.corporationNameKana,
      this.corporateNumber,
      this.address,
      this.phoneNumber,
      this.established,
      this.numberOfEmployees,
      this.initialCapital,
      this.fiscalYearEnd,
      website,
      this.items,
      this.deleted,
    );
  }

  changeItems(items: ClientItem[]): Corporation {
    return new Corporation(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.corporationName,
      this.corporationNameKana,
      this.corporateNumber,
      this.address,
      this.phoneNumber,
      this.established,
      this.numberOfEmployees,
      this.initialCapital,
      this.fiscalYearEnd,
      this.website,
      items,
      this.deleted,
    );
  }
}

export class CorporationName {
  constructor(public readonly value: string) {
  }

  readonly fieldType = FieldType.SINGLE_LINE_TEXT;
  static readonly MAX_LENGTH = 255;
}

export class CorporationNameKana {
  constructor(public readonly value: string) {
  }

  readonly fieldType = FieldType.SINGLE_LINE_TEXT;
  static readonly MAX_LENGTH = 255;
  static readonly PATTERN = kanaPattern;
}

export class CorporateNumber {
  constructor(public readonly value: string) {
  }

  readonly fieldType = FieldType.SINGLE_LINE_TEXT;
  static readonly DIGITS_LENGTH = 13;
}

export class Address {
  constructor(public readonly value: string) {
  }

  readonly fieldType = FieldType.ADDRESS;
  static readonly MAX_LENGTH = 255;
}

export class PhoneNumber {
  constructor(public readonly value: string) {
  }

  readonly fieldType = FieldType.PHONE_NUMBER;
  static readonly PATTERN = /^$|^(?=.*\d)[0-9\-]{1,20}$/;
}

export class Established {
  constructor(public readonly value: number) {
  }

  readonly fieldType = FieldType.NUMBER;
  static readonly MIN = 1;
  static readonly MAX = 9999;
}

export class NumberOfEmployees {
  constructor(public readonly value: number) {
  }

  readonly fieldType = FieldType.NUMBER;
  static readonly MIN = 1;
}

export class InitialCapital {
  constructor(public readonly value: number) {
  }

  readonly fieldType = FieldType.NUMBER;
  static readonly MIN = 1;
}

export class FiscalYearEnd {
  constructor(public readonly value: number) {
  }

  readonly fieldType = FieldType.NUMBER;
  static readonly MIN = 1;
  static readonly MAX = 12;
  static readonly DEFAULT_VALUE = 3;
}

export class Website {
  constructor(public readonly value: string) {
  }

  readonly fieldType = FieldType.URL;
  static readonly PATTERN = urlPattern;
}

export class Individual extends BaseClient {
  constructor(
    id: ClientID,
    clientNumber: ClientNumber,
    name: ClientName,
    clientTypeID: ClientTypeID,
    public readonly firstName: FirstName,
    public readonly lastName: LastName,
    public readonly firstNameKana: FirstNameKana,
    public readonly lastNameKana: LastNameKana,
    public readonly address: Address | undefined,
    public readonly phoneNumber: PhoneNumber | undefined,
    public readonly sns1: SNS | undefined,
    public readonly sns2: SNS | undefined,
    items: ClientItem[] | undefined,
    deleted: boolean,
  ) {
    super(id, clientNumber, name, ClientCategory.Individual, clientTypeID, items, deleted);
  }

  changeName(name: ClientName): Individual {
    return new Individual(
      this.id,
      this.clientNumber,
      name,
      this.clientTypeID,
      this.firstName,
      this.lastName,
      this.firstNameKana,
      this.lastNameKana,
      this.address,
      this.phoneNumber,
      this.sns1,
      this.sns2,
      this.items,
      this.deleted,
    );
  }

  changeFirstName(firstName: FirstName): Individual {
    return new Individual(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      firstName,
      this.lastName,
      this.firstNameKana,
      this.lastNameKana,
      this.address,
      this.phoneNumber,
      this.sns1,
      this.sns2,
      this.items,
      this.deleted,
    );
  }

  changeLastName(lastName: LastName): Individual {
    return new Individual(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.firstName,
      lastName,
      this.firstNameKana,
      this.lastNameKana,
      this.address,
      this.phoneNumber,
      this.sns1,
      this.sns2,
      this.items,
      this.deleted,
    );
  }

  changeFirstNameKana(firstNameKana: FirstNameKana): Individual {
    return new Individual(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.firstName,
      this.lastName,
      firstNameKana,
      this.lastNameKana,
      this.address,
      this.phoneNumber,
      this.sns1,
      this.sns2,
      this.items,
      this.deleted,
    );
  }

  changeLastNameKana(lastNameKana: LastNameKana): Individual {
    return new Individual(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.firstName,
      this.lastName,
      this.firstNameKana,
      lastNameKana,
      this.address,
      this.phoneNumber,
      this.sns1,
      this.sns2,
      this.items,
      this.deleted,
    );
  }

  changeAddress(address: Address | undefined): Individual {
    return new Individual(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.firstName,
      this.lastName,
      this.firstNameKana,
      this.lastNameKana,
      address,
      this.phoneNumber,
      this.sns1,
      this.sns2,
      this.items,
      this.deleted,
    );
  }

  changePhoneNumber(phoneNumber: PhoneNumber | undefined): Individual {
    return new Individual(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.firstName,
      this.lastName,
      this.firstNameKana,
      this.lastNameKana,
      this.address,
      phoneNumber,
      this.sns1,
      this.sns2,
      this.items,
      this.deleted,
    );
  }

  changeSns1(sns1: SNS | undefined): Individual {
    return new Individual(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.firstName,
      this.lastName,
      this.firstNameKana,
      this.lastNameKana,
      this.address,
      this.phoneNumber,
      sns1,
      this.sns2,
      this.items,
      this.deleted,
    );
  }

  changeSns2(sns2: SNS | undefined): Individual {
    return new Individual(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.firstName,
      this.lastName,
      this.firstNameKana,
      this.lastNameKana,
      this.address,
      this.phoneNumber,
      this.sns1,
      sns2,
      this.items,
      this.deleted,
    );
  }

  changeItems(items: ClientItem[]): Individual {
    return new Individual(
      this.id,
      this.clientNumber,
      this.name,
      this.clientTypeID,
      this.firstName,
      this.lastName,
      this.firstNameKana,
      this.lastNameKana,
      this.address,
      this.phoneNumber,
      this.sns1,
      this.sns2,
      items,
      this.deleted,
    );
  }
}

export class FirstName {
  constructor(public readonly value: string) {
  }

  readonly fieldType = FieldType.SINGLE_LINE_TEXT;
  static readonly MAX_LENGTH = 255;
}

export class LastName {
  constructor(public readonly value: string) {
  }

  readonly fieldType = FieldType.SINGLE_LINE_TEXT;
  static readonly MAX_LENGTH = 255;
}

export class FirstNameKana {
  constructor(public readonly value: string) {
  }

  readonly fieldType = FieldType.SINGLE_LINE_TEXT;
  static readonly MAX_LENGTH = 255;
  static readonly PATTERN = kanaPattern;
}


export class LastNameKana {
  constructor(public readonly value: string) {
  }

  readonly fieldType = FieldType.SINGLE_LINE_TEXT;
  static readonly MAX_LENGTH = 255;
  static readonly PATTERN = kanaPattern;
}

export class SNS {
  constructor(public readonly value: string) {
  }

  readonly fieldType = FieldType.URL;
  static readonly PATTERN = urlPattern;
}
