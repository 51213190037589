import {Observable} from 'rxjs';
import {OpportunityTypeDescription, OpportunityTypeName, OpportunityTypeID} from './opportunity-type';
import {FormLayoutId} from "app/model/form-layout/form-layout";

export interface UpdateOpportunityTypeParam {
  id: OpportunityTypeID,
  name: OpportunityTypeName,
  description: OpportunityTypeDescription,
  formLayoutID?: FormLayoutId,
}

/**
 * 商談タイプリポジトリ
 */
export abstract class OpportunityTypeRepository {
  abstract remove(opportunityTypeID: OpportunityTypeID): Observable<void>;

  abstract saveOrders(ids: OpportunityTypeID[]): Observable<void>;

  abstract save(param: UpdateOpportunityTypeParam): Observable<void>;
}
