import { MessageDialogService } from "#application/services/message-dialog.service";
import { AmountReportOpportunityOutputs, CountReportOpportunityOutputs, GetBySalesRecordReportOpportunityOutputs, GetPerUserBySalesRecordReportOpportunityOutputs, GetPerUserReportOpportunityInputs, GetPerUserReportOpportunityOutputs, GetPersonalBySalesRecordReportOpportunityOutputs, GetPersonalReportOpportunityInputs, GetPersonalReportOpportunityOutputs, GetReportOpportunityInputs, GetReportOpportunityOutputs, ReportOpportunityV2QueryService } from "#application/services/report-opportunity-v2-query.service";
import { OpportunityTypeID, OpportunityTypeName } from "app/model/opportunity-type/opportunity-type";
import { DisplayName, UserID } from "app/model/user/user";
import { ServerApi } from "#infrastructure/api/server-api";
import { AmountInReportOpportunityResponse, CountInReportOpportunityResponse, GetBySalesRecordReportOpportunityV2Response, GetPerUserBySalesRecordReportOpportunityV2Response, GetPerUserReportOpportunityV2Request, GetPerUserReportOpportunityV2Response, GetPersonalBySalesRecordReportOpportunityResponse, GetPersonalReportOpportunityRequest, GetReportOpportunityV2Request, GetReportOpportunityV2Response } from "#infrastructure/api/server-report-opportunity-v2-api";
import { Injectable, inject } from "@angular/core";
import { DateTime } from "luxon";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class ReportOpportunityV2QueryServiceImpl implements ReportOpportunityV2QueryService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _messageDialogService = inject(MessageDialogService);

  get(inputs: GetReportOpportunityInputs): Observable<GetReportOpportunityOutputs> {
    return this._serverApi.reportOpportunityV2Api
      .get(ReportOpportunityV2QueryServiceConvertor.toGetRequest(inputs))
      .pipe(
        map(ReportOpportunityV2QueryServiceConvertor.toGetOutputs),
        catchError((err) => {
          this._messageDialogService.notice({
            errorTarget: '商談レポートの取得',
          });
          return throwError(err);
        }),
      );
  }

  getPerUser(inputs: GetPerUserReportOpportunityInputs): Observable<GetPerUserReportOpportunityOutputs> {
    return this._serverApi.reportOpportunityV2Api
      .getPerUser(ReportOpportunityV2QueryServiceConvertor.toGetPerUserRequest(inputs))
      .pipe(
        map(ReportOpportunityV2QueryServiceConvertor.toGetPerUserOutputs),
        catchError((err) => {
          this._messageDialogService.notice({
            errorTarget: '商談レポートの取得',
          });
          return throwError(err);
        }),
      );
  }

  getPersonal(input: GetPersonalReportOpportunityInputs): Observable<GetPerUserReportOpportunityOutputs> {
    return this._serverApi.reportOpportunityV2Api
      .getPersonal(ReportOpportunityV2QueryServiceConvertor.toGetPersonalRequest(input))
      .pipe(
        map(ReportOpportunityV2QueryServiceConvertor.toGetPerUserOutputs),
        catchError((err) => {
          this._messageDialogService.notice({
            errorTarget: '商談レポートの取得',
          });
          return throwError(err);
        }),
      );
  }

  getBySalesRecord(inputs: GetReportOpportunityInputs): Observable<GetBySalesRecordReportOpportunityOutputs> {
    return this._serverApi.reportOpportunityV2Api
      .getBySalesRecord(ReportOpportunityV2QueryServiceConvertor.toGetRequest(inputs))
      .pipe(
        map(ReportOpportunityV2QueryServiceConvertor.toGetBySalesRecordOutputs),
        catchError((err) => {
          this._messageDialogService.notice({
            errorTarget: '商談レポートの取得',
          });
          return throwError(err);
        }),
      );
  }

  getPerUserBySalesRecord(inputs: GetPerUserReportOpportunityInputs): Observable<GetPerUserBySalesRecordReportOpportunityOutputs> {
    return this._serverApi.reportOpportunityV2Api
      .getPerUserBySalesRecord(ReportOpportunityV2QueryServiceConvertor.toGetPerUserRequest(inputs))
      .pipe(
        map(ReportOpportunityV2QueryServiceConvertor.toGetPerUserBySalesRecordOutputs),
        catchError((err) => {
          this._messageDialogService.notice({
            errorTarget: '商談レポートの取得',
          });
          return throwError(err);
        }),
      );
  }

  getPersonalBySalesRecord(input: GetPersonalReportOpportunityInputs): Observable<GetPersonalBySalesRecordReportOpportunityOutputs> {
    return this._serverApi.reportOpportunityV2Api
      .getPersonalBySalesRecord(ReportOpportunityV2QueryServiceConvertor.toGetPersonalRequest(input))
      .pipe(
        map(ReportOpportunityV2QueryServiceConvertor.toGetPerUserBySalesRecordOutputs),
        catchError((err) => {
          this._messageDialogService.notice({
            errorTarget: '商談レポートの取得',
          });
          return throwError(err);
        }),
      );
  }
}

export namespace ReportOpportunityV2QueryServiceConvertor {
  export function toGetRequest(inputs: GetReportOpportunityInputs): GetReportOpportunityV2Request {
    return {
      duration: inputs.duration.type === 'DAILY'
        ? {
          type: 'DAILY',
          startDate: inputs.duration.startDate.toFormat('yyyy-MM-dd HH:mm:ss'),
          days: inputs.duration.days,
        }
        : {
          type: 'MONTHLY',
          startYearMonth: inputs.duration.startYearMonth.toFormat('yyyy-MM'),
          months: inputs.duration.months,
        },
      opportunityTypeIDs: inputs.opportunityTypeIDs.map(v => v.value),
      assignee: inputs.assignee.type === 'ALL_USERS'
        ? {
          type: 'ALL_USERS',
        }
        : {
          type: 'USER_GROUP',
          userGroupID: inputs.assignee.userGroupID.value,
        },
      status: inputs.status,
    };
  }

  export function toGetPerUserRequest(inputs: GetPerUserReportOpportunityInputs): GetPerUserReportOpportunityV2Request {
    return {
      duration: inputs.duration.type === 'DAILY'
        ? {
          type: 'DAILY',
          startDate: inputs.duration.startDate.toFormat('yyyy-MM-dd HH:mm:ss'),
          days: inputs.duration.days,
        }
        : {
          type: 'MONTHLY',
          startYearMonth: inputs.duration.startYearMonth.toFormat('yyyy-MM'),
          months: inputs.duration.months,
        },
      opportunityTypeIDs: inputs.opportunityTypeIDs.map(v => v.value),
      userID: inputs.userID.value,
      status: inputs.status,
    };
  }

  export function toGetPersonalRequest(inputs: GetPersonalReportOpportunityInputs): GetPersonalReportOpportunityRequest {
    return {
      duration: inputs.duration.type === 'DAILY'
        ? {
          type: 'DAILY',
          startDate: inputs.duration.startDate.toFormat('yyyy-MM-dd HH:mm:ss'),
          days: inputs.duration.days,
        }
        : {
          type: 'MONTHLY',
          startYearMonth: inputs.duration.startYearMonth.toFormat('yyyy-MM'),
          months: inputs.duration.months,
        },
      opportunityTypeIDs: inputs.opportunityTypeIDs.map(v => v.value),
      status: inputs.status,
    };
  }

  export function toAmount(res: AmountInReportOpportunityResponse): AmountReportOpportunityOutputs {
    return {
      opportunityTypes: res.opportunityTypes.map(opportunityType => ({
        id: new OpportunityTypeID(opportunityType.id),
        name: new OpportunityTypeName(opportunityType.name),
        opportunityStatuses: opportunityType.opportunityStatuses.map(opportunityStatus => ({
          name: opportunityStatus.name,
          amounts: opportunityStatus.amounts.map(amount => ({
            date: DateTime.fromFormat(amount.date, 'yyyy-MM-dd hh:mm:ss'),
            value: amount.value,
          })),
          totalAmount: opportunityStatus.totalAmount,
        })),
      })),
      opportunityTypeTotal: {
        opportunityStatuses: res.opportunityTypeTotal.opportunityStatuses.map(opportunityStatus => ({
          name: opportunityStatus.name,
          amounts: opportunityStatus.amounts.map(amount => ({
            date: DateTime.fromFormat(amount.date, 'yyyy-MM-dd hh:mm:ss'),
            value: amount.value,
          })),
          totalAmount: opportunityStatus.totalAmount,
        })),
      },
    };
  }

  export function toCount(res: CountInReportOpportunityResponse): CountReportOpportunityOutputs {
    return {
      opportunityTypes: res.opportunityTypes.map(opportunityType => ({
        id: new OpportunityTypeID(opportunityType.id),
        name: new OpportunityTypeName(opportunityType.name),
        opportunityStatuses: opportunityType.opportunityStatuses.map(opportunityStatus => ({
          name: opportunityStatus.name,
          counts: opportunityStatus.counts.map(count => ({
            date: DateTime.fromFormat(count.date, 'yyyy-MM-dd hh:mm:ss'),
            value: count.value,
          })),
          totalCount: opportunityStatus.totalCount,
        })),
      })),
      opportunityTypeTotal: {
        opportunityStatuses: res.opportunityTypeTotal.opportunityStatuses.map(opportunityStatus => ({
          name: opportunityStatus.name,
          counts: opportunityStatus.counts.map(count => ({
            date: DateTime.fromFormat(count.date, 'yyyy-MM-dd hh:mm:ss'),
            value: count.value,
          })),
          totalCount: opportunityStatus.totalCount,
        })),
      },
    };
  }

  export function toGetOutputs(res: GetReportOpportunityV2Response): GetReportOpportunityOutputs {
    return {
      amount: ReportOpportunityV2QueryServiceConvertor.toAmount(res.amount),
      amountsPerUser: res.amountsPerUser.map(amountPerUser => ({
        user: {
          id: new UserID(amountPerUser.user.id),
          name: new DisplayName(amountPerUser.user.name),
          isActive: amountPerUser.user.isActive,
        },
        amount: ReportOpportunityV2QueryServiceConvertor.toAmount(amountPerUser.amount),
      })),
      count: ReportOpportunityV2QueryServiceConvertor.toCount(res.count),
      countsPerUser: res.countsPerUser.map(countPerUser => ({
        user: {
          id: new UserID(countPerUser.user.id),
          name: new DisplayName(countPerUser.user.name),
          isActive: countPerUser.user.isActive,
        },
        count: ReportOpportunityV2QueryServiceConvertor.toCount(countPerUser.count),
      })),
    };
  }

  export function toGetPerUserOutputs(
    res: GetPerUserReportOpportunityV2Response
  ): GetPerUserReportOpportunityOutputs | GetPersonalReportOpportunityOutputs {
    return {
      amount: ReportOpportunityV2QueryServiceConvertor.toAmount(res.amount),
      count: ReportOpportunityV2QueryServiceConvertor.toCount(res.count),
    };
  }

  export function toGetBySalesRecordOutputs(res: GetBySalesRecordReportOpportunityV2Response): GetBySalesRecordReportOpportunityOutputs {
    return {
      amount: ReportOpportunityV2QueryServiceConvertor.toAmount(res.amount),
      amountsPerUser: res.amountsPerUser.map(amountPerUser => ({
        user: {
          id: new UserID(amountPerUser.user.id),
          name: new DisplayName(amountPerUser.user.name),
          isActive: amountPerUser.user.isActive,
        },
        amount: ReportOpportunityV2QueryServiceConvertor.toAmount(amountPerUser.amount),
      })),
    };
  }

  export function toGetPerUserBySalesRecordOutputs(
    res: GetPerUserBySalesRecordReportOpportunityV2Response | GetPersonalBySalesRecordReportOpportunityResponse
  ): GetPerUserBySalesRecordReportOpportunityOutputs | GetPersonalBySalesRecordReportOpportunityOutputs {
    return {
      amount: ReportOpportunityV2QueryServiceConvertor.toAmount(res.amount),
    };
  }
}
