import {ConfirmService} from "#application/services/confirm.service";
import {MessageDialogService} from "#application/services/message-dialog.service";
import {
  AddRestAuthTokenInputs,
  AddRestAuthTokenKeyOutputs,
  AddRestAuthTokenOutputs,
  DeleteRestAuthTokenKeyInputs,
  RestAuthTokenCommandService,
  UpdateRestAuthTokenInputs
} from "#application/services/rest-auth-token-command.service";
import {SnackBarService} from "#application/services/snack-bar.service";
import {RestAuthTokenID, RestAuthTokenString} from "app/model/rest-auth-token/rest-auth-token";
import { ServerApiError, ServerApiErrorCode } from "#infrastructure/api/error";
import {ServerApi} from "#infrastructure/api/server-api";
import {messageFn, Target} from "#utils/messages";
import {inject, Injectable} from "@angular/core";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Failure, Result, Success } from "app/lib/result/result";
import {NEVER, Observable, of, throwError} from "rxjs";
import {catchError, concatMap, filter, map, tap} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class RestAuthTokenCommandServiceImpl implements RestAuthTokenCommandService {
  private readonly serverApi = inject(ServerApi);
  private readonly snackBarService = inject(SnackBarService);
  private readonly confirmService = inject(ConfirmService);
  private readonly messageDialogService = inject(MessageDialogService);

  add(inputs: AddRestAuthTokenInputs): Observable<AddRestAuthTokenOutputs> {
    return this.serverApi.restAuthTokenApi.create({
      name: inputs.name.value,
      subjectID: inputs.subjectID.value,
    })
      .pipe(
        map(res => ({tokenString: new RestAuthTokenString(res.tokenString)})),
        tap(() => {
          this.snackBarService.show(messageFn(Target.REST_AUTH_TOKEN, 'CREATE_SUCCESS').message);
        }),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '認証トークンの登録'
          });
          return throwError(err);
        })
      );
  }

  update(id: RestAuthTokenID, inputs: UpdateRestAuthTokenInputs): Observable<Result<
    void,
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Conflict
  >> {
    return this.serverApi.restAuthTokenApi.update(id.value, {
      name: inputs.name.value,
      subjectID: inputs.subjectID.value,
    })
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(Target.REST_AUTH_TOKEN, 'UPDATE_SUCCESS').message);
        }),
        map((v) => Success(v)),
        catchError((e: ServerApiError) => {
          if (e.code === ServerApiErrorCode.NotFound) {
            return of(Failure(GeneralFailure.NotFound));
          }
          if (e.code === ServerApiErrorCode.Conflict) {
            return of(Failure(GeneralFailure.Conflict));
          }
          this.messageDialogService.notice({
            errorTarget: `${Target.REST_AUTH_TOKEN}の更新`
          });
          return NEVER;
        })
      );
  }

  delete(id: RestAuthTokenID): Observable<void> {
    return this.confirmService.confirm({
      title: messageFn(Target.REST_AUTH_TOKEN, 'DELETE_CONFIRM').title,
      message: messageFn(Target.REST_AUTH_TOKEN, 'DELETE_CONFIRM').message,
    }).pipe(
      filter(r => r.isOK()),
      concatMap(() => this.serverApi.restAuthTokenApi.delete(id.value)),
      tap(() => this.snackBarService.show(messageFn(Target.REST_AUTH_TOKEN, 'DELETE_SUCCESS').message)),
      catchError(err => {
        this.messageDialogService.notice({
          title: messageFn(Target.REST_AUTH_TOKEN, 'DELETE_ERROR').title,
          message: messageFn(Target.REST_AUTH_TOKEN, 'DELETE_ERROR').message,
        });
        return throwError(err);
      })
    );
  }

  addKey(id: RestAuthTokenID): Observable<AddRestAuthTokenKeyOutputs> {
    return this.serverApi.restAuthTokenApi.createKey(id.value)
      .pipe(
        map(res => ({tokenString: new RestAuthTokenString(res.tokenString)})),
        tap(() => {
          this.snackBarService.show(messageFn(Target.REST_AUTH_TOKEN_KEY, 'CREATE_SUCCESS').message);
        }),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '認証トークンキーの登録'
          });
          return throwError(err);
        })
      );
  }

  deleteKey(inputs: DeleteRestAuthTokenKeyInputs): Observable<void> {
    return this.confirmService.confirm({
      title: messageFn(Target.REST_AUTH_TOKEN_KEY, 'DELETE_CONFIRM').title,
      message: messageFn(Target.REST_AUTH_TOKEN_KEY, 'DELETE_CONFIRM').message,
    }).pipe(
      filter(r => r.isOK()),
      concatMap(() => this.serverApi.restAuthTokenApi.deleteKey(inputs.id.value, inputs.key)),
      tap(() => this.snackBarService.show(messageFn(Target.REST_AUTH_TOKEN_KEY, 'DELETE_SUCCESS').message)),
      catchError(err => {
        this.messageDialogService.notice({
          title: messageFn(Target.REST_AUTH_TOKEN_KEY, 'DELETE_ERROR').title,
          message: messageFn(Target.REST_AUTH_TOKEN_KEY, 'DELETE_ERROR').message,
        });
        return throwError(err);
      })
    );
  }
}
