import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

export type ProductRelatedClientsRequest = {
  perPage: number;
  page: number;
};

export type ProductRelatedClientListResponse = {
  totalCount: number;
  results: ProductRelatedClientListResponseItem[];
};

export interface ProductRelatedClientListResponseItem {
  clientProductRelationshipID: string,
  clientID: string,
  clientNumber: number,
  clientName: string,
  clientTypeName: string,
  clientCategory: 'CORPORATION' | 'INDIVIDUAL'
}

export class ProductRelatedClientsApi {
  constructor(private readonly http: HttpClient) {}

  list(productID: string, request: ProductRelatedClientsRequest): Observable<ProductRelatedClientListResponse> {
    return this.http.post<ProductRelatedClientListResponse>(
      `/api/products/${productID}/related-client-list`, request);
  }
}
