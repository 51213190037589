import {Observable} from 'rxjs';
import {concatMap, mapTo, tap} from 'rxjs/operators';

import {Injectable} from '@angular/core';

import {SnackBarService} from './snack-bar.service';
import {Page, PageNavigateService} from './page-navigate.service';
import {OrganizationFactory} from 'app/model/organization/organization.factory';
import {OrganizationRepository} from 'app/model/organization/organization.repository';
import {
  IsCoachingAvailable,
  FiscalYearBegin,
  Organization,
  OrganizationCode,
  OrganizationID,
  OrganizationName,
  IsProposalEnabled,
  IsLinkageEnabled,
  IsOpportunityGroupEnabled,
} from 'app/model/organization/organization';

/**
 * 組織管理サービス
 */
@Injectable({providedIn: 'root'})
export class OrganizationManageService {
  constructor(
    private snackBarService: SnackBarService,
    private pageNavigateService: PageNavigateService,
    private organizationFactory: OrganizationFactory,
    private organizationRepository: OrganizationRepository,
  ) {
  }

  getOrganizations(): Observable<Organization[]> {
    return this.organizationRepository.findAll();
  }

  getOrganizationDetail(organizationID: string): Observable<Organization> {
    return this.organizationRepository.findByID(new OrganizationID(organizationID));
  }

  addOrganization(
    code: string,
    name: string,
    fiscalYearBegin: number,
  ): Observable<void> {
    return this.organizationFactory
      .create(
        new OrganizationCode(code),
        new OrganizationName(name),
        new FiscalYearBegin(fiscalYearBegin),
      )
      .pipe(
        concatMap(() => this.pageNavigateService.navigate(Page.AdminOrganization)),
        mapTo(undefined)
      );
  }

  deleteOrganization(organizationID: string): Observable<void> {
    return this.organizationRepository.delete(new OrganizationID(organizationID)).pipe(
      tap(() => {
        this.pageNavigateService.navigate(Page.AdminOrganization);
        this.snackBarService.show('組織情報を削除しました');
      })
    );
  }

  updateOrganization(
    organizationID: string,
    organizationName: string,
    fiscalYearBegin: number,
    coachingAvailable: IsCoachingAvailable,
    proposalEnabled: IsProposalEnabled,
    linkageEnabled: IsLinkageEnabled,
    opportunityGroupEnabled: IsOpportunityGroupEnabled,
  ): Observable<void> {
    return this.organizationRepository.findByID(new OrganizationID(organizationID)).pipe(
      concatMap(organization => {
        const changedOrganization = organization
          .rename(new OrganizationName(organizationName))
          .changeFiscalYearBegin(new FiscalYearBegin(fiscalYearBegin))
          .changeCoachingAvailable(coachingAvailable)
          .changeProposalEnabled(proposalEnabled)
          .changeLinkageEnabled(linkageEnabled)
          .changeOpportunityGroupEnabled(opportunityGroupEnabled);
        return this.organizationRepository.save(changedOrganization);
      }),
      tap(() => {
        this.pageNavigateService.navigate(Page.AdminOrganization);
        this.snackBarService.show('組織情報を更新しました');
      })
    );
  }
}
