import { DisplayName, UserID } from "app/model/user/user";

export class UserGroupID {
  constructor(public readonly value: string) {}

  equals(another: UserGroupID){
    return this.value === another.value
  }
}

export class UserGroupName {
  constructor(public readonly value: string) {}

  static readonly MAX_LENGTH = 30;
}

export class UserGroupUserCount {
  constructor(public readonly value: number) {}
}

export class UserGroupUpdatedAt {
  constructor(public readonly value: Date) {}
}

export type UserGroup = Readonly<{
  id: UserGroupID,
  name: UserGroupName,
  groups: {
    id: UserGroupID,
    name: UserGroupName,
  }[],
  users: {
    id: UserID,
    name: DisplayName,
  }[],
}>

export class UserGroupListTotalCount {
  constructor(public readonly value: number) {}

  static readonly MAX_COUNT = 100;

  moreThanMaxCount(): boolean {
    return this.value >= UserGroupListTotalCount.MAX_COUNT;
  }
}

export type UserGroupListItem = (UserGroup & Readonly<{
  userCount: UserGroupUserCount,
  creator: {
    id: UserID,
    name: DisplayName,
  },
  updatedAt: UserGroupUpdatedAt,
}>)

export type UserGroupListResult = Readonly<{
  totalCount: UserGroupListTotalCount,
  list: UserGroupListItem[]
}>

export type AllUserGroupListItem = Pick<
  UserGroupListItem,
  'id' | 'name' | 'userCount'
>

export type AllUserGroupList = AllUserGroupListItem[]
