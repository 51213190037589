import { FormLayoutId } from 'app/model/form-layout/form-layout';

/**
 * 商品タイプ
 */
 export class ProductType {
  constructor(
    public readonly id: ProductTypeId,
    public readonly name: ProductTypeName,
    public readonly description: ProductTypeDescription,
    public readonly formLayoutId: FormLayoutId
  ) {}
}

/**
 * 商品タイプID
 */
export class ProductTypeId {
  constructor(
    public readonly value: string
  ) {}
}

/**
 * 商品タイプ名
 */
export class ProductTypeName {
  static readonly MAX_LENGTH = 30;
  constructor(
    public readonly value: string
  ) {}
}

/**
 * 商品タイプ説明
 */
export class ProductTypeDescription {
  static readonly MAX_LENGTH = 100;
  constructor(
    public readonly value: string
  ) {}
}
