import { ActionAttendees, ActionEndDateTime, ActionID, ActionNote, ActionStartDateTime } from 'app/model/action/action';
import { ActionTypeID } from 'app/model/action/action-type';
import { ActionFactory } from 'app/model/action/action.factory';
import { Companions } from 'app/model/companion/companion';
import { OpportunityID, OpportunityItem, } from 'app/model/opportunity/opportunity';
import { PhaseID } from 'app/model/sales-phase/sales-phase';
import { ServerApi } from '#infrastructure/api/server-api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { convertOpportunityItemsToKeyValueItems } from './opportunity.factory';

@Injectable({providedIn: 'root'})
export class ActionFactoryImpl implements ActionFactory {
  constructor(private readonly serverApi: ServerApi) {
  }

  create(
    opportunityID: OpportunityID,
    companions: Companions,
    attendees: ActionAttendees,
    currentPhaseID: PhaseID,
    startPhaseID: PhaseID,
    targetPhaseID: PhaseID,
    actionTypeID: ActionTypeID,
    actionStartDateTime: ActionStartDateTime,
    actionEndDateTime: ActionEndDateTime,
    note: ActionNote,
    items: OpportunityItem[]
  ): Observable<ActionID> {
    return this.serverApi.actionApi.createAction({
      opportunityID: opportunityID.value,
      companions: companions.map(companion => {
        return {userID: companion.userID.value};
      }),
      attendees: attendees.list.map(attendee => {
        return {contactID: attendee.contactID.value};
      }),
      currentPhaseID: currentPhaseID.value,
      startPhaseID: startPhaseID.value,
      targetPhaseID: targetPhaseID.value,
      actionTypeID: actionTypeID.value,
      actionStartDateTime: actionStartDateTime.format('yyyy-MM-dd HH:mm:ss'),
      actionEndDateTime: actionEndDateTime.format('yyyy-MM-dd HH:mm:ss'),
      actionNote: note.value,
      items: convertOpportunityItemsToKeyValueItems(items)
    })
    .pipe(
      map(res => new ActionID(res.actionID))
    );
  }
}
