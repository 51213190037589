import {
  ProductCsvExportList,
  ProductCsvExportService,
  ProductExportCsvData
} from '#application/services/product-csv-export.service';
import { SnackBarService } from '#application/services/snack-bar.service';
import { ClientApi } from '#infrastructure/api/client-api';
import { ServerApi } from '#infrastructure/api/server-api';
import { FilterInput } from "#infrastructure/application/filter.service";
import {
  ProductCsvExportCustomDisplayItem,
  ProductCsvExportStatus
} from '#presentation/pages/activity/product-management/product-csv-export/product-csv-export-setting';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApplicationHistoryType } from "../../model/storage/application-history";

function toOutputColumns(list: ProductCsvExportList) {
  return list.map(item => {
    let name = '';
    let label = item.columnName;
    switch (item.columnType) {
      case 'PRODUCT_ID':
      case 'PRODUCT_NUMBER':
      case 'PRODUCT_TYPE':
        name = item.columnType;
        break;
      case 'BUILTIN_FIELD':
        name = item.builtinFieldID;
        break;
      case 'CUSTOM_FIELD':
        name = item.customFieldID;
        break;
      default:
        throw Error(`${item} is unexpected value`);
    }
    return {
      name,
      label
    }
  });
}

@Injectable({
  providedIn: 'root',
})
export class ProductCsvExportServiceImpl extends ProductCsvExportService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
    private readonly clientApi: ClientApi,
  ) {
    super();
  }

  csvExport(list: ProductCsvExportList, input: FilterInput, encoding: string): Observable<ProductExportCsvData> {
    return this.serverApi.productCsvExportApi
      .csvExport({
        filters: input.filters,
        sorts: input.sorts,
        outputColumns: toOutputColumns(list),
        charset: encoding
      })
      .pipe(
        tap(() => this.snackBarService.show('CSVエクスポートを開始しました。')),
        catchError(err => {
          this.snackBarService.show('CSVエクスポートに失敗しました。');
          return throwError(err);
        })
      );
  }

  saveStatus(status: ProductCsvExportStatus): void {
    this.clientApi.storeLocalStorage(
      ApplicationHistoryType.ProductCsvExport,
      status
    );
  }

  getLastStatus(): ProductCsvExportStatus | null {
    return this.clientApi.lookupLocalStorage(
      ApplicationHistoryType.ProductCsvExport
    );
  }

  deleteStatus(): void {
    this.clientApi.deleteLocalStorage(
      ApplicationHistoryType.ProductCsvExport,
    );
  }

  listProductCustomField(): Observable<ProductCsvExportCustomDisplayItem[]> {
    return this.serverApi.customFieldApi.list('product').pipe(
      map(list => list.map(item => ({
        customFieldID: item.id,
        columnType: 'CUSTOM_FIELD',
        columnName: item.name,
        isChecked: true
      })))
    );
  }
}
