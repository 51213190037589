import {ApiFilterRequest, ApiPaginationRequest} from "#infrastructure/api/server-filter-api";
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

export type ClientForSelectResponse = {
  totalCount: number,
  results: {
    id: string,
    name: string,
  }[],
}

export class ClientForSelectApi {
  constructor(private readonly http: HttpClient) {
  }

  filter(request: ApiPaginationRequest & ApiFilterRequest): Observable<ClientForSelectResponse> {
    return this.http.post<ClientForSelectResponse>(
      `/api/client-for-select/filter`,
      request,
    );
  }

}
