import {ServerApi} from '#infrastructure/api/server-api';
import {SnackBarService} from '#application/services/snack-bar.service';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {ClientApi,} from '#infrastructure/api/client-api';
import {CsvFieldMapping, CsvFileFormat, CsvImportParam, ImportTypes,} from '#application/services/csv-import.service';
import {ClientCsvImportService} from '#application/services/client-csv-import.service';
import {FormLayoutCategory, FormLayoutId,} from 'app/model/form-layout/form-layout';
import {CsvImportRequest} from '#infrastructure/api/server-csv-import-api';
import {ApplicationHistoryType} from "../../model/storage/application-history";

@Injectable({ providedIn: 'root' })
export class ClientCsvImportServiceImpl extends ClientCsvImportService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
    private readonly clientApi: ClientApi
  ) {
    super();
  }

  getImportTypes(): Observable<ImportTypes> {
    return this.serverApi.clientTypeApi.list().pipe(
      map((response) =>
        response.map((item) => {
          const category =
            item.category === 'CORPORATION'
              ? FormLayoutCategory.CORPORATION_CLIENT
              : FormLayoutCategory.INDIVIDUAL_CLIENT;
          const importType = {
            typeId: item.id,
            typeName: item.name,
            category: category,
            layoutId: item.formLayoutID
              ? new FormLayoutId(item.formLayoutID)
              : undefined,
          };
          return importType;
        })
      )
    );
  }

  importCsv(param: CsvImportParam): Observable<void> {
    const request: CsvImportRequest = {
      fileName: param.fileName,
      typeId: param.type.typeId,
      formatInfo: {
        encoding: param.formatDefinition.encoding,
        delimiter: param.formatDefinition.delimiter,
        dateFormat: param.formatDefinition.dateFormat,
        dateTimeFormat: param.formatDefinition.dateTimeFormat,
      },
      mapping: param.mapping.map((v) => ({ fieldId: v })),
      file: param.base64encodedCsvFile,
      uniqueFieldId: param.uniqueFieldId,
    };
    const category =
      param.type.category === FormLayoutCategory.CORPORATION_CLIENT
        ? 'corporation'
        : 'individual';
    return this.serverApi.csvImportApi.importCsv(category, request).pipe(
      tap(() => this.snackBarService.show('CSVインポートを開始しました。')),
      map(() => {}),
      catchError((error) => {
        this.snackBarService.show('CSVインポートに失敗しました。');
        return throwError(error);
      })
    );
  }

  saveLastFileFormat(format: CsvFileFormat): void {
    this.clientApi.storeLocalStorage(
      ApplicationHistoryType.ClientCsvFileFormat,
      format
    );
  }

  getLastFileFormat(): CsvFileFormat | null {
    return this.clientApi.lookupLocalStorage(
      ApplicationHistoryType.ClientCsvFileFormat
    );
  }

  saveFieldMapping(mapping: CsvFieldMapping): void {
    this.clientApi.storeLocalStorage(
      ApplicationHistoryType.ClientCsvImport,
      mapping
    );
  }

  getLastFieldMapping(): CsvFieldMapping | null {
    return this.clientApi.lookupLocalStorage(
      ApplicationHistoryType.ClientCsvImport
    );
  }
}
