import { OpportunityTypeID, OpportunityTypeName } from "app/model/opportunity-type/opportunity-type"
import { OpportunityID } from "app/model/opportunity/opportunity"
import { OpportunityName } from "app/model/opportunity/opportunity"
import { OpportunityNumber } from "app/model/opportunity/opportunity"
import { OpportunityStatus } from "app/model/opportunity/opportunity"
import { PhaseID, PhaseName } from "app/model/sales-phase/sales-phase"
import { UserGroupID } from "app/model/user-group/user-group"
import { DisplayName, UserID } from "app/model/user/user"
import { DateTime } from "luxon"
import { Observable } from "rxjs"

export type ListOpportunityOfReportInput = {
  pagination: {
    perPage: number,
    page: number,
  },
  duration: {
    type: 'MONTHLY'
    startYearMonth: DateTime,
    months: number,
  } | {
    type: 'DAILY'
    startDate: DateTime,
    days: number,
  },
  assignee: {
    type: 'USER',
    userID: UserID,
  } | {
    type: 'USER_GROUP',
    userGroupID: UserGroupID,
  } | {
    type: 'ALL_USERS',
  },
  opportunityTypeIDs: OpportunityTypeID[],
  statuses: Exclude<OpportunityStatus, typeof OpportunityStatus.DELETED>[],
}

export type OpportunityListOfOpportunityReport = {
  totalCount: number,
  results: {
    opportunity: {
      id: OpportunityID,
      name: OpportunityName,
      number: OpportunityNumber,
      status: Exclude<OpportunityStatus, typeof OpportunityStatus.DELETED>
    },
    opportunityType: {
      id: OpportunityTypeID,
      name: OpportunityTypeName,
    },
    currentPhase: {
      id: PhaseID,
      name: PhaseName,
    },
    assignee: {
      id: UserID,
      name: DisplayName,
    }
  }[],
}

export abstract class OpportunityListReportOpportunityQueryService {
  abstract list(input: ListOpportunityOfReportInput): Observable<OpportunityListOfOpportunityReport>;
  abstract listBySalesRecord(input: ListOpportunityOfReportInput): Observable<OpportunityListOfOpportunityReport>;
}
