import { Injectable } from '@angular/core';
import { FollowUpActionFactory } from 'app/model/follow-up-action/follow-up-action.factory';
import {
  FollowUpActionDetail,
  FollowUpActionDue,
  FollowUpActionSummary,
} from 'app/model/follow-up-action/follow-up-action';
import { Observable } from 'rxjs';
import { ServerApi } from '#infrastructure/api/server-api';
import { FollowUpActionTypeID } from 'app/model/follow-up-action/follow-up-action-type';
import { OpportunityID } from 'app/model/opportunity/opportunity';
import { UserID } from 'app/model/user/user';

@Injectable({ providedIn: 'root' })
export class FollowUpActionFactoryImpl implements FollowUpActionFactory {
  constructor(private readonly serverApi: ServerApi) {}

  create(
    opportunityID: OpportunityID,
    followUpActionDue: FollowUpActionDue,
    followUpActionTypeID: FollowUpActionTypeID,
    assigneeID: UserID,
    followUpActionSummary: FollowUpActionSummary,
    followUpActionDetail: FollowUpActionDetail
  ): Observable<void> {
    return this.serverApi.followUpActionApi.createFollowUpAction({
      opportunityID: opportunityID.value,
      due: followUpActionDue.format('yyyy-MM-dd HH:mm:ss'),
      followUpActionTypeID: followUpActionTypeID.value,
      assignee: assigneeID.value,
      contentsSummary: followUpActionSummary.value,
      contentsDetail: followUpActionDetail.value,
    });
  }
}
