import {
  BuiltinFieldItem,
  CustomFieldItem,
  FormLayout,
  FormLayoutId,
  FormLayoutName,
  SectionTitleItem,
} from 'app/model/form-layout/form-layout';
import { Observable } from 'rxjs';

export interface FormLayoutAddParam {
  name: FormLayoutName;
  items: (
    | SectionTitleItem
    | CustomFieldItem
    | BuiltinFieldItem
  )[];
}

export abstract class FormLayoutCommandService {
  abstract add(param: FormLayoutAddParam): Observable<void>;
  abstract save(formLayout: FormLayout): Observable<void>;
  abstract remove(formLayoutId: FormLayoutId): Observable<void>;
}
