import { ConfirmService } from "#application/services/confirm.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { CreateRoleInputs, RoleBadRequestError, RoleCommandService, RoleConflictError, UpdateRoleInputs } from "#application/services/role-command.service";
import { SnackBarService } from "#application/services/snack-bar.service";
import { RoleID } from "app/model/role/role";
import { ServerApiError, ServerApiErrorCode } from "#infrastructure/api/error";
import { ServerApi } from "#infrastructure/api/server-api";
import { Target, messageFn } from "#utils/messages";
import { Injectable, inject } from "@angular/core";
import { Observable, catchError, concatMap, filter, of, tap, throwError } from "rxjs";

@Injectable({ providedIn: 'root' })
export class RoleCommandServiceImpl implements RoleCommandService {
  private readonly serverApi = inject(ServerApi);
  private readonly snackBarService = inject(SnackBarService);
  private readonly confirmService = inject(ConfirmService);
  private readonly messageDialogService = inject(MessageDialogService);

  add(inputs: CreateRoleInputs): Observable<void | RoleBadRequestError | RoleConflictError> {
    return this.serverApi.roleApi.create({ name: inputs.name.value, parentRoleID: inputs.parentRoleID?.value })
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(Target.ROLE, 'CREATE_SUCCESS').message);
        }),
        catchError((err: ServerApiError) => {
          if (err.code === ServerApiErrorCode.BadRequest) {
            this.messageDialogService.notice({
              title: messageFn(Target.ROLE, 'CREATE_ERROR').message,
              message:
                '原因は以下のものが考えられます。\n' +
                '- 入力に誤りがある場合\n' +
                '- ロールの登録数が上限を超えた場合\n' +
                '- ロールの深さが上限を超えた場合\n' +
                '- 循環参照が発生した場合\n',
            });
            return of(new RoleBadRequestError());
          }
          if (err.code === ServerApiErrorCode.Conflict) {
            this.messageDialogService.notice({
              title: messageFn(Target.ROLE, 'CREATE_ERROR').message,
              message: 'ロール名が重複しています。',
            });
            return of(new RoleConflictError());
          }
          this.messageDialogService.notice({
            errorTarget: `${Target.ROLE}の作成`
          });
          return throwError(err);
        })
      );
  }

  update(inputs: UpdateRoleInputs): Observable<void | RoleBadRequestError | RoleConflictError> {
    return this.serverApi.roleApi.update({
        id: inputs.id.value,
        name: inputs.name.value,
        parentRoleID: inputs.parentRoleID?.value
      })
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(Target.ROLE, 'UPDATE_SUCCESS').message);
        }),
        catchError((err: ServerApiError) => {
          if (err.code === ServerApiErrorCode.BadRequest) {
            this.messageDialogService.notice({
              title: messageFn(Target.ROLE, 'UPDATE_ERROR').message,
              message:
                '原因は以下のものが考えられます。\n' +
                '- 選択中のロールが削除された場合\n' +
                '- 入力に誤りがある場合\n' +
                '- ロールの深さが上限を超えた場合\n' +
                '- 循環参照が発生した場合\n',
            });
            return of(new RoleBadRequestError());
          }
          if (err.code === ServerApiErrorCode.Conflict) {
            this.messageDialogService.notice({
              title: messageFn(Target.ROLE, 'UPDATE_ERROR').message,
              message: 'ロール名が重複しています。',
            });
            return of(new RoleConflictError());
          }
          this.messageDialogService.notice({
            errorTarget: `${Target.ROLE}の更新`
          });
          return throwError(err);
        })
      );
  }

  delete(id : RoleID): Observable<void | RoleBadRequestError | RoleConflictError> {
    return this.confirmService.confirm({
      title: messageFn(Target.ROLE, 'DELETE_CONFIRM').title,
      message: messageFn(Target.ROLE, 'DELETE_CONFIRM').message,
    }).pipe(
      filter(r => r.isOK()),
      concatMap(() => this.serverApi.roleApi.delete(id.value)),
      tap(() => this.snackBarService.show(messageFn(Target.ROLE, 'DELETE_SUCCESS').message)),
      catchError((err: ServerApiError) => {
        if (err.code === ServerApiErrorCode.BadRequest) {
          this.messageDialogService.notice({
            title: messageFn(Target.ROLE, 'DELETE_ERROR').title,
            message: 'このロールはすでに削除されている可能性があります。',
          });
          return of(new RoleBadRequestError());
        }
        if (err.code === ServerApiErrorCode.Conflict) {
          this.messageDialogService.notice({
            title: messageFn(Target.ROLE, 'DELETE_ERROR').title,
            message: 'このロールは他の場所で使用されている可能性があります。',
          });
          return of(new RoleConflictError());
        }
        this.messageDialogService.notice({
          errorTarget: `${Target.ROLE}の削除`
        });
        return throwError(err);
      })
    );
  }
}
