import { AddContactRelatedClientFailure, ContactRelatedClientCommandService } from "#application/services/contact-related-client-command.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { SnackBarService } from "#application/services/snack-bar.service";
import { ClientID } from "app/model/client/client";
import { ContactID } from "app/model/contact/contact";
import { ServerApiError, ErrorResponseTitle } from "#infrastructure/api/error";
import { ServerApi } from "#infrastructure/api/server-api";
import { Target, messageFn } from "#utils/messages";
import { Injectable, inject } from "@angular/core";
import { Failure, Result, Success } from "app/lib/result/result";
import { NEVER, Observable, catchError, map, of, tap } from "rxjs";

@Injectable({ providedIn: 'root'  })
export class ContactRelatedClientCommandServiceImpl implements ContactRelatedClientCommandService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _snackBarService = inject(SnackBarService);
  private readonly _messageDialogService = inject(MessageDialogService);
  
  add(contactID: ContactID, clientID: ClientID): Observable<Result<
    void,
    | typeof AddContactRelatedClientFailure.ClientDuplicated
    | typeof AddContactRelatedClientFailure.ClientNotFound
  >> {
    return this._serverApi.contactApi.createRelatedClient(contactID.value, {
      clientID: clientID.value,
    })
    .pipe(
      tap(() => this._snackBarService.show(
        messageFn(Target.CONTACT_RELATED_CLIENT, 'CREATE_SUCCESS').message,
      )),
      map((v) => Success<void>(v)),
      catchError((e: ServerApiError) => {
        if (e.response.title === ErrorResponseTitle.DuplicatedError) {
          return of(Failure(AddContactRelatedClientFailure.ClientDuplicated));
        }
        if (
          e.response.title === ErrorResponseTitle.ResourceNotFoundError
          || e.response.resources.includes('client')
        ) {
          return of(Failure(AddContactRelatedClientFailure.ClientNotFound));
        }
        this._messageDialogService.notice({
          errorTarget: `${Target.CONTACT_RELATED_CLIENT}の登録`
        });
        return NEVER;
      })
    );
  }
}