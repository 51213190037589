import { Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { inject, Injectable } from '@angular/core';

import { AuthorizeInfo, AuthorizeService, NoAuthInfoError } from '#application/services/authorize.service';
import { MessageDialogService } from '#application/services/message-dialog.service';
import { Page, PageNavigateService } from '#application/services/page-navigate.service';
import { SnackBarService } from '#application/services/snack-bar.service';
import { UserProfileService } from '#application/services/user-profile.service';
import { ClientApi } from "#infrastructure/api/client-api";
import { ServerApiError, ServerApiErrorCode } from '#infrastructure/api/error';
import { ServerApi } from '#infrastructure/api/server-api';
import { SecurityRole } from 'app/model/security-role/security-role';
import { ApiSecurityRole, AUTH_INFO_KEY_NAME, ClientApiAuthorizeInfo } from "../../model/storage/authorize-info";
import { CalendarSettingCloseTime, CalendarSettingEnableDayOfWeek, CalendarSettings, CalendarSettingStartTime } from 'app/model/calendar-settings/calendar-settings';
import { UserProfileSettings } from 'app/model/storage/user-profile-setting';

const RUN = 'RUN';
const CLEAR = 'CLEAR';

@Injectable({providedIn: 'root'})
export class AuthorizeServiceImpl implements AuthorizeService {
  private readonly _userProfileService = inject(UserProfileService);
  private readonly _nullAuthInfoHandler$ = new Subject<typeof RUN | typeof CLEAR>();

  constructor(
    private readonly clientApi: ClientApi,
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
    private readonly pageNavigateService: PageNavigateService,
    private readonly messageDialogService: MessageDialogService,
  ) {
    this._nullAuthInfoHandler$.pipe(
      distinctUntilChanged(),
      filter(v => v === RUN),
      switchMap(() => this.logout().pipe(
        tap(() => {
          this.messageDialogService.notice({
            title: '自動でログアウトしました',
            message: '予期せぬエラーが発生したため、自動でログアウトしました。\nお手数ですが、もう一度ログインしてください。'
          });
        })
      )),
    )
    .subscribe();
  }

  login(loginID: string, password: string): Observable<boolean> {
    return this.serverApi.auth.signIn({
      userID: loginID,
      password,
    })
    .pipe(
      tap(res => {
        this.clientApi.storeLocalStorage(AUTH_INFO_KEY_NAME, {
          id: res.userID,
          organizationName: res.organizationName,
          loginID: res.userName,
          displayName: res.displayName,
          mailAddress: res.mailAddress,
          fiscalYearBegin: res.fiscalYearBegin,
          securityRoles: res.securityRoles,
          isCoachingAvailable: res.isCoachingAvailable,
          isProposalEnabled: res.isProposalEnabled,
          isLinkageEnabled: res.isLinkageEnabled,
          isOpportunityGroupEnabled: res.isOpportunityGroupEnabled,
          localize: res.localize,
        });
        this._userProfileService.saveUserProfile({
          calendarSettings: CalendarSettings(res.userProfile.calendarSettings),
        } satisfies UserProfileSettings);
        this.snackBarService.show('ログイン認証に成功しました。');
      }),
      map(() => true),
      catchError((error: unknown) => {
        if ((error instanceof ServerApiError) && (error.code === ServerApiErrorCode.Unauthorized)) {
          this.snackBarService.show('ログイン認証に失敗しました。');
          return of(false);
        }
        throw error;
      })
    );
  }

  logout(): Observable<void> {
    return this.serverApi.auth.signOut()
    .pipe(
      tap(() => {
        const roles = this.clientApi.lookupLocalStorage<ClientApiAuthorizeInfo>(AUTH_INFO_KEY_NAME)?.securityRoles;
        if (roles === undefined) {
          this.pageNavigateService.navigate(Page.SignIn);
        } else if (
          roles.includes(SecurityRole.CONSULTANT)
          || roles.includes(SecurityRole.ADMIN)
        ) {
          this.pageNavigateService.navigate(Page.SignInAsConsultant);
        } else {
          this.pageNavigateService.navigate(Page.SignIn);
        }
        this.clientApi.deleteLocalStorage(AUTH_INFO_KEY_NAME);
        this._userProfileService.deleteUserProfile();
      })
    );
  }

  isAuthenticated(): boolean {
    return this.clientApi.lookupLocalStorage<ClientApiAuthorizeInfo>(AUTH_INFO_KEY_NAME) !== null;
  }

  getAuthInfo(): AuthorizeInfo {
    const info = this.clientApi.lookupLocalStorage<ClientApiAuthorizeInfo>(AUTH_INFO_KEY_NAME);
    if (info === null) {
      this._nullAuthInfoHandler$.next(RUN);
      this._nullAuthInfoHandler$.next(CLEAR);
      throw new NoAuthInfoError('認証情報が存在しません。');
    } else {
      return {
        ...info,
        securityRoles: convertSecurityRoles(info.securityRoles),
      };
    }
  }

  isCoachingAvailable(): boolean {
    return this.isAuthenticated()
    ? this.getAuthInfo().isCoachingAvailable ?? false
    : false;
  }

  isProposalEnabled(): boolean {
    return this.isAuthenticated() ? (this.getAuthInfo().isProposalEnabled ?? false) : false;
  }

  isLinkageEnabled(): boolean {
    return this.isAuthenticated()
    ? this.getAuthInfo().isLinkageEnabled ?? false
    : false;
  }

  isOpportunityGroupEnabled(): boolean {
    return this.isAuthenticated()
    ? this.getAuthInfo().isOpportunityGroupEnabled ?? false
    : false;
  }

  getSecurityRoles(roles: ApiSecurityRole[]): SecurityRole[] {
    return convertSecurityRoles(roles);
  }
}

const convertSecurityRoles = (roles: ApiSecurityRole[]): SecurityRole[] => {
  return roles.map(r => {
    switch (r) {
      case 'MEMBER':
        return SecurityRole.MEMBER;
      case 'MANAGER':
        return SecurityRole.MANAGER;
      case 'SYSTEM_ADMINISTRATOR':
        return SecurityRole.SYSTEM_ADMINISTRATOR;
      case 'CONSULTANT':
        return SecurityRole.CONSULTANT;
      case 'ORGANIZATION_CONSULTANT':
        return SecurityRole.ORGANIZATION_CONSULTANT;
      case 'ADMIN':
        return SecurityRole.ADMIN;
      default:
        const unexpected: never = r;
        throw new Error(`Unexpected security role: ${unexpected}`);
    }
  });
}
