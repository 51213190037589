import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnDestroy } from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

@Component({
  template: ``,
})
export class ResponsiveComponent implements OnDestroy {

  private _destroyed$ = new Subject<void>();

  /**
   * テンプレートでは
   * [class.mobile]="isMobile$ | async"
   * のように使用する。
   */
  isMobile$: Observable<boolean>;

  /** MatDialogを開く際に渡す大きさ等の設定 */
  dialogConfig: MatDialogConfig = {};

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
  ) {
    this.isMobile$ = this._isMobile();
    this.isMobile$
    .pipe(takeUntil(this._destroyed$))
    .subscribe(v => {
      this.dialogConfig = (v === true)
        ? {
            maxWidth: 'calc(100% - 20px)',
            maxHeight: 'calc(100% - 20px)',
          }
        : { width: '720px' };
    });
  }

  private _isMobile(): Observable<boolean> {
    return this.breakpointObserver
    .observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ])
    .pipe(
      takeUntil(this._destroyed$),
      map(state => {
        return state.matches;
      })
    );
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
