import { FormLayoutId, FormLayoutName } from 'app/model/form-layout/form-layout';
import { ProductTypeDescription, ProductTypeId, ProductTypeName } from 'app/model/product-type/product-type';
import { Observable } from 'rxjs';

export interface ProductTypeListItem {
  id: ProductTypeId,
  name: ProductTypeName,
  description: ProductTypeDescription,
  formLayoutID?: FormLayoutId,
  formLayoutName?: FormLayoutName
}

export type ProductTypeList = ProductTypeListItem[];

export interface ProductTypeDetail {
  id: ProductTypeId,
  name: ProductTypeName,
  description: ProductTypeDescription,
  formLayoutID?: FormLayoutId,
}

export abstract class ProductTypeQueryService {
  abstract list(): Observable<ProductTypeList>;

  abstract get(id: ProductTypeId): Observable<ProductTypeDetail>
}
