import { AddInsightInputs, UpdateInsightInputs } from "#application/services/base-insight-command.service";
import { ConfirmService } from "#application/services/confirm.service";
import { InsightCommandService } from "#application/services/insight-command.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { SnackBarService } from "#application/services/snack-bar.service";
import { InsightAnswerSelectionType, InsightAnswerTextType, InsightID } from "app/model/insight/insight";
import { ServerApi } from "#infrastructure/api/server-api";
import { CreateInsightRequest, UpdateInsightRequest } from "#infrastructure/api/server-insight-api";
import { messageFn, Target } from "#utils/messages";
import { inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, filter, concatMap } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class InsightCommandServiceImpl implements InsightCommandService {
  private readonly serverApi = inject(ServerApi);
  private readonly snackBarService = inject(SnackBarService);
  private readonly confirmService = inject(ConfirmService);
  private readonly messageDialogService = inject(MessageDialogService);

  add(inputs: AddInsightInputs): Observable<void> {
    return this.serverApi.insightApi.create(this._convertToCreateRequest(inputs))
    .pipe(
      catchError((err) => {
        this.snackBarService.show(messageFn(Target.INSIGHT, 'CREATE_ERROR').message);
        return throwError(err);
      })
    );
  }

  update(id: InsightID, inputs: UpdateInsightInputs): Observable<void> {
    return this.serverApi.insightApi.update(id.value, this._convertToUpdateRequest(inputs))
    .pipe(
      catchError((err) => {
        this.snackBarService.show(messageFn(Target.INSIGHT, 'UPDATE_ERROR').message);
        return throwError(err);
      })
    );
  }

  delete(id: InsightID): Observable<void> {
    return this.confirmService.confirm({
      title: messageFn(Target.INSIGHT, 'DELETE_CONFIRM').title,
      message: messageFn(Target.INSIGHT, 'DELETE_CONFIRM').message
    })
    .pipe(
      filter(result => result.isOK()),
      concatMap(() => this.serverApi.insightApi.delete(id.value)),
      catchError((err) => {
        this.messageDialogService.notice({
          title: messageFn(Target.INSIGHT, 'DELETE_ERROR').title,
          message: messageFn(Target.INSIGHT, 'DELETE_ERROR').message
        });
        return throwError(err);
      })
    );
  }

  private _convertToCreateRequest(inputs: AddInsightInputs): CreateInsightRequest {
    const answerType = inputs.answerType;
    switch (answerType) {
      case InsightAnswerSelectionType.value:
        return {
          coachingTiming: inputs.coachingTiming,
          question: inputs.question.value,
          supplementTitle: inputs.supplementTitle.value,
          supplement: inputs.supplement.value,
          triggeringOpportunityContextIDs: inputs.triggeringOpportunityContextIDs.map(v => v.value),
          answerType: inputs.answerType,
          selection: {
            multiple: inputs.selection.multiple,
            answerOptions: inputs.selection.answerOptions.map(v => ({
              answer: v.answer.value,
              occurringOpportunityContextIDs: v.occurringOpportunityContextIDs === undefined
                ? undefined
                : v.occurringOpportunityContextIDs.map(v => v.value),
              hintID: v.hintID === undefined ? undefined : v.hintID.value,
            })),
          },
        };
      case InsightAnswerTextType.value:
        return {
          coachingTiming: inputs.coachingTiming,
          question: inputs.question.value,
          supplementTitle: inputs.supplementTitle.value,
          supplement: inputs.supplement.value,
          triggeringOpportunityContextIDs: inputs.triggeringOpportunityContextIDs.map(v => v.value),
          answerType: inputs.answerType,
        };
      default:
        const unexpected: never = answerType;
        throw Error(`${unexpected}は予期せぬ回答種別です。`);
    }
  }

  private _convertToUpdateRequest(inputs: UpdateInsightInputs): UpdateInsightRequest {
    const answerType = inputs.answerType;
    switch (answerType) {
      case InsightAnswerSelectionType.value:
        return {
          question: inputs.question.value,
          supplementTitle: inputs.supplementTitle.value,
          supplement: inputs.supplement.value,
          triggeringOpportunityContextIDs: inputs.triggeringOpportunityContextIDs.map(v => v.value),
          answerType: inputs.answerType,
          selection: {
            multiple: inputs.selection.multiple,
            answerOptions: inputs.selection.answerOptions.map(v => ({
              id: v.id === undefined ? undefined : v.id.value,
              answer: v.answer.value,
              occurringOpportunityContextIDs: v.occurringOpportunityContextIDs === undefined
                ? undefined
                : v.occurringOpportunityContextIDs.map(v => v.value),
              hintID: v.hintID === undefined ? undefined : v.hintID.value,
            })),
          },
        };
      case InsightAnswerTextType.value:
        return {
          question: inputs.question.value,
          supplementTitle: inputs.supplementTitle.value,
          supplement: inputs.supplement.value,
          triggeringOpportunityContextIDs: inputs.triggeringOpportunityContextIDs.map(v => v.value),
          answerType: inputs.answerType,
        };
      default:
        const unexpected: never = answerType;
        throw Error(`${unexpected}は予期せぬ回答種別です。`);
    }
  }
}
