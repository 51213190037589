import { HttpClient } from "@angular/common/http";
import { GoalCategoryForReport, GoalPeriodType } from "app/model/report/report-goal";
import { Observable } from "rxjs";


/* ユーザーグループ別目標レポートのリクエスト */
export type GetReportGoalV2Request = {
  duration: {
    goalPeriodType: GoalPeriodType,
    startYearMonth: string,
    months: number,
  },
  userIDs?: string[],
  containsEstimation: boolean,
};

/* ユーザー別目標レポートのリクエスト */
export type GetPerUserReportGoalV2Request = {
  duration: {
    goalPeriodType: GoalPeriodType,
    startYearMonth: string,
    months: number,
  },
  userID: string,
  containsEstimation: boolean,
};

/* 目標マイデータのリクエスト */
export type GetPersonalReportGoalV2Request = {
  duration: {
    goalPeriodType: GoalPeriodType,
    startYearMonth: string,
    months: number,
  },
  containsEstimation: boolean,
};

/* ユーザー */
export type UserReportGoalV2 = {
  id: string,
  name: string,
  isActive: true,
};

/* 見込み含むレポート */
export type ContainsEstimationReportGoalV2 = {
  yearMonth: string,
  goal: number,
  achievementAndEstimation: number,
  achievement: number,
  estimation: number,
  difference: number,
  achievementRate?: number,
};

/* 見込み含まないレポート */
export type WithoutEstimationReportGoalV2 =
  Omit<ContainsEstimationReportGoalV2, 'achievementAndEstimation' | 'estimation'>;

/* ユーザーグループ別目標レポートのレスポンス */
export type GetContainsEstimationReportGoalV2Response = {
  goals: {
    unit: ContainsEstimationReportGoalV2[],
    total: ContainsEstimationReportGoalV2[],
  },
  goalsPerUser: {
    user: UserReportGoalV2,
    unit: ContainsEstimationReportGoalV2[],
    total: ContainsEstimationReportGoalV2[],
  }[],
};
export type GetWithoutEstimationReportGoalV2Response = {
  goals: {
    unit: WithoutEstimationReportGoalV2[],
    total: WithoutEstimationReportGoalV2[],
  },
  goalsPerUser: {
    user: UserReportGoalV2,
    unit: WithoutEstimationReportGoalV2[],
    total: WithoutEstimationReportGoalV2[],
  }[],
};
export type GetReportGoalV2Response =
  | GetContainsEstimationReportGoalV2Response
  | GetWithoutEstimationReportGoalV2Response;

/* ユーザー別目標レポートのレスポンス */
export type GetPerUserContainsEstimationReportGoalV2Response = {
  user: UserReportGoalV2,
  unit: ContainsEstimationReportGoalV2[],
  total: ContainsEstimationReportGoalV2[],
};
export type GetPerUserWithoutEstimationReportGoalV2Response = {
  user: UserReportGoalV2,
  unit: WithoutEstimationReportGoalV2[],
  total: WithoutEstimationReportGoalV2[],
};
export type GetPerUserReportGoalV2Response =
  | GetPerUserContainsEstimationReportGoalV2Response
  | GetPerUserWithoutEstimationReportGoalV2Response;

/* 目標マイデータのレスポンス */
export type GetPersonalReportGoalV2Response = GetPerUserReportGoalV2Response;

export class ReportGoalV2Api {
  constructor(private readonly http: HttpClient) {}

  get(category: GoalCategoryForReport, request: GetReportGoalV2Request): Observable<GetReportGoalV2Response> {
    return this.http.post<GetReportGoalV2Response>(`/api/report-goals/${ category }`, request);
  }
  getPerUser(category: GoalCategoryForReport, request: GetPerUserReportGoalV2Request): Observable<GetPerUserReportGoalV2Response> {
    return this.http.post<GetPerUserReportGoalV2Response>(`/api/report-goals/${ category }/user`, request);
  }
  getPersonal(category: GoalCategoryForReport, request: GetPersonalReportGoalV2Request): Observable<GetPersonalReportGoalV2Response> {
    return this.http.post<GetPersonalReportGoalV2Response>(`/api/my-goals/${ category }`, request);
  }
}
