import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Result } from 'app/lib/result/result';
import {
  Action,
  ActionAttendees,
  ActionEndDateTime,
  ActionID,
  ActionNote,
  ActionStartDateTime,
  IsDone
} from 'app/model/action/action';
import { ActionTypeID, ActionTypeName } from 'app/model/action/action-type';
import { Companions } from 'app/model/companion/companion';
import {
  OpportunityID,
  OpportunityName,
} from 'app/model/opportunity/opportunity';
import { PhaseID, PhaseName } from 'app/model/sales-phase/sales-phase';
import { DisplayName, UserID } from 'app/model/user/user';
import { Observable } from 'rxjs';

export type SearchMyUnreportedActionsInput = {
  opportunityName?: string;
  perPage: number;
  page: number;
};

export interface ActionListItem {
  actionId: ActionID;
  companions: Companions;
  attendees: ActionAttendees;
  actionStartDateTime: ActionStartDateTime;
  actionEndDateTime: ActionEndDateTime;
  opportunityId: OpportunityID;
  opportunityName: OpportunityName;
  currentPhaseId: PhaseID;
  currentPhaseName: PhaseName;
  startPhaseId: PhaseID;
  startPhaseName: PhaseName;
  targetPhaseId: PhaseID;
  targetPhaseName: PhaseName;
  actualStartPhaseId?: PhaseID;
  actualStartPhaseName?: PhaseName;
  actualPhaseId?: PhaseID;
  actualPhaseName?: PhaseName;
  actionTypeId: ActionTypeID;
  actionTypeName: ActionTypeName;
  actionNote: ActionNote;
  assignee: UserID;
  assigneeName: DisplayName;
  isDone: IsDone;
}

export type ActionList = {
  totalCount: number;
  results: ActionListItem[];
};

export abstract class ActionQueryService {
  abstract searchMyUnreportedActions(
    input: SearchMyUnreportedActionsInput
  ): Observable<ActionList>;

  abstract get(actionID: ActionID): Observable<Result<
    Action,
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >>;
}
