import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type ApiWebhookObjectResponse = {
  category: 'OPPORTUNITY' | 'CLIENT',
  typeID: string,
  typeName: string,
}
export type ApiWebhookObjectRequest = Omit<ApiWebhookObjectResponse, 'typeName'>
export type ApiWebhookCommand = 'CREATED' | 'UPDATED' | 'DELETED'

export type WebhookRequest = Readonly<{
  name: string,
  uri: string,
  object: ApiWebhookObjectRequest,
  commands: ApiWebhookCommand[]
}>

export type ListWebhookResponse = Readonly<{
  id: string,
  connectionStatus: 'SUCCESS' | 'FAILURE' | 'UNUSED',
  name: string,
  uri: string,
}[]>

export type GetWebhookResponse = Readonly<{
  id: string,
  name: string,
  uri: string,
  object: ApiWebhookObjectResponse,
  commands: ApiWebhookCommand[]
}>


export class WebhookApi {
  constructor(private readonly http: HttpClient) {}

  list(): Observable<ListWebhookResponse> {
    return this.http.get<ListWebhookResponse>(`/api/webhooks`);
  }

  get(id: string): Observable<GetWebhookResponse> {
    return this.http.get<GetWebhookResponse>(`/api/webhooks/${id}`);
  }

  add(request: WebhookRequest): Observable<void> {
    return this.http.post<void>(`/api/webhooks`, request);
  }

  update(id: string, request: WebhookRequest): Observable<void> {
    return this.http.put<void>(`/api/webhooks/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/webhooks/${id}`);
  }

}
