import {
  ClientCategory,
  ClientTypeDescription,
  ClientTypeName
} from "app/model/client-info-type/client-type";
import {Observable} from "rxjs";
import {FormLayoutId} from "app/model/form-layout/form-layout";

export abstract class ClientTypeFactory {
  abstract create(category: ClientCategory, name: ClientTypeName, description: ClientTypeDescription, formLayoutId?: FormLayoutId): Observable<void>
}
