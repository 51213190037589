import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AdminRoutingModule} from './admin-routing.module';
import {AdminComponent} from './admin.component';
import {PageContainerModule} from "#presentation/components/page-container/page-container.module";
import {
  ConsultantSettingModule
} from "#presentation/pages/admin/consultant/consultant-setting.module";


@NgModule({
  declarations: [
    AdminComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    ConsultantSettingModule,
    PageContainerModule
  ]
})
export class AdminModule { }
