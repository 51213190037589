<mat-form-field class="password-form" [appearance]="'outline'">
  <mat-label>パスワード</mat-label>
  <input
    [type]="hide() ? 'password' : 'text'"
    matInput
    class="password-input"
    [formControl]="control"
    placeholder="パスワード"
    id="password"
    name="password"
    (keyup.enter)="keyupEnter.emit()"
  >
  <button
    type="button"
    mat-icon-button
    class="show-btn"
    matTooltip="パスワードを表示"
    *ngIf="hide(); else show"
    matSuffix
    aria-label="show"
    (click)="hide.set(false)"
  >
    <mat-icon>visibility</mat-icon>
  </button>
  <ng-template #show>
    <button
      type="button"
      class="hide-btn"
      matTooltip="パスワードを表示"
      matSuffix
      mat-icon-button
      aria-label="show"
      (click)="hide.set(true)"
    >
      <mat-icon>visibility_off</mat-icon>
    </button>
  </ng-template>
  <mat-error *ngIf="control.hasError('required')">
    パスワードを入力して下さい。
  </mat-error>
  <mat-error *ngIf="control.hasError('unauthorized')">
    パスワードを確認して下さい。
  </mat-error>
</mat-form-field>