import { DisplayName, UserID } from 'app/model/user/user';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

export interface CsvImportHistory {
  id: string;
  csvFileName: string;
  userId: UserID;
  userName: DisplayName;
  importStartDateTime: DateTime;
  importEndDateTime: DateTime;
  importDataCount: number;
  importSucceedDataCount: number;
  importFailedDataCount: number;
}

export interface CsvImportHistories {
  totalCount: number;
  results: CsvImportHistory[];
}

export interface CsvImportError {
  rowNo: number,
  error: string,
}

export interface CsvImportErrors {
  totalCount: number;
  results: CsvImportError[];
}


export abstract class CsvImportHistoriesQueryService {
  abstract getCsvImportHistories(
    perPage: number,
    page: number
  ): Observable<CsvImportHistories>;

  abstract getCsvImportErrors(
    clientCSVImportHistoryID: string,
    perPage: number,
    page: number
  ): Observable<CsvImportErrors>;
}
