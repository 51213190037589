import { ConsultantCommandService } from "#application/services/consultant-command.service";
import { Page, PageNavigateService } from "#application/services/page-navigate.service";
import { AssignedOrganization, AssignedOrganizationType, DisplayName, MailAddress, ConsultantLoginID } from "app/model/consultant/consultant";
import { OrganizationID } from "app/model/organization/organization";
import { Component } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { assignedOrgTypeOptions } from "../assigned-organization/assigned-organization";

@Component({
  selector: 'sn-consultant-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class ConsultantAddComponent {

  readonly loginIDControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(ConsultantLoginID.availableCharacter),
  ]);

  readonly displayNameControl = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(DisplayName.maxLength),
  ]);

  readonly mailAddressControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(MailAddress.availableCharacter),
  ]);

  selectedAssignedOrgType: AssignedOrganizationType = AssignedOrganizationType.ALL;
  readonly assignedOrgTypeOptions = assignedOrgTypeOptions;

  selectedOrgs: OrganizationID[] = [];

  constructor(
    private readonly consultantCommandService: ConsultantCommandService,
    private readonly pageNavigateService: PageNavigateService,
  ) {}

  private _touchValidators(): void {
    this.loginIDControl.markAsTouched();
    this.displayNameControl.markAsTouched();
    this.mailAddressControl.markAsTouched();
  }

  onSelectedOrgs(event: OrganizationID[]): void {
    this.selectedOrgs = event;
  }

  onAdd(): void {
    this._touchValidators();
    if (this.loginIDControl.invalid) return;
    if (this.displayNameControl.invalid) return;
    if (this.mailAddressControl.invalid) return;
    if (
      this.selectedAssignedOrgType === AssignedOrganizationType.SELECTED
      && this.selectedOrgs.length === 0
    ) return;

    this.consultantCommandService.add(
      new ConsultantLoginID(this.loginIDControl.value),
      new DisplayName(this.displayNameControl.value),
      new MailAddress(this.mailAddressControl.value),
      new AssignedOrganization(
        this.selectedAssignedOrgType,
        this.selectedOrgs.length === 0
          ? undefined
          : this.selectedOrgs
      )
    )
    .subscribe();
  }

  onCancel(): void {
    this.pageNavigateService.navigate(Page.AdminConsultant);
  }
}
