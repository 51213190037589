import { FilterCondition, FilterPredicateType, SearchFilterBookmarkCount, SearchFilterCategory, SearchFilterDescription, SearchFilterId, SearchFilterName, SearchFilterPermission, SortConditions } from 'app/model/search-filter/search-filter';
import { DisplayName, UserID } from 'app/model/user/user';
import { DisplayItems } from '#presentation/pages/activity/shared/display-item';
import { Observable } from 'rxjs';

export type SearchSearchFilterListParams = {
  perPage: number,
  page: number,
  filter: {
    readonly filterName: {
      readonly type: typeof FilterPredicateType.contains,
      value: string,
    },
    readonly ownerID: {
      readonly type: typeof FilterPredicateType.anyMatch,
      values: string[],
    }
  }
};

export type FilteredSearchFilterList = {
  totalCount: number,
  results: {
    id: SearchFilterId,
    filterName: SearchFilterName,
    description: SearchFilterDescription,
    owner: {
      id: UserID,
      name: DisplayName,
    },
    visibility: {
      type: SearchFilterPermission
    },
    editability: {
      type: SearchFilterPermission
    },
    bookmark: boolean,
    bookmarkCount: SearchFilterBookmarkCount,
  }[]
}

export interface SearchFilterDetail {
  id: SearchFilterId,
  filterName: SearchFilterName,
  description: SearchFilterDescription,
  owner: {
    id: UserID,
    name: DisplayName,
  },
  visibility: {
    type: SearchFilterPermission
  },
  editability: {
    type: SearchFilterPermission
  },
  filter: FilterCondition[],
  sorts: SortConditions,
  displayItems: DisplayItems | undefined,
}

export abstract class SearchFilterQueryService {
  abstract get(
    category: SearchFilterCategory,
    id: SearchFilterId
  ): Observable<SearchFilterDetail | undefined>;

  abstract findBy(
    category: SearchFilterCategory,
    params: SearchSearchFilterListParams
  ): Observable<FilteredSearchFilterList>;
}
