import {Injectable} from "@angular/core";
import { Route, UrlSegment, UrlTree } from "@angular/router";

import {Page, PageNavigateService} from "#application/services/page-navigate.service";
import {AuthorizeService} from "#application/services/authorize.service";
import {Observable} from "rxjs";
import {isSubdomainOfBaseHost, sameAsBaseHost} from "#application/guard/domain-checker";

@Injectable({providedIn: 'root'})
export class AuthorizeCheckGuard  {
  constructor(
    private readonly pageNavigateService: PageNavigateService,
    private readonly authorizeService: AuthorizeService,
  ) {
  }

  canActivate(): boolean | Promise<boolean> {
    if (this.authorizeService.isAuthenticated()) {
      return true;
    }
    // ログインしていない場合はログイン画面に飛ばす
    return this.pageNavigateService.navigate(this.decideDestinationPage(window.location.host));
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authorizeService.isAuthenticated()) {
      return true;
    }
    // ログインしていない場合はログイン画面に飛ばす
    return this.pageNavigateService.navigate(this.decideDestinationPage(window.location.host));
  }

  decideDestinationPage(host: string): Page {
    if (sameAsBaseHost(host)) {
      return Page.SignInAsConsultant;
    }
    if (isSubdomainOfBaseHost(host)) {
      return Page.SignIn;
    }
    return Page.ErrorNotFound;
  }
}
