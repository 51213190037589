import {inject, Injectable} from '@angular/core';
import {
  Activities,
  ActivityReport,
  ActivityReportOutput,
  ActivityReportPerActionType,
  ActivityReportPerDate,
  ActivityReportPerUser,
  ActivityReportPerUserOutput,
  DailyDuration,
  GetActivityReportPerUserInput,
  GetActivityReportInput,
  GetPersonalActivityReportInput,
  MonthlyDuration,
  PersonalActivityReportOutput,
  ReportActivityV2QueryService
} from "#application/services/report-activity-v2-query.service";
import {Observable, throwError} from "rxjs";
import {ServerApi} from "#infrastructure/api/server-api";
import {DateTime} from "luxon";
import {
  ActivitiesResponse,
  AllUsersSearchRequest,
  DailyDurationRequest,
  GroupUserSearchRequest,
  MonthlyDurationRequest,
  PersonalActivityReportRequest,
  PersonalActivityReportResponse,
  ActivityReportRequest,
  ActivityReportResponse,
  ActivityReportUserRequest,
  ActivityReportUserResponse,
  PerActionTypeResponse,
  PerDateResponse,
  ActivityPerUserResponse,
  ActivityResponse
} from "#infrastructure/api/server-report-activity-v2-api";
import {UserGroupID} from "app/model/user-group/user-group";
import {catchError, map} from "rxjs/operators";
import {DisplayName, UserID} from "app/model/user/user";
import {MessageDialogService} from "#application/services/message-dialog.service";
import {ActionEndDateTime, ActionID, ActionStartDateTime} from "app/model/action/action";
import {OpportunityID, OpportunityName, OpportunityNumber} from "app/model/opportunity/opportunity";
import {PhaseID, PhaseName} from "app/model/sales-phase/sales-phase";
import {ActionTypeName} from "app/model/action/action-type";

@Injectable({
  providedIn: 'root',
})
export class ReportActivityV2QueryServiceImpl extends ReportActivityV2QueryService {

  private readonly serverApi = inject(ServerApi);
  private readonly messageDialogService = inject(MessageDialogService);

  getActivityReport(input: GetActivityReportInput): Observable<ActivityReportOutput> {
    return this.serverApi.reportActivityV2Api
      .getActivityReport(convertToReportActivitiesRequest(input))
      .pipe(
        map(convertToActivityReportOutput),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '活動量レポートの取得',
          });
          return throwError(err);
        })
      )
  }

  getActivityReportPerUser(
    input: GetActivityReportPerUserInput
  ): Observable<ActivityReportPerUserOutput> {
    return this.serverApi.reportActivityV2Api
      .getActivityReportPerUser(convertToReportActivitiesUserRequest(input))
      .pipe(
        map(convertToActivityReportPerUserOutput),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: 'ユーザーごとの活動量レポートの取得',
          });
          return throwError(err);
        })
      )
  }

  getPersonalActivityReport(input: GetPersonalActivityReportInput): Observable<PersonalActivityReportOutput> {
    return this.serverApi.reportActivityV2Api
      .getPersonalActivityReport(convertToPersonalActivityRequest(input))
      .pipe(
        map(convertToPersonalActivityReportOutput),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: 'マイデータ活動量の取得',
          });
          return throwError(err);
        })
      )
  }

}

const convertToReportActivitiesRequest = (
  input: GetActivityReportInput
): ActivityReportRequest => {
  return {
    duration: convertToDurationRequest(input.duration),
    actionTypeIDs: input.actionTypeIDs.map(id => id.value),
    assignee: convertToUserSearchRequest(input.userGroupID),
  }
}

const convertToReportActivitiesUserRequest = (
  input: GetActivityReportPerUserInput
): ActivityReportUserRequest => {
  return {
    pagination: {
      perPage: input.pagination.perPage,
      page: input.pagination.page,
    },
    duration: convertToDurationRequest(input.duration),
    actionTypeIDs: input.actionTypeIDs.map(id => id.value),
    userID: input.userID.value,
  }
}

const convertToPersonalActivityRequest = (
  input: GetPersonalActivityReportInput
): PersonalActivityReportRequest => {
  return {
    pagination: {
      perPage: input.pagination.perPage,
      page: input.pagination.page,
    },
    duration: convertToDurationRequest(input.duration),
    actionTypeIDs: input.actionTypeIDs.map(id => id.value),
  }
}

const convertToDurationRequest = (
  duration: MonthlyDuration | DailyDuration
): MonthlyDurationRequest | DailyDurationRequest => {
  if ('days' in duration) {
    return {
      type: 'DAILY',
      startDate: duration.startDate.toFormat('yyyy-MM-dd HH:mm:ss'),
      days: duration.days,
    }
  } else if ('months' in duration) {
    return {
      type: 'MONTHLY',
      startYearMonth: duration.startYearMonth.toFormat('yyyy-MM'),
      months: duration.months
    }
  } else {
    throw new Error('Invalid type');
  }
}

const convertToUserSearchRequest = (
  userGroupID?: UserGroupID
): GroupUserSearchRequest | AllUsersSearchRequest => {
  if (userGroupID === undefined) {
    return {
      type: 'ALL_USERS',
    }
  } else {
    return {
      type: 'USER_GROUP',
      userGroupID: userGroupID.value,
    }
  }
}

const convertToActivityReportOutput = (
  response: ActivityReportResponse
): ActivityReportOutput => {
  return {
    times: convertToActivityReport(response.times),
    timesPerUser: response.timesPerUser.map(convertToActivityReportPerUser),
    counts: convertToActivityReport(response.counts),
    countsPerUser: response.countsPerUser.map(convertToActivityReportPerUser),
  }
}

const convertToActivityReportPerUserOutput = (
  response: ActivityReportUserResponse
): ActivityReportPerUserOutput => {
  return {
    times: convertToActivityReportPerUser(response.times),
    counts: convertToActivityReportPerUser(response.counts),
    activities: convertToActivities(response.activities),
  }
}

const convertToPersonalActivityReportOutput = (
  response: PersonalActivityReportResponse
): PersonalActivityReportOutput => {
  return {
    times: convertToActivityReport(response.times),
    counts: convertToActivityReport(response.counts),
    activities: convertToActivities(response.activities),
  }
}

const convertToActivityReport = (
  reportActivity: ActivityResponse
): ActivityReport => {
  return {
    perDate: reportActivity.perDate.map(convertToActivityReportPerDate),
    perType: convertToActivityReportPerActionType(reportActivity.perActionType),
    total: reportActivity.total,
  }
}

const convertToActivityReportPerUser = (
  perUser: ActivityPerUserResponse
): ActivityReportPerUser => {
  return {
    user: {
      id: new UserID(perUser.user.id),
      name: new DisplayName(perUser.user.name),
      isActive: perUser.user.isActive,
    },
    perDate: perUser.perDate.map(convertToActivityReportPerDate),
    perType: convertToActivityReportPerActionType(perUser.perActionType),
    total: perUser.total,
  }
}

const convertToActivityReportPerDate = (
  perDate: PerDateResponse
): ActivityReportPerDate => {
  return {
    date: DateTime.fromFormat(perDate.date, 'yyyy-MM-dd hh:mm:ss'),
    values: perDate.values
      .reduce(
        (previous, current) => {
          previous[current.actionTypeID] = current.value;
          return previous;
        },
        {} as { [actionTypeID: string]: number }
      ),
    total: perDate.total,
  }
}

const convertToActivityReportPerActionType = (
  perActionType: PerActionTypeResponse[]
): ActivityReportPerActionType => {
  return perActionType
    .reduce(
      (previous, current) => {
        previous[current.actionTypeID] = current.total;
        return previous;
      },
      {} as ActivityReportPerActionType
    )
}

const convertToActivities = (
  response: ActivitiesResponse
): Activities => {
  return {
    totalCount: response.totalCount,
    results: response.results.map(activity => ({
      actionID: new ActionID(activity.actionID),
      opportunityID: new OpportunityID(activity.opportunityID),
      opportunityName: new OpportunityName(activity.opportunityName),
      opportunityNumber: new OpportunityNumber(activity.opportunityNumber),
      isDone: activity.isDone,
      actionStartDateTime: new ActionStartDateTime(new Date(activity.actionStartDateTime)),
      actionEndDateTime: new ActionEndDateTime(new Date(activity.actionEndDateTime)),
      actionTime: activity.actionTime,
      currentPhaseID: new PhaseID(activity.currentPhaseID),
      currentPhaseName: new PhaseName(activity.currentPhaseName),
      startPhaseID: new PhaseID(activity.startPhaseID),
      startPhaseName: new PhaseName(activity.startPhaseName),
      targetPhaseID: new PhaseID(activity.targetPhaseID),
      targetPhaseName: new PhaseName(activity.targetPhaseName),
      actualStartPhaseID: activity.actualStartPhaseID === undefined
        ? undefined
        : new PhaseID(activity.actualStartPhaseID),
      actualStartPhaseName: activity.actualStartPhaseName === undefined
        ? undefined
        : new PhaseName(activity.actualStartPhaseName),
      actualPhaseID: activity.actualPhaseID === undefined
        ? undefined
        :  new PhaseID(activity.actualPhaseID),
      actualPhaseName: activity.actualPhaseName === undefined
        ? undefined
        :  new PhaseName(activity.actualPhaseName),
      actionTypeName: new ActionTypeName(activity.actionTypeName),
    }))
  }
}
