import { OpportunityBoardFilterStoreService } from '#application/services/opportunity-board-filter-store.service';
import { ClientApi } from '#infrastructure/api/client-api';
import { Injectable, effect, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { OpportunityTypeID, OpportunityTypeName } from 'app/model/opportunity-type/opportunity-type';
import { SalesPhaseID } from 'app/model/sales-phase/sales-phase';
import { MembersFilterType } from 'app/model/storage/members-filter';
import { OPPORTUNITY_BOARD_FILTER_KEY_NAME, OpportunityBoardFilter, PrimitiveOpportunityBoardFilter } from 'app/model/storage/opportunity-board-filter';

@Injectable({
  providedIn: 'root'
})
export class OpportunityBoardFilterStoreServiceImpl implements OpportunityBoardFilterStoreService {
  private readonly _clientApi = inject(ClientApi);

  private readonly _filter = signal<OpportunityBoardFilter>({
    opportunityType: {
      id: new OpportunityTypeID(''),
      name: new OpportunityTypeName(''),
      salesPhaseID: new SalesPhaseID(''),
    },
    members: {
      users: [],
      userGroupIDs: [],
      membersType: MembersFilterType.USER
    },
    allUsers: []
  });
  readonly filter$ = toObservable(this._filter);
  get filter(): OpportunityBoardFilter {
    return this._filter();
  }

  constructor() {
    effect(() => {
      this._clientApi.storeLocalStorage(OPPORTUNITY_BOARD_FILTER_KEY_NAME, OpportunityBoardFilter.serialize(this._filter()));
    });
    const stored = this._clientApi.lookupLocalStorage<PrimitiveOpportunityBoardFilter>(OPPORTUNITY_BOARD_FILTER_KEY_NAME);
    if (stored != null) {
      this._filter.set(OpportunityBoardFilter.deserialize(stored));
    }
  }

  store(filter: OpportunityBoardFilter): void {
    this._filter.set(filter);
  }
}
