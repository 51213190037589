import { ApiFilterRequest, ApiPaginationRequest, FilterSchemaResponse } from "#infrastructure/api/server-filter-api";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export type CreateProductRequest = CreateProductRequestData;

export interface CreateProductRequestData {
  name: string,
  price: number,
  unit: string,
  productTypeID: string,
  items?: {
    [customFieldId: string]: string
  }
}

export interface SearchProductListRequestData {
  perPage: number,
  page: number,
  filter: {
    [propertyName: string]: {
      type: 'contains' | 'greaterThanOrEqualTo' | 'lessThanOrEqualTo' | 'equals' | 'empty',
      value: string,
    } | {
      type: 'anyMatch',
      values: string[],
    },
  },
}

export interface SearchProductListResponseData {
  totalCount: number,
  results: {
    id: string,
    productNumber: number,
    name: string,
    price: number,
    unit: string,
    productTypeID: string,
    productTypeName: string,
    items: {
      [customFieldId: string]: string
    },
  }[]
}

export type GetProductResponse = GetProductResponseData;

export interface GetProductResponseData {
  id: string,
  productNumber: number,
  name: string,
  price: number,
  unit: string,
  productTypeID: string,
  items: {
    [customFieldId: string]: string
  },
  deleted: boolean,
}

export type UpdateProductRequest = UpdateProductRequestData;

export interface UpdateProductRequestData {
  name: string,
  price: number,
  unit: string,
  items?: {
    [customFieldId: string]: string
  }
}

export type CopyProductRequest = {
  id: string,
  name: string,
};

export type CopyProductResponse = {
  productNumber: number,
};

export type FilterProductResponse = {
  totalCount: number,
  results: FilterProductResult[]
}

export type FilterProductResult = {
  id: string,
  productNumber: number,
  name: string,
  price: number,
  unit: string,
  productTypeID: string,
  productTypeName: string,
  items: {
    [customFieldId: string]: string
  },
}

export class ProductApi {
  constructor(
    private readonly http: HttpClient
  ) {
  }

  create(request: CreateProductRequest): Observable<void> {
    return this.http.post<void>(`/api/products`, request);
  }

  get(id: string): Observable<GetProductResponse> {
    return this.http.get<GetProductResponse>(`/api/products/${id}`);
  }

  update(id: string, request: UpdateProductRequest): Observable<void> {
    return this.http.put<void>(`/api/products/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/products/${id}`);
  }

  copy(request: CopyProductRequest): Observable<CopyProductResponse> {
    return this.http.post<CopyProductResponse>(`/api/products/copy`, request);
  }

  filter(request: ApiPaginationRequest & ApiFilterRequest): Observable<FilterProductResponse> {
    return this.http.post<FilterProductResponse>(
      `/api/products/filter`,
      request,
    );
  }

  getFilterSchema(): Observable<FilterSchemaResponse> {
    return this.http.get<FilterSchemaResponse>(`/api/products/filter2`);
  }
}
