import { Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ResponsiveComponent } from "../responsive/responsive.component";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: 'sn-generic-string-search-form',
  template: `
    <div class="search-form">
      <mat-icon class="search-icon">search</mat-icon>
      <input
        #input
        [formControl]="formControl"
        class="search-input"
        [placeholder]="placeholder"
        (keyup.enter)="onEnter()"
        name="search-input"
        type="search"
        enterKeyHint="search"
      >
      <mat-icon matRipple role="button" class="clear-icon" (click)="clearValues()">close</mat-icon>
    </div>
  `,
  styleUrls: ['./generic-string-search-form.component.scss']
})
export class GenericStringSearchFormComponent extends ResponsiveComponent implements OnDestroy {
  @Input()
  formControl = new FormControl<string>('', { nonNullable: true });

  @Input()
  placeholder = '';

  @ViewChild('input')
  readonly input!: ElementRef<HTMLInputElement>;

  private readonly _onDestroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this._onDestroy$.next();
  }

  onEnter(): void {
    this.isMobile$
    .pipe(takeUntil(this._onDestroy$))
    .subscribe(isMobile => {
      if (isMobile) {
        this.input.nativeElement.blur();
      }
    });
  }

  clearValues(): void {
    this.formControl.setValue('');
  }
}
