import {environment} from "../../../environments/environment";

const baseHostname = environment.urlHost;
export const sameAsBaseHost: (host: string) => boolean = host => {
  return baseHostname === host;
}

const subdomainRegexp = new RegExp(`^[a-z0-9]+([a-z0-9\-]*[a-z0-9]+)?\.${baseHostname}$`);
export const isSubdomainOfBaseHost: (host: string) => boolean = host => {
  return subdomainRegexp.test(host);
}
