import { ClientTypeID } from 'app/model/client-info-type/client-type';
import {
  Address, ClientID, ClientItem, ClientName, ClientNumber, CorporateNumber, CorporationName, CorporationNameKana,
  Established, FirstName, FirstNameKana, FiscalYearEnd, InitialCapital, LastName, LastNameKana,
  NumberOfEmployees, PhoneNumber, SNS, Website
} from 'app/model/client/client';
import { Observable } from 'rxjs';

export interface CreateCorporationParam {
  type: 'CORPORATION';
  name: ClientName;
  clientTypeID: ClientTypeID;
  corporationName: CorporationName;
  corporationNameKana: CorporationNameKana | undefined;
  corporateNumber: CorporateNumber | undefined;
  address: Address | undefined;
  phoneNumber: PhoneNumber | undefined;
  established: Established | undefined;
  numberOfEmployees: NumberOfEmployees | undefined;
  initialCapital: InitialCapital | undefined;
  fiscalYearEnd: FiscalYearEnd | undefined;
  website: Website | undefined;
  items: ClientItem[] | undefined;
}

export interface CreateIndividualParam {
  type: 'INDIVIDUAL';
  name: ClientName;
  clientTypeID: ClientTypeID;
  firstName: FirstName;
  lastName: LastName;
  firstNameKana: FirstNameKana | undefined;
  lastNameKana: LastNameKana | undefined;
  address: Address | undefined;
  phoneNumber: PhoneNumber | undefined;
  sns1: SNS | undefined;
  sns2: SNS | undefined;
  items: ClientItem[] | undefined;
}

export type CreateClientOutput = {
  clientID: ClientID,
  clientNumber: ClientNumber,
}

export abstract class ClientFactory {
  abstract create(
    param: CreateCorporationParam | CreateIndividualParam
  ): Observable<CreateClientOutput>;
}
