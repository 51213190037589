import { Opportunity, OpportunityGroup, OpportunityGroupID, OpportunityGroupNumber } from "app/model/opportunity-group/opportunity-group";
import { FilterInput } from "#infrastructure/application/filter.service";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { Observable } from "rxjs";
import { OpportunityGroupStatusList } from "./opportunity-group-status-query.service";

export type GetOpportunityGroupsInput = {
  perPage: number,
  page: number,
  filterInput: FilterInput,
}
export type OpportunityGroups = {
  totalCount: number,
  results: OpportunityGroup[],
}
export type OpportunitiesInOpportunityGroup = Opportunity[];

export abstract class OpportunityGroupQueryService {
  abstract getByID(id: OpportunityGroupID): Observable<Result<
    OpportunityGroup,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >>;
  abstract getByNumber(number: OpportunityGroupNumber): Observable<Result<
    OpportunityGroup,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >>;
  abstract filter(input: GetOpportunityGroupsInput): Observable<Result<
    OpportunityGroups,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >>;
  abstract listOpportunities(id: OpportunityGroupID): Observable<Result<
    OpportunitiesInOpportunityGroup,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >>;
  abstract getStatuses(id: OpportunityGroupID): Observable<Result<
    OpportunityGroupStatusList,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >>;
}