import { OpportunityGroupTypeQueryService } from '#application/services/opportunity-group-type-query.service';
import { FormLayoutId, FormLayoutName } from 'app/model/form-layout/form-layout';
import { OpportunityGroupType, OpportunityGroupTypeDescription, OpportunityGroupTypeID, OpportunityGroupTypeName } from 'app/model/opportunity-group/opportunity-group-type';
import { OpportunityGroupWorkflowID, OpportunityGroupWorkflowName } from 'app/model/opportunity-group/opportunity-group-workflow';
import { ServerApi } from '#infrastructure/api/server-api';
import { GetOpportunityGroupTypeResponse } from '#infrastructure/api/server-opportunity-group-type-api';
import { Injectable, inject } from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpportunityGroupTypeQueryServiceImpl implements OpportunityGroupTypeQueryService {
  private readonly _serverApi = inject(ServerApi);

  list(): Observable<Result<
    OpportunityGroupType[],
    typeof GeneralFailure.Unexpected
  >> {
    return this._serverApi.opportunityGroupTypeApi
      .list()
      .pipe(
        map(res => Success(res.map(v => this._toDomain(v)))),
        catchError(() => of(Failure(GeneralFailure.Unexpected))),
      );
  }

  get(id: OpportunityGroupTypeID): Observable<Result<
    OpportunityGroupType,
    typeof GeneralFailure.Unexpected
  >> {
    return this._serverApi.opportunityGroupTypeApi
      .get(id)
      .pipe(
        map(res => Success(this._toDomain(res))),
        catchError(() => of(Failure(GeneralFailure.Unexpected))),
      );
  }

  private _toDomain(response: GetOpportunityGroupTypeResponse): OpportunityGroupType {
    return {
      id: OpportunityGroupTypeID(response.id),
      name: OpportunityGroupTypeName(response.name),
      description: OpportunityGroupTypeDescription(response.description),
      formLayout: response.formLayout
        ? {
          id: new FormLayoutId(response.formLayout.id),
          name: new FormLayoutName(response.formLayout.name),
        }
        : undefined,
      workflow: {
        id: OpportunityGroupWorkflowID(response.workflow.id),
        name: OpportunityGroupWorkflowName(response.workflow.name),
      },
    }
  }
}
