/**
 * ナーチャリングタイプID
 */
export class NurturingTypeID {
  constructor(readonly value: string) {}

  equals(other: NurturingTypeID): boolean {
    return this.value === other.value;
  }
}

/**
 * ナーチャリングタイプ名
 * 30文字以内
 */
export class NurturingTypeName {
  constructor(readonly value: string) {}

  static readonly MAX_LENGTH = 30;
}

/**
 * ナーチャリングタイプ説明
 * 100文字以内
 */
export class NurturingTypeDescription {
  constructor(readonly value: string) {}

  static readonly MAX_LENGTH = 100;
}

/**
 * ナーチャリングタイプ
 */
export type NurturingType = Readonly<{
  id: NurturingTypeID,
  name: NurturingTypeName,
  description: NurturingTypeDescription,
}>
