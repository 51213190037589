import { ActionManagementStorageService } from '#application/services/action-management-storage.service';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActionManagementStorageServiceImpl implements ActionManagementStorageService {

  private readonly addAction$ = new BehaviorSubject<DateTime | undefined>(undefined);
  private readonly addNurturing$ = new BehaviorSubject<DateTime | undefined>(undefined);
  private readonly addEvent$ = new BehaviorSubject<DateTime | undefined>(undefined);

  storeAddActionDateTime(datetime?: DateTime): void {
    this.addAction$.next(datetime);
  }

  lookupAddActionDateTime(): DateTime | undefined {
    return this.addAction$.value;
  }

  storeAddNurturingDateTime(datetime?: DateTime): void {
    this.addNurturing$.next(datetime);
  }

  lookupAddNurturingDateTime(): DateTime | undefined {
    return this.addNurturing$.value;
  }

  storeAddEventDateTime(datetime?: DateTime): void {
    this.addEvent$.next(datetime);
  }

  lookupAddEventDateTime(): DateTime | undefined {
    return this.addEvent$.value;
  }
}
