import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

type Input = {
  name: string,
  description?: string,
  formLayoutID?: string,
  workflowID: string,
};

type OpportunityGroupType = {
  id: string,
  name: string,
  description: string,
  formLayout?: {
    id: string,
    name: string,
  },
  workflow: {
    id: string,
    name: string,
  },
};

export type ListOpportunityGroupTypeResponse = OpportunityGroupType[];
export type GetOpportunityGroupTypeResponse = OpportunityGroupType;
export type CreateOpportunityGroupTypeRequest = Input;
export type CreateOpportunityGroupTypeResponse = { id: string };
export type UpdateOpportunityGroupTypeRequest = Input;
export type SortOpportunityGroupTypeRequest = { ids: string[] };

export class OpportunityGroupTypeApi {
  private readonly url = '/api/opportunity-group-types';
  constructor(private readonly http: HttpClient) {}

  list(): Observable<ListOpportunityGroupTypeResponse> {
    return this.http.get<ListOpportunityGroupTypeResponse>(`${this.url}/all`);
  };

  get(id: string): Observable<GetOpportunityGroupTypeResponse> {
    return this.http.get<GetOpportunityGroupTypeResponse>(`${this.url}/${id}`);
  };

  create(request: CreateOpportunityGroupTypeRequest): Observable<CreateOpportunityGroupTypeResponse> {
    return this.http.post<CreateOpportunityGroupTypeResponse>(this.url, request);
  };

  update(id: string, request: UpdateOpportunityGroupTypeRequest): Observable<void> {
    return this.http.put<void>(`${this.url}/${id}`, request);
  };

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${id}`);
  };

  sort(request: SortOpportunityGroupTypeRequest): Observable<void> {
    return this.http.patch<void>(`${this.url}/sort`, request);
  };
}
