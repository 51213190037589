import { DateTime } from "luxon";

/**
 * UTC文字列をDateオブジェクトに変換する
 * 
 * @param str UTC文字列
 * @returns Dateオブジェクト
 */
export const fromUTCStrToDate = (str: string): Date => {
  return DateTime.fromFormat(str, 'yyyy-MM-dd HH:mm:ss', { zone: 'utc'}).toJSDate();
}

/**
 * Dateオブジェクトを日付文字列に変換する
 * 
 * @param date Dateオブジェクト
 * @returns 日付文字列
 */
export const fromDateToStr = (date: Date): string => {
  return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd HH:mm:ss');
}