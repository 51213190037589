import { ClientID } from 'app/model/client/client';
import { Observable } from 'rxjs';
import { ClientRelationshipID } from '../client-relationship';
import { ClientRelationshipNote, ClientRelationshipNoteID } from './client-relationship-note';

export abstract class ClientRelationshipNoteRepository {
  abstract save(note: ClientRelationshipNote): Observable<void>;

  abstract delete(
    clientID: ClientID,
    relationshipID: ClientRelationshipID,
    noteID: ClientRelationshipNoteID
  ): Observable<void>;
}
