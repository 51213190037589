import { FormLayoutId } from "app/model/form-layout/form-layout";
import { OpportunityGroupTypeDescription, OpportunityGroupTypeID, OpportunityGroupTypeName } from "app/model/opportunity-group/opportunity-group-type";
import { OpportunityGroupWorkflowID } from "app/model/opportunity-group/opportunity-group-workflow";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { Observable } from "rxjs";

export type OpportunityGroupTypeInputs = {
  name: OpportunityGroupTypeName,
  description?: OpportunityGroupTypeDescription,
  workflowID: OpportunityGroupWorkflowID,
  formLayoutID?: FormLayoutId,
};

export type SortOpportunityGroupTypeInputs = {
  ids: OpportunityGroupTypeID[],
};

export abstract class OpportunityGroupTypeCommandService {
  abstract add(inputs: OpportunityGroupTypeInputs): Observable<Result<
    OpportunityGroupTypeID,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
  >>;

  abstract update(id: OpportunityGroupTypeID, inputs: OpportunityGroupTypeInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.NotFound
  >>;

  abstract delete(id: OpportunityGroupTypeID): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.NotFound
  >>;

  abstract sort(inputs: SortOpportunityGroupTypeInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>;
}
