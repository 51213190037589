import { OpportunityTypeID } from 'app/model/opportunity-type/opportunity-type';
import { PhaseID } from 'app/model/sales-phase/sales-phase';
import { UserID } from 'app/model/user/user';
import { Observable } from 'rxjs';
import { LostReasonID } from './lost-reason/lost-reason';
import {
  ExpectedAmount,
  ExpectedCloseDate,
  LostNote,
  LostOrderDate,
  OpportunityAccrualDate,
  OpportunityID,
  OpportunityItem,
  OpportunityName
} from './opportunity';

export type SaveOpportunityDto = {
  opportunityName: OpportunityName,
  opportunityTypeID: OpportunityTypeID,
  phaseID: PhaseID,
  assignee: UserID,
  expectedAmount?: ExpectedAmount,
  expectedCloseDate?: ExpectedCloseDate,
  opportunityAccrualDate?: OpportunityAccrualDate,
  items?: OpportunityItem[],
}

export type LoseOpportunityDto = {
  opportunityID: OpportunityID,
  lostOrderDate: LostOrderDate,
  lostReasonIDs: LostReasonID[],
  lostNote: LostNote,
}

export abstract class OpportunityRepository {

  abstract save(opportunityID: OpportunityID, opportunity: SaveOpportunityDto): Observable<void>;

  abstract lose(dto: LoseOpportunityDto): Observable<void>;

  abstract delete(opportunityID: OpportunityID): Observable<void>;

  abstract reopen(opportunityID: OpportunityID): Observable<void>;
}
