import {
    ClientFactory, CreateClientOutput, CreateCorporationParam, CreateIndividualParam
} from 'app/model/client/client.factory';
import { ServerApi } from '#infrastructure/api/server-api';
import { Observable, map } from 'rxjs';

import { Injectable } from '@angular/core';
import { ClientID, ClientItem, ClientNumber } from 'app/model/client/client';

@Injectable({ providedIn: 'root' })
export class ClientFactoryImpl extends ClientFactory {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  create(
    param: CreateCorporationParam | CreateIndividualParam
  ): Observable<CreateClientOutput> {
    switch (param.type) {
      case 'CORPORATION':
        return this.serverApi.clientApi
          .createCorporation({
            name: param.name.value,
            clientTypeID: param.clientTypeID.value,
            corporationName: param.corporationName.value,
            corporationNameKana: param.corporationNameKana?.value ?? '',
            corporateNumber: param.corporateNumber === undefined ? undefined :  param.corporateNumber.value,
            address: param.address === undefined ? undefined : param.address.value,
            phoneNumber: param.phoneNumber === undefined ? undefined : param.phoneNumber.value,
            established: param.established === undefined  ? undefined : param.established.value,
            numberOfEmployees: param.numberOfEmployees === undefined ? undefined : param.numberOfEmployees.value,
            initialCapital: param.initialCapital === undefined  ? undefined : param.initialCapital.value,
            fiscalYearEnd: param.fiscalYearEnd === undefined ? undefined : param.fiscalYearEnd.value,
            website: param.website === undefined ? undefined : param.website.value,
            items: param.items === undefined ? undefined : convertClientItemsToKeyValueItems(param.items)
          })
          .pipe(
            map(res => ({
              clientID: new ClientID(res.clientID),
              clientNumber: new ClientNumber(res.clientNumber),
            })),
          );
      case 'INDIVIDUAL':
        return this.serverApi.clientApi
          .createIndividual({
            name: param.name.value,
            clientTypeID: param.clientTypeID.value,
            firstName: param.firstName.value,
            lastName: param.lastName.value,
            firstNameKana: param.firstNameKana?.value ?? '',
            lastNameKana: param.lastNameKana?.value ?? '',
            address: param.address === undefined ? undefined : param.address.value,
            phoneNumber: param.phoneNumber === undefined ? undefined : param.phoneNumber.value,
            sns1: param.sns1 === undefined ? undefined : param.sns1.value,
            sns2: param.sns2 === undefined ? undefined : param.sns2.value,
            items: param.items === undefined ? undefined : convertClientItemsToKeyValueItems(param.items)
          })
          .pipe(
            map(res => ({
              clientID: new ClientID(res.clientID),
              clientNumber: new ClientNumber(res.clientNumber),
            })),
          );
      default:
        const unexpected: never = param;
        throw Error(`${unexpected} is unexpected value`);
    }
  }
}

type keyValueItem = {
  [key: string]: string
};

export const convertClientItemsToKeyValueItems = (items: ClientItem[]) => {
  const itemObj: keyValueItem = {};
  items.forEach(item => {
    itemObj[`${item.fieldId.value}`] = item.fieldValue.value;
  });
  return itemObj;
};
