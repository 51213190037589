import { DisplayName, UserID } from "../user/user";

export const EventAttendeeReply = {
  PENDING: 'PENDING',
  PRESENCE: 'PRESENCE',
  ABSENCE: 'ABSENCE',
} as const;
export type EventAttendeeReply = typeof EventAttendeeReply[keyof typeof EventAttendeeReply];
export const EventAttendeeReplyLabel: Record<EventAttendeeReply, string> = {
  PENDING: '保留',
  PRESENCE: '出席',
  ABSENCE: '欠席',
} as const;

/**
 * イベント出席者
 */
export type EventAttendeeV2 = Readonly<{
  id: UserID,
  name: DisplayName,
  isActive: boolean,
  reply: EventAttendeeReply,
}>

/**
 * イベント出席者のリスト
 */
export class EventAttendeesV2 {
  constructor(readonly list: EventAttendeeV2[]) {}

  add(att: EventAttendeeV2): EventAttendeesV2 {
    return new EventAttendeesV2([...this.list, att]);
  }

  delete(att: EventAttendeeV2): EventAttendeesV2 {
    return new EventAttendeesV2(this.list.filter(v => !(v.id.equals(att.id))));
  }

  findAttendeeByID(userID: UserID): EventAttendeeV2 | undefined {
    return this.list.find(att => att.id.equals(userID));
  }

  concat(another: EventAttendeesV2): EventAttendeesV2 {
    return new EventAttendeesV2([...this.list, ...another.list]);
  }

  isEmpty(): boolean {
    return this.list.length === 0;
  }

  /**
   * anotherと比較し、重複しているものを取り除く
   * @param another 比較対象
   */
  removeDuplicationByComparingWith(another: EventAttendeesV2): EventAttendeesV2 {
    return new EventAttendeesV2(this.list.filter(item => {
      return another.list
        .filter(context => context.id.equals(item.id))
        .length === 0;
    }));
  }
}
