import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'sn-login-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  template: `
    <button
      mat-raised-button
      color="primary"
      class="login-btn"
      [disabled]="disabled"
    >ログイン</button>
  `,
  styles: [`
    .login-btn {
      width: 100%;
      height: 48px;
      font-size: 16px;
    }
  `]
})
export class LoginButtonComponent {
  @Input()
  disabled = false;
}
