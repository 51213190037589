import { HintContent, HintID, HintTitle } from "app/model/hint/hint";
import { Observable } from "rxjs";

export type AddHintInputs = {
  title: HintTitle,
  content: HintContent,
};

export type UpdateHintInputs = {
  id: HintID,
} & AddHintInputs;

export abstract class BaseHintCommandService {
  abstract add(inputs: AddHintInputs): Observable<void>;

  abstract update(inputs: UpdateHintInputs): Observable<void>;

  abstract delete(id: HintID): Observable<void>;
}
