import { Observable } from "rxjs";
import { BuiltinFieldItem, CustomFieldItem, FormLayoutCategory, FormLayoutName, SectionTitleItem } from "./form-layout";

export interface CreateFormLayoutParam {
  category: FormLayoutCategory;
  name: FormLayoutName;
  items: (
    | SectionTitleItem
    | CustomFieldItem
    | BuiltinFieldItem
  )[]
};

export abstract class FormLayoutFactory {
  abstract create(param: CreateFormLayoutParam): Observable<void>;
}
