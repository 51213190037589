import {Observable} from "rxjs";
import {ProposalStatusDescription, ProposalStatusID, ProposalStatusName} from "app/model/proposal-status/proposal-status";

export type ProposalStatusInputs = {
  name: ProposalStatusName,
  description: ProposalStatusDescription,
}

export abstract class ProposalStatusCommandService {
  abstract update(id: ProposalStatusID, inputs: ProposalStatusInputs): Observable<void>;
}
