import {
  ActionList,
  ActionListItem,
  ActionQueryService,
  SearchMyUnreportedActionsInput,
} from '#application/services/action-query.service';
import { AuthorizeService } from '#application/services/authorize.service';
import { ServerApiError, ServerApiErrorCode } from '#infrastructure/api/error';
import {
  SearchActionFilter,
  SearchActionRequest,
  SearchActionResponseV2,
} from '#infrastructure/api/server-action-list-api';
import { ServerApi } from '#infrastructure/api/server-api';
import { getActionResponseToAction } from '#infrastructure/model/action.repository';
import { Injectable } from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import {
  Action,
  ActionAttendee,
  ActionAttendees,
  ActionEndDateTime,
  ActionID,
  ActionNote,
  ActionStartDateTime,
  IsDone
} from 'app/model/action/action';
import { ActionTypeID, ActionTypeName } from 'app/model/action/action-type';
import { ContactDisplayName, ContactID, ContactRoleName, DepartmentName } from "app/model/contact/contact";
import { OpportunityID, OpportunityName, } from 'app/model/opportunity/opportunity';
import { PhaseID, PhaseName } from 'app/model/sales-phase/sales-phase';
import { DisplayName, UserID } from 'app/model/user/user';
import { DateTime } from 'luxon';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ActionQueryServiceImpl implements ActionQueryService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly authorizeService: AuthorizeService
  ) {
  }

  searchMyUnreportedActions(
    input: SearchMyUnreportedActionsInput
  ): Observable<ActionList> {
    const filter: SearchActionFilter[] = [];
    const authInfo = this.authorizeService.getAuthInfo();
    filter.push({
      propertyName: 'assignee',
      type: 'anyMatch',
      values: [authInfo.id],
    });
    filter.push({
      propertyName: 'isDone',
      type: 'equals',
      value: 'false',
    });
    if (input.opportunityName) {
      filter.push({
        propertyName: 'opportunityName',
        type: 'contains',
        value: input.opportunityName,
      });
    }
    const request: SearchActionRequest = {
      filter: filter,
      perPage: input.perPage,
      page: input.page,
    };
    return this.serverApi.actionListApi.search2(request).pipe(
      map((value) => {
        return {
          totalCount: value.totalCount,
          results: convertActionList(value.results),
        };
      })
    );
  }

  get(id: ActionID): Observable<Result<
    Action,
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.actionApi.get(id.value).pipe(
      map(res => Success(
        getActionResponseToAction(res)
      )),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.NotFound:
            return of(Failure(GeneralFailure.NotFound));
          default:
            return of(Failure(GeneralFailure.Unexpected));
        }
      })
    );
  }
}

const toJSDate = (dateString: string): Date => {
  return DateTime.fromFormat(dateString, 'yyyy-MM-dd HH:mm:ss').toJSDate();
};

const convertActionList = (
  response: SearchActionResponseV2
): ActionListItem[] => {
  return response.map((item) => {
    return {
      actionId: new ActionID(item.actionID),
      companions: item.companions ?
        item.companions.map(c => ({
          userID: new UserID(c.userID),
          userDisplayName: c.userDisplayName
        })) :
        [],
      attendees: new ActionAttendees(item.attendees ?
        item.attendees.map(a => new ActionAttendee(
          new ContactID(a.contactID),
          new ContactDisplayName(a.contactDisplayName),
          new DepartmentName(a.departmentName),
          new ContactRoleName(a.roleName)
        )) : []
      ),
      opportunityId: new OpportunityID(item.opportunityID),
      opportunityName: new OpportunityName(item.opportunityName),
      actionStartDateTime: new ActionStartDateTime(
        toJSDate(item.actionStartDateTime)
      ),
      actionEndDateTime: new ActionEndDateTime(
        toJSDate(item.actionEndDateTime)
      ),
      actionTypeId: new ActionTypeID(item.actionTypeID),
      actionTypeName: new ActionTypeName(item.actionTypeName),
      currentPhaseId: new PhaseID(item.currentPhaseID),
      currentPhaseName: new PhaseName(item.currentPhaseName),
      startPhaseId: new PhaseID(item.startPhaseID),
      startPhaseName: new PhaseName(item.startPhaseName),
      targetPhaseId: new PhaseID(item.targetPhaseID),
      targetPhaseName: new PhaseName(item.targetPhaseName),
      actualStartPhaseId: item.actualStartPhaseID
        ? new PhaseID(item.actualStartPhaseID)
        : undefined,
      actualStartPhaseName: item.actualStartPhaseName
        ? new PhaseName(item.actualStartPhaseName)
        : undefined,
      actualPhaseId: item.actualPhaseID
        ? new PhaseID(item.actualPhaseID)
        : undefined,
      actualPhaseName: item.actualPhaseName
        ? new PhaseName(item.actualPhaseName)
        : undefined,
      actionNote: new ActionNote(item.actionNote),
      assignee: new UserID(item.assignee),
      assigneeName: new DisplayName(item.assigneeName),
      isDone: new IsDone(item.isDone),
    };
  });
};
