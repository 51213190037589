import { AssignedOrganizationType, Consultant, ConsultantID, DisplayName, MailAddress, ConsultantLoginID } from "app/model/consultant/consultant";
import { OrganizationName } from "app/model/organization/organization";
import { DateTime } from "luxon";
import { Observable } from "rxjs";

export type ConsultantListItem = {
  id: ConsultantID,
  loginID: ConsultantLoginID,
  displayName: DisplayName,
  mailAddress: MailAddress,
  assignedOrganizationType: AssignedOrganizationType,
  selectedOrganizationNames?: OrganizationName[],
  lastLoginDateTime?: DateTime,
};
export type ConsultantList = ConsultantListItem[];

export abstract class ConsultantQueryService {
  abstract list(): Observable<ConsultantList>;

  abstract get(id: ConsultantID): Observable<Consultant>;
}
