import {ServerApi} from '#infrastructure/api/server-api';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import { OpportunityContextID, OpportunityContextName } from 'app/model/opportunity-context/opportunity-context';
import { InsightID, InsightQuestion } from 'app/model/insight/insight';
import { IncorporatedInsightQueryService, InsightListForIncorporation, SearchInsightListInputs } from '#application/services/incorporated-insight-query.service';
import { SearchIncorporatedInsightListRequest } from '#infrastructure/api/server-incorporated-insight-api';
import { convertCoachingTiming } from './insight-query.service';

@Injectable({
  providedIn: 'root',
})
export class IncorporatedInsightQueryServiceImpl extends IncorporatedInsightQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  findBy(inputs: SearchInsightListInputs): Observable<InsightListForIncorporation> {
    return this.serverApi.incorporatedInsightApi
    .search(convertInputsToRequestAllowedUndefined(inputs))
    .pipe(
      map(data => ({
        totalCount: data.totalCount,
        results: data.results.map(result => ({
          id: new InsightID(result.id),
          coachingTiming: convertCoachingTiming(result.coachingTiming),
          question: new InsightQuestion(result.question),
          incorporated: result.incorporated,
          triggeringOpportunityContexts: result.triggeringOpportunityContexts.map(v => ({
            id: new OpportunityContextID(v.id),
            name: new OpportunityContextName(v.name),
          })),
        }))
      }))
    );
  }
}

const convertInputsToRequestAllowedUndefined =
(inputs: SearchInsightListInputs): SearchIncorporatedInsightListRequest => {
  return {
    perPage: inputs.perPage,
    page: inputs.page,
    filter: {
      question: inputs.filter.question.value.length === 0
      ? undefined
      : {
        type: inputs.filter.question.type,
        value: inputs.filter.question.value,
      },
      opportunityContextID: inputs.filter.opportunityContextID.values.length === 0
      ? undefined
      : {
        type: inputs.filter.opportunityContextID.type,
        values: inputs.filter.opportunityContextID.values
      },
      incorporated: inputs.filter.incorporated.values.length === 0
      ? undefined
      : {
        type: inputs.filter.incorporated.type,
        values: inputs.filter.incorporated.values
      },
      coachingTiming: inputs.filter.coachingTiming.values.length === 0
      ? undefined
      : {
        type: inputs.filter.coachingTiming.type,
        values: inputs.filter.coachingTiming.values
      },
    }
  };
};
