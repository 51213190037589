import { Brand } from "app/lib/brand/brand";
import { DateTime } from "luxon";
import { BrandedModelTypes } from "../model-types";
import { DisplayName, UserID } from "../user/user";
import { EventAttendeesV2 } from "./event-attendee";
import { ScheduleRecurringRule } from "../schedule/schedule-recurring-rule";

/**
 * イベント（繰り返しの中の特定日のイベントを除く）
 */
export type EventV2 = Readonly<{
  type: typeof EventType.EVENT,
  id: EventIDV2,
  eventVersion: EventVersion,
  name: EventNameV2,
  ownerID: UserID,
  ownerName: DisplayName,
  start: EventStart,
  end: EventEnd,
  rangeType: EventRangeType,
  recurringRule: EventRecurringRule,
  requiredAttendees: EventAttendeesV2,
  optionalAttendees: EventAttendeesV2,
  place: EventPlaceV2,
  url: EventUrlV2,
  note: EventNoteV2,
}>;

/**
 * 繰り返しの中の特定日のイベント。
 * スケジュール上はどのイベントもbaseStartを持つことから、
 * イベント詳細ではこのイベントを参照する。
 */
export type EventOnBaseStart = Readonly<{
  type: typeof EventType.ON_BASE_START,
  id: EventIDV2,
  eventVersion: EventVersion,
  exceptionalEventVersion: ExceptionalEventVersion,
  name: EventNameV2,
  ownerID: UserID,
  ownerName: DisplayName,
  ownerIsActive: boolean,
  start: EventStart,
  end: EventEnd,
  rangeType: EventRangeType,
  recurringRule: EventRecurringRule,
  requiredAttendees: EventAttendeesV2,
  optionalAttendees: EventAttendeesV2,
  place: EventPlaceV2,
  url: EventUrlV2,
  note: EventNoteV2,
}>;

export const EventType = {
  EVENT: 'EVENT',
  ON_BASE_START: 'ON_BASE_START',
  COPY: 'COPY',
} as const;
export type EventType = typeof EventType[keyof typeof EventType];

export type EventIDV2 = Brand<string, 'EventID'>;
export function EventIDV2(value: string) {
  return value as EventIDV2;
}

export class EventBaseStart {
  readonly brand = BrandedModelTypes.EventBaseStart;
  constructor(readonly value: Date) {}

  toString() {
    return DateTime.fromJSDate(this.value).toISO({ includeOffset: true });
  }
}

export type EventVersion = Brand<number, 'EventVersion'>;
export function EventVersion(value: number) {
  return value as EventVersion;
}

export type ExceptionalEventVersion = Brand<number, 'ExceptionalEventVersion'>;
export function ExceptionalEventVersion(value: number) {
  return value as ExceptionalEventVersion;
}

export type EventNameV2 = Brand<string, 'EventName'>;
export function EventNameV2(value: string) {
  return value as EventNameV2;
}

export type EventStart = Brand<Date, 'EventStart'>;
export function EventStart(value: Date) {
  return value as EventStart;
}

export type EventEnd = Brand<Date, 'EventEnd'>;
export function EventEnd(value: Date) {
  return value as EventEnd;
}

export const EventRangeType = {
  MINUTES: 'MINUTES',
  DAYS: 'DAYS',
} as const;
export type EventRangeType = typeof EventRangeType[keyof typeof EventRangeType];

export const RecurringRuleType = {
  NEVER: 'NEVER',
  RECURRING: 'RECURRING',
} as const;
export type RecurringRuleType = typeof RecurringRuleType[keyof typeof RecurringRuleType];

export const DayOfWeek = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
} as const;
export type DayOfWeek = typeof DayOfWeek[keyof typeof DayOfWeek];
export const DayOfWeeksLabel: Record<DayOfWeek, string> = {
  MONDAY: '月',
  TUESDAY: '火',
  WEDNESDAY: '水',
  THURSDAY: '木',
  FRIDAY: '金',
  SATURDAY: '土',
  SUNDAY: '日',
};

export type EventNeverRecurringRule = {
  type: typeof RecurringRuleType.NEVER,
};
export type EventRecurringRecurringRule = {
  type: typeof RecurringRuleType.RECURRING,
  until?: EventRecurringUntil,
  dayOfWeeks: DayOfWeek[],
};
export type EventRecurringRule = EventNeverRecurringRule | EventRecurringRecurringRule;

export type EventRecurringUntil = Brand<Date, 'EventRecurringUntil'>;
export function EventRecurringUntil(value: Date) {
  return value as EventRecurringUntil;
}

export type EventPlaceV2 = Brand<string, 'EventPlace'>;
export function EventPlaceV2(value: string) {
  return value as EventPlaceV2;
}

export type EventUrlV2 = Brand<string, 'EventUrl'>;
export function EventUrlV2(value: string) {
  return value as EventUrlV2;
}

export type EventNoteV2 = Brand<string, 'EventNote'>;
export function EventNoteV2(value: string) {
  return value as EventNoteV2;
}
export namespace EventNoteV2 {
  export const MAX_LENGTH = 3000
}

/**
 * 関数を通してしか作成できないようにしたいため、exportしない。
 */
type _EventCopy = Readonly<{
  type: typeof EventType.COPY,
  id: EventIDV2,
  name: EventNameV2,
  ownerID: UserID,
  ownerName: DisplayName,
  start: EventStart,
  end: EventEnd,
  rangeType: EventRangeType,
  recurringRule: EventRecurringRule,
  requiredAttendees: EventAttendeesV2,
  optionalAttendees: EventAttendeesV2,
  place: EventPlaceV2,
  url: EventUrlV2,
  note: EventNoteV2,
}>;

/**
 * イベントのコピーを作成する。
 */
export function EventCopy(event: EventV2, scheduleRecurringRule: ScheduleRecurringRule): _EventCopy {
  const newEventID = EventIDV2('');
  const newEventName = EventNameV2(`${event.name}のコピー`);
  const startDT = DateTime.fromJSDate(event.start);
  const endDT = DateTime.fromJSDate(event.end);
  const today = DateTime.local();
  const copiedStartDT = startDT.set({
    year: today.year,
    month: today.month,
    day: today.day,
  });
  const copiedEndDT = event.rangeType === EventRangeType.MINUTES
    ? copiedStartDT.plus({ minutes: endDT.diff(startDT, 'minutes').minutes })
    : copiedStartDT.plus({ days: endDT.diff(startDT, 'days').days });
  const recurringRule = scheduleRecurringRule === ScheduleRecurringRule.EXCEPTIONAL
    ? { type: RecurringRuleType.NEVER }
    : event.recurringRule;
  return {
    ...event,
    type: EventType.COPY,
    id: newEventID,
    name: newEventName,
    start: EventStart(copiedStartDT.toJSDate()),
    end: EventEnd(copiedEndDT.toJSDate()),
    recurringRule,
  } satisfies EventCopy;
}

/**
 * イベントのコピーを表す型。
 */
export type EventCopy = ReturnType<typeof EventCopy>;
