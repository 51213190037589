import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { GoalCategory, MonthlyGoal } from "app/model/report/report-goal";
import { DateTime } from "luxon";
import { Observable } from "rxjs";

export type SaveGoalSettingInput = {
  fiscalYear: DateTime,
  goals: MonthlyGoal[],
};

export abstract class GoalSettingCommandService {
  abstract save(category: GoalCategory, input: SaveGoalSettingInput): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >>;
}
