<mat-toolbar color="primary">
  <button mat-icon-button *ngIf="showMenuToggle" (click)="clickToggle.emit()">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="toolbar-spacer"></span>
  <h1 class="sn-app-title">
    <img class="title" src="/assets/img/type.svg" alt="SalesNaviのロゴ画像"/>
  </h1>
  <div *ngIf="env.msw" class="msw">MSW起動中</div>
  <span class="toolbar-spacer"></span>
  <ng-container *ngIf="login">
    <button
      mat-icon-button
      [matMenuTriggerFor]="helpMenu"
    >
      <mat-icon>help_center</mat-icon>
    </button>
    <mat-menu #helpMenu="matMenu">
      <button mat-menu-item (click)="openUserManualPage()">
        ユーザーマニュアルへ
        <mat-icon>open_in_new</mat-icon>
      </button>
    </mat-menu>
    <button
      (click)="showAccountMenu = !showAccountMenu"
      mat-icon-button
      class="logout-icon"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin">
      <mat-icon>account_circle</mat-icon>
    </button>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="showAccountMenu"
      (overlayOutsideClick)="showAccountMenu = false"
    >
      <div class="account-menu">
        <div class="account-menu-group">
          <div class="organization-name">{{authInfo?.organizationName}}</div>
          <div class="display-name">{{authInfo?.displayName}}</div>
          <div
            class="mail-address"
            matTooltip="{{authInfo?.mailAddress}}"
            matTooltipPosition="left"
          >{{authInfo?.mailAddress}}</div>
        </div>
        <button mat-button
          *ngIf="showCalendarSetting"
          class="calendar-button"
          (click)="calendarSetting()">
          カレンダー設定
        </button>
        <button mat-button
                class="logout-button"
                color="warn"
                (click)="logout()">
          ログアウト
        </button>
      </div>
    </ng-template>
  </ng-container>
</mat-toolbar>
