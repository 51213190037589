import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

export interface CreateClientProductRelationshipRequestData {
  clientID: string;
  productID: string;
}

export type CreateClientProductRelationshipRequest = CreateClientProductRelationshipRequestData;

export class ClientProductRelationshipApi {
  constructor(private readonly http: HttpClient) {}

  create(request: CreateClientProductRelationshipRequest): Observable<void> {
    return this.http.post<void>(`/api/client-product-relationships`, request);
  }

  delete(clientProductRelationshipID: string): Observable<void> {
    return this.http.delete<void>(`/api/client-product-relationships/${clientProductRelationshipID}`);
  }
}
