import { MeetingBoardSettingID } from "../meeting-board/meeting-board";

export const MEETING_BOARD_KEY_NAME = 'MEETING_BOARD';

/**
 * ミーティングボードの最後の閲覧履歴
 * @property id 最後に閲覧したミーティングボード（設定）のID
 * @property dateSpan 表示対象期間（開始年月と月数）
 */
export type MeetingBoardLastBrowsingHistory = Readonly<{
  id: MeetingBoardSettingID,
  dateSpan: {
    start: string,
    months: number,
  },
}>