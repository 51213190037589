import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GetContactHistoriesResponse} from "#infrastructure/api/server-contact-history-api";

export class OpportunityContactHistoryApi {
  constructor(private readonly http: HttpClient) {
  }

  list(clientID: string, opportunityID: string): Observable<GetContactHistoriesResponse> {
    return this.http.get<GetContactHistoriesResponse>(`/api/clients/${clientID}/opportunities/${opportunityID}/contact-histories`);
  }
}
