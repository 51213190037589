import {ActionTypeID, ActionTypeName} from 'app/model/action/action-type';
import {DateTime} from 'luxon';
import {Observable} from 'rxjs';
import {UserGroupID} from "app/model/user-group/user-group";
import {DisplayName, UserID} from "app/model/user/user";
import {ActionEndDateTime, ActionID, ActionStartDateTime} from "app/model/action/action";
import {OpportunityID, OpportunityName, OpportunityNumber} from "app/model/opportunity/opportunity";
import {PhaseID, PhaseName} from "app/model/sales-phase/sales-phase";

// input: 活動量レポートのデータ型
export type GetActivityReportInput = {
  duration: MonthlyDuration | DailyDuration,
  actionTypeIDs: ActionTypeID[],
  userGroupID?: UserGroupID,
}

// input: ユーザーごとの活動量レポートのデータ型
export type GetActivityReportPerUserInput = {
  pagination: {
    perPage: number,
    page: number,
  },
  duration: MonthlyDuration | DailyDuration,
  actionTypeIDs: ActionTypeID[],
  userID: UserID,
}

// input: マイデータ活動量のデータ型
export type GetPersonalActivityReportInput = Omit<GetActivityReportPerUserInput, 'userID'>;

export type MonthlyDuration = {
  startYearMonth: DateTime,
  months: number
}

export type DailyDuration = {
  startDate: DateTime,
  days: number
}

// output: 活動量レポートのデータ型
export type ActivityReportOutput = {
  times: ActivityReport,
  timesPerUser: ActivityReportPerUser[],
  counts: ActivityReport,
  countsPerUser: ActivityReportPerUser[],
}

// output: ユーザーごとの活動量レポートのデータ型
export type ActivityReportPerUserOutput = {
  times: ActivityReportPerUser,
  counts: ActivityReportPerUser,
  activities: Activities,
}

// output: マイデータ活動量のデータ型
export type PersonalActivityReportOutput = {
  times: ActivityReport,
  counts: ActivityReport,
  activities: Activities,
};

export type ActivityReport = {
  perDate: ActivityReportPerDate[],
  perType: ActivityReportPerActionType,
  total: number,
}

export type ActivityReportPerUser = {
  user: {
    id: UserID,
    name: DisplayName,
    isActive: boolean,
  },
  perDate: ActivityReportPerDate[],
  perType: ActivityReportPerActionType,
  total: number,
}

export type ActivityReportPerDate = {
  date: DateTime,
  values: {
    [actionTypeID: string]: number
  },
  total: number,
}

export type ActivityReportPerActionType = {
  [actionTypeID: string]: number
}

// 活動一覧のデータ型
export type Activities = {
  totalCount: number,
  results: ActivitiesResults,
}
export type ActivitiesResults = {
  actionID: ActionID,
  opportunityID: OpportunityID,
  opportunityName: OpportunityName,
  opportunityNumber: OpportunityNumber,
  isDone: boolean,
  actionStartDateTime: ActionStartDateTime,
  actionEndDateTime: ActionEndDateTime,
  actionTime: number,
  currentPhaseID: PhaseID,
  currentPhaseName: PhaseName,
  startPhaseID: PhaseID,
  startPhaseName: PhaseName,
  targetPhaseID: PhaseID,
  targetPhaseName: PhaseName,
  actualStartPhaseID?: PhaseID,
  actualStartPhaseName?: PhaseName,
  actualPhaseID?: PhaseID,
  actualPhaseName?: PhaseName,
  actionTypeName: ActionTypeName,
}[];

export abstract class ReportActivityV2QueryService {
  abstract getActivityReport(input: GetActivityReportInput): Observable<ActivityReportOutput>;
  abstract getActivityReportPerUser(input: GetActivityReportPerUserInput): Observable<ActivityReportPerUserOutput>;
  abstract getPersonalActivityReport(input: GetPersonalActivityReportInput): Observable<PersonalActivityReportOutput>;
}
