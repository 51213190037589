import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

export type ApiMetaUserSecurityRole =
  | 'CONSULTANT'
  | 'ORGANIZATION_CONSULTANT'
  | 'ADMIN'

export interface MetaUserSignInRequest {
  userID: string;
  password: string;
}

export interface MetaUserSignInResponse {
  userID: string;
  // FIXME バックエンドと同時にloginIDに変更する
  userName: string;
  name: string;
  mailAddress: string;
  securityRoles: ApiMetaUserSecurityRole[];
}

export class MetaUserAuthApi {
  constructor(private readonly http: HttpClient) {}

  signIn(request: MetaUserSignInRequest): Observable<MetaUserSignInResponse> {
    return this.http.post<MetaUserSignInResponse>('/api/sign-in-as-consultant', request);
  }
}
