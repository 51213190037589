import {
  CorporationClientBuiltinFieldQueryService,
  CorporationClientFormLayoutQueryService,
  IndividualClientBuiltinFieldQueryService,
  IndividualClientFormLayoutQueryService
} from "#application/services/client-form-layout-query.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {
  BuiltinField,
  BuiltinFieldId,
  BuiltinFieldItem,
  CustomFieldItem,
  DefaultFormLayout,
  FormLayout,
  FormLayoutCategory,
  FormLayoutId,
  FormLayoutName,
  FormLayoutWidth,
  SectionTitle,
  SectionTitleItem
} from "app/model/form-layout/form-layout";
import {ServerApi} from "#infrastructure/api/server-api";
import {map} from "rxjs/operators";
import {FieldType} from "app/model/custom-field/field-type";
import {FormLayoutOutline} from "#application/services/form-layout-query.service";
import {CustomFieldId} from "app/model/custom-field/custom-field";
import {GetDefaultFormLayoutResponse} from "#infrastructure/api/server-default-form-layout-api";
import {ListBuiltinFieldResponse} from "#infrastructure/api/server-builtin-field-api";
import {
  GetFormLayoutResponse,
  ListFormLayoutResponse
} from "#infrastructure/api/server-form-layout-api";

@Injectable({
  providedIn: 'root'
})
export class CorporationClientBuiltinFieldQueryServiceImpl extends CorporationClientBuiltinFieldQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  list(): Observable<BuiltinField[]> {
    return this.serverApi.builtinFieldApi.list(FormLayoutCategory.CORPORATION_CLIENT)
    .pipe(
      map(value => responseToBuiltinFields(FormLayoutCategory.CORPORATION_CLIENT, value))
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class CorporationClientFormLayoutQueryServiceImpl extends CorporationClientFormLayoutQueryService {

  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  getDefaultLayout(): Observable<DefaultFormLayout> {
    return this.serverApi.defaultFormLayoutApi.get(FormLayoutCategory.CORPORATION_CLIENT)
    .pipe(
      map(value => responseToDefaultFormLayout(FormLayoutCategory.CORPORATION_CLIENT, value))
    );
  }

  get(id: FormLayoutId): Observable<FormLayout> {
    return this.serverApi.formLayoutApi.get(FormLayoutCategory.CORPORATION_CLIENT, id.value)
    .pipe(
      map(value => responseToFormLayout(FormLayoutCategory.CORPORATION_CLIENT, value))
    );
  }

  list(): Observable<FormLayoutOutline[]> {
    return this.serverApi.formLayoutApi.list(FormLayoutCategory.CORPORATION_CLIENT)
    .pipe(
      map(response => response.map(e => ({
        id: new FormLayoutId(e.id),
        name: new FormLayoutName(e.name)
      })))
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class IndividualClientBuiltinFieldQueryServiceImpl extends IndividualClientBuiltinFieldQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  list(): Observable<BuiltinField[]> {
    return this.serverApi.builtinFieldApi.list(FormLayoutCategory.INDIVIDUAL_CLIENT)
    .pipe(
      map(value => responseToBuiltinFields(FormLayoutCategory.INDIVIDUAL_CLIENT, value))
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export class IndividualClientFormLayoutQueryServiceImpl extends IndividualClientFormLayoutQueryService {

  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  getDefaultLayout(): Observable<DefaultFormLayout> {
    return this.serverApi.defaultFormLayoutApi.get(FormLayoutCategory.INDIVIDUAL_CLIENT)
    .pipe(
      map(value => responseToDefaultFormLayout(FormLayoutCategory.INDIVIDUAL_CLIENT, value))
    );
  }

  get(id: FormLayoutId): Observable<FormLayout> {
    return this.serverApi.formLayoutApi.get(FormLayoutCategory.INDIVIDUAL_CLIENT, id.value)
    .pipe(
      map(value => responseToFormLayout(FormLayoutCategory.INDIVIDUAL_CLIENT, value))
    );
  }

  list(): Observable<FormLayoutOutline[]> {
    return this.serverApi.formLayoutApi.list(FormLayoutCategory.INDIVIDUAL_CLIENT)
    .pipe(
      map(responseToFormLayoutOutlines)
    );
  }
}

const responseToBuiltinFields = (category: FormLayoutCategory, response: ListBuiltinFieldResponse): BuiltinField[] => {
  return response.map(e => new BuiltinField(
    BuiltinFieldId.valueOf(e.id, category),
    FieldType[e.fieldType],
    e.needsToDisplay
  ));
}

const responseToDefaultFormLayout = (category: FormLayoutCategory, response: GetDefaultFormLayoutResponse): DefaultFormLayout => {
  const items = response.items.map(e => {
    switch (e.type) {
      case "BUILTIN_FIELD":
        return new BuiltinFieldItem(FormLayoutWidth[e.width], BuiltinFieldId.valueOf(e.builtinFieldID, category));
      case "SECTION_TITLE":
        return new SectionTitleItem(new SectionTitle(e.title))
      default:
        const unexpected: never = e;
        throw Error(`${unexpected} is unexpected value`);
    }
  });
  return new DefaultFormLayout(items);
};

const responseToFormLayout = (category: FormLayoutCategory, response: GetFormLayoutResponse): FormLayout => {
  const items = response.items.map(e => {
    switch (e.type) {
      case "BUILTIN_FIELD":
        return new BuiltinFieldItem(FormLayoutWidth[e.width], BuiltinFieldId.valueOf(e.builtinFieldID, category));
      case "CUSTOM_FIELD":
        return new CustomFieldItem(FormLayoutWidth[e.width], new CustomFieldId(e.customFieldID));
      case "SECTION_TITLE":
        return new SectionTitleItem(new SectionTitle(e.title));
      default:
        const unexpected: never = e;
        throw Error(`${unexpected} is unexpected value`);
    }
  });
  return new FormLayout(
    new FormLayoutId(response.id),
    new FormLayoutName(response.name),
    items
  );
};

const responseToFormLayoutOutlines = (response: ListFormLayoutResponse): FormLayoutOutline[] => response.map(e => ({
  id: new FormLayoutId(e.id),
  name: new FormLayoutName(e.name)
}));
