import { FilterInput } from "#infrastructure/application/filter.service";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { OpportunitiesWithTotalCountPerPhase } from "app/model/opportunity-board/opportunity-board";
import { PhaseID } from "app/model/sales-phase/sales-phase";
import { Observable } from "rxjs";

export type FilterOpportunitiesPerPhaseInputs = {
  perPage: number,
  page: number,
  filterInput: FilterInput,
};

export abstract class OpportunityPerPhaseQueryService {
  /**
   * フェーズごとの商談一覧取得。
   */
  abstract filter(
    inputs: FilterOpportunitiesPerPhaseInputs,
    phaseIDs: PhaseID[],
  ): Observable<Result<
    OpportunitiesWithTotalCountPerPhase,
    | typeof GeneralFailure.Unexpected
  >>;
}
