import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

export interface ListActionTypeData {
  id: string;
  name: string;
  description: string;
}

export type ListActionTypeResponse = ListActionTypeData[];

export interface SaveAllActionTypeData {
  id?: string;
  name: string;
  description: string;
}

export type SaveAllActionTypeRequest = SaveAllActionTypeData[];

interface CreateActionTypeRequestData {
  name: string,
  description: string,
}

export type CreateActionTypeRequest = CreateActionTypeRequestData;

interface GetActionTypeResponseData {
  id: string,
  name: string,
  description: string,
}

export type GetActionTypeResponse = GetActionTypeResponseData;

interface UpdateActionTypeRequestData {
  name: string,
  description: string,
}

export type UpdateActionTypeRequest = UpdateActionTypeRequestData;

interface SortActionTypeRequestData {
  ids: string[]
}

export type SortActionTypeRequest = SortActionTypeRequestData;

export class ActionTypeApi {
  constructor(
    private readonly http: HttpClient
  ) {
  }

  list(): Observable<ListActionTypeResponse> {
    return this.http.get<ListActionTypeResponse>('/api/action-types');
  }

  saveAll(request: SaveAllActionTypeRequest): Observable<void> {
    return this.http.post<void>('/api/action-types', request);
  }

  create(request: CreateActionTypeRequest): Observable<void> {
    return this.http.post<void>(`/api/action-types`, request);
  }

  get(id: string): Observable<GetActionTypeResponse> {
    return this.http.get<GetActionTypeResponse>(`/api/action-types/${id}`);
  }

  update(id: string, request: UpdateActionTypeRequest): Observable<void> {
    return this.http.put<void>(`/api/action-types/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/action-types/${id}`);
  }

  sort(request: SortActionTypeRequest): Observable<void> {
    return this.http.patch<void>(`/api/action-types/sort`, request);
  }
}
