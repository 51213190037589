import {
  FollowUpActionTypeDescription,
  FollowUpActionTypeID,
  FollowUpActionTypeName
} from "app/model/follow-up-action/follow-up-action-type";
import {Observable} from "rxjs";

export type FollowUpActionTypeListItem = Readonly<{
  id: FollowUpActionTypeID,
  name: FollowUpActionTypeName,
  description: FollowUpActionTypeDescription,
}>

export type FollowUpActionTypeList = FollowUpActionTypeListItem[];

export type FollowUpActionTypeDetail = Readonly<{
  id: FollowUpActionTypeID,
  name: FollowUpActionTypeName,
  description: FollowUpActionTypeDescription,
}>

export abstract class FollowUpActionTypeQueryService {
  abstract list(): Observable<FollowUpActionTypeList>;

  abstract get(id: FollowUpActionTypeID): Observable<FollowUpActionTypeDetail>
}
