import { OrganizationManageService } from "#application/services/organization-manage.service";
import { Organization, OrganizationID } from "app/model/organization/organization";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Observable, of } from "rxjs";
import { concatMap } from "rxjs/operators";
import { OrgOptionList } from "./assigned-organization";

@Component({
  selector: 'sn-consultant-assigned-organization',
  templateUrl: './assigned-organization.component.html',
  styleUrls: ['./assigned-organization.component.scss'],
})
export class ConsultantAssignedOrganizationComponent implements OnInit {

  @Input()
  savedAssignedOrgs?: OrganizationID[];

  @Output()
  selectedOrgs = new EventEmitter<OrganizationID[]>();

  readonly searchOrgNameControl = new UntypedFormControl('');

  isOnlySelected = false;

  readonly orgOptionsGroup = new UntypedFormGroup({});

  initOrgOptionList = new OrgOptionList([]);
  orgOptionList = new OrgOptionList([]);

  readonly displayedColumns: string[] = [
    'selected',
    'code',
    'name'
  ];

  selectedOrgIDList: OrganizationID[] = [];

  constructor(
    private readonly organizationManageService: OrganizationManageService,
  ) {
    this.searchOrgNameControl.valueChanges.subscribe(() => {
      this.changeSearchState();
    });
  }

  ngOnInit(): void {
    this._fetchOrganizations().subscribe(() => {
      if (this.savedAssignedOrgs === undefined) return;
      this.orgOptionList = this.initOrgOptionList.reflectSelected(this.savedAssignedOrgs);
      this.selectedOrgIDList = this.savedAssignedOrgs;
      this.isOnlySelected = true;
      this.changeSearchState();
    });
  }

  private _fetchOrganizations(): Observable<Organization[]> {
    return this.organizationManageService.getOrganizations().pipe(
      concatMap(list => {
        this.initOrgOptionList = new OrgOptionList(list.map(item => ({
          selected: false,
          id: item.id,
          code: item.code,
          name: item.name,
        })));
        this.orgOptionList = this.initOrgOptionList.clone();
        return of(list);
      })
    );
  }

  onChangeSelectedOrg(id: OrganizationID, selected: boolean): void {
    if (selected === true) {
      this.selectedOrgIDList = [
        ...this.selectedOrgIDList,
        id
      ];
    } else {
      this.selectedOrgIDList = this.selectedOrgIDList.filter(item => !item.equals(id));
    }
    this.selectedOrgs.emit(this.selectedOrgIDList);
  }

  clearValues(): void {
    this.searchOrgNameControl.setValue('');
    this.changeSearchState();
  }

  changeSearchState(): void {
    this.orgOptionList =
      this.initOrgOptionList
      .reflectSelected(this.selectedOrgIDList)
      .searchOnlySelected(this.isOnlySelected)
      .searchOrgName(this.searchOrgNameControl.value);
  }
}
