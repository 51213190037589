import { ActionStartDateTime, ActionEndDateTime, ActionID } from "app/model/action/action"
import { CoachingID, CoachingTextAnswer, CoachingTiming } from "app/model/coaching/coaching"
import { InsightID, InsightQuestion, InsightAnswerSelectionType, InsightAnswer, InsightAnswerTextType, InsightAnswerID } from "app/model/insight/insight"
import { OpportunityID } from "app/model/opportunity/opportunity"
import { Observable } from "rxjs"

export type CoachingHistories = CoachingHistory[];

export type CoachingHistory = {
  actionID: ActionID,
  actionStartDateTime: ActionStartDateTime,
  actionEndDateTime: ActionEndDateTime,
  coaching: {
    pre: {
      coachingID: CoachingID | undefined,
      timing: typeof CoachingTiming.PRE,
      insights: CoachingHistoryInsightList,
    },
    post: {
      coachingID: CoachingID | undefined,
      timing: typeof CoachingTiming.POST,
      insights: CoachingHistoryInsightList,
    },
  },
};

export type CoachingHistoryInsightList = CoachingHistoryInsight[];

export type CoachingHistoryInsight = {
  insight: {
    id: InsightID,
    question: InsightQuestion,
  },
  reply: {
    type: typeof InsightAnswerSelectionType['value'],
    content: {
      answerOptions: {
        id: InsightAnswerID,
        answer: InsightAnswer,
      }[]
    }
  }
  | {
    type: typeof InsightAnswerTextType['value'],
    content: {
      value: CoachingTextAnswer
    }
  }
}

export abstract class OpportunityCoachingHistoryQueryService {
  abstract get(opportunityID: OpportunityID): Observable<CoachingHistories>;
}
