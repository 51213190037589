import { MeetingBoardStoreService } from "#application/services/meeting-board-store.service";
import { ClientApi } from "#infrastructure/api/client-api";
import { inject, Injectable } from "@angular/core";
import { MEETING_BOARD_KEY_NAME, MeetingBoardLastBrowsingHistory } from "app/model/storage/meeting-board";

@Injectable({providedIn: 'root'})
export class MeetingBoardStoreServiceImpl implements MeetingBoardStoreService {
  private readonly _clientApi = inject(ClientApi);

  getHistory(): MeetingBoardLastBrowsingHistory | null {
    return this._clientApi.lookupLocalStorage<MeetingBoardLastBrowsingHistory>(MEETING_BOARD_KEY_NAME);
  }

  storeHistory(value: MeetingBoardLastBrowsingHistory): void {
    this._clientApi.storeLocalStorage(MEETING_BOARD_KEY_NAME, value);
  }

  deleteHistory(): void {
    this._clientApi.deleteLocalStorage(MEETING_BOARD_KEY_NAME);
  }
}
