import { FormLayoutId } from "app/model/form-layout/form-layout";
import { Observable } from "rxjs";
import { ProductTypeDescription, ProductTypeName } from "./product-type";

export interface CreateProductTypeParam {
  name: ProductTypeName,
  description: ProductTypeDescription,
  formLayoutID?: FormLayoutId,
};

export abstract class ProductTypeFactory {
  abstract create(param: CreateProductTypeParam): Observable<void>;
}
