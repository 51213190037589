import { Brand } from "app/lib/brand/brand";
import { OpportunityGroupStatusID, OpportunityGroupStatusName } from "./opportunity-group-status";

export type OpportunityGroupWorkflow = Readonly<{
  id: OpportunityGroupWorkflowID,
  name: OpportunityGroupWorkflowName,
  description: OpportunityGroupWorkflowDescription,
  statuses: {
    id: OpportunityGroupStatusID,
    name: OpportunityGroupStatusName,
  }[],
}>;
export namespace OpportunityGroupWorkflow {
  export const STATUS_MAX_LENGTH: number = 10;
  export const STATUS_MIN_LENGTH: number = 1;
}

export type OpportunityGroupWorkflowID = Brand<string, 'OpportunityGroupWorkflowID'>;
export function OpportunityGroupWorkflowID(value: string) {
  return value as OpportunityGroupWorkflowID;
}

export type OpportunityGroupWorkflowName = Brand<string, 'OpportunityGroupWorkflowName'>;
export function OpportunityGroupWorkflowName(value: string) {
  return value as OpportunityGroupWorkflowName;
}
export namespace OpportunityGroupWorkflowName {
  export const MAX_LENGTH: number = 30;
}

export type OpportunityGroupWorkflowDescription = Brand<string, 'OpportunityGroupWorkflowDescription'>;
export function OpportunityGroupWorkflowDescription(value: string) {
  return value as OpportunityGroupWorkflowDescription;
}
export namespace OpportunityGroupWorkflowDescription {
  export const MAX_LENGTH: number = 100;
}
