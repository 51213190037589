import { FeatureTitleBarModule } from "#presentation/components/feature-title-bar/feature-title-bar.module";
import { GenericStringSearchFormModule } from "#presentation/components/generic-string-search-form/generic-string-search-form.module";
import { PreventMultipleClickModule } from "#presentation/directives/prevent-multiple-click/prevent-multiple-click.module";
import { MaterialModule } from "#presentation/material.module";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AdminContainerModule } from "../shared/admin-container/admin-container.module";
import { ConsultantAddComponent } from "./add/add.component";
import { ConsultantAssignedOrganizationComponent } from "./assigned-organization/assigned-organization.component";
import { ConsultantSettingRoutingModule } from "./consultant-setting-routing.module";
import { ConsultantDetailComponent } from "./detail/detail.component";
import { ConsultantListComponent } from "./list/list.component";

@NgModule({
  declarations: [
    ConsultantListComponent,
    ConsultantAddComponent,
    ConsultantDetailComponent,
    ConsultantAssignedOrganizationComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FeatureTitleBarModule,
    ConsultantSettingRoutingModule,
    GenericStringSearchFormModule,
    AdminContainerModule,
    PreventMultipleClickModule,
  ],
})
export class ConsultantSettingModule {}
