import {Observable} from 'rxjs';
import {LostReasonDescription, LostReasonID, LostReasonName} from './lost-reason';

export interface UpdateLostReasonParam {
  id: LostReasonID,
  name: LostReasonName,
  description: LostReasonDescription,
}

export abstract class LostReasonRepository {
  abstract save(param: UpdateLostReasonParam): Observable<void>;

  abstract remove(id: LostReasonID): Observable<void>;

  abstract saveOrders(ids: LostReasonID[]): Observable<void>;
}
