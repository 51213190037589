import { EventQueryV2Service } from "#application/services/event-query-v2.service";
import { ServerApiError, ServerApiErrorCode } from "#infrastructure/api/error";
import { ServerApi } from "#infrastructure/api/server-api";
import { ApiRecurringRule } from "#infrastructure/api/server-event-v2-api";
import { Injectable, inject } from "@angular/core";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Failure, Result, Success } from "app/lib/result/result";
import { EventAttendeeReply, EventAttendeesV2 } from "app/model/event/event-attendee";
import { EventBaseStart, EventEnd, EventIDV2, EventNameV2, EventNoteV2, EventOnBaseStart, EventPlaceV2, EventRecurringRule, EventStart, EventType, EventRecurringUntil, EventUrlV2, EventV2, EventVersion, ExceptionalEventVersion, RecurringRuleType } from "app/model/event/event-v2";
import { DisplayName, UserID } from "app/model/user/user";
import { Observable, catchError, map, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EventQueryV2ServiceImpl extends EventQueryV2Service {
  private readonly _serverApi = inject(ServerApi);

  get(id: EventIDV2): Observable<Result<
    EventV2,
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >>{
    return this._serverApi.eventV2Api.get(id).pipe(
      map(res => Success(
        {
          type: EventType.EVENT,
          id: EventIDV2(res.id),
          eventVersion: EventVersion(res.eventVersion),
          name: EventNameV2(res.name),
          ownerID: new UserID(res.ownerID),
          ownerName: new DisplayName(res.ownerName),
          start: EventStart(new Date(res.start)),
          end: EventEnd(new Date(res.end)),
          rangeType: res.rangeType,
          recurringRule: this._convertToRecurringRule(res.recurringRule),
          requiredAttendees: new EventAttendeesV2(
            res.requiredAttendees.map(v => ({
              id: new UserID(v.id),
              name: new DisplayName(v.name),
              isActive: v.isActive,
              reply: EventAttendeeReply.PENDING,
            }))
          ),
          optionalAttendees: new EventAttendeesV2(
            res.optionalAttendees.map(v => ({
              id: new UserID(v.id),
              name: new DisplayName(v.name),
              isActive: v.isActive,
              reply: EventAttendeeReply.PENDING,
            }))
          ),
          place: EventPlaceV2(res.place),
          url: EventUrlV2(res.url),
          note: EventNoteV2(res.note),
        } satisfies EventV2
      )),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.NotFound:
            return of(Failure(GeneralFailure.NotFound));
          default:
            return of(Failure(GeneralFailure.Unexpected));
        }
      })
    );
  }

  getOnBaseStart(id: EventIDV2, baseStart: EventBaseStart): Observable<Result<
    EventOnBaseStart,
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >>{
    return this._serverApi.eventV2Api.getOnBaseStart(id, baseStart.toString()).pipe(
      map(res => Success(
        {
          type: EventType.ON_BASE_START,
          id: EventIDV2(res.id),
          eventVersion: EventVersion(res.eventVersion),
          exceptionalEventVersion: ExceptionalEventVersion(res.exceptionalEventVersion),
          name: EventNameV2(res.name),
          ownerID: new UserID(res.ownerID),
          ownerName: new DisplayName(res.ownerName),
          ownerIsActive: res.ownerIsActive,
          start: EventStart(new Date(res.start)),
          end: EventEnd(new Date(res.end)),
          rangeType: res.rangeType,
          recurringRule: this._convertToRecurringRule(res.recurringRule),
          requiredAttendees: new EventAttendeesV2(
            res.requiredAttendees.map(v => ({
              id: new UserID(v.id),
              name: new DisplayName(v.name),
              isActive: v.isActive,
              reply: v.reply,
            }))
          ),
          optionalAttendees: new EventAttendeesV2(
            res.optionalAttendees.map(v => ({
              id: new UserID(v.id),
              name: new DisplayName(v.name),
              isActive: v.isActive,
              reply: v.reply,
            }))
          ),
          place: EventPlaceV2(res.place),
          url: EventUrlV2(res.url),
          note: EventNoteV2(res.note),
        } satisfies EventOnBaseStart
      )),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.NotFound:
            return of(Failure(GeneralFailure.NotFound));
          default:
            return of(Failure(GeneralFailure.Unexpected));
        }
      })
    );
  }

  private _convertToRecurringRule(recurringRule: ApiRecurringRule): EventRecurringRule {
    switch (recurringRule.type) {
      case 'NEVER':
        return { type: RecurringRuleType.NEVER };
      case 'RECURRING':
        return {
          type: RecurringRuleType.RECURRING,
          until: recurringRule.until == null
            ? undefined
            : EventRecurringUntil(new Date(recurringRule.until)),
          dayOfWeeks: recurringRule.dayOfWeeks,
        };
    }
  }
}
