import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type ListOpportunityFollowUpActionResponse = {
  id: string,
  createdBy: {
    id: string,
    name: string,
  },
  opportunity: {
    id: string,
    name: string,
  },
  due: string,
  followUpActionType: {
    id: string,
    name: string,
  },
  assignee: {
    id: string,
    name: string,
  },
  contentsSummary: string,
  isDone: boolean,
}[]

export class OpportunityFollowUpActionApi {
  constructor(private readonly http: HttpClient) {}

  list(opportunityID: string): Observable<ListOpportunityFollowUpActionResponse> {
    return this.http.get<ListOpportunityFollowUpActionResponse>(`/api/opportunities/${opportunityID}/follow-up-actions/list`);
  }
}