import { SearchFilterCategory, SearchFilterId } from "app/model/search-filter/search-filter";
import { CreateSearchFilterParam, SearchFilterFactory } from "app/model/search-filter/search-filter.factory";
import { ServerApi } from "#infrastructure/api/server-api";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class SearchFilterFactoryImpl extends SearchFilterFactory {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  create(param: CreateSearchFilterParam): Observable<void> {
    return this.serverApi.searchFilterApi.create(
      param.category,
      {
        filterName: param.filterName.value,
        description: param.description.value,
        visibility: {
          type: param.visibility.type
        },
        editability: {
          type: param.editability.type
        },
        filter: JSON.stringify(param.filter),
        sort: JSON.stringify(param.sorts),
        displayField: param.displayItems === undefined
          ? undefined
          : JSON.stringify(param.displayItems),
      }
    );
  }

  createBookmark(category: SearchFilterCategory, id: SearchFilterId): Observable<void> {
    return this.serverApi.searchFilterApi.createBookmark(category, id.value);
  }
}
