import { ConfirmService } from '#application/services/confirm.service';
import { MessageDialogService } from '#application/services/message-dialog.service';
import { NurturingFieldCommandService } from '#application/services/nurturing-field-command.service';
import { SnackBarService } from '#application/services/snack-bar.service';
import { CustomField, CustomFieldCategory, CustomFieldDescription, CustomFieldId, CustomFieldName, NumberSettings, SelectSettings } from 'app/model/custom-field/custom-field';
import { CreateCustomFieldParam, CustomFieldFactory } from 'app/model/custom-field/custom-field.factory';
import { CustomFieldRepository } from 'app/model/custom-field/custom-field.repository';
import { FieldType } from 'app/model/custom-field/field-type';
import { ServerApiError, ServerApiErrorCode } from '#infrastructure/api/error';
import { messageFn } from '#utils/messages';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, concatMap, filter, tap, throwError } from 'rxjs';
import {LocalizedNameService} from "#application/services/localized-name.service";
import {LocalizeKey} from "#application/services/authorize.service";

@Injectable({
  providedIn: 'root'
})
export class NurturingFieldCommandServiceImpl implements NurturingFieldCommandService {
  private readonly _customFieldFactory = inject(CustomFieldFactory);
  private readonly _customFieldRepository = inject(CustomFieldRepository);
  private readonly _snackBarService = inject(SnackBarService);
  private readonly _confirmService = inject(ConfirmService);
  private readonly _messageDialogService = inject(MessageDialogService);
  private readonly _localizedNameService = inject(LocalizedNameService);

  add(name: CustomFieldName, description: CustomFieldDescription, type: FieldType, required: boolean, numberSettings?: NumberSettings, selectSettings?: SelectSettings): Observable<void> {
    const param: CreateCustomFieldParam = {
      category: CustomFieldCategory.NURTURING,
      name,
      type,
      description,
      required,
      numberSetting: numberSettings,
      selectSetting: selectSettings
    }
    const nurturingField = `${this._localizedNameService.get(LocalizeKey.NURTURING)}項目`
    return this._customFieldFactory.create(param)
      .pipe(
        tap(() => this._snackBarService.show(messageFn(nurturingField, 'CREATE_SUCCESS').message)),
        catchError((err: ServerApiError) => {
          if (err.code === ServerApiErrorCode.Conflict) {
            this._messageDialogService.notice({
              title: messageFn(nurturingField, 'CREATE_NAME_CONFLICT_ERROR').title,
              message: messageFn(nurturingField, 'CREATE_NAME_CONFLICT_ERROR').message
            });
          } else {
            this._messageDialogService.notice({
              message: messageFn(nurturingField, 'CREATE_ERROR').message
            });
          }
          return throwError(() => err);
        })
      );
  }

  remove(field: CustomFieldId): Observable<void> {
    const nurturingField = `${this._localizedNameService.get(LocalizeKey.NURTURING)}項目`
    return this._confirmService.confirm({
      title: messageFn(nurturingField, 'DELETE_CONFIRM').title,
      message: messageFn(nurturingField, 'DELETE_CONFIRM').message,
    })
      .pipe(
        filter(result => result.isOK()),
        concatMap(() => this._customFieldRepository.remove(field, CustomFieldCategory.NURTURING)),
        tap(() => this._snackBarService.show(messageFn(nurturingField, 'DELETE_SUCCESS').message)),
        catchError(err => {
          this._messageDialogService.notice({
            title: messageFn(nurturingField, 'DELETE_ERROR').title,
            message: messageFn(nurturingField, 'DELETE_ERROR').message
          });
          return throwError(() => err);
        })
      );
  }

  save(field: CustomField): Observable<void> {
    const nurturingField = `${this._localizedNameService.get(LocalizeKey.NURTURING)}項目`
    return this._customFieldRepository.save(CustomFieldCategory.NURTURING, field)
      .pipe(
        tap(() => this._snackBarService.show(messageFn(nurturingField, 'UPDATE_SUCCESS').message)),
        catchError((err: ServerApiError) => {
          if (err.code === ServerApiErrorCode.Conflict) {
            this._messageDialogService.notice({
              title: messageFn(nurturingField, 'UPDATE_NAME_CONFLICT_ERROR').title,
              message: messageFn(nurturingField, 'UPDATE_NAME_CONFLICT_ERROR').message
            });
          } else if (err.code === ServerApiErrorCode.NotFound) {
            this._messageDialogService.notice({
              title: messageFn(nurturingField, 'UPDATE_NOT_FOUND_ERROR').title,
              message: messageFn(nurturingField, 'UPDATE_NOT_FOUND_ERROR').message
            });
          } else {
            this._messageDialogService.notice({
              message: messageFn(nurturingField, 'UPDATE_ERROR').message
            });
          }
          return throwError(() => err);
        })
      );
  }

  sort(fieldIdList: CustomFieldId[]): Observable<void> {
    const nurturingField = `${this._localizedNameService.get(LocalizeKey.NURTURING)}項目`
    return this._customFieldRepository.saveOrders(CustomFieldCategory.NURTURING, fieldIdList)
      .pipe(
        tap(() => this._snackBarService.show(messageFn(nurturingField, 'SORT_SUCCESS').message)),
        catchError(err => {
          if (err instanceof ServerApiError && err.code === ServerApiErrorCode.Conflict) {
            this._messageDialogService.notice({
              title: messageFn(nurturingField, 'SORT_ERROR').message,
              message: `別のユーザーにより${nurturingField}が更新されていたため、並び順の更新に失敗しました。\n${nurturingField}の一覧を更新し、操作をやり直してください。`,
            });
          } else {
            this._snackBarService.show(messageFn(nurturingField, 'SORT_ERROR').message);
          }
          return throwError(() => err);
        })
      );
  }
}
