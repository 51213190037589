import {ClientID} from 'app/model/client/client';
import {OpportunityID} from "app/model/opportunity/opportunity";

export class ContactHistory {
  constructor(
    public readonly id: ContactHistoryID,
    public readonly clientID: ClientID,
    public readonly content: ContactHistoryContent,
    public readonly opportunityID?: OpportunityID,
  ) {
  }
}

export class ContactHistoryID {
  constructor(public readonly value: string) {
  }
}

export class ContactHistoryContent {
  constructor(public readonly value: string) {
  }
}

