import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { ApiPaginationRequest } from './server-filter-api';

export type ApiUserAccessLevel =
  | 'MEMBER'
  | 'MANAGER'
  | 'SYSTEM_ADMINISTRATOR'

export interface GetUserData {
  organizationID: { value: string };
  id: { value: string };
  // FIXME バックエンドと同時にloginIDに変更する
  userName: { value: string };
  name: { value: string };
  mailAddress: { value: string };
  accessLevels: ApiUserAccessLevel[];
}

export type GetUserResponse = GetUserData;

export interface UpdateUserData {
  name: string;
  mailAddress: string;
  accessLevels: ApiUserAccessLevel[];
}

export type UpdateUserRequest = UpdateUserData;

export interface UserData {
  id: string;
  // FIXME バックエンドと同時にloginIDに変更する
  userName: string;
  name: string;
  mailAddress: string;
  isActive: boolean;
  lastLoginDatetime?: string;
  accessLevels: ApiUserAccessLevel[];
}

export type GetUserListResponse = UserData[];

export interface CreateUserRequest {
  // FIXME バックエンドと同時にloginIDに変更する
  userName: string;
  name: string;
  mailAddress: string;
  accessLevels: ApiUserAccessLevel[];
}

export type SearchSelectableUserListRequest = ApiPaginationRequest & {
  name: string,
}

export type SearchSelectableUserListResponse = {
  totalCount: number,
  results: {
    id: string,
    name: string,
  }[]
}

export class UserApi {
  constructor(private readonly http: HttpClient) {
  }

  create(request: CreateUserRequest): Observable<void> {
    const request2 = {
      ...request,
      timezone: 'Asia/Tokyo'
    }
    return this.http.post<void>('/api/users', request2);
  }

  get(userID: string): Observable<GetUserResponse> {
    return this.http.get<GetUserData>('/api/users/' + userID);
  }

  update(userId: string, request: UpdateUserRequest): Observable<void> {
    const request2 = {
      ...request,
      timezone: 'Asia/Tokyo'
    }
    return this.http.put<void>('/api/users/' + userId, request2);
  }

  list(includesInactive: boolean = false): Observable<GetUserListResponse> {
    const params = {
      'has-included-inactive-users': includesInactive,
    };
    return this.http.get<UserData[]>('/api/users', { params });
  }

  delete(userId: string): Observable<void> {
    return this.http.delete<void>('/api/users/' + userId);
  }

  resetPassword(userId: string): Observable<void> {
    return this.http.put<void>('/api/users/' + userId + '/reset_password', null);
  }

  searchSelectable(request: SearchSelectableUserListRequest): Observable<SearchSelectableUserListResponse> {
    return this.http.post<SearchSelectableUserListResponse>('/api/users/for-selectable-search', request);
  }
}
