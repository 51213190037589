import { ConfirmService } from "#application/services/confirm.service";
import { FileCommandService } from "#application/services/file-command.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { SnackBarService } from "#application/services/snack-bar.service";
import { categoryOfParent, FileID, ParentID } from "app/model/file/file";
import { ServerApi } from "#infrastructure/api/server-api";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { tap, catchError, map, filter, concatMap } from "rxjs/operators";
import {messageFn, Target} from "#utils/messages";

@Injectable({ providedIn: 'root' })
export class FileCommandServiceImpl extends FileCommandService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
    private readonly messageDialogService: MessageDialogService,
    private readonly confirmService: ConfirmService,
  ) {
    super();
  }

  upload(file: File): Observable<FileID> {
    return this.serverApi.fileApi.upload(file)
    .pipe(
      map(res => new FileID(res)),
      tap(() => {
        this.snackBarService.show('ファイルのアップロードに成功しました。');
      }),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: 'ファイルのアップロード'
        });
        return throwError(err);
      })
    );
  }

  uploadByParent(file: File, parentID: ParentID): Observable<FileID> {
    return this.serverApi.fileApi.uploadByParent(
      {
        file,
        parentID: parentID.value,
        category: categoryOfParent(parentID),
      }
    )
    .pipe(
      map(res => new FileID(res)),
      tap(() => {
        this.snackBarService.show('ファイルのアップロードに成功しました。');
      }),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: 'ファイルのアップロード'
        });
        return throwError(err);
      })
    );
  }

  delete(id: FileID): Observable<void> {
    return this.confirmService.confirm({
      title: messageFn(Target.FILE, 'DELETE_CONFIRM').title,
      message: messageFn(Target.FILE, 'DELETE_CONFIRM').message
    })
    .pipe(
      filter(r => r.isOK()),
      concatMap(() => this.serverApi.fileApi.delete(id.value)),
      tap(() => {
        this.snackBarService.show(messageFn(Target.FILE, 'DELETE_SUCCESS').message);
      }),
      catchError((err) => {
        this.messageDialogService.notice({
          title: messageFn(Target.FILE, 'DELETE_ERROR').title,
          message: messageFn(Target.FILE, 'DELETE_ERROR').message
        });
        return throwError(err);
      })
    );
  }
}
