import {MaterialModule} from '#presentation/material.module';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import { AdminContainerComponent } from './admin-container.component';

@NgModule({
  declarations: [
    AdminContainerComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
  ],
  exports: [
    AdminContainerComponent,
  ],
})
export class AdminContainerModule {
}
