import { ConfirmService } from "#application/services/confirm.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { SnackBarService } from "#application/services/snack-bar.service";
import { WonReasonCommandService, WonReasonInputs } from "#application/services/won-reason-command.service";
import { WonReasonID } from "app/model/opportunity/won-reason/won-reason";
import { ServerApi } from "#infrastructure/api/server-api";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { tap, catchError, concatMap, filter } from "rxjs/operators";
import {messageFn, Target} from "#utils/messages";

@Injectable({ providedIn: 'root' })
export class WonReasonCommandServiceImpl extends WonReasonCommandService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
    private readonly messageDialogService: MessageDialogService,
    private readonly confirmService: ConfirmService,
  ) {
    super();
  }

  add(inputs: WonReasonInputs): Observable<void> {
    return this.serverApi.wonReasonApi.create({
      name: inputs.name.value,
      description: inputs.description.value,
    })
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(Target.WON_REASON, 'CREATE_SUCCESS').message);
      }),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: '受注理由の登録'
        });
        return throwError(err);
      })
    );
  }

  update(id: WonReasonID, inputs: WonReasonInputs): Observable<void> {
    return this.serverApi.wonReasonApi.update(
      id.value,
      {
        name: inputs.name.value,
        description: inputs.description.value,
      }
    )
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(Target.WON_REASON, 'UPDATE_SUCCESS').message);
      }),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: '受注理由の更新'
        });
        return throwError(err);
      })
    );
  }

  delete(id: WonReasonID): Observable<void> {
    return this.confirmService.confirm({
      title: messageFn(Target.WON_REASON, 'DELETE_CONFIRM').title,
      message: messageFn(Target.WON_REASON, 'DELETE_CONFIRM').message
    })
    .pipe(
      filter(r => r.isOK()),
      concatMap(() => this.serverApi.wonReasonApi.delete(id.value)),
      tap(() => {
        this.snackBarService.show(messageFn(Target.WON_REASON, 'DELETE_SUCCESS').message);
      }),
      catchError((err) => {
        this.messageDialogService.notice({
          title: messageFn(Target.WON_REASON, 'DELETE_ERROR').title,
          message: messageFn(Target.WON_REASON, 'DELETE_ERROR').message
        });
        return throwError(err);
      })
    );
  }

  sort(ids: WonReasonID[]): Observable<void> {
    return this.serverApi.wonReasonApi.sort(
      {
        ids: ids.map(v => v.value)
      }
    )
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(Target.WON_REASON, 'SORT_SUCCESS').message);
      }),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: '受注理由の並び替え'
        });
        return throwError(err);
      })
    );
  }
}
