import { CsvExportCategory, CsvExportRequestCategory, CsvExportService, ExportType, ExportTypes } from "#application/services/csv-export.service";
import { ServerApi } from "#infrastructure/api/server-api";
import { Injectable } from "@angular/core";
import {Observable, of} from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class CsvExportServiceImpl extends CsvExportService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  getExportTypes(category: CsvExportCategory): Observable<ExportTypes> {
    switch (category) {
      case 'client':
        return this.serverApi.clientTypeApi.list().pipe(map(typeList => {
          return typeList.map(t => {
            switch (t.category) {
              case 'CORPORATION':
                return { category: 'corporation', typeId: t.id, typeName: t.name };
              case 'INDIVIDUAL':
                return { category: 'individual', typeId: t.id, typeName: t.name };
            }
          })
        }))
      case 'opportunity':
        return this.serverApi.opportunityTypeApi.list().pipe(map(typeList => { return typeList.map(t => ({ category: 'opportunity', typeId: t.id, typeName: t.name })) }))
      case 'product':
        return this.serverApi.productTypeApi.list().pipe(map(typeList => { return typeList.map(t => ({ category: 'product', typeId: t.id, typeName: t.name })) }))
      case 'action':
        return of([{ category: 'action', typeId: '', typeName: ''}])
      case 'nurturing':
        return of([{ category: 'nurturing', typeId: '', typeName: ''}])
      default:
        const never = category;
        throw new Error('${category} is not defined.')
    }
  }

  getCsvFileDownloadUrl(category: CsvExportRequestCategory, requestId: string): Observable<string> {
    return this.serverApi.csvExportApi.getPreSignedUrl(category, requestId).pipe(map(r => r.preSignedUrl));
  }

  requestCsvExport(category: CsvExportRequestCategory, exportTypeId: string, encoding: string): Observable<void> {
    return this.serverApi.csvExportApi.requestExportCsv(category, { typeId: exportTypeId, charset: encoding });
  }
}
