import { ClientID } from 'app/model/client/client';
import { ClientRelationshipID } from '../client-relationship';

export class ClientRelationshipNote {
  constructor(
    public readonly id: ClientRelationshipNoteID,
    public readonly content: ClientRelationshipNoteContent,
    public readonly clientID: ClientID,
    public readonly relationshipID: ClientRelationshipID,
  ) {
  }
}

export class ClientRelationshipNoteID {
  constructor(public readonly value: string) {}
}

export class ClientRelationshipNoteContent {
  constructor(public readonly value: string) {}
}
