export const FieldType = {
  SINGLE_LINE_TEXT: 'SINGLE_LINE_TEXT',
  MULTI_LINE_TEXT: 'MULTI_LINE_TEXT',
  NUMBER: 'NUMBER',
  SELECT: 'SELECT',
  DATE: 'DATE',
  DATETIME: 'DATETIME',
  URL: 'URL',
  MAIL_ADDRESS: 'MAIL_ADDRESS',
  ADDRESS: 'ADDRESS',
  PHONE_NUMBER: 'PHONE_NUMBER',
  USER: 'USER',
  ATTACHMENT: 'ATTACHMENT',
  CLIENT: 'CLIENT',
  CHECKBOX: 'CHECKBOX',
} as const;

export type FieldType = typeof FieldType[keyof typeof FieldType];
