import {
  OpportunityHearingSettingList,
  OpportunityHearingSettingQueryService
} from "#application/services/opportunity-hearing-setting-query.service";
import {hearingTypeValueOf} from "app/model/opportunity-hearing-setting/opportunity-hearing-setting";
import {OpportunityTypeID} from "app/model/opportunity-type/opportunity-type";
import {PhaseID} from "app/model/sales-phase/sales-phase";
import {ServerApi} from "#infrastructure/api/server-api";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {CustomFieldId, CustomFieldName} from "app/model/custom-field/custom-field";


@Injectable({
  providedIn: 'root'
})
export class OpportunityHearingSettingQueryServiceImpl extends OpportunityHearingSettingQueryService {
  constructor(
    private readonly serverApi: ServerApi
  ) {
    super();
  }

  get(opportunityTypeID: OpportunityTypeID): Observable<OpportunityHearingSettingList> {
    return this.serverApi.opportunityHearingSettingApi.list(opportunityTypeID.value).pipe(
      map(response => response.map(item => ({
        id: new CustomFieldId(item.customFieldID),
        name: new CustomFieldName(item.customFieldName),
        settings: item.values.map(v => ({
          phaseID: new PhaseID(v.phaseID),
          before: hearingTypeValueOf(v.before),
          after: hearingTypeValueOf(v.after),
        }))
      })))
    );
  }
}
