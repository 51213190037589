import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

type Input = {
  name: string,
  description?: string,
  statusIDs: string[],
};

type OpportunityGroupWorkflow = {
  id: string,
  name: string,
  description: string,
  statuses: {
    id: string,
    name: string,
  }[],
};

export type ListOpportunityGroupWorkflowResponse = OpportunityGroupWorkflow[];
export type GetOpportunityGroupWorkflowResponse = OpportunityGroupWorkflow;
export type CreateOpportunityGroupWorkflowRequest = Input;
export type CreateOpportunityGroupWorkflowResponse = { id: string };
export type UpdateOpportunityGroupWorkflowRequest = Input;
export type SortOpportunityGroupWorkflowRequest = { ids: string[] };

export class OpportunityGroupWorkflowApi {
  private readonly url = '/api/opportunity-group-workflows';
  constructor(private readonly http: HttpClient) {}

  list(): Observable<ListOpportunityGroupWorkflowResponse> {
    return this.http.get<ListOpportunityGroupWorkflowResponse>(`${this.url}/all`);
  };

  get(id: string): Observable<GetOpportunityGroupWorkflowResponse> {
    return this.http.get<GetOpportunityGroupWorkflowResponse>(`${this.url}/${id}`);
  };

  create(request: CreateOpportunityGroupWorkflowRequest): Observable<CreateOpportunityGroupWorkflowResponse> {
    return this.http.post<CreateOpportunityGroupWorkflowResponse>(this.url, request);
  };

  update(id: string, request: UpdateOpportunityGroupWorkflowRequest): Observable<void> {
    return this.http.put<void>(`${this.url}/${id}`, request);
  };

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${id}`);
  };

  sort(request: SortOpportunityGroupWorkflowRequest): Observable<void> {
    return this.http.patch<void>(`${this.url}/sort`, request);
  };
}
