import { ClientID } from "app/model/client/client";
import { OpportunityID } from "app/model/opportunity/opportunity";
import { ProductId } from "app/model/product/product";
import { DateTime } from "luxon";

/**
 * 添付ファイルの最大数
 */
export const ATTACHED_FILE_MAX = 10;

/**
 * 添付ファイルID
 */
export class FileID {
  constructor(public readonly value: string) {}
}

/**
 * 添付ファイル名
 */
export class FileName {
  static readonly MAX_LENGTH = 300;

  constructor(public readonly value: string) {}
}

/**
 * ファイルサイズ
 */
export class FileSize {
  constructor(public readonly value: number) {}
}

/**
 * ファイル更新（アップロード）日時
 */
export class FileUpdatedAt {
  private constructor(public readonly value: DateTime) {}

  static ofUTC(dateStr: string): FileUpdatedAt {
    return new FileUpdatedAt(
      DateTime.fromFormat(dateStr, 'yyyy-MM-dd HH:mm:ss', { zone: 'UTC' })
    );
  }

  setTimeZone(tz: string = 'Asia/Tokyo', locale: string = 'ja-JP'): DateTime {
    return this.value.setZone(tz).setLocale(locale);
  }
}

/**
 * 添付ファイルプロファイル
 * （添付ファイルの概要を表すもの）
 */
export type FileProfile = Readonly<{
  id: FileID,
  fileName: FileName,
  fileSize: FileSize,
}>

/**
 * ファイルを管理するカテゴリーのID
 */
export type ParentID = ClientID | OpportunityID | ProductId;

type ParentCategory = 'CLIENT' | 'OPPORTUNITY' | 'PRODUCT';

/**
 * ペアレントIDに基づき、そのカテゴリー文字列を返す
 * @param {ParentID} id - ペアレントID
 * @returns カテゴリー文字列
 */
export const categoryOfParent = (id: ParentID): ParentCategory => {
  if (id instanceof ClientID) {
    return 'CLIENT';
  }
  if (id instanceof OpportunityID) {
    return 'OPPORTUNITY';
  }
  if (id instanceof ProductId) {
    return 'PRODUCT';
  }
  throw Error(`${id}は予期せぬクラスのインスタンスです。引数のParentIDを見直してください。`);
}
