import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiFilterRequest, ApiPaginationRequest, FilterSchemaResponse } from "./server-filter-api";

type UserData = {
  userID: string,
  userDisplayName: string,
};

export type MeetingBoardSettingResponse = {
  meetingBoardSettingID: string,
  name: string,
  description: string,
  visibility: 'PUBLIC' | 'PRIVATE',
  editability: 'PUBLIC' | 'PRIVATE',
  owner: {
    userID: string,
    userDisplayName: string,
  },
  createdAt: string,
  updatedAt: string,
  bookmark: boolean,
  bookmarkCount: number,
};

export type FilterMeetingBoardSettingRequest = ApiPaginationRequest & ApiFilterRequest;

export type FilterMeetingBoardSettingResponse = {
  totalCount: number,
  results: MeetingBoardSettingResponse[],
};

export type GetMeetingBoardSettingResponse = {
  meetingBoardSettingID: string,
  name: string,
  description: string,
  visibility: 'PUBLIC' | 'PRIVATE',
  editability: 'PUBLIC' | 'PRIVATE',
  headerCondition: {
    isDisplayed: {
      wonAmount: boolean,
      wonCount: boolean,
      lostAmount: boolean,
      lostCount: boolean,
      opportunityWonCount: boolean,
      actualAmount: boolean,
      salesRecordingAmount: boolean,
    },
    displayUsers: UserData[],
  },
  filterConditionLiteral: string,
};

export type SaveMeetingBoardSettingRequest = {
  meetingBoardSettingID?: string,
  name: string,
  description: string,
  visibility: 'PUBLIC' | 'PRIVATE',
  editability: 'PUBLIC' | 'PRIVATE',
  headerCondition: {
    isDisplayed: {
      wonAmount: boolean,
      wonCount: boolean,
      lostAmount: boolean,
      lostCount: boolean,
      opportunityWonCount: boolean,
      actualAmount: boolean,
      salesRecordingAmount: boolean,
    },
    displayUserIDs: string[],
  },
  filterConditionLiteral: string,
};

export type SaveMeetingBoardSettingResponse = {
  meetingBoardSettingID: string,
};

export class MeetingBoardSettingApi {
  constructor(private readonly http: HttpClient) {}

  filter(request: FilterMeetingBoardSettingRequest): Observable<FilterMeetingBoardSettingResponse> {
    return this.http.post<FilterMeetingBoardSettingResponse>(`/api/meeting-board-settings/filter`, request);
  }

  getFilterSchema(): Observable<FilterSchemaResponse> {
    return this.http.get<FilterSchemaResponse>(`/api/meeting-board-settings/filter`);
  }

  get(id: string): Observable<GetMeetingBoardSettingResponse> {
    return this.http.get<GetMeetingBoardSettingResponse>(`/api/meeting-board-settings/${ id }`);
  }

  save(request: SaveMeetingBoardSettingRequest): Observable<SaveMeetingBoardSettingResponse> {
    return this.http.post<SaveMeetingBoardSettingResponse>(`/api/meeting-board-settings`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/meeting-board-settings/${ id }`);
  }

  bookmark(id: string): Observable<void> {
    return this.http.post<void>(`/api/meeting-board-settings/${ id }/bookmark`, {});
  }

  unbookmark(id: string): Observable<void> {
    return this.http.delete<void>(`/api/meeting-board-settings/${ id }/bookmark`);
  }
}
