import { LocalizeKey } from "#application/services/authorize.service";
import { ConfirmService } from "#application/services/confirm.service";
import { LocalizedNameService } from "#application/services/localized-name.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { OpportunityGroupCommandService, OpportunityGroupInputs } from "#application/services/opportunity-group-command.service";
import { SnackBarService } from "#application/services/snack-bar.service";
import { ServerApiError, ServerApiErrorCode } from "#infrastructure/api/error";
import { ServerApi } from "#infrastructure/api/server-api";
import { Target, messageFn } from "#utils/messages";
import { Injectable } from "@angular/core";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Failure, Result, Success } from "app/lib/result/result";
import { OpportunityGroupID } from "app/model/opportunity-group/opportunity-group";
import { OpportunityGroupStatusID } from "app/model/opportunity-group/opportunity-group-status";
import { OpportunityID } from "app/model/opportunity/opportunity";
import { NEVER, Observable, catchError, concatMap, filter, map, of, tap } from "rxjs";

@Injectable({ providedIn: 'root' })
export class OpportunityGroupCommandServiceImpl extends OpportunityGroupCommandService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
    private readonly messageDialogService: MessageDialogService,
    private readonly confirmService: ConfirmService,
    private readonly localizedNameService: LocalizedNameService,
  ) {
    super();
  }

  add(inputs: OpportunityGroupInputs): Observable<Result<
    OpportunityGroupID,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
  >>{
    const fieldName = this.localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP);
    const itemObj: { [key: string]: string } = {};
    inputs.items?.forEach((item) => {
      itemObj[`${item.fieldId.value}`] = item.fieldValue.value;
    });
    return this.serverApi.opportunityGroupApi.create({
      title: inputs.title,
      opportunityGroupTypeID: inputs.opportunityGroupTypeID ?? '',
      opportunityGroupStatusID: inputs.opportunityGroupStatusID ?? '',
      items: inputs.items ? itemObj : undefined,
      opportunities: inputs.opportunities?.map(v => v.value) ?? [],
    })
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(fieldName, 'CREATE_SUCCESS').message);
      }),
      map(res => Success(OpportunityGroupID(res))),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.BadRequest:
            return of(Failure(GeneralFailure.BadRequest));
          case ServerApiErrorCode.Conflict:
            return of(Failure(GeneralFailure.Conflict));
        }
        this.messageDialogService.notice({
          errorTarget: `${fieldName}の登録`
        });
        return NEVER;
      })
    );
  }

  update(id: OpportunityGroupID, inputs: OpportunityGroupInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.NotFound
  >>{
    const fieldName = this.localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP);
    const itemObj: { [key: string]: string } = {};
    inputs.items?.forEach((item) => {
      itemObj[`${item.fieldId.value}`] = item.fieldValue.value;
    });
    return this.serverApi.opportunityGroupApi.update(
      id,
      {
        title: inputs.title,
        items: inputs.items ? itemObj : {},
      }
    )
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(fieldName, 'UPDATE_SUCCESS').message);
      }),
      map((v) => Success<void>(v)),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.BadRequest:
            return of(Failure(GeneralFailure.BadRequest));
          case ServerApiErrorCode.Conflict:
            return of(Failure(GeneralFailure.Conflict));
          case ServerApiErrorCode.NotFound:
            return of(Failure(GeneralFailure.NotFound));
        }
        this.messageDialogService.notice({
          message: messageFn(fieldName, 'UPDATE_ERROR').message,
        });
        return NEVER;
      })
    );
  }

  updateStatus(id: OpportunityGroupID, opportunityGroupStatusID: OpportunityGroupStatusID): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>{
    const fieldName = `${this.localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}ステータス`;
    return this.serverApi.opportunityGroupApi.updateStatus(
      id,
      { opportunityGroupStatusID: opportunityGroupStatusID }
    )
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(fieldName, 'UPDATE_SUCCESS').message);
      }),
      map((v) => Success<void>(v)),
      catchError((e: ServerApiError) => {
        if (e.code === ServerApiErrorCode.BadRequest) {
          return of(Failure(GeneralFailure.BadRequest));
        }
        if (e.code === ServerApiErrorCode.NotFound) {
          return of(Failure(GeneralFailure.NotFound));
        }
        this.messageDialogService.notice({
          errorTarget: `${fieldName}の更新`
        });
        return NEVER;
      })
    );
  }

  updateOpportunities(id: OpportunityGroupID, opportunities: OpportunityID[]): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>{
    return this.serverApi.opportunityGroupApi.updateOpportunities(
      id,
      { opportunityIDs: opportunities.map(v => v.value) }
    )
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(Target.OPPORTUNITY, 'UPDATE_SUCCESS').message);
      }),
      map((v) => Success<void>(v)),
      catchError((e: ServerApiError) => {
        if (e.code === ServerApiErrorCode.BadRequest) {
          return of(Failure(GeneralFailure.BadRequest));
        }
        if (e.code === ServerApiErrorCode.NotFound) {
          return of(Failure(GeneralFailure.NotFound));
        }
        this.messageDialogService.notice({
          errorTarget: `${Target.OPPORTUNITY}の更新`
        });
        return NEVER;
      })
    );
  }

  delete(id: OpportunityGroupID): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>{
    const fieldName = this.localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)
    return this.confirmService.confirm({
      title: messageFn(fieldName, 'DELETE_CONFIRM').title,
      message: messageFn(fieldName, 'DELETE_CONFIRM').message
    })
    .pipe(
      filter(r => r.isOK()),
      concatMap(() => this.serverApi.opportunityGroupApi.delete(id)),
      tap(() => {
        this.snackBarService.show(messageFn(fieldName, 'DELETE_SUCCESS').message);
      }),
      map((v) => Success<void>(v)),
      catchError((e: ServerApiError) => {
        if (e.code === ServerApiErrorCode.BadRequest) {
          return of(Failure(GeneralFailure.BadRequest));
        }
        if (e.code === ServerApiErrorCode.NotFound) {
          return of(Failure(GeneralFailure.NotFound));
        }
        this.messageDialogService.notice({
          errorTarget: `${fieldName}の削除`
        });
        return NEVER;
      })
    );
  }
}
