import { GetClientListInput } from "#application/services/client-query.service";
import { SearchContactInputs } from "#application/services/contact-query.service";
import { SearchOpportunityListInput } from "#application/services/opportunity-query.service";
import { ProductCsvExportFilter, ProductCsvExportList } from "#application/services/product-csv-export.service";
import { SearchProductsListParam } from "#application/services/product-query.service";
import { FilterPredicate, FilterPredicateType } from "app/model/search-filter/search-filter";
import { ServerApi } from "#infrastructure/api/server-api";
import {
  ApiFilterRequest,
  ApiItemType,
  ApiNullsType,
  ApiOrderType,
  ApiPaginationRequest,
  SchemaResponse
} from "#infrastructure/api/server-filter-api";
import { ProductCsvExportFilterRequest } from "#infrastructure/api/server-product-csv-export-api";
import { inject, Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export type BoundaryType = 'INCLUSIVE_EXCLUSIVE' | 'INCLUSIVE_INCLUSIVE' | 'EXCLUSIVE_INCLUSIVE' | 'EXCLUSIVE_EXCLUSIVE'
export type ConditionOperatorType = 'AND' | 'OR'
export type OrderType = 'ASC' | 'DESC'
export type NullsType = 'FIRST' | 'LAST'
export type FilterType = 'EXISTS_AND_FILTERED' | 'EMPTY_OR_FILTERED'

export const ItemType = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  REFERENCE: 'REFERENCE',
  BOOLEAN: 'BOOLEAN',
  ARRAY_STRING: 'ARRAY_STRING',
} as const;
export type ItemType = typeof ItemType[keyof typeof ItemType];

export type Greater = {
  type: FilterType,
  operator: {
    type: 'GREATER',
    value: string | number,
  }
}

export type GreaterOrEqual = {
  type: FilterType,
  operator: {
    type: 'GREATER_OR_EQUAL',
    value: string | number,
  }
}

export type Less = {
  type: FilterType,
  operator: {
    type: 'LESS',
    value: string | number,
  }
}

export type LessOrEqual = {
  type: FilterType,
  operator: {
    type: 'LESS_OR_EQUAL',
    value: string | number,
  }
}

export type Equal = {
  type: FilterType,
  operator: {
    type: 'EQUAL',
    value: string | number | boolean,
  }
}

export type In = {
  type: FilterType,
  operator: {
    type: 'IN',
    values: string[],
  }
}

export type Range = {
  type: FilterType,
  operator: {
    type: 'RANGE'
    from: string,
    range: number,
    bounds: BoundaryType,
  }
}

export type Keywords = {
  type: FilterType,
  operator: {
    type: 'KEYWORDS',
    values: string[],
    condition: ConditionOperatorType,
  }
}

export type Between = {
  type: FilterType,
  operator: {
    type: 'BETWEEN',
    from: number,
    to: number,
    bounds: BoundaryType,
  }
}

export type Overlap = {
  type: FilterType,
  operator: {
    type: 'OVERLAP',
    value: string[],
  }
}

export type EmptyOnly = {
  type: 'EMPTY',
}

export type ExistsOnly = {
  type: 'EMPTY',
}

export type FilterInput = {
  filters: {
    [itemName: string]:
      | Greater
      | GreaterOrEqual
      | Less
      | LessOrEqual
      | Equal
      | In
      | Between
      | Range
      | Keywords
      | Overlap
      | EmptyOnly
      | ExistsOnly
  },
  sorts: {
    name: string,
    order: OrderType,
    nulls: NullsType,
  }[]
}

export const ItemNamePrefix = {
  BUILTIN: 'BUILTIN',
  CUSTOM: 'CUSTOM',
  NONE: 'NONE',
} as const;
export type ItemNamePrefix = typeof ItemNamePrefix[keyof typeof ItemNamePrefix];

export type FilterItem = {
  prefix: ItemNamePrefix,
  name: string,
  type: ItemType,
}

export type SortItem = {
  prefix: ItemNamePrefix,
  name: string,
}

export type Schema = {
  filter: FilterItem[],
  sort: SortItem[]
}

export type FilterCategory = "opportunities" | "clients" | "products" | "contacts";

const convertToSchema = (response: SchemaResponse): Schema => {
  const filter: FilterItem[] = Object
    .entries(response.filter)
    .map<FilterItem>(([itemName, itemType]) => {
      return convertToFilterItem(itemName, itemType);
    })
  const sort: SortItem[] = response.sort.map<SortItem>(itemName => {
    return convertToSortItem(itemName);
  })
  return {filter, sort};
}

const convertToFilterItem = (itemName: string, itemType: ApiItemType): FilterItem => {
  const [x, ...xs] = itemName.split('-');
  const prefix = convertToItemNamePrefix(x);
  const name = prefix !== ItemNamePrefix.NONE ? xs.join('-') : itemName;
  const type = convertToItemType(itemType);
  return {prefix, name, type};
}

const convertToSortItem = (itemName: string): SortItem => {
  const [x, ...xs] = itemName.split('-');
  const prefix = convertToItemNamePrefix(x);
  const name = prefix !== ItemNamePrefix.NONE ? xs.join('-') : itemName;
  return {prefix, name};
}

const convertToItemNamePrefix = (prefix: string): ItemNamePrefix => {
  switch (prefix) {
    case 'builtin':
      return ItemNamePrefix.BUILTIN;
    case 'custom':
      return ItemNamePrefix.CUSTOM;
    default:
      return ItemNamePrefix.NONE;
  }
}

const convertToItemType = (type: ApiItemType): ItemType => {
  switch (type) {
    case 'STRING':
      return ItemType.STRING;
    case 'NUMBER':
      return ItemType.NUMBER;
    case 'DATE':
      return ItemType.DATE;
    case 'REFERENCE':
      return ItemType.REFERENCE;
    case 'BOOLEAN':
      return ItemType.BOOLEAN;
    case 'ARRAY_STRING':
      return ItemType.ARRAY_STRING;
    default:
      const unexpected: never = type;
      throw Error(`${unexpected} is unexpected value`);
  }
}

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private readonly _serverApi = inject(ServerApi);

  /**
   * @duplicate #findBy()が#filter()に移行したら削除する
   */
  getSchema(category: FilterCategory): Observable<Schema> {
    return this._serverApi.filterApi
      .getSchema(category)
      .pipe(map(convertToSchema));
  }
}

// 検索API移行のためのコンバータ
// TODO: フィルター系のQueryServiceのfindByをfilterに移行したら削除する
export namespace FilterRequestConverter {
  export function toFilterRequest(
    schema: Schema,
    input: SearchOpportunityListInput | GetClientListInput | SearchProductsListParam | SearchContactInputs | SearchContactInputs
  ): ApiPaginationRequest & ApiFilterRequest {
    const filter = Object
      .entries(input.filter)
      .filter(([, filterPredicate]) => filterPredicate !== undefined)
      .filter(([, filterPredicate]) => filterPredicate?.value !== '')
      .map(([propName, filterPredicate]) => toFilterEntry(schema, propName, filterPredicate))
    const filterMap = Object.fromEntries(filter);
    const sort = {
      name: 'builtin-updatedAt',
      order: 'DESC' as ApiOrderType,
      nulls: 'LAST' as ApiNullsType,
    };
    return {
      pagination: {
        perPage: input.perPage,
        page: input.page,
      },
      filters: filterMap,
      sorts: [
        sort,
      ],
    };
  }

  const propNameToFilterNameMap: { [name: string]: string } = {
    'productTypes': 'productTypeID',
  }

  function toFilterEntry(
    schema: Schema,
    propName: string,
    filterPredicate: FilterPredicate | undefined,
  ) {
    const filterName = propNameToFilterNameMap[propName] ?? propName;
    if ((filterName === 'searchString' || filterName === 'contact') && filterPredicate?.type === 'contains') {
      return ['builtin-textForSearch', {
        type: "EXISTS_AND_FILTERED",
        operator: {
          type: 'KEYWORDS',
          values: (filterPredicate?.value as string)
            .split(/[ 　]/) // 半角スペース、全角スペースで分割
            .filter(Boolean),
          condition: 'AND',
        },
      }]
    }

    const isCustom = filterName.startsWith('item:');
    const filterItem = schema.filter.find(item => {
      if (!isCustom && item.prefix === ItemNamePrefix.BUILTIN) {
        return item.name === filterName;
      } else if (isCustom && item.prefix === ItemNamePrefix.CUSTOM) {
        const filterItemName = filterName.replace(/^item:/, '');
        return item.name.includes(filterItemName);
      } else {
        return false;
      }
    });
    if (filterItem === undefined)
      throw Error(`検索できないプロパティ名です: ${propName}`);

    const prefix = filterItem.prefix === ItemNamePrefix.BUILTIN ? 'builtin' : 'custom';
    const name = prefix + '-' + filterItem.name;
    switch (filterPredicate?.type) {
      case FilterPredicateType.contains:
        return [name, {
          type: "EXISTS_AND_FILTERED",
          operator: {
            type: 'KEYWORDS',
            values: (filterPredicate?.value as string)
              .split(/[ 　]/) // 半角スペース、全角スペースで分割
              .filter(Boolean),
            condition: 'AND',
          },
        }]
      case FilterPredicateType.greaterThanOrEqualTo:
        return [name, {
          type: "EXISTS_AND_FILTERED",
          operator: {
            type: 'GREATER_OR_EQUAL',
            value: toItemValue(filterItem.type, filterPredicate),
          },
        }]
      case FilterPredicateType.lessThanOrEqualTo:
        return [name, {
          type: "EXISTS_AND_FILTERED",
          operator: {
            type: 'LESS_OR_EQUAL',
            value: toItemValue(filterItem.type, filterPredicate),
          },
        }]
      case FilterPredicateType.anyMatch:
        const [emptyValue] = filterPredicate.value.filter(value => value === '$$$empty$$$');
        const filterType = emptyValue === undefined ? 'EXISTS_AND_FILTERED' : 'EMPTY_OR_FILTERED';
        const values = filterPredicate.value.filter(value => value !== '$$$empty$$$');
        if (filterItem.type === ItemType.ARRAY_STRING) {
          return [name, {
            type: filterType,
            operator: {
              type: 'OVERLAP',
              value: values,
            },
          }]
        } else {
          return [name, {
            type: filterType,
            operator: {
              type: 'IN',
              values,
            },
          }]
        }
      case FilterPredicateType.equals:
        return [name, {
          type: "EXISTS_AND_FILTERED",
          operator: {
            type: 'EQUAL',
            value: toItemValue(filterItem.type, filterPredicate),
          },
        }]
      case FilterPredicateType.empty:
      case FilterPredicateType.range: // 旧フィルターでは範囲検索はできない。未入力検索として扱う
      case FilterPredicateType.between: // 旧フィルターでは範囲検索はできない。未入力検索として扱う
        return [name, {
          type: 'EMPTY'
        }]
      default:
        const unexpected: never = filterPredicate!.type;
        throw Error(`${unexpected} is unexpected value`);
    }
  }

  function toItemValue(
    itemType: ItemType,
    predicate: FilterPredicate,
  ): string | number | string[] {
    if (predicate.type === FilterPredicateType.range || predicate.type === FilterPredicateType.between)
      return ''; // 旧フィルターでは範囲検索はできない。未入力検索として扱う

    switch (itemType) {
      case ItemType.STRING:
      case ItemType.DATE:
      case ItemType.REFERENCE:
      case ItemType.BOOLEAN:
      case ItemType.ARRAY_STRING:
        return predicate.value;
      case ItemType.NUMBER:
        return Number(predicate.value);
      default:
        const unexpected: never = itemType;
        throw Error(`${unexpected} is unexpected value`);
    }
  }

  function toOutputColumns(list: ProductCsvExportList) {
    return list.map(item => {
      let name = '';
      let label = item.columnName;
      switch (item.columnType) {
        case 'PRODUCT_ID':
        case 'PRODUCT_NUMBER':
        case 'PRODUCT_TYPE':
          name = item.columnType;
          break;
        case 'BUILTIN_FIELD':
          name = item.builtinFieldID;
          break;
        case 'CUSTOM_FIELD':
          name = item.customFieldID;
          break;
        default:
          throw Error(`${item} is unexpected value`);
      }
      return {
        name,
        label
      }
    });
  }
}
