import { OpportunityID, OpportunityName } from 'app/model/opportunity/opportunity';
import { DateTime } from 'luxon';
import { FollowUpActionTypeID, FollowUpActionTypeName } from './follow-up-action-type';
import { DisplayName, UserID } from 'app/model/user/user';

/**
 * フォロー
 */
export type FollowUpAction = Readonly<{
  id: FollowUpActionID,
  createdBy: FollowUpActionCreator,
  opportunity: {
    id: OpportunityID,
    name: OpportunityName,
  },
  due: FollowUpActionDue,
  followUpActionType: {
    id: FollowUpActionTypeID,
    name: FollowUpActionTypeName,
  },
  assignee: FollowUpActionAssignee,
  summary: FollowUpActionSummary,
  detail: FollowUpActionDetail,
  isDone: FollowUpActionIsDone,
}>
export function FollowUpAction(values: {
  id: string,
  createdBy: {
    id: string,
    name: string,
  },
  opportunity: {
    id: string,
    name: string,
  },
  due: string,
  followUpActionType: {
    id: string,
    name: string,
  },
  assignee: {
    id: string,
    name: string,
  },
  contentsSummary: string,
  contentsDetail: string,
  isDone: boolean,
}): FollowUpAction {
  return {
    id: new FollowUpActionID(values.id),
    createdBy: {
      id: new UserID(values.createdBy.id),
      name: new DisplayName(values.createdBy.name),
    },
    opportunity: {
      id: new OpportunityID(values.opportunity.id),
      name: new OpportunityName(values.opportunity.name),
    },
    due: new FollowUpActionDue(DateTime.fromFormat(values.due, 'yyyy-MM-dd HH:mm:ss').toJSDate()),
    followUpActionType: {
      id: new FollowUpActionTypeID(values.followUpActionType.id),
      name: new FollowUpActionTypeName(values.followUpActionType.name),
    },
    assignee: {
      id: new UserID(values.assignee.id),
      name: new DisplayName(values.assignee.name),
    },
    summary: new FollowUpActionSummary(values.contentsSummary),
    detail: new FollowUpActionDetail(values.contentsDetail),
    isDone: new FollowUpActionIsDone(values.isDone),
  }
}

/**
 * フォローを一意に識別するID
 */
export class FollowUpActionID {
  constructor(public readonly value: string) {}
}

/**
 * フォローが完了しているかどうか
 */
export class FollowUpActionIsDone {
  constructor(public readonly value: boolean) {}
}

/**
 * フォローの期日
 */
export class FollowUpActionDue {
  constructor(public readonly value: Date) {}

  public format(format: string): string {
    const datetime = DateTime.fromJSDate(this.value);
    return datetime.toFormat(format);
  }
}

/**
 * フォローの作成者
 */
export type FollowUpActionCreator = Readonly<{
  id: UserID,
  name: DisplayName,
}>

/**
 * フォローの担当者
 */
export type FollowUpActionAssignee = Readonly<{
  id: UserID,
  name: DisplayName,
}>

export class FollowUpActionSummary {
  constructor(public readonly value: string) {}
  
  static readonly MAX_LENGTH = 100;
}

export class FollowUpActionDetail {
  constructor(public readonly value: string) {}

  static readonly MAX_LENGTH = 3000;
}
