import { ConfirmService } from "#application/services/confirm.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { AddProductInput, ProductCommandV2Service, UpdateProductInput } from "#application/services/product-command-v2.service";
import { SnackBarService } from "#application/services/snack-bar.service";
import { ServerApiError, ServerApiErrorCode } from "#infrastructure/api/error";
import { ServerApi } from "#infrastructure/api/server-api";
import { Target, messageFn } from "#utils/messages";
import { Injectable, inject } from "@angular/core";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Failure, Result, Success } from "app/lib/result/result";
import { ProductId } from "app/model/product/product";
import { NEVER, Observable, catchError, concatMap, filter, map, of, tap } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ProductCommandV2ServiceImpl implements ProductCommandV2Service {
  private readonly _serverApi = inject(ServerApi);
  private readonly _snackbar = inject(SnackBarService);
  private readonly _message = inject(MessageDialogService);
  private readonly _confirm = inject(ConfirmService);

  add(input: AddProductInput): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
  >> {
    return this._serverApi.productApi.create({
      name: input.name.value,
      price: input.price.value,
      unit: input.unit.value,
      productTypeID: input.productTypeID.value,
      items: input.items.reduce((acc, item) => {
        acc[item.fieldId.value] = item.fieldValue.value;
        return acc;
      }, {} as Record<string, string>)
    })
    .pipe(
      tap(() => this._snackbar.show(
        messageFn(Target.PRODUCT, 'CREATE_SUCCESS').message
      )),
      map(() => Success(undefined)),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.BadRequest:
            return of(Failure(GeneralFailure.BadRequest));
        }
        this._message.notice({
          errorTarget: `${Target.PRODUCT}の登録`,
        });
        return NEVER
      }),
    );
  }

  update(id: ProductId, input: UpdateProductInput): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >> {
    return this._serverApi.productApi.update(id.value,{
      name: input.name.value,
      price: input.price.value,
      unit: input.unit.value,
      items: input.items.reduce((acc, item) => {
        acc[item.fieldId.value] = item.fieldValue.value;
        return acc;
      }, {} as Record<string, string>)
    })
    .pipe(
      tap(() => this._snackbar.show(
        messageFn(Target.PRODUCT, 'UPDATE_SUCCESS').message
      )),
      map(() => Success(undefined)),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.BadRequest:
            return of(Failure(GeneralFailure.BadRequest));
          case ServerApiErrorCode.NotFound:
            return of(Failure(GeneralFailure.NotFound));
        }
        this._message.notice({
          errorTarget: `${Target.PRODUCT}の更新`,
        });
        return NEVER
      }),
    );
  }

  delete(id: ProductId): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >> {
    return this._confirm.confirm({
      title: messageFn(Target.PRODUCT, 'DELETE_CONFIRM').title,
      message: messageFn(Target.PRODUCT, 'DELETE_CONFIRM').message
    })
    .pipe(
      filter(result => result.isOK()),
      concatMap(() => this._serverApi.productApi.delete(id.value)),
      tap(() => this._snackbar.show(
        messageFn(Target.PRODUCT, 'DELETE_SUCCESS').message
      )),
      map(() => Success(undefined)),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.BadRequest:
            return of(Failure(GeneralFailure.BadRequest));
          case ServerApiErrorCode.NotFound:
            return of(Failure(GeneralFailure.NotFound));
        }
        this._message.notice({
          errorTarget: `${Target.PRODUCT}の削除`,
        });
        return NEVER
      }),
    );
  }
}