import { OpportunityGroupStatus, OpportunityGroupStatusID } from "app/model/opportunity-group/opportunity-group-status";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { Observable } from "rxjs";

export type OpportunityGroupStatusList = OpportunityGroupStatus[];

export const OpportunityGroupStatusQueryFailure = {
  OpportunityGroupStatusQueryBadRequest: 'OpportunityGroupStatusQueryBadRequest',
  OpportunityGroupStatusQueryNotFound: 'OpportunityGroupStatusQueryNotFound',
} as const


export abstract class OpportunityGroupStatusQueryService {
  abstract list(): Observable<Result<
    OpportunityGroupStatusList,
    | typeof GeneralFailure.BadRequest
  >>;

  abstract get(id: OpportunityGroupStatusID): Observable<Result<
    OpportunityGroupStatus,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>;
}
