import { FormItems } from "app/model/form-items/form-items";
import { FormLayoutId } from "app/model/form-layout/form-layout";
import { Product } from "app/model/product/product";
import { Proposal } from "app/model/proposal/proposal";
import { Observable } from 'rxjs';
import {OpportunityGroup} from "app/model/opportunity-group/opportunity-group";
import {Result} from "../../lib/result/result";
import {GeneralFailure} from "../../lib/general-failure/general-failure";
import {OpportunityGroupType} from "app/model/opportunity-group/opportunity-group-type";
import { OpportunityV2 } from "app/model/opportunity/opportunity";
import { Corporation, Individual } from "app/model/client/client";

export abstract class FormItemsV2QueryService {
  abstract getProductFormItems(product: Product | undefined, formLayoutId: FormLayoutId | undefined): Observable<FormItems>;
  abstract getProposalFormItems(proposal: Proposal | undefined, formLayoutId: FormLayoutId | undefined): Observable<FormItems>;
  abstract getOpportunityGroupFormItems(opportunityGroup: OpportunityGroup): Observable<Result<
    FormItems,
    | typeof GeneralFailure.Unexpected
  >>;
  abstract getOpportunityGroupFormItemsForAdd(opportunityGroupType: OpportunityGroupType): Observable<Result<
    FormItems,
    | typeof GeneralFailure.Unexpected
  >>;
  abstract getOpportunityFormItems(opportunity: OpportunityV2, formLayoutId: FormLayoutId | undefined): Observable<Result<
    FormItems,
    | typeof GeneralFailure.Unexpected
  >>;
  abstract getOpportunityFormItemsForAdd(formLayoutId: FormLayoutId | undefined): Observable<FormItems>;
  abstract getCorporationClientFormItems(corporation: Corporation, formLayoutId: FormLayoutId | undefined): Observable<Result<
    FormItems,
    | typeof GeneralFailure.Unexpected
  >>;
  abstract getCorporationClientFormItemsForAdd(formLayoutId: FormLayoutId | undefined): Observable<FormItems>;
  abstract getIndividualClientFormItems(individual: Individual, formLayoutId: FormLayoutId | undefined): Observable<Result<
    FormItems,
    | typeof GeneralFailure.Unexpected
  >>;
  abstract getIndividualClientFormItemsForAdd(formLayoutId: FormLayoutId | undefined): Observable<FormItems>;
}
