import {
  AssignedOrganization,
  Consultant,
  ConsultantID,
  DisplayName,
  MailAddress,
  ConsultantLoginID
} from "app/model/consultant/consultant";
import {ConsultantFactory, CreateConsultantParams} from "app/model/consultant/consultant.factory";
import {ConsultantRepository} from "app/model/consultant/consultant.repository";
import {Injectable} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {catchError, concatMap, filter, tap} from "rxjs/operators";
import {ConfirmService} from "./confirm.service";
import {Page, PageNavigateService} from "./page-navigate.service";
import {SnackBarService} from "./snack-bar.service";
import {messageFn, Target} from "#utils/messages";
import {MessageDialogService} from "#application/services/message-dialog.service";

export type AddConsultantInput = {
  userName: ConsultantLoginID,
  displayName: DisplayName,
  mailAddress: MailAddress,
  assignedOrganization: AssignedOrganization,
}

@Injectable({ providedIn: 'root' })
export class ConsultantCommandService {
  constructor(
    private readonly consultantFactory: ConsultantFactory,
    private readonly consultantRepository: ConsultantRepository,
    private readonly snackBarService: SnackBarService,
    private readonly pageNavigateService: PageNavigateService,
    private readonly confirmService: ConfirmService,
    private readonly messageDialogService: MessageDialogService,
  ) {}

  add(
    loginID: ConsultantLoginID,
    displayName: DisplayName,
    mailAddress: MailAddress,
    assignedOrganization: AssignedOrganization,
  ): Observable<void> {
    const params: CreateConsultantParams = {
      loginID,
      displayName,
      mailAddress,
      assignedOrganization,
    }
    return this.consultantFactory.create(params)
    .pipe(
      tap(() => {
        this.pageNavigateService.navigate(Page.AdminConsultant);
        this.snackBarService.show(messageFn(Target.CONSULTANT, 'CREATE_SUCCESS').message);
      }),
      catchError((err) => {
        this.snackBarService.show(messageFn(Target.CONSULTANT, 'CREATE_ERROR').message);
        return throwError(err);
      })
    );
  }

  update(consultant: Consultant): Observable<void> {
    return this.consultantRepository.save(consultant)
    .pipe(
      tap(() => {
        this.pageNavigateService.navigate(Page.AdminConsultant);
        this.snackBarService.show(messageFn(Target.CONSULTANT, 'UPDATE_SUCCESS').message);
      }),
      catchError((err) => {
        this.snackBarService.show(messageFn(Target.CONSULTANT, 'UPDATE_ERROR').message);
        return throwError(err);
      })
    );
  }

  delete(id: ConsultantID): Observable<void> {
    return this.confirmService.confirm(
      {
        title: messageFn(Target.CONSULTANT, 'DELETE_CONFIRM').title,
        message: messageFn(Target.CONSULTANT, 'DELETE_CONFIRM').message
      }
    )
    .pipe(
      filter(result => result.isOK()),
      concatMap(() => this.consultantRepository.delete(id)),
      tap(() => {
        this.pageNavigateService.navigate(Page.AdminConsultant);
        this.snackBarService.show(messageFn(Target.CONSULTANT, 'DELETE_SUCCESS').message);
      }),
      catchError((err) => {
        this.messageDialogService.notice({
          title: messageFn(Target.CONSULTANT, 'DELETE_ERROR').title,
          message: messageFn(Target.CONSULTANT, 'DELETE_ERROR').message
        });
        return throwError(err);
      })
    );
  }

  resetPassword(id: ConsultantID): Observable<void> {
    return this.confirmService.confirm(
      {
        title: 'パスワードをリセットしますか？',
        message: ''
      }
    )
    .pipe(
      filter(result => result.isOK()),
      concatMap(() => this.consultantRepository.resetPassword(id)),
      tap(() => {
        this.pageNavigateService.navigate(Page.AdminConsultant);
        this.snackBarService.show(`パスワードをリセットしました。`);
      }),
      catchError((err) => {
        this.snackBarService.show(`パスワードリセットに失敗しました。`);
        return throwError(err);
      })
    );
  }
}
