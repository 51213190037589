import {
  ClientProductRelationshipRepository
} from "app/model/client-product-relationship/client-product-relationship.repository";
import {ClientProductRelationshipID} from "app/model/client-product-relationship/client-product-relationship";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {ServerApi} from "#infrastructure/api/server-api";

@Injectable({providedIn: 'root'})
export class ClientProductRelationshipRepositoryImpl extends ClientProductRelationshipRepository{

  constructor(
    private readonly serverApi: ServerApi
  ) {
    super();
  }

  delete(clientProductRelationshipID: ClientProductRelationshipID): Observable<void> {
    return this.serverApi.clientProductRelationshipApi.delete(clientProductRelationshipID.value);
  }

}
