import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type GetCoachingHistoryResponse = {
  entries: {
    actionID: string,
    actionStartDateTime: string,
    actionEndDateTime: string,
    preCoaching: {
      coachingID: string,
      history: ApiCoachingHistoryInsight[],
    } | undefined,
    postCoaching: {
      coachingID: string,
      history: ApiCoachingHistoryInsight[],
    } | undefined,
  }[]
};
export type ApiCoachingHistoryInsight = {
  insight: {
    id: string,
    question: string,
  },
  reply: TextReply | SelectionReply
};
type TextReply = {
  type: 'TEXT',
  content: {
    value: string,
  },
};
type SelectionReply = {
  type: 'SELECTION',
  content: {
    answerOptions: {
      id: string,
      answer: string,
    }[],
  },
};

export class OpportunityCoachingHistoryApi {
  constructor(private readonly http: HttpClient) {}

  get(opportunityID: string): Observable<GetCoachingHistoryResponse> {
    return this.http.get<GetCoachingHistoryResponse>(`/api/opportunities/${opportunityID}/coaching-history`);
  }
}
