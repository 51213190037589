import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { GenericStringSearchFormComponent } from './generic-string-search-form.component';

@NgModule({
  declarations: [
    GenericStringSearchFormComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatRippleModule,
  ],
  exports: [
    GenericStringSearchFormComponent,
  ]
})
export class GenericStringSearchFormModule { }
