import { StatusCodes as HttpStatusCode } from 'http-status-codes';
import { NEVER, Observable, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { AuthorizeService } from '#application/services/authorize.service';
import { MessageDialogService } from '#application/services/message-dialog.service';
import { Injectable, inject } from '@angular/core';
import { ServerApiError, ServerApiErrorCode } from './error';

const RUN = 'RUN';
const CLEAR = 'CLEAR';

/**
 * HTTPレスポンスのインターセプター
 */
@Injectable({providedIn: 'root'})
export class HttpResponseInterceptor implements HttpInterceptor {
  private readonly authorizeService = inject(AuthorizeService);
  private readonly messageDialogService = inject(MessageDialogService);

  private readonly _sessionTimeoutHandler$ = new Subject<typeof RUN | typeof CLEAR>();

  constructor() {
    /** 
     * セッションタイムアウト時はログアウトし、メッセージを表示する。
     * 並行した複数リクエストにより複数エラーが返ってくることがあるため、
     * 重複排除・キャンセル処理を行っている。
     */
    this._sessionTimeoutHandler$.pipe(
      distinctUntilChanged(),
      filter(v => v === RUN),
      switchMap(() => this.authorizeService.logout().pipe(
        tap(() => {
          this.messageDialogService.notice({
            title: '自動でログアウトしました',
            message: '最終ログインから一定時間が経過したため、自動でログアウトしました。\nお手数ですが、もう一度ログインしてください。'
          });
        })
      )),
    )
    .subscribe();
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError(this._handleHttpError)
      )
      ;
  }

  /**
   * HTTPエラーレスポンスをサーバーエラーに変換
   * HttpStatusCode.UNAUTHORIZEDのセッション切れの場合はハンドラに委ねる。
   * ログイン時の認証失敗は後続の処理にハンドリングを任せるためServerApiErrorを投げる。
   * HttpStatusCode.FORBIDDENの場合もセッション切れの場合はハンドラに委ねる。
   * ハンドラに委ねる場合、後続の処理でエラーを扱わないようにNEVERを返す。
   *  
   * @param response エラー
   */
  private _handleHttpError = (response: unknown): Observable<never> | never => {
    if (!(response instanceof HttpErrorResponse)) {
      throw response;
    }
    switch (response.status) {
      case HttpStatusCode.BAD_REQUEST:
        throw new ServerApiError(ServerApiErrorCode.BadRequest, response.error);
      case HttpStatusCode.UNAUTHORIZED:
        if (response.error === 'AUTHENTICATION_FAILED') {
          throw new ServerApiError(ServerApiErrorCode.Unauthorized, response.error);
        }
        this._sessionTimeoutHandler$.next(RUN);
        this._sessionTimeoutHandler$.next(CLEAR);
        return NEVER;
      case HttpStatusCode.FORBIDDEN:
        this._sessionTimeoutHandler$.next(RUN);
        this._sessionTimeoutHandler$.next(CLEAR);
        return NEVER;
      case HttpStatusCode.NOT_FOUND:
        throw new ServerApiError(ServerApiErrorCode.NotFound, response.error);
      case HttpStatusCode.CONFLICT:
        throw new ServerApiError(ServerApiErrorCode.Conflict, response.error);
      default:
        throw new ServerApiError(ServerApiErrorCode.Unknown, response.error);
    }
  }
}