import { MessageDialogService } from "#application/services/message-dialog.service";
import { ProposalQueryService, ProposalsSearchResults, SearchProposalsInputs } from "#application/services/proposal-query.service";
import { AdoptedReasonID, AdoptedReasonName } from "app/model/adopted-reason/adopted-reason";
import { CustomItems } from "app/model/custom-field/custom-field";
import { DeclineReasonID, DeclineReasonName } from "app/model/decline-reason/decline-reason";
import { ProductId } from "app/model/product/product";
import { ProposalStatusID, ProposalStatusName, ProposalStatusType } from "app/model/proposal-status/proposal-status";
import { ProductDetailType, ProposalTypeID, ProposalTypeName } from "app/model/proposal-type/proposal-type";
import { ProposalID, ProposalNumber, ProposalDetailRowTotalAmount, ProposalTitle, Proposal, ProposalDetailRow, AdoptedReasonNote, DeclineReasonNote } from "app/model/proposal/proposal";
import { ServerApi } from "#infrastructure/api/server-api";
import { GetProposalResponse, SearchProposalsRequest } from "#infrastructure/api/server-proposal-api";
import { Injectable } from "@angular/core";
import { id } from "@swimlane/ngx-charts";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class ProposalQueryServiceImpl implements ProposalQueryService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly messageDialogService: MessageDialogService,
  ) {}

  search(inputs: SearchProposalsInputs): Observable<ProposalsSearchResults> {
    return this.serverApi.proposalApi.search({
      perPage: inputs.perPage,
      page: inputs.page,
      filter: this._convertFilter(inputs.filter),
    })
    .pipe(
      map(v => ({
        totalCount: v.totalCount,
        results: v.results.map(r => ({
          id: new ProposalID(r.id),
          title: new ProposalTitle(r.title),
          number: new ProposalNumber(r.number),
          statusID: new ProposalStatusID(r.statusID),
          statusType: this._convertProposalStatusType(r.statusType),
          statusName: new ProposalStatusName(r.statusName),
          proposalTypeID: new ProposalTypeID(r.proposalTypeID),
          proposalTypeName: new ProposalTypeName(r.proposalTypeName),
          totalAmount: new ProposalDetailRowTotalAmount(r.totalAmount),
        }))
      })),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: '提案一覧の取得'
        });
        return throwError(err);
      })
    );
  }

  getBy(prop: ProposalID | ProposalNumber): Observable<Proposal> {
    if (prop instanceof ProposalID) {
      return this.serverApi.proposalApi.get(prop.value)
      .pipe(
        map(res => this._convertProposal(res)),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '提案の取得'
          });
          return throwError(err);
        })
      );
    }
    if (prop instanceof ProposalNumber) {
      return this.serverApi.proposalApi.getByNumber(prop.value)
      .pipe(
        map(res => this._convertProposal(res)),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '提案の取得'
          });
          return throwError(err);
        })
      );
    }
    const unexpected: never = prop;
    throw Error(`${unexpected}は予期せぬ値です。`);
  }

  private _convertFilter(
    inputFilter: SearchProposalsInputs['filter']
  ): SearchProposalsRequest['filter']
  {
    const filter: SearchProposalsRequest['filter'] = {};
    Object.entries(inputFilter).forEach(([prop, predicate]) => {
      if (predicate === undefined) return;
      filter[`${prop}`] = {
        type: predicate.type,
        values: predicate.value,
      };
    });
    return filter;
  }

  private _convertProposalStatusType(
    type: 'DRAFT' | 'READY' | 'PROPOSED' | 'ADOPTED' | 'DECLINED' | 'REPROPOSAL_REQUESTED'
  ) {
    switch (type) {
      case 'DRAFT':
        return ProposalStatusType.DRAFT;
      case 'READY':
        return ProposalStatusType.READY;
      case 'PROPOSED':
        return ProposalStatusType.PROPOSED;
      case 'ADOPTED':
        return ProposalStatusType.ADOPTED;
      case 'DECLINED':
        return ProposalStatusType.DECLINED;
      case 'REPROPOSAL_REQUESTED':
        return ProposalStatusType.REPROPOSAL_REQUESTED;
      default:
        const unexpected: never = type;
        throw Error(`${unexpected}は予期せぬ値です。`);
    }
  }

  private _convertProposal(res: GetProposalResponse): Proposal {
    return {
      id: new ProposalID(res.id),
      title: new ProposalTitle(res.title),
      number: new ProposalNumber(res.number),
      proposalTypeID: new ProposalTypeID(res.proposalTypeID),
      statusID: new ProposalStatusID(res.proposalStatusID),
      items: CustomItems.convertFromRawKVObj(res.items),
      detail: this._convertDetail(res.detail),
      status: this._convertStatusWithReasons(res.status),
    };
  }

  private _convertDetail(detail: GetProposalResponse['detail']): Proposal['detail'] {
    const type = detail.type;
    switch (type) {
      case 'SINGLE':
        return {
          type: ProductDetailType.SINGLE,
          productID: new ProductId(detail.productID)
        };
      case 'MULTIPLE':
        return {
          type: ProductDetailType.MULTIPLE,
          rows: detail.rows.map(r => ProposalDetailRow.fromRawValue(
            r.title,
            r.quantity,
            r.unit,
            0,
            r.subTotalAmount,
            r.productID,
            r.productNumber
          )
          .calculateUnitPrice()
          ),
        };
      default:
        const unexpected: never = type;
        throw Error(`${unexpected}は予期せぬ値です。`);
    }
  }

  private _convertStatusWithReasons(status: GetProposalResponse['status']): Proposal['status'] {
    const type = status.type;
    switch (type) {
      case 'ADOPTED':
        return {
          type: ProposalStatusType.ADOPTED,
          reasons: status.reasons.map(r => ({
            id: new AdoptedReasonID(r.id),
            name: new AdoptedReasonName(r.name)
          })),
          reasonNote: new AdoptedReasonNote(status.reasonNote)
        };
      case 'DECLINED':
        return {
          type: ProposalStatusType.DECLINED,
          reasons: status.reasons.map(r => ({
            id: new DeclineReasonID(r.id),
            name: new DeclineReasonName(r.name)
          })),
          reasonNote: new DeclineReasonNote(status.reasonNote)
        };
      case 'DRAFT':
        return {
          type: ProposalStatusType.DRAFT
        };
      case 'READY':
        return {
          type: ProposalStatusType.READY
        };
      case 'PROPOSED':
        return {
          type: ProposalStatusType.PROPOSED
        };
      case 'REPROPOSAL_REQUESTED':
        return {
          type: ProposalStatusType.REPROPOSAL_REQUESTED
        };
      default:
        const unexpected: never = type;
        throw Error(`${unexpected}は予期せぬ値です。`);
    }
  }
}
