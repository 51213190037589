import { MessageDialogService } from '#application/services/message-dialog.service';
import { ListOpportunityReportGoalsInputs, ListOpportunityReportGoalsOutputs, OpportunityListReportGoalsQueryService } from '#application/services/opportunity-report-goals-query.service';
import { OpportunityTypeName } from 'app/model/opportunity-type/opportunity-type';
import { OpportunityID, OpportunityName, OpportunityNumber, OpportunityStatus } from 'app/model/opportunity/opportunity';
import { PhaseName } from 'app/model/sales-phase/sales-phase';
import { DisplayName } from 'app/model/user/user';
import { ServerApi } from '#infrastructure/api/server-api';
import { ListOpportunityReportGoalsRequest, ListOpportunityReportGoalsResponse } from '#infrastructure/api/server-opportunity-report-goals-api';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

type Assignee = {
  type: string,
  userID?: string,
  userGroupID?: string,
};

@Injectable({
  providedIn: 'root',
})
export class OpportunityListReportGoalsQueryServiceImpl extends OpportunityListReportGoalsQueryService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _messageDialogService = inject(MessageDialogService);

  list(inputs: ListOpportunityReportGoalsInputs): Observable<ListOpportunityReportGoalsOutputs> {
    return this._serverApi.opportunityReportGoalsApi
      .list(this._toRequestBody(inputs))
      .pipe(
        map((res: ListOpportunityReportGoalsResponse) => this._toOutputs(res)),
        catchError((err) => {
          this._messageDialogService.notice({
            errorTarget: '商談一覧の取得',
          });
          return throwError(err);
        }),
      );
  }

  private _toRequestBody(inputs: ListOpportunityReportGoalsInputs): ListOpportunityReportGoalsRequest {
    const assignee: Assignee = { type: inputs.assignee.type };
    if (inputs.assignee.type === 'USER') {
      assignee.userID = inputs.assignee.userID.value;
    } else if (inputs.assignee.type === 'USER_GROUP') {
      assignee.userGroupID = inputs.assignee.userGroupID.value;
    }
    return {
      pagination: {
        perPage: inputs.pagination.perPage,
        page: inputs.pagination.page,
      },
      goalCategory: inputs.goalCategory,
      duration: {
        startYearMonth: inputs.duration.startYearMonth.toFormat('yyyy-MM'),
        months: inputs.duration.months,
      },
      assignee: assignee,
    };
  }

  private _toOutputs(res: ListOpportunityReportGoalsResponse): ListOpportunityReportGoalsOutputs {
    return {
      totalCount: res.totalCount,
      list: res.results.map(result => {
        return {
          id: new OpportunityID(result.id),
          name: new OpportunityName(result.opportunityName),
          status: result.status as Exclude<OpportunityStatus, 'DELETED'>,
          number: new OpportunityNumber(result.opportunityNumber),
          typeName: new OpportunityTypeName(result.opportunityTypeName),
          assigneeName: new DisplayName(result.assigneeName),
          phaseName: new PhaseName(result.phaseName),
        }
      }),
    };
  }
}
