import {Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {ServerApi} from "#infrastructure/api/server-api";
import {map} from "rxjs/operators";
import { FormLayoutCategory, FormLayoutId, SectionTitle } from 'app/model/form-layout/form-layout';
import { CustomFieldDescription, CustomFieldId, CustomFieldName } from 'app/model/custom-field/custom-field';
import { BuiltinFormItem, CustomFormItem, FormItemsList, FormItemsQueryService, SectionItem } from '#application/services/form-items-query.service';
import { BuiltinFormItemData, CustomFormItemData, GetFormItemsResponse, SectionFormItemData } from '#infrastructure/api/server-form-items-api';
import { convertApiDataToNumberSettingsDomain, convertApiDataToSelectSettingsDomain } from './product-field-query.service';

const convertSectionDataToFormItem = (data: SectionFormItemData): SectionItem => {
  return {
    layoutItemType: data.layoutItemType,
    width: data.width,
    title: new SectionTitle(data.title)
  };
};

const convertBuiltinDataToFormItem = (data: BuiltinFormItemData): BuiltinFormItem => {
  return {
    layoutItemType: data.layoutItemType,
    builtinFieldID: data.builtinFieldID,
    width: data.width,
    fieldType: data.fieldType,
  };
};

const convertCustomDataToFormItem = (data: CustomFormItemData): CustomFormItem => {
  return {
    layoutItemType: data.layoutItemType,
    customFieldID: new CustomFieldId(data.customFieldID),
    width: data.width,
    name: new CustomFieldName(data.name),
    description: new CustomFieldDescription(data.description),
    fieldType: data.fieldType,
    required: data.required,
    numberSetting: data.numberSetting
      ? convertApiDataToNumberSettingsDomain(data.numberSetting)
      : undefined,
    selectSetting: data.selectSetting
      ? convertApiDataToSelectSettingsDomain(data.selectSetting)
      : undefined
  };
};

const getResponseToFormItems = (response: GetFormItemsResponse): FormItemsList => {
  return response.items.map(item => {
      switch (item.layoutItemType) {
        case 'SECTION_TITLE':
          return convertSectionDataToFormItem(item);
        case `CUSTOM_FIELD`:
          return convertCustomDataToFormItem(item);
        case `BUILTIN_FIELD`:
          return convertBuiltinDataToFormItem(item);
        default:
          const unexpected: never = item;
          throw Error(`${unexpected} is unexpected value`);
      }
  });
};

@Injectable({providedIn: 'root'})
export class FormItemsQueryServiceImpl implements FormItemsQueryService {
  constructor(
    private readonly serverApi: ServerApi
  ) {}

  getFormItems(category: FormLayoutCategory, layoutID: FormLayoutId | undefined): Observable<FormItemsList> {
    if (layoutID) {
      return this.serverApi.formItemsApi
        .get(category, layoutID.value)
        .pipe(map(getResponseToFormItems));
    }
    return this.serverApi.defaultFormItemsApi
      .get(category)
      .pipe(map(getResponseToFormItems));
  }
}
