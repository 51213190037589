import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type ApiCoachingTiming = 'PRE' | 'POST'

type CreateInsightRequestCommonData = {
  coachingTiming: ApiCoachingTiming,
  question: string,
  supplementTitle: string,
  supplement: string,
  triggeringOpportunityContextIDs: string[],
};
type CreateInsightTextAnswerRequestData = CreateInsightRequestCommonData & {
  answerType: 'TEXT',
};
type CreateInsightSelectionAnswerRequestData = CreateInsightRequestCommonData & {
  answerType: 'SELECTION',
  selection: {
    multiple: boolean,
    answerOptions: {
      answer: string,
      occurringOpportunityContextIDs?: string[],
      hintID: string | undefined,
    }[],
  },
};
export type CreateInsightRequest =
  | CreateInsightTextAnswerRequestData
  | CreateInsightSelectionAnswerRequestData

type UpdateInsightRequestCommonData = {
  question: string,
  supplementTitle: string,
  supplement: string,
  triggeringOpportunityContextIDs: string[],
};
type UpdateInsightTextAnswerRequestData = UpdateInsightRequestCommonData & {
  answerType: 'TEXT',
};
type UpdateInsightSelectionAnswerRequestData = UpdateInsightRequestCommonData & {
  answerType: 'SELECTION',
  selection: {
    multiple: boolean,
    answerOptions: {
      id?: string,
      answer: string,
      occurringOpportunityContextIDs?: string[]
      hintID: string | undefined,
    }[],
  },
};
export type UpdateInsightRequest =
  | UpdateInsightTextAnswerRequestData
  | UpdateInsightSelectionAnswerRequestData

interface SearchInsightListRequestData {
  perPage: number,
  page: number,
  filter?: {
    question: {
      type: 'contains',
      value: string,
    },
    opportunityContextID: {
      type: 'anyMatch',
      values: string[],
    },
    coachingTiming: {
      type: 'anyMatch',
      values: ApiCoachingTiming[],
    },
  }
}
export type SearchInsightListRequest = SearchInsightListRequestData;

interface SearchInsightListResponseData {
  totalCount: number,
  results: {
    id: string,
    coachingTiming: ApiCoachingTiming,
    question: string,
    triggeringOpportunityContexts: {
      id: string,
      name: string,
    }[],
  }[]
}
export type SearchInsightListResponse = SearchInsightListResponseData;

type GetInsightResponseCommonData = {
  id: string,
  coachingTiming: ApiCoachingTiming,
  question: string,
  supplementTitle: string,
  supplement: string,
  triggeringOpportunityContexts: {
    id: string,
    name: string,
  }[],
};

type GetInsightTextAnswerResponseData = GetInsightResponseCommonData & {
  answerType: 'TEXT',
};

export type GetInsightSelectionAnswerResponseData = GetInsightResponseCommonData & {
  answerType: 'SELECTION',
  selection: {
    multiple: boolean,
    answerOptions: {
      id: string,
      answer: string,
      occurringOpportunityContexts: {
        id: string,
        name: string,
      }[],
      hint: {
        id: string,
        title: string,
      } | undefined,
    }[],
  },
};

export type InsightFeedbackRequest = {
  feedbackOptionID: string,
  feedbackContent: string,
}

export type GetInsightResponse =
  | GetInsightTextAnswerResponseData
  | GetInsightSelectionAnswerResponseData

export class InsightApi {
  constructor(private readonly http: HttpClient) {}

  create(request: CreateInsightRequest): Observable<void> {
    return this.http.post<void>(`/api/insights`, request);
  }

  update(id: string, request: UpdateInsightRequest): Observable<void> {
    return this.http.put<void>(`/api/insights/${id}`, request);
  }

  search(request: SearchInsightListRequest): Observable<SearchInsightListResponse> {
    return this.http.post<SearchInsightListResponse>(`/api/insight-list/search`, request);
  }

  get(id: string): Observable<GetInsightResponse> {
    return this.http.get<GetInsightResponse>(`/api/insights/${id}`,);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/insights/${id}`);
  }

  feedback(id: string, request: InsightFeedbackRequest): Observable<void> {
    return this.http.post<void>(`/api/insights/${id}/feedback`, request);
  }
}
