import { OpportunityContextLabelName } from 'app/model/opportunity-context/opportunity-context';
import { FilterPredicateType } from 'app/model/search-filter/search-filter';
import { Observable } from 'rxjs';

export type SearchOpportunityContextLabelListParams = {
  perPage: number,
  page: number,
  filter?: {
    labelName: {
      type: typeof FilterPredicateType.contains,
      value: string,
    },
  }
};

export type OpportunityContextLabelList = {
  totalCount: number,
  results: OpportunityContextLabelName[],
};

export abstract class BaseOpportunityContextLabelQueryService {
  abstract findBy(params: SearchOpportunityContextLabelListParams): Observable<OpportunityContextLabelList>;
}
