import { ProposalStatusID, ProposalStatusName, ProposalStatusType } from "app/model/proposal-status/proposal-status";
import { ProposalTypeID, ProposalTypeName } from "app/model/proposal-type/proposal-type";
import { Proposal, ProposalDetailRowTotalAmount, ProposalID, ProposalNumber, ProposalTitle } from "app/model/proposal/proposal";
import { FilterPredicateType } from "app/model/search-filter/search-filter";
import { Observable } from "rxjs";

export type SearchProposalsInputs = {
  perPage: number,
  page: number,
  filter: {
    opportunityID?: {
      type: typeof FilterPredicateType.anyMatch,
      value: string[],
    },
    productID?: {
      type: typeof FilterPredicateType.anyMatch,
      value: string[],
    },
  },
}

export type ProposalsSearchResults = {
  totalCount: number,
  results: {
    id: ProposalID,
    title: ProposalTitle,
    number: ProposalNumber,
    statusID: ProposalStatusID,
    statusType: ProposalStatusType,
    statusName: ProposalStatusName,
    proposalTypeID: ProposalTypeID,
    proposalTypeName: ProposalTypeName,
    totalAmount: ProposalDetailRowTotalAmount,
  }[]
}

export abstract class ProposalQueryService {
  abstract search(inputs: SearchProposalsInputs): Observable<ProposalsSearchResults>;
  abstract getBy(prop: ProposalID | ProposalNumber): Observable<Proposal>;
}
