import {ActionTypeID, ActionTypeName} from './action-type';
import {PhaseID, PhaseName} from 'app/model/sales-phase/sales-phase';
import {DateTime} from 'luxon';
import {OpportunityID, OpportunityName} from 'app/model/opportunity/opportunity';
import { DisplayName, UserID } from 'app/model/user/user';
import {ContactID, DepartmentName, ContactRoleName, ContactDisplayName} from "app/model/contact/contact";
import { Companions } from '../companion/companion';

/**
 * 活動履歴の項目
 */
export class ActionHistoryItem {
  constructor(
    public readonly id: ActionID,
    public readonly opportunityName: OpportunityName,
    public readonly startDateTime: ActionStartDateTime,
    public readonly endDateTime: ActionEndDateTime,
    public readonly actionTypeName: ActionTypeName,
    public readonly currentPhaseName: PhaseName,
    public readonly startPhaseName: PhaseName,
    public readonly targetPhaseName: PhaseName,
    public readonly assignee: UserID,
    public readonly assigneeName: DisplayName,
    public readonly isDone: IsDone,
    public readonly actualStartPhaseName: PhaseName,
    public readonly actualPhaseName: PhaseName,
    public readonly isEditable: IsEditable
  ) {}
}

/**
 * 活動の履歴
 */
export class ActionHistory {
  constructor(
    public readonly list: ActionHistoryItem[]
  ) {
  }

  /** 活動履歴の有無 */
  get hasHistory(): boolean {
    return this.list.length > 0;
  }

  /** 未報告活動件数 */
  get unreportedCount(): number {
    return this.list.reduce((acc, cur) => {
      return (acc += cur.isDone.value ? 0 : 1);
    }, 0);
  }
}

export class WeeklyActionSummary {
  constructor(
    public readonly list: DailyActionSummary[]
  ) {
  }

  public getFirstDate(): Date {
    return this.list[0].date.value;
  }

  public getLastDate(): Date {
    return this.list[this.list.length - 1].date.value;
  }
}

export class DailyActionSummaries {
  constructor(
    public readonly list: DailyActionSummary[]
  ) {
  }

  public getFirstDate(): Date {
    return this.list[0].date.value;
  }

  public getLastDate(): Date {
    return this.list[this.list.length - 1].date.value;
  }
}

export class DailyActionSummary {
  constructor(
    public readonly date: ActionSummaryDate,
    public readonly list: ActionSummary[],
  ) {
  }
}

export class ActionSummaryDate {
  constructor(
    public readonly value: Date,
  ) {
  }
}

export class ActionSummary {
  constructor(
    public readonly actionID: ActionID,
    public readonly opportunityName: OpportunityName,
    public readonly targetPhaseName: PhaseName,
    public readonly actionTypeName: ActionTypeName,
    public readonly startDateTime: ActionStartDateTime,
    public readonly endDateTime: ActionEndDateTime,
    public readonly allDay: AllDay,
    public readonly isDone: IsDone,
    public readonly isEditable: IsEditable,
  ) {
  }

  getTitle(): ActionSummaryTitle {
    return new ActionSummaryTitle(`${this.actionTypeName.value}_${this.targetPhaseName.value}_${this.opportunityName.value}`);
  }

  isOverlap(actionSummary: ActionSummary) {
    return (
      (actionSummary.startDateTime.value >= this.startDateTime.value &&
        actionSummary.startDateTime.value < this.endDateTime.value) ||
      (actionSummary.endDateTime.value > this.startDateTime.value &&
        actionSummary.endDateTime.value <= this.endDateTime.value)
    );
  }
}

export class ActionSummaryTitle {
  constructor(
    public readonly value: string
  ) {
  }
}

export class AllDay {
  constructor(
    public readonly value: boolean
  ) {
  }
}

/**
 * 活動
 */
export class Action {
  constructor(
    readonly actionID: ActionID,
    /** 作成者のユーザーID */
    readonly userID: UserID,
    /** 作成者のユーザー表示名 */
    readonly userDisplayName: DisplayName,
    readonly opportunityID: OpportunityID,
    readonly currentPhaseID: PhaseID,
    readonly startPhaseID: PhaseID,
    readonly targetPhaseID: PhaseID,
    readonly actionTypeID: ActionTypeID,
    readonly actionTypeName: ActionTypeName,
    readonly startDateTime: ActionStartDateTime,
    readonly endDateTime: ActionEndDateTime,
    readonly isDone: IsDone,
    readonly note: ActionNote,
    readonly companions?: Companions,
    readonly attendees?: ActionAttendees,
    readonly actualStartPhaseID?: PhaseID,
    readonly actualPhaseID?: PhaseID,
  ) {
  }

  changeStartPhaseID(newStartPhaseID: PhaseID): Action {
    return new Action(
      this.actionID,
      this.userID,
      this.userDisplayName,
      this.opportunityID,
      this.currentPhaseID,
      newStartPhaseID,
      this.targetPhaseID,
      this.actionTypeID,
      this.actionTypeName,
      this.startDateTime,
      this.endDateTime,
      this.isDone,
      this.note,
      this.companions,
      this.attendees,
      this.actualStartPhaseID,
      this.actualPhaseID,
    )
  }

  changeTargetPhaseID(newTargetPhaseID: PhaseID): Action {
    return new Action(
      this.actionID,
      this.userID,
      this.userDisplayName,
      this.opportunityID,
      this.currentPhaseID,
      this.startPhaseID,
      newTargetPhaseID,
      this.actionTypeID,
      this.actionTypeName,
      this.startDateTime,
      this.endDateTime,
      this.isDone,
      this.note,
      this.companions,
      this.attendees,
      this.actualStartPhaseID,
      this.actualPhaseID,
    );
  }

  changeActionTypeID(newActionTypeID: ActionTypeID): Action {
    return new Action(
      this.actionID,
      this.userID,
      this.userDisplayName,
      this.opportunityID,
      this.currentPhaseID,
      this.startPhaseID,
      this.targetPhaseID,
      newActionTypeID,
      this.actionTypeName,
      this.startDateTime,
      this.endDateTime,
      this.isDone,
      this.note,
      this.companions,
      this.attendees,
      this.actualStartPhaseID,
      this.actualPhaseID,
    );
  }

  changeStartDateTime(newStartDateTime: ActionStartDateTime): Action {
    return new Action(
      this.actionID,
      this.userID,
      this.userDisplayName,
      this.opportunityID,
      this.currentPhaseID,
      this.startPhaseID,
      this.targetPhaseID,
      this.actionTypeID,
      this.actionTypeName,
      newStartDateTime,
      this.endDateTime,
      this.isDone,
      this.note,
      this.companions,
      this.attendees,
      this.actualStartPhaseID,
      this.actualPhaseID,
    );
  }

  changeEndDateTime(newEndDateTime: ActionEndDateTime): Action {
    return new Action(
      this.actionID,
      this.userID,
      this.userDisplayName,
      this.opportunityID,
      this.currentPhaseID,
      this.startPhaseID,
      this.targetPhaseID,
      this.actionTypeID,
      this.actionTypeName,
      this.startDateTime,
      newEndDateTime,
      this.isDone,
      this.note,
      this.companions,
      this.attendees,
      this.actualStartPhaseID,
      this.actualPhaseID,
    );
  }

  changeIsDone(newIsDone: IsDone): Action {
    return new Action(
      this.actionID,
      this.userID,
      this.userDisplayName,
      this.opportunityID,
      this.currentPhaseID,
      this.startPhaseID,
      this.targetPhaseID,
      this.actionTypeID,
      this.actionTypeName,
      this.startDateTime,
      this.endDateTime,
      newIsDone,
      this.note,
      this.companions,
      this.attendees,
      this.actualStartPhaseID,
      this.actualPhaseID,
    );
  }

  changeCompanions(newCompanions: Companions): Action {
    return new Action(
      this.actionID,
      this.userID,
      this.userDisplayName,
      this.opportunityID,
      this.currentPhaseID,
      this.startPhaseID,
      this.targetPhaseID,
      this.actionTypeID,
      this.actionTypeName,
      this.startDateTime,
      this.endDateTime,
      this.isDone,
      this.note,
      newCompanions,
      this.attendees,
      this.actualStartPhaseID,
      this.actualPhaseID,
    )
  }

  changeAttendees(newAttendees: ActionAttendees): Action {
    return new Action(
      this.actionID,
      this.userID,
      this.userDisplayName,
      this.opportunityID,
      this.currentPhaseID,
      this.startPhaseID,
      this.targetPhaseID,
      this.actionTypeID,
      this.actionTypeName,
      this.startDateTime,
      this.endDateTime,
      this.isDone,
      this.note,
      this.companions,
      newAttendees,
      this.actualStartPhaseID,
      this.actualPhaseID,
    )
  }

  changeActualStartPhaseID(newActualStartPhaseID?: PhaseID): Action {
    return new Action(
      this.actionID,
      this.userID,
      this.userDisplayName,
      this.opportunityID,
      this.currentPhaseID,
      this.startPhaseID,
      this.targetPhaseID,
      this.actionTypeID,
      this.actionTypeName,
      this.startDateTime,
      this.endDateTime,
      this.isDone,
      this.note,
      this.companions,
      this.attendees,
      newActualStartPhaseID,
      this.actualPhaseID,
    )
  }

  changeActualPhaseID(newActualPhaseID?: PhaseID): Action {
    return new Action(
      this.actionID,
      this.userID,
      this.userDisplayName,
      this.opportunityID,
      this.currentPhaseID,
      this.startPhaseID,
      this.targetPhaseID,
      this.actionTypeID,
      this.actionTypeName,
      this.startDateTime,
      this.endDateTime,
      this.isDone,
      this.note,
      this.companions,
      this.attendees,
      this.actualStartPhaseID,
      newActualPhaseID,
    );
  }

  changeNote(newNote: ActionNote): Action {
    return new Action(
      this.actionID,
      this.userID,
      this.userDisplayName,
      this.opportunityID,
      this.currentPhaseID,
      this.startPhaseID,
      this.targetPhaseID,
      this.actionTypeID,
      this.actionTypeName,
      this.startDateTime,
      this.endDateTime,
      this.isDone,
      newNote,
      this.companions,
      this.attendees,
      this.actualStartPhaseID,
      this.actualPhaseID,
    );
  }
}

/**
 * 活動を一意に識別するID
 */
export class ActionID {
  constructor(public readonly value: string) {}

  equals(obj: ActionID) {
    return this.value === obj.value;
  }
}

/**
 * 活動の開始日時
 */
export class ActionStartDateTime {
  constructor(
    public readonly value: Date,
  ) {
  }

  public format(format: string): string {
    const datetime = DateTime.fromJSDate(this.value);
    return datetime.toFormat(format);
  }
}

/**
 * 活動の終了日時
 */
export class ActionEndDateTime {
  constructor(
    public readonly value: Date,
  ) {
  }

  public format(format: string): string {
    const datetime = DateTime.fromJSDate(this.value);
    return datetime.toFormat(format);
  }
}

/**
 * 活動が完了しているかどうか
 */
export class IsDone {
  constructor(
    public readonly value: boolean,
  ) {
  }
}


/**
 * 活動が編集可能化どうか
 */
export class IsEditable {
  constructor(
    public readonly value: boolean,
  ) {
  }
}

/**
 * 活動の備考
 */
export class ActionNote {
  constructor(
    public readonly value: string,
  ) {
  }

  static readonly MAX_LENGTH = 3000;
}

export class ActionAttendees {
  constructor(
    public readonly list: ReadonlyArray<ActionAttendee>
  ) {}

  includes(contactID: ContactID): boolean {
    return this.list.some(attendee => attendee.contactID.equals(contactID))
  }
}

export class ActionAttendee {
  constructor(
    public readonly contactID: ContactID,
    public readonly contactDisplayName: ContactDisplayName,
    public readonly departmentName: DepartmentName,
    public readonly roleName: ContactRoleName,
  ) {}

  equals(attendee: ActionAttendee): boolean {
    return this.contactID.equals(attendee.contactID);
  }
}

/**
 * 活動の実施期間（単位：分）
 */
export class ActionMinutesDuration {
  constructor(readonly value: number) {}

  static readonly MAX_VALUE = 1439;
}

/**
 * 活動の期間で表現した日時
 */
export type ActionDuration = Readonly<{
  startDateTime: ActionStartDateTime,
  minutes: ActionMinutesDuration,
}>
