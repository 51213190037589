import { MessageDialogService } from "#application/services/message-dialog.service";
import { SnackBarService } from "#application/services/snack-bar.service";
import { UserProfileCalendarSettingsCommandService, UserProfileCalendarSettingsInputs } from "#application/services/user-profile-calendar-settings-command.service";
import { ServerApiError, ServerApiErrorCode } from "#infrastructure/api/error";
import { ServerApi } from "#infrastructure/api/server-api";
import { Target, messageFn } from "#utils/messages";
import { Injectable } from "@angular/core";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Failure, Result, Success } from "app/lib/result/result";
import { UserID } from "app/model/user/user";
import { NEVER, Observable, catchError, map, of, tap } from "rxjs";

@Injectable({ providedIn: 'root' })
export class UserProfileCalendarSettingsCommandServiceImpl extends UserProfileCalendarSettingsCommandService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
    private readonly messageDialogService: MessageDialogService,
  ) {
    super();
  }

  update(id: UserID, inputs: UserProfileCalendarSettingsInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
  >>{
    return this.serverApi.userProfileCalendarSettingsApi.update(id.value, inputs)
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(Target.CALENDAR_SETTING, 'UPDATE_SUCCESS').message);
      }),
      map((v) => Success<void>(v)),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.BadRequest:
            return of(Failure(GeneralFailure.BadRequest));
        }
        this.messageDialogService.notice({
          errorTarget: `${Target.CALENDAR_SETTING}の更新`,
        });
        return NEVER;
      })
    )
  }
}
