import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

export type GetInsightFeedbackOptionsResponse = {
  id: 'NOT_APPLICABLE_TO_THE_COMPANY' | 'NOT_WELL_EXPRESSED' | 'DO_NOT_UNDERSTAND' | 'OTHER',
  content: string,
}[];

export class InsightFeedbackOptionsApi {

  constructor(private readonly http: HttpClient) {}

  get(): Observable<GetInsightFeedbackOptionsResponse> {
    return this.http.get<GetInsightFeedbackOptionsResponse>(`/api/insight-feedback-options`);
  }
}
