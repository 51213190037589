import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export type CsvImportRequest = CsvImportRequestData;

interface CsvImportRequestData {
  fileName: string;
  typeId: string;
  formatInfo: {
    encoding: 'UTF-8' | 'Shift-JIS';
    delimiter: ',' | '\t';
    dateFormat: string;
    dateTimeFormat: string;
  };
  mapping: {
    fieldId: string;
  }[];
  file: string;
  uniqueFieldId?: string;
}

export type CsvImportResponse = CsvImportResponseData;

interface CsvImportResponseData {
  message?: string;
}

type Category = 'corporation' | 'individual' | 'opportunity' | 'product';

export class CsvImportApi {
  constructor(private readonly httpClient: HttpClient) {}

  importCsv(
    category: Category,
    request: CsvImportRequestData
  ): Observable<CsvImportResponse> {
    return this.httpClient.post<CsvImportResponse>(
      `/api/csv-import/${category}`,
      request
    );
  }
}
