import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { RouterModule } from "@angular/router";
import { TopBarModule } from "../top-bar/top-bar.module";
import { PageContainerComponent } from "./page-container.component";

@NgModule({
  declarations: [
    PageContainerComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    TopBarModule,
  ],
  exports: [
    PageContainerComponent,
  ]
})
export class PageContainerModule { }