import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiProposalStatusType} from "#infrastructure/api/server-proposal-status-api";

export type GetProposalWorkflowResponse = {
  statuses: {
    id: string,
    type: ApiProposalStatusType,
    name: string,
    description: string,
  }[],
}

export class ProposalWorkflowApi {
  constructor(private readonly http: HttpClient) {}

  get(): Observable<GetProposalWorkflowResponse> {
    return this.http.get<GetProposalWorkflowResponse>(`/api/proposal-status`);
  }

}
