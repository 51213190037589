import { Observable } from "rxjs"
import { AssignedOrganization, DisplayName, MailAddress, ConsultantLoginID } from "./consultant"

export type CreateConsultantParams = {
  loginID: ConsultantLoginID,
  displayName: DisplayName,
  mailAddress: MailAddress,
  assignedOrganization: AssignedOrganization,
}

export abstract class ConsultantFactory {
  abstract create(params: CreateConsultantParams): Observable<void>;
}
