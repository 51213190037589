import { Component } from '@angular/core';
import { LoginContainerComponent } from '../components/container/container.component';

@Component({
  selector: 'sn-login-as-consultant',
  standalone: true,
  imports: [LoginContainerComponent],
  template: `
    <sn-login-container [user]="'META_USER'"/>
  `,
})
export class LoginAsConsultantComponent {
}
