import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { buildHttpParams } from "./http-params-builder";
import { ApiFieldType } from "./server-custom-field-api";
import {OpportunityNumber} from "../../model/opportunity/opportunity";

export type GetOpportunityWithSettingsResponse = {
  opportunityID: string,
  opportunityName: string,
  opportunityNumber: number,
  phaseID: string,
  items: {
    [customFieldId: string]: string
  },
  salesPhase: {
    id: string,
    name: string,
    phaseList: {
      id: string,
      name: string,
      definition: string,
      successCriteria: string
    }[]
  },
  hearingItems: {
    phaseID: string,
    phaseName: string,
    items: {
      customFieldID: string,
      name: string,
      description: string,
      required: boolean,
      type: ApiFieldType,
      selectSetting?: {
        multiple: boolean,
        allowInput: boolean,
        options: string[]
      },
      numberSetting?: {
        defaultValue?: number,
        minValue?: number,
        maxValue?: number,
        unit: string
      }
    }[]
  }[]
};

export type GetHearingTiming = 'before' | 'after';

export class OpportunityWithSettingsForActionApi {
  constructor(private readonly http: HttpClient) {}

  get(opportunityID: string, timing: GetHearingTiming): Observable<GetOpportunityWithSettingsResponse> {
    const params = buildHttpParams({ opportunityID, timing });
    return this.http.get<GetOpportunityWithSettingsResponse>(
      `/api/action-management/opportunity-with-settings`,
      { params }
    )
  }
}
