import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type WebhookEventHistoryRequest = Readonly<{
  perPage: number,
  page: number,
  filter: {
    webhookID: {
      type: 'equals',
      value: string,
    }
  }
}>

export type WebhookEventHistoryResponse = Readonly<{
  totalCount: number,
  results: [
    {
      success: boolean,
      eventID: string,
      timestamp: string,
      request?: {
        headers: string,
        payload: string
      },
      response?: {
        statusCode: number,
        headers: string,
        body: string
      },
      error?: {
        message: string
      },
    }
  ]
}>

export class WebhookEventHistoryApi {
  constructor(private readonly http: HttpClient) {}

  search(request: WebhookEventHistoryRequest): Observable<WebhookEventHistoryResponse> {
    return this.http.post<WebhookEventHistoryResponse>(`/api/webhooks/events/search`, request);
  }

}
