import {SearchFilterProperty} from "#application/services/search-filter-property-query.service";
import {Bounds, Condition, FilterPredicate, FilterPredicateType, SortConditions} from "app/model/search-filter/search-filter";
import {FilterInput, ItemType} from "#infrastructure/application/filter.service";
import { SortOptions } from "../sortable-items";

export const FilterDataType = {
  text: 'text',
  number: 'number',
  date: 'date',
  list: 'list',
  user: 'user',
  file: 'file',
  client: 'client',
  checkbox: 'checkbox',
} as const;

export type FilterDataType = typeof FilterDataType[keyof typeof FilterDataType];

export type Predicate = {
  type: typeof FilterPredicateType.anyMatch
  valueType: typeof FilterDataType.list | typeof FilterDataType.user
  listValues: ListValue[],
} | {
  type: FilterPredicateType,
  valueType: typeof FilterDataType.number,
  unit?: string,
} | {
  type: Exclude<FilterPredicateType, typeof FilterPredicateType.anyMatch>,
  valueType: Exclude<FilterDataType, (typeof FilterDataType.number | typeof FilterDataType.list | typeof FilterDataType.user)>,
};

export type ListValue = {
  value: string,
  optionName: string,
};

/** 選択検索フィルター用の「未選択」選択肢 */
export const emptyOption: ListValue = {
  value: '$$$empty$$$',
  optionName: '(未選択)',
};

export const removeEmptyOptionFrom = (options: ListValue[]): ListValue[] => {
  return options.filter(op => op.value !== emptyOption.value);
}

export const convertPredicatesPlusEmptyOption = (
  predicates: SearchFilterProperty['predicates']
): Predicate[] => {
  let predicate: Predicate;
  return predicates.map(pre => {
    if (pre.type === 'anyMatch') {
      predicate = {
        type: pre.type,
        valueType: pre.valueType,
        listValues: pre.listValues === undefined
          ? []
          : [
            ...pre.listValues.map(v => ({
              value: v,
              optionName: v,
            })),
            emptyOption,
          ]
      };
    } else if (pre.valueType === 'number') {
      predicate = {
        type: pre.type,
        valueType: pre.valueType,
        unit: pre.unit,
      };
    } else {
      predicate = {
        type: pre.type,
        valueType: pre.valueType,
      };
    }
    return predicate;
  });
};

export type FilterItem = {
  itemName: string,
  itemType: ItemType
}

export type Filter = Readonly<{
  filterItem?: FilterItem,
  propertyId: string,
  type: typeof FilterPredicateType.greaterThanOrEqualTo
    | typeof FilterPredicateType.lessThanOrEqualTo
    | typeof FilterPredicateType.equals
    | typeof FilterPredicateType.empty,
  value: string,
} | {
  filterItem?: FilterItem,
  propertyId: string,
  type: typeof FilterPredicateType.anyMatch,
  value: string[],
} | {
  filterItem?: FilterItem,
  propertyId: string,
  type: typeof FilterPredicateType.contains,
  value: string,
  condition: Condition
} | {
  filterItem?: FilterItem,
  propertyId: string,
  type: typeof FilterPredicateType.range,
  from: string,
  range: number,
  bounds: Bounds,
} | {
  filterItem?: FilterItem,
  propertyId: string,
  type: typeof FilterPredicateType.between,
  from?: number,
  to?: number,
  bounds: Bounds,
}>;

export type FilterWithoutId = FilterPredicate;

export const extractSavedFilters = (from: FilterProperties): Filter[] => {
  return from
    .filter(prop => prop.savedFilter !== undefined)
    .map(prop => ({
      filterItem: {
        itemName: prop.itemName,
        itemType: prop.itemType,
      },
      ...prop.savedFilter!
    }));
};

export class SearchFilters {
  constructor(
    private readonly _list: Filter[]
  ) {
  }

  get list(): Filter[] {
    return this._list;
  }

  get length(): number {
    return this._list.length;
  }

  isEmpty(): boolean {
    return this.list.length === 0;
  }

  private _isEmptyValue(filter: Filter): boolean {
    if (filter.type === FilterPredicateType.range)
      return filter.from.length === 0;
    else if (filter.type === FilterPredicateType.between)
      return filter.from === undefined || filter.to === undefined;
    else
      return filter.value.length === 0;
  }

  private _includes(propertyId: string): boolean {
    return (
      this.list.find(item => item.propertyId === propertyId)
        ? true
        : false
    );
  }

  clone(): SearchFilters {
    return new SearchFilters(this.list.map(v => v));
  }

  extractFiltersFrom(props: FilterProperties): Filter[] {
    return this.list.filter(f => {
      return props.find(p => {
        return p.propertyId === f.propertyId;
      })
    });
  }

  followDisplayStateOf(fixed: FilterProperties, selected: FilterProperties): SearchFilters {
    const fixedFiltersWithInputValue = this.extractFiltersFrom(fixed);
    const selectedFilters = this.extractFiltersFrom(selected);
    return new SearchFilters(
      [
        ...fixedFiltersWithInputValue,
        ...selectedFilters,
      ]
    );
  }

  private _removeEmptyValueFilter(filter: Filter): SearchFilters {
    return new SearchFilters(
      this.list.filter(v => v.propertyId !== filter.propertyId)
    );
  }

  private _overwrite(filter: Filter): SearchFilters {
    const filters = this.clone();
    const index = filters.list.findIndex(item => {
      return item.propertyId === filter.propertyId;
    });
    filters.list.splice(index, 1, filter);
    return filters;
  }

  change(filter: Filter): SearchFilters {
    if (this._includes(filter.propertyId) && !this._isEmptyValue(filter)) {
      return this._overwrite(filter);
    } else if (this._includes(filter.propertyId) && this._isEmptyValue(filter)) {
      return this._removeEmptyValueFilter(filter);
    } else if (this._isEmptyValue(filter)) {
      return this;
    } else {
      return new SearchFilters(
        [
          ...this.list,
          filter,
        ]
      );
    }
  }
}

export class FilterProperty {
  constructor(
    public readonly itemName: string,
    public readonly itemType: ItemType,
    public readonly propertyId: string,
    public readonly propertyName: string,
    public readonly propertyType: FilterDataType | 'searchString',
    public readonly predicates: Predicate[],
    public readonly isInitDisplay: boolean,
    public readonly savedFilter?: Filter | undefined,
    public readonly isCommaSeparated: boolean = propertyType === FilterDataType.number,
  ) {
  }

  equals(prop: FilterProperty): boolean {
    return this.propertyId === prop.propertyId;
  }

  changePredicates(predicates: Predicate[]): FilterProperty {
    return new FilterProperty(
      this.itemName,
      this.itemType,
      this.propertyId,
      this.propertyName,
      this.propertyType,
      predicates,
      this.isInitDisplay,
      this.savedFilter,
      this.isCommaSeparated,
    );
  }

  changeSavedFilter(filter: Filter | undefined): FilterProperty {
    return new FilterProperty(
      this.itemName,
        this.itemType,
      this.propertyId,
      this.propertyName,
      this.propertyType,
      this.predicates,
      this.isInitDisplay,
      filter,
      this.isCommaSeparated,
    );
  }
}

export type FilterProperties = FilterProperty[];

/**
 * 条件種別のラベル
 */
export const predicateTypeLabels: Record<FilterPredicateType, string> = {
  contains: '含む',
  greaterThanOrEqualTo: '以上',
  lessThanOrEqualTo: '以下',
  anyMatch: '含む',
  equals: 'と等しい',
  empty: '未入力',
  range: '範囲',
  between: '範囲',
} as const;

export type ItemString = `item:${string}`;

/** カスタム項目の検索条件の判定 */
export const isCustomFilterProperty = (value: string): value is ItemString => {
  return /^item:.+$/.test(value);
};

/** カテゴリ特有項目の検索条件の判定 */
export const isCategoryFilterProperty =
  <T extends string>(value: string, propIds: Readonly<T[]>): value is T => {
    return propIds.find(id => id === value) ? true : false;
  };

/** カテゴリ特有項目とカスタム項目を合わせた検索条件の判定 */
export const isSearchFilterProperty =
  <T extends string>(value: string, propIds: Readonly<T[]>): value is SearchPropertyId<T> => {
    return isCategoryFilterProperty(value, propIds) || isCustomFilterProperty(value);
  };

export type SearchPropertyId<T extends string> = T | ItemString;

export const convertToFilterInput = (filters: SearchFilters, sorts: SortOptions): FilterInput => {
  let filterInput: FilterInput = {
    filters: {},
    sorts: sorts
      .filter(sort => sort != null)
      .map(sort => ({
        name: sort!.itemName,
        order: sort!.order,
        nulls: 'LAST',
      })),
  };
  filters.list.forEach(filter => {
    if (!filter.filterItem) return;
    switch (filter.type) {
      case FilterPredicateType.greaterThanOrEqualTo:
        filterInput.filters[filter.filterItem.itemName] = {
          type: 'EXISTS_AND_FILTERED',
          operator: {
            type: 'GREATER_OR_EQUAL',
            value: (filter.filterItem.itemType === ItemType.NUMBER) ? Number(filter.value) : filter.value
          }
        };
        break;
      case FilterPredicateType.lessThanOrEqualTo:
        filterInput.filters[filter.filterItem.itemName] = {
          type: 'EXISTS_AND_FILTERED',
          operator: {
            type: 'LESS_OR_EQUAL',
            value: (filter.filterItem.itemType === ItemType.NUMBER) ? Number(filter.value) : filter.value
          }
        };
        break;
      case FilterPredicateType.equals:
        filterInput.filters[filter.filterItem.itemName] = {
          type: 'EXISTS_AND_FILTERED',
          operator: {
            type: 'EQUAL',
            value:
              (filter.filterItem.itemType === ItemType.NUMBER) ? Number(filter.value) :
              (filter.filterItem.itemType === ItemType.BOOLEAN) ? Boolean(filter.value) : filter.value
          }
        };
        break;
      case FilterPredicateType.empty:
        if (filter.filterItem.itemType === ItemType.BOOLEAN) {
          filterInput.filters[filter.filterItem.itemName] = {
            type: 'EMPTY_OR_FILTERED',
            operator: {
              type: 'EQUAL',
              value: false
            }
          };
        } else {
          filterInput.filters[filter.filterItem.itemName] = {
            type: 'EMPTY'
          };
        }
        break;
      case FilterPredicateType.anyMatch:
        const emptyValue = filter.value.find(value => value === '$$$empty$$$');
        const value = filter.value.filter(value => value !== '$$$empty$$$');
        const filterType = emptyValue === undefined ? 'EXISTS_AND_FILTERED' : 'EMPTY_OR_FILTERED';
        if (filter.filterItem.itemType === 'ARRAY_STRING') {
          filterInput.filters[filter.filterItem.itemName] = {
            type: filterType,
            operator: {
              type: 'OVERLAP',
              value: value,
            }
          };
        }
        if (filter.filterItem.itemType === 'REFERENCE') {
          filterInput.filters[filter.filterItem.itemName] = {
            type: filterType,
            operator: {
              type: 'IN',
              values: value,
            }
          };
        }
        break;
      case FilterPredicateType.contains:
        filterInput.filters[filter.filterItem.itemName] = {
          type: 'EXISTS_AND_FILTERED',
          operator: {
            type: 'KEYWORDS',
            values: filter.value
              .split(/[ 　]/)
              .filter(Boolean),
            condition: (filter.condition === 'or') ? 'OR' : 'AND',
          }
        };
        break;
      case FilterPredicateType.range:
        filterInput.filters[filter.filterItem.itemName] = {
          type: 'EXISTS_AND_FILTERED',
          operator: {
            type: 'RANGE',
            from: filter.from,
            range: filter.range,
            bounds: filter.bounds
          }
        };
        break;
      case FilterPredicateType.between:
        filterInput.filters[filter.filterItem.itemName] = {
          type: 'EXISTS_AND_FILTERED',
          operator: {
            type: 'BETWEEN',
            from: (filter.from) ? filter.from : 0,
            to: (filter.to) ? filter.to : 0,
            bounds: filter.bounds
          }
        };
        break;
    }
  });
  return filterInput;
}
