<sn-top-bar
  [showMenuToggle]="mobileQuery.matches"
  (clickToggle)="snav.toggle()"
  class="top-bar no-print"
></sn-top-bar>
<mat-sidenav-container class="sn-main-container">
  <!-- サイドバー -->
  <mat-sidenav
    #snav
    class="sidebar no-print"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [opened]="!mobileQuery.matches"
  >
    <mat-nav-list>
      <ng-container *ngFor="let menu of sideMenu">
        <span class="menu-group-title" mat-subheader>{{
          menu.groupTitle
        }}</span>
        <ng-container *ngFor="let menuItem of menu.menuItems">
          <a class="menu-list-item" [routerLink]="menuItem.url">
            <mat-list-item class="list-item-wrapper" [class.selected]="isSelected(menuItem.url)">
              <!-- mat-rippleがここにあり、その影響範囲調整のため一枚被せる -->
              <div class="item-wrapper" [class.selected-color]="isSelected(menuItem.url)">
                <mat-icon>{{ menuItem.iconName }}</mat-icon>
                <div class="sn-sidebar-text">{{ menuItem.title }}</div>
              </div>
            </mat-list-item>
          </a>
        </ng-container>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <!-- コンテンツ -->
  <mat-sidenav-content class="sn-app-container">
    <div class="main-content">
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
