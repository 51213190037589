import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { buildHttpParams } from './http-params-builder';

interface CsvImportHistoryData {
  id: string;
  csvFileName: string;
  userId: string;
  userName: string;
  importStartDateTime: string;
  importEndDateTime: string;
  importDataCount: number;
  importSucceedDataCount: number;
  importFailedDataCount: number;
}

interface CsvImportHistoriesResponseData {
  totalCount: number;
  results: CsvImportHistoryData[];
}

export type CsvImportHistoriesResponse =
  CsvImportHistoriesResponseData;

interface CsvImportErrors {
  csvRowNumber: number;
  error: string;
}

interface CsvImportErrorResponseData {
  totalCount: number;
  results: CsvImportErrors[];
}

export type CsvImportErrorResponse = CsvImportErrorResponseData;

export type Category = 'client' | 'opportunity' | 'product'

export class CsvImportHistoriesApi {
  constructor(private readonly http: HttpClient) {}

  list(
    category: Category,
    perPage: number,
    page: number
  ): Observable<CsvImportHistoriesResponse> {
    const params = buildHttpParams({ perPage: perPage, page: page });
    return this.http.get<CsvImportHistoriesResponse>(
      `/api/csv-import-histories/${category}`,
      { params: params }
    );
  }

  errorList(
    CsvImportHistoryID: string,
    perPage: number,
    page: number
  ): Observable<CsvImportErrorResponse> {
    const params = buildHttpParams({ perPage: perPage, page: page });
    return this.http.get<CsvImportErrorResponse>(
      `/api/csv-import-histories/${CsvImportHistoryID}/errors`,
      { params: params }
    );
  }
}
