import { ApiFilterRequest, ApiPaginationRequest } from "#infrastructure/api/server-filter-api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type CreateContactRequest = {
  clientID: string | undefined,
  department: string,
  role: string,
  displayName: string,
  firstName: string,
  firstNameKana: string,
  lastName: string,
  lastNameKana: string,
  sex: string,
  phoneNumbers: {
    label: string,
    phoneNumber: string,
  }[],
  emailAddresses: {
    label: string,
    emailAddress: string,
  }[],
  note: string,
}

export type CreateContactResponse = {
  id: string
}

export type GetContactData = {
  id: string,
  contactNumber: number,
  department: string,
  role: string,
  displayName: string,
  firstName: string,
  firstNameKana: string,
  lastName: string,
  lastNameKana: string,
  sex: string,
  phoneNumbers: {
    label: string,
    phoneNumber: string,
  }[],
  emailAddresses: {
    label: string,
    emailAddress: string,
  }[],
  note: string,
};
export type ListByContactResponse = GetContactData[];

export type UpdateContactRequest =  {
  department: string,
  role: string,
  displayName: string,
  firstName: string,
  firstNameKana: string,
  lastName: string,
  lastNameKana: string,
  sex: string,
  phoneNumbers: {
    label: string,
    phoneNumber: string,
  }[],
  emailAddresses: {
    label: string,
    emailAddress: string,
  }[],
  note: string,
}

export type GetContactResponse = {
  id: string,
  client: {
    id: string,
    name: string,
    number: number,
    category: 'CORPORATION' | 'INDIVIDUAL',
  } | undefined,
  contactNumber: number,
  department: string,
  role: string,
  displayName: string,
  firstName: string,
  firstNameKana: string,
  lastName: string,
  lastNameKana: string,
  sex: string,
  phoneNumbers: {
    label: string,
    phoneNumber: string,
  }[],
  emailAddresses: {
    label: string,
    emailAddress: string,
  }[],
  note: string,
  createdBy: string,
  updatedBy: string,
}

export type CreateRelatedClientRequest = {
  clientID: string,
}

export type ListRelatedClientResponse = {
  contactID: string,
  clientID: string,
  isMain: boolean,
  clientNumber: number,
  clientName: string,
  clientCategory: 'CORPORATION' | 'INDIVIDUAL',
  clientTypeName: string,
}[]


export type FilterContactResponse = {
  totalCount: number,
  results: FilterContactResult[]
}

export type FilterContactResult = {
  id: string,
  client: {
    id: string,
    name: string,
    category: 'CORPORATION' | 'INDIVIDUAL',
  } | undefined,
  relatedClientCount: number,
  contactNumber: number,
  department: string,
  role: string,
  firstName: string,
  firstNameKana: string,
  lastName: string,
  lastNameKana: string,
  displayName: string,
  sex: string,
  phoneNumbers: {
    label: string,
    phoneNumber: string,
  }[],
  emailAddresses: {
    label: string,
    emailAddress: string,
  }[],
  note: string,
}


export class ContactApi {
  constructor(
    private readonly http: HttpClient,
  ) {
  }

  create(request: CreateContactRequest): Observable<CreateContactResponse> {
    return this.http.post<CreateContactResponse>(`/api/contacts`, request);
  }

  listBy(clientID: string): Observable<ListByContactResponse> {
    return this.http.get<ListByContactResponse>(`/api/clients/${clientID}/contact-list`);
  }

  delete(contactID: string): Observable<void> {
    return this.http.delete<void>(`/api/contacts/${contactID}`);
  }

  get(id: string): Observable<GetContactResponse> {
    return this.http.get<GetContactResponse>(`/api/contacts/${id}`);
  }

  update(id: string, request: UpdateContactRequest): Observable<void> {
    return this.http.put<void>(`/api/contacts/${id}`, request);
  }

  createRelatedClient(contactID: string, request: CreateRelatedClientRequest): Observable<void> {
    return this.http.post<void>(`/api/contacts/${contactID}/related-clients`, request);
  }

  listRelatedClient(contactID: string): Observable<ListRelatedClientResponse> {
    return this.http.get<ListRelatedClientResponse>(`/api/contacts/${contactID}/related-client-list`);
  }

  deleteRelatedClient(contactID: string, clientID: string): Observable<void> {
    return this.http.delete<void>(`/api/contacts/${contactID}/related-clients/${clientID}`);
  }

  updateMainRelatedClient(contactID: string, clientID: string): Observable<void> {
    return this.http.post<void>(`/api/contacts/${contactID}/related-clients/${clientID}/main`, {});
  }

  filter(request: ApiPaginationRequest & ApiFilterRequest): Observable<FilterContactResponse> {
    return this.http.post<FilterContactResponse>(
      `/api/contacts/filter`,
      request,
    );
  }
}
