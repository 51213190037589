import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

/** ユーザーグループ別ナーチャリングレポート取得リクエスト */
export type GetNurturingReportRequest = {
  duration: {
    type: 'MONTHLY'
    startYearMonth: string,
    months: number,
  } | {
    type: 'DAILY'
    startDate: string,
    days: number,
  },
  assignee: {
    type: 'USER_GROUP',
    userGroupID: string,
  } | {
    type: 'ALL_USERS',
  },
  nurturingTypeIDs: string[],
  nurturingStates: ReadonlyArray<'BEFORE' | 'AFTER'>,
}

/** ユーザー別ナーチャリングレポート取得リクエスト */
export type GetNurturingReportPerUserRequest =
  Omit<GetNurturingReportRequest, 'assignee'>
  & {
    userID: string,
    pagination: {
      perPage: number,
      page: number,
    }
  }

/** マイデータ・ナーチャリング取得リクエスト */
export type GetPersonalNurturingReportRequest =
  Omit<GetNurturingReportRequest, 'assignee'>
  & {
    pagination: {
      perPage: number,
      page: number,
    }
  }

export type NurturingReportResponse = {
  perDate: PerDateResponse[],
  perNurturingType: PerNurturingTypeResponse[],
  total: number,
}

export type NurturingReportPerUserResponse = {
  user: {
    id: string,
    name: string,
    isActive: boolean,
  }
} & NurturingReportResponse

type PerDateResponse = {
  date: string,
  values: {
    nurturingTypeID: string,
    value: number
  }[],
  total: number,
}

type PerNurturingTypeResponse = {
  nurturingTypeID: string,
  total: number,
}

export type NurturingListResponse = {
  totalCount: number,
  results: {
    nurturingID: string,
    nurturingTitle: string,
    clientID: string,
    clientName: string,
    nurturingTypeID: string,
    nurturingTypeName: string,
    nurturingStartDateTime: string,
    nurturingEndDateTime: string,
    nurturingTime: number,
    nurturingState: 'BEFORE' | 'AFTER',
  }[]
}

/** ユーザーグループ別ナーチャリングレポート取得レスポンス */
export type GetNurturingReportResponse = {
  times: NurturingReportResponse,
  timesPerUser: NurturingReportPerUserResponse[],
  counts: NurturingReportResponse,
  countsPerUser: NurturingReportPerUserResponse[],
}

/** ユーザー別ナーチャリングレポート取得レスポンス */
export type GetNurturingReportPerUserResponse = {
  times: NurturingReportPerUserResponse,
  counts: NurturingReportPerUserResponse,
  nurturings: NurturingListResponse,
}

/** マイデータ・ナーチャリング取得レスポンス */
export type GetPersonalNurturingReportResponse = {
  times: NurturingReportResponse,
  counts: NurturingReportResponse,
  nurturings: NurturingListResponse,
};

export class ReportNurturingApi {
  constructor(
    private readonly http: HttpClient,
  ) {}

  get(request: GetNurturingReportRequest): Observable<GetNurturingReportResponse> {
    return this.http.post<GetNurturingReportResponse>(`/api/report-nurturings`, request);
  }

  getPerUser(request: GetNurturingReportPerUserRequest): Observable<GetNurturingReportPerUserResponse> {
    return this.http.post<GetNurturingReportPerUserResponse>(`/api/report-nurturings/user`, request);
  }

  getPersonal(request: GetPersonalNurturingReportRequest): Observable<GetPersonalNurturingReportResponse> {
    return this.http.post<GetPersonalNurturingReportResponse>(`/api/my-nurturing`, request);
  }
}
