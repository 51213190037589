import { ConfirmService } from "#application/services/confirm.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { AddProposalInputs, ProposalCommandService, UpdateProposalInputs, UpdateProposalStatusInputs } from "#application/services/proposal-command.service";
import { SnackBarService } from "#application/services/snack-bar.service";
import { CustomItems } from "app/model/custom-field/custom-field";
import { ProductDetailType } from "app/model/proposal-type/proposal-type";
import { ProposalID } from "app/model/proposal/proposal";
import { ServerApi } from "#infrastructure/api/server-api";
import { CreateProposalRequest, UpdateProposalRequest, UpdateStatusOfProposalRequest } from "#infrastructure/api/server-proposal-api";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { tap, catchError, filter, concatMap } from "rxjs/operators";
import {messageFn, Target} from "#utils/messages";
import { ProposalStatusType } from "app/model/proposal-status/proposal-status";

@Injectable({ providedIn: 'root' })
export class ProposalCommandServiceImpl implements ProposalCommandService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
    private readonly messageDialogService: MessageDialogService,
    private readonly confirmService: ConfirmService,
  ) {}

  updateStatus(id: ProposalID, inputs: UpdateProposalStatusInputs): Observable<void> {
    return this.serverApi.proposalApi.updateStatus(
      id.value,
      convertUpdateStatus(inputs)
    )
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(Target.PROPOSAL_STATUS, 'UPDATE_SUCCESS').message);
      }),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: '提案ステータスの更新'
        });
        return throwError(err);
      })
    );
  }

  add(inputs: AddProposalInputs): Observable<void> {
    return this.serverApi.proposalApi.create({
      title: inputs.title.value,
      proposalTypeID: inputs.proposalTypeID.value,
      opportunityID: inputs.opportunityID.value,
      items: CustomItems.convertToRawKVObj(inputs.items),
      detail: convertDetail(inputs.detail),
    })
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(Target.PROPOSAL, 'CREATE_SUCCESS').message);
      }),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: '提案の登録'
        });
        return throwError(err);
      })
    );
  }

  update(id: ProposalID, inputs: UpdateProposalInputs): Observable<void> {
    return this.serverApi.proposalApi.update(id.value, {
      title: inputs.title.value,
      items: CustomItems.convertToRawKVObj(inputs.items),
      detail: convertDetail(inputs.detail),
      status: convertStatusWithReason(inputs.status),
    })
    .pipe(
      tap(() => {
        this.snackBarService.show(messageFn(Target.PROPOSAL, 'UPDATE_SUCCESS').message);
      }),
      catchError((err) => {
        this.messageDialogService.notice({
          message: `
            予期せぬエラーにより提案の更新に失敗しました。次の可能性が考えられます。ブラウザの更新をお試しください。\n
            ・別のユーザーによって提案ステータスが更新された\n
            ・アプリケーションの不具合\n
            ・ネットワークエラー
          `
        });
        return throwError(err);
      })
    );
  }

  delete(id: ProposalID): Observable<void> {
    return this.confirmService.confirm({
      title: messageFn(Target.PROPOSAL, 'DELETE_CONFIRM').title,
      message: messageFn(Target.PROPOSAL, 'DELETE_CONFIRM').message
    })
    .pipe(
      filter(r => r.isOK()),
      concatMap(() => this.serverApi.proposalApi.delete(id.value)),
      tap(() => {
        this.snackBarService.show(messageFn(Target.PROPOSAL, 'DELETE_SUCCESS').message);
      }),
      catchError((err) => {
        this.messageDialogService.notice({
          title: messageFn(Target.PROPOSAL, 'DELETE_ERROR').title,
          message: messageFn(Target.PROPOSAL, 'DELETE_ERROR').message
        });
        return throwError(err);
      })
    );
  }
}

const convertUpdateStatus =
(inputs: UpdateProposalStatusInputs): UpdateStatusOfProposalRequest => {
  return (inputs.type === ProposalStatusType.ADOPTED
    || inputs.type === ProposalStatusType.DECLINED)
    ? {
      status: {
        id: inputs.id.value,
        type: inputs.type,
        reasonIDs: inputs.reasonIDs.map(v => v.value),
        reasonNote: inputs.reasonNote.value,
      }
    }
    : {
      status: {
        id: inputs.id.value,
        type: inputs.type
      }
    }
}

const convertDetail =
(detail: AddProposalInputs['detail']): CreateProposalRequest['detail'] => {
  const type = detail.type;
  switch (type) {
    case ProductDetailType.SINGLE:
      return {
        type: 'SINGLE',
        productID: detail.productID.value
      };
    case ProductDetailType.MULTIPLE:
      return {
        type: 'MULTIPLE',
        rows: detail.rows.map(r => ({
          productID: r.productID.value,
          title: r.title.value,
          quantity: r.quantity.value,
          subTotalAmount: r.subTotalAmount.value,
        }))
      };
    default:
      const unexpected: never = type;
      throw Error(`${unexpected}は予期せぬ値です。`);
  }
}

const convertStatusWithReason =
(status: UpdateProposalInputs['status']): UpdateProposalRequest['status'] => {
  return (status.type === ProposalStatusType.ADOPTED
      || status.type === ProposalStatusType.DECLINED)
      ? {
        type: status.type,
        reasonIDs: status.reasonIDs.map(v => v.value),
        reasonNote: status.reasonNote.value
      }
      : {
        type: status.type
      }
}
