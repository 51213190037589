import {CustomFieldId, CustomFieldValue} from "app/model/custom-field/custom-field";
import {ProductTypeId} from "app/model/product-type/product-type";
import { DomainValidations } from "app/model/validations/validations";
import { FieldType } from "../custom-field/field-type";

/**
 * 商品
 */
export class Product {
  constructor(
    public readonly id: ProductId,
    public readonly productNumber: ProductNumber,
    public readonly name: ProductName,
    public readonly productTypeID: ProductTypeId,
    public readonly price: ProductPrice,
    public readonly unit: ProductQuantityUnit,
    public readonly items?: ProductItem[],
    public readonly deleted: ProductDeleted = new ProductDeleted(false),
  ) {
  }

  changeName(name: ProductName): Product {
    return new Product(
      this.id,
      this.productNumber,
      name,
      this.productTypeID,
      this.price,
      this.unit,
      this.items,
      this.deleted,
    );
  }

  changePrice(price: ProductPrice): Product {
    return new Product(
      this.id,
      this.productNumber,
      this.name,
      this.productTypeID,
      price,
      this.unit,
      this.items,
      this.deleted,
    );
  }

  changeUnit(unit: ProductQuantityUnit): Product {
    return new Product(
      this.id,
      this.productNumber,
      this.name,
      this.productTypeID,
      this.price,
      unit,
      this.items,
      this.deleted,
    );
  }

  changeItems(items: ProductItem[]): Product {
    return new Product(
      this.id,
      this.productNumber,
      this.name,
      this.productTypeID,
      this.price,
      this.unit,
      items,
      this.deleted,
    );
  }
}

/**
 * 商品を一意に特定するID
 */
export class ProductId {
  constructor(public readonly value: string) {
  }

  equals(object: ProductId) {
    return object.value === this.value;
  }
}

/**
 * 商品の通し番号
 */
export class ProductNumber {
  constructor(public readonly value: number) {
  }

  toString(): string {
    return this.value.toString();
  }
}

/**
 * 商品名
 */
export class ProductName implements DomainValidations {
  readonly fieldType = FieldType.SINGLE_LINE_TEXT;
  static readonly MAX_LENGTH = 100;
  static readonly DEFAULT_VALUE = '';

  /** @duplicated */
  readonly type = 'STRING';

  /** @duplicated */
  readonly name = '商品名';

  /** @duplicated */
  validations = [
    {
      type: 'REQUIRED',
    },
    {
      type: 'MAX_LENGTH',
      length: 100,
    }
  ] as const;



  constructor(public readonly value: string) {}

  static defaultValue(): ProductName {
    return new ProductName(ProductName.DEFAULT_VALUE);
  }
}

/**
 * 商品価格
 */
export class ProductPrice {
  readonly fieldType = FieldType.NUMBER;
  static readonly MAX_VALUE = 999999999999;
  static readonly DEFAULT_VALUE = 0;

  constructor(public readonly value: number) {}

  static defaultValue(): ProductPrice {
    return new ProductPrice(ProductPrice.DEFAULT_VALUE);
  }
}

/**
 * 商品単位
 */
export class ProductQuantityUnit {
  readonly fieldType = FieldType.SINGLE_LINE_TEXT;
  static readonly MAX_LENGTH = 10;
  static readonly DEFAULT_VALUE = '';

  constructor(public readonly value: string) {}

  static defaultValue(): ProductQuantityUnit {
    return new ProductQuantityUnit(ProductQuantityUnit.DEFAULT_VALUE);
  }
}

/**
 * 商品に関するカスタム項目
 */
export class ProductItem {
  constructor(
    public readonly fieldId: CustomFieldId,
    public readonly fieldValue: CustomFieldValue,
  ) {
  }
}

/**
 *商品の論理削除フラグ
 */
export class ProductDeleted {
  constructor(public readonly value: boolean) {
  }
}
