import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type ListOpportunityOfReportRequest = {
  pagination: {
    perPage: number,
    page: number,
  },
  duration: {
    type: 'MONTHLY'
    startYearMonth: string,
    months: number,
  } | {
    type: 'DAILY'
    startDate: string,
    days: number,
  },
  assignee: {
    type: 'USER',
    userID: string,
  } | {
    type: 'USER_GROUP',
    userGroupID: string,
  } | {
    type: 'ALL_USERS',
  },
  opportunityTypeIDs: string[],
  statuses: ('OPEN' | 'WON' | 'LOST')[],
};

export type ListOpportunityOfReportResponse = {
  totalCount: number,
  results: {
    opportunity: {
      id: string,
      name: string,
      number: number,
      status: 'OPEN' | 'WON' | 'LOST'
    },
    opportunityType: {
      id: string,
      name: string
    },
    currentPhase: {
      id: string,
      name: string
    },
    assignee: {
      id: string,
      name: string
    }
  }[],
};

export class OpportunityListReportOpportunityApi {
  constructor(private readonly http: HttpClient) {}

  list(request: ListOpportunityOfReportRequest): Observable<ListOpportunityOfReportResponse> {
    return this.http.post<ListOpportunityOfReportResponse>(`/api/opportunity-list/search/report-opportunities/won`, request);
  }

  listBySalesRecord(request: ListOpportunityOfReportRequest): Observable<ListOpportunityOfReportResponse> {
    return this.http.post<ListOpportunityOfReportResponse>(`/api/opportunity-list/search/report-opportunities/sales-record`, request);
  }
}
