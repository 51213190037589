import { ServerApi } from '#infrastructure/api/server-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ClientID, ClientName, ClientNumber } from 'app/model/client/client';
import { ClientRelationshipNotesList, ClientRelationshipQueryService, ClientRelationshipsList } from '#application/services/client-relationship-query.service';
import { ClientCategory, ClientTypeName } from 'app/model/client-info-type/client-type';
import { ClientRelationshipID } from 'app/model/client-relationship/client-relationship';
import { LoginID } from 'app/model/user/user';
import { DateTime } from 'luxon';
import { ClientRelationshipNoteContent, ClientRelationshipNoteID } from 'app/model/client-relationship/client-relationship-note/client-relationship-note';

@Injectable({
  providedIn: 'root',
})
export class ClientRelationshipQueryServiceImpl extends ClientRelationshipQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  getClientRelationships(clientID: ClientID): Observable<ClientRelationshipsList> {
    return this.serverApi.clientRelationshipApi.list(clientID.value).pipe(
      map(relationships =>
        relationships.map(relationship => ({
          id: new ClientRelationshipID(relationship.id),
          relevantClientID: new ClientID(relationship.relevantClientID),
          relevantClientName: new ClientName(relationship.relevantClientName),
          relevantClientNumber: new ClientNumber(relationship.relevantClientNumber),
          relevantClientTypeName: new ClientTypeName(relationship.relevantClientTypeName),
          category: convertClientCategory(relationship.category)
        }))
      )
    );
  }

  getClientRelationshipNotes(clientID: ClientID, relationshipID: ClientRelationshipID): Observable<ClientRelationshipNotesList> {
    return this.serverApi.clientRelationshipApi.listNotes(clientID.value, relationshipID.value).pipe(
      map(notes =>
        notes.map(note => ({
          noteID: new ClientRelationshipNoteID(note.id),
          authorName: new LoginID(note.authorName),
          content: new ClientRelationshipNoteContent(note.content),
          editedDateTime: DateTime.fromJSDate(new Date(note.editedDateTime)),
          isEdited: note.isEdited,
        }))
      )
    );
  }
}

const convertClientCategory = (category: 'CORPORATION' | `INDIVIDUAL`) => {
  switch (category) {
    case 'CORPORATION':
      return ClientCategory.Corporation;
    case `INDIVIDUAL`:
      return ClientCategory.Individual;
  }
}
