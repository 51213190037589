import { OpportunityTypeName } from "app/model/opportunity-type/opportunity-type";
import { OpportunityID, OpportunityName, OpportunityNumber, OpportunityStatus } from "app/model/opportunity/opportunity";
import { PhaseName } from "app/model/sales-phase/sales-phase";
import { UserGroupID } from "app/model/user-group/user-group";
import { DisplayName, UserID } from "app/model/user/user";
import { DateTime } from "luxon";
import { Observable } from "rxjs";

type AmountType = 'ACHIEVEMENT_AND_ESTIMATION' | 'ACHIEVEMENT' | 'ESTIMATION';

export type GoalCategory = {
  type: 'OPPORTUNITY_WON',
  amountType: AmountType,
} | {
  type: 'SALES_RECORDING',
};

type Assignee = AsUser | AsUserGroup | AsAllUsers;

type AsUser = {
  type: 'USER',
  userID: UserID,
};

type AsUserGroup = {
  type: 'USER_GROUP',
  userGroupID: UserGroupID,
};

type AsAllUsers = {
  type: 'ALL_USERS',
};

export type ListOpportunityReportGoalsInputs = {
  pagination: {
    perPage: number,
    page: number,
  },
  goalCategory: GoalCategory,
  duration: {
    startYearMonth: DateTime,
    months: number,
  },
  assignee: Assignee,
}

export type ListOpportunityReportGoalsOutputs = {
  totalCount: number,
  list: {
    id: OpportunityID,
    name: OpportunityName,
    status: Exclude<OpportunityStatus, 'DELETED'>,
    number: OpportunityNumber,
    typeName: OpportunityTypeName,
    assigneeName: DisplayName,
    phaseName: PhaseName,
  }[],
};

export abstract class OpportunityListReportGoalsQueryService {
  abstract list(inputs: ListOpportunityReportGoalsInputs): Observable<ListOpportunityReportGoalsOutputs>;
}
