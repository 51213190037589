import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from "#presentation/pages/admin/admin.component";

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'organization',
        loadChildren: () => import('./organization/organization.module')
        .then((m) => m.OrganizationModule)
      },
      {
        path: 'consultant',
        loadChildren: () =>
          import('../admin/consultant/consultant-setting.module').then(
            (m) => m.ConsultantSettingModule),
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
