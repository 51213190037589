export const CALENDAR_KEY_NAME = 'CALENDAR';
export type Calendar = Readonly<{
  daily: {
    group: DailyGroupCalendar,
  },
  weekly: {
    group: WeeklyGroupCalendar
  }
}>

export type DailyGroupCalendar = Readonly<{
  enableDisplayTimeLimit: boolean,
}>

export type WeeklyGroupCalendar = Readonly<{
  enableDisplayTimeLimit: boolean,
}>
