import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { SingleSignOnComponent } from './single-sign-on.component';

const routes: Routes = [
  {
    path: '',
    component: SingleSignOnComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SingleSignOnRoutingModule { }
