import { UnreportedActivityList, UnreportedActivityQueryService, UnreportedActivityType } from "#application/services/unreported-activity-query.service";
import { ActionID, ActionMinutesDuration, ActionStartDateTime } from "app/model/action/action";
import { ClientName } from "app/model/client/client";
import { NurturingID, NurturingMinutesDuration, NurturingStartDateTime } from "app/model/nurturing/nurturing";
import { OpportunityName } from "app/model/opportunity/opportunity";
import { ServerApi } from "#infrastructure/api/server-api";
import { ListUnreportedActivityResponse } from "#infrastructure/api/server-unreported-activity-api";
import { Injectable, inject } from "@angular/core";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Failure, Result, Success } from "app/lib/result/result";
import { DateTime } from "luxon";
import { Observable, catchError, map, of } from "rxjs";

@Injectable({providedIn: 'root'})
export class UnreportedActivityQueryServiceImpl implements UnreportedActivityQueryService {
  private readonly _serverApi = inject(ServerApi);

  list(): Observable<Result<
    UnreportedActivityList,
    typeof GeneralFailure.Unexpected
  >> {
    return this._serverApi.unreportedActivityApi.list()
      .pipe(
        map(res => Success(res.map(v => toUnreportedActivityItem(v)))),
        catchError(() => of(Failure(GeneralFailure.Unexpected))),
      );
  }
}

const toUnreportedActivityItem = (res: ListUnreportedActivityResponse[number]): UnreportedActivityList[number] => {
  const type = res.type;
  switch (type) {
    case 'ACTION':
      return {
        id: new ActionID(res.id),
        type: UnreportedActivityType.ACTION,
        name: new OpportunityName(res.name),
        duration: {
          startDateTime: new ActionStartDateTime(DateTime.fromFormat(res.duration.startDateTime, 'yyyy-MM-dd HH:mm:ss').toJSDate()),
          minutes: new ActionMinutesDuration(res.duration.minutes),
        },
      };
    case 'NURTURING':
      return {
        id: new NurturingID(res.id),
        type: UnreportedActivityType.NURTURING,
        name: new ClientName(res.name),
        duration: {
          startDateTime: new NurturingStartDateTime(DateTime.fromFormat(res.duration.startDateTime, 'yyyy-MM-dd HH:mm:ss').toJSDate()),
          minutes: new NurturingMinutesDuration(res.duration.minutes),
        },
      };
    default:
      const unexpected: never = type;
      throw new Error(`Unexpected type: ${unexpected}`);
  }
}