import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export class HintReviewApi {
  constructor(private readonly http: HttpClient) {}

  review(id: string, status: 'GOOD' | 'BAD' | 'NONE'): Observable<void> {
    return this.http.post<void>(`/api/hint-review/${id}/${status}`, {});
  }
}
