<sn-admin-container>
  <sn-feature-title-bar
    titleArea
    title="コンサルタント登録"
    description="コンサルタントを登録できます。"
  ></sn-feature-title-bar>
  <div contentsArea class="forms-wrapper">
    <mat-form-field class="form" appearance="outline">
      <mat-label>ログインID</mat-label>
      <input matInput [formControl]="loginIDControl" placeholder="Ex. consul001"/>
      <mat-hint>ログインに使用。最大50文字まで、半角英数・ピリオド（.）のみ設定できます。</mat-hint>
      <mat-error *ngIf="loginIDControl.hasError('required')">
        ログインIDは必須項目です。
      </mat-error>
      <mat-error *ngIf="loginIDControl.hasError('pattern')">
        ログインIDは50文字以下、または半角英数・ピリオド（.）のみで設定してください。
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form" appearance="outline">
      <mat-label>コンサルタント名</mat-label>
      <input matInput [formControl]="displayNameControl" placeholder="Ex. セルナビ"/>
      <mat-hint>コンサルタントの表示名。最大50文字まで設定できます。</mat-hint>
      <mat-error *ngIf="displayNameControl.hasError('required')">
        コンサルタント名は必須項目です。
      </mat-error>
      <mat-error *ngIf="displayNameControl.hasError('maxlength')">
        コンサルタント名は50文字以下で設定してください。
      </mat-error>
    </mat-form-field>

    <mat-form-field class="form" appearance="outline">
      <mat-label>メールアドレス</mat-label>
      <input matInput [formControl]="mailAddressControl" placeholder="Ex. test@exapmle.com"/>
      <mat-hint>最大255文字まで設定できます。</mat-hint>
      <mat-error *ngIf="mailAddressControl.hasError('required')">
        メールアドレスは必須項目です。
      </mat-error>
      <mat-error *ngIf="mailAddressControl.hasError('pattern')">
        メールアドレスは255文字以下で設定してください。
      </mat-error>
    </mat-form-field>

    <div class="radio-wrapper">
      <label>担当組織</label>
      <mat-radio-group class="radio-group" [(ngModel)]="selectedAssignedOrgType">
        <mat-radio-button *ngFor="let type of assignedOrgTypeOptions" [value]="type.value">
          {{ type.name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <sn-consultant-assigned-organization
      *ngIf="selectedAssignedOrgType === 'SELECTED'"
      (selectedOrgs)="onSelectedOrgs($event)"
    ></sn-consultant-assigned-organization>

    <div class="button-group">
      <button mat-raised-button class="cancel-button" (click)="onCancel()">
        キャンセル
      </button>
      <button mat-raised-button class="add-button" color="primary" (click)="onAdd()" snPreventMultipleClick>
        登録
      </button>
    </div>
  </div>
</sn-admin-container>
