import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { ApiFormLayoutCategory, BuiltinFieldItemData, SectionTitleItemData } from './server-form-layout-api';

export type GetDefaultFormLayoutResponse = GetDefaultFormLayoutResponseData;

interface GetDefaultFormLayoutResponseData {
  category: ApiFormLayoutCategory,
  items: (SectionTitleItemData | BuiltinFieldItemData)[]
}

export class DefaultFormLayoutApi {
  constructor(private readonly http: HttpClient) {
  }

  get(category: ApiFormLayoutCategory): Observable<GetDefaultFormLayoutResponse> {
    return this.http.get<GetDefaultFormLayoutResponse>(`/api/default-form-layout/${category}`);
  }
}
