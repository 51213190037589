import {
  ApiFilterRequest,
  ApiPaginationRequest,
  FilterSchemaResponse
} from "#infrastructure/api/server-filter-api";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export type CreateOpportunityRequest = CreateOpportunityData;

export interface CreateOpportunityData {
  clientID: string,
  opportunityName: string,
  opportunityTypeID: string,
  phaseID: string,
  expectedAmount?: number,
  assignee: string,
  expectedCloseDate?: string,
  opportunityAccrualDate?: string,
  items?: {
    [customFieldId: string]: string
  }
  opportunitySource?: {
    type: 'NURTURING',
    nurturingID: string,
  },
}
export type CreateOpportunityResponse = CreateOpportunityResponseData;

export interface CreateOpportunityResponseData {
  opportunityID: string,
  opportunityNumber: number,
}

// 更新用のインターフェース
export type UpdateOpportunityRequest = UpdateOpportunityData;

export interface UpdateOpportunityData {
  opportunityName: string,
  opportunityTypeID: string,
  phaseID: string,
  expectedAmount?: number,
  assignee: string,
  expectedCloseDate?: string,
  opportunityAccrualDate?: string,
  items?: {
    [customFieldId: string]: string
  }
}

export interface WinOpportunityRequestData {
  actualAmount: number,
  actualCloseDate: string,
  salesRecordingDate: string,
  wonReasonIDs: string[],
  wonNote: string,
}

export type LoseOpportunityRequest = {
  lostOrderDate: string,
  lostReasonIDs: string[],
  lostNote: string,
}

export type CreateQuickOpportunityRequest = CreateQuickOpportunityData;

export interface CreateQuickOpportunityData {
  clientID: string,
  opportunityName: string,
  opportunityTypeID: string,
  phaseID: string,
  assignee: string,
  opportunitySource?: {
    type: 'NURTURING',
    nurturingID: string,
  },
}

export type FilterOpportunityResponse = {
  totalCount: number,
  results: FilterOpportunityResult[],
}

export type FilterOpportunityResult = {
  opportunityID: string,
  opportunityName: string,
  clientID: string,
  clientName: string,
  clientCategory: string,
  clientNumber: number,
  opportunityTypeID: string,
  opportunityTypeName: string,
  opportunityNumber: number,
  salesPhaseID: string,
  phaseID: string,
  phaseNumber: number,
  phaseName: string,
  status: 'OPEN' | 'WON' | 'LOST' | 'DELETED',
  assignee: string,
  assigneeName: string,
  expectedAmount?: number,
  expectedCloseDate?: string,
  opportunityAccrualDate?: string,
  items?: {
    [customFieldId: string]: string
  }
}

export class OpportunityApi {
  constructor(private readonly http: HttpClient) {
  }

  createOpportunity(request: CreateOpportunityRequest): Observable<CreateOpportunityResponse> {
    return this.http.post<CreateOpportunityResponseData>(`/api/opportunities`, request);
  }

  updateOpportunity(opportunityID: string, request: UpdateOpportunityRequest): Observable<void> {
    return this.http.put<void>(`/api/opportunities/${opportunityID}`, request);
  }

  lose(opportunityID: string, request: LoseOpportunityRequest): Observable<void> {
    return this.http.put<void>(`/api/opportunities/${opportunityID}/lose`, request);
  }

  delete(opportunityID: string): Observable<void> {
    return this.http.put<void>(`/api/opportunities/${opportunityID}/delete`, {});
  }

  reopen(opportunityID: string): Observable<void> {
    return this.http.put<void>(`/api/opportunities/${opportunityID}/reopen`, {});
  }

  createQuick(request: CreateQuickOpportunityData): Observable<CreateOpportunityResponse> {
    return this.http.post<CreateOpportunityResponseData>(`/api/opportunities/quick`, request);
  }

  filter(request: ApiPaginationRequest & ApiFilterRequest): Observable<FilterOpportunityResponse> {
    return this.http.post<FilterOpportunityResponse>(
      `/api/opportunities/filter`,
      request,
    );
  }

  getFilterSchema(): Observable<FilterSchemaResponse> {
    return this.http.get<FilterSchemaResponse>(`/api/opportunities/filter2`);
  }
}
