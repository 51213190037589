import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

export type ApiCustomFieldCategory =
  | 'client'
  | 'opportunity'
  | 'opportunity_group'
  | 'product'
  | 'proposal'
  | 'nurturing'

export type ApiFieldType =
  | 'SINGLE_LINE_TEXT'
  | 'MULTI_LINE_TEXT'
  | 'NUMBER'
  | 'SELECT'
  | 'DATE'
  | 'DATETIME'
  | 'URL'
  | 'MAIL_ADDRESS'
  | 'ADDRESS'
  | 'PHONE_NUMBER'
  | 'USER'
  | 'ATTACHMENT'
  | 'CLIENT'
  | 'CHECKBOX'

export interface ApiCustomFieldNumberSettingData {
  min?: number,
  max?: number,
  defaultValue?: number,
  unit?: string,
}

export interface ApiCustomFieldSelectSettingData {
  multiple: boolean,
  allowInput: boolean,
  options: string[],
}

export type CreateCustomFieldRequest = CreateCustomFieldRequestData;

export interface CreateCustomFieldRequestData {
  name: string,
  description: string,
  type: ApiFieldType,
  required: boolean,
  numberSetting?: ApiCustomFieldNumberSettingData,
  selectSetting?: ApiCustomFieldSelectSettingData,
}

export type UpdateCustomFieldRequest = UpdateCustomFieldRequestData;

export interface UpdateCustomFieldRequestData {
  name: string,
  description: string,
  type: ApiFieldType,
  required: boolean,
  numberSetting?: ApiCustomFieldNumberSettingData,
  selectSetting?: ApiCustomFieldSelectSettingData,
}

export type GetCustomItemResponse = CustomItemResponseData;

export interface CustomItemResponseData {
  id: string,
  category: ApiCustomFieldCategory,
  name: string,
  description: string,
  type: ApiFieldType,
  required: boolean,
  numberSetting?: ApiCustomFieldNumberSettingData,
  selectSetting?: ApiCustomFieldSelectSettingData,
}

export type ListCustomItemResponse = CustomItemResponseData[];

export type SortCustomItemsRequest = SortCustomItemsRequestData;

export interface SortCustomItemsRequestData {
  ids: string[]
}

export class CustomFieldApi {
  constructor(private readonly http: HttpClient) {
  }

  create(category: ApiCustomFieldCategory, request: CreateCustomFieldRequest): Observable<void> {
    return this.http.post<void>(`/api/custom-fields/${category}`, request);
  }

  update(category: ApiCustomFieldCategory, id: string, request: UpdateCustomFieldRequest): Observable<void> {
    return this.http.put<void>(`/api/custom-fields/${category}/${id}`, request);
  }

  get(category: ApiCustomFieldCategory, id: string): Observable<GetCustomItemResponse> {
    return this.http.get<GetCustomItemResponse>(`/api/custom-fields/${category}/${id}`);
  }

  list(category: ApiCustomFieldCategory): Observable<ListCustomItemResponse> {
    return this.http.get<ListCustomItemResponse>(`/api/custom-fields/${category}`);
  }

  delete(category: ApiCustomFieldCategory, id: string): Observable<void> {
    return this.http.delete<void>(`/api/custom-fields/${category}/${id}`);
  }

  sort(category: ApiCustomFieldCategory, request: SortCustomItemsRequest): Observable<void> {
    return this.http.patch<void>(`/api/custom-fields/${category}/sort`, request);
  }
}
