import { GetOpportunityGroupsInput, OpportunitiesInOpportunityGroup, OpportunityGroupQueryService, OpportunityGroups } from "#application/services/opportunity-group-query.service";
import { OpportunityGroupStatusList } from "#application/services/opportunity-group-status-query.service";
import { ServerApiError, ServerApiErrorCode } from "#infrastructure/api/error";
import { ServerApi } from "#infrastructure/api/server-api";
import { Injectable } from "@angular/core";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Failure, Result, Success } from "app/lib/result/result";
import { ClientName } from "app/model/client/client";
import { CustomFieldId, CustomFieldValue } from "app/model/custom-field/custom-field";
import { OpportunityGroup, OpportunityGroupID, OpportunityGroupItem, OpportunityGroupNumber, OpportunityGroupTitle } from "app/model/opportunity-group/opportunity-group";
import { OpportunityGroupStatusDescription, OpportunityGroupStatusID, OpportunityGroupStatusName } from "app/model/opportunity-group/opportunity-group-status";
import { OpportunityGroupTypeID, OpportunityGroupTypeName } from "app/model/opportunity-group/opportunity-group-type";
import { OpportunityTypeName } from "app/model/opportunity-type/opportunity-type";
import { OpportunityID, OpportunityName, OpportunityNumber } from "app/model/opportunity/opportunity";
import { PhaseName, SalesPhaseName } from "app/model/sales-phase/sales-phase";
import { DisplayName } from "app/model/user/user";
import { Observable, catchError, map, of } from "rxjs";

const convertKeyValueObjToOpportunityGroupItemList = (items: { [key: string]: string }): OpportunityGroupItem[] => {
  return Object.keys(items)
    .map(key => OpportunityGroupItem(
      new CustomFieldId(key),
      new CustomFieldValue(items[key])
    ));
};

@Injectable({ providedIn: 'root' })
export class OpportunityGroupQueryServiceImpl extends OpportunityGroupQueryService {
  constructor(
    private readonly serverApi: ServerApi,
  ) {
    super();
  }

  getByID(id: OpportunityGroupID): Observable<Result<
    OpportunityGroup,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >>{
    return this.serverApi.opportunityGroupApi.getByID(id)
    .pipe(
      map(res => (
        Success({
          id: OpportunityGroupID(res.id),
          number: OpportunityGroupNumber(res.number),
          title: OpportunityGroupTitle(res.title),
          opportunityGroupTypeID: OpportunityGroupTypeID(res.opportunityGroupTypeID),
          opportunityGroupTypeName: OpportunityGroupTypeName(res.opportunityGroupTypeName),
          opportunityGroupStatusID: OpportunityGroupStatusID(res.opportunityGroupStatusID),
          opportunityGroupStatusName: OpportunityGroupStatusName(res.opportunityGroupStatusName),
          items: res.items ? convertKeyValueObjToOpportunityGroupItemList(res.items) : undefined,
        })
      )),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.BadRequest:
            return of(Failure(GeneralFailure.BadRequest));
          case ServerApiErrorCode.NotFound:
            return of(Failure(GeneralFailure.NotFound));
          default:
            return of(Failure(GeneralFailure.Unexpected));
        }
      })
    );
  };

  getByNumber(number: OpportunityGroupNumber): Observable<Result<
    OpportunityGroup,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >>{
    return this.serverApi.opportunityGroupApi.getByNumber(number)
    .pipe(
      map(res => (
        Success({
          id: OpportunityGroupID(res.id),
          number: OpportunityGroupNumber(res.number),
          title: OpportunityGroupTitle(res.title),
          opportunityGroupTypeID: OpportunityGroupTypeID(res.opportunityGroupTypeID),
          opportunityGroupTypeName: OpportunityGroupTypeName(res.opportunityGroupTypeName),
          opportunityGroupStatusID: OpportunityGroupStatusID(res.opportunityGroupStatusID),
          opportunityGroupStatusName: OpportunityGroupStatusName(res.opportunityGroupStatusName),
          items: res.items ? convertKeyValueObjToOpportunityGroupItemList(res.items) : undefined,
        })
      )),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.BadRequest:
            return of(Failure(GeneralFailure.BadRequest));
          case ServerApiErrorCode.NotFound:
            return of(Failure(GeneralFailure.NotFound));
          default:
            return of(Failure(GeneralFailure.Unexpected));
        }
      })
    );
  };

  filter(input: GetOpportunityGroupsInput): Observable<Result<
    OpportunityGroups,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >>{
    return this.serverApi.opportunityGroupApi.filter({
      pagination: {
        perPage: input.perPage,
        page: input.page,
      },
      filters: input.filterInput.filters,
      sorts: input.filterInput.sorts,
    })
    .pipe(
      map(res => (
        Success({
          totalCount: res.totalCount,
          results: res.results.map(v => ({
            id: OpportunityGroupID(v.id),
            number: OpportunityGroupNumber(v.number),
            title: OpportunityGroupTitle(v.title),
            opportunityGroupTypeID: OpportunityGroupTypeID(v.opportunityGroupTypeID),
            opportunityGroupTypeName: OpportunityGroupTypeName(v.opportunityGroupTypeName),
            opportunityGroupStatusID: OpportunityGroupStatusID(v.opportunityGroupStatusID),
            opportunityGroupStatusName: OpportunityGroupStatusName(v.opportunityGroupStatusName),
            items: v.items ? convertKeyValueObjToOpportunityGroupItemList(v.items) : undefined,
          }))
        })
      )),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.BadRequest:
            return of(Failure(GeneralFailure.BadRequest));
          default:
            return of(Failure(GeneralFailure.Unexpected));
        }
      })
    );
  };

  listOpportunities(id: OpportunityGroupID): Observable<Result<
    OpportunitiesInOpportunityGroup,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >>{
    return this.serverApi.opportunityGroupApi.getOpportunities(id)
    .pipe(
      map(res => (
        Success(res.opportunities.map(v => ({
          id: new OpportunityID(v.id),
          opportunityNumber: new OpportunityNumber(v.opportunityNumber),
          opportunityName: new OpportunityName(v.opportunityName),
          opportunityStatus: v.opportunityStatus,
          clientName: new ClientName(v.clientName),
          salesPhaseName: new SalesPhaseName(v.salesPhaseName),
          phaseName: new PhaseName(v.phaseName),
          opportunityTypeName: new OpportunityTypeName(v.opportunityTypeName),
          assigneeName: new DisplayName(v.assigneeName),
        })))
      )),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.BadRequest:
            return of(Failure(GeneralFailure.BadRequest));
          default:
            return of(Failure(GeneralFailure.Unexpected));
        }
      })
    );
  };

  getStatuses(id: OpportunityGroupID): Observable<Result<
    OpportunityGroupStatusList,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >>{
    return this.serverApi.opportunityGroupApi.getStatuses(id)
    .pipe(
      map(res => (Success(
        res.opportunityGroupStatuses.map(v => ({
          id: OpportunityGroupStatusID(v.id),
          name: OpportunityGroupStatusName(v.name),
          description: OpportunityGroupStatusDescription(v.description!)
        }))
      ))),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.BadRequest:
            return of(Failure(GeneralFailure.BadRequest));
          case ServerApiErrorCode.NotFound:
            return of(Failure(GeneralFailure.NotFound));
          default:
            return of(Failure(GeneralFailure.Unexpected));
        }
      })
    );
  };
}
