import {ServerApi} from '#infrastructure/api/server-api';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import { OpportunityContextLabelName } from 'app/model/opportunity-context/opportunity-context';
import { CommonOpportunityContextLabelQueryService } from '#application/services/common-opportunity-context-lebel-query.service';
import { OpportunityContextLabelList, SearchOpportunityContextLabelListParams } from '#application/services/base-opportunity-context-label-query.service';

@Injectable({
  providedIn: 'root',
})
export class CommonOpportunityContextLabelQueryServiceImpl extends CommonOpportunityContextLabelQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  findBy(params: SearchOpportunityContextLabelListParams): Observable<OpportunityContextLabelList> {
    return this.serverApi.commonOpportunityContextLabelApi.search(params).pipe(
      map(data => ({
        totalCount: data.totalCount,
        results: data.results.map(v => new OpportunityContextLabelName(v)),
      }))
    );
  }
}
