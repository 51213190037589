import { FilterInput } from "#infrastructure/application/filter.service";
import { Brand } from "app/lib/brand/brand";
import { DateTime } from "luxon";
import { SearchFilterPermission } from "../search-filter/search-filter";
import { UserGroupID, UserGroupName } from "../user-group/user-group";
import { DisplayName, UserID } from "../user/user";
import { MeetingBoardSettingFilterCondition } from "./meeting-board-setting-filter-conditon";

export type MeetingBoardSettingID = Brand<string, 'MeetingBoardSettingID'>;
export function MeetingBoardSettingID(value: string) {
  return value as MeetingBoardSettingID;
}

export type MeetingBoardSettingName = Brand<string, 'MeetingBoardSettingName'>;
export function MeetingBoardSettingName(value: string) {
  return value as MeetingBoardSettingName;
}
export namespace MeetingBoardSettingName {
  export const MAX_LENGTH = 30;
}

export type MeetingBoardSettingDescription = Brand<string, 'MeetingBoardSettingDescription'>;
export function MeetingBoardSettingDescription(value: string) {
  return value as MeetingBoardSettingDescription;
}
export namespace MeetingBoardSettingDescription {
  export const MAX_LENGTH = 100;
}

export type MeetingBoardSettingBookmark = Brand<boolean, 'MeetingBoardSettingBookmark'>;
export function MeetingBoardSettingBookmark(value: boolean) {
  return value as MeetingBoardSettingBookmark;
}

export type MeetingBoardSettingBookmarkCount = Brand<number, 'MeetingBoardSettingBookmarkCount'>;
export function MeetingBoardSettingBookmarkCount(value: number) {
  return value as MeetingBoardSettingBookmarkCount;
}

export type MeetingBoardSetting = {
  meetingBoardSettingID: MeetingBoardSettingID,
  name: MeetingBoardSettingName,
  description: MeetingBoardSettingDescription,
  visibility: SearchFilterPermission,
  editability: SearchFilterPermission,
  owner: {
    userID: UserID,
    userDisplayName: DisplayName,
  },
  createdAt: DateTime,
  updatedAt: DateTime,
  bookmark: MeetingBoardSettingBookmark,
  bookmarkCount: MeetingBoardSettingBookmarkCount,
};

export type MeetingBoardHeader = {
  displayUser: MeetingBoardUser,
  wonAmount: number | undefined,
  wonCount: number | undefined,
  lostAmount: number | undefined,
  lostCount: number | undefined,
  goals: MeetingBoardGoal[],
};

export type MeetingBoardUser = {
  userID: UserID,
  displayName: DisplayName,
};
export namespace MeetingBoardUser {
  export const MAX_LENGTH = 30;
}

export type MeetingBoardDisplayUser =
  MeetingBoardUser & {
    display: boolean,
    selected: boolean,
  };

export type MeetingBoardDisplayUserGroup = {
  userGroupID: UserGroupID,
  userGroupName: UserGroupName,
  display: boolean,
  users: MeetingBoardUser[],
};

type MeetingBoardGoal = {
  goalCategory: "OPPORTUNITY_WON_COUNT" | "ACTUAL_AMOUNT" | "SALES_RECORDING_AMOUNT",
  goal: number,
  achievement: number,
  achievementRate: number,
};

/** ミーティングボードを組み立てるために必要な情報 */
export type MeetingBoardPropsForSettingUp = Readonly<{
  id: MeetingBoardSettingID,
  name: MeetingBoardSettingName,
  editability: SearchFilterPermission,
  ownerID: UserID,
  filterInput: FilterInput,
  displayItems: MeetingBoardSettingFilterCondition['displayItems'],
}>
