import { Observable } from "rxjs";
import { Consultant, ConsultantID } from "./consultant";

export abstract class ConsultantRepository {
  abstract save(consultant: Consultant): Observable<void>;

  abstract delete(id: ConsultantID): Observable<void>;

  abstract resetPassword(id: ConsultantID): Observable<void>;
}
