import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { setupWorker } from "msw";
import { mockHandlers } from 'mocks/mock-handlers';

if (environment.production) {
  enableProdMode();
}

/** 非同期に行われるService Workerの起動を同期的に解決する */
async function prepare() {
  if (environment.msw) {
    const worker = setupWorker(...mockHandlers);
    return worker.start({
      onUnhandledRequest: 'bypass',
    });
  }
}

prepare()
  .then(() => platformBrowserDynamic().bootstrapModule(AppModule))
  .catch(err => console.error(err));
