import {Observable} from 'rxjs';

import {HttpClient} from '@angular/common/http';

export type CreateContactHistoryRequest = CreateContactHistoryRequestData;

export interface CreateContactHistoryRequestData {
  content: string;
  opportunityID?: string;
}

export type GetContactHistoriesResponse = GetContactHistoriesData[];
export type GetContactHistoryResponse = GetContactHistoriesData;

export interface GetContactHistoriesData {
  id: string;
  authorName: string;
  content: string;
  opportunityID?: string;
  opportunityNumber?: number;
  opportunityName?: string;
  createdDateTime: string;
  isEdited: boolean;
  editedDateTime: string;
}

export type UpdateContactHistoryRequest = UpdateContactHistoryRequestData;

export interface UpdateContactHistoryRequestData {
  content: string;
  opportunityID?: string;
}

export class ContactHistoryApi {
  constructor(private readonly http: HttpClient) {
  }

  create(clientID: string, request: CreateContactHistoryRequest): Observable<void> {
    return this.http.post<void>(`/api/clients/${clientID}/contact-histories`, request);
  }

  list(clientID: string): Observable<GetContactHistoriesResponse> {
    return this.http.get<GetContactHistoriesResponse>(`/api/clients/${clientID}/contact-histories`);
  }

  get(clientID: string, historyID: string): Observable<GetContactHistoryResponse> {
    return this.http.get<GetContactHistoryResponse>(`/api/clients/${clientID}/contact-histories/${historyID}`);
  }

  update(clientID: string, historyID: string, request: UpdateContactHistoryRequest): Observable<void> {
    return this.http.put<void>(`/api/clients/${clientID}/contact-histories/${historyID}`, request);
  }

  delete(clientID: string, historyID: string): Observable<void> {
    return this.http.delete<void>(`/api/clients/${clientID}/contact-histories/${historyID}`);
  }
}
