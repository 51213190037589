import { ClientID, ClientName, ClientNumber } from 'app/model/client/client';
import { OpportunityID, OpportunityName, OpportunityNumber } from 'app/model/opportunity/opportunity';
import { Page } from './page-navigate.service';

export type FlowStackPage = keyof Pick<typeof Page, 'CoachingAddAction' | 'CoachingAddOpportunity'>;

export type CoachingResultOpportunityAddData = {
  opportunityID: OpportunityID,
  opportunityName: OpportunityName,
  opportunityNumber: OpportunityNumber,
};

export type CoachingResultClientAddData = {
  clientID: ClientID,
  clientName: ClientName,
  clientNumber: ClientNumber,
};

export type CoachingTemporaryActionAddData = {
  actionStartDateTime: string,
  actionEndDateTime: string,
};

export abstract class CoachingStorageService {
  // FlowStack
  abstract pushFlowStack(page: FlowStackPage): void;
  abstract popFlowStack(): Page | undefined;
  abstract clearFlowStack(): void;

  // ResultData
  abstract storeResultClientAddData(data: CoachingResultClientAddData): void;
  abstract takeResultClientAddData(): CoachingResultClientAddData | undefined;
  abstract storeResultOpportunityAddData(data: CoachingResultOpportunityAddData): void;
  abstract takeResultOpportunityAddData(): CoachingResultOpportunityAddData | undefined;

  // TemporaryData
  abstract storeTemporaryActionAddData(data: CoachingTemporaryActionAddData): void;
  abstract lookupTemporaryActionAddData(): CoachingTemporaryActionAddData | undefined;
  abstract deleteTemporaryActionAddData(): void;
}
