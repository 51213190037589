import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { ActionTypeQueryService } from "#application/services/action-type-query.service";
import { AuthorizeService } from '#application/services/authorize.service';
import { ClientCsvImportHistoriesQueryService } from '#application/services/client-csv-import-histories-query.service';
import { ClientCsvImportService } from '#application/services/client-csv-import.service';
import { ClientFieldQueryService } from "#application/services/client-field-query.service";
import {
  CorporationClientBuiltinFieldQueryService,
  CorporationClientFormLayoutQueryService,
  IndividualClientBuiltinFieldQueryService,
  IndividualClientFormLayoutQueryService
} from "#application/services/client-form-layout-query.service";
import { ClientQueryService } from "#application/services/client-query.service";
import { ClientRelationshipQueryService } from '#application/services/client-relationship-query.service';
import { ConfirmService } from '#application/services/confirm.service';
import { ContactHistoryQueryService } from '#application/services/contact-history-query.service';
import { ContactHistoryTemplateQueryService } from '#application/services/contact-history-template-query.service';
import { ContactQueryService } from "#application/services/contact-query.service";
import { FollowUpActionQueryService } from '#application/services/follow-up-action-query.service';
import { OpportunityQueryService } from "#application/services/opportunity-query.service";
import { OpportunityTypeQueryService } from '#application/services/opportunity-type-query.service';
import { PageNavigateService } from '#application/services/page-navigate.service';
import { ProductFieldQueryService } from "#application/services/product-field-query.service";
import {
  ProductBuiltinFieldQueryService,
  ProductFormLayoutQueryService
} from '#application/services/product-form-layout-query.service';
import { ProductQueryService } from '#application/services/product-query.service';
import { ProductTypeQueryService } from '#application/services/product-type-query.service';
import { SnackBarService } from '#application/services/snack-bar.service';
import { UserQueryService } from '#application/services/user-query.service';
import { ActionTypeQueryServiceImpl } from "#infrastructure/application/action-type-query.service";
import { ClientFieldQueryServiceImpl } from "#infrastructure/application/client-field-query.service";
import {
  CorporationClientBuiltinFieldQueryServiceImpl,
  CorporationClientFormLayoutQueryServiceImpl,
  IndividualClientBuiltinFieldQueryServiceImpl,
  IndividualClientFormLayoutQueryServiceImpl
} from "#infrastructure/application/client-form-layout-query.service";
import { ClientQueryServiceImpl } from "#infrastructure/application/client-query.service";
import { ContactQueryServiceImpl } from "#infrastructure/application/contact-query.service";
import { OpportunityQueryServiceImpl } from "#infrastructure/application/opportunity-query.service";
import { ActionTypeFactoryImpl } from "#infrastructure/model/action-type.factory";
import { ActionTypeRepositoryImpl } from '#infrastructure/model/action-type.repository';
import { ActionRepositoryImpl } from '#infrastructure/model/action.repository';
import { ClientTypeFactoryImpl } from '#infrastructure/model/client-type.factory';
import { ClientTypeRepositoryImpl } from '#infrastructure/model/client-type.repository';
import { ClientFactoryImpl } from "#infrastructure/model/client.factory";
import { ContactFactoryImpl } from "#infrastructure/model/contact.factory";
import { ContactRepositoryImpl } from "#infrastructure/model/contact.repository";
import { CustomFieldRepositoryImpl } from "#infrastructure/model/custom-field.repository";
import { FollowUpActionTypeRepositoryImpl } from '#infrastructure/model/follow-up-action-type.repository';
import { LostReasonRepositoryImpl } from '#infrastructure/model/lost-reason.repository';
import { UserFactoryImpl } from '#infrastructure/model/user.factory';
import { UserRepositoryImpl } from '#infrastructure/model/user.repository';
import { MatButtonModule } from '@angular/material/button';
import { ActionTypeFactory } from "app/model/action/action-type.factory";
import { ActionTypeRepository } from 'app/model/action/action-type.repository';
import { ActionFactory } from 'app/model/action/action.factory';
import { ActionRepository } from 'app/model/action/action.repository';
import { ClientTypeFactory } from 'app/model/client-info-type/client-type.factory';
import { ClientTypeRepository } from 'app/model/client-info-type/client-type.repository';
import {
  ClientRelationshipNoteFactory
} from 'app/model/client-relationship/client-relationship-note/client-relationship-note.factory';
import {
  ClientRelationshipNoteRepository
} from 'app/model/client-relationship/client-relationship-note/client-relationship-note.repository';
import { ClientRelationshipFactory } from 'app/model/client-relationship/client-relationship.factory';
import { ClientRelationshipRepository } from 'app/model/client-relationship/client-relationship.repository';
import { ClientFactory } from "app/model/client/client.factory";
import { ContactHistoryTemplateFactory } from 'app/model/contact-history-template/contact-history-template.factory';
import { ContactHistoryTemplateRepository } from 'app/model/contact-history-template/contact-history-template.repository';
import { ContactHistoryFactory } from 'app/model/contact-history/contact-history.factory';
import { ContactHistoryRepository } from 'app/model/contact-history/contact-history.repository';
import { ContactFactory } from "app/model/contact/contact.factory";
import { ContactRepository } from "app/model/contact/contact.repository";
import { CustomFieldFactory } from 'app/model/custom-field/custom-field.factory';
import { CustomFieldRepository } from "app/model/custom-field/custom-field.repository";
import { FollowUpActionTypeRepository } from 'app/model/follow-up-action/follow-up-action-type.repository';
import { FollowUpActionFactory } from 'app/model/follow-up-action/follow-up-action.factory';
import { FollowUpActionRepository } from 'app/model/follow-up-action/follow-up-action.repository';
import { FormLayoutFactory } from 'app/model/form-layout/form-layout.factory';
import { FormLayoutRepository } from 'app/model/form-layout/form-layout.repository';
import {
  OpportunityHearingSettingRepository
} from 'app/model/opportunity-hearing-setting/opportunity-hearing-setting.repository';
import { OpportunityTypeFactory } from 'app/model/opportunity-type/opportunity-type.factory';
import { OpportunityTypeRepository } from 'app/model/opportunity-type/opportunity-type.repository';
import { LostReasonRepository } from 'app/model/opportunity/lost-reason/lost-reason.repository';
import { OpportunityFactory } from 'app/model/opportunity/opportunity.factory';
import { OpportunityRepository } from 'app/model/opportunity/opportunity.repository';
import { OrganizationFactory } from 'app/model/organization/organization.factory';
import { OrganizationRepository } from 'app/model/organization/organization.repository';
import { ProductTypeFactory } from 'app/model/product-type/product-type.factory';
import { ProductTypeRepository } from 'app/model/product-type/product-type.repository';
import { UserFactory } from 'app/model/user/user.factory';
import { UserRepository } from 'app/model/user/user.repository';
import { ClientApi } from './api/client-api';
import { HttpResponseInterceptor } from './api/response.interceptor';
import { ServerApi } from './api/server-api';
import { AuthorizeServiceImpl } from './application/authorize.service';
import { ClientCsvImportHistoriesQueryServiceImpl } from './application/client-csv-import-histories-query.service';
import { ClientCsvImportServiceImpl } from './application/client-csv-import.service';
import { ClientRelationshipQueryServiceImpl } from './application/client-relationship-query.service';
import { ConfirmDialogComponent, ConfirmServiceImpl } from './application/confirm.service';
import { ContactHistoryQueryServiceImpl } from './application/contact-history-query.service';
import { ContactHistoryTemplateQueryServiceImpl } from './application/contact-history-template-query.service';
import { FollowUpActionQueryServiceImpl } from './application/follow-up-action-query.service';
import { OpportunityTypeQueryServiceImpl } from './application/opportunity-type-query.service';
import { PageNavigateServiceImpl } from './application/page-navigate.service';
import { ProductFieldQueryServiceImpl } from './application/product-field-query.service';
import {
  ProductBuiltinFieldQueryServiceImpl,
  ProductFormLayoutQueryServiceImpl
} from './application/product-form-layout-query.service';
import { ProductQueryServiceImpl } from './application/product-query.service';
import { ProductTypeQueryServiceImpl } from './application/product-type-query.service';
import { SnackBarServiceImpl } from './application/snack-bar.service';
import { UserQueryServiceImpl } from './application/user-query.service';
import { ActionFactoryImpl } from './model/action.factory';
import { ClientRelationshipNoteFactoryImpl } from './model/client-relationship-note.factory';
import { ClientRelationshipNoteRepositoryImpl } from './model/client-relationship-note.repository';
import { ClientRelationshipFactoryImpl } from './model/client-relationship.factory';
import { ClientRelationshipRepositoryImpl } from './model/client-relationship.repository';
import { ContactHistoryTemplateFactoryImpl } from './model/contact-history-template.factory';
import { ContactHistoryTemplateRepositoryImpl } from './model/contact-history-template.repository';
import { ContactHistoryFactoryImpl } from './model/contact-history.factory';
import { ContactHistoryRepositoryImpl } from './model/contact-history.repository';
import { CustomFieldFactoryImpl } from './model/custom-field.factory';
import { FollowUpActionFactoryImpl } from './model/follow-up-action.factory';
import { FollowUpActionRepositoryImpl } from './model/follow-up-action.repository';
import { FormLayoutFactoryImpl } from './model/form-layout.factory';
import { FormLayoutRepositoryImpl } from './model/form-layout.repository';
import { OpportunityHearingSettingRepositoryImpl } from './model/opportunity-hearing-setting.repository';
import { OpportunityTypeFactoryImpl } from './model/opportunity-type.factory';
import { OpportunityTypeRepositoryImpl } from './model/opportunity-type.repository';
import { OpportunityFactoryImpl } from './model/opportunity.factory';
import { OpportunityRepositoryImpl } from './model/opportunity.repository';
import { OrganizationFactoryImpl } from './model/organization.factory';
import { OrganizationRepositoryImpl } from './model/organization.repository';
import { ProductTypeFactoryImpl } from './model/product-type.factory';
import { ProductTypeRepositoryImpl } from './model/product-type.repository';

import { ActionManagementStorageService } from '#application/services/action-management-storage.service';
import { ActionQueryService } from '#application/services/action-query.service';
import { AdoptedReasonCommandService } from "#application/services/adopted-reason-command.service";
import { AdoptedReasonQueryService } from "#application/services/adopted-reason-query.service";
import { AssignedOrganizationQueryService } from "#application/services/assigned-organization-query.service";
import { CalendarStoreService } from "#application/services/calendar-store.service";
import { ClientCommandV2Service } from '#application/services/client-command-v2.service';
import { ClientForSelectQueryService } from "#application/services/client-for-select-query.service";
import { ClientNurturingQueryService } from '#application/services/client-nurturing-query.service';
import { ClientRelatedProductsQueryService } from "#application/services/client-related-products-query.service";
import { ClientTypeQueryService } from '#application/services/client-type-query.service';
import { CoachingStorageService } from '#application/services/coaching-storage.service';
import { CoachingService } from '#application/services/coaching.service';
import { CommonHintCommandService } from '#application/services/common-hint-command.service';
import { CommonHintQueryService } from '#application/services/common-hint-query.service';
import { CommonInsightCommandService } from '#application/services/common-insight-command.service';
import { CommonInsightQueryService } from '#application/services/common-insight-query.service';
import {
  CommonOpportunityContextLabelQueryService
} from '#application/services/common-opportunity-context-lebel-query.service';
import { CommonOpportunityContextQueryService } from '#application/services/common-opportunity-context-query.service';
import { ConsultantQueryService } from '#application/services/consultant-query.service';
import { ContactRelatedClientCommandService } from '#application/services/contact-related-client-command.service';
import { CsvExportHistoriesQueryService } from '#application/services/csv-export-histories-query.service';
import { CsvExportService } from '#application/services/csv-export.service';
import { DeclineReasonCommandService } from "#application/services/decline-reason-command.service";
import { DeclineReasonQueryService } from "#application/services/decline-reason-query.service";
import { EventCommandV2Service } from '#application/services/event-command-v2.service';
import { EventQueryV2Service } from '#application/services/event-query-v2.service';
import { FileCommandService } from '#application/services/file-command.service';
import { FileQueryService } from '#application/services/file-query.service';
import { FollowUpActionAuthorityService } from '#application/services/follow-up-action-authority.service';
import { FollowUpActionTypeQueryService } from "#application/services/follow-up-action-type-query.service";
import { FormItemsQueryService } from '#application/services/form-items-query.service';
import { FormItemsV2QueryService } from "#application/services/form-items-v2-query.service";
import { GoalConfirmationQueryService } from '#application/services/goal-confirmation-query.service';
import { GoalSettingCommandService } from '#application/services/goal-setting-command.service';
import { GoalSettingQueryService } from '#application/services/goal-setting-query.service';
import { HintCommandService } from '#application/services/hint-command.service';
import { HintQueryService } from '#application/services/hint-query.service';
import { HintReviewCommandService } from '#application/services/hint-review-command.service';
import { IncorporatedInsightCommandService } from '#application/services/incorporated-insight-command.service';
import { IncorporatedInsightQueryService } from '#application/services/incorporated-insight-query.service';
import { InsightCommandService } from '#application/services/insight-command.service';
import { InsightFeedbackCommandService } from "#application/services/insight-feedback-command.service";
import { InsightFeedbackOptionQueryService } from "#application/services/insight-feedback-option-query.service";
import { InsightQueryService } from '#application/services/insight-query.service';
import { LocalizationSettingCommandService } from "#application/services/localization-setting-command.service";
import { LocalizationSettingQueryService } from "#application/services/localization-setting-query.service";
import { LocalizedNameService } from "#application/services/localized-name.service";
import { LostReasonQueryService } from "#application/services/lost-reason-query.service";
import { MeetingBoardQueryService } from '#application/services/meeting-board-query.service';
import { MeetingBoardSettingCommandService } from '#application/services/meeting-board-setting-command.service';
import { MeetingBoardSettingQueryService } from '#application/services/meeting-board-setting-query.service';
import { MeetingBoardStoreService } from '#application/services/meeting-board-store.service';
import { MessageDialogService } from '#application/services/message-dialog.service';
import { MetaUserAuthorizeService } from '#application/services/meta-user-authorize.service';
import { NurturingCommandService } from '#application/services/nurturing-command.service';
import { NurturingFieldCommandService } from '#application/services/nurturing-field-command.service';
import { NurturingFieldQueryService } from '#application/services/nurturing-field-query.service';
import { NurturingHearingSettingCommandService } from '#application/services/nurturing-hearing-setting-command.service';
import { NurturingHearingSettingQueryService } from '#application/services/nurturing-hearing-setting-query.service';
import { NurturingQueryService } from '#application/services/nurturing-query.service';
import { NurturingTypeCommandService } from '#application/services/nurturing-type-command.service';
import { NurturingTypeQueryService } from '#application/services/nurturing-type-query.service';
import { OpportunityCoachingHistoryQueryService } from '#application/services/opportunity-coaching-history-query.service';
import { OpportunityCommandV2Service } from '#application/services/opportunity-command-v2.service';
import { OpportunityContactHistoryQueryService } from "#application/services/opportunity-contact-history-query.service";
import { OpportunityContextLabelQueryService } from '#application/services/opportunity-context-label-query.service';
import { OpportunityContextQueryService } from '#application/services/opportunity-context-query.service';
import {
  OpportunityCsvImportHistoriesQueryService
} from "#application/services/opportunity-csv-import-histories-query.service";
import { OpportunityCsvImportService } from "#application/services/opportunity-csv-import.service";
import { OpportunityFieldQueryService } from "#application/services/opportunity-field-query.service";
import { OpportunityFollowUpActionQueryService } from '#application/services/opportunity-follow-up-action-query.service';
import {
  OpportunityBuiltinFieldQueryService,
  OpportunityFormLayoutQueryService
} from "#application/services/opportunity-form-layout-query.service";
import { OpportunityGroupCommandService } from '#application/services/opportunity-group-command.service';
import { OpportunityGroupFieldCommandService } from '#application/services/opportunity-group-field-command.service';
import { OpportunityGroupFieldQueryService } from '#application/services/opportunity-group-field-query.service';
import { OpportunityGroupFormLayoutCommandService } from '#application/services/opportunity-group-form-layout-command.service';
import { OpportunityGroupBuiltinFieldQueryService, OpportunityGroupFormLayoutQueryService } from '#application/services/opportunity-group-form-layout-query.service';
import { OpportunityGroupQueryService } from '#application/services/opportunity-group-query.service';
import { OpportunityGroupStatusCommandService } from '#application/services/opportunity-group-status-command.service';
import { OpportunityGroupStatusQueryService } from '#application/services/opportunity-group-status-query.service';
import { OpportunityGroupTypeCommandService } from '#application/services/opportunity-group-type-command.service';
import { OpportunityGroupTypeQueryService } from '#application/services/opportunity-group-type-query.service';
import { OpportunityGroupWorkflowCommandService } from '#application/services/opportunity-group-workflow-command.service';
import { OpportunityGroupWorkflowQueryService } from '#application/services/opportunity-group-workflow-query.service';
import { OpportunityHearingSettingQueryService } from '#application/services/opportunity-hearing-setting-query.service';
import { OpportunityPerPhaseQueryService } from '#application/services/opportunity-per-phase-query.service';
import { OpportunityQueryV2Service } from '#application/services/opportunity-query-v2.service';
import { OpportunityListReportGoalsQueryService } from '#application/services/opportunity-report-goals-query.service';
import { OpportunityListReportOpportunityQueryService } from '#application/services/opportunity-report-opportunity-query.service';
import {
  OpportunityTypeOpportunityContextSettingQueryService
} from '#application/services/opportunity-type-opportunity-context-setting-query.service';
import { OpportunityWithSettingsForActionQueryService } from '#application/services/opportunity-with-settings-for-action-query.service';
import { ProductCommandV2Service } from '#application/services/product-command-v2.service';
import { ProductCopyService } from '#application/services/product-copy.service';
import { ProductCsvExportService } from '#application/services/product-csv-export.service';
import { ProductCsvImportHistoriesQueryService } from '#application/services/product-csv-import-histories-query.service';
import { ProductCsvImportService } from '#application/services/product-csv-import.service';
import { ProductRelatedClientsQueryService } from "#application/services/product-related-clients-query.service";
import { ProposalCommandService } from '#application/services/proposal-command.service';
import { ProposalFieldQueryService } from '#application/services/proposal-field-query.service';
import { ProposalBuiltinFieldQueryService, ProposalFormLayoutQueryService } from '#application/services/proposal-form-layout-query.service';
import { ProposalQueryService } from '#application/services/proposal-query.service';
import { ProposalStatusCommandService } from "#application/services/proposal-status-command.service";
import { ProposalStatusQueryService } from "#application/services/proposal-status-query.service";
import { ProposalTypeCommandService } from '#application/services/proposal-type-command.service';
import { ProposalTypeQueryService } from '#application/services/proposal-type-query.service';
import { ProposalWorkflowQueryService } from "#application/services/proposal-workflow-query.service";
import { ReportActivityV2QueryService } from "#application/services/report-activity-v2-query.service";
import { ReportCvrQueryService } from '#application/services/report-cvr-query.service';
import { ReportGoalQueryV2Service } from '#application/services/report-goal-query-v2.service';
import { ReportNurturingQueryService } from '#application/services/report-nurturing-query.service';
import { ReportOpportunityV2QueryService } from '#application/services/report-opportunity-v2-query.service';
import { RestAuthTokenCommandService } from '#application/services/rest-auth-token-command.service';
import { RestAuthTokenQueryService } from '#application/services/rest-auth-token-query.service';
import { RoleCommandService } from '#application/services/role-command.service';
import { RoleQueryService } from '#application/services/role-query.service';
import { SalesPhaseQueryService } from "#application/services/sales-phase-query.service";
import { SalesPhaseSettingCommandService } from '#application/services/sales-phase-setting-command.service';
import { SalesPhaseSettingQueryService } from '#application/services/sales-phase-setting-query.service';
import { SchedulePcFilterStorageService } from '#application/services/schedule-pc-filter-storage.service';
import { ScheduleV2QueryService } from '#application/services/schedule-v2-query.service';
import { SearchFilterPropertyQueryService } from '#application/services/search-filter-property-query.service';
import { SearchFilterQueryService } from '#application/services/search-filter-query.service';
import { SingleSignOnService } from '#application/services/single-sign-on.service';
import { UndoneOverdueFollowUpActionCountStoreService } from '#application/services/undone-overdue-follow-up-action-count-store.service';
import { UnreportedActivityCountStoreService } from '#application/services/unreported-activity-count-store.service';
import { UnreportedActivityQueryService } from '#application/services/unreported-activity-query.service';
import { UserGroupCommandService } from '#application/services/user-group-command.service';
import { UserGroupForSelectQueryService } from "#application/services/user-group-for-select-query.service";
import { UserGroupQueryService } from '#application/services/user-group-query.service';
import { UserProfileCalendarSettingsCommandService } from '#application/services/user-profile-calendar-settings-command.service';
import { UserProfileService } from '#application/services/user-profile.service';
import { WebhookCommandService } from "#application/services/webhook-command.service";
import { WebhookEventHistoryQueryService } from "#application/services/webhook-event-history-query.service";
import { WebhookQueryService } from "#application/services/webhook-query.service";
import { WonReasonCommandService } from '#application/services/won-reason-command.service';
import { WonReasonQueryService } from '#application/services/won-reason-query.service';
import { AdoptedReasonCommandServiceImpl } from "#infrastructure/application/adopted-reason-command.service";
import { AdoptedReasonQueryServiceImpl } from "#infrastructure/application/adopted-reason-query.service";
import { AssignedOrganizationQueryServiceImpl } from "#infrastructure/application/assigned-organization-query.service";
import { CalendarStoreServiceImpl } from "#infrastructure/application/calendar-store.service";
import { ClientForSelectQueryServiceImpl } from "#infrastructure/application/client-for-select-query.service";
import { ClientRelatedProductsQueryServiceImpl } from "#infrastructure/application/client-related-products-query.service";
import { DeclineReasonCommandServiceImpl } from "#infrastructure/application/decline-reason-command.service";
import { DeclineReasonQueryServiceImpl } from "#infrastructure/application/decline-reason-query.service";
import { FollowUpActionTypeQueryServiceImpl } from "#infrastructure/application/follow-up-action-type-query.service";
import { FormItemsV2QueryServiceImpl } from "#infrastructure/application/form-items-v2-query.service";
import { InsightFeedbackCommandServiceImpl } from "#infrastructure/application/insight-feedback-command.service";
import { InsightFeedbackOptionQueryServiceImpl } from "#infrastructure/application/insight-feedback-option-query.service";
import { LocalizationSettingCommandServiceImpl } from "#infrastructure/application/localization-setting-command.service";
import { LocalizationSettingQueryServiceImpl } from "#infrastructure/application/localization-setting-query.service";
import { LocalizedNameServiceImpl } from "#infrastructure/application/localized-name.service";
import { LostReasonQueryServiceImpl } from "#infrastructure/application/lost-reason-query.service";
import {
  OpportunityContactHistoryQueryServiceImpl
} from "#infrastructure/application/opportunity-contact-history-query.service";
import {
  OpportunityCsvImportHistoriesQueryServiceImpl
} from "#infrastructure/application/opportunity-csv-import-histories-query.service";
import { OpportunityCsvImportServiceImpl } from "#infrastructure/application/opportunity-csv-import.service";
import { OpportunityFieldQueryServiceImpl } from "#infrastructure/application/opportunity-field-query.service";
import {
  OpportunityBuiltinFieldQueryServiceImpl,
  OpportunityFormLayoutQueryServiceImpl
} from "#infrastructure/application/opportunity-form-layout-query.service";
import { ProductRelatedClientsQueryServiceImpl } from "#infrastructure/application/product-related-clients-query.service";
import { ProposalStatusCommandServiceImpl } from "#infrastructure/application/proposal-status-command.service";
import { ProposalStatusQueryServiceImpl } from "#infrastructure/application/proposal-status-query.service";
import { ProposalWorkflowQueryServiceImpl } from "#infrastructure/application/proposal-workflow-query.service";
import { ReportActivityV2QueryServiceImpl } from "#infrastructure/application/report-activity-v2-query.service";
import { SalesPhaseQueryServiceImpl } from "#infrastructure/application/sales-phase-query.service";
import { UserGroupForSelectQueryServiceImpl } from "#infrastructure/application/user-group-for-select-query.service";
import { WebhookCommandServiceImpl } from "#infrastructure/application/webhook-command.service";
import { WebhookEventHistoryQueryServiceImpl } from "#infrastructure/application/webhook-event-history-query.service";
import { WebhookQueryServiceImpl } from "#infrastructure/application/webhook-query.service";
import { ClientProductRelationshipFactoryImpl } from "#infrastructure/model/client-product-relationship.factory";
import { ClientProductRelationshipRepositoryImpl } from "#infrastructure/model/client-product-relationship.repository";
import { FollowUpActionTypeFactoryImpl } from "#infrastructure/model/follow-up-action-type.factory";
import { LostReasonFactoryImpl } from "#infrastructure/model/lost-reason.factory";
import {
  ClientProductRelationshipFactory
} from "app/model/client-product-relationship/client-product-relationship.factory";
import {
  ClientProductRelationshipRepository
} from "app/model/client-product-relationship/client-product-relationship.repository";
import { ConsultantFactory } from 'app/model/consultant/consultant.factory';
import { ConsultantRepository } from 'app/model/consultant/consultant.repository';
import { ClientRelatedWithContactRepository } from 'app/model/contact/client-related-with-contact.repository';
import { FollowUpActionTypeFactory } from "app/model/follow-up-action/follow-up-action-type.factory";
import { CommonOpportunityContextFactory } from 'app/model/opportunity-context/common-opportunity-context.factory';
import { CommonOpportunityContextRepository } from 'app/model/opportunity-context/common-opportunity-context.repository';
import { OpportunityContextFactory } from 'app/model/opportunity-context/opportunity-context.factory';
import { OpportunityContextRepository } from 'app/model/opportunity-context/opportunity-context.repository';
import {
  PhaseOpportunityContextSettingRepository
} from 'app/model/opportunity-type/phase-opportunity-context-setting/phase-opportunity-context-setting.repository';
import { LostReasonFactory } from "app/model/opportunity/lost-reason/lost-reason.factory";
import { SearchFilterFactory } from 'app/model/search-filter/search-filter.factory';
import { SearchFilterRepository } from 'app/model/search-filter/search-filter.repository';
import { ActionManagementStorageServiceImpl } from './application/action-management-storage.service';
import { ActionQueryServiceImpl } from './application/action-query.service';
import { ClientCommandV2ServiceImpl } from './application/client-command-v2.service';
import { ClientNurturingQueryServiceImpl } from './application/client-nurturing-query.service';
import { ClientTypeQueryServiceImpl } from './application/client-type-query.service';
import { CoachingStorageServiceImpl } from './application/coaching-storage.service';
import { CoachingServiceImpl } from './application/coaching.service';
import { CommonHintCommandServiceImpl } from './application/common-hint-command.service';
import { CommonHintQueryServiceImpl } from './application/common-hint-query.service';
import { CommonInsightCommandServiceImpl } from './application/common-insight-command.service';
import { CommonInsightQueryServiceImpl } from './application/common-insight-query.service';
import {
  CommonOpportunityContextLabelQueryServiceImpl
} from './application/common-opportunity-context-label-query.service';
import { CommonOpportunityContextQueryServiceImpl } from './application/common-opportunity-context-query.service';
import { ConsultantQueryServiceImpl } from './application/consultant-query.service';
import { ContactRelatedClientCommandServiceImpl } from './application/contact-related-client-command.service';
import { CsvExportHistoriesQueryServiceImpl } from './application/csv-export-histories-query.service';
import { CsvExportServiceImpl } from './application/csv-export.service';
import { EventCommandV2ServiceImpl } from './application/event-command-v2.service';
import { EventQueryV2ServiceImpl } from './application/event-query-v2.service';
import { FileCommandServiceImpl } from './application/file-command.service';
import { FileQueryServiceImpl } from './application/file-query.service';
import { FollowUpActionAuthorityServiceImpl } from './application/follow-up-action-authority.service';
import { FormItemsQueryServiceImpl } from './application/form-items-query.service';
import { GoalConfirmationQueryServiceImpl } from './application/goal-confirmation-query.service';
import { GoalSettingCommandServiceImpl } from './application/goal-setting-command.service';
import { GoalSettingQueryServiceImpl } from './application/goal-setting-query.service';
import { HintCommandServiceImpl } from './application/hint-command.service';
import { HintQueryServiceImpl } from './application/hint-query.service';
import { HintReviewCommandServiceImpl } from './application/hint-review-command.service';
import { IncorporatedInsightCommandServiceImpl } from './application/incorporated-insight-command.service';
import { IncorporatedInsightQueryServiceImpl } from './application/incorporated-insight-query.service';
import { InsightCommandServiceImpl } from './application/insight-command.service';
import { InsightQueryServiceImpl } from './application/insight-query.service';
import { MeetingBoardQueryServiceImpl } from './application/meeting-board-query.service';
import { MeetingBoardSettingCommandServiceImpl } from './application/meeting-board-setting-command.service';
import { MeetingBoardSettingQueryServiceImpl } from './application/meeting-board-setting-query.service';
import { MeetingBoardStoreServiceImpl } from './application/meeting-board-store.service';
import { MessageDialogComponent, MessageDialogServiceImpl } from './application/message-dialog.service';
import { MetaUserAuthorizeServiceImpl } from './application/meta-user-authorize.service';
import { NurturingCommandServiceImpl } from './application/nurturing-command.service';
import { NurturingFieldCommandServiceImpl } from './application/nurturing-field-command.service';
import { NurturingFieldQueryServiceImpl } from './application/nurturing-field-query.service';
import { NurturingHearingSettingCommandServiceImpl } from './application/nurturing-hearing-setting-command.service';
import { NurturingHearingSettingQueryServiceImpl } from './application/nurturing-hearing-setting-query.service';
import { NurturingQueryServiceImpl } from './application/nurturing-query.service';
import { NurturingTypeCommandServiceImpl } from './application/nurturing-type-command.service';
import { NurturingTypeQueryServiceImpl } from './application/nurturing-type-query.service';
import { OpportunityCoachingHistoryQueryServiceImpl } from './application/opportunity-coaching-history-query.service';
import { OpportunityCommandV2ServiceImpl } from './application/opportunity-command-v2.service';
import { OpportunityContextLabelQueryServiceImpl } from './application/opportunity-context-label-query.service';
import { OpportunityContextQueryServiceImpl } from './application/opportunity-context-query.service';
import { OpportunityFollowUpActionQueryServiceImpl } from './application/opportunity-follow-up-action-query.service';
import { OpportunityGroupCommandServiceImpl } from './application/opportunity-group-command.service';
import { OpportunityGroupFieldCommandServiceImpl } from './application/opportunity-group-field-command.service';
import { OpportunityGroupFieldQueryServiceImpl } from './application/opportunity-group-field-query.service';
import { OpportunityGroupFormLayoutCommandServiceImpl } from './application/opportunity-group-form-layout-command.service';
import { OpportunityGroupBuiltinFieldQueryServiceServiceImpl, OpportunityGroupFormLayoutQueryServiceImpl } from './application/opportunity-group-form-layout-query.service';
import { OpportunityGroupQueryServiceImpl } from './application/opportunity-group-query.service';
import { OpportunityGroupStatusCommandServiceImpl } from './application/opportunity-group-status-command.service';
import { OpportunityGroupStatusQueryServiceImpl } from './application/opportunity-group-status-query.service';
import { OpportunityGroupTypeCommandServiceServiceImpl } from './application/opportunity-group-type-command.service';
import { OpportunityGroupTypeQueryServiceImpl } from './application/opportunity-group-type-query.service';
import { OpportunityGroupWorkflowCommandServiceImpl } from './application/opportunity-group-workflow-command.service';
import { OpportunityGroupWorkflowQueryServiceImpl } from './application/opportunity-group-workflow-query.service';
import { OpportunityHearingSettingQueryServiceImpl } from './application/opportunity-hearing-setting-query.service';
import { OpportunityPerPhaseQueryServiceImpl } from './application/opportunity-per-phase-query.service';
import { OpportunityQueryV2ServiceImpl } from './application/opportunity-query-v2.service';
import { OpportunityListReportGoalsQueryServiceImpl } from './application/opportunity-report-goals-query.service';
import { OpportunityListReportOpportunityQueryServiceImpl } from './application/opportunity-report-opportunity-query.service';
import {
  OpportunityTypeOpportunityContextSettingQueryServiceImpl
} from './application/opportunity-type-opportunity-context-setting-query.service';
import { OpportunityWithSettingsForActionQueryServiceImpl } from './application/opportunity-with-settings-for-action-query.service';
import { ProductCommandV2ServiceImpl } from './application/product-command-v2.service';
import { ProductCopyServiceImpl } from './application/product-copy.service';
import { ProductCsvExportServiceImpl } from './application/product-csv-export.service';
import { ProductCsvImportHistoriesQueryServiceImpl } from './application/product-csv-import-histories-query.service';
import { ProductCsvImportServiceImpl } from './application/product-csv-import.service';
import { ProposalCommandServiceImpl } from './application/proposal-command.service';
import { ProposalFieldQueryServiceImpl } from './application/proposal-field-query.service';
import { ProposalBuiltinFieldQueryServiceImpl, ProposalFormLayoutQueryServiceImpl } from './application/proposal-form-layout-query.service';
import { ProposalQueryServiceImpl } from './application/proposal-query.service';
import { ProposalTypeCommandServiceImpl } from './application/proposal-type-command.service';
import { ProposalTypeQueryServiceImpl } from './application/proposal-type-query.service';
import { ReportCvrQueryServiceImpl } from './application/report-cvr-query.service';
import { ReportGoalQueryV2ServiceImpl } from './application/report-goal-query-v2.service';
import { ReportNurturingQueryServiceImpl } from './application/report-nurturing-query.service';
import { ReportOpportunityV2QueryServiceImpl } from './application/report-opportunity-v2-query.service';
import { RestAuthTokenCommandServiceImpl } from './application/rest-auth-token-command.service';
import { RestAuthTokenQueryServiceImpl } from './application/rest-auth-token-query.service';
import { RoleCommandServiceImpl } from './application/role-command.service';
import { RoleQueryServiceImpl } from './application/role-query.service';
import { SalesPhaseSettingCommandServiceImpl } from './application/sales-phase-setting-command.service';
import { SalesPhaseSettingQueryServiceImpl } from './application/sales-phase-setting-query.service';
import { SchedulePcFilterStorageServiceImpl } from './application/schedule-pc-filter-storage.service';
import { ScheduleV2QueryServiceImpl } from './application/schedule-v2-query.service';
import { SearchFilterPropertyQueryServiceImpl } from './application/search-filter-property-query.service';
import { SearchFilterQueryServiceImpl } from './application/search-filter-query.service';
import { SingleSignOnServiceImpl } from './application/single-sign-on.service';
import { UndoneOverdueFollowUpActionCountStoreServiceImpl } from './application/undone-overdue-follow-up-action-count-store.service';
import { UnreportedActivityCountStoreServiceImpl } from './application/unreported-activity-count-store.service';
import { UnreportedActivityQueryServiceImpl } from './application/unreported-activity-query.service';
import { UserGroupCommandServiceImpl } from './application/user-group-command.service';
import { UserGroupQueryServiceImpl } from './application/user-group-query.service';
import { UserProfileCalendarSettingsCommandServiceImpl } from './application/user-profile-calendar-settings-command.service';
import { UserProfileServiceImpl } from './application/user-profile.service';
import { WonReasonCommandServiceImpl } from './application/won-reason-command.service';
import { WonReasonQueryServiceImpl } from './application/won-reason-query.service';
import { ClientRelatedWithContactRepositoryImpl } from './model/client-related-with-contact.repository';
import { CommonOpportunityContextFactoryImpl } from './model/common-opportunity-context.factory';
import { CommonOpportunityContextRepositoryImpl } from './model/common-opportunity-context.repository';
import { ConsultantFactoryImpl } from './model/consultant.factory';
import { ConsultantRepositoryImpl } from './model/consultant.repository';
import { OpportunityContextFactoryImpl } from './model/opportunity-context.factory';
import { OpportunityContextRepositoryImpl } from './model/opportunity-context.repository';
import { PhaseOpportunityContextSettingRepositoryImpl } from './model/phase-opportunity-context-setting.repository';
import { SearchFilterFactoryImpl } from './model/search-filter.factory';
import { SearchFilterRepositoryImpl } from './model/search-filter.repository';
import { OpportunityBoardFilterStoreService } from '#application/services/opportunity-board-filter-store.service';
import { OpportunityBoardFilterStoreServiceImpl } from './application/opportunity-board-filter-store.service';

@NgModule({
  imports: [CommonModule, HttpClientModule, MatDialogModule, MatButtonModule],
  declarations: [
    ConfirmDialogComponent,
    MessageDialogComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptor,
      multi: true,
    },
    ClientApi,
    ServerApi,
    HttpResponseInterceptor,
    {
      provide: PageNavigateService,
      useClass: PageNavigateServiceImpl,
    },
    {
      provide: AuthorizeService,
      useClass: AuthorizeServiceImpl,
    },
    {
      provide: SnackBarService,
      useClass: SnackBarServiceImpl,
    },
    {
      provide: ConfirmService,
      useClass: ConfirmServiceImpl,
    },
    {
      provide: OrganizationFactory,
      useClass: OrganizationFactoryImpl,
    },
    {
      provide: OrganizationRepository,
      useClass: OrganizationRepositoryImpl,
    },
    {
      provide: UserFactory,
      useClass: UserFactoryImpl,
    },
    {
      provide: UserRepository,
      useClass: UserRepositoryImpl,
    },
    {
      provide: OpportunityTypeFactory,
      useClass: OpportunityTypeFactoryImpl,
    },
    {
      provide: OpportunityTypeRepository,
      useClass: OpportunityTypeRepositoryImpl,
    },
    {
      provide: OpportunityTypeQueryService,
      useClass: OpportunityTypeQueryServiceImpl,
    },
    {
      provide: OpportunityRepository,
      useClass: OpportunityRepositoryImpl,
    },
    {
      provide: OpportunityFactory,
      useClass: OpportunityFactoryImpl,
    },
    {
      provide: ActionFactory,
      useClass: ActionFactoryImpl,
    },
    {
      provide: ActionTypeRepository,
      useClass: ActionTypeRepositoryImpl,
    },
    {
      provide: ActionTypeFactory,
      useClass: ActionTypeFactoryImpl,
    },
    {
      provide: ActionTypeQueryService,
      useClass: ActionTypeQueryServiceImpl,
    },
    {
      provide: ActionRepository,
      useClass: ActionRepositoryImpl,
    },
    {
      provide: FollowUpActionFactory,
      useClass: FollowUpActionFactoryImpl,
    },
    {
      provide: FollowUpActionRepository,
      useClass: FollowUpActionRepositoryImpl,
    },
    {
      provide: FollowUpActionTypeFactory,
      useClass: FollowUpActionTypeFactoryImpl,
    },
    {
      provide: FollowUpActionTypeRepository,
      useClass: FollowUpActionTypeRepositoryImpl,
    },
    {
      provide: FollowUpActionQueryService,
      useClass: FollowUpActionQueryServiceImpl,
    },
    {
      provide: FollowUpActionTypeQueryService,
      useClass: FollowUpActionTypeQueryServiceImpl,
    },
    {
      provide: LostReasonFactory,
      useClass: LostReasonFactoryImpl
    },
    {
      provide: LostReasonRepository,
      useClass: LostReasonRepositoryImpl,
    },
    {
      provide: ClientTypeRepository,
      useClass: ClientTypeRepositoryImpl,
    },
    {
      provide: ClientTypeFactory,
      useClass: ClientTypeFactoryImpl,
    },
    {
      provide: ContactHistoryTemplateFactory,
      useClass: ContactHistoryTemplateFactoryImpl
    },
    {
      provide: ContactHistoryTemplateRepository,
      useClass: ContactHistoryTemplateRepositoryImpl
    },
    {
      provide: ClientFactory,
      useClass: ClientFactoryImpl,
    },
    {
      provide: OpportunityHearingSettingRepository,
      useClass: OpportunityHearingSettingRepositoryImpl,
    },
    {
      provide: ContactFactory,
      useClass: ContactFactoryImpl,
    },
    {
      provide: ContactQueryService,
      useClass: ContactQueryServiceImpl,
    },
    {
      provide: ContactRepository,
      useClass: ContactRepositoryImpl,
    },
    {
      provide: OpportunityQueryService,
      useClass: OpportunityQueryServiceImpl,
    },
    {
      provide: UserQueryService,
      useClass: UserQueryServiceImpl,
    },
    {
      provide: ContactHistoryTemplateQueryService,
      useClass: ContactHistoryTemplateQueryServiceImpl,
    },
    {
      provide: ClientQueryService,
      useClass: ClientQueryServiceImpl,
    },
    {
      provide: ContactHistoryFactory,
      useClass: ContactHistoryFactoryImpl,
    },
    {
      provide: ContactHistoryQueryService,
      useClass: ContactHistoryQueryServiceImpl,
    },
    {
      provide: ContactHistoryRepository,
      useClass: ContactHistoryRepositoryImpl,
    },
    {
      provide: ClientRelationshipFactory,
      useClass: ClientRelationshipFactoryImpl,
    },
    {
      provide: ClientCsvImportService,
      useClass: ClientCsvImportServiceImpl,
    },
    {
      provide: ClientRelationshipQueryService,
      useClass: ClientRelationshipQueryServiceImpl,
    },
    {
      provide: ClientRelationshipRepository,
      useClass: ClientRelationshipRepositoryImpl,
    },
    {
      provide: ClientRelationshipNoteFactory,
      useClass: ClientRelationshipNoteFactoryImpl,
    },
    {
      provide: ClientRelationshipNoteRepository,
      useClass: ClientRelationshipNoteRepositoryImpl,
    },
    {
      provide: ClientProductRelationshipFactory,
      useClass: ClientProductRelationshipFactoryImpl,
    },
    {
      provide: ClientProductRelationshipRepository,
      useClass: ClientProductRelationshipRepositoryImpl,
    },
    {
      provide: ClientRelatedProductsQueryService,
      useClass: ClientRelatedProductsQueryServiceImpl,
    },
    {
      provide: ClientCsvImportHistoriesQueryService,
      useClass: ClientCsvImportHistoriesQueryServiceImpl,
    },
    {
      provide: CustomFieldFactory,
      useClass: CustomFieldFactoryImpl,
    },
    {
      provide: ProductFieldQueryService,
      useClass: ProductFieldQueryServiceImpl,
    },
    {
      provide: CustomFieldRepository,
      useClass: CustomFieldRepositoryImpl,
    },
    {
      provide: ProductFormLayoutQueryService,
      useClass: ProductFormLayoutQueryServiceImpl,
    },
    {
      provide: ProductBuiltinFieldQueryService,
      useClass: ProductBuiltinFieldQueryServiceImpl,
    },
    {
      provide: FormLayoutFactory,
      useClass: FormLayoutFactoryImpl,
    },
    {
      provide: FormLayoutRepository,
      useClass: FormLayoutRepositoryImpl,
    },
    {
      provide: ProductTypeFactory,
      useClass: ProductTypeFactoryImpl,
    },
    {
      provide: ProductTypeQueryService,
      useClass: ProductTypeQueryServiceImpl,
    },
    {
      provide: ProductTypeRepository,
      useClass: ProductTypeRepositoryImpl,
    },
    {
      provide: ProductQueryService,
      useClass: ProductQueryServiceImpl,
    },
    {
      provide: ProductCsvImportService,
      useClass: ProductCsvImportServiceImpl,
    },
    {
      provide: ProductCsvImportHistoriesQueryService,
      useClass: ProductCsvImportHistoriesQueryServiceImpl,
    },
    {
      provide: ProductRelatedClientsQueryService,
      useClass: ProductRelatedClientsQueryServiceImpl,
    },
    {
      provide: ClientFieldQueryService,
      useClass: ClientFieldQueryServiceImpl,
    },
    {
      provide: CorporationClientBuiltinFieldQueryService,
      useClass: CorporationClientBuiltinFieldQueryServiceImpl,
    },
    {
      provide: CorporationClientFormLayoutQueryService,
      useClass: CorporationClientFormLayoutQueryServiceImpl,
    },
    {
      provide: IndividualClientBuiltinFieldQueryService,
      useClass: IndividualClientBuiltinFieldQueryServiceImpl,
    },
    {
      provide: IndividualClientFormLayoutQueryService,
      useClass: IndividualClientFormLayoutQueryServiceImpl,
    },
    {
      provide: FormItemsQueryService,
      useClass: FormItemsQueryServiceImpl,
    },
    {
      provide: FormItemsV2QueryService,
      useClass: FormItemsV2QueryServiceImpl,
    },
    {
      provide: ClientTypeQueryService,
      useClass: ClientTypeQueryServiceImpl,
    },
    {
      provide: ProductCsvExportService,
      useClass: ProductCsvExportServiceImpl,
    },
    {
      provide: LostReasonQueryService,
      useClass: LostReasonQueryServiceImpl,
    },
    {
      provide: OpportunityHearingSettingQueryService,
      useClass: OpportunityHearingSettingQueryServiceImpl,
    },
    {
      provide: SalesPhaseQueryService,
      useClass: SalesPhaseQueryServiceImpl,
    },
    {
      provide: OpportunityFieldQueryService,
      useClass: OpportunityFieldQueryServiceImpl,
    },
    {
      provide: OpportunityBuiltinFieldQueryService,
      useClass: OpportunityBuiltinFieldQueryServiceImpl,
    },
    {
      provide: OpportunityFormLayoutQueryService,
      useClass: OpportunityFormLayoutQueryServiceImpl,
    },
    {
      provide: ActionQueryService,
      useClass: ActionQueryServiceImpl,
    },
    {
      provide: SearchFilterFactory,
      useClass: SearchFilterFactoryImpl,
    },
    {
      provide: SearchFilterQueryService,
      useClass: SearchFilterQueryServiceImpl,
    },
    {
      provide: SearchFilterPropertyQueryService,
      useClass: SearchFilterPropertyQueryServiceImpl,
    },
    {
      provide: SearchFilterRepository,
      useClass: SearchFilterRepositoryImpl,
    },
    {
      provide: ReportActivityV2QueryService,
      useClass: ReportActivityV2QueryServiceImpl,
    },
    {
      provide: ConsultantFactory,
      useClass: ConsultantFactoryImpl,
    },
    {
      provide: ConsultantQueryService,
      useClass: ConsultantQueryServiceImpl,
    },
    {
      provide: ConsultantRepository,
      useClass: ConsultantRepositoryImpl,
    },
    {
      provide: AssignedOrganizationQueryService,
      useClass: AssignedOrganizationQueryServiceImpl,
    },
    {
      provide: MetaUserAuthorizeService,
      useClass: MetaUserAuthorizeServiceImpl,
    },
    {
      provide: SingleSignOnService,
      useClass: SingleSignOnServiceImpl,
    },
    {
      provide: OpportunityContextFactory,
      useClass: OpportunityContextFactoryImpl,
    },
    {
      provide: OpportunityContextQueryService,
      useClass: OpportunityContextQueryServiceImpl,
    },
    {
      provide: OpportunityContextRepository,
      useClass: OpportunityContextRepositoryImpl,
    },
    {
      provide: OpportunityContextLabelQueryService,
      useClass: OpportunityContextLabelQueryServiceImpl,
    },
    {
      provide: CommonOpportunityContextFactory,
      useClass: CommonOpportunityContextFactoryImpl,
    },
    {
      provide: CommonOpportunityContextQueryService,
      useClass: CommonOpportunityContextQueryServiceImpl,
    },
    {
      provide: CommonOpportunityContextRepository,
      useClass: CommonOpportunityContextRepositoryImpl,
    },
    {
      provide: CommonOpportunityContextLabelQueryService,
      useClass: CommonOpportunityContextLabelQueryServiceImpl,
    },
    {
      provide: InsightQueryService,
      useClass: InsightQueryServiceImpl,
    },
    {
      provide: InsightCommandService,
      useClass: InsightCommandServiceImpl,
    },
    {
      provide: CommonInsightQueryService,
      useClass: CommonInsightQueryServiceImpl,
    },
    {
      provide: CommonInsightCommandService,
      useClass: CommonInsightCommandServiceImpl,
    },
    {
      provide: OpportunityContactHistoryQueryService,
      useClass: OpportunityContactHistoryQueryServiceImpl,
    },
    {
      provide: OpportunityTypeOpportunityContextSettingQueryService,
      useClass: OpportunityTypeOpportunityContextSettingQueryServiceImpl,
    },
    {
      provide: PhaseOpportunityContextSettingRepository,
      useClass: PhaseOpportunityContextSettingRepositoryImpl,
    },
    {
      provide: CsvExportHistoriesQueryService,
      useClass: CsvExportHistoriesQueryServiceImpl,
    },
    {
      provide: CsvExportService,
      useClass: CsvExportServiceImpl,
    },
    {
      provide: IncorporatedInsightQueryService,
      useClass: IncorporatedInsightQueryServiceImpl,
    },
    {
      provide: MessageDialogService,
      useClass: MessageDialogServiceImpl,
    },
    {
      provide: ClientRelatedWithContactRepository,
      useClass: ClientRelatedWithContactRepositoryImpl,
    },
    {
      provide: CoachingService,
      useClass: CoachingServiceImpl,
    },
    {
      provide: OpportunityCoachingHistoryQueryService,
      useClass: OpportunityCoachingHistoryQueryServiceImpl,
    },
    {
      provide: ProductCopyService,
      useClass: ProductCopyServiceImpl,
    },
    {
      provide: CommonHintCommandService,
      useClass: CommonHintCommandServiceImpl,
    },
    {
      provide: CommonHintQueryService,
      useClass: CommonHintQueryServiceImpl,
    },
    {
      provide: EventCommandV2Service,
      useClass: EventCommandV2ServiceImpl,
    },
    {
      provide: EventQueryV2Service,
      useClass: EventQueryV2ServiceImpl,
    },
    {
      provide: WonReasonCommandService,
      useClass: WonReasonCommandServiceImpl,
    },
    {
      provide: WonReasonQueryService,
      useClass: WonReasonQueryServiceImpl,
    },
    {
      provide: InsightFeedbackCommandService,
      useClass: InsightFeedbackCommandServiceImpl,
    },
    {
      provide: InsightFeedbackOptionQueryService,
      useClass: InsightFeedbackOptionQueryServiceImpl,
    },
    {
      provide: OpportunityWithSettingsForActionQueryService,
      useClass: OpportunityWithSettingsForActionQueryServiceImpl,
    },
    {
      provide: ProposalTypeCommandService,
      useClass: ProposalTypeCommandServiceImpl,
    },
    {
      provide: ProposalTypeQueryService,
      useClass: ProposalTypeQueryServiceImpl,
    },
    {
      provide: FileCommandService,
      useClass: FileCommandServiceImpl,
    },
    {
      provide: FileQueryService,
      useClass: FileQueryServiceImpl,
    },
    {
      provide: ProposalFieldQueryService,
      useClass: ProposalFieldQueryServiceImpl,
    },
    {
      provide: ProposalBuiltinFieldQueryService,
      useClass: ProposalBuiltinFieldQueryServiceImpl,
    },
    {
      provide: ProposalFormLayoutQueryService,
      useClass: ProposalFormLayoutQueryServiceImpl,
    },
    {
      provide: DeclineReasonCommandService,
      useClass: DeclineReasonCommandServiceImpl,
    },
    {
      provide: DeclineReasonQueryService,
      useClass: DeclineReasonQueryServiceImpl,
    },
    {
      provide: AdoptedReasonCommandService,
      useClass: AdoptedReasonCommandServiceImpl,
    },
    {
      provide: AdoptedReasonQueryService,
      useClass: AdoptedReasonQueryServiceImpl,
    },

    {
      provide: ProposalStatusQueryService,
      useClass: ProposalStatusQueryServiceImpl,
    },
    {
      provide: ProposalStatusCommandService,
      useClass: ProposalStatusCommandServiceImpl,
    },
    {
      provide: ProposalWorkflowQueryService,
      useClass: ProposalWorkflowQueryServiceImpl,
    },
    {
      provide: ProposalCommandService,
      useClass: ProposalCommandServiceImpl,
    },
    {
      provide: ProposalQueryService,
      useClass: ProposalQueryServiceImpl,
    },
    {
      provide: IncorporatedInsightCommandService,
      useClass: IncorporatedInsightCommandServiceImpl,
    },
    {
      provide: WebhookQueryService,
      useClass: WebhookQueryServiceImpl,
    },
    {
      provide: WebhookCommandService,
      useClass: WebhookCommandServiceImpl,
    },
    {
      provide: RestAuthTokenCommandService,
      useClass: RestAuthTokenCommandServiceImpl,
    },
    {
      provide: RestAuthTokenQueryService,
      useClass: RestAuthTokenQueryServiceImpl,
    },
    {
      provide: WebhookEventHistoryQueryService,
      useClass: WebhookEventHistoryQueryServiceImpl,
    },
    {
      provide: SalesPhaseSettingCommandService,
      useClass: SalesPhaseSettingCommandServiceImpl,
    },
    {
      provide: SalesPhaseSettingQueryService,
      useClass: SalesPhaseSettingQueryServiceImpl,
    },
    {
      provide: UserGroupCommandService,
      useClass: UserGroupCommandServiceImpl,
    },
    {
      provide: UserGroupQueryService,
      useClass: UserGroupQueryServiceImpl,
    },
    {
      provide: OpportunityListReportGoalsQueryService,
      useClass: OpportunityListReportGoalsQueryServiceImpl,
    },
    {
      provide: GoalConfirmationQueryService,
      useClass: GoalConfirmationQueryServiceImpl,
    },
    {
      provide: ReportOpportunityV2QueryService,
      useClass: ReportOpportunityV2QueryServiceImpl,
    },
    {
      provide: ReportCvrQueryService,
      useClass: ReportCvrQueryServiceImpl,
    },
    {
      provide: OpportunityListReportOpportunityQueryService,
      useClass: OpportunityListReportOpportunityQueryServiceImpl,
    },
    {
      provide: HintReviewCommandService,
      useClass: HintReviewCommandServiceImpl,
    },
    {
      provide: HintCommandService,
      useClass: HintCommandServiceImpl,
    },
    {
      provide: HintQueryService,
      useClass: HintQueryServiceImpl,
    },
    {
      provide: RoleQueryService,
      useClass: RoleQueryServiceImpl,
    },
    {
      provide: RoleCommandService,
      useClass: RoleCommandServiceImpl,
    },
    {
      provide: NurturingHearingSettingQueryService,
      useClass: NurturingHearingSettingQueryServiceImpl,
    },
    {
      provide: NurturingHearingSettingCommandService,
      useClass: NurturingHearingSettingCommandServiceImpl,
    },
    {
      provide: NurturingFieldQueryService,
      useClass: NurturingFieldQueryServiceImpl,
    },
    {
      provide: NurturingFieldCommandService,
      useClass: NurturingFieldCommandServiceImpl,
    },
    {
      provide: NurturingTypeQueryService,
      useClass: NurturingTypeQueryServiceImpl,
    },
    {
      provide: NurturingCommandService,
      useClass: NurturingCommandServiceImpl,
    },
    {
      provide: NurturingTypeCommandService,
      useClass: NurturingTypeCommandServiceImpl,
    },
    {
      provide: NurturingQueryService,
      useClass: NurturingQueryServiceImpl,
    },
    {
      provide: LocalizationSettingCommandService,
      useClass: LocalizationSettingCommandServiceImpl,
    },
    {
      provide: LocalizationSettingQueryService,
      useClass: LocalizationSettingQueryServiceImpl,
    },
    {
      provide: ClientNurturingQueryService,
      useClass: ClientNurturingQueryServiceImpl,
    },
    {
      provide: LocalizedNameService,
      useClass: LocalizedNameServiceImpl,
    },
    {
      provide: CoachingStorageService,
      useClass: CoachingStorageServiceImpl,
    },
    {
      provide: ClientCommandV2Service,
      useClass: ClientCommandV2ServiceImpl,
    },
    {
      provide: FollowUpActionAuthorityService,
      useClass: FollowUpActionAuthorityServiceImpl,
    },
    {
      provide: OpportunityFollowUpActionQueryService,
      useClass: OpportunityFollowUpActionQueryServiceImpl,
    },
    {
      provide: OpportunityCommandV2Service,
      useClass: OpportunityCommandV2ServiceImpl,
    },
    {
      provide: OpportunityQueryV2Service,
      useClass: OpportunityQueryV2ServiceImpl,
    },
    {
      provide: ActionManagementStorageService,
      useClass: ActionManagementStorageServiceImpl,
    },
    {
      provide: ContactRelatedClientCommandService,
      useClass: ContactRelatedClientCommandServiceImpl,
    },
    {
      provide: SchedulePcFilterStorageService,
      useClass: SchedulePcFilterStorageServiceImpl,
    },
    {
      provide: ReportNurturingQueryService,
      useClass: ReportNurturingQueryServiceImpl,
    },
    {
      provide: UndoneOverdueFollowUpActionCountStoreService,
      useClass: UndoneOverdueFollowUpActionCountStoreServiceImpl,
    },
    {
      provide: UnreportedActivityCountStoreService,
      useClass: UnreportedActivityCountStoreServiceImpl,
    },
    {
      provide: OpportunityGroupFieldCommandService,
      useClass: OpportunityGroupFieldCommandServiceImpl,
    },
    {
      provide: OpportunityGroupFieldQueryService,
      useClass: OpportunityGroupFieldQueryServiceImpl,
    },
    {
      provide: UnreportedActivityQueryService,
      useClass: UnreportedActivityQueryServiceImpl,
    },
    {
      provide: OpportunityGroupStatusCommandService,
      useClass: OpportunityGroupStatusCommandServiceImpl,
    },
    {
      provide: OpportunityGroupStatusQueryService,
      useClass: OpportunityGroupStatusQueryServiceImpl,
    },
    {
      provide: OpportunityGroupWorkflowQueryService,
      useClass: OpportunityGroupWorkflowQueryServiceImpl,
    },
    {
      provide: OpportunityGroupWorkflowCommandService,
      useClass: OpportunityGroupWorkflowCommandServiceImpl,
    },
    {
      provide: OpportunityGroupBuiltinFieldQueryService,
      useClass: OpportunityGroupBuiltinFieldQueryServiceServiceImpl,
    },
    {
      provide: OpportunityGroupFormLayoutCommandService,
      useClass: OpportunityGroupFormLayoutCommandServiceImpl,
    },
    {
      provide: OpportunityGroupFormLayoutQueryService,
      useClass: OpportunityGroupFormLayoutQueryServiceImpl,
    },
    {
      provide: OpportunityGroupTypeCommandService,
      useClass: OpportunityGroupTypeCommandServiceServiceImpl,
    },
    {
      provide: OpportunityGroupTypeQueryService,
      useClass: OpportunityGroupTypeQueryServiceImpl,
    },
    {
      provide: OpportunityGroupQueryService,
      useClass: OpportunityGroupQueryServiceImpl,
    },
    {
      provide: OpportunityGroupCommandService,
      useClass: OpportunityGroupCommandServiceImpl,
    },
    {
      provide: UserProfileCalendarSettingsCommandService,
      useClass: UserProfileCalendarSettingsCommandServiceImpl,
    },
    {
      provide: CalendarStoreService,
      useClass: CalendarStoreServiceImpl,
    },
    {
      provide: UserProfileService,
      useClass: UserProfileServiceImpl,
    },
    {
      provide: OpportunityCsvImportService,
      useClass: OpportunityCsvImportServiceImpl,
    },
    {
      provide: OpportunityCsvImportHistoriesQueryService,
      useClass: OpportunityCsvImportHistoriesQueryServiceImpl,
    },
    {
      provide: OpportunityPerPhaseQueryService,
      useClass: OpportunityPerPhaseQueryServiceImpl,
    },
    {
      provide: UserGroupForSelectQueryService,
      useClass: UserGroupForSelectQueryServiceImpl,
    },
    {
      provide: ProductCommandV2Service,
      useClass: ProductCommandV2ServiceImpl,
    },
    {
      provide: ReportGoalQueryV2Service,
      useClass: ReportGoalQueryV2ServiceImpl,
    },
    {
      provide: GoalSettingQueryService,
      useClass: GoalSettingQueryServiceImpl,
    },
    {
      provide: GoalSettingCommandService,
      useClass: GoalSettingCommandServiceImpl,
    },
    {
      provide: MeetingBoardQueryService,
      useClass: MeetingBoardQueryServiceImpl,
    },
    {
      provide: MeetingBoardSettingCommandService,
      useClass: MeetingBoardSettingCommandServiceImpl,
    },
    {
      provide: MeetingBoardSettingQueryService,
      useClass: MeetingBoardSettingQueryServiceImpl,
    },
    {
      provide: MeetingBoardStoreService,
      useClass: MeetingBoardStoreServiceImpl,
    },
    {
      provide: ClientForSelectQueryService,
      useClass: ClientForSelectQueryServiceImpl,
    },
    {
      provide: ScheduleV2QueryService,
      useClass: ScheduleV2QueryServiceImpl,
    },
    {
      provide: OpportunityBoardFilterStoreService,
      useClass: OpportunityBoardFilterStoreServiceImpl,
    },
  ],
})
export class InfrastructureModule {
}
