import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type CreateHintRequest = {
  title: string,
  content: string,
}

export type UpdateHintRequest = CreateHintRequest;

export type SearchHintRequest = {
  pagination: {
    perPage: number,
    page: number,
  },
  searchString: string,
  searchRange: 'ORGANIZATION' | 'COMMON_AND_ORGANIZATION',
}

export type SearchHintResponse = {
  totalCount: number,
  results: {
    id: string,
    title: string,
    good: number,
    bad: number
  }[]
}

export type GetHintResponse = {
  id: string,
  title: string,
  content: string,
}

export class HintApi {
  constructor(private readonly http: HttpClient) {}

  create(request: CreateHintRequest): Observable<void> {
    return this.http.post<void>(`/api/hints`, request);
  }

  search(request: SearchHintRequest): Observable<SearchHintResponse> {
    return this.http.post<SearchHintResponse>(`/api/hints/search`, request);
  }

  get(id: string): Observable<GetHintResponse> {
    return this.http.get<GetHintResponse>(`/api/hints/${id}`);
  }

  update(id: string, request: UpdateHintRequest): Observable<void> {
    return this.http.put<void>(`/api/hints/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/hints/${id}`);
  }
}
