import { Role, RoleDepth, RoleID, RoleName, RoleUpdatedAt } from "app/model/role/role";
import { Observable } from "rxjs";

export type ListRoleOutputs = ReadonlyArray<{
  id : RoleID,
  name : RoleName,
  depth: RoleDepth,
  parentRole? : {
    id : RoleID,
    name : RoleName,
  },
  membersCount : number,
  updatedAt : RoleUpdatedAt,
}>;

export type ListRoleSummaryOutputs = ReadonlyArray<{
  id : RoleID,
  name : RoleName,
}>;

export abstract class RoleQueryService {
  abstract list(): Observable<ListRoleOutputs>;
  abstract listSummary(): Observable<ListRoleSummaryOutputs>;
  abstract get(id : RoleID): Observable<Role>;
}
