import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

export type CreateProductTypeRequest = CreateProductTypeRequestData;

interface CreateProductTypeRequestData {
  name: string,
  description: string,
  formLayoutID?: string,
}

export type ListProductTypeResponse = ListProductTypeResponseData[];

interface ListProductTypeResponseData {
  id: string,
  name: string,
  description: string,
  formLayoutID?: string,
  formLayoutName?: string
}

export type GetProductTypeResponse = GetProductTypeResponseData;

interface GetProductTypeResponseData {
  id: string,
  name: string,
  description: string,
  formLayoutID?: string,
}

export type UpdateProductTypeRequest = UpdateProductTypeRequestData;

interface UpdateProductTypeRequestData {
  name: string,
  description: string,
  formLayoutID?: string,
}

export type SortProductTypeRequest = SortProductTypeRequestData;

interface SortProductTypeRequestData {
  ids: string[]
}

export class ProductTypeApi {
  constructor(private readonly http: HttpClient) {
  }

  create(request: CreateProductTypeRequest): Observable<void> {
    return this.http.post<void>(`/api/product-types`, request);
  }

  list(): Observable<ListProductTypeResponse> {
    return this.http.get<ListProductTypeResponse>(`/api/product-type-list`);
  }

  get(id: string): Observable<GetProductTypeResponse> {
    return this.http.get<GetProductTypeResponse>(`/api/product-types/${id}`);
  }

  update(id: string, request: UpdateProductTypeRequest): Observable<void> {
    return this.http.put<void>(`/api/product-types/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/product-types/${id}`);
  }

  sort(request: SortProductTypeRequest): Observable<void> {
    return this.http.patch<void>(`/api/product-types/sort`, request);
  }
}
