import { AdoptedReasonID, AdoptedReasonName } from "app/model/adopted-reason/adopted-reason";
import { CustomItems } from "app/model/custom-field/custom-field";
import { DeclineReasonID, DeclineReasonName } from "app/model/decline-reason/decline-reason";
import { ProductId, ProductNumber, ProductQuantityUnit } from "app/model/product/product"
import { ProposalStatusID, ProposalStatusType } from "app/model/proposal-status/proposal-status";
import { ProductDetailType, ProposalTypeID } from "app/model/proposal-type/proposal-type"
import { FieldType } from "../custom-field/field-type";

/**
 * 小数点第1位を切り上げた数値を返す。
 * 結果がNaNの場合は0を返す。
 */
const roundUp = (n: number): number => {
  const result = Math.ceil(
    Number.parseInt((n * 10).toFixed(1)) / 10
  );
  return Number.isNaN(result) ? 0 : result;
}

/** 提案ID */
export class ProposalID {
  constructor(public readonly value: string) {}

  equals(another: ProposalID): boolean {
    return this.value === another.value;
  }
}

/** 提案番号 */
export class ProposalNumber {
  constructor(public readonly value: number) {}

  toString(): string {
    return this.value.toString();
  }
}

/** 1提案に付与するタイトル */
export class ProposalTitle {
  readonly fieldType = FieldType.SINGLE_LINE_TEXT;
  static readonly DEFAULT_VALUE = '';
  constructor(public readonly value: string) {}

  static defaultValue(): ProposalTitle {
    return new ProposalTitle(ProposalTitle.DEFAULT_VALUE);
  }
}

/** 明細行ごとの名前 */
export class ProposalDetailRowTitle {
  constructor(public readonly value: string) {}
}

/** 明細行の数値項目のインターフェース */
export type ProposalDetailRowNumber = {
  value: number,
  isOverMaxLength: boolean,
  toLocaleString: () => string,
}

/** 明細行ごとの数量 */
export class ProposalDetailRowQuantity implements ProposalDetailRowNumber {

  constructor(
    public readonly value: number,
  ) {}

  static readonly MAX_LENGTH = 7;

  get isOverMaxLength(): boolean {
    return this.value.toString().length > ProposalDetailRowQuantity.MAX_LENGTH;
  }

  toLocaleString(): string {
    return this.value.toLocaleString();
  }
}

/** 明細行ごとの単価 */
export class ProposalDetailRowUnitPrice implements ProposalDetailRowNumber {

  private constructor(
    public readonly value: number,
    private readonly isOverSafeInteger: boolean,
  ) {}

  static readonly MAX_LENGTH = 15;

  get isOverMaxLength(): boolean {
    return (this.value.toString().length > ProposalDetailRowUnitPrice.MAX_LENGTH)
        || this.isOverSafeInteger;
  }

  static from(
    quantity: ProposalDetailRowQuantity,
    subTotalAmount: ProposalDetailRowSubTotalAmount,
  ): ProposalDetailRowUnitPrice {
    return (quantity.isOverMaxLength || subTotalAmount.isOverMaxLength)
    ? new ProposalDetailRowUnitPrice(0, true)
    : new ProposalDetailRowUnitPrice(
      roundUp(subTotalAmount.value / quantity.value),
      false
    );
  }

  static create(value: number): ProposalDetailRowUnitPrice {
    if (Number.isNaN(value)) {
      return new ProposalDetailRowUnitPrice(0, false);
    }
    if (Number.isSafeInteger(value)) {
      return new ProposalDetailRowUnitPrice(value, false);
    }
    return new ProposalDetailRowUnitPrice(0, true);
  }

  toLocaleString(): string {
    return this.value.toLocaleString();
  }
}

/** 明細行の小計 */
export class ProposalDetailRowSubTotalAmount implements ProposalDetailRowNumber {

  private constructor(
    public readonly value: number,
    private readonly isOverSafeInteger: boolean,
  ) {}

  static readonly MAX_LENGTH = 15;

  get isOverMaxLength(): boolean {
    return (this.value.toString().length > ProposalDetailRowSubTotalAmount.MAX_LENGTH)
        || this.isOverSafeInteger;
  }

  static from(
    quantity: ProposalDetailRowQuantity,
    unitPrice: ProposalDetailRowUnitPrice,
  ): ProposalDetailRowSubTotalAmount {
    return (quantity.isOverMaxLength || unitPrice.isOverMaxLength)
        && !Number.isSafeInteger(quantity.value * unitPrice.value)
    ? new ProposalDetailRowSubTotalAmount(0, true)
    : new ProposalDetailRowSubTotalAmount(
      roundUp(quantity.value * unitPrice.value),
      false,
    )
  }

  static create(value: number): ProposalDetailRowSubTotalAmount {
    return Number.isSafeInteger(value)
    ? new ProposalDetailRowSubTotalAmount(value, false)
    : new ProposalDetailRowSubTotalAmount(0, true);
  }

  toLocaleString(): string {
    return this.value.toLocaleString();
  }
}

/** 明細行の小計の合計 */
export class ProposalDetailRowTotalAmount {
  /** 消費税率 */
  private readonly _consumptionTaxRate = 0.1;

  constructor(
    public readonly value: number,
  ) {}

  static sum(...args: number[]): ProposalDetailRowTotalAmount {
    return new ProposalDetailRowTotalAmount(
      args.reduce((p, c) => p + c, 0)
    );
  }

  /** 税込み価格（小数点切り上げ）を返す */
  includesConsumptionTax(): number {
    return Math.ceil(
      Number.parseInt(
        (this.value * (1 + this._consumptionTaxRate) * 10).toFixed(1)
      ) / 10
    );
  }
}

/** 複数行の場合の1明細行 */
export class ProposalDetailRow {

  private constructor(
    public readonly title: ProposalDetailRowTitle,
    public readonly quantity: ProposalDetailRowQuantity,
    public readonly unit: ProductQuantityUnit,
    public readonly unitPrice: ProposalDetailRowUnitPrice,
    public readonly subTotalAmount: ProposalDetailRowSubTotalAmount,
    public readonly productID: ProductId | undefined,
    public readonly productNumber: ProductNumber | undefined,
  ) {}

  static default(): ProposalDetailRow {
    return new ProposalDetailRow(
      new ProposalDetailRowTitle(''),
      new ProposalDetailRowQuantity(1),
      new ProductQuantityUnit('個'),
      ProposalDetailRowUnitPrice.create(0),
      ProposalDetailRowSubTotalAmount.create(0),
      undefined,
      undefined
    );
  }

  static from(
    title: ProposalDetailRowTitle,
    quantity: ProposalDetailRowQuantity,
    unit: ProductQuantityUnit,
    unitPrice: ProposalDetailRowUnitPrice,
    subTotalAmount: ProposalDetailRowSubTotalAmount,
    productID: ProductId | undefined,
    productNumber: ProductNumber | undefined,
  ): ProposalDetailRow {
    return new ProposalDetailRow(
      title,
      quantity,
      unit,
      unitPrice,
      subTotalAmount,
      productID,
      productNumber,
    );
  }

  static fromRawValue(
    title: string,
    quantity: number,
    unit: string,
    unitPrice: number,
    subTotalAmount: number,
    productID: string | undefined,
    productNumber: number | undefined,
  ): ProposalDetailRow {
    return new ProposalDetailRow(
      new ProposalDetailRowTitle(title),
      new ProposalDetailRowQuantity(quantity),
      new ProductQuantityUnit(unit),
      ProposalDetailRowUnitPrice.create(unitPrice),
      ProposalDetailRowSubTotalAmount.create(subTotalAmount),
      productID === undefined ? undefined : new ProductId(productID),
      productNumber === undefined ? undefined : new ProductNumber(productNumber),
    );
  }

  changeQuantity(quantity: ProposalDetailRowQuantity): ProposalDetailRow {
    return new ProposalDetailRow(
      this.title,
      quantity,
      this.unit,
      this.unitPrice,
      this.subTotalAmount,
      this.productID,
      this.productNumber,
    );
  }

  changeUnitPrice(unitPrice: ProposalDetailRowUnitPrice): ProposalDetailRow {
    return new ProposalDetailRow(
      this.title,
      this.quantity,
      this.unit,
      unitPrice,
      this.subTotalAmount,
      this.productID,
      this.productNumber,
    );
  }

  changeSubTotalAmount(subTotalAmount: ProposalDetailRowSubTotalAmount): ProposalDetailRow {
    return new ProposalDetailRow(
      this.title,
      this.quantity,
      this.unit,
      this.unitPrice,
      subTotalAmount,
      this.productID,
      this.productNumber,
    );
  }

  calculateUnitPrice(): ProposalDetailRow {
    return new ProposalDetailRow(
      this.title,
      this.quantity,
      this.unit,
      ProposalDetailRowUnitPrice.from(this.quantity, this.subTotalAmount),
      this.subTotalAmount,
      this.productID,
      this.productNumber,
    );
  }

  calculateSubTotalAmount(): ProposalDetailRow {
    return new ProposalDetailRow(
      this.title,
      this.quantity,
      this.unit,
      this.unitPrice,
      ProposalDetailRowSubTotalAmount.from(this.quantity, this.unitPrice),
      this.productID,
      this.productNumber,
    );
  }
}

/** 単一商品の明細 */
export type ProposalSingleDetail = Readonly<{
  type: typeof ProductDetailType.SINGLE,
  productID: ProductId,
}>
/** 複数商品（又は明細項目）の明細 */
export type ProposalMultipleDetail = Readonly<{
  type: typeof ProductDetailType.MULTIPLE,
  rows: ProposalDetailRow[],
}>

/** 採択理由詳細 */
export class AdoptedReasonNote {
  constructor(public readonly value: string) {}
}

/** 却下理由詳細 */
export class DeclineReasonNote {
  constructor(public readonly value: string) {}
}

/** 採択理由のリスト */
export type AdoptedReasons = ReadonlyArray<{
  id: AdoptedReasonID,
  name: AdoptedReasonName,
}>

/** 却下理由のリスト */
export type DeclineReasons = ReadonlyArray<{
  id: DeclineReasonID,
  name: DeclineReasonName,
}>

type AdoptedStatusWithReason = {
  type: typeof ProposalStatusType.ADOPTED,
  reasons: AdoptedReasons,
  reasonNote: AdoptedReasonNote,
}
type DeclineStatusWithReason = {
  type: typeof ProposalStatusType.DECLINED,
  reasons: DeclineReasons,
  reasonNote: DeclineReasonNote,
}
export type StatusWithReason = AdoptedStatusWithReason | DeclineStatusWithReason

type OnlyOtherStatus = {
  type: Exclude<
    ProposalStatusType,
    typeof ProposalStatusType.ADOPTED
     | typeof ProposalStatusType.DECLINED
  >
}

/** 提案エンティティ */
export type Proposal = Readonly<{
  id: ProposalID,
  title: ProposalTitle,
  number: ProposalNumber,
  proposalTypeID: ProposalTypeID,
  statusID: ProposalStatusID,
  items: CustomItems,
  detail: ProposalSingleDetail | ProposalMultipleDetail,
  status: StatusWithReason | OnlyOtherStatus
}>
