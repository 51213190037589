/**
 * とあるの処理の結果を表す型。
 * エラーハンドリングに用いる。
 * @param T 成功時の値の型
 * @param E 失敗時の値の型
 */
export type Result<T, E> = Success<T> | Failure<E>

export const ResultType = {
  Success: 'Success',
  Failure: 'Failure',
} as const

type Success<T> = {
  type: typeof ResultType.Success,
  value: T,
}
export function Success<T>(value: T): Result<T, any> {
  return {
    type: ResultType.Success,
    value,
  }
}

type Failure<E> = {
  type: typeof ResultType.Failure,
  error: E,
}
export function Failure<E>(error: E): Result<any, E> {
  return {
    type: ResultType.Failure,
    error,
  }
}