import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { EventAttendeeReply } from "app/model/event/event-attendee";
import { EventBaseStart, EventEnd, EventIDV2, EventNameV2, EventNoteV2, EventPlaceV2, EventRangeType, EventStart, EventUrlV2, EventVersion, ExceptionalEventVersion, EventRecurringRule } from "app/model/event/event-v2";
import { UserID } from "app/model/user/user";
import { Observable } from "rxjs";

export type AddEventInputs = {
  name: EventNameV2,
  start: EventStart,
  end: EventEnd,
  rangeType: EventRangeType,
  recurringRule: EventRecurringRule,
  requiredAttendees: UserID[],
  optionalAttendees: UserID[],
  place: EventPlaceV2,
  url: EventUrlV2,
  note: EventNoteV2,
}

export type UpdateEventInputs = AddEventInputs & {
  id: EventIDV2,
  eventVersion: EventVersion,
}

export type UpdateEventOutputs = {
  baseStart: EventBaseStart,
}

export type DeleteEventInputs = {
  id: EventIDV2,
  eventVersion: EventVersion,
}

export type ExceptionalEventInputs = {
  id: EventIDV2,
  baseStart: EventBaseStart,
  eventVersion: EventVersion,
  exceptionalEventVersion: ExceptionalEventVersion,
  name: EventNameV2,
  start: EventStart,
  end: EventEnd,
  rangeType: EventRangeType,
  requiredAttendees: UserID[],
  optionalAttendees: UserID[],
  place: EventPlaceV2,
  url: EventUrlV2,
  note: EventNoteV2,
}

export type DeleteExceptionalEventInputs = {
  id: EventIDV2,
  baseStart: EventBaseStart,
  eventVersion: EventVersion,
  exceptionalEventVersion: ExceptionalEventVersion,
}

export type ReplyEventInputs = {
  id: EventIDV2,
  baseStart: EventBaseStart,
  eventVersion: EventVersion,
  exceptionalEventVersion?: ExceptionalEventVersion,
  status: EventAttendeeReply,
}

export abstract class EventCommandV2Service {
  abstract add(inputs: AddEventInputs): Observable<Result<
    EventIDV2,
    | typeof GeneralFailure.BadRequest
  >>;

  abstract update(inputs: UpdateEventInputs): Observable<Result<
    UpdateEventOutputs,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.NotFound
  >>;

  abstract delete(inputs: DeleteEventInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>;

  abstract updateExceptionalEvent(inputs: ExceptionalEventInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.NotFound
  >>;

  abstract deleteExceptionalEvent(inputs: DeleteExceptionalEventInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>;

  abstract reply(inputs: ReplyEventInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>;
}
