import { OrganizationID } from 'app/model/organization/organization';
import { FieldType } from '../custom-field/field-type';

export class User {
  constructor(
    public readonly id: UserID,
    public readonly organizationID: OrganizationID,
    public readonly loginID: LoginID,
    public readonly name: DisplayName,
    public readonly mailAddress: MailAddress,
    public readonly accessLevels: UserAccessLevel[]
  ) {
  }

  rename(name: DisplayName): User {
    return new User(this.id, this.organizationID, this.loginID, name, this.mailAddress, this.accessLevels);
  }

  changeMailAddress(mailAddress: MailAddress): User {
    return new User(this.id, this.organizationID, this.loginID, this.name, mailAddress, this.accessLevels);
  }

  reassignRoles(accessLevels: UserAccessLevel[]): User {
    return new User(this.id, this.organizationID, this.loginID, this.name, this.mailAddress, accessLevels);
  }
}

/** UUID */
export class UserID {
  constructor(public readonly value: string) {
  }

  readonly fieldType = FieldType.USER;

  equals(obj: UserID) {
    return this.value === obj.value;
  }
}

/** ログインID */
export class LoginID {
  constructor(public readonly value: string) { }
  static readonly AVAILABLE_CHARACTER: RegExp = /^[a-zA-Z0-9-_\.]{1,255}$/;
}

/** アプリケーション中で実際に表示される名前 */
export class DisplayName {
  constructor(public readonly value: string) { }
  static readonly MAX_LENGTH = 255;
}

export class Password {
  constructor(public readonly value: string) {
  }
}

export const UserAccessLevel = {
  MEMBER: 'MEMBER',
  MANAGER: 'MANAGER',
  SYSTEM_ADMINISTRATOR: 'SYSTEM_ADMINISTRATOR',
} as const;
export type UserAccessLevel = typeof UserAccessLevel[keyof typeof UserAccessLevel];

export class MailAddress {
  constructor(public readonly value: string) { }
  static readonly AVAILABLE_CHARACTER: RegExp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
}

