import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiProposalStatusType } from "./server-proposal-status-api";

type UpdateStatusWithReason = {
  id: string,
  type: Extract<'ADOPTED' | 'DECLINED', ApiProposalStatusType>
  reasonIDs: string[],
  reasonNote: string,
}
type UpdateOtherStatus = {
  id: string,
  type: Exclude<ApiProposalStatusType, 'ADOPTED' | 'DECLINED'>
}

export type UpdateStatusOfProposalRequest = {
  status: UpdateStatusWithReason | UpdateOtherStatus
}

type DetailSingle = {
  type: 'SINGLE',
  productID: string,
}

type DetailMultiple = {
  type: 'MULTIPLE',
  rows: {
    productID: string,
    title: string,
    quantity: number,
    subTotalAmount: number,
  }[],
}

export type CreateProposalRequest = {
  title: string,
  proposalTypeID: string,
  opportunityID: string,
  items: {
    [id: string]: string,
  },
  detail: DetailSingle | DetailMultiple,
}

export type UpdateProposalRequest = {
  title: string,
  items: {
    [id: string]: string,
  },
  detail: DetailSingle | DetailMultiple,
  status: {
    type: Extract<'ADOPTED' | 'DECLINED', ApiProposalStatusType>
    reasonIDs: string[],
    reasonNote: string,
  } | OtherStatus
};

export type SearchProposalsRequest = {
  perPage: number,
  page: number,
  filter: {
    [searchTarget: string]: {
      type: 'anyMatch',
      values: string[],
    },
  },
}

export type SearchProposalsResponse = {
  totalCount: number,
  results: {
    id: string,
    title: string,
    number: number,
    statusID: string,
    statusType: ApiProposalStatusType,
    statusName: string,
    proposalTypeID: string,
    proposalTypeName: string,
    totalAmount: number
  }[]
}

type StatusWithReason = {
  type: Extract<'ADOPTED' | 'DECLINED', ApiProposalStatusType>
  reasons: [
    {
      id: string,
      name: string
    }
  ],
  reasonNote: string
}
type OtherStatus = {
  type: Exclude<ApiProposalStatusType, 'ADOPTED' | 'DECLINED'>
}

export type GetProposalResponse = {
  id: string,
  title: string,
  number: number,
  proposalTypeID: string,
  proposalStatusID: string,
  items: {
    [id: string]: string,
  },
  detail: DetailSingle
  | {
    type: 'MULTIPLE',
    rows: {
      productID: string,
      productNumber: number,
      title: string,
      quantity: number,
      unit: string,
      subTotalAmount: number,
    }[],
  },
  status: StatusWithReason | OtherStatus
}

export class ProposalApi {
  constructor(private readonly http: HttpClient) { }

  updateStatus(id: string, request: UpdateStatusOfProposalRequest): Observable<void> {
    return this.http.patch<void>(`/api/proposals/${id}/status`, request);
  }

  create(request: CreateProposalRequest): Observable<void> {
    return this.http.post<void>(`/api/proposals`, request);
  }

  update(id: string, request: UpdateProposalRequest): Observable<void> {
    return this.http.patch<void>(`/api/proposals/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/proposals/${id}`);
  }

  search(request: SearchProposalsRequest): Observable<SearchProposalsResponse> {
    return this.http.post<SearchProposalsResponse>(`/api/proposals/search`, request);
  }

  get(id: string): Observable<GetProposalResponse> {
    return this.http.get<GetProposalResponse>(`/api/proposals/from-id/${id}`);
  }

  getByNumber(number: number): Observable<GetProposalResponse> {
    return this.http.get<GetProposalResponse>(`/api/proposals/from-number/${number}`);
  }
}
