import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import { ApiSearchFilterCategory } from "./server-search-filter-api";

export type SearchFilterPropertiesResponse = {
  propertyId: `item:${string}`,
  propertyName: string,
  propertyType: 'text' | 'date' | 'number' | 'list' | 'user',
  predicates: (
    {
      type: 'contains' | 'greaterThanOrEqualTo' | 'lessThanOrEqualTo' | 'empty',
      valueType: 'text' | 'date' | 'number',
    } |
    {
      type: 'anyMatch',
      valueType: 'list' | 'user',
      listValues?: string[],
    }
  )[]
}[];

export class SearchFilterPropertyApi {
  constructor(
    private readonly httpClient: HttpClient
  ) {}

  list(category: ApiSearchFilterCategory): Observable<SearchFilterPropertiesResponse> {
    return this.httpClient.get<SearchFilterPropertiesResponse>(`/api/filter-properties/${category}`);
  }
}
