import {Observable} from "rxjs";
import {DeclineReason, DeclineReasonID} from "app/model/decline-reason/decline-reason";

export type DeclineReasonList = DeclineReason[];
export abstract class DeclineReasonQueryService {

  abstract list(): Observable<DeclineReasonList>;

  abstract get(id: DeclineReasonID): Observable<DeclineReason>;
}
