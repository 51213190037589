import { ConsultantCommandService } from "#application/services/consultant-command.service";
import { ConsultantQueryService } from "#application/services/consultant-query.service";
import { Page, PageNavigateService } from "#application/services/page-navigate.service";
import { AssignedOrganization, AssignedOrganizationType, Consultant, ConsultantID, DisplayName, MailAddress, ConsultantLoginID } from "app/model/consultant/consultant";
import { OrganizationID } from "app/model/organization/organization";
import { assert } from "#utils/assert";
import { Component } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { assignedOrgTypeOptions } from "../assigned-organization/assigned-organization";

@Component({
  selector: 'sn-consultant-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class ConsultantDetailComponent {

  readonly loginIDControl = new UntypedFormControl(
    {
      value: '',
      disabled: true,
    },
    [
      Validators.required,
      Validators.pattern(ConsultantLoginID.availableCharacter),
    ]
  );

  readonly displayNameControl = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(DisplayName.maxLength),
  ]);

  readonly mailAddressControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(MailAddress.availableCharacter),
  ]);

  selectedAssignedOrgType?: AssignedOrganizationType;
  readonly assignedOrgTypeOptions = assignedOrgTypeOptions;

  readonly consultantID: ConsultantID;

  consultant?: Consultant;

  savedAssignedOrgs?: OrganizationID[];

  selectedOrgs: OrganizationID[] = [];

  constructor(
    private readonly consultantCommandService: ConsultantCommandService,
    private readonly consultantQueryService: ConsultantQueryService,
    private readonly route: ActivatedRoute,
    private readonly pageNavigateService: PageNavigateService,
    ) {
    const consultantID = this.route.snapshot.paramMap.get('consultantID');
    assert(consultantID != null);
    this.consultantID = new ConsultantID(consultantID);

    this.consultantQueryService.get(this.consultantID).subscribe(consul => {
      this.consultant = consul;
      this.loginIDControl.setValue(consul.loginID.value);
      this.displayNameControl.setValue(consul.displayName.value);
      this.mailAddressControl.setValue(consul.mailAddress.value);
      this.selectedAssignedOrgType = consul.assignedOrganization.type;
      this.savedAssignedOrgs = consul.assignedOrganization.list;
      if (consul.assignedOrganization.list === undefined) return;
      this.selectedOrgs = consul.assignedOrganization.list;
    });
  }

  private _touchValidators(): void {
    this.loginIDControl.markAsTouched();
    this.displayNameControl.markAsTouched();
    this.mailAddressControl.markAsTouched();
  }

  onSelectedOrgs(event: OrganizationID[]): void {
    this.selectedOrgs = event;
  }

  onUpdate(): void {
    this._touchValidators();
    if (this.consultant === undefined) return;
    if (this.loginIDControl.invalid) return;
    if (this.displayNameControl.invalid) return;
    if (this.mailAddressControl.invalid) return;
    if (this.selectedAssignedOrgType === undefined) return;
    if (
      this.selectedAssignedOrgType === AssignedOrganizationType.SELECTED
      && this.selectedOrgs.length === 0
    ) return;

    const consultant = this.consultant
      .changeDisplayName(new DisplayName(this.displayNameControl.value))
      .changeMailAddress(new MailAddress(this.mailAddressControl.value))
      .changeAssignedOrganization(
        new AssignedOrganization(
          this.selectedAssignedOrgType,
          this.selectedOrgs.length === 0
          ? undefined
          : this.selectedOrgs
        )
      );

    this.consultantCommandService.update(consultant).subscribe();
  }

  onDelete(): void {
    this.consultantCommandService.delete(this.consultantID).subscribe();
  }

  onResetPassword(): void {
    this.consultantCommandService.resetPassword(this.consultantID).subscribe();
  }

  onCancel(): void {
    this.pageNavigateService.navigate(Page.AdminConsultant);
  }
}
