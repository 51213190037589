import { DisplayItems } from "#presentation/pages/activity/shared/display-item";

export const SearchFilterCategory = {
  OPPORTUNITY: 'opportunity',
  OPPORTUNITY_GROUP: 'opportunity_group',
  CLIENT: 'client',
  PRODUCT: 'product',
  MEETING_BOARD_SETTING: 'meeting_board_setting',
  OPPORTUNITY_GROUP_OPPORTUNITY: 'opportunity_group_opportunity'  // 商談グループ内の商談フィルタ用
} as const;
export type SearchFilterCategory =
  typeof SearchFilterCategory[keyof typeof SearchFilterCategory];

export const FilterPredicateType = {
  contains: 'contains',
  greaterThanOrEqualTo: 'greaterThanOrEqualTo',
  lessThanOrEqualTo: 'lessThanOrEqualTo',
  anyMatch: 'anyMatch',
  equals: 'equals',
  empty: 'empty',
  range: 'range',
  between: 'between',
 } as const;
export type FilterPredicateType =
  typeof FilterPredicateType [keyof typeof FilterPredicateType];

export type FilterPredicate = Readonly<{
  type: typeof FilterPredicateType.greaterThanOrEqualTo
    | typeof FilterPredicateType.lessThanOrEqualTo
    | typeof FilterPredicateType.equals
    | typeof FilterPredicateType.empty,
  value: string,
} | {
  type: typeof FilterPredicateType.anyMatch,
  value: string[],
} | {
  type: typeof FilterPredicateType.contains,
  value: string,
  condition: Condition
} | {
  type: typeof FilterPredicateType.range,
  from: string,
  range: number,
  bounds: Bounds,
} | {
  type: typeof FilterPredicateType.between,
  from?: number,
  to?: number,
  bounds: Bounds,
}>;

export class SearchFilter {
  constructor(
    public readonly id: SearchFilterId,
    public readonly name: SearchFilterName,
    public readonly description: SearchFilterDescription,
    public readonly visibility: SearchFilterVisibility,
    public readonly editability: SearchFilterEditability,
    public readonly filter: FilterCondition[],
    public readonly sorts: SortConditions,
    public readonly displayItems?: DisplayItems,
  ) {}
}

export class SearchFilterId {
  constructor(public readonly value: string) {}
}

export class SearchFilterName {
  static readonly MAX_LENGTH = 50;
  constructor(public readonly value: string) {}
}

export class SearchFilterDescription {
  static readonly MAX_LENGTH = 200;
  constructor(public readonly value: string) {}
}

export class SearchFilterVisibility {
  constructor(
    public readonly type: SearchFilterPermission,
  ) {}
}

export class SearchFilterEditability {
  constructor(
    public readonly type: SearchFilterPermission,
  ) {}
}

export type FilterCondition = Readonly<{
  propertyId: string,
  type: typeof FilterPredicateType.greaterThanOrEqualTo
    | typeof FilterPredicateType.lessThanOrEqualTo
    | typeof FilterPredicateType.equals
    | typeof FilterPredicateType.empty,
  value: string,
} | {
  propertyId: string,
  type: typeof FilterPredicateType.anyMatch,
  value: string[],
} | {
  propertyId: string,
  type: typeof FilterPredicateType.contains,
  value: string,
  condition: Condition
} | {
  propertyId: string,
  type: typeof FilterPredicateType.range,
  from: string,
  range: number,
  bounds: Bounds,
} | {
  propertyId: string,
  type: typeof FilterPredicateType.between,
  from?: number,
  to?: number,
  bounds: Bounds,
}>;

export type Condition =
  | 'and'
  | 'or'

export type Bounds =
  | 'INCLUSIVE_EXCLUSIVE'
  | 'INCLUSIVE_INCLUSIVE'
  | 'EXCLUSIVE_INCLUSIVE'
  | 'EXCLUSIVE_EXCLUSIVE'

export type SortCondition = Readonly<{
  name: string,
  order: 'ASC' | 'DESC',
  nulls: 'FIRST' | 'LAST',
}>;
export type SortConditions = ReadonlyArray<SortCondition>;

export const SearchFilterPermissionValue = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
} as const;

export type SearchFilterPermission = keyof typeof SearchFilterPermissionValue;

export class SearchFilterBookmarkCount {
  constructor(
    public readonly value: number,
  ) {}
}
