import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type NurturingHearingItemResponse = {
  customFieldID: string,
  customFieldName: string,
  customFieldDescription: string,
  customFieldType: string,
  required: boolean,
  selectSetting?: {
    multiple: boolean,
    allowInput: boolean,
    options: string[],
  },
  numberSetting?: {
    min: number,
    max: number,
    defaultValue: number,
    unit: string,
  },
};

export type GetNurturingHearingSettingResponse = {
  nurturingTypeID: string,
  beforeHearingItems: NurturingHearingItemResponse[],
  afterHearingItems: NurturingHearingItemResponse[],
};

export type SaveNurturingHearingSettingRequest = {
  nurturingTypeID: string,
  requestBody: {
    beforeHearing: string[],
    afterHearing: string[],
  },
};

export class NurturingHearingSettingApi {
  constructor(private readonly http: HttpClient) {}

  get(nurturingTypeID: string): Observable<GetNurturingHearingSettingResponse> {
    const url = `/api/nurturing-types/${nurturingTypeID}/hearing-setting`;
    return this.http.get<GetNurturingHearingSettingResponse>(url);
  }

  save(request: SaveNurturingHearingSettingRequest): Observable<void> {
    const url = `/api/nurturing-types/${request.nurturingTypeID}/hearing-setting`;
    return this.http.post<void>(url, request.requestBody);
  }
}
