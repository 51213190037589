import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { MeetingBoardSettingDescription, MeetingBoardSettingID, MeetingBoardSettingName } from "app/model/meeting-board/meeting-board";
import { MeetingBoardSettingFilterCondition } from "app/model/meeting-board/meeting-board-setting-filter-conditon";
import { SearchFilterPermission } from "app/model/search-filter/search-filter";
import { UserID } from "app/model/user/user";
import { Observable } from "rxjs";

export type SaveMeetingBoardSettingInput = {
  meetingBoardSettingID?: MeetingBoardSettingID,
  name: MeetingBoardSettingName,
  description: MeetingBoardSettingDescription,
  visibility: SearchFilterPermission,
  editability: SearchFilterPermission,
  headerCondition: {
    isDisplayed: {
      wonAmount: boolean,
      wonCount: boolean,
      lostAmount: boolean,
      lostCount: boolean,
      opportunityWonCount: boolean,
      actualAmount: boolean,
      salesRecordingAmount: boolean,
    },
    displayUserIDs: UserID[],
  },
  filterCondition: MeetingBoardSettingFilterCondition,
};

export abstract class MeetingBoardSettingCommandService {
  abstract save(input: SaveMeetingBoardSettingInput): Observable<Result<
    MeetingBoardSettingID,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >>;

  abstract delete(id: MeetingBoardSettingID): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >>;

  abstract bookmark(id: MeetingBoardSettingID): Observable<Result<
    void,
    | typeof GeneralFailure.NotFound
  >>;

  abstract unbookmark(id: MeetingBoardSettingID): Observable<Result<
    void,
    | typeof GeneralFailure.NotFound
  >>;
}
