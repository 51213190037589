import {ActionTypeRepository, UpdateActionTypeParam} from 'app/model/action/action-type.repository';
import {Observable} from 'rxjs';
import {ActionType, ActionTypeDescription, ActionTypeID, ActionTypeName, ActionTypes} from 'app/model/action/action-type';
import {ServerApi} from '#infrastructure/api/server-api';
import {Injectable} from '@angular/core';
import {ListActionTypeData} from '#infrastructure/api/server-action-type-api';
import {map} from 'rxjs/operators';

const convertFromResponse = (data: ListActionTypeData): ActionType => new ActionType(
  new ActionTypeID(data.id),
  new ActionTypeName(data.name),
  new ActionTypeDescription(data.description),
);

@Injectable({providedIn: 'root'})
export class ActionTypeRepositoryImpl extends ActionTypeRepository {
  constructor(
    private readonly serverApi: ServerApi
  ) {
    super();
  }

  findAll(): Observable<ActionTypes> {
    return this.serverApi.actionTypeApi.list().pipe(
      map(actionTypes => actionTypes.map(convertFromResponse)),
      map(actionTypes => new ActionTypes(actionTypes))
    );
  }

  save(param: UpdateActionTypeParam): Observable<void> {
    return this.serverApi.actionTypeApi.update(
      param.id.value,
      {
        name: param.name.value,
        description: param.description.value,
      }
    );
  }

  remove(id: ActionTypeID): Observable<void> {
    return this.serverApi.actionTypeApi.delete(id.value);
  }

  saveOrders(ids: ActionTypeID[]): Observable<void> {
    const request = {ids: ids.map(id => id.value)};
    return this.serverApi.actionTypeApi.sort(request);
  }
}
