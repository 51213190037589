import {ProductFieldQueryService} from '#application/services/product-field-query.service';
import {
  CustomFieldRequired,
  CustomField,
  CustomFieldDescription,
  CustomFieldId,
  CustomFieldName,
  CustomFieldNumberUnit,
  CustomFieldNumberValue,
  NumberSettings,
  SelectItemValue,
  SelectSettings,
} from 'app/model/custom-field/custom-field';
import {FieldType} from 'app/model/custom-field/field-type';
import {Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {ServerApi} from "#infrastructure/api/server-api";
import {map} from "rxjs/operators";
import {
  ApiCustomFieldNumberSettingData,
  ApiCustomFieldSelectSettingData,
  GetCustomItemResponse,
  ListCustomItemResponse
} from "#infrastructure/api/server-custom-field-api";
import { CustomFieldAlias } from '#application/services/custom-field-query.service';

const getResponseToCustomField = (response: GetCustomItemResponse): CustomField => {
  return new CustomField(
    new CustomFieldId(response.id),
    new CustomFieldName(response.name),
    FieldType[response.type],
    response.required,
    new CustomFieldDescription(response.description),
    response.numberSetting
      ? convertApiDataToNumberSettingsDomain(response.numberSetting)
      : undefined,
    response.selectSetting
      ? convertApiDataToSelectSettingsDomain(response.selectSetting)
      : undefined
  );
}

export const convertApiDataToNumberSettingsDomain = (data: ApiCustomFieldNumberSettingData) => {
  return new NumberSettings(
    data.min || data.min === 0 ? new CustomFieldNumberValue(data.min) : undefined,
    data.max || data.max === 0 ? new CustomFieldNumberValue(data.max) : undefined,
    data.defaultValue || data.defaultValue === 0 ? new CustomFieldNumberValue(data.defaultValue) : undefined,
    data.unit ? new CustomFieldNumberUnit(data.unit) : undefined,
  );
};

export const convertApiDataToSelectSettingsDomain = (data: ApiCustomFieldSelectSettingData) => {
  return new SelectSettings(
    data.multiple,
    data.allowInput,
    data.options.map(option => new SelectItemValue(option))
  );
};

const listResponseToOutline = (response: ListCustomItemResponse): CustomFieldAlias[] => {
  return response
    .map(listItem => ({
      id: new CustomFieldId(listItem.id),
      name: new CustomFieldName(listItem.name),
      description: new CustomFieldDescription(listItem.description),
      type: FieldType[listItem.type],
      required: new CustomFieldRequired(listItem.required),
      numberSettings: listItem.numberSetting === undefined
        ? undefined
        : new NumberSettings(
          listItem.numberSetting?.min ? new CustomFieldNumberValue(listItem.numberSetting.min) : undefined,
          listItem.numberSetting?.max ? new CustomFieldNumberValue(listItem.numberSetting.max) : undefined,
          listItem.numberSetting?.defaultValue ? new CustomFieldNumberValue(listItem.numberSetting.defaultValue) : undefined,
          listItem.numberSetting?.unit ? new CustomFieldNumberUnit(listItem.numberSetting.unit) : undefined,
        ),
      selectSettings: listItem.selectSetting === undefined
        ? undefined
        : new SelectSettings(
          listItem.selectSetting.multiple,
          listItem.selectSetting.allowInput,
          listItem.selectSetting.options.map(option => new SelectItemValue(option)
        )
      ),
    }));
};

@Injectable({providedIn: 'root'})
export class ProductFieldQueryServiceImpl implements ProductFieldQueryService {
  constructor(
    private readonly serverApi: ServerApi
  ) {
  }

  get(id: CustomFieldId): Observable<CustomField> {
    return this.serverApi.customFieldApi.get('product', id.value)
      .pipe(
        map(getResponseToCustomField)
      );
  }

  list(): Observable<CustomFieldAlias[]> {
    return this.serverApi.customFieldApi.list('product')
      .pipe(
        map(listResponseToOutline)
      );
  }
}
