import {
  FormLayoutCategory,
  FormLayoutId,
} from 'app/model/form-layout/form-layout';
import { Observable } from 'rxjs';

export interface ImportType {
  typeId: string;
  typeName: string;
  category: FormLayoutCategory;
  layoutId: FormLayoutId | undefined;
}
export type ImportTypes = ImportType[];

export type ApprovedCsvEncoding = 'UTF-8' | 'Shift-JIS';

export type ApprovedDelimiter = ',' | '\t';

export type CsvImportParam = {
  fileName: string;
  type: ImportType;
  formatDefinition: CsvFileFormat;
  mapping: string[];
  base64encodedCsvFile: string;
  uniqueFieldId?: string;
};

export interface CsvFileFormat {
  encoding: ApprovedCsvEncoding;
  delimiter: ApprovedDelimiter;
  dateFormat: string;
  dateTimeFormat: string;
}

export interface CsvFieldMappingItem {
  columnName: string;
  fieldValue: string;
  userAsKey: boolean;
}

export type CsvFieldMapping = CsvFieldMappingItem[];

export abstract class CsvImportService {
  abstract getImportTypes(): Observable<ImportTypes>;
  abstract importCsv(param: CsvImportParam): Observable<void>;
  abstract saveLastFileFormat(format: CsvFileFormat): void;
  abstract getLastFileFormat(): CsvFileFormat | null;
  abstract saveFieldMapping(mapping: CsvFieldMapping): void;
  abstract getLastFieldMapping(): CsvFieldMapping | null;
}
