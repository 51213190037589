import { HintDetail, SearchHintInputs, SearchHintListResult } from "#application/services/base-hint-query.service";
import { CommonHintQueryService } from "#application/services/common-hint-query.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { HintContent, HintID, HintTitle } from "app/model/hint/hint";
import { ServerApi } from "#infrastructure/api/server-api";
import { SearchCommonHintRequest } from "#infrastructure/api/server-common-hint-api";
import { Target } from "#utils/messages";
import { Injectable, inject } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class CommonHintQueryServiceImpl implements CommonHintQueryService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _messageDialogService = inject(MessageDialogService);

  search(inputs: SearchHintInputs): Observable<SearchHintListResult> {
    const request: SearchCommonHintRequest = {
      perPage: inputs.perPage,
      page: inputs.page,
      filter: inputs.filter,
    };
    return this._serverApi.commonHintApi.search(request)
    .pipe(
      map(v => ({
        totalCount: v.totalCount,
        results: v.results.map(r => ({
          id: new HintID(r.id),
          title: new HintTitle(r.title),
          good: r.good,
          bad: r.bad
        }))
      })),
      catchError(e => {
        this._messageDialogService.notice({ errorTarget: `${Target.COMMON_HINT}の検索・取得` });
        return throwError(e);
      })
    );
  }

  get(id: HintID): Observable<HintDetail> {
    return this._serverApi.commonHintApi.get(id.value)
    .pipe(
      map(v => ({
        id: new HintID(v.id),
        title: new HintTitle(v.title),
        content: new HintContent(v.content),
      })),
      catchError(e => {
        this._messageDialogService.notice({ errorTarget: `${Target.COMMON_HINT}の取得` });
        return throwError(e);
      })
    );
  }
}
