import {Observable} from 'rxjs';
import {ActionTypeDescription, ActionTypeID, ActionTypeName, ActionTypes} from 'app/model/action/action-type';

export interface UpdateActionTypeParam {
  id: ActionTypeID,
  name: ActionTypeName,
  description: ActionTypeDescription,
}

export abstract class ActionTypeRepository {
  abstract findAll(): Observable<ActionTypes>;

  abstract save(param: UpdateActionTypeParam): Observable<void>;

  abstract remove(id: ActionTypeID): Observable<void>;

  abstract saveOrders(ids: ActionTypeID[]): Observable<void>;
}
