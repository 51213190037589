import { Observable } from "rxjs";

import { HttpClient } from "@angular/common/http";

export interface OrganizationData {
    id: string;
    code: string;
    name: string;
    fiscalYearBegin: number;
    isCoachingAvailable: boolean;
    isProposalEnabled: boolean;
    isLinkageEnabled: boolean;
    isOpportunityGroupEnabled: boolean;
}

export interface CreateOrganizationRequest {
    code: string;
    name: string;
    fiscalYearBegin: number;
}

export type GetOrganizationListResponse = OrganizationData[];

export type GetOrganizationResponse = OrganizationData;

export interface UpdateOrganizationRequest {
    name: string
    fiscalYearBegin: number;
    isCoachingAvailable: boolean;
    isProposalEnabled: boolean;
    isLinkageEnabled: boolean;
    isOpportunityGroupEnabled: boolean;
}

export class OrganizationApi {
    constructor(private readonly http: HttpClient) {
    }

    create(organization: CreateOrganizationRequest): Observable<void> {
        return this.http.post<void>('/api/organizations', organization);
    }

    list(): Observable<GetOrganizationListResponse> {
        return this.http.get<OrganizationData[]>('/api/organizations');
    }

    get(organizationID: string): Observable<GetOrganizationResponse> {
        return this.http.get<OrganizationData>(`/api/organizations/${organizationID}`);
    }

    delete(organizationID: string): Observable<void> {
        return this.http.delete<void>(`/api/organizations/${organizationID}`);
    }

    update(organizationID: string, request: UpdateOrganizationRequest): Observable<void> {
        return this.http.put<void>(`/api/organizations/${organizationID}`, request);
    }
}
