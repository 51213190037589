import { ClientCsvImportHistoriesQueryService } from '#application/services/client-csv-import-histories-query.service';
import {
  CsvImportHistories,
  CsvImportErrors,
} from '#application/services/csv-import-histories-query.service';
import { DisplayName, UserID } from 'app/model/user/user';
import { ServerApi } from '#infrastructure/api/server-api';
import {
  CsvImportErrorResponse,
  CsvImportHistoriesResponse,
} from '#infrastructure/api/server-csv-import-histories-api';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ClientCsvImportHistoriesQueryServiceImpl extends ClientCsvImportHistoriesQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  getCsvImportHistories(
    perPage: number,
    page: number
  ): Observable<CsvImportHistories> {
    return this.serverApi.csvImportHistoriesApi
      .list('client', perPage, page)
      .pipe(map((response) => convertToClientCsvImportHistories(response)));
  }
  getCsvImportErrors(
    clientCSVImportHistoryID: string,
    perPage: number,
    page: number
  ): Observable<CsvImportErrors> {
    return this.serverApi.csvImportHistoriesApi
      .errorList(clientCSVImportHistoryID, perPage, page)
      .pipe(map((response) => convertToClientCsvImportErrors(response)));
  }
}

const convertToClientCsvImportErrors = (
  response: CsvImportErrorResponse
): CsvImportErrors => {
  return {
    totalCount: response.totalCount,
    results: response.results.map((v) => {
      return {
        rowNo: v.csvRowNumber,
        error: v.error,
      };
    }),
  };
};

const convertToClientCsvImportHistories = (
  response: CsvImportHistoriesResponse
): CsvImportHistories => {
  return {
    totalCount: response.totalCount,
    results: response.results.map((v) => {
      return {
        id: v.id,
        csvFileName: v.csvFileName,
        userId: new UserID(v.userId),
        userName: new DisplayName(v.userName),
        importStartDateTime: DateTime.fromISO(v.importStartDateTime),
        importEndDateTime: DateTime.fromISO(v.importEndDateTime),
        importDataCount: v.importDataCount,
        importSucceedDataCount: v.importSucceedDataCount,
        importFailedDataCount: v.importFailedDataCount,
      };
    }),
  };
};
