import {FieldType} from './field-type';

export const CustomFieldCategory = {
  CLIENT: 'client',
  OPPORTUNITY: 'opportunity',
  OPPORTUNITY_GROUP: 'opportunity_group',
  PRODUCT: 'product',
  PROPOSAL: 'proposal',
  NURTURING: 'nurturing',
} as const;

export type CustomFieldCategory =
  typeof CustomFieldCategory[keyof typeof CustomFieldCategory];

/**
 * 項目ID
 */
export class CustomFieldId {
  constructor(public readonly value: string) {
  }

  equals(obj: CustomFieldId): boolean {
    return obj.value === this.value;
  }
}

/**
 * 項目名
 */
export class CustomFieldName {
  static readonly MAX_LENGTH = 30

  constructor(public readonly value: string) {
  }
}

/**
 * 項目説明
 */
export class CustomFieldDescription {
  static readonly MAX_LENGTH = 100

  constructor(public readonly value: string) {
  }
}

/**
 * 必須
 */
export class CustomFieldRequired {
  constructor(public readonly value: boolean) {
  }
}

export class CustomFieldNumberValue {
  static readonly MIN_VALUE = -999999999999;
  static readonly MAX_VALUE = 999999999999;

  constructor(public readonly value: number) {
  }

  get valueAsLocalString() {
    return this.value.toLocaleString();
  }
}

export class CustomFieldNumberUnit {
  static readonly MAX_LENGTH = 10

  constructor(public readonly value: string) {
  }
}


/**
 * 数値項目設定
 */
export class NumberSettings {
  constructor(
    public readonly min: CustomFieldNumberValue | undefined,
    public readonly max: CustomFieldNumberValue | undefined,
    public readonly defaultValue: CustomFieldNumberValue | undefined,
    public readonly unit: CustomFieldNumberUnit | undefined
  ) {
  }
}

export class SelectItemValue {
  /** 選択肢の文字数の上限 */
  static readonly MAX_SELECT_ITEM_VALUE_LENGTH = 100;

  constructor(public readonly value: string) {
  }
}

/**
 * 選択項目設定
 */
export class SelectSettings {
  /** 選択肢の数の下限 */
  static readonly MIN_SELECT_ITEM_COUNT = 1;
  /** 選択肢の数の上限 */
  static readonly MAX_SELECT_ITEM_COUNT = 100;

  constructor(
    public readonly multiple: boolean,
    public readonly allowInput: boolean,
    public readonly options: SelectItemValue[]
  ) {
  }
}

/**
 * カスタム項目の設定値
 */
export class CustomField {
  constructor(
    public readonly id: CustomFieldId,
    public readonly name: CustomFieldName,
    public readonly type: FieldType,
    public readonly required: boolean,
    public readonly description: CustomFieldDescription,
    public readonly numberSettings?: NumberSettings,
    public readonly selectSettings?: SelectSettings
  ) {
  }
}

/**
 * 商品・商談・顧客・提案のカスタム項目の（保存）値
 */
export class CustomFieldValue {
  constructor(public readonly value: string) {
  }
}

/**
 * 各管理カテゴリー（商談、顧客、商品、提案）ごとに
 * 集約が保持するカスタム項目のIDと値のセット。
 */
export type CustomItems = Map<string, CustomFieldValue>;

export namespace CustomItems {
  /**
   * カスタム項目IDと値のセットを、それぞれ文字列に変換して同構造のオブジェクトを返す。
   *
   * @param items カスタム項目ID・値のMapオブジェクト
   * @returns 項目IDのvalueをキーに、項目値のvalueを値に持つオブジェクト
   */
  export const convertToRawKVObj =
  (items: CustomItems): { [id: string]: string } => {
    return [...items].reduce((p, [k, v]) => {
      return Object.assign(p, { [`${k}`]: v.value });
    }, {});
  }

  /**
   *
   * @param items keyにIDの文字列を、valueに値の文字列を持つオブジェクト
   * @returns カスタム項目ID・値のMapオブジェクト
   */
  export const convertFromRawKVObj =
  (items: { [id: string]: string }): CustomItems => {
    return Object.entries(items).reduce((p, [k, v]) => {
      return p.set(k, new CustomFieldValue(v));
    }, new Map<string, CustomFieldValue>());
  }
}
