import { Observable } from "rxjs";
import { FilterCondition, SearchFilterCategory, SearchFilterDescription, SearchFilterEditability, SearchFilterId, SearchFilterName, SearchFilterPermission, SearchFilterVisibility, SortConditions } from "./search-filter";
import { DisplayItems } from "#presentation/pages/activity/shared/display-item";

export interface CreateSearchFilterParam{
  category: SearchFilterCategory,
  filterName: SearchFilterName,
  description: SearchFilterDescription,
  visibility: SearchFilterVisibility,
  editability: SearchFilterEditability,
  filter: FilterCondition[],
  sorts: SortConditions,
  displayItems?: DisplayItems,
}

export abstract class SearchFilterFactory {
  abstract create(param: CreateSearchFilterParam): Observable<void>;

  abstract createBookmark(category: SearchFilterCategory, id: SearchFilterId): Observable<void>;
}
