import {CustomFieldRepository} from "app/model/custom-field/custom-field.repository";
import {Injectable} from "@angular/core";
import {CustomField, CustomFieldCategory, CustomFieldId} from "app/model/custom-field/custom-field";
import {Observable} from "rxjs";
import {ServerApi} from "#infrastructure/api/server-api";

@Injectable({providedIn: 'root'})
export class CustomFieldRepositoryImpl extends CustomFieldRepository {
  constructor(
    private readonly serverApi: ServerApi,
  ) {
    super();
  }

  save(category: CustomFieldCategory, customField: CustomField): Observable<void> {
    return this.serverApi.customFieldApi.update(
      category,
      customField.id.value,
      {
        name: customField.name.value,
        description: customField.description.value,
        type: customField.type,
        required: customField.required,
        numberSetting:
          customField.numberSettings
          ? {
              min: customField.numberSettings.min?.value,
              max: customField.numberSettings.max?.value,
              defaultValue: customField.numberSettings.defaultValue?.value,
              unit: customField.numberSettings.unit?.value
            }
          : undefined,
        selectSetting:
          customField.selectSettings
          ? {
              multiple: customField.selectSettings.multiple,
              allowInput: customField.selectSettings.allowInput,
              options: customField.selectSettings.options.map(option => option.value)
            }
          : undefined
      }
    )
  }

  remove(id: CustomFieldId, category: CustomFieldCategory): Observable<void> {
    return this.serverApi.customFieldApi.delete(category, id.value);
  }

  saveOrders(category: CustomFieldCategory, idList: CustomFieldId[]): Observable<void> {
    const request = { ids: idList.map(id => id.value) };
    return this.serverApi.customFieldApi.sort(category, request);
  }
}
