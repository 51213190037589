import {
  ClientProductRelationshipFactory
} from "app/model/client-product-relationship/client-product-relationship.factory";
import {ClientID} from "app/model/client/client";
import {ProductId} from "app/model/product/product";
import {Observable} from "rxjs";
import {ServerApi} from "#infrastructure/api/server-api";
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class ClientProductRelationshipFactoryImpl extends ClientProductRelationshipFactory {

  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  create(
    clientID: ClientID,
    productID: ProductId
  ): Observable<void> {
    return this.serverApi.clientProductRelationshipApi.create({
      clientID: clientID.value,
      productID: productID.value
    });
  }

}
