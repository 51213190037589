import {
  ContactID,
  ContactNumber,
  DepartmentName,
  EmailInfoList,
  ContactDisplayName,
  FirstName,
  FirstNameKana,
  fullNameKana,
  LastName,
  LastNameKana,
  DisplayName,
  Note,
  PhoneNumberInfoList,
  ContactRoleName,
  Sex, CreatedBy, UpdatedBy
} from "app/model/contact/contact";
import {ClientID, ClientName, ClientNumber} from "app/model/client/client";
import {Observable} from "rxjs";
import { FilterPredicateType } from "app/model/search-filter/search-filter";
import { ClientCategory, ClientTypeName } from "app/model/client-info-type/client-type";

/**
 * 担当者の一覧
 */
 export class ContactList {
  constructor(public readonly list: ContactListItem[]) {
  }

  isEmpty(): boolean {
    return this.list.length === 0;
  }
}

/**
 * 担当者
 */
export class ContactListItem {
  constructor(
    public readonly id: ContactID,
    public readonly contactNumber: ContactNumber,
    public readonly departmentName: DepartmentName,
    public readonly roleName: ContactRoleName,
    public readonly displayName: ContactDisplayName,
    public readonly lastName: LastName,
    public readonly firstName: FirstName,
    public readonly lastNameKana: LastNameKana,
    public readonly firstNameKana: FirstNameKana,
    public readonly sex: Sex,
    public readonly phoneNumberInfoList: PhoneNumberInfoList,
    public readonly emailInfoList: EmailInfoList,
    public readonly note: Note
  ) {
  }
  /**
   * フルネーム（カナ）取得処理
   *
   * @param separator
   * @returns
   */
  getFullNameKana(separator: string = ' '): string {
    return fullNameKana(this.lastNameKana, this.firstNameKana);
  }
}

export type ContactDetail = {
  id: ContactID,
  client: {
    id: ClientID,
    name: ClientName,
    number: ClientNumber,
    category: ClientCategory,
  } | undefined,
  contactNumber: ContactNumber,
  departmentName: DepartmentName,
  roleName: ContactRoleName,
  displayName: ContactDisplayName,
  lastName: LastName,
  firstName: FirstName,
  lastNameKana: LastNameKana,
  firstNameKana: FirstNameKana,
  sex: Sex,
  phoneNumberInfoList: PhoneNumberInfoList,
  emailInfoList: EmailInfoList,
  note: Note,
  createdBy: CreatedBy | undefined,
  updatedBy: UpdatedBy | undefined,
};

export type SearchContactInputs = {
  perPage: number,
  page: number,
  filter: {
    contact?: {
      type: typeof FilterPredicateType.contains,
      value: string,
    },
    contactNumber?: {
      type: typeof FilterPredicateType.equals,
      value: string,
    },
    opportunityID?: {
      type: typeof FilterPredicateType.equals | typeof FilterPredicateType.anyMatch,
      value: string | string[],
    },
    clientID?: {
      type: typeof FilterPredicateType.equals | typeof FilterPredicateType.anyMatch,
      value: string | string[],
    },
  },
};

export type ContactListBySearch = {
  totalCount: number;
  results: {
    id: ContactID,
    client: {
      id: ClientID,
      name: ClientName,
      category: ClientCategory,
    } | undefined,
    relatedClientCount: number,
    contactNumber: ContactNumber,
    departmentName: DepartmentName,
    roleName: ContactRoleName,
    lastName: LastName,
    firstName: FirstName,
    lastNameKana: LastNameKana,
    firstNameKana: FirstNameKana,
    displayName: DisplayName,
    sex: Sex,
    phoneNumberInfoList: PhoneNumberInfoList,
    emailInfoList: EmailInfoList,
    note: Note,
  }[],
};

export type ClientRelatedWithContactList = {
  contactID: ContactID,
  clientID: ClientID,
  isMain: boolean,
  clientNumber: ClientNumber,
  clientName: ClientName,
  clientCategory: ClientCategory,
  clientTypeName: ClientTypeName,
}[]

export abstract class ContactQueryService {

  abstract listBy(clientID: ClientID): Observable<ContactList>;

  abstract get(id: ContactID): Observable<ContactDetail>;

  abstract findBy(inputs: SearchContactInputs): Observable<ContactListBySearch>;

  abstract listRelatedClient(id: ContactID): Observable<ClientRelatedWithContactList>;
}
