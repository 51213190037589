/**
 * 採択理由ID
 */
export class AdoptedReasonID {
  constructor(public readonly value: string) {}
}

/**
 * 採択理由名
 */
export class AdoptedReasonName {
  static readonly MAX_LENGTH = 30;
  constructor(public readonly value: string) {}
}

/**
 * 採択理由説明
 */
export class AdoptedReasonDescription {
  static readonly MAX_LENGTH = 100;
  constructor(public readonly value: string) {}
}

/**
 * 採択理由
 */
export type AdoptedReason = Readonly<{
  id: AdoptedReasonID,
  name: AdoptedReasonName,
  description: AdoptedReasonDescription,
}>
