import { HttpClient } from "@angular/common/http"
import { Observable } from "rxjs"

export type ListClientNurturingResponse = {
  id: string,
  title: string,
  status: 'UNREPORTED' | 'REPORTED',
  duration: {
    startDateTime: string,
    minutes: number
  },
  nurturingType: {
    id: string,
    name: string,
  },
  owner: {
    id: string,
    name: string,
  },
}[]

export class ClientNurturingApi {
  constructor(private readonly http: HttpClient) {}

  list(clientID: string): Observable<ListClientNurturingResponse> {
    return this.http.get<ListClientNurturingResponse>(`/api/clients/${clientID}/nurturings/list`);
  }
}
