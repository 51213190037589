import {Injectable} from '@angular/core';
import {
  CustomFieldRequired,
  CustomField,
  CustomFieldDescription,
  CustomFieldId,
  CustomFieldName,
  CustomFieldNumberUnit,
  CustomFieldNumberValue,
  NumberSettings,
  SelectItemValue,
  SelectSettings
} from "app/model/custom-field/custom-field";
import {Observable} from "rxjs";
import {CustomFieldAlias} from "#application/services/custom-field-query.service";
import {ServerApi} from "#infrastructure/api/server-api";
import {map} from "rxjs/operators";
import {FieldType} from "app/model/custom-field/field-type";
import { ProposalFieldQueryService } from '#application/services/proposal-field-query.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalFieldQueryServiceImpl extends ProposalFieldQueryService {

  constructor(private readonly severApi: ServerApi) {
    super();
  }

  get(id: CustomFieldId): Observable<CustomField> {
    return this.severApi.customFieldApi.get('proposal', id.value)
      .pipe(
        map(response => {
          let numberSettings: NumberSettings | undefined;
          if (response.numberSetting !== undefined) {
            numberSettings = new NumberSettings(
              response.numberSetting.min === undefined ? undefined : new CustomFieldNumberValue(response.numberSetting.min),
              response.numberSetting.max === undefined ? undefined : new CustomFieldNumberValue(response.numberSetting.max),
              response.numberSetting.defaultValue === undefined ? undefined : new CustomFieldNumberValue(response.numberSetting.defaultValue),
              response.numberSetting.unit === undefined ? undefined : new CustomFieldNumberUnit(response.numberSetting.unit)
            );
          }
          let selectSettings: SelectSettings | undefined;
          if (response.selectSetting !== undefined) {
            selectSettings = new SelectSettings(
              response.selectSetting.multiple,
              response.selectSetting.allowInput,
              response.selectSetting.options.map(value => new SelectItemValue(value))
            );
          }

          return new CustomField(
            new CustomFieldId(response.id),
            new CustomFieldName(response.name),
            FieldType[response.type],
            response.required,
            new CustomFieldDescription(response.description),
            numberSettings,
            selectSettings
          );
        })
      );
  }

  list(): Observable<CustomFieldAlias[]> {
    return this.severApi.customFieldApi.list('proposal')
      .pipe(
        map(responseList => responseList
          .map(response => ({
            id: new CustomFieldId(response.id),
            type: FieldType[response.type],
            name: new CustomFieldName(response.name),
            description: new CustomFieldDescription(response.description),
            required: new CustomFieldRequired(response.required),
            numberSettings: response.numberSetting === undefined ? undefined : new NumberSettings(
              response.numberSetting.min === undefined ? undefined : new CustomFieldNumberValue(response.numberSetting.min),
              response.numberSetting.max === undefined ? undefined : new CustomFieldNumberValue(response.numberSetting.max),
              response.numberSetting.defaultValue === undefined ? undefined : new CustomFieldNumberValue(response.numberSetting.defaultValue),
              response.numberSetting.unit === undefined ? undefined : new CustomFieldNumberUnit(response.numberSetting.unit)
            ),
            selectSettings: response.selectSetting === undefined ? undefined : new SelectSettings(
              response.selectSetting.multiple,
              response.selectSetting.allowInput,
              response.selectSetting.options.map(value => new SelectItemValue(value))
            )
          }))
        )
      );
  }
}
