import { CustomField, CustomFieldValue, CustomItems } from "app/model/custom-field/custom-field";
import {
  BuiltinFieldId,
  FormLayoutItemType,
  FormLayoutWidth,
  IndividualClientBuiltinFieldId,
  OpportunityGroupBuiltinFieldId,
  SectionTitle
} from "app/model/form-layout/form-layout";
import { OpportunityGroupTitle } from "app/model/opportunity-group/opportunity-group";
import { ExpectedAmount, ExpectedCloseDate, OpportunityAccrualDate, OpportunityItem, OpportunityName } from "app/model/opportunity/opportunity";
import { ProductItem, ProductName, ProductPrice, ProductQuantityUnit } from "app/model/product/product";
import { ProposalTitle } from "app/model/proposal/proposal";
import { UserID } from "app/model/user/user";
import { Address, ClientItem, CorporateNumber, CorporationName, CorporationNameKana, Established, FirstName, FirstNameKana, FiscalYearEnd, InitialCapital, LastName, LastNameKana, NumberOfEmployees, PhoneNumber, SNS, Website } from "../client/client";

export type BuiltinFieldV2 =
  // product
  | ProductName
  | ProductPrice
  | ProductQuantityUnit
  // proposal
  | ProposalTitle
  // opportunity
  | OpportunityName
  | UserID
  | ExpectedAmount
  | ExpectedCloseDate
  | OpportunityAccrualDate
  // opportunity group
  | OpportunityGroupTitle
  // client corporation
  | CorporationName
  | CorporationNameKana
  | CorporateNumber
  | Established
  | NumberOfEmployees
  | InitialCapital
  | FiscalYearEnd
  | Website
  // client individual
  | LastName
  | LastNameKana
  | FirstName
  | FirstNameKana
  | SNS
  // client shared
  | Address
  | PhoneNumber

export type FormItemV2 =
  | SectionItemV2
  | BuiltinFormItemV2
  | CustomFormItemV2

export class SectionItemV2 {
  readonly type = FormLayoutItemType.SECTION_TITLE;
  constructor(
    public readonly width: FormLayoutWidth,
    public readonly title: SectionTitle
  ) {}
}

export class BuiltinFormItemV2 {
  readonly type = FormLayoutItemType.BUILTIN_FIELD;
  constructor(
    public readonly width: FormLayoutWidth,
    public readonly builtinFieldId: BuiltinFieldId,
    public readonly builtinField: BuiltinFieldV2 | undefined,
  ) {}

  changeBuiltinField(builtinField: BuiltinFieldV2 | undefined): BuiltinFormItemV2 {
    return new BuiltinFormItemV2(this.width, this.builtinFieldId, builtinField);
  }
}

export class CustomFormItemV2 {
  readonly type = FormLayoutItemType.CUSTOM_FIELD;
  constructor(
    public readonly width: FormLayoutWidth,
    public readonly customField: CustomField,
    public readonly customFieldValue: CustomFieldValue | undefined,
  ) {}

  changeCustomFieldValue(customFieldValue: CustomFieldValue): CustomFormItemV2 {
    return new CustomFormItemV2(this.width, this.customField, customFieldValue);
  }
}

export type ActionHearingItems = OpportunityItem[]

export type OpportunityFormItems = Readonly<{
  opportunityName: OpportunityName,
  assignee: UserID,
  expectedAmount:   ExpectedAmount | undefined,
  expectedCloseDate:  ExpectedCloseDate | undefined,
  opportunityAccrualDate:   OpportunityAccrualDate | undefined,
  items: OpportunityItem[],
}>

export type CorporationFormItems = Readonly<{
  corporationName: CorporationName,
  corporationNameKana: CorporationNameKana | undefined,
  corporateNumber: CorporateNumber | undefined,
  address: Address | undefined,
  phoneNumber: PhoneNumber | undefined,
  established: Established | undefined,
  numberOfEmployees: NumberOfEmployees | undefined,
  initialCapital: InitialCapital | undefined,
  fiscalYearEnd: FiscalYearEnd | undefined,
  website: Website | undefined,
  items: ClientItem[],
}>

export type IndividualFormItems = Readonly<{
  firstName: FirstName | undefined,
  lastName: LastName | undefined,
  firstNameKana: FirstNameKana | undefined,
  lastNameKana: LastNameKana | undefined,
  address: Address | undefined,
  phoneNumber: PhoneNumber | undefined,
  sns1: SNS | undefined,
  sns2: SNS | undefined,
  items: ClientItem[],
}>

export type ProductFormItems = Readonly<{
  name: ProductName,
  price: ProductPrice,
  unit: ProductQuantityUnit,
  items: ProductItem[],
}>

export type ProposalFormItems = Readonly<{
  title: ProposalTitle,
  items: CustomItems,
}>

export type OpportunityGroupFormItems = Readonly<{
  title: OpportunityGroupTitle,
  items: CustomItems,
}>

export class FormItems {
  constructor(
    public readonly list: ReadonlyArray<FormItemV2>,
  ) {}

  toActionHearingItems(): ActionHearingItems {
    return this.list
      .filter(FormItemConverter.isCustomFormItem)
      .map(FormItemConverter.toOpportunityCustomItem);
  }

  toOpportunityFormItems(): OpportunityFormItems {
    const builtinItems = FormItemConverter.toOpportunityBuiltinItems(
      this.list.filter(FormItemConverter.isBuiltinFormItem)
    );
    const items = this.list
      .filter(FormItemConverter.isCustomFormItem)
      .map(FormItemConverter.toOpportunityCustomItem);
    return {
      ...builtinItems,
      items,
    };
  }

  toCorporationFormItems(): CorporationFormItems {
    const builtinItems = FormItemConverter.toCorporationBuiltinItems(
      this.list.filter(FormItemConverter.isBuiltinFormItem)
    );
    const items = this.list
      .filter(FormItemConverter.isCustomFormItem)
      .map(FormItemConverter.toClientCustomItem);
    return {
      ...builtinItems,
      items,
    };
  }

  toIndividualFormItems(): IndividualFormItems {
    const builtinItems = FormItemConverter.toIndividualBuiltinItems(
      this.list.filter(FormItemConverter.isBuiltinFormItem)
    );
    const items = this.list
      .filter(FormItemConverter.isCustomFormItem)
      .map(FormItemConverter.toClientCustomItem);
    return {
      ...builtinItems,
      items,
    };
  }

  toProductFormItems(): ProductFormItems {
    const builtinItems = FormItemConverter.toProductBuiltinItems(
      this.list.filter(FormItemConverter.isBuiltinFormItem)
    );
    const items = this.list
      .filter(FormItemConverter.isCustomFormItem)
      .map(FormItemConverter.toProductCustomItem);
    return {
      ...builtinItems,
      items,
    };
  }

  toProposalFormItems(): ProposalFormItems {
    const proposalTitle = this.list
      .map(FormItemConverter.toProposalTitle)
      .find(formItem => formItem !== undefined);
    const customItems = this.list
      .map(FormItemConverter.toCustomItem)
      .filter(formItem => formItem !== undefined)
      .reduce(
        (previous, current) => {
          let copiedMap = new Map(previous!.entries());
          current!.forEach((value, key) => copiedMap.set(key, value));
          return copiedMap;
        },
        new Map()
      )
    return {
      title: proposalTitle ?? new ProposalTitle(''),
      items: customItems ?? new Map(),
    }
  }

  toOpportunityGroupFormItems(): OpportunityGroupFormItems {
    const opportunityGroupTitle = this.list
      .map(FormItemConverter.toOpportunityGroupTitle)
      .find(formItem => formItem !== undefined);
    const customItems = this.list
      .map(FormItemConverter.toCustomItem)
      .filter(formItem => formItem !== undefined)
      .reduce(
        (previous, current) => {
          let copiedMap = new Map(previous!.entries());
          current!.forEach((value, key) => copiedMap.set(key, value));
          return copiedMap;
        },
        new Map()
      )
    return {
      title: opportunityGroupTitle ?? OpportunityGroupTitle(''),
      items: customItems ?? new Map(),
    }
  }

  toOpportunityCustomItems(): OpportunityItem[] {
    return this.list
      .filter(FormItemConverter.isCustomFormItem)
      .map(FormItemConverter.toOpportunityCustomItem);
  }

  toClientCustomItems(): ClientItem[] {
    return this.list
      .filter(FormItemConverter.isCustomFormItem)
      .map(FormItemConverter.toClientCustomItem);
  }

  extractRequiredCustomItems(): CustomFormItemV2[] {
    return this.list
      .filter(item =>
        FormItemConverter.isCustomFormItem(item)
        && item.customField.required
      ) as CustomFormItemV2[];
  }
}

class FormItemConverter {
  static isBuiltinFormItem = (formItem: FormItemV2): formItem is BuiltinFormItemV2 => {
    return formItem.type === FormLayoutItemType.BUILTIN_FIELD;
  }

  static isCustomFormItem = (formItem: FormItemV2): formItem is CustomFormItemV2 => {
    return formItem.type === FormLayoutItemType.CUSTOM_FIELD;
  }

  static toOpportunityBuiltinItems = (items: BuiltinFormItemV2[]): Omit<OpportunityFormItems, 'items'> => {
    return {
      opportunityName: items.find(item => item.builtinField instanceof OpportunityName)!.builtinField as OpportunityName,
      assignee: items.find(item => item.builtinField instanceof UserID)!.builtinField as UserID,
      expectedAmount: items.find(item => item.builtinField instanceof ExpectedAmount)?.builtinField as ExpectedAmount | undefined,
      expectedCloseDate: items.find(item => item.builtinField instanceof ExpectedCloseDate)?.builtinField as ExpectedCloseDate | undefined,
      opportunityAccrualDate: items.find(item => item.builtinField instanceof OpportunityAccrualDate)?.builtinField as OpportunityAccrualDate | undefined,
    };
  }

  static toOpportunityCustomItem = (item: CustomFormItemV2): OpportunityItem => {
    return new OpportunityItem(item.customField.id, item.customFieldValue ?? new CustomFieldValue(''));
  }

  static toCorporationBuiltinItems = (items: BuiltinFormItemV2[]): Omit<CorporationFormItems, 'items'> => {
    return {
      corporationName: items.find(item => item.builtinField instanceof CorporationName)!.builtinField as CorporationName,
      corporationNameKana: items.find(item => item.builtinField instanceof CorporationNameKana)?.builtinField as CorporationNameKana | undefined,
      corporateNumber: items.find(item => item.builtinField instanceof CorporateNumber)?.builtinField as CorporateNumber | undefined,
      address: items.find(item => item.builtinField instanceof Address)?.builtinField as Address | undefined,
      phoneNumber: items.find(item => item.builtinField instanceof PhoneNumber)?.builtinField as PhoneNumber | undefined,
      established: items.find(item => item.builtinField instanceof Established)?.builtinField as Established | undefined,
      numberOfEmployees: items.find(item => item.builtinField instanceof NumberOfEmployees)?.builtinField as NumberOfEmployees | undefined,
      initialCapital: items.find(item => item.builtinField instanceof InitialCapital)?.builtinField as InitialCapital | undefined,
      fiscalYearEnd: items.find(item => item.builtinField instanceof FiscalYearEnd)?.builtinField as FiscalYearEnd | undefined,
      website: items.find(item => item.builtinField instanceof Website)?.builtinField as Website | undefined,
    };
  }

  static toIndividualBuiltinItems = (items: BuiltinFormItemV2[]): Omit<IndividualFormItems, 'items'> => {
    return {
      firstName: items.find(item => item.builtinField instanceof FirstName)?.builtinField as FirstName | undefined,
      lastName: items.find(item => item.builtinField instanceof LastName)?.builtinField as LastName | undefined,
      firstNameKana: items.find(item => item.builtinField instanceof FirstNameKana)?.builtinField as FirstNameKana | undefined,
      lastNameKana: items.find(item => item.builtinField instanceof LastNameKana)?.builtinField as LastNameKana | undefined,
      address: items.find(item => item.builtinField instanceof Address)?.builtinField as Address | undefined,
      phoneNumber: items.find(item => item.builtinField instanceof PhoneNumber)?.builtinField as PhoneNumber | undefined,
      sns1: items.find(item => item.builtinField instanceof SNS && item.builtinFieldId === IndividualClientBuiltinFieldId.INDIVIDUAL_SNS1)?.builtinField as SNS | undefined,
      sns2: items.find(item => item.builtinField instanceof SNS && item.builtinFieldId === IndividualClientBuiltinFieldId.INDIVIDUAL_SNS2)?.builtinField as SNS | undefined,
    };
  }

  static toClientCustomItem = (item: CustomFormItemV2): ClientItem => {
    return new ClientItem(item.customField.id, item.customFieldValue ?? new CustomFieldValue(''));
  }

  static toProductBuiltinItems = (items: BuiltinFormItemV2[]): Omit<ProductFormItems, 'items'> => {
    return {
      name: items.find(item => item.builtinField instanceof ProductName)!.builtinField as ProductName,
      price: items.find(item => item.builtinField instanceof ProductPrice)!.builtinField as ProductPrice,
      unit: items.find(item => item.builtinField instanceof ProductQuantityUnit)!.builtinField as ProductQuantityUnit,
    };
  }

  static toProductCustomItem = (item: CustomFormItemV2): ProductItem => {
    return new ProductItem(item.customField.id, item.customFieldValue ?? new CustomFieldValue(''));
  }

  static toProposalTitle = (formItem: FormItemV2): ProposalTitle | undefined => {
    if (formItem instanceof BuiltinFormItemV2 && formItem?.builtinField instanceof ProposalTitle)
      return formItem.builtinField;
    return undefined;
  }

  static toOpportunityGroupTitle = (formItem: FormItemV2): OpportunityGroupTitle | undefined => {
    if (
      formItem instanceof BuiltinFormItemV2 &&
      formItem.builtinFieldId === OpportunityGroupBuiltinFieldId.OPPORTUNITY_GROUP_TITLE &&
      formItem.builtinField !== undefined
    )
      return OpportunityGroupTitle(formItem.builtinField.toString());
    return undefined;
  }

  static toCustomItem = (formItem: FormItemV2): Map<string, CustomFieldValue> | undefined => {
    if (formItem instanceof CustomFormItemV2)
      return new Map([
        [
          formItem.customField.id.value,
          (formItem.customFieldValue) ? formItem.customFieldValue: new CustomFieldValue('')
        ]
      ]);
    return undefined;
  }
}
