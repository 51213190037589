import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { OpportunityGroupID, OpportunityGroupItem, OpportunityGroupTitle } from "app/model/opportunity-group/opportunity-group";
import { OpportunityGroupStatusID } from "app/model/opportunity-group/opportunity-group-status";
import { OpportunityGroupTypeID } from "app/model/opportunity-group/opportunity-group-type";
import { OpportunityID } from "app/model/opportunity/opportunity";
import { Observable } from "rxjs";

export type OpportunityGroupInputs = {
  title: OpportunityGroupTitle,
  opportunityGroupTypeID?: OpportunityGroupTypeID,
  opportunityGroupStatusID?: OpportunityGroupStatusID,
  items: OpportunityGroupItem[],
  opportunities?: OpportunityID[]
}

export abstract class OpportunityGroupCommandService {
  abstract add(inputs: OpportunityGroupInputs): Observable<Result<
    OpportunityGroupID,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
  >>;

  abstract update(id: OpportunityGroupID, inputs: OpportunityGroupInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.NotFound
  >>;

  abstract updateStatus(id: OpportunityGroupID, opportunityGroupStatusID: OpportunityGroupStatusID): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>;

  abstract updateOpportunities(id: OpportunityGroupID, opportunities: OpportunityID[]): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>;

  abstract delete(id: OpportunityGroupID): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>;
}
