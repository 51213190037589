import { FilterInput } from "#infrastructure/application/filter.service";
import { ProductCsvExportCustomDisplayItem, ProductCsvExportStatus } from "#presentation/pages/activity/product-management/product-csv-export/product-csv-export-setting";
import { ProductBuiltinFieldId } from "app/model/form-layout/form-layout";
import { FilterPredicate } from "app/model/search-filter/search-filter";
import { Observable } from "rxjs";

export type ProductCsvExportList = (
  | ProductCsvExportDefaultItem
  | ProductCsvExportBuiltinItem
  | ProductCsvExportCustomItem
)[];

export interface ProductCsvExportDefaultItem {
  readonly columnType: 'PRODUCT_ID' | 'PRODUCT_NUMBER' | 'PRODUCT_TYPE'
  readonly columnName: string,
}

export interface ProductCsvExportBuiltinItem {
  readonly builtinFieldID: ProductBuiltinFieldId,
  readonly columnType: 'BUILTIN_FIELD',
  readonly columnName: string,
}

export interface ProductCsvExportCustomItem {
  readonly customFieldID: string,
  readonly columnType: 'CUSTOM_FIELD',
  readonly columnName: string,
}

export interface ProductCsvExportFilter {
  searchString?: FilterPredicate | undefined,
  productName?: FilterPredicate | undefined,
  productTypes?: FilterPredicate | undefined,
  [propertyName: `item:${string}`]: FilterPredicate | undefined,
}

export type ProductExportCsvData = Blob;

export abstract class ProductCsvExportService {
  abstract csvExport(list: ProductCsvExportList, filter: FilterInput, encoding: string): Observable<ProductExportCsvData>;

  abstract saveStatus(status: ProductCsvExportStatus): void;

  abstract getLastStatus(): ProductCsvExportStatus | null;

  abstract deleteStatus(): void;

  abstract listProductCustomField(): Observable<ProductCsvExportCustomDisplayItem[]>;
}
