import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { UserRepository } from 'app/model/user/user.repository';

import { OrganizationID } from 'app/model/organization/organization';
import { DisplayName, MailAddress, User, UserID, LoginID } from 'app/model/user/user';
import { ServerApi } from '#infrastructure/api/server-api';
import { GetUserData } from '#infrastructure/api/user-api';

const convertFromDetailData = (data: GetUserData): User => {
  return new User(
    new UserID(data.id.value),
    new OrganizationID(data.organizationID.value),
    new LoginID(data.userName.value),
    new DisplayName(data.name.value),
    new MailAddress(data.mailAddress.value),
    data.accessLevels
  );
};

@Injectable({providedIn: 'root'})
export class UserRepositoryImpl implements UserRepository {
  constructor(private readonly serverApi: ServerApi) {
  }

  findById(userID: UserID): Observable<User> {
    return this.serverApi.user.get(userID.value).pipe(
      map(convertFromDetailData)
    );
  }

  save(user: User): Observable<void> {
    return this.serverApi.user.update(user.id.value, {
      name: user.name.value,
      mailAddress: user.mailAddress.value,
      accessLevels: user.accessLevels
    });
  }

  delete(userID: UserID): Observable<void> {
    return this.serverApi.user.delete(userID.value);
  }

  resetPassword(userId: UserID): Observable<void> {
    return this.serverApi.user.resetPassword(userId.value);
  }
}
