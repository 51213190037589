import {
  OpportunityFilterInput,
  OpportunityQueryService,
  PaginatedOpportunityList,
  SearchOpportunityListInput
} from "#application/services/opportunity-query.service";
import { ServerApi } from "#infrastructure/api/server-api";
import { FilterRequestConverter, FilterService } from "#infrastructure/application/filter.service";
import { inject, Injectable } from '@angular/core';
import { ClientCategory } from 'app/model/client-info-type/client-type';
import { ClientID, ClientName, ClientNumber } from "app/model/client/client";
import { CustomFieldId, CustomFieldValue } from "app/model/custom-field/custom-field";
import { OpportunityTypeID, OpportunityTypeName } from "app/model/opportunity-type/opportunity-type";
import {
  ExpectedAmount,
  ExpectedCloseDate,
  OpportunityAccrualDate,
  OpportunityID,
  OpportunityItem,
  OpportunityName,
  OpportunityNumber,
} from "app/model/opportunity/opportunity";
import { PhaseID, PhaseName, SalesPhaseID } from "app/model/sales-phase/sales-phase";
import { DisplayName, UserID } from "app/model/user/user";
import { DateTime } from "luxon";
import { mergeMap, Observable } from "rxjs";
import { map } from "rxjs/operators";

const convertKeyValueObjToOpportunityItemList = (items: { [key: string]: string }): OpportunityItem[] => {
  return Object.keys(items)
    .map(key => new OpportunityItem(
      new CustomFieldId(key),
      new CustomFieldValue(items[key])
    ));
};

const categoryConverter = (category: string): ClientCategory => {
  if (category === 'CORPORATION') {
    return ClientCategory.Corporation;
  } else {
    return ClientCategory.Individual;
  }
}

@Injectable({
  providedIn: 'root'
})
export class OpportunityQueryServiceImpl extends OpportunityQueryService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _filterService = inject(FilterService);

  searchOpportunityList(input: SearchOpportunityListInput): Observable<PaginatedOpportunityList> {
    return this._filterService.getSchema('opportunities')
      .pipe(
        mergeMap(schema => {
          const filterRequest = FilterRequestConverter.toFilterRequest(schema, input);
          return this._serverApi.opportunityApi.filter(filterRequest);
        }),
        map(response => ({
          totalCount: response.totalCount,
          results: response.results.map(opp => ({
            id: new OpportunityID(opp.opportunityID),
            name: new OpportunityName(opp.opportunityName),
            clientID: new ClientID(opp.clientID),
            clientName: new ClientName(opp.clientName),
            clientCategory: categoryConverter(opp.clientCategory),
            clientNumber: new ClientNumber(opp.clientNumber),
            opportunityTypeID: new OpportunityTypeID(opp.opportunityTypeID),
            opportunityTypeName: new OpportunityTypeName(opp.opportunityTypeName),
            opportunityNumber: new OpportunityNumber(opp.opportunityNumber),
            salesPhaseID: new SalesPhaseID(opp.salesPhaseID),
            phaseID: new PhaseID(opp.phaseID),
            phaseNumber: opp.phaseNumber,
            phaseName: new PhaseName(opp.phaseName),
            status: opp.status,
            assignee: new UserID(opp.assignee),
            assigneeName: new DisplayName(opp.assigneeName),
            expectedAmount: opp.expectedAmount !== undefined
              ? new ExpectedAmount(opp.expectedAmount)
              : undefined,
            expectedCloseDate: opp.expectedCloseDate !== undefined
              ? new ExpectedCloseDate(DateTime.fromFormat(opp.expectedCloseDate, 'yyyy-MM-dd').toJSDate())
              : undefined,
            opportunityAccrualDate: opp.opportunityAccrualDate !== undefined
              ? new OpportunityAccrualDate(DateTime.fromFormat(opp.opportunityAccrualDate, 'yyyy-MM-dd').toJSDate())
              : undefined,
            items: opp.items !== undefined
              ? convertKeyValueObjToOpportunityItemList(opp.items)
              : undefined,
          }))
        }))
      );
  }

  filter(input: OpportunityFilterInput): Observable<PaginatedOpportunityList> {
    return this._serverApi.opportunityApi.filter({
      pagination: {
        perPage: input.perPage,
        page: input.page,
      },
      filters: input.filterInput.filters,
      sorts: input.filterInput.sorts
    })
      .pipe(
        map(response => ({
          totalCount: response.totalCount,
          results: response.results.map(opp => ({
            id: new OpportunityID(opp.opportunityID),
            name: new OpportunityName(opp.opportunityName),
            clientID: new ClientID(opp.clientID),
            clientName: new ClientName(opp.clientName),
            clientCategory: categoryConverter(opp.clientCategory),
            clientNumber: new ClientNumber(opp.clientNumber),
            opportunityTypeID: new OpportunityTypeID(opp.opportunityTypeID),
            opportunityTypeName: new OpportunityTypeName(opp.opportunityTypeName),
            opportunityNumber: new OpportunityNumber(opp.opportunityNumber),
            salesPhaseID: new SalesPhaseID(opp.salesPhaseID),
            phaseID: new PhaseID(opp.phaseID),
            phaseNumber: opp.phaseNumber,
            phaseName: new PhaseName(opp.phaseName),
            status: opp.status,
            assignee: new UserID(opp.assignee),
            assigneeName: new DisplayName(opp.assigneeName),
            expectedAmount: opp.expectedAmount !== undefined
              ? new ExpectedAmount(opp.expectedAmount)
              : undefined,
            expectedCloseDate: opp.expectedCloseDate !== undefined
              ? new ExpectedCloseDate(DateTime.fromFormat(opp.expectedCloseDate, 'yyyy-MM-dd').toJSDate())
              : undefined,
            opportunityAccrualDate: opp.opportunityAccrualDate !== undefined
              ? new OpportunityAccrualDate(DateTime.fromFormat(opp.opportunityAccrualDate, 'yyyy-MM-dd').toJSDate())
              : undefined,
            items: opp.items !== undefined
              ? convertKeyValueObjToOpportunityItemList(opp.items)
              : undefined,
          }))
        }))
      );
  }
}
