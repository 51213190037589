import { Observable } from "rxjs";

import { FiscalYearBegin, Organization, OrganizationCode, OrganizationName } from "./organization";

/**
 * 組織ファクトリ
 */
export abstract class OrganizationFactory {
  abstract create(
    code: OrganizationCode,
    name: OrganizationName,
    fiscalYearBegin: FiscalYearBegin,
  ): Observable<Organization>;
}
