import { ClientCategory } from "app/model/client-info-type/client-type";
import { ClientID, ClientName, ClientNumber } from "app/model/client/client";
import { OpportunityTypeID, OpportunityTypeName } from "app/model/opportunity-type/opportunity-type";
import {
  ExpectedAmount,
  ExpectedCloseDate,
  OpportunityAccrualDate,
  OpportunityID,
  OpportunityItem,
  OpportunityName,
  OpportunityNumber,
  OpportunityStatus,
} from "app/model/opportunity/opportunity";
import { PhaseID, PhaseName, SalesPhaseID } from "app/model/sales-phase/sales-phase";
import { FilterPredicate } from "app/model/search-filter/search-filter";
import { DisplayName, UserID } from "app/model/user/user";
import { Observable } from "rxjs";
import {FilterInput} from "#infrastructure/application/filter.service";

export type SearchOpportunityListInput = {
  perPage: number;
  page: number;
  filter: {
    searchString?: FilterPredicate,
    opportunityName?: FilterPredicate,
    opportunityTypeID?: FilterPredicate,
    status?: FilterPredicate,
    clientID?: FilterPredicate,
    clientName?: FilterPredicate,
    expectedAmount?: FilterPredicate,
    actualAmount?: FilterPredicate,
    assignee?: FilterPredicate,
    expectedCloseDate?: FilterPredicate,
    actualCloseDate?: FilterPredicate,
    lostOrderDate?: FilterPredicate,
    lostReason?: FilterPredicate,
    opportunityNumber?: FilterPredicate,
    [propertyName: `item:${string}`]: FilterPredicate | undefined,
  }
}

export type OpportunityFilterInput = {
  perPage: number,
  page: number,
  filterInput: FilterInput,
}

export type PaginatedOpportunityList = {
  totalCount: number;
  results: {
    id: OpportunityID;
    name: OpportunityName;
    clientID: ClientID;
    clientName: ClientName;
    clientCategory: ClientCategory;
    clientNumber: ClientNumber;
    opportunityTypeID: OpportunityTypeID;
    opportunityTypeName: OpportunityTypeName;
    opportunityNumber: OpportunityNumber;
    salesPhaseID: SalesPhaseID;
    phaseID: PhaseID;
    phaseNumber: number;
    phaseName: PhaseName;
    status: OpportunityStatus;
    assignee: UserID;
    assigneeName: DisplayName,
    expectedAmount?: ExpectedAmount | undefined;
    expectedCloseDate?: ExpectedCloseDate | undefined;
    opportunityAccrualDate?: OpportunityAccrualDate,
    items?: OpportunityItem[] | undefined;
  }[];
};

export abstract class OpportunityQueryService {
  abstract searchOpportunityList(input: SearchOpportunityListInput): Observable<PaginatedOpportunityList>;
  abstract filter(input: OpportunityFilterInput): Observable<PaginatedOpportunityList>;
}
