import { OpportunityTypeID } from "app/model/opportunity-type/opportunity-type";
import { PhaseName } from "app/model/sales-phase/sales-phase";
import { UserGroupID } from "app/model/user-group/user-group";
import { DateTime } from "luxon";
import { Observable } from "rxjs";

type Assignee = AsUserGroup | AsAllUsers;

type AsUserGroup = {
  type: 'USER_GROUP',
  userGroupID: UserGroupID,
};

type AsAllUsers = {
  type: 'ALL_USERS',
};

export type GetReportCvrInputs = {
  opportunityTypeID: OpportunityTypeID,
  duration: {
    startYearMonth: DateTime,
    months: number,
  },
  assignee: Assignee,
};

export type GetReportCvrOutputs = {
  wonCount: number,
  wonRate: number,
  totalLostCount: number,
  conversionRates: {
    phase: {
      name: PhaseName,
      countPerPhase: number,
      lostCount: number,
    },
    rate: number,
  }[],
};

export type GetPersonalCvrReportInput = {
  opportunityTypeID: OpportunityTypeID,
  duration: {
    startYearMonth: DateTime,
    months: number,
  },
}

export type GetPersonalCvrReportOutput = {
  wonCount: number,
  wonRate: number,
  totalLostCount: number,
  conversionRates: {
    phase: {
      name: PhaseName,
      countPerPhase: number,
      lostCount: number,
    },
    rate: number,
  }[],
};

export abstract class ReportCvrQueryService {
  abstract get(input: GetReportCvrInputs): Observable<GetReportCvrOutputs>;
  abstract getPersonalCvrReport(input: GetPersonalCvrReportInput): Observable<GetPersonalCvrReportOutput>;
}
