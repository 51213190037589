import { FormItemsV2QueryService } from "#application/services/form-items-v2-query.service";
import { ServerApi } from "#infrastructure/api/server-api";
import {
  ApiCustomFieldNumberSettingData,
  ApiCustomFieldSelectSettingData
} from "#infrastructure/api/server-custom-field-api";
import {
  BuiltinFormItemData,
  CustomFormItemData,
  GetFormItemsResponse,
  SectionFormItemData
} from '#infrastructure/api/server-form-items-api';
import { Injectable } from "@angular/core";
import { Corporation, Individual } from 'app/model/client/client';
import {
  CustomField,
  CustomFieldDescription,
  CustomFieldId,
  CustomFieldName,
  CustomFieldNumberUnit,
  CustomFieldNumberValue,
  CustomFieldValue,
  NumberSettings,
  SelectItemValue,
  SelectSettings
} from 'app/model/custom-field/custom-field';
import {
  BuiltinFieldV2,
  BuiltinFormItemV2,
  CustomFormItemV2,
  FormItems,
  SectionItemV2
} from "app/model/form-items/form-items";
import {
  CorporationClientBuiltinFieldId,
  FormLayoutCategory,
  FormLayoutId,
  IndividualClientBuiltinFieldId,
  OpportunityBuiltinFieldId,
  SectionTitle
} from 'app/model/form-layout/form-layout';
import { OpportunityGroup, OpportunityGroupTitle } from 'app/model/opportunity-group/opportunity-group';
import { OpportunityGroupType } from "app/model/opportunity-group/opportunity-group-type";
import { OpportunityV2 } from 'app/model/opportunity/opportunity';
import { Product, ProductName, ProductPrice, ProductQuantityUnit } from 'app/model/product/product';
import { Proposal, ProposalTitle } from "app/model/proposal/proposal";
import { mergeMap, Observable, of } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { GeneralFailure } from "../../lib/general-failure/general-failure";
import { Failure, Result, Success } from "../../lib/result/result";

@Injectable({providedIn: 'root'})
export class FormItemsV2QueryServiceImpl implements FormItemsV2QueryService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly converter: FormItemsV2Converter,
  ) {}

  getProductFormItems(product: Product | undefined, formLayoutId: FormLayoutId | undefined): Observable<FormItems> {
    if (formLayoutId === undefined)
      return this.serverApi.defaultFormItemsApi
        .get(FormLayoutCategory.PRODUCT)
        .pipe(map(formItems => this.converter.toProductFormItems(product, formItems)));
    else
      return this.serverApi.formItemsApi
        .get(FormLayoutCategory.PRODUCT, formLayoutId.value)
        .pipe(map(formItems => this.converter.toProductFormItems(product, formItems)));
  }

  getProposalFormItems(proposal: Proposal | undefined, formLayoutId: FormLayoutId | undefined): Observable<FormItems> {
    if (formLayoutId === undefined)
      return this.serverApi.defaultFormItemsApi
        .get(FormLayoutCategory.PROPOSAL)
        .pipe(map(formItems => this.converter.toProposalFormItems(proposal, formItems)));
    else
      return this.serverApi.formItemsApi
        .get(FormLayoutCategory.PROPOSAL, formLayoutId.value)
        .pipe(map(formItems => this.converter.toProposalFormItems(proposal, formItems)));

  }

  getOpportunityGroupFormItems(opportunityGroup: OpportunityGroup): Observable<Result<
    FormItems,
    | typeof GeneralFailure.Unexpected
  >> {
    return  this.serverApi.opportunityGroupTypeApi
      .get(opportunityGroup.opportunityGroupTypeID)
      .pipe(
        mergeMap(opportunityGroupType => {
          if (opportunityGroupType.formLayout?.id === undefined)
            return this.serverApi.defaultFormItemsApi
              .get(FormLayoutCategory.OPPORTUNITY_GROUP)
              .pipe(
                map(formItems => this.converter.toOpportunityGroupFormItems(opportunityGroup, formItems)),
              )
          else
            return this.serverApi.formItemsApi
              .get(FormLayoutCategory.OPPORTUNITY_GROUP, opportunityGroupType.formLayout.id)
              .pipe(
                map(formItems => this.converter.toOpportunityGroupFormItems(opportunityGroup, formItems))
              );
        }),
        map(formItems => Success(formItems)),
        catchError(() => of(Failure(GeneralFailure.Unexpected)))
      )
  }

  getOpportunityGroupFormItemsForAdd(opportunityGroupType: OpportunityGroupType): Observable<Result<
    FormItems,
    | typeof GeneralFailure.Unexpected
  >> {
      if (opportunityGroupType.formLayout?.id === undefined)
        return this.serverApi.defaultFormItemsApi
          .get(FormLayoutCategory.OPPORTUNITY_GROUP)
          .pipe(
            map(formItems => this.converter.toOpportunityGroupFormItems(undefined, formItems)),
            map(formItems => Success(formItems)),
            catchError(() => of(Failure(GeneralFailure.Unexpected)))
          )
      else
        return this.serverApi.formItemsApi
          .get(FormLayoutCategory.OPPORTUNITY_GROUP, opportunityGroupType.formLayout.id.value)
          .pipe(
            map(formItems => this.converter.toOpportunityGroupFormItems(undefined, formItems)),
            map(formItems => Success(formItems)),
            catchError(() => of(Failure(GeneralFailure.Unexpected)))
          )
  }

  getOpportunityFormItems(opportunity: OpportunityV2, formLayoutId: FormLayoutId | undefined): Observable<Result<
    FormItems,
    | typeof GeneralFailure.Unexpected
  >> {
    return formLayoutId === undefined
      ? this.serverApi.defaultFormItemsApi
        .get(FormLayoutCategory.OPPORTUNITY)
        .pipe(
          map(items => Success(this.converter.toOpportunityFormItems(opportunity, items))),
          catchError(() => of(Failure(GeneralFailure.Unexpected)))
        )
      : this.serverApi.formItemsApi
        .get(FormLayoutCategory.OPPORTUNITY, formLayoutId.value)
        .pipe(
          map(items => Success(this.converter.toOpportunityFormItems(opportunity, items))),
          catchError(() => of(Failure(GeneralFailure.Unexpected)))
        )
  }

  getOpportunityFormItemsForAdd(formLayoutId: FormLayoutId | undefined): Observable<FormItems> {
    if (formLayoutId === undefined)
      return this.serverApi.defaultFormItemsApi
        .get(FormLayoutCategory.OPPORTUNITY)
        .pipe(map(formItems => this.converter.toOpportunityFormItemsForAdd(formItems)));
    else
      return this.serverApi.formItemsApi
        .get(FormLayoutCategory.OPPORTUNITY, formLayoutId.value)
        .pipe(map(formItems => this.converter.toOpportunityFormItemsForAdd(formItems)));
  }

  getCorporationClientFormItems(corporation: Corporation, formLayoutId: FormLayoutId | undefined): Observable<Result<
    FormItems,
    | typeof GeneralFailure.Unexpected
  >> {
    return formLayoutId === undefined
      ? this.serverApi.defaultFormItemsApi
        .get(FormLayoutCategory.CORPORATION_CLIENT)
        .pipe(
          map(items => Success(this.converter.toCorporationClientFormItems(corporation, items))),
          catchError(() => of(Failure(GeneralFailure.Unexpected)))
        )
      : this.serverApi.formItemsApi
        .get(FormLayoutCategory.CORPORATION_CLIENT, formLayoutId.value)
        .pipe(
          map(items => Success(this.converter.toCorporationClientFormItems(corporation, items))),
          catchError(() => of(Failure(GeneralFailure.Unexpected)))
        )
  }

  getCorporationClientFormItemsForAdd(formLayoutId: FormLayoutId | undefined): Observable<FormItems> {
    if (formLayoutId === undefined)
      return this.serverApi.defaultFormItemsApi
        .get(FormLayoutCategory.CORPORATION_CLIENT)
        .pipe(map(formItems => this.converter.toCorporationClientFormItemsForAdd(formItems)));
    else
      return this.serverApi.formItemsApi
        .get(FormLayoutCategory.CORPORATION_CLIENT, formLayoutId.value)
        .pipe(map(formItems => this.converter.toCorporationClientFormItemsForAdd(formItems)));
  }

  getIndividualClientFormItems(individual: Individual, formLayoutId: FormLayoutId | undefined): Observable<Result<
    FormItems,
    | typeof GeneralFailure.Unexpected
  >> {
    return formLayoutId === undefined
      ? this.serverApi.defaultFormItemsApi
        .get(FormLayoutCategory.INDIVIDUAL_CLIENT)
        .pipe(
          map(items => Success(this.converter.toIndividualClientFormItems(individual, items))),
          catchError(() => of(Failure(GeneralFailure.Unexpected)))
        )
      : this.serverApi.formItemsApi
        .get(FormLayoutCategory.INDIVIDUAL_CLIENT, formLayoutId.value)
        .pipe(
          map(items => Success(this.converter.toIndividualClientFormItems(individual, items))),
          catchError(() => of(Failure(GeneralFailure.Unexpected)))
        )
  }

  getIndividualClientFormItemsForAdd(formLayoutId: FormLayoutId | undefined): Observable<FormItems> {
    if (formLayoutId === undefined)
      return this.serverApi.defaultFormItemsApi
        .get(FormLayoutCategory.INDIVIDUAL_CLIENT)
        .pipe(map(formItems => this.converter.toIndividualClientFormItemsForAdd(formItems)));
    else
      return this.serverApi.formItemsApi
        .get(FormLayoutCategory.INDIVIDUAL_CLIENT, formLayoutId.value)
        .pipe(map(formItems => this.converter.toIndividualClientFormItemsForAdd(formItems)));
  }
}

@Injectable({providedIn: 'root'})
export class FormItemsV2Converter {

  toProposalFormItems(
    proposal: Proposal | undefined,
    formItemsResponse: GetFormItemsResponse
  ): FormItems {
    const formItems = formItemsResponse.items.map(formItemResponse => {
      const layoutItemType = formItemResponse.layoutItemType;
      switch (layoutItemType) {
        case 'SECTION_TITLE':
          return this._toSectionItem(formItemResponse);
        case 'CUSTOM_FIELD':
          let customFieldValue: CustomFieldValue | undefined;
          if (proposal !== undefined)
            customFieldValue = proposal.items.get(formItemResponse.customFieldID) ?? new CustomFieldValue('');
          return this._toCustomFormItem(customFieldValue, formItemResponse);
        case 'BUILTIN_FIELD':
          return this._toProposalBuiltinFormItem(proposal, formItemResponse);
        default:
          const unexpected: never = layoutItemType;
          throw Error(`${unexpected} is unexpected value`);
      }
    });
    return new FormItems(formItems)
  };

  toProductFormItems(
    product: Product | undefined,
    formItemsResponse: GetFormItemsResponse
  ): FormItems {
    const formItems = formItemsResponse.items.map(formItemResponse => {
      const layoutItemType = formItemResponse.layoutItemType;
      switch (layoutItemType) {
        case 'SECTION_TITLE':
          return this._toSectionItem(formItemResponse);
        case 'CUSTOM_FIELD':
          let customFieldValue: CustomFieldValue | undefined;
          if (product !== undefined) {
            const customFieldItem = product.items?.find(item => item.fieldId.value === formItemResponse.customFieldID);
            customFieldValue = customFieldItem === undefined ? new CustomFieldValue('') : customFieldItem.fieldValue;
          }
          return this._toCustomFormItem(customFieldValue, formItemResponse);
        case 'BUILTIN_FIELD':
          return this._toProductBuiltinFormItem(product, formItemResponse);
        default:
          const unexpected: never = layoutItemType;
          throw Error(`${unexpected} is unexpected value`);
      }
    });
    return new FormItems(formItems)
  };

  toOpportunityGroupFormItems(
    opportunityGroup: OpportunityGroup | undefined,
    formItemsResponse: GetFormItemsResponse
  ): FormItems {
    const formItems = formItemsResponse.items.map(formItemResponse => {
      const layoutItemType = formItemResponse.layoutItemType;
      switch (layoutItemType) {
        case 'SECTION_TITLE':
          return this._toSectionItem(formItemResponse);
        case 'CUSTOM_FIELD':
          let customFieldValue: CustomFieldValue | undefined;
          if (opportunityGroup !== undefined) {
            const customFieldItem = opportunityGroup.items?.find(item => item.fieldId.value === formItemResponse.customFieldID);
            customFieldValue = customFieldItem === undefined ? new CustomFieldValue('') : customFieldItem.fieldValue;
          }
          return this._toCustomFormItem(customFieldValue, formItemResponse);
        case 'BUILTIN_FIELD':
          return this._toOpportunityGroupBuiltinFormItem(opportunityGroup, formItemResponse);
        default:
          const unexpected: never = layoutItemType;
          throw Error(`${unexpected} is unexpected value`);
      }
    });
    return new FormItems(formItems)
  };

  toOpportunityFormItems(
    opportunity: OpportunityV2,
    res: GetFormItemsResponse
  ): FormItems {
    return new FormItems(
      res.items.map(item => {
        const layoutItemType = item.layoutItemType;
        switch (layoutItemType) {
          case 'SECTION_TITLE':
            return this._toSectionItem(item);
          case 'CUSTOM_FIELD':
            const customFieldItem = opportunity.items?.find(
              opportunityItem => opportunityItem.fieldId.value === item.customFieldID
            );
            return this._toCustomFormItem(customFieldItem?.fieldValue, item);
          case 'BUILTIN_FIELD':
            return this._toOpportunityBuiltinFormItem(opportunity, item);
          default:
            const unexpected: never = layoutItemType;
            throw Error(`${unexpected} is unexpected value`);
        }
      })
    )
  };

  toOpportunityFormItemsForAdd(
    formItemsResponse: GetFormItemsResponse
  ): FormItems {
    const formItems = formItemsResponse.items.map(formItemResponse => {
      const layoutItemType = formItemResponse.layoutItemType;
      switch (layoutItemType) {
        case 'SECTION_TITLE':
          return this._toSectionItem(formItemResponse);
        case 'CUSTOM_FIELD':
          return this._toCustomFormItem(undefined, formItemResponse);
        case 'BUILTIN_FIELD':
          return this._toOpportunityBuiltinFormItemForAdd(formItemResponse);
        default:
          const unexpected: never = layoutItemType;
          throw Error(`${unexpected} is unexpected value`);
      }
    });
    return new FormItems(formItems)
  };

  toCorporationClientFormItems(
    corporation: Corporation,
    formItemsResponse: GetFormItemsResponse
  ): FormItems {
    return new FormItems(
      formItemsResponse.items.map(item => {
        const layoutItemType = item.layoutItemType;
        switch (layoutItemType) {
          case 'SECTION_TITLE':
            return this._toSectionItem(item);
          case 'CUSTOM_FIELD':
            const customFieldItem = corporation.items?.find(
              corporationItem => corporationItem.fieldId.value === item.customFieldID
            );
            return this._toCustomFormItem(customFieldItem?.fieldValue, item);
          case 'BUILTIN_FIELD':
            return this._toCorporationClientBuiltinFormItem(corporation, item);
          default:
            const unexpected: never = layoutItemType;
            throw Error(`${unexpected} is unexpected value`);
        }
      })
    );
  }

  toCorporationClientFormItemsForAdd(
    formItemsResponse: GetFormItemsResponse
  ): FormItems {
    const formItems = formItemsResponse.items.map(formItemResponse => {
      const layoutItemType = formItemResponse.layoutItemType;
      switch (layoutItemType) {
        case 'SECTION_TITLE':
          return this._toSectionItem(formItemResponse);
        case 'CUSTOM_FIELD':
          return this._toCustomFormItem(undefined, formItemResponse);
        case 'BUILTIN_FIELD':
          return this._toCorporationClientBuiltinFormItemForAdd(formItemResponse);
        default:
          const unexpected: never = layoutItemType;
          throw Error(`${unexpected} is unexpected value`);
      }
    });
    return new FormItems(formItems)
  };

  toIndividualClientFormItems(
    individual: Individual,
    formItemsResponse: GetFormItemsResponse
  ): FormItems {
    return new FormItems(
      formItemsResponse.items.map(item => {
        const layoutItemType = item.layoutItemType;
        switch (layoutItemType) {
          case 'SECTION_TITLE':
            return this._toSectionItem(item);
          case 'CUSTOM_FIELD':
            const customFieldItem = individual.items?.find(
              individualItem => individualItem.fieldId.value === item.customFieldID
            );
            return this._toCustomFormItem(customFieldItem?.fieldValue, item);
          case 'BUILTIN_FIELD':
            return this._toIndividualClientBuiltinFormItem(individual, item);
          default:
            const unexpected: never = layoutItemType;
            throw Error(`${unexpected} is unexpected value`);
        }
      })
    );
  }

  toIndividualClientFormItemsForAdd(
    formItemsResponse: GetFormItemsResponse
  ): FormItems {
    const formItems = formItemsResponse.items.map(formItemResponse => {
      const layoutItemType = formItemResponse.layoutItemType;
      switch (layoutItemType) {
        case 'SECTION_TITLE':
          return this._toSectionItem(formItemResponse);
        case 'CUSTOM_FIELD':
          return this._toCustomFormItem(undefined, formItemResponse);
        case 'BUILTIN_FIELD':
          return this._toIndividualClientBuiltinFormItemForAdd(formItemResponse);
        default:
          const unexpected: never = layoutItemType;
          throw Error(`${unexpected} is unexpected value`);
      }
    });
    return new FormItems(formItems)
  };

  private _toSectionItem(sectionFormResponse: SectionFormItemData): SectionItemV2 {
    return new SectionItemV2(sectionFormResponse.width, new SectionTitle(sectionFormResponse.title));
  };

  private _toProductBuiltinFormItem(
    product: Product | undefined,
    builtinFormResponse: BuiltinFormItemData
  ): BuiltinFormItemV2 {
    let builtinField: BuiltinFieldV2;
    switch (builtinFormResponse.builtinFieldID) {
      case 'PRODUCT_NAME':
        builtinField = product === undefined ? ProductName.defaultValue() : product.name;
        break;
      case 'PRODUCT_PRICE':
        builtinField = product === undefined ? ProductPrice.defaultValue() : product.price;
        break;
      case 'PRODUCT_QUANTITY_UNIT':
        builtinField = product === undefined ? ProductQuantityUnit.defaultValue() : product.unit;
        break;
      default:
        throw Error(`${builtinFormResponse.builtinFieldID} is unexpected value`);
    }
    return new BuiltinFormItemV2(builtinFormResponse.width, builtinFormResponse.builtinFieldID, builtinField);
  };

  private _toOpportunityGroupBuiltinFormItem(
    opportunityGroup: OpportunityGroup | undefined,
    builtinFormResponse: BuiltinFormItemData
  ): BuiltinFormItemV2 {
    let builtinField: BuiltinFieldV2;
    switch (builtinFormResponse.builtinFieldID) {
      case 'OPPORTUNITY_GROUP_TITLE':
        builtinField = opportunityGroup === undefined ? OpportunityGroupTitle('') : opportunityGroup.title;
        break;
      default:
        throw Error(`${builtinFormResponse.builtinFieldID} is unexpected value`);
    }
    return new BuiltinFormItemV2(builtinFormResponse.width, builtinFormResponse.builtinFieldID, builtinField);
  };

  private _toOpportunityBuiltinFormItem(
    opportunity: OpportunityV2,
    itemData: BuiltinFormItemData
  ): BuiltinFormItemV2 {
    const builtinFieldID = itemData.builtinFieldID;
    switch (builtinFieldID) {
      case 'OPPORTUNITY_TITLE':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, opportunity.opportunityName);
      case 'EXPECTED_AMOUNT':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, opportunity.expectedAmount);
      case 'ASSIGNEE':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, opportunity.assignee);
      case 'EXPECTED_CLOSE_DATE':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, opportunity.expectedCloseDate);
      case 'OPPORTUNITY_ACCRUAL_DATE':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, opportunity.opportunityAccrualDate);
      default:
        throw Error(`${builtinFieldID} is unexpected value`);
    }
  }

  private _toOpportunityBuiltinFormItemForAdd(
    builtinFormResponse: BuiltinFormItemData
  ): BuiltinFormItemV2 {
    if (!(Object.keys(OpportunityBuiltinFieldId).includes(builtinFormResponse.builtinFieldID))) {
      throw Error(`${builtinFormResponse.builtinFieldID} is unexpected value`);
    }

    return new BuiltinFormItemV2(builtinFormResponse.width, builtinFormResponse.builtinFieldID, undefined);
  };

  private _toCorporationClientBuiltinFormItem(
    corporation: Corporation,
    itemData: BuiltinFormItemData
  ): BuiltinFormItemV2 {
    const builtinFieldID = itemData.builtinFieldID;
    switch (builtinFieldID) {
      case 'CORPORATION_NAME':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, corporation.corporationName);
      case 'CORPORATION_NAME_KANA':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, corporation.corporationNameKana);
      case 'CORPORATE_NUMBER':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, corporation.corporateNumber);
      case 'CORPORATE_PHONE_NUMBER':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, corporation.phoneNumber);
      case 'CORPORATE_ADDRESS':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, corporation.address);
      case 'ESTABLISHED':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, corporation.established);
      case 'NUMBER_OF_EMPLOYEES':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, corporation.numberOfEmployees);
      case 'INITIAL_CAPITAL':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, corporation.initialCapital);
      case 'FISCAL_YEAR_END':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, corporation.fiscalYearEnd);
      case 'WEBSITE':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, corporation.website);
      default:
        throw Error(`${builtinFieldID} is unexpected value`);
    }
  }

  private _toCorporationClientBuiltinFormItemForAdd(
    builtinFormResponse: BuiltinFormItemData
  ): BuiltinFormItemV2 {
    if (!(Object.keys(CorporationClientBuiltinFieldId).includes(builtinFormResponse.builtinFieldID))) {
      throw Error(`${builtinFormResponse.builtinFieldID} is unexpected value`);
    }
    return new BuiltinFormItemV2(builtinFormResponse.width, builtinFormResponse.builtinFieldID, undefined);
  };

  private _toIndividualClientBuiltinFormItem(
    individual: Individual,
    itemData: BuiltinFormItemData
  ): BuiltinFormItemV2 {
    const builtinFieldID = itemData.builtinFieldID;
    switch (builtinFieldID) {
      case 'LAST_NAME':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, individual.lastName);
      case 'FIRST_NAME':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, individual.firstName);
      case 'LAST_NAME_KANA':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, individual.lastNameKana);
      case 'FIRST_NAME_KANA':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, individual.firstNameKana);
      case 'INDIVIDUAL_PHONE_NUMBER':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, individual.phoneNumber);
      case 'INDIVIDUAL_ADDRESS':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, individual.address);
      case 'INDIVIDUAL_SNS1':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, individual.sns1);
      case 'INDIVIDUAL_SNS2':
        return new BuiltinFormItemV2(itemData.width, builtinFieldID, individual.sns2);
      default:
        throw Error(`${builtinFieldID} is unexpected value`);
    }
  }

  private _toIndividualClientBuiltinFormItemForAdd(
    builtinFormResponse: BuiltinFormItemData
  ): BuiltinFormItemV2 {
    if (!(Object.keys(IndividualClientBuiltinFieldId).includes(builtinFormResponse.builtinFieldID))) {
      throw Error(`${builtinFormResponse.builtinFieldID} is unexpected value`);
    }
    return new BuiltinFormItemV2(builtinFormResponse.width, builtinFormResponse.builtinFieldID, undefined);
  };

  private _toProposalBuiltinFormItem(
    proposal: Proposal | undefined,
    builtinFormResponse: BuiltinFormItemData
  ): BuiltinFormItemV2 {
    let builtinField: BuiltinFieldV2;
    switch (builtinFormResponse.builtinFieldID) {
      case 'PROPOSAL_TITLE':
        builtinField = proposal === undefined ? ProposalTitle.defaultValue() : proposal.title;
        break;
      default:
        throw Error(`${builtinFormResponse.builtinFieldID} is unexpected value`);
    }
    return new BuiltinFormItemV2(builtinFormResponse.width, builtinFormResponse.builtinFieldID, builtinField);
  };

  private _toCustomFormItem = (
    customFieldValue: CustomFieldValue | undefined,
    customFormResponse: CustomFormItemData
  ): CustomFormItemV2 => {
    const customFieldID = new CustomFieldId(customFormResponse.customFieldID);
    return new CustomFormItemV2(
      customFormResponse.width,
      new CustomField(
        customFieldID,
        new CustomFieldName(customFormResponse.name),
        customFormResponse.fieldType,
        customFormResponse.required,
        new CustomFieldDescription(customFormResponse.description),
        customFormResponse.numberSetting
          ? this._toNumberSettings(customFormResponse.numberSetting)
          : undefined,
        customFormResponse.selectSetting
          ? this._toSelectSettings(customFormResponse.selectSetting)
          : undefined,
      ),
      customFieldValue
    )
  };

  private _toNumberSettings(numberSettingResponse: ApiCustomFieldNumberSettingData): NumberSettings {
    return new NumberSettings(
      numberSettingResponse.min || numberSettingResponse.min === 0 ?
        new CustomFieldNumberValue(numberSettingResponse.min) :
        undefined,
      numberSettingResponse.max || numberSettingResponse.max === 0 ?
        new CustomFieldNumberValue(numberSettingResponse.max) :
        undefined,
      numberSettingResponse.defaultValue || numberSettingResponse.defaultValue === 0 ?
        new CustomFieldNumberValue(numberSettingResponse.defaultValue) :
        undefined,
      numberSettingResponse.unit ?
        new CustomFieldNumberUnit(numberSettingResponse.unit) :
        undefined,
    );
  };

  private _toSelectSettings(selectSettingResponse: ApiCustomFieldSelectSettingData): SelectSettings {
    return new SelectSettings(
      selectSettingResponse.multiple,
      selectSettingResponse.allowInput,
      selectSettingResponse.options.map(option => new SelectItemValue(option))
    );
  };
}
