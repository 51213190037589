import { Observable } from 'rxjs';
import { ContactHistoryTemplate } from './contact-history-template';

export abstract class ContactHistoryTemplateRepository {
  abstract save(template: ContactHistoryTemplate): Observable<void>;

  abstract delete(template: ContactHistoryTemplate): Observable<void>;

  abstract saveOrders(templates: ContactHistoryTemplate[]): Observable<void>;
}
