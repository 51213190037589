import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { buildHttpParams } from './http-params-builder';

interface SearchActionFilterSingleParam {
  propertyName: string;
  type: 'equals' | 'contains' | 'greaterThanOrEqualTo' | 'lessThanOrEqualTo';
  value: string;
}

interface SearchActionFilterMultiParam {
  propertyName: string;
  type: 'anyMatch';
  values: string[];
}

export type SearchActionFilter =
  | SearchActionFilterSingleParam
  | SearchActionFilterMultiParam;

interface SearchActionRequestData {
  perPage: number;
  page: number;
  filter: SearchActionFilter[];
}

export type SearchActionRequest = SearchActionRequestData;

interface SearchActionResponseData {
  id: string;
  opportunityName: string;
  startDateTime: string;
  endDateTime: string;
  actionTypeName: string;
  currentPhaseName: string;
  startPhaseName: string;
  targetPhaseName: string;
  actualStartPhaseName?: string;
  actualPhaseName?: string;
  assignee: string;
  assigneeName: string;
  isDone: boolean;
  isEditable: boolean;
}

export type SearchActionResponse = SearchActionResponseData[];

type SearchActionResponseDataV2 = {
  actionID: string,
  companions?: {
    userID: string,
    userDisplayName: string,
  }[]
  attendees?: {
    contactID: string,
    contactDisplayName: string,
    departmentName: string,
    roleName: string,
  }[]
  actionStartDateTime: string;
  actionEndDateTime: string;
  opportunityID: string,
  opportunityName: string,
  currentPhaseID: string,
  currentPhaseName: string,
  startPhaseID: string,
  startPhaseName: string,
  targetPhaseID: string,
  targetPhaseName: string,
  actualStartPhaseID?: string,
  actualStartPhaseName?: string,
  actualPhaseID?: string,
  actualPhaseName?: string,
  actionTypeID: string,
  actionTypeName: string,
  actionNote: string,
  assignee: string,
  assigneeName: string,
  isDone: true;
}

export type SearchActionResponseV2 = SearchActionResponseDataV2[];

export type SearchActionListResponse = {
  totalCount: number;
  results: SearchActionResponseV2;
};

export class ActionListApi {
  constructor(private readonly http: HttpClient) {}

  search(opportunityID: string): Observable<SearchActionResponse> {
    const params = buildHttpParams({ 'opportunity-id': opportunityID });
    return this.http.get<SearchActionResponse>(`/api/action-list/search`, {
      params: params,
    });
  }

  search2(request: SearchActionRequest): Observable<SearchActionListResponse> {
    return this.http.post<SearchActionListResponse>(
      `/api/action-list/search`,
      request
    );
  }
}
