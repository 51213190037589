import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiCoachingTiming } from "./server-insight-api";

export type TalkCoachingRequest = {
  actionID: string,
  coachingTiming: ApiCoachingTiming,
}

export type FinishedCoachingResponse = {
  status: 'FINISHED',
}

export type ContinueCoachingResponse = {
  status: 'CONTINUE',
  coachingID: string,
  index: number,
  insight: {
    id: string,
    question: string,
    supplementTitle: string,
    supplement: string,
  } & (TextTypeAnswer | SelectionTypeAnswer)
}
type TextTypeAnswer = {
  answerType: 'TEXT',
}
type SelectionTypeAnswer = {
  answerType: 'SELECTION',
  selection: {
    multiple: boolean,
    answerOptions: {
      id: string,
      answer: string,
      hint?: {
        id: string,
        title: string,
        content: string,
        review: 'GOOD' | 'BAD' | 'NONE',
      },
    }[],
  }
}

export type CoachingResponse = FinishedCoachingResponse | ContinueCoachingResponse;

export type ReplyCoachingRequest = {
  answer: TextTypeReply | SelectionTypeReply,
}
type TextTypeReply = {
  text: {
    value: string,
  },
}
type SelectionTypeReply = {
  selection: {
    values: string[],
  },
}

export class CoachingApi {
  constructor(
    private readonly http: HttpClient,
  ) {}

  talk(request: TalkCoachingRequest): Observable<CoachingResponse> {
    return this.http.post<CoachingResponse>(`/api/coaching/talk`, request);
  }

  reply(id: string, request: ReplyCoachingRequest): Observable<CoachingResponse> {
    return this.http.post<CoachingResponse>(`/api/coaching/${id}/reply`, request);
  }

  undo(id: string): Observable<ContinueCoachingResponse> {
    return this.http.post<ContinueCoachingResponse>(`/api/coaching/${id}/undo`, {});
  }
}
