import {Observable} from "rxjs";
import {Result} from "../../lib/result/result";
import {GeneralFailure} from "../../lib/general-failure/general-failure";
import {FilterInput} from "#infrastructure/application/filter.service";
import {ClientID, ClientName} from "../../model/client/client";

export type ClientForSelectFilterInput = {
  perPage: number,
  page: number,
  filterInput: FilterInput,
}

export type ClientForSelectFilterOutput = {
  totalCount: number,
  results: {
    id: ClientID,
    name: ClientName,
  }[]
}

export abstract class ClientForSelectQueryService {
  abstract filter(input: ClientForSelectFilterInput): Observable<Result<
    ClientForSelectFilterOutput,
    | typeof GeneralFailure.Unexpected
  >>;
}
