import { FollowUpActionDue, FollowUpActionID, FollowUpActionIsDone, FollowUpActionSummary } from "app/model/follow-up-action/follow-up-action";
import { FollowUpActionTypeID, FollowUpActionTypeName } from "app/model/follow-up-action/follow-up-action-type";
import { OpportunityID, OpportunityName } from "app/model/opportunity/opportunity";
import { DisplayName, UserID } from "app/model/user/user";
import { Observable } from "rxjs";

export type FollowUpActionListOfOpportunity = ReadonlyArray<{
  id: FollowUpActionID,
  createdBy: Readonly<{
    id: UserID,
    name: DisplayName,
  }>,
  opportunity: Readonly<{
    id: OpportunityID,
    name: OpportunityName,
  }>,
  due: FollowUpActionDue,
  followUpActionType: Readonly<{
    id: FollowUpActionTypeID,
    name: FollowUpActionTypeName,
  }>,
  assignee: Readonly<{
    id: UserID,
    name: DisplayName,
  }>,
  contentsSummary: FollowUpActionSummary,
  isDone: FollowUpActionIsDone,
}>

export abstract class OpportunityFollowUpActionQueryService {
  abstract list(opportunityID: OpportunityID): Observable<FollowUpActionListOfOpportunity>;
}