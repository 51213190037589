import { MessageDialogService } from "#application/services/message-dialog.service";
import { ListRoleOutputs, ListRoleSummaryOutputs, RoleQueryService } from "#application/services/role-query.service";
import { Role, RoleDepth, RoleID, RoleName, RoleUpdatedAt } from "app/model/role/role";
import { DisplayName, UserID } from "app/model/user/user";
import { ServerApi } from "#infrastructure/api/server-api";
import { GetRoleResponse, ListRoleResponse } from "#infrastructure/api/server-role-api";
import { fromUTCStrToDate } from "#utils/date";
import { Target } from "#utils/messages";
import { Injectable, inject } from "@angular/core";
import { Observable, catchError, map, throwError } from "rxjs";

@Injectable({ providedIn: 'root' })
export class RoleQueryServiceImpl implements RoleQueryService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _messageDialogService = inject(MessageDialogService);

  list(): Observable<ListRoleOutputs> {
    return this._serverApi.roleApi
      .list()
      .pipe(
        map(this._toListRoleOutputs),
        catchError((err) => {
          this._messageDialogService.notice({
            errorTarget: `${Target.ROLE}一覧の取得`,
          });
          return throwError(err);
        }),
      );
  }

  listSummary(): Observable<ListRoleSummaryOutputs> {
    return this._serverApi.roleApi
      .list()
      .pipe(
        map(this._toListRoleSummaryOutputs),
        catchError((err) => {
          this._messageDialogService.notice({
            errorTarget: `${Target.ROLE}一覧の取得`,
          });
          return throwError(err);
        }),
      );
  }

  get(id : RoleID): Observable<Role> {
    return this._serverApi.roleApi
      .get(id.value)
      .pipe(
        map(this._toGetRoleOutputs),
        catchError((err) => {
          this._messageDialogService.notice({
            errorTarget: `${Target.ROLE}の取得`,
          });
          return throwError(err);
        }),
      );
  }

  private _toListRoleOutputs(res: ListRoleResponse): ListRoleOutputs {
    return res.map(role => ({
      id: new RoleID(role.id),
      name: new RoleName(role.name),
      depth: new RoleDepth(role.depth),
      parentRole: role.parentRole
        ? {
          id: new RoleID(role.parentRole.id),
          name: new RoleName(role.parentRole.name),
        }
        : undefined,
      membersCount: role.membersCount,
      updatedAt: new RoleUpdatedAt(fromUTCStrToDate(role.updatedAt)),
    }));
  }

  private _toListRoleSummaryOutputs(res: ListRoleResponse): ListRoleSummaryOutputs {
    return res.map(role => ({
      id: new RoleID(role.id),
      name: new RoleName(role.name),
    }));
  }

  private _toGetRoleOutputs(res: GetRoleResponse): Role {
    return {
      id : new RoleID(res.id),
      name : new RoleName(res.name),
      parentRole: res.parentRole
        ? {
          id : new RoleID(res.parentRole.id),
          name : new RoleName(res.parentRole.name),
        }
        : undefined,
      ownedClientsCount: res.ownedClientsCount,
      childRoles: res.childRoles.map(role => ({
        id : new RoleID(role.id),
        name : new RoleName(role.name),
      })),
      members: res.members.map(member => ({
        id : new UserID(member.id),
        name : new DisplayName(member.name),
      })),
    };
  }
}
