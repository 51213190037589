import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {InsightFeedback} from "app/model/insight/insight-feedback";

@Injectable({
  providedIn: 'root'
})
export abstract class InsightFeedbackCommandService {
  abstract register(insightFeedback: InsightFeedback): Observable<void>;
}
