import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export type CreateWonReasonRequest = {
  name: string,
  description: string,
}

type WonReasonData = {
  id: string,
  name: string,
  description: string,
}

export type ListWonReasonResponse = {
  wonReasons: WonReasonData[]
}

export type GetWonReasonResponse = WonReasonData;

export type UpdateWonReasonRequest = CreateWonReasonRequest;

export type SortWonReasonRequest = {
  ids: string[]
}

export class WonReasonApi {
  constructor(private readonly http: HttpClient) {}

  create(request: CreateWonReasonRequest): Observable<void> {
    return this.http.post<void>(`/api/won-reasons`, request);
  }

  list(): Observable<ListWonReasonResponse> {
    return this.http.get<ListWonReasonResponse>(`/api/won-reasons/list`);
  }

  get(id: string): Observable<GetWonReasonResponse> {
    return this.http.get<GetWonReasonResponse>(`/api/won-reasons/${id}`);
  }

  update(id: string, request: UpdateWonReasonRequest): Observable<void> {
    return this.http.put<void>(`/api/won-reasons/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/won-reasons/${id}`);
  }

  sort(request: SortWonReasonRequest): Observable<void> {
    return this.http.patch<void>(`/api/won-reasons/sort`, request);
  }
}
