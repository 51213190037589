import { OpportunityID } from "app/model/opportunity/opportunity";
import { UserID } from "app/model/user/user";
import { Observable } from "rxjs";
import { FollowUpActionDetail, FollowUpActionDue, FollowUpActionID, FollowUpActionSummary } from "./follow-up-action";
import { FollowUpActionTypeID } from "./follow-up-action-type";

export type SaveFollowUpActionDto = Readonly<{
  id: FollowUpActionID,
  opportunityID: OpportunityID,
  due: FollowUpActionDue,
  followUpActionTypeID: FollowUpActionTypeID,
  assigneeID: UserID,
  summary: FollowUpActionSummary,
  detail: FollowUpActionDetail,
}>

export abstract class FollowUpActionRepository {
  abstract delete(followUpActionID: FollowUpActionID): Observable<void>;

  abstract save(dto: SaveFollowUpActionDto): Observable<void>;

  abstract done(followUpActionID: FollowUpActionID): Observable<void>;

  abstract unDone(followUpActionID: FollowUpActionID): Observable<void>;
}
