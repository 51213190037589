import { DisplayName, UserID, LoginID } from "app/model/user/user";

export class RestAuthTokenID {
  constructor(public readonly value: string) {}
}

export class RestAuthTokenName {
  constructor(public readonly value: string) {}

  static readonly MAX_LENGTH = 30;
}

export class RestAuthTokenKeyTail {
  constructor(public readonly value: string) {}

  toFormat(): string {
    return '*'.repeat(30) + this.value;
  }
}

export class RestAuthTokenCreatedAt {
  constructor(public readonly value: Date) {}
}

export class RestAuthTokenString {
  constructor(public readonly value: string) {}
}

export enum RestAuthTokenKeyType {
  Primary = 'primary',
  Secondary = 'secondary',
}

export class RestAuthTokenKeyCreatedAt {
  constructor(public readonly value: Date) {}
}

export type RestAuthTokenSubjectID = UserID;

export type RestAuthTokenSubjectLoginID = LoginID;

export type RestAuthTokenSubjectName = DisplayName;

export type RestAuthToken = Readonly<{
  id: RestAuthTokenID,
  name: RestAuthTokenName,
  subject: Readonly<{
    id: RestAuthTokenSubjectID,
    loginID: RestAuthTokenSubjectLoginID,
    name: RestAuthTokenSubjectName,
  }> | undefined,
}>;