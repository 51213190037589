import {Injectable} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {ServerApi} from "#infrastructure/api/server-api";
import {MessageDialogService} from "#application/services/message-dialog.service";
import {catchError, map} from "rxjs/operators";
import {
  SearchWebhookEventHistoryInput,
  WebhookEventHistory,
  WebhookEventHistoryQueryService
} from "#application/services/webhook-event-history-query.service";
import {DateTime} from "luxon";

@Injectable({ providedIn: 'root' })
export class WebhookEventHistoryQueryServiceImpl extends WebhookEventHistoryQueryService {

  constructor(
    private readonly serverApi: ServerApi,
    private readonly messageDialogService: MessageDialogService,
  ) {
    super();
  }

  search(input: SearchWebhookEventHistoryInput): Observable<WebhookEventHistory> {
    return this.serverApi.webhookEventHistoryApi
      .search(input)
      .pipe(
        map(response => ({
          totalCount: response.totalCount,
          results: response.results.map(result => ({
            success: result.success,
            eventID: result.eventID,
            timestamp: DateTime.fromFormat(result.timestamp, 'yyyy-MM-dd HH:mm:ss', { zone: 'UTC' }),
            request: result.request,
            response: result.response,
            error: result.error,
          }))
        })),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: 'Webhookイベント履歴の取得'
          });
          return throwError(err);
        })
      );
  }

}
