import { SalesPhase, SalesPhaseID, SalesPhaseName } from "app/model/sales-phase/sales-phase";
import { Observable } from "rxjs";

/**
 * 商談フェーズの概要リスト
 */
export class SalesPhaseSummaries {
  constructor(
    public readonly list: SalesPhaseSummary[],
  ) {}

  isNotEmpty(): boolean {
    return this.list.length > 0;
  }
}

/**
 * 商談フェーズの概要
 */
export type SalesPhaseSummary =  {
  id: SalesPhaseID,
  name: SalesPhaseName,
}

export abstract class SalesPhaseQueryService {
  abstract listSummaries(): Observable<SalesPhaseSummaries>;
  abstract get(salesPhaseID: SalesPhaseID): Observable<SalesPhase>;
}
