import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { ProductTypeId } from "app/model/product-type/product-type";
import { ProductName, ProductPrice, ProductQuantityUnit, ProductItem, ProductId } from "app/model/product/product";
import { Observable } from "rxjs";

export type AddProductInput = Readonly<{
  productTypeID: ProductTypeId,
  name: ProductName,
  price: ProductPrice,
  unit: ProductQuantityUnit,
  items: ProductItem[],
}>

export type UpdateProductInput = Omit<AddProductInput, 'productTypeID'>;

export abstract class ProductCommandV2Service {
  abstract add(input: AddProductInput): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
  >>;

  abstract update(id: ProductId, input: UpdateProductInput): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>;

  abstract delete(id: ProductId): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>;
}