import { Observable } from "rxjs";

export type MessageDialogData = {
  title?: string,
  message?: string,
  errorTarget?: string,
};

export abstract class MessageDialogService {
  /**
   * メッセージダイアログによる通知を行う（エラー発生時の案内等）
   * @param data 通知のタイトルとメッセージ
   */
  abstract notice(data: MessageDialogData): void;

  /**
   * メッセージダイアログによる通知を行い、閉じられるのを待つ（後続に処理を繋げる）
   * @param data 通知のタイトルとメッセージ
   */
  abstract notice$(data: MessageDialogData): Observable<void>;
}
