import { NurturingTypeDescription, NurturingTypeID, NurturingTypeName } from "app/model/nurturing-type/nurturing-type";
import { Observable } from "rxjs";

export type AddNurturingTypeInput = Readonly<{
  name: NurturingTypeName,
  description: NurturingTypeDescription,
}>;

export type UpdateNurturingTypeInput = Readonly<{
  id: NurturingTypeID,
  name: NurturingTypeName,
  description: NurturingTypeDescription,
}>;

export abstract class NurturingTypeCommandService {
  abstract add(input: AddNurturingTypeInput): Observable<void>;
  abstract update(input: UpdateNurturingTypeInput): Observable<void>;
  abstract delete(id: NurturingTypeID): Observable<void>;
  abstract sort(ids: NurturingTypeID[]): Observable<void>;
}
