import { EventBaseStart } from "../event/event-v2";
import { ScheduleActionStatus, ScheduleEnd, ScheduleID, ScheduleItemType, ScheduleName, ScheduleNurturingStatus, ScheduleStart } from "./pc-schedule";
import { ScheduleRecurringRule } from "./schedule-recurring-rule";

/** モバイル表示用「分」単位スケジュール項目 */
export type MbScheduleMinutesItem = {
  readonly type: ScheduleItemType,
  readonly id: ScheduleID,
  readonly name: ScheduleName,
  readonly originalStart: ScheduleStart,
  readonly originalEnd: ScheduleEnd,
}

/** モバイル表示用「日」単位スケジュール項目 */
export type MbScheduleDaysItem = MbScheduleDaysEventItem;

/** モバイル表示用スケジュールの活動項目 */
export type MbScheduleActionItem = {
  readonly type: typeof ScheduleItemType.ACTION;
  readonly id: ScheduleID,
  readonly status: ScheduleActionStatus,
  readonly name: ScheduleName,
  readonly originalStart: ScheduleStart,
  readonly originalEnd: ScheduleEnd,
}

/** モバイル表示用スケジュールのナーチャリング項目 */
export type MbScheduleNurturingItem = {
  readonly type: typeof ScheduleItemType.NURTURING;
  readonly id: ScheduleID,
  readonly status: ScheduleNurturingStatus,
  readonly name: ScheduleName,
  readonly originalStart: ScheduleStart,
  readonly originalEnd: ScheduleEnd,
}

/** モバイル表示用スケジュールの分単位活動項目 */
export class MbScheduleMinutesActionItem implements MbScheduleMinutesItem, MbScheduleActionItem {
  readonly type = ScheduleItemType.ACTION;

  constructor(
    readonly id: ScheduleID,
    readonly status: ScheduleActionStatus,
    readonly name: ScheduleName,
    readonly originalStart: ScheduleStart,
    readonly originalEnd: ScheduleEnd,
  ) {}
}

/** モバイル表示用スケジュールの分単位ナーチャリング項目 */
export class MbScheduleMinutesNurturingItem implements MbScheduleMinutesItem, MbScheduleNurturingItem {
  readonly type = ScheduleItemType.NURTURING;

  constructor(
    readonly id: ScheduleID,
    readonly status: ScheduleNurturingStatus,
    readonly name: ScheduleName,
    readonly originalStart: ScheduleStart,
    readonly originalEnd: ScheduleEnd,
  ) {}
}


/** モバイル表示用スケジュールのイベント項目 */
export type MbScheduleEventItem = {
  readonly type: typeof ScheduleItemType.EVENT;
  readonly id: ScheduleID,
  readonly name: ScheduleName,
  readonly originalStart: ScheduleStart,
  readonly originalEnd: ScheduleEnd,
}

/** モバイル表示用スケジュールの分単位イベント項目 */
export class MbScheduleMinutesEventItem implements MbScheduleMinutesItem, MbScheduleEventItem {
  readonly type = ScheduleItemType.EVENT;

  constructor(
    public readonly id: ScheduleID,
    public readonly name: ScheduleName,
    public readonly originalStart: ScheduleStart,
    public readonly originalEnd: ScheduleEnd,
    public readonly baseStart: EventBaseStart,
    public readonly recurring: ScheduleRecurringRule,
  ) {}
}

/** モバイル表示用スケジュールの日単位イベント項目 */
export class MbScheduleDaysEventItem implements MbScheduleEventItem {
  readonly type = ScheduleItemType.EVENT;

  constructor(
    public readonly id: ScheduleID,
    public readonly name: ScheduleName,
    public readonly originalStart: ScheduleStart,
    public readonly originalEnd: ScheduleEnd,
    public readonly baseStart: EventBaseStart,
    public readonly recurring: ScheduleRecurringRule,
  ) {}
}

/** 「分」単位活動項目か判定 */
export const isMinutesActionItem = (item: MbScheduleMinutesItem): boolean => {
  return item.type === ScheduleItemType.ACTION;
}

/** 「分」単位ナーチャリング項目か判定 */
export const isMinutesNurturingItem = (item: MbScheduleMinutesItem): boolean => {
  return item.type === ScheduleItemType.NURTURING;
}

/** 「分」単位イベント項目か判定 */
export const isMinutesEventItem = (item: MbScheduleMinutesItem): boolean => {
  return item.type === ScheduleItemType.EVENT;
}

/** 「日」単位イベント項目か判定 */
export const isDaysEventItem = (item: MbScheduleDaysItem): boolean => {
  return item.type === ScheduleItemType.EVENT;
}
