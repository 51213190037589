import { UserGroupID } from "app/model/user-group/user-group";
import { DisplayName, LoginID, UserID } from "app/model/user/user";
import { Observable } from "rxjs";
import { GeneralFailure } from "../../lib/general-failure/general-failure";
import { Result } from "../../lib/result/result";
import { AllUserGroupsForSelect } from "../../model/user-group-for-select/all-user-groups-for-select";
import { UserList } from "./user-query.service";

/**
 * 指定ユーザーグループの全ユーザー取得Output。
 * loginIDを持っているのは並び替えのため。
 */
export type UsersByOutputInUserGroupForSelect = {
  id: UserID,
  loginID: LoginID,
  name: DisplayName,
}[]

export abstract class UserGroupForSelectQueryService {
  abstract all(): Observable<Result<
    AllUserGroupsForSelect,
    | typeof GeneralFailure.Unexpected
  >>;

  /** 指定ユーザーグループの全ユーザー取得 */
  abstract usersBy(groupIDs: ReadonlyArray<UserGroupID>, userList: UserList): Observable<Result<
    UsersByOutputInUserGroupForSelect,
    | typeof GeneralFailure.Unexpected
  >>
}
