import {AuthorizeService, LocalizeKey} from '#application/services/authorize.service';
import { Page } from '#application/services/page-navigate.service';
import { FiscalYearBegin } from 'app/model/organization/organization';
import { SecurityRole } from 'app/model/security-role/security-role';
import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, Component, InjectionToken, OnDestroy, inject } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";
import { MatDialog } from '@angular/material/dialog';
import {LocalizedNameService} from "#application/services/localized-name.service";
import { ResponsiveComponent } from '../responsive/responsive.component';

interface MenuGroup {
  groupTitle: string;
  menuItems: MenuItem[];
}

interface MenuItem {
  url: string;
  title: string;
  iconName: string;
}

@Component({
  selector: 'sn-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PageContainerComponent extends ResponsiveComponent implements OnDestroy {
  readonly enableRoleConfig = environment.enableRoleConfig;

  private readonly _localizedNameService = inject(LocalizedNameService);
  readonly NURTURING_ALIAS = this._localizedNameService.get(LocalizeKey.NURTURING);
  readonly OPPORTUNITY_GROUP_ALIAS = this._localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP);

  /** メディア判定 */
  mobileQuery: MediaQueryList;

  /** Main Menu Group */
  private _mainMenu: MenuGroup = {
    groupTitle: 'メインメニュー',
    menuItems: [
      {
        url: Page.Coaching,
        title: 'コーチング',
        iconName: 'school',
      },
      {
        url: Page.OpportunityBoard,
        title: '商談ボード',
        iconName: 'view_kanban',
      },
      {
        url: Page.Action,
        title: '活動',
        iconName: 'work',
      },
      {
        url: Page.Opportunity,
        title: '商談',
        iconName: 'handshake',
      },
      {
        url: Page.Client,
        title: '顧客',
        iconName: 'business',
      },
      {
        url: Page.Contact,
        title: '連絡先',
        iconName: 'recent_actors',
      },
      {
        url: Page.Product,
        title: '商品',
        iconName: 'discount',
      },
    ],
  };

  /** My Data Group */
  private _myDataMenu: MenuGroup = {
    groupTitle: 'マイデータ',
    menuItems: [
      {
        url: Page.MyDataGoal,
        title: '目標',
        iconName: 'query_stats',
      },
      {
        url: Page.MyDataActivity,
        title: '活動量',
        iconName: 'query_stats',
      },
      {
        url: Page.MyDataCvr,
        title: '移行率',
        iconName: 'query_stats',
      },
      {
        url: Page.MyDataOpportunity,
        title: '商談',
        iconName: 'query_stats',
      },
    ],
  };

  /** Main Menu Group */
  private _reportMenu: MenuGroup = {
    groupTitle: 'レポート',
    menuItems: [
      {
        url: Page.ReportsGoal,
        title: '目標レポート',
        iconName: 'insert_chart_outlined',
      },
      {
        url: Page.ReportsActivity,
        title: '活動量レポート',
        iconName: 'insert_chart_outlined',
      },
      {
        url: Page.ReportsCvr,
        title: '移行率レポート',
        iconName: 'insert_chart_outlined'
      },
      {
        url: Page.ReportsOpportunity,
        title: '商談レポート',
        iconName: 'insert_chart_outlined',
      },
    ],
  };

  /** System Config Group */
  private _systemSettingMenu: MenuGroup = {
    groupTitle: 'システム設定',
    menuItems: [
      {
        url: Page.ConfigRole,
        title: 'ロール管理',
        iconName: 'group',
      },
      {
        url: Page.ConfigUser,
        title: 'ユーザー管理',
        iconName: 'group',
      },
      {
        url: Page.ConfigUserGroup,
        title: 'ユーザーグループ管理',
        iconName: 'group',
      },
      {
        url: Page.ConfigSalesPhaseList,
        title: '商談フェーズ管理',
        iconName: 'settings',
      },
      {
        url: Page.ConfigProduct,
        title: '商品設定',
        iconName: 'settings',
      },
      {
        url: Page.ConfigClient,
        title: '顧客設定',
        iconName: 'settings',
      },
      {
        url: Page.ConfigOpportunity,
        title: '商談設定',
        iconName: 'settings',
      },
      {
        url: Page.ConfigOpportunityGroup,
        title: `${this.OPPORTUNITY_GROUP_ALIAS}設定`,
        iconName: 'settings',
      },
      {
        url: Page.ConfigProposal,
        title: '提案設定',
        iconName: 'settings',
      },
      {
        url: Page.ConfigAction,
        title: '活動設定',
        iconName: 'settings',
      },
      {
        url: Page.ConfigFollowUpAction,
        title: 'フォロー設定',
        iconName: 'settings',
      },
      {
        url: Page.ConfigNurturing,
        title: `${this.NURTURING_ALIAS}設定`,
        iconName: 'settings',
      },
      {
        url: Page.ConfigLocalize,
        title: `ローカライズ設定`,
        iconName: 'settings',
      },
    ],
  };

  /** System Linkage Group */
  private _linkageSettingMenu: MenuGroup = {
    groupTitle: '連携設定',
    menuItems: [
      {
        url: Page.LinkageWebhook,
        title: 'Webhook設定',
        iconName: 'webhook',
      },
      {
        url: Page.LinkageRestAuthToken,
        title: '認証トークン設定',
        iconName: 'key',
      },
    ],
  };

  /** Menu For Admin */
  private _adminMenu: MenuGroup = {
    groupTitle: '管理者',
    menuItems: [
      {
        url: Page.AdminOrganization,
        title: '組織情報管理',
        iconName: 'domain',
      },
      {
        url: Page.AdminConsultant,
        title: 'コンサルタント管理',
        iconName: 'people_outline',
      }
    ],
  };

  /** Menu For Consultant */
  private _consultingMenu: MenuGroup = {
    groupTitle: 'コンサルタント',
    menuItems: [
      {
        url: Page.ConsultingAssignedOrganizationList,
        title: '担当組織一覧',
        iconName: 'domain',
      },
    ],
  };

  private _commonInsightManagementMenu: MenuGroup = {
    groupTitle: '共通インサイト管理',
    menuItems: [
      {
        url: Page.ConsultingCommonOpportunityContext,
        title: '共通商談コンテキスト管理',
        iconName: 'account_tree',
      },
      {
        url: Page.ConsultingCommonInsight,
        title: '共通インサイト管理',
        iconName: 'insights',
      },
      {
        url: Page.ConsultingCommonHint,
        title: '共通ヒント管理',
        iconName: 'tips_and_updates',
      },
    ],
  };

  private _organizationConsultantMenu: MenuGroup = {
    groupTitle: 'インサイト管理',
    menuItems: [
      {
        url: Page.InsightOpportunityContext,
        title: '商談コンテキスト管理',
        iconName: 'account_tree',
      },
      {
        url: Page.InsightManagement,
        title: '個別インサイト管理',
        iconName: 'insights',
      },
      {
        url: Page.InsightHint,
        title: '個別ヒント管理',
        iconName: 'tips_and_updates',
      },
      {
        url: Page.InsightContextSettingOpportunityType,
        title: '商談タイプ別商談コンテキスト設定',
        iconName: 'settings',
      },
      {
        url: Page.InsightIncorporation,
        title: '共通インサイト取込管理',
        iconName: 'download',
      },
    ],
  }

  get sideMenu(): MenuGroup[] {
    return this._sideMenu;
  }

  private _sideMenu: MenuGroup[] = [];

  /** 現在のページURL */
  currentUrl = '';

  private readonly _onDestroy$ = new Subject<void>();

  constructor(
    private readonly authorizeService: AuthorizeService,
    media: MediaMatcher,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    breakpointObserver: BreakpointObserver,
  ) {
    super(breakpointObserver);

    this.router.events
    .pipe(takeUntil(this._onDestroy$))
    .subscribe((event) => {
      this.currentUrl = this.router.url;
      if (event instanceof NavigationStart) {
        this.dialog.closeAll();
      }
    });

    // Tablet以下のサイズであればtrue
    this.mobileQuery = media.matchMedia('(max-width: 959.98px)');
    const securityRoles = this.authorizeService.getAuthInfo().securityRoles;
    if (securityRoles === undefined) {
      return;
    }
    const systemSettingMenu = {
      groupTitle: this._systemSettingMenu.groupTitle,
      menuItems: this.authorizeService.isProposalEnabled() ?
        this._systemSettingMenu.menuItems :
        this._systemSettingMenu.menuItems.filter(item => item.url !== Page.ConfigProposal)
    };
    if (!this.authorizeService.isOpportunityGroupEnabled()) {
      systemSettingMenu.menuItems = systemSettingMenu.menuItems.filter(item => item.url !== Page.ConfigOpportunityGroup);
    }
    if (!this.enableRoleConfig) {
      systemSettingMenu.menuItems = systemSettingMenu.menuItems.filter(item => item.url !== Page.ConfigRole);
    }
    if (securityRoles.includes(SecurityRole.MEMBER)) {
      this._sideMenu = this._sideMenu.concat(this._mainMenu);
      this._sideMenu = this._sideMenu.concat(this._myDataMenu);
    }
    if (securityRoles.includes(SecurityRole.MANAGER)) {
      this._sideMenu = this._sideMenu.concat(this._reportMenu);
    }
    if (securityRoles.includes(SecurityRole.SYSTEM_ADMINISTRATOR)) {
      this._sideMenu = this._sideMenu.concat(systemSettingMenu);
      if (this.authorizeService.isLinkageEnabled()) {
        this._sideMenu = this._sideMenu.concat(this._linkageSettingMenu);
      }
    }
    if (securityRoles.includes(SecurityRole.ADMIN)) {
      this._sideMenu = this._sideMenu.concat(this._adminMenu);
    }
    if (securityRoles.includes(SecurityRole.CONSULTANT)) {
      this._sideMenu = this._sideMenu.concat(this._consultingMenu, this._commonInsightManagementMenu);
    }
    if (securityRoles.includes(SecurityRole.ORGANIZATION_CONSULTANT)) {
      systemSettingMenu.menuItems = systemSettingMenu.menuItems.filter(item => item.url !== Page.ConfigUserGroup);
      this._sideMenu = this._sideMenu.concat(systemSettingMenu, this._organizationConsultantMenu);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
  }

  isSelected(url: string): boolean {
    const currentFeature = this._getFeatureName(this.currentUrl);
    const argFeature = this._getFeatureName(url);
    return currentFeature === argFeature;
  }

  private _getFeatureName(url: string): string {
    return url.split('?')[0].split('/', 3).join('/');
  }
}

/** 認証情報から作成される期首 */
export const FISCAL_YEAR_BEGIN = new InjectionToken<FiscalYearBegin>('期首', {
  providedIn: 'root',
  factory: () => {
    const authInfo = inject(AuthorizeService).getAuthInfo();
    return authInfo?.fiscalYearBegin === undefined
      ? new FiscalYearBegin(4)
      : new FiscalYearBegin(authInfo.fiscalYearBegin);
  }
});

