import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export type ApiSearchFilterCategory =
  | 'opportunity'
  | 'opportunity_group'
  | 'client'
  | 'product'
  // 型制約のため追加しているがmeeting_board_settingのエンドポイントはない
  // MeetingBoardSettingApiを使用すること
  | 'meeting_board_setting'
  // 型制約のため追加しているがopportunity_group_opportunityのエンドポイントはない
  // opportunityを使うこと
  | 'opportunity_group_opportunity';

export type ApiSearchFilterPermission =
  | 'PRIVATE'
  | 'PUBLIC'

export type CreateSearchFilterRequest = CreateSearchFilterRequestData;
interface CreateSearchFilterRequestData {
  filterName: string,
  description: string,
  visibility: {
    type: ApiSearchFilterPermission,
  },
  editability: {
    type: ApiSearchFilterPermission,
  },
  filter?: string,
  sort: string,
  displayField?: string,
}

export type GetSearchFilterResponse = GetSearchFilterResponseData;
interface GetSearchFilterResponseData {
  id: string,
  filterName: string,
  description: string,
  owner: {
    id: string,
    name: string,
  },
  visibility: {
    type: ApiSearchFilterPermission,
  },
  editability: {
    type: ApiSearchFilterPermission,
  },
  filter?: string,
  sort?: string,
  displayField?: string,
}

export type SearchSearchFilterRequest = {
  perPage: number,
  page: number,
  filter: {
    filterName: {
      type: 'contains',
      value: string,
    },
    ownerID: {
      type: 'anyMatch',
      values: string[],
    }
  }
};

export type SearchSearchFilterResponse = {
  totalCount: number,
  results: {
    id: string,
    filterName: string,
    description: string,
    owner: {
      id: string,
      name: string,
    },
    visibility: {
      type: ApiSearchFilterPermission,
    },
    editability: {
      type: ApiSearchFilterPermission,
    },
    bookmark: boolean,
    bookmarkCount: number,
  }[]
};

export type UpdateSearchFilterRequest = {
  filterName: string,
  description: string,
  visibility: {
    type: ApiSearchFilterPermission,
  },
  editability: {
    type: ApiSearchFilterPermission,
  },
  filter?: string,
  sort: string,
  displayField?: string,
};

export class SearchFilterApi {
  constructor(private readonly http: HttpClient) {}

  create(category: ApiSearchFilterCategory, request: CreateSearchFilterRequest): Observable<void> {
    return this.http.post<void>(`/api/search-filters/${category}`, request);
  }

  get(category: ApiSearchFilterCategory, id: string): Observable<GetSearchFilterResponse> {
    return this.http.get<GetSearchFilterResponse>(`/api/search-filters/${category}/${id}`);
  }

  delete(category: ApiSearchFilterCategory, id: string): Observable<void> {
    return this.http.delete<void>(`/api/search-filters/${category}/${id}`);
  }

  search(category: ApiSearchFilterCategory, request: SearchSearchFilterRequest): Observable<SearchSearchFilterResponse> {
    return this.http.post<SearchSearchFilterResponse>(`/api/search-filters/${category}/search`, request);
  }

  update(category: ApiSearchFilterCategory, id: string, request: UpdateSearchFilterRequest): Observable<void> {
    return this.http.put<void>(`/api/search-filters/${category}/${id}`, request);
  }

  createBookmark(category: ApiSearchFilterCategory, id: string): Observable<void> {
    return this.http.post<void>(`/api/search-filters/${category}/${id}/bookmark`, {});
  }

  deleteBookmark(category: ApiSearchFilterCategory, id: string): Observable<void> {
    return this.http.delete<void>(`/api/search-filters/${category}/${id}/bookmark`);
  }
}
