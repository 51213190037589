export class NameId {
  constructor(public readonly value: string) {
  }
}

export class OriginalName {
  constructor(public readonly value: string) {
  }
}

export class LocalizedName {
  static readonly MAX_LENGTH = 10;

  constructor(public readonly value: string) {
  }
}
