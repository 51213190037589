import {
  ProductRelatedClientList, ProductRelatedClientListItem,
  ProductRelatedClientsInput,
  ProductRelatedClientsQueryService
} from "#application/services/product-related-clients-query.service";
import {ProductId} from "app/model/product/product";
import {Observable} from "rxjs";
import {ServerApi} from "#infrastructure/api/server-api";
import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";
import {ClientProductRelationshipID} from "app/model/client-product-relationship/client-product-relationship";
import {ClientID, ClientName, ClientNumber} from "app/model/client/client";
import {ClientCategory, ClientTypeName} from "app/model/client-info-type/client-type";

@Injectable({providedIn: 'root'})
export class ProductRelatedClientsQueryServiceImpl extends ProductRelatedClientsQueryService {

  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  getProductRelatedClientList(
    productId: ProductId,
    input: ProductRelatedClientsInput
  ): Observable<ProductRelatedClientList> {
    return this.serverApi.productRelatedClientsApi.list(productId.value, {
      perPage: input.perPage,
      page: input.page
    }).pipe(
      map(response => ({
          totalCount: response.totalCount,
          results: response.results.map(item => {
            let category: ClientCategory;
            if (item.clientCategory === 'CORPORATION') {
              category = ClientCategory.Corporation;
            } else if (item.clientCategory === 'INDIVIDUAL') {
              category = ClientCategory.Individual;
            } else {
              throw Error(`client category ${item.clientCategory} is not supported`);
            }
            const productRelatedClientListItem: ProductRelatedClientListItem = {
              clientProductRelationshipID: new ClientProductRelationshipID(item.clientProductRelationshipID),
              clientID: new ClientID(item.clientID),
              clientNumber: new ClientNumber(item.clientNumber),
              clientName: new ClientName(item.clientName),
              clientTypeName: new ClientTypeName(item.clientTypeName),
              clientCategory: category
            };
            return productRelatedClientListItem;
          })
        })
      )
    );
  }
}
