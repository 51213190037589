import { AllUserGroupList, UserGroup, UserGroupID, UserGroupListResult } from "app/model/user-group/user-group";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { Observable } from "rxjs";

export type ListUserGroupInputs = {
  perPage: number,
  page: number,
}

export abstract class UserGroupQueryService {
  abstract list(inputs: ListUserGroupInputs): Observable<UserGroupListResult>;
  abstract get(id: UserGroupID): Observable<UserGroup>;
  abstract getAll(): Observable<Result<AllUserGroupList, typeof GeneralFailure.Unexpected>>;
}
