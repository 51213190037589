import {ServerApi} from '#infrastructure/api/server-api';
import {
  FollowUpActionTypeDetail,
  FollowUpActionTypeList,
  FollowUpActionTypeQueryService
} from "#application/services/follow-up-action-type-query.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {
  FollowUpActionTypeDescription,
  FollowUpActionTypeID,
  FollowUpActionTypeName
} from "app/model/follow-up-action/follow-up-action-type";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class FollowUpActionTypeQueryServiceImpl extends FollowUpActionTypeQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  list(): Observable<FollowUpActionTypeList> {
    return this.serverApi.followUpActionTypeApi.list().pipe(
      map(list => {
        return list.map(item => ({
          id: new FollowUpActionTypeID(item.id),
          name: new FollowUpActionTypeName(item.name),
          description: new FollowUpActionTypeDescription(item.description),
        }))
      })
    );
  }

  get(id: FollowUpActionTypeID): Observable<FollowUpActionTypeDetail> {
    return this.serverApi.followUpActionTypeApi.get(id.value).pipe(
      map(followUpActionType => ({
        id: new FollowUpActionTypeID(followUpActionType.id),
        name: new FollowUpActionTypeName(followUpActionType.name),
        description: new FollowUpActionTypeDescription(followUpActionType.description),
      }))
    );
  }
}
