export type MessageActionType =
  | 'COMPLETED'
  | 'FAILED'
  | 'CREATE_CONFIRM'
  | 'CREATE_SUCCESS'
  | 'CREATE_ERROR'
  | 'CREATE_NAME_CONFLICT_ERROR'
  | 'CREATE_INVALID_INPUT_ERROR'
  | 'UPDATE_CONFIRM'
  | 'UPDATE_SUCCESS'
  | 'UPDATE_ERROR'
  | 'UPDATE_NAME_CONFLICT_ERROR'
  | 'UPDATE_INVALID_INPUT_ERROR'
  | 'UPDATE_NOT_FOUND_ERROR'
  | 'DELETE_CONFIRM'
  | 'DELETE_SUCCESS'
  | 'DELETE_ERROR'
  | 'REMOVE_CONFIRM'
  | 'REMOVE_SUCCESS'
  | 'REMOVE_ERROR'
  | 'SORT_SUCCESS'
  | 'SORT_ERROR'
  | 'CANCEL_CONFIRM'
  | 'CANCEL_SUCCESS'
  | 'CANCEL_ERROR'
  | 'SAVE_CONFIRM'
  | 'SAVE_SUCCESS'
  | 'SAVE_ERROR'
  | 'GET_NOT_FOUND_ERROR'

export type MessageContent = {
  title?: string,
  message: string,
}

type MessageReturn = Record<MessageActionType, MessageContent>;

export const Target = {
  ACTION: '活動',
  ACTION_REPORT: '活動報告',
  ACTION_TYPE: '活動タイプ',
  EVENT: 'イベント',
  CLIENT: '顧客',
  CLIENT_FIELD: '顧客項目',
  CORPORATION_CLIENT_FORM_LAYOUT: '法人顧客入力フォームレイアウト',
  INDIVIDUAL_CLIENT_FORM_LAYOUT: '個人顧客入力フォームレイアウト',
  CLIENT_RELATIONSHIP: '関係先',
  CLIENT_RELATIONSHIP_NOTE: 'メモ',
  CLIENT_TYPE: '顧客タイプ',
  CLIENT_RELATED_PRODUCT: '関連商品',
  CONTACT: '連絡先',
  CONTACT_RELATED_CLIENT: '関連顧客',
  CONTACT_HISTORY: '連絡履歴',
  CONTACT_HISTORY_TEMPLATE: '連絡履歴テンプレート',
  FOLLOW_UP_ACTION: 'フォロー',
  FOLLOW_UP_ACTION_TYPE: 'フォロータイプ',
  OPPORTUNITY: '商談',
  OPPORTUNITY_WON: '受注情報',
  OPPORTUNITY_LOSE: '失注情報',
  OPPORTUNITY_FIELD: '商談項目',
  OPPORTUNITY_FORM_LAYOUT: '商談入力フォームレイアウト',
  OPPORTUNITY_TYPE: '商談タイプ',
  OPPORTUNITY_BOARD: '商談ボード',
  WON_REASON: '受注理由',
  LOST_REASON: '失注理由',
  PRODUCT: '商品',
  PRODUCT_FIELD: '商品項目',
  PRODUCT_FORM_LAYOUT: '商品入力フォームレイアウト',
  PRODUCT_TYPE: '商品タイプ',
  PRODUCT_RELATED_CLIENT: '関連顧客',
  PROPOSAL: '提案',
  PROPOSAL_FIELD: '提案項目',
  PROPOSAL_FORM_LAYOUT: '提案入力フォームレイアウト',
  PROPOSAL_TYPE: '提案タイプ',
  PROPOSAL_STATUS: '提案ステータス',
  ADOPTED_REASON: '採択理由',
  DECLINE_REASON: '却下理由',
  SALES_PHASE: '商談フェーズ',
  SEARCH_FILTER: 'フィルター',
  PERSONAL_GOAL_SETTING: '目標設定',
  FILE: 'ファイル',
  INSIGHT_FEEDBACK: 'フィードバック',
  HINT: 'ヒント',
  COMMON_HINT: '共通ヒント',
  INSIGHT: 'インサイト',
  COMMON_INSIGHT: '共通インサイト',
  OPPORTUNITY_CONTEXT: '商談コンテキスト',
  COMMON_OPPORTUNITY_CONTEXT: '共通商談コンテキスト',
  USER: 'ユーザー',
  USER_GROUP: 'ユーザーグループ',
  CONSULTANT: 'コンサルタント',
  WEBHOOK: 'Webhook',
  REST_AUTH_TOKEN: '認証トークン',
  REST_AUTH_TOKEN_KEY: '認証トークン',
  REPORT_GOAL: '目標レポート',
  ROLE: 'ロール',
  LOCALIZE_SETTING: 'ローカライズ設定',
  OPPORTUNITY_GROUP_STATUS: '商談グループステータス',
  SCHEDULE: 'スケジュール',
  CALENDAR_SETTING: 'カレンダー設定',
} as const
export type Target = typeof Target[keyof typeof Target]
export const messageFn = (target: Target | string, actionType: MessageActionType): MessageContent => {
  const commonMessages: MessageReturn = {
    COMPLETED: {
      title: undefined,
      message: `${target}が完了しました。`,
    },
    FAILED: {
      title: undefined,
      message: `${target}に失敗しました。`,
    },
    CREATE_CONFIRM: {
      title: undefined,
      message: `${target}を登録します。よろしいですか。`,
    },
    CREATE_SUCCESS: {
      title: undefined,
      message: `${target}を登録しました。`,
    },
    CREATE_ERROR: {
      title: undefined,
      message: `${target}の登録に失敗しました。`,
    },
    CREATE_NAME_CONFLICT_ERROR: {
      title: `${target}の登録に失敗しました。`,
      message: `${target}名が重複しています。`,
    },
    CREATE_INVALID_INPUT_ERROR: {
      title: `${target}の登録に失敗しました。`,
      message: `入力に誤りがあります。`,
    },
    UPDATE_CONFIRM: {
      title: undefined,
      message: `${target}を更新します。よろしいですか。`,
    },
    UPDATE_SUCCESS: {
      title: undefined,
      message: `${target}を更新しました。`,
    },
    UPDATE_ERROR: {
      title: undefined,
      message: `${target}の更新に失敗しました。`,
    },
    UPDATE_NAME_CONFLICT_ERROR: {
      title: `${target}の更新に失敗しました。`,
      message: `${target}名が重複しています。`,
    },
    UPDATE_INVALID_INPUT_ERROR: {
      title: `${target}の更新に失敗しました。`,
      message: `入力に誤りがあります。`,
    },
    UPDATE_NOT_FOUND_ERROR: {
      title: `${target}の更新に失敗しました。`,
      message: `すでに削除されている可能性があります。`,
    },
    DELETE_CONFIRM: {
      title: `${target}を削除してもよろしいですか`,
      message: `この操作を取り消すことはできません。この${target}を削除してもよろしいですか。`
    },
    DELETE_SUCCESS: {
      title: undefined,
      message: `${target}を削除しました。`,
    },
    DELETE_ERROR: {
      title: `${target}の削除に失敗しました。`,
      message: `この${target}が他の場所で使用されているか、すでに削除されている可能性があります。`,
    },
    REMOVE_CONFIRM: {
      title: undefined,
      message: `${target}を削除します。よろしいですか。`,
    },
    REMOVE_SUCCESS: {
      title: undefined,
      message: `${target}を削除しました。`,
    },
    REMOVE_ERROR: {
      title: `${target}の削除に失敗しました。`,
      message: `この${target}が他の場所で使用されているか、すでに削除されている可能性があります。`,
    },
    SORT_SUCCESS: {
      title: undefined,
      message: `${target}を並び替えました。`,
    },
    SORT_ERROR: {
      title: undefined,
      message: `${target}の並び替えに失敗しました。`,
    },
    CANCEL_CONFIRM: {
      title: undefined,
      message: `${target}を取り消します。よろしいですか。`,
    },
    CANCEL_SUCCESS: {
      title: undefined,
      message: `${target}を取り消しました。`,
    },
    CANCEL_ERROR: {
      title: undefined,
      message: `${target}の取り消しに失敗しました。`,
    },
    SAVE_CONFIRM: {
      title: undefined,
      message: `${target}を保存します。よろしいですか。`,
    },
    SAVE_SUCCESS: {
      title: undefined,
      message: `${target}を保存しました。`,
    },
    SAVE_ERROR: {
      title: undefined,
      message: `${target}の保存に失敗しました。`,
    },
    GET_NOT_FOUND_ERROR: {
      title: `${target}の取得に失敗しました。`,
      message: `すでに削除されている可能性があります。`,
    },
  } as const;
  return commonMessages[actionType]
}
