import {
  CustomField,
  CustomFieldDescription,
  CustomFieldId,
  CustomFieldName,
  NumberSettings,
  SelectSettings,
} from 'app/model/custom-field/custom-field';
import { FieldType } from 'app/model/custom-field/field-type';
import { Observable } from 'rxjs';

export abstract class FieldCommandService {
  abstract add(
    name: CustomFieldName,
    description: CustomFieldDescription,
    type: FieldType,
    required: boolean,
    numberSettings?: NumberSettings,
    selectSettings?: SelectSettings
  ): Observable<void>;
  abstract save(field: CustomField): Observable<void>;
  abstract remove(field: CustomFieldId): Observable<void>;
  abstract sort(fieldIdList: CustomFieldId[]): Observable<void>;
}
