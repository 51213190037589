import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

export type ApiProposalStatusType =
  'DRAFT' | 'READY' | 'PROPOSED' | 'ADOPTED' | 'DECLINED' | 'REPROPOSAL_REQUESTED'

export type GetProposalStatusResponse = {
  id: string,
  type: ApiProposalStatusType,
  name: string,
  description: string,
}

export type UpdateProposalStatusRequest = {
  name: string,
  description: string
}

export class ProposalStatusApi {
  constructor(private readonly http: HttpClient) {}

  get(id: string): Observable<GetProposalStatusResponse> {
    return this.http.get<GetProposalStatusResponse>(`/api/proposal-status/${id}`);
  }

  update(id: string, request: UpdateProposalStatusRequest): Observable<void> {
    return this.http.put<void>(`/api/proposal-status/${id}`, request);
  }

}
