<mat-form-field class="login-id-form" [appearance]="'outline'">
  <mat-label>ログインID</mat-label>
  <input
    type="text"
    class="login-id-input"
    matInput
    [formControl]="control"
    placeholder="ログインID"
    id="loginID"
    name="loginID"
    (keyup.enter)="keyupEnter.emit()"
  >
  <button
    *ngIf="control.value.length > 0"
    type="button"
    class="clear-btn"
    matTooltip="ログインIDをクリア"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="control.setValue('')"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-error *ngIf="control.hasError('required')">
    ログインIDを入力して下さい。
  </mat-error>
  <mat-error *ngIf="control.hasError('unauthorized')">
    ログインIDを確認して下さい。
  </mat-error>
</mat-form-field>