import { AdoptedReasonID } from "app/model/adopted-reason/adopted-reason";
import { CustomItems } from "app/model/custom-field/custom-field";
import { DeclineReasonID } from "app/model/decline-reason/decline-reason";
import { OpportunityID } from "app/model/opportunity/opportunity";
import { ProductId } from "app/model/product/product";
import { ProposalStatusID, ProposalStatusType } from "app/model/proposal-status/proposal-status";
import { ProductDetailType, ProposalTypeID } from "app/model/proposal-type/proposal-type";
import { AdoptedReasonNote, DeclineReasonNote, ProposalDetailRowQuantity, ProposalDetailRowSubTotalAmount, ProposalDetailRowTitle, ProposalID, ProposalTitle } from "app/model/proposal/proposal";
import { Observable } from "rxjs";

export type BaseStatusInputs = {
  id: ProposalStatusID,
}
type AdoptedInputs = {
  type: typeof ProposalStatusType.ADOPTED,
  reasonIDs: AdoptedReasonID[],
  reasonNote: AdoptedReasonNote,
}
type DeclineInputs = {
  type: typeof ProposalStatusType.DECLINED,
  reasonIDs: DeclineReasonID[],
  reasonNote: DeclineReasonNote,
}
type StatusWithReasonInputs = AdoptedInputs | DeclineInputs
export type ProposalStatusWithReasonInputs = BaseStatusInputs & StatusWithReasonInputs
type OtherInputs = {
  type: typeof ProposalStatusType.DRAFT
    | typeof ProposalStatusType.READY
    | typeof ProposalStatusType.PROPOSED
    | typeof ProposalStatusType.REPROPOSAL_REQUESTED
}
export type ProposalOtherStatusInputs = BaseStatusInputs & OtherInputs
export type UpdateProposalStatusInputs = Readonly<
  ProposalStatusWithReasonInputs
  | ProposalOtherStatusInputs
>

type SingleDetailInput = Readonly<{
  type: typeof ProductDetailType.SINGLE,
  productID: ProductId,
}>
type MultipleDetailInput = Readonly<{
  type: typeof ProductDetailType.MULTIPLE,
  rows: {
    productID: ProductId,
    title: ProposalDetailRowTitle,
    quantity: ProposalDetailRowQuantity,
    subTotalAmount: ProposalDetailRowSubTotalAmount,
  }[]
}>

export type AddProposalInputs = Readonly<{
  title: ProposalTitle,
  proposalTypeID: ProposalTypeID,
  opportunityID: OpportunityID,
  items: CustomItems,
  detail: SingleDetailInput | MultipleDetailInput,
}>

export type UpdateProposalInputs = Readonly<{
  title: ProposalTitle,
  items: CustomItems,
  detail: SingleDetailInput | MultipleDetailInput,
  status: StatusWithReasonInputs | OtherInputs,
}>

export abstract class ProposalCommandService {
  abstract updateStatus(id: ProposalID, input: UpdateProposalStatusInputs): Observable<void>;
  abstract add(inputs: AddProposalInputs): Observable<void>;
  abstract update(id: ProposalID, inputs: UpdateProposalInputs): Observable<void>;
  abstract delete(id: ProposalID): Observable<void>;
}
