import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiFieldType} from './server-custom-field-api';
import {ApiBuiltinFieldId, ApiFormLayoutCategory} from './server-form-layout-api';

export type ListBuiltinFieldResponse = ListBuiltinFieldResponseData[];

interface ListBuiltinFieldResponseData {
  id: ApiBuiltinFieldId,
  fieldType: ApiFieldType
  needsToDisplay: boolean
}

export class BuiltinFieldApi {
  constructor(private readonly http: HttpClient) {
  }

  list(category: ApiFormLayoutCategory): Observable<ListBuiltinFieldResponse> {
    return this.http.get<ListBuiltinFieldResponse>(`/api/builtin-fields/${category}`);
  }
}
