import { UnreportedActivityCountStoreService } from "#application/services/unreported-activity-count-store.service";
import { ServerApi } from "#infrastructure/api/server-api";
import { Injectable, inject } from "@angular/core";
import { Subject, Subscription, takeUntil } from "rxjs";

@Injectable({providedIn: 'root'})
export class UnreportedActivityCountStoreServiceImpl implements UnreportedActivityCountStoreService {
  private readonly _serverApi = inject(ServerApi);
  private _subscription?: Subscription;

  private readonly _count$ = new Subject<number>();
  readonly count$ = this._count$.asObservable();

  private readonly _onDestroy$ = new Subject<void>();

  sync() {
    this._subscription = this._serverApi.unreportedActivityApi.count()
    .pipe(takeUntil(this._onDestroy$))
    .subscribe(res => {
      this._count$.next(res.count);
    });
  }

  unsubscribe() {
    if (this._subscription === undefined) return;
    this._subscription.unsubscribe();
  }
}