import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { EventIDV2, EventV2, EventOnBaseStart, EventBaseStart } from "app/model/event/event-v2";
import { Observable } from "rxjs";

export abstract class EventQueryV2Service {
  abstract get(id: EventIDV2): Observable<Result<
    EventV2,
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >>;
  abstract getOnBaseStart(id: EventIDV2, baseStart: EventBaseStart): Observable<Result<
    EventOnBaseStart,
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >>;
}
