import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

interface CreateOpportunityContextRequestData {
  name: string,
  description: string,
  labelNames: string[],
}
export type CreateOpportunityContextRequest = CreateOpportunityContextRequestData;

interface SearchOpportunityContextListRequestData {
  perPage: number,
  page: number,
  filter?: {
    name: {
      type: 'contains',
      value: string,
    },
    labelName: {
      type: 'anyMatch',
      values: string[],
    }
  }
}
export type SearchOpportunityContextListRequest = SearchOpportunityContextListRequestData;

interface SearchOpportunityContextListResponseData {
  totalCount: number,
  results: {
    id: string,
    name: string,
    description: string,
    readonly?: boolean,
    labelNames: string[],
  } []
}
export type SearchOpportunityContextListResponse = SearchOpportunityContextListResponseData;

interface GetOpportunityContextResponseData {
  id: string,
  name: string,
  description: string,
  labelNames: string[]
}
export type GetOpportunityContextResponse = GetOpportunityContextResponseData;

interface UpdateOpportunityContextRequestData {
  name: string,
  description: string,
  labelNames: string[]
}
export type UpdateOpportunityContextRequest = UpdateOpportunityContextRequestData;

export class OpportunityContextApi {
  constructor(private readonly http: HttpClient) {}

  create(request: CreateOpportunityContextRequest): Observable<void> {
    return this.http.post<void>(`/api/opportunity-contexts`, request);
  }

  search(request: SearchOpportunityContextListRequest): Observable<SearchOpportunityContextListResponse> {
    return this.http.post<SearchOpportunityContextListResponse>(`/api/opportunity-context-list/search`, request);
  }

  get(id: string): Observable<GetOpportunityContextResponse> {
    return this.http.get<GetOpportunityContextResponse>(`/api/opportunity-contexts/${id}`,);
  }

  update(id: string, request: UpdateOpportunityContextRequest): Observable<void> {
    return this.http.put<void>(`/api/opportunity-contexts/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/opportunity-contexts/${id}`);
  }
}
