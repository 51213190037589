<div class="container" [class.mb]="isMobile$ | async">
  <h2 class="title-logo">
    <img class="logo-img" src="/assets/img/salesnavi_logo_blue.svg" alt="SalesNaviのロゴ画像"/>
  </h2>
  <div class="login-forms">
    <div class="forms-wrapper">
      <sn-login-id-form [control]="loginIDControl" (keyupEnter)="login()"/>
      <sn-password-form [control]="passwordControl" (keyupEnter)="login()"/>
    </div>
    <sn-login-button class="login-btn" (click)="login()" [disabled]="disableLogin()"/>
  </div>
</div>