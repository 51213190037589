import { OpportunityStatus } from "app/model/opportunity/opportunity";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiFilterRequest, ApiPaginationRequest, FilterSchemaResponse } from "./server-filter-api";
import { OpportunityGroupStatusData } from "./server-opportunity-group-status-api";

export type CreateOpportunityGroupRequest = {
  title: string,
  opportunityGroupTypeID: string,
  opportunityGroupStatusID: string,
  items?: {
    [customFieldId: string]: string
  },
  opportunities: string[],
};

type OpportunityGroupData = {
  id: string,
  number: number,
  title: string,
  opportunityGroupTypeID: string,
  opportunityGroupTypeName: string,
  opportunityGroupStatusID: string,
  opportunityGroupStatusName: string,
  items?: {
    [customFieldId: string]: string
  },
};

export type GetOpportunityGroupResponse = OpportunityGroupData;

export type OpportunityGroupsResponse = {
  totalCount: number,
  results: OpportunityGroupData[]
};

type OpportunityData = {
  id: string,
  opportunityNumber: number,
  opportunityName: string,
  opportunityStatus: OpportunityStatus,
  clientName: string,
  salesPhaseName: string,
  phaseName: string,
  opportunityTypeName: string,  
  assigneeName: string,
};

export type GetOpportunitiesResponse = {
  opportunities: OpportunityData[]
};

export type UpdateOpportunityGroupRequest = {
  title: string,
  items: {
    [customFieldId: string]: string
  },
};

export type UpdateOpportunityGroupStatusRequest = {
  opportunityGroupStatusID: string,
};

export type UpdateOpportunitiesRequest = {
  opportunityIDs: string[]
}

export type GetOpportunityGroupStatusResponse = {
  opportunityGroupStatuses: OpportunityGroupStatusData[]
};

export class OpportunityGroupApi {
  constructor(private readonly http: HttpClient) {}

  create(request: CreateOpportunityGroupRequest): Observable<string> {
    return this.http.post<string>(`/api/opportunity-groups`, request);
  }

  getByID(id: string): Observable<GetOpportunityGroupResponse> {
    return this.http.get<GetOpportunityGroupResponse>(`/api/opportunity-groups/from-id/${id}`);
  }

  getByNumber(number: number): Observable<GetOpportunityGroupResponse> {
    return this.http.get<GetOpportunityGroupResponse>(`/api/opportunity-groups/from-number/${number}`);
  };

  filter(request: ApiPaginationRequest & ApiFilterRequest): Observable<OpportunityGroupsResponse> {
    return this.http.post<OpportunityGroupsResponse>(`/api/opportunity-groups/filter`, request);
  }

  getOpportunities(id: string): Observable<GetOpportunitiesResponse> {
    return this.http.get<GetOpportunitiesResponse>(`/api/opportunity-groups/${id}/opportunities`);
  }

  update(id: string, request: UpdateOpportunityGroupRequest): Observable<void> {
    return this.http.put<void>(`/api/opportunity-groups/${id}`, request);
  }

  updateStatus(id: string, request: UpdateOpportunityGroupStatusRequest): Observable<void> {
    return this.http.put<void>(`/api/opportunity-groups/${id}/status`, request);
  }

  updateOpportunities(id: string, request: UpdateOpportunitiesRequest): Observable<void> {
    return this.http.put<void>(`/api/opportunity-groups/${id}/opportunities`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/opportunity-groups/${id}`);
  }

  getFilterSchema(): Observable<FilterSchemaResponse> {
    return this.http.get<FilterSchemaResponse>(`/api/opportunity-groups/filter`);
  }  

  getStatuses(id: string): Observable<GetOpportunityGroupStatusResponse> {
    return this.http.get<GetOpportunityGroupStatusResponse>(`/api/opportunity-groups/${id}/statuses`);
  }
}
