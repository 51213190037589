import {Component} from '@angular/core';

@Component({
  selector: 'sn-admin',
  template: `
    <sn-page-container>
      <router-outlet></router-outlet>
    </sn-page-container>
  `,
})
export class AdminComponent {

  constructor() { }
}
