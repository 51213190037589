import { Observable } from "rxjs";
import { CustomFieldCategory, CustomFieldDescription, CustomFieldName, CustomFieldNumberUnit, CustomFieldNumberValue, NumberSettings, SelectItemValue, SelectSettings } from "./custom-field";
import { FieldType } from "./field-type";

export type CreateCustomFieldParam = {
  category: CustomFieldCategory;
  name: CustomFieldName;
  description: CustomFieldDescription;
  type: FieldType;
  required: boolean;
  numberSetting?: NumberSettingsParam;
  selectSetting?: SelectSettingsParam;
};

type NumberSettingsParam = {
  min?: CustomFieldNumberValue;
  max?: CustomFieldNumberValue;
  defaultValue?: CustomFieldNumberValue;
  unit?: CustomFieldNumberUnit;
};

type SelectSettingsParam = {
  multiple: boolean;
  allowInput: boolean;
  options: SelectItemValue[];
};

export abstract class CustomFieldFactory {
  abstract create(param: CreateCustomFieldParam): Observable<void>;
}
