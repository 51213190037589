import { MessageDialogService } from "#application/services/message-dialog.service";
import { OpportunityWithSettings, OpportunityWithSettingsForActionQueryService, OpportunityWithSettingsV2 } from "#application/services/opportunity-with-settings-for-action-query.service";
import { CustomField, CustomFieldDescription, CustomFieldId, CustomFieldName, CustomFieldNumberUnit, CustomFieldNumberValue, CustomFieldValue, NumberSettings, SelectItemValue, SelectSettings } from "app/model/custom-field/custom-field";
import { FieldType } from "app/model/custom-field/field-type";
import { Timing } from "app/model/opportunity-hearing-setting/opportunity-hearing-setting";
import {OpportunityID, OpportunityItem, OpportunityName, OpportunityNumber} from "app/model/opportunity/opportunity";
import { Definition, Phase, PhaseID, PhaseName, SalesPhase, SalesPhaseID, SalesPhaseName, SuccessCriteria } from "app/model/sales-phase/sales-phase";
import { ServerApi } from "#infrastructure/api/server-api";
import { GetOpportunityWithSettingsResponse } from "#infrastructure/api/server-opportunity-with-settings-for-action-api";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Failure, Result, Success } from "app/lib/result/result";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { ErrorResponseTitle, ServerApiError } from "#infrastructure/api/error";
import { CustomFormItemV2 } from "app/model/form-items/form-items";
import { FormLayoutWidth } from "app/model/form-layout/form-layout";

@Injectable({ providedIn: 'root' })
export class OpportunityWithSettingsForActionQueryServiceImpl extends OpportunityWithSettingsForActionQueryService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly messageDialogService: MessageDialogService,
  ) {
    super();
  }

  get(opportunityID: OpportunityID, timing: Timing): Observable<OpportunityWithSettings> {
    return this.serverApi.opportunityWithSettingsForActionApi
    .get(opportunityID.value, timing)
    .pipe(
      map(res => convertToOpportunityWithSettings(res)),
      catchError(e => {
        this.messageDialogService.notice({
          errorTarget: '商談関連情報の取得'
        });
        return throwError(e);
      })
    );
  }

  getV2(opportunityID: OpportunityID, timing: Timing): Observable<Result<
    OpportunityWithSettingsV2,
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.opportunityWithSettingsForActionApi
      .get(opportunityID.value, timing)
      .pipe(
        map(res => Success(convertToOpportunityWithSettingsV2(res))),
        catchError((e: ServerApiError) => {
          if (e.response.title === ErrorResponseTitle.ResourceNotFoundError) {
            return of(Failure(GeneralFailure.NotFound));
          }
          return of(Failure(GeneralFailure.Unexpected));
        }),
      );
  }
}

type keyValueItem = {
  [key: string]: string
};

export const convertKeyValueObjToOpportunityItemList = (items: keyValueItem): OpportunityItem[] => {
  const keys = Object.keys(items);
  const opportunityItems: OpportunityItem[] = [];
  keys.forEach(key => {
    opportunityItems.push(new OpportunityItem(
      new CustomFieldId(key),
      new CustomFieldValue(items[key])
    ));
  });
  return opportunityItems;
};

// TODO 消す
const convertToOpportunityWithSettings =
(res: GetOpportunityWithSettingsResponse): OpportunityWithSettings => {
  return {
    opportunityID: new OpportunityID(res.opportunityID),
    opportunityName: new OpportunityName(res.opportunityName),
    phaseID: new PhaseID(res.phaseID),
    items: convertKeyValueObjToOpportunityItemList(res.items),
    salesPhase: new SalesPhase(
      new SalesPhaseID(res.salesPhase.id),
      new SalesPhaseName(res.salesPhase.name),
      res.salesPhase.phaseList.map(v => new Phase(
        new PhaseID(v.id),
        new PhaseName(v.name),
        new Definition(v.definition),
        new SuccessCriteria(v.successCriteria)
      ))
    ),
    hearingItems: res.hearingItems.map(item => ({
      phaseID: new PhaseID(item.phaseID),
      phaseName: new PhaseName(item.phaseName),
      items: item.items.map(v => ({
        id: new CustomFieldId(v.customFieldID),
        name: new CustomFieldName(v.name),
        description: new CustomFieldDescription(v.description),
        required: v.required,
        fieldType: FieldType[v.type],
        selectSetting: v.selectSetting === undefined
          ? undefined
          : new SelectSettings(
            v.selectSetting.multiple,
            v.selectSetting.allowInput,
            v.selectSetting.options.map(value => new SelectItemValue(value))
          ),
        numberSetting: v.numberSetting === undefined
          ? undefined
          : new NumberSettings(
            v.numberSetting.minValue === undefined ? undefined : new CustomFieldNumberValue(v.numberSetting.minValue),
            v.numberSetting.maxValue === undefined ? undefined : new CustomFieldNumberValue(v.numberSetting.maxValue),
            v.numberSetting.defaultValue === undefined ? undefined : new CustomFieldNumberValue(v.numberSetting.defaultValue),
            new CustomFieldNumberUnit(v.numberSetting.unit),
          ),
      })),
    })),
  };
}

const convertToOpportunityWithSettingsV2 =
(res: GetOpportunityWithSettingsResponse): OpportunityWithSettingsV2 => {
  return {
    opportunityID: new OpportunityID(res.opportunityID),
    opportunityName: new OpportunityName(res.opportunityName),
    opportunityNumber: new OpportunityNumber(res.opportunityNumber),
    currentPhaseID: new PhaseID(res.phaseID),
    salesPhase: new SalesPhase(
      new SalesPhaseID(res.salesPhase.id),
      new SalesPhaseName(res.salesPhase.name),
      res.salesPhase.phaseList.map(v => new Phase(
        new PhaseID(v.id),
        new PhaseName(v.name),
        new Definition(v.definition),
        new SuccessCriteria(v.successCriteria)
      ))
    ),
    hearingItems: res.hearingItems.map(item => ({
      phaseID: new PhaseID(item.phaseID),
      phaseName: new PhaseName(item.phaseName),
      items: item.items.map(v => new CustomFormItemV2(
        FormLayoutWidth.FULL,
        new CustomField(
          new CustomFieldId(v.customFieldID),
          new CustomFieldName(v.name),
          FieldType[v.type],
          v.required,
          new CustomFieldDescription(v.description),
          v.numberSetting === undefined
            ? undefined
            : new NumberSettings(
              v.numberSetting.minValue === undefined ? undefined : new CustomFieldNumberValue(v.numberSetting.minValue),
              v.numberSetting.maxValue === undefined ? undefined : new CustomFieldNumberValue(v.numberSetting.maxValue),
              v.numberSetting.defaultValue === undefined ? undefined : new CustomFieldNumberValue(v.numberSetting.defaultValue),
              new CustomFieldNumberUnit(v.numberSetting.unit),
            ),
          v.selectSetting === undefined
            ? undefined
            : new SelectSettings(
              v.selectSetting.multiple,
              v.selectSetting.allowInput,
              v.selectSetting.options.map(value => new SelectItemValue(value))
            ),
        ),
        Object.keys(res.items).find((key) => key === v.customFieldID)
          ? new CustomFieldValue(res.items[v.customFieldID])
          : undefined,
      )),
    })),
  };
}
