import { Observable } from 'rxjs';

export type CsvExportCategory = 'client' | 'opportunity' | 'product' | 'action' | 'nurturing';

export type CsvExportRequestCategory = 'corporation' | 'individual' | 'opportunity' | 'product' | 'action' | 'nurturing';

export interface ExportType {
  category: CsvExportRequestCategory;
  typeId: string;
  typeName: string;
}
export type ExportTypes = ExportType[];

export type CsvExportParam = {
  type: ExportType;
};

export abstract class CsvExportService {
  abstract getExportTypes(category: CsvExportCategory): Observable<ExportTypes>;
  abstract getCsvFileDownloadUrl(category: CsvExportRequestCategory, requestId: string): Observable<string>;
  abstract requestCsvExport(category: CsvExportRequestCategory, exportTypeId:string, encoding: string ): Observable<void>;
}
