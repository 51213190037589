import { ConsultantFactory, CreateConsultantParams } from "app/model/consultant/consultant.factory";
import { ServerApi } from "#infrastructure/api/server-api";
import { CreateConsultantRequest } from "#infrastructure/api/server-consultant-api";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ConsultantFactoryImpl extends ConsultantFactory {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  create(params: CreateConsultantParams): Observable<void> {
    const request: CreateConsultantRequest = {
      userName: params.loginID.value,
      displayName: params.displayName.value,
      mailAddress: params.mailAddress.value,
      assignedOrganizationType: params.assignedOrganization.type,
      selectedOrganizations: params.assignedOrganization.list === undefined
        ? undefined
        : params.assignedOrganization.list.map(id => id.value),
    };
    return this.serverApi.consultantApi.create(request);
  }
}
