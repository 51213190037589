import { DisplayItems } from "#presentation/pages/activity/shared/display-item";
import { Filter } from "#presentation/pages/activity/shared/search-filter/search-filter";
import { SortOptions } from "#presentation/pages/activity/shared/sortable-items";

type _MaxImpl<N extends number, T extends readonly unknown[]>
  = [N] extends [Partial<T>['length']]
  ? T['length']
  : _MaxImpl<N, [0, ...T]>;

/**
 * 数値型のユニオンから最大値の型を求める。
 * @see https://zenn.dev/noshiro_piko/articles/typescript-type-level-min
 */
type Max<N extends number> = _MaxImpl<N, []>;

type LatestVersion = Max<MeetingBoardSettingFilterConditionWithVersion['version']>;

type MeetingBoardSettingFilterConditionV1 =
  {
    filters: Filter[],
    sorts: SortOptions,
    displayItems: DisplayItems,
  };

/**
 * ミーティングボードのフィルター設定値。
 * 最新版の型ができた場合は、その最新版に差し替えること。
 */
export type MeetingBoardSettingFilterCondition = MeetingBoardSettingFilterConditionV1;

type MeetingBoardSettingFilterConditionWithVersion =
  | {
      version: 1,
      data: MeetingBoardSettingFilterConditionV1,
    }
  // 今後V2以降があるかも？

export namespace MeetingBoardSettingFilterCondition {
  /**
   * フィルター設定値をJSON文字列に変換して返す。
   * @param data フィルター設定値
   * @returns JSON文字列
   * （型MeetingBoardSettingFilterConditionWithVersionの最新版が増えたとき、versionの値が旧いとコンパイルエラーになる）
   */
  export function stringify(data: unknown): string {
    const latest: {
      version: LatestVersion,
      data: unknown,
    } = {
      version: 1,
      data,
    };
    return JSON.stringify(latest);
  }

  /**
   * フィルター設定値のJSON文字列を復元して返す。
   * 版が増えた場合、旧い版のデータを最新の版のデータ構造に補正して返す。
   * @param jsonStr フィルター設定値のJSON文字列
   * @returns 版が増えるごとに返すデータは変わるため、あえて戻り値の型は明示しない
   */
  export function parse(jsonStr: string): MeetingBoardSettingFilterCondition {
    const conditionWithVersion = JSON.parse(jsonStr) as MeetingBoardSettingFilterConditionWithVersion;
    const version = conditionWithVersion.version;
    switch (version) {
      case 1:
        return conditionWithVersion.data;
        // 今後V2以降が増えた場合、旧い版のデータを版ごとに補正して返す
      default:
        const unexpectedVersion: never = version;
        throw new Error(`Unexpected version: ${unexpectedVersion}`);
    }
  }
}
