import { OpportunityGroupStatusDescription, OpportunityGroupStatusID, OpportunityGroupStatusName } from "app/model/opportunity-group/opportunity-group-status";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { Observable } from "rxjs";

export type OpportunityGroupStatusInputs = {
  name: OpportunityGroupStatusName,
  description: OpportunityGroupStatusDescription,
}

export abstract class OpportunityGroupStatusCommandService {

  abstract add(inputs: OpportunityGroupStatusInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
  >>;

  abstract update(id: OpportunityGroupStatusID, inputs: OpportunityGroupStatusInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.NotFound
  >>;
  abstract delete(id: OpportunityGroupStatusID): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.NotFound
  >>;
  abstract sort(ids: OpportunityGroupStatusID[]): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>;
}
