import {
  SalesPhaseQueryService,
  SalesPhaseSummaries
} from "#application/services/sales-phase-query.service";
import {
  Definition,
  Phase,
  PhaseID,
  PhaseName,
  SalesPhase,
  SalesPhaseID,
  SalesPhaseName,
  SuccessCriteria
} from "app/model/sales-phase/sales-phase";
import { ServerApi } from "#infrastructure/api/server-api";
import { ListSalesPhaseResponse } from "#infrastructure/api/server-sales-phase-api";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SalesPhaseQueryServiceImpl extends SalesPhaseQueryService {

  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  listSummaries(): Observable<SalesPhaseSummaries> {
    return this.serverApi.salesPhaseApi.list()
      .pipe(
        map((res: ListSalesPhaseResponse) => res.list.map(salesPhase => ({
          id: new SalesPhaseID(salesPhase.id),
          name: new SalesPhaseName(salesPhase.name),
        }))),
        map(summaries => new SalesPhaseSummaries(summaries)),
      );
  }

  get(salesPhaseID: SalesPhaseID): Observable<SalesPhase> {
    return this.serverApi.salesPhaseApi.get(salesPhaseID.value)
      .pipe(
        map(response => {
          const phases: Phase[] = response.phaseList.map(phase => new Phase(
            new PhaseID(phase.id),
            new PhaseName(phase.name),
            new Definition(phase.definition),
            new SuccessCriteria(phase.successCriteria),
          ));
          return new SalesPhase(
            new SalesPhaseID(response.id),
            new SalesPhaseName(response.name),
            phases
          );
        })
      );
  }
}
