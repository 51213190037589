import { MessageDialogService } from "#application/services/message-dialog.service";
import { SalesPhaseSettingCommandService } from "#application/services/sales-phase-setting-command.service";
import { SnackBarService } from "#application/services/snack-bar.service";
import { SalesPhaseSetting } from "app/model/sales-phase/sales-phase-setting";
import { ServerApi } from "#infrastructure/api/server-api";
import { AddSalesPhaseRequest, EditSalesPhaseRequest, FixSalesPhaseRequest } from "#infrastructure/api/server-sales-phase-api";
import { Target, messageFn } from "#utils/messages";
import { Injectable, inject } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class SalesPhaseSettingCommandServiceImpl implements SalesPhaseSettingCommandService {
  private readonly serverApi = inject(ServerApi);
  private readonly snackBarService = inject(SnackBarService);
  private readonly messageDialogService = inject(MessageDialogService);

  add(input: SalesPhaseSetting): Observable<void> {
    const phaseList = input.list.map(phase => ({
      name: phase.name.value,
      definition: phase.definition.value,
      successCriteria: phase.successCriteria.value,
    }));
    const requestBody: AddSalesPhaseRequest = {
      name: input.name.value,
      phaseList: phaseList,
    };
    return this.serverApi.salesPhaseApi
      .add(requestBody)
      .pipe(
        tap(() => this.snackBarService.show(messageFn(Target.SALES_PHASE, 'CREATE_SUCCESS').message)),
        catchError((err) => {
          this.messageDialogService.notice({ errorTarget: Target.SALES_PHASE + 'の登録' });
          return throwError(err);
        }),
      );
  };

  edit(input: SalesPhaseSetting): Observable<void> {
    const phaseList = input.list.map(phase => ({
      name: phase.name.value,
      definition: phase.definition.value,
      successCriteria: phase.successCriteria.value,
    }));
    const requestBody: EditSalesPhaseRequest = {
      name: input.name.value,
      phaseList: phaseList,
    };
    return this.serverApi.salesPhaseApi
      .edit(input.id.value, requestBody)
      .pipe(
        tap(() => this.snackBarService.show(messageFn(Target.SALES_PHASE, 'SAVE_SUCCESS').message)),
        catchError((err) => {
          this.messageDialogService.notice({ errorTarget: Target.SALES_PHASE + 'の保存' });
          return throwError(err);
        }),
      );
  };

  fix(input: SalesPhaseSetting): Observable<void> {
    const phaseMap = new Map();
    input.list.forEach(phase => {
      phaseMap.set(phase.id.value, {
        name: phase.name.value,
        definition: phase.definition.value,
        successCriteria: phase.successCriteria.value,
      });
    });
    const requestBody: FixSalesPhaseRequest = {
      name: input.name.value,
      phaseMap: Object.fromEntries(phaseMap),
    };
    return this.serverApi.salesPhaseApi
      .fix(input.id.value, requestBody)
      .pipe(
        tap(() => this.snackBarService.show(messageFn(Target.SALES_PHASE, 'SAVE_SUCCESS').message)),
        catchError((err) => {
          this.messageDialogService.notice({ errorTarget: Target.SALES_PHASE + 'の保存' });
          return throwError(err);
        }),
      );
  };
}
