import {Observable} from "rxjs";
import {AdoptedReasonDescription, AdoptedReasonID, AdoptedReasonName} from "app/model/adopted-reason/adopted-reason";

export type AdoptedReasonInputs = {
  name: AdoptedReasonName,
  description: AdoptedReasonDescription,
}

export abstract class AdoptedReasonCommandService {
  abstract add(inputs: AdoptedReasonInputs): Observable<void>;
  abstract update(id: AdoptedReasonID, inputs: AdoptedReasonInputs): Observable<void>;
  abstract delete(id: AdoptedReasonID): Observable<void>;
  abstract sort(ids: AdoptedReasonID[]): Observable<void>;
}
