import {CsvImportErrors, CsvImportHistories,} from '#application/services/csv-import-histories-query.service';
import {DisplayName, UserID} from 'app/model/user/user';
import {ServerApi} from '#infrastructure/api/server-api';
import {CsvImportErrorResponse, CsvImportHistoriesResponse,} from '#infrastructure/api/server-csv-import-histories-api';
import {Injectable} from '@angular/core';
import {DateTime} from 'luxon';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  OpportunityCsvImportHistoriesQueryService
} from '#application/services/opportunity-csv-import-histories-query.service';

@Injectable({ providedIn: 'root' })
export class OpportunityCsvImportHistoriesQueryServiceImpl extends OpportunityCsvImportHistoriesQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  getCsvImportHistories(
    perPage: number,
    page: number
  ): Observable<CsvImportHistories> {
    return this.serverApi.csvImportHistoriesApi
      .list('opportunity', perPage, page)
      .pipe(map((response) => convertToCsvImportHistories(response)));
  }

  getCsvImportErrors(
    csvImportHistoryID: string,
    perPage: number,
    page: number
  ): Observable<CsvImportErrors> {
    return this.serverApi.csvImportHistoriesApi
      .errorList(csvImportHistoryID, perPage, page)
      .pipe(map((response) => convertToCsvImportErrors(response)));
  }
}

const convertToCsvImportErrors = (
  response: CsvImportErrorResponse
): CsvImportErrors => {
  return {
    totalCount: response.totalCount,
    results: response.results.map((v) => {
      return {
        rowNo: v.csvRowNumber,
        error: v.error,
      };
    }),
  };
};

const convertToCsvImportHistories = (
  response: CsvImportHistoriesResponse
): CsvImportHistories => {
  return {
    totalCount: response.totalCount,
    results: response.results.map((v) => {
      return {
        id: v.id,
        csvFileName: v.csvFileName,
        userId: new UserID(v.userId),
        userName: new DisplayName(v.userName),
        importStartDateTime: DateTime.fromISO(v.importStartDateTime),
        importEndDateTime: DateTime.fromISO(v.importEndDateTime),
        importDataCount: v.importDataCount,
        importSucceedDataCount: v.importSucceedDataCount,
        importFailedDataCount: v.importFailedDataCount,
      };
    }),
  };
};
