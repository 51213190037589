import {ApiLocalizeKey, ApiUserSecurityRole} from "#infrastructure/api/server-auth-api";
import {ApiMetaUserSecurityRole} from "#infrastructure/api/server-meta-user-auth-api";

export const AUTH_INFO_KEY_NAME = 'AUTH_INFO';

export type ApiSecurityRole =
  | ApiUserSecurityRole
  | ApiMetaUserSecurityRole

export interface ClientApiAuthorizeInfo {
  id: string,
  organizationName?: string,
  loginID: string,
  displayName: string,
  mailAddress: string,
  fiscalYearBegin?: number,
  securityRoles: ApiSecurityRole[],
  isCoachingAvailable?: boolean,
  isProposalEnabled?: boolean,
  isLinkageEnabled?: boolean,
  isOpportunityGroupEnabled?: boolean,
  localize?: Record<ApiLocalizeKey, string>,
}
