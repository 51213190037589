import {LocalizedName, NameId, OriginalName} from "app/model/localize/localize";
import {Observable} from "rxjs";

export type LocalizationSettingListItem = {
  id: NameId,
  originalName: OriginalName,
  localizedName: LocalizedName,
}

export type LocalizationSettingList = LocalizationSettingListItem[]

export abstract class LocalizationSettingQueryService {
  abstract list(lang: string): Observable<LocalizationSettingList>;
}
