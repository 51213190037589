import {ProductId} from "app/model/product/product";
import {ClientID} from "app/model/client/client";

export class ClientProductRelationship {
  constructor(
    public readonly id: ClientProductRelationshipID,
    public readonly clientID: ClientID,
    public readonly productID: ProductId
  ) {}
}

export class ClientProductRelationshipID {
  constructor(public readonly value: string) {
  }
}
