/**
 * フォロータイプのリスト
 */
export class FollowUpActionTypes {
  constructor(public readonly list: FollowUpActionType[]) {
  }

  public getDefault(): FollowUpActionType {
    return this.list[0];
  }

  public clone(): FollowUpActionTypes {
    return new FollowUpActionTypes(this.list.map((followUpActionType) => followUpActionType));
  }
}

/**
 * フォロータイプ
 */
export class FollowUpActionType {
  constructor(
    public readonly id: FollowUpActionTypeID,
    public readonly name: FollowUpActionTypeName,
    public readonly description: FollowUpActionTypeDescription
  ) {
  }

  public clone(): FollowUpActionType {
    return new FollowUpActionType(this.id, this.name, this.description);
  }

  public isNewItem(): boolean {
    return this.id.isEmpty();
  }
}

/**
 * フォロータイプを一意に識別する値
 */
export class FollowUpActionTypeID {
  constructor(public readonly value: string) {
  }

  public isEmpty(): boolean {
    return this.value === '';
  }
}

/**
 * フォロータイプの名称
 */
export class FollowUpActionTypeName {
  static readonly MAX_LENGTH = 30;

  constructor(public readonly value: string) {
  }
}

/**
 * フォロータイプの説明
 */
export class FollowUpActionTypeDescription {
  static readonly MAX_LENGTH = 100;

  constructor(public readonly value: string) {
  }
}
