import { UserList } from "#application/services/user-query.service";
import { DateTime } from "luxon";
import { OpportunityTypeID, OpportunityTypeName } from "../opportunity-type/opportunity-type";
import { SalesPhaseID } from "../sales-phase/sales-phase";
import { UserGroupID } from "../user-group/user-group";
import { DisplayName, LoginID, MailAddress, UserAccessLevel, UserID } from "../user/user";
import { MembersFilter, MembersFilterType } from "./members-filter";

export const OPPORTUNITY_BOARD_FILTER_KEY_NAME = 'OPPORTUNITY_BOARD_FILTER';

export type OpportunityBoardFilterOpportunityType = {
  id: OpportunityTypeID,
  name: OpportunityTypeName,
  salesPhaseID: SalesPhaseID,
}

export type OpportunityBoardFilter = {
  opportunityType: OpportunityBoardFilterOpportunityType,
  members: MembersFilter,
  allUsers: UserList,
}
export type PrimitiveOpportunityBoardFilter = {
  opportunityType: {
    id: string,
    name: string,
    salesPhaseID: string,
  },
  members: {
    users: {
      selected: boolean,
      id: string,
      loginID: string,
      name: string,
    }[],
    userGroupIDs: string[],
    membersType: MembersFilterType,
  },
  allUsers: {
    id: string,
    loginID: string,
    name: string,
    mailAddress: string,
    isActive: boolean,
    lastLoginDatetime?: string,
    accessLevels: UserAccessLevel[]
  }[]
}
export namespace OpportunityBoardFilter {
  export function serialize(filter: OpportunityBoardFilter | undefined): PrimitiveOpportunityBoardFilter | null {
    return filter == null
      ? null
      : {
          opportunityType: {
            id: filter.opportunityType.id.value,
            name: filter.opportunityType.name.value,
            salesPhaseID: filter.opportunityType.salesPhaseID.value,
          },
          members: {
            users: filter.members.users.map(u => ({
              selected: u.selected,
              id: u.id.value,
              loginID: u.loginID.value,
              name: u.name.value,
            })),
            userGroupIDs: filter.members.userGroupIDs.map(id => id.value),
            membersType: filter.members.membersType,
          },
          allUsers: filter.allUsers.map(u => ({
            id: u.id.value,
            loginID: u.loginID.value,
            name: u.name.value,
            mailAddress: u.mailAddress.value,
            isActive: u.isActive,
            lastLoginDatetime: u.lastLoginDatetime?.toISO(),
            accessLevels: u.accessLevels,
          })),
        };
  }

  export function deserialize(filter: PrimitiveOpportunityBoardFilter): OpportunityBoardFilter {
    return {
      opportunityType: {
        id: new OpportunityTypeID(filter.opportunityType.id),
        name: new OpportunityTypeName(filter.opportunityType.name),
        salesPhaseID: new SalesPhaseID(filter.opportunityType.salesPhaseID),
      },
      members: {
        users: filter.members.users.map(u => ({
          selected: u.selected,
          id: new UserID(u.id),
          loginID: new LoginID(u.loginID),
          name: new DisplayName(u.name),
        })),
        userGroupIDs: filter.members.userGroupIDs.map(id => new UserGroupID(id)),
        membersType: filter.members.membersType,
      },
      allUsers: filter.allUsers.map(u => ({
        id: new UserID(u.id),
        loginID: new LoginID(u.loginID),
        name: new DisplayName(u.name),
        mailAddress: new MailAddress(u.mailAddress),
        isActive: u.isActive,
        lastLoginDatetime: u.lastLoginDatetime == null ? undefined : DateTime.fromISO(u.lastLoginDatetime),
        accessLevels: u.accessLevels,
      })),
    };
  }
}
