import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

// response
export type ActivityReportResponse = {
  times: ActivityResponse,
  timesPerUser: ActivityPerUserResponse[],
  counts: ActivityResponse,
  countsPerUser: ActivityPerUserResponse[],
}

export type ActivityReportUserResponse = {
  times: ActivityPerUserResponse,
  counts: ActivityPerUserResponse,
  activities: ActivitiesResponse
}

export type PersonalActivityReportResponse = {
  times: ActivityResponse,
  counts: ActivityResponse,
  activities: ActivitiesResponse,
};

export type ActivitiesResponse = {
  totalCount: number,
  results: {
    actionID: string,
    opportunityID: string,
    opportunityName: string,
    opportunityNumber: number,
    isDone: boolean,
    actionStartDateTime: string,
    actionEndDateTime: string,
    actionTime: number,
    currentPhaseID: string,
    currentPhaseName: string,
    startPhaseID: string,
    startPhaseName: string,
    targetPhaseID: string,
    targetPhaseName: string,
    actualStartPhaseID?: string,
    actualStartPhaseName?: string,
    actualPhaseID?: string,
    actualPhaseName?: string,
    actionTypeName: string,
  }[]
}


export type ActivityResponse = {
  perDate: PerDateResponse[],
  perActionType: PerActionTypeResponse[],
  total: number,
}

export type ActivityPerUserResponse = {
  user: {
    id: string,
    name: string,
    isActive: boolean,
  },
  perDate: PerDateResponse[],
  perActionType: PerActionTypeResponse[],
  total: number,
}

export type PerDateResponse = {
  date: string,
  values: [
    {
      actionTypeID: string,
      value: number
    }
  ],
  total: number,
}

export type PerActionTypeResponse = {
  actionTypeID: string,
  total: number,
}

// request
export type ActivityReportRequest = {
  duration: MonthlyDurationRequest | DailyDurationRequest,
  actionTypeIDs: string[],
  assignee: GroupUserSearchRequest | AllUsersSearchRequest,
}

export type ActivityReportUserRequest = {
  pagination: {
    "perPage": number,
    "page": number
  },
  duration: MonthlyDurationRequest | DailyDurationRequest,
  actionTypeIDs: string[],
  userID: string,
}

export type PersonalActivityReportRequest = Omit<ActivityReportUserRequest, 'userID'>

export type MonthlyDurationRequest = {
  type: 'MONTHLY'
  startYearMonth: string,
  months: number,
}

export type DailyDurationRequest = {
  type: 'DAILY'
  startDate: string,
  days: number,
}

export type GroupUserSearchRequest = {
  type: 'USER_GROUP',
  userGroupID: string,
}

export type AllUsersSearchRequest = {
  type: 'ALL_USERS',
}

export class ReportActivityV2Api {
  constructor(
    private readonly http: HttpClient,
  ) {}

  getActivityReport(request: ActivityReportRequest): Observable<ActivityReportResponse> {
    return this.http.post<ActivityReportResponse>(`/api/report-activities`, request);
  }

  getActivityReportPerUser(request: ActivityReportUserRequest): Observable<ActivityReportUserResponse> {
    return this.http.post<ActivityReportUserResponse>(`/api/report-activities/user`, request);
  }

  getPersonalActivityReport(request: PersonalActivityReportRequest): Observable<PersonalActivityReportResponse> {
    return this.http.post<PersonalActivityReportResponse>(`/api/my-activity`, request);
  }
}
