import {
  CustomFieldRequired,
  CustomField,
  CustomFieldDescription,
  CustomFieldId,
  CustomFieldName,
  SelectSettings,
  NumberSettings
} from "app/model/custom-field/custom-field";
import { FieldType } from "app/model/custom-field/field-type";
import { Observable } from "rxjs";

/**
 * 本当はCustomFieldと統合したいが、工数がかかるためAliasとして誤魔化した。
 * 統合するにはrequiredをbooleanに変更する必要がある。
 */
export interface CustomFieldAlias {
  id: CustomFieldId,
  name: CustomFieldName,
  type: FieldType,
  description: CustomFieldDescription,
  required: CustomFieldRequired,
  numberSettings?: NumberSettings,
  selectSettings?: SelectSettings,
}
export namespace CustomFieldAlias {
  export function toCustomField(alias: CustomFieldAlias): CustomField {
    return {
      id: alias.id,
      name: alias.name,
      type: alias.type,
      required: alias.required.value,
      description: alias.description,
      numberSettings: alias.numberSettings,
      selectSettings: alias.selectSettings,
    };
  }
}

export abstract class FieldQueryService {
  abstract get(id: CustomFieldId): Observable<CustomField>;
  abstract list(): Observable<CustomFieldAlias[]>;
}
