import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ServerApi} from "#infrastructure/api/server-api";
import { SearchFilterRepository } from "app/model/search-filter/search-filter.repository";
import { SearchFilter, SearchFilterCategory, SearchFilterId } from "app/model/search-filter/search-filter";
import { UpdateSearchFilterRequest } from "#infrastructure/api/server-search-filter-api";

@Injectable({providedIn: 'root'})
export class SearchFilterRepositoryImpl extends SearchFilterRepository {
  constructor(
    private readonly serverApi: ServerApi,
  ) {
    super();
  }

  delete(category: SearchFilterCategory, id: SearchFilterId): Observable<void> {
    return this.serverApi.searchFilterApi.delete(category, id.value);
  }

  save(category: SearchFilterCategory, filter: SearchFilter): Observable<void> {
    const request: UpdateSearchFilterRequest = {
      filterName: filter.name.value,
      description: filter.description.value,
      visibility: {
        type: filter.visibility.type,
      },
      editability: {
        type: filter.editability.type,
      },
      filter: filter.filter === undefined ? undefined : JSON.stringify(filter.filter),
      sort: JSON.stringify(filter.sorts),
      displayField: filter.displayItems === undefined
        ? undefined
        : JSON.stringify(filter.displayItems),
    }
    return this.serverApi.searchFilterApi.update(category, filter.id.value, request);
  }

  deleteBookmark(category: SearchFilterCategory, id: SearchFilterId): Observable<void> {
    return this.serverApi.searchFilterApi.deleteBookmark(category, id.value);
  }
}
