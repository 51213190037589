import { SchedulePcFilterStorageService } from '#application/services/schedule-pc-filter-storage.service';
import { ClientApi } from '#infrastructure/api/client-api';
import { Injectable, computed, effect, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MembersFilterType } from 'app/model/storage/members-filter';
import { SCHEDULE_PC_FILTER_KEY_NAME, SchedulePcDisplayType, SchedulePcFilterState, TimeframeType } from 'app/model/storage/schedule-pc-filter';
import { UserGroupID } from 'app/model/user-group/user-group';
import { DisplayName, LoginID, MailAddress, UserAccessLevel, UserID } from 'app/model/user/user';
import { DateTime } from 'luxon';

type PrimitiveSchedulePcFilterState = {
  scheduleType: SchedulePcDisplayType,
  individual?: {
    timeframeType: TimeframeType,
  },
  group?: {
    timeframeType: TimeframeType,
  },
  targetDate: string,
  targetUser?: {
    id: string,
    name: string,
  },
  targetUsersAndGroups: {
    users: {
      selected: boolean,
      id: string,
      loginID: string,
      name: string,
    }[],
    userGroupIDs: string[],
    membersType: MembersFilterType,
  },
  allUsers: {
    id: string,
    loginID: string,
    name: string,
    mailAddress: string,
    isActive: boolean,
    lastLoginDatetime?: string,
    accessLevels: UserAccessLevel[]
  }[]
}

@Injectable({
  providedIn: 'root'
})
export class SchedulePcFilterStorageServiceImpl implements SchedulePcFilterStorageService {
  private readonly _clientApi = inject(ClientApi);

  private readonly _filter = signal<SchedulePcFilterState>({
    scheduleType: SchedulePcDisplayType.INDIVIDUAL,
    individual: {
      timeframeType: TimeframeType.WEEK,
    },
    group: {
      timeframeType: TimeframeType.WEEK,
    },
    targetDate: DateTime.now(),
    targetUser: undefined,
    targetUsersAndGroups: {
      users: [],
      userGroupIDs: [],
      membersType: MembersFilterType.USER,
    },
    allUsers: [],
  });

  readonly filter$ = toObservable<SchedulePcFilterState>(this._filter);

  readonly filter = computed(() => this._filter());

  constructor() {
    effect(() => {
      this._clientApi.storeLocalStorage(SCHEDULE_PC_FILTER_KEY_NAME, this._serialize(this._filter()));
    });
    const stored = this._clientApi.lookupLocalStorage<PrimitiveSchedulePcFilterState>(SCHEDULE_PC_FILTER_KEY_NAME);
    if (stored != null) {
      this._filter.set(this._deserialize(stored));
    }
  }

  store(filter: SchedulePcFilterState): void {
    this._filter.set(filter);
  }

  reset(current: SchedulePcFilterState): void {
    if (current.scheduleType === SchedulePcDisplayType.INDIVIDUAL) {
      const resetCurrent = {
        ...current,
        targetDate: DateTime.now(),
        targetUser: undefined,
      }
      this._filter.set(resetCurrent);
    } else {
      const resetCurrent = {
        ...current,
        targetDate: DateTime.now(),
        targetUsersAndGroups: {
          users: [],
          userGroupIDs: [],
          membersType: MembersFilterType.USER,
        },
      }
      this._filter.set(resetCurrent);
    }
  }

  refresh(): void {
    const copy = { ...this._filter() };
    this._filter.set(copy);
  }

  private _serialize(filter: SchedulePcFilterState): PrimitiveSchedulePcFilterState {
    return {
      scheduleType: filter.scheduleType,
      individual: filter.individual ? {
        timeframeType: filter.individual.timeframeType,
      } : undefined,
      group: filter.group ? {
        timeframeType: filter.group.timeframeType,
      } : undefined,
      targetDate: filter.targetDate.toISO(),
      targetUser: filter.targetUser,
      targetUsersAndGroups: {
        users: filter.targetUsersAndGroups.users.map(u => ({
          selected: u.selected,
          id: u.id.value,
          loginID: u.loginID.value,
          name: u.name.value,
        })),
        userGroupIDs: filter.targetUsersAndGroups.userGroupIDs.map(id => id.value),
        membersType: filter.targetUsersAndGroups.membersType,
      },
      allUsers: filter.allUsers.map(u => ({
        id: u.id.value,
        loginID: u.loginID.value,
        name: u.name.value,
        mailAddress: u.mailAddress.value,
        isActive: u.isActive,
        lastLoginDatetime: u.lastLoginDatetime?.toISO(),
        accessLevels: u.accessLevels,
      })),
    };
  }

  private _deserialize(filter: PrimitiveSchedulePcFilterState): SchedulePcFilterState {
    return {
      scheduleType: filter.scheduleType,
      individual: filter.individual ? {
        timeframeType: filter.individual.timeframeType,
      } : undefined,
      group: filter.group ? {
        timeframeType: filter.group.timeframeType,
      } : undefined,
      targetDate: DateTime.fromISO(filter.targetDate),
      targetUser: filter.targetUser,
      targetUsersAndGroups: {
        users: filter.targetUsersAndGroups.users.map(u => ({
          selected: u.selected,
          id: new UserID(u.id),
          loginID: new LoginID(u.loginID),
          name: new DisplayName(u.name),
        })),
        userGroupIDs: filter.targetUsersAndGroups.userGroupIDs.map(id => new UserGroupID(id)),
        membersType: filter.targetUsersAndGroups.membersType,
      },
      allUsers: filter.allUsers.map(u => ({
        id: new UserID(u.id),
        loginID: new LoginID(u.loginID),
        name: new DisplayName(u.name),
        mailAddress: new MailAddress(u.mailAddress),
        isActive: u.isActive,
        lastLoginDatetime: u.lastLoginDatetime ? DateTime.fromISO(u.lastLoginDatetime) : undefined,
        accessLevels: u.accessLevels,
      })),
    };
  }
}
