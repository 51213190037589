import { FormLayoutId } from "app/model/form-layout/form-layout"
import {
  ProductDetailType,
  ProposalTypeDescription,
  ProposalTypeID,
  ProposalTypeName
} from "app/model/proposal-type/proposal-type"
import { Observable } from "rxjs"

export type AddProposalTypeInputs = {
  name: ProposalTypeName,
  description: ProposalTypeDescription,
  productDetailType: ProductDetailType,
  formLayoutID?: FormLayoutId,
}

export type UpdateProposalTypeInputs = {
  name: ProposalTypeName,
  description: ProposalTypeDescription,
  formLayoutID?: FormLayoutId,
}

export abstract class ProposalTypeCommandService {
  abstract add(inputs: AddProposalTypeInputs): Observable<void>;
  abstract update(id: ProposalTypeID, inputs: UpdateProposalTypeInputs): Observable<void>;
  abstract delete(id: ProposalTypeID): Observable<void>;
  abstract sort(ids: ProposalTypeID[]): Observable<void>;
}
