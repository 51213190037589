import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

export type LostReasonsResponse = {
  lostReasons: {
    id: string,
    name: string,
    description: string,
  }[]
}

export type CreateLostReasonRequest = {
  name: string,
  description: string,
}

export type GetLostReasonResponse = {
  id: string,
  name: string,
  description: string,
}

export type UpdateLostReasonRequest = {
  name: string,
  description: string,
}

export type SortLostReasonRequest = {
  ids: string[]
}

export class LostReasonApi {
  constructor(
    private readonly http: HttpClient
  ) {
  }

  list(): Observable<LostReasonsResponse> {
    return this.http.get<LostReasonsResponse>('/api/lost-reasons');
  }

  create(request: CreateLostReasonRequest): Observable<void> {
    return this.http.post<void>(`/api/lost-reasons`, request);
  }

  get(id: string): Observable<GetLostReasonResponse> {
    return this.http.get<GetLostReasonResponse>(`/api/lost-reasons/${id}`);
  }

  update(id: string, request: UpdateLostReasonRequest): Observable<void> {
    return this.http.put<void>(`/api/lost-reasons/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/lost-reasons/${id}`);
  }

  sort(request: SortLostReasonRequest): Observable<void> {
    return this.http.patch<void>(`/api/lost-reasons/sort`, request);
  }
}
