import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ServerApi} from '#infrastructure/api/server-api';
import { ClientID } from 'app/model/client/client';
import { ClientRelationshipID } from 'app/model/client-relationship/client-relationship';
import { ClientRelationshipRepository } from 'app/model/client-relationship/client-relationship.repository';

@Injectable({providedIn: 'root'})
export class ClientRelationshipRepositoryImpl implements ClientRelationshipRepository {
  constructor(
    private readonly serverApi: ServerApi
  ) {
  }

  delete(clientID: ClientID, relationshipID: ClientRelationshipID): Observable<void> {
    return this.serverApi.clientRelationshipApi.delete(clientID.value, relationshipID.value);
  }
}
