import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Result } from 'app/lib/result/result';
import { ClientCategory, ClientTypeID } from 'app/model/client-info-type/client-type';
import {
  Address,
  Client,
  ClientID,
  ClientItem,
  ClientName,
  ClientNumber,
  CorporateNumber,
  CorporationName,
  CorporationNameKana,
  Established,
  FirstName,
  FirstNameKana,
  FiscalYearEnd,
  InitialCapital,
  LastName,
  LastNameKana,
  NumberOfEmployees,
  PhoneNumber,
  SNS,
  Website
} from 'app/model/client/client';
import { Observable } from 'rxjs';

export type AddCorporationInputs = {
  name: ClientName,
  clientTypeID: ClientTypeID,
  corporationName: CorporationName,
  corporationNameKana?: CorporationNameKana,
  corporateNumber?: CorporateNumber,
  address?: Address,
  phoneNumber?: PhoneNumber,
  established?: Established,
  numberOfEmployees?: NumberOfEmployees,
  initialCapital?: InitialCapital,
  fiscalYearEnd?: FiscalYearEnd,
  website?: Website,
  items: ClientItem[]
}
export type AddIndividualInputs = {
  name: ClientName,
  clientTypeID: ClientTypeID,
  firstName?: FirstName,
  lastName?: LastName,
  firstNameKana?: FirstNameKana,
  lastNameKana?: LastNameKana,
  address?: Address,
  phoneNumber?: PhoneNumber,
  sns1?: SNS,
  sns2?: SNS,
  items: ClientItem[]
}

export type AddClientOutput = {
  clientID: ClientID,
  clientNumber: ClientNumber,
}

export type UpdateCorporationInputs = Readonly<{
  category: typeof ClientCategory.Corporation,
  id: ClientID,
  name: ClientName,
  corporationName: CorporationName,
  corporationNameKana?: CorporationNameKana,
  corporateNumber?: CorporateNumber,
  address?: Address,
  phoneNumber?: PhoneNumber,
  established?: Established,
  numberOfEmployees?: NumberOfEmployees,
  initialCapital?: InitialCapital,
  fiscalYearEnd?: FiscalYearEnd,
  website?: Website,
  items: ClientItem[]
}>
export type UpdateIndividualInputs = Readonly<{
  category: typeof ClientCategory.Individual,
  name: ClientName,
  id: ClientID,
  firstName?: FirstName,
  lastName?: LastName,
  firstNameKana?: FirstNameKana,
  lastNameKana?: LastNameKana,
  address?: Address,
  phoneNumber?: PhoneNumber,
  sns1?: SNS,
  sns2?: SNS,
  items: ClientItem[]
}>
export type UpdateClientInputs = UpdateCorporationInputs | UpdateIndividualInputs;

export abstract class ClientCommandV2Service {
  abstract addCorporation(inputs: AddCorporationInputs): Observable<AddClientOutput>;
  abstract addIndividual(inputs: AddIndividualInputs): Observable<AddClientOutput>;
  abstract update(inputs: UpdateClientInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>;
  abstract delete(id: ClientID): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>;
}
