import { ConfirmService } from "#application/services/confirm.service";
import { DeleteEventInputs, DeleteExceptionalEventInputs, EventCommandV2Service, AddEventInputs, ReplyEventInputs, ExceptionalEventInputs, UpdateEventInputs, UpdateEventOutputs } from "#application/services/event-command-v2.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { ScheduleChangeEmitterService } from "#application/services/schedule-change-emitter.service";
import { SnackBarService } from "#application/services/snack-bar.service";
import { ServerApiError, ServerApiErrorCode } from "#infrastructure/api/error";
import { ServerApi } from "#infrastructure/api/server-api";
import { ApiRecurringRule, CreateEventV2Request, DeleteEventRequest, DeleteExceptionalEventRequest, ReplyEventRequest, UpdateEventV2Request, UpdateExceptionalEventRequest } from "#infrastructure/api/server-event-v2-api";
import { Target, messageFn } from "#utils/messages";
import { Injectable, inject } from "@angular/core";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Failure, Result, Success } from "app/lib/result/result";
import { EventAttendeeReplyLabel } from "app/model/event/event-attendee";
import { EventBaseStart, EventIDV2, RecurringRuleType } from "app/model/event/event-v2";
import { DateTime } from "luxon";
import { NEVER, Observable, catchError, concatMap, filter, map, of, tap } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EventCommandV2ServiceImpl extends EventCommandV2Service {
  private readonly _serverApi = inject(ServerApi);
  private readonly _snackBarService = inject(SnackBarService);
  private readonly _messageDialogService = inject(MessageDialogService);
  private readonly _confirmService = inject(ConfirmService);
  private readonly _scheduleChangeEmitter = inject(ScheduleChangeEmitterService);

  add(inputs: AddEventInputs): Observable<Result<
    EventIDV2,
    | typeof GeneralFailure.BadRequest
  >>{
    const request: CreateEventV2Request = {
      name: inputs.name,
      start: DateTime.fromJSDate(inputs.start).toISO({ includeOffset: true }),
      end: DateTime.fromJSDate(inputs.end).toISO({ includeOffset: true }),
      rangeType: inputs.rangeType,
      recurringRule: this._toApiRecurringRule(inputs.recurringRule),
      requiredAttendees: inputs.requiredAttendees.map(v => v.value),
      optionalAttendees: inputs.optionalAttendees.map(v => v.value),
      place: inputs.place,
      url: inputs.url,
      note: inputs.note,
    };
    return this._serverApi.eventV2Api.create(request)
    .pipe(
      tap(() => {
        this._snackBarService.show(messageFn(Target.EVENT, 'CREATE_SUCCESS').message);
        this._scheduleChangeEmitter.emit();
      }),
      map(res => Success(EventIDV2(res))),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.BadRequest:
            return of(Failure(GeneralFailure.BadRequest));
        }
        this._messageDialogService.notice({
          errorTarget: `${Target.EVENT}の登録`
        });
        return NEVER;
      })
    )
  }

  update(inputs: UpdateEventInputs): Observable<Result<
    UpdateEventOutputs,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.NotFound
  >>{
    const request: UpdateEventV2Request = {
      name: inputs.name,
      start: DateTime.fromJSDate(inputs.start).toISO({ includeOffset: true }),
      end: DateTime.fromJSDate(inputs.end).toISO({ includeOffset: true }),
      rangeType: inputs.rangeType,
      recurringRule: this._toApiRecurringRule(inputs.recurringRule),
      requiredAttendees: inputs.requiredAttendees.map(v => v.value),
      optionalAttendees: inputs.optionalAttendees.map(v => v.value),
      place: inputs.place,
      url: inputs.url,
      note: inputs.note,
      eventVersion: inputs.eventVersion,
    };
    return this._serverApi.eventV2Api.update(inputs.id, request)
    .pipe(
      tap(() => {
        this._snackBarService.show(messageFn(Target.EVENT, 'UPDATE_SUCCESS').message);
        this._scheduleChangeEmitter.emit();
      }),
      map((v) => Success<UpdateEventOutputs>({ baseStart: new EventBaseStart(new Date(v.baseStart)) })),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.BadRequest:
            return of(Failure(GeneralFailure.BadRequest));
          case ServerApiErrorCode.Conflict:
            return of(Failure(GeneralFailure.Conflict));
          case ServerApiErrorCode.NotFound:
            return of(Failure(GeneralFailure.NotFound));
        }
        this._messageDialogService.notice({
          errorTarget: `${Target.EVENT}の更新`
        });
        return NEVER;
      })
    );
  }

  delete(inputs: DeleteEventInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>{
    return this._serverApi.eventV2Api.delete(
      inputs.id,
      { eventVersion: inputs.eventVersion } satisfies DeleteEventRequest
    )
    .pipe(
      tap(() => {
        this._snackBarService.show(messageFn(Target.EVENT, 'DELETE_SUCCESS').message);
        this._scheduleChangeEmitter.emit();
      }),
      map((v) => Success<void>(v)),
      catchError((e: ServerApiError) => {
        if (e.code === ServerApiErrorCode.BadRequest) {
          return of(Failure(GeneralFailure.BadRequest));
        }
        if (e.code === ServerApiErrorCode.NotFound) {
          return of(Failure(GeneralFailure.NotFound));
        }
        this._messageDialogService.notice({
          errorTarget: `${Target.EVENT}の削除`
        });
        return NEVER;
      })
    );
  }

  updateExceptionalEvent(inputs: ExceptionalEventInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.NotFound
  >>{
    const request: UpdateExceptionalEventRequest = {
      eventVersion: inputs.eventVersion,
      exceptionalEventVersion: inputs.exceptionalEventVersion,
      name: inputs.name,
      start: DateTime.fromJSDate(inputs.start).toISO({ includeOffset: true }),
      end: DateTime.fromJSDate(inputs.end).toISO({ includeOffset: true }),
      rangeType: inputs.rangeType,
      requiredAttendees: inputs.requiredAttendees.map(v => v.value),
      optionalAttendees: inputs.optionalAttendees.map(v => v.value),
      place: inputs.place,
      url: inputs.url,
      note: inputs.note,
    };
    return this._serverApi.eventV2Api.updateExceptionalEvent(
      inputs.id,
      inputs.baseStart.toString(),
      request
    )
    .pipe(
      tap(() => {
        this._snackBarService.show(messageFn(Target.EVENT, 'UPDATE_SUCCESS').message);
        this._scheduleChangeEmitter.emit();
      }),
      map((v) => Success<void>(v)),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.BadRequest:
            return of(Failure(GeneralFailure.BadRequest));
          case ServerApiErrorCode.Conflict:
            return of(Failure(GeneralFailure.Conflict));
          case ServerApiErrorCode.NotFound:
            return of(Failure(GeneralFailure.NotFound));
        }
        this._messageDialogService.notice({
          errorTarget: `${Target.EVENT}の更新`
        });
        return NEVER;
      })
    );
  }

  deleteExceptionalEvent(inputs: DeleteExceptionalEventInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >> {
    return this._serverApi.eventV2Api.deleteExceptionalEvent(
      inputs.id,
      inputs.baseStart.toString(),
      {
        eventVersion: inputs.eventVersion,
        exceptionalEventVersion: inputs.exceptionalEventVersion
      } satisfies DeleteExceptionalEventRequest
    )
    .pipe(
      tap(() => {
        this._snackBarService.show(messageFn(Target.EVENT, 'DELETE_SUCCESS').message);
        this._scheduleChangeEmitter.emit();
      }),
      map((v) => Success<void>(v)),
      catchError((e: ServerApiError) => {
        if (e.code === ServerApiErrorCode.BadRequest) {
          return of(Failure(GeneralFailure.BadRequest));
        }
        if (e.code === ServerApiErrorCode.NotFound) {
          return of(Failure(GeneralFailure.NotFound));
        }
        this._messageDialogService.notice({
          errorTarget: `${Target.EVENT}の削除`
        });
        return NEVER;
      })
    );
  }

  reply(inputs: ReplyEventInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >>{
    return this._confirmService.confirm({
      title: `${Target.EVENT}の出欠回答確認`,
      message: `「${EventAttendeeReplyLabel[inputs.status]}」で回答します。よろしいですか。`
    })
    .pipe(
      filter(r => r.isOK()),
      concatMap(() => this._serverApi.eventV2Api.reply(
        inputs.id,
        inputs.baseStart.toString(),
        {
          eventVersion: inputs.eventVersion,
          exceptionalEventVersion: inputs.exceptionalEventVersion,
          status: inputs.status
        } satisfies ReplyEventRequest
      )),
      tap(() => {
        this._snackBarService.show(`${Target.EVENT}の出欠回答を行いました。`);
        this._scheduleChangeEmitter.emit();
      }),
      map((v) => Success<void>(v)),
      catchError((e: ServerApiError) => {
        if (e.code === ServerApiErrorCode.BadRequest) {
          return of(Failure(GeneralFailure.BadRequest));
        }
        if (e.code === ServerApiErrorCode.NotFound) {
          return of(Failure(GeneralFailure.NotFound));
        }
        this._messageDialogService.notice({
          errorTarget: `${Target.EVENT}の出欠回答`
        });
        return NEVER;
      })
    );
  }

  private _toApiRecurringRule(recurringRule: AddEventInputs['recurringRule']): ApiRecurringRule {
    const type = recurringRule.type;
    switch (type) {
      case RecurringRuleType.NEVER:
        return { type };
      case RecurringRuleType.RECURRING:
        return {
          type,
          until: recurringRule.until == null
            ? undefined
            : DateTime.fromJSDate(recurringRule.until).toISO({ includeOffset: true }),
          dayOfWeeks: recurringRule.dayOfWeeks,
        };
      default:
        const _: never = type;
        throw new Error(`Unexpected recurring rule type: ${_}`);
    }
  }
}
