import { Component, Input } from '@angular/core';

@Component({
  selector: 'sn-feature-title-bar',
  templateUrl: './feature-title-bar.component.html',
  styleUrls: ['./feature-title-bar.component.scss']
})
export class FeatureTitleBarComponent {

  @Input()
  title!: string;

  @Input()
  description!: string;

  constructor() { }
}
