import {LocalizedName, NameId, OriginalName} from "app/model/localize/localize";
import {Observable} from "rxjs";

export type SaveLocalizationSettingInput = Readonly<{
  lang: string,
  list: {
    id: NameId,
    localizedName: LocalizedName,
  }[]
}>

export abstract class LocalizationSettingCommandService {
  abstract save(input: SaveLocalizationSettingInput): Observable<void>;
}
