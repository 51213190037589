import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiCoachingTiming } from "./server-insight-api";

export type SearchIncorporatedInsightListRequest = {
  perPage: number,
  page: number,
  filter: {
    question: {
      type: 'contains',
      value: string,
    } | undefined,
    opportunityContextID: {
      type: 'anyMatch',
      values: string[],
    } | undefined,
    incorporated: {
      type: 'anyMatch',
      values: ('TRUE' | 'FALSE')[],
    } | undefined,
    coachingTiming: {
      type: 'anyMatch',
      values: ApiCoachingTiming[],
    } | undefined,
  },
};

export type SearchIncorporatedInsightListResponse = {
  totalCount: number,
  results: {
    id: string,
    coachingTiming: ApiCoachingTiming,
    question: string,
    incorporated: boolean,
    triggeringOpportunityContexts: {
      id: string,
      name: string,
    }[],
  }[]
}

export type InBulkIncorporatedInsightByFilterRequest = {
  question: {
    type: 'contains',
    value: string,
  } | undefined,
  opportunityContextID: {
    type: 'anyMatch',
    values: string[],
  } | undefined,
  incorporated: {
    type: 'anyMatch',
    values: ('TRUE' | 'FALSE')[],
  } | undefined,
  coachingTiming: {
    type: 'anyMatch',
    values: ApiCoachingTiming[],
  } | undefined,
}

export class IncorporatedInsightApi {
  constructor(private readonly http: HttpClient) {}

  search(request: SearchIncorporatedInsightListRequest): Observable<SearchIncorporatedInsightListResponse> {
    return this.http.post<SearchIncorporatedInsightListResponse>(`/api/incorporated-insight-list/search`, request);
  }

  register(id: string): Observable<void> {
    return this.http.post<void>(`/api/incorporated-insights/${id}`, {});
  }

  unregister(id: string): Observable<void> {
    return this.http.delete<void>(`/api/incorporated-insights/${id}`);
  }

  registerInBulk(request: InBulkIncorporatedInsightByFilterRequest): Observable<void> {
    return this.http.post<void>(`/api/incorporated-insights/register`, request);
  }

  unregisterInBulk(request: InBulkIncorporatedInsightByFilterRequest): Observable<void> {
    return this.http.post<void>(`/api/incorporated-insights/unregister`, request);
  }
}
