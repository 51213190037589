import { ConsultantQueryService } from "#application/services/consultant-query.service";
import { Page, PageNavigateService, PathOptions } from "#application/services/page-navigate.service";
import { Component } from "@angular/core";
import { ConsultantTableList, convertToTableData } from "./list";

@Component({
  selector: 'sn-consultant-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ConsultantListComponent {

  tableData: ConsultantTableList = [];

  displayedColumns: string[] = [
    'loginID',
    'displayName',
    'mailAddress',
    'assignedOrganization',
    'lastLoginDateTime',
  ];

  constructor(
    private readonly consultantQueryService: ConsultantQueryService,
    private readonly pageNavigateService: PageNavigateService,
  ) {
    this.consultantQueryService.list().subscribe(list => {
      this.tableData = convertToTableData(list);
    });
  }

  onAddClick(): void {
    this.pageNavigateService.navigate(Page.AdminConsultantAdd);
  }

  onDetailClick(id: string): void {
    const op: PathOptions = {
      requiredParam: id,
    };
    this.pageNavigateService.navigate(Page.AdminConsultantDetail, op);
  }
}

