import {
  OpportunityBuiltinFieldQueryService,
  OpportunityFormLayoutQueryService
} from "#application/services/opportunity-form-layout-query.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {
  BuiltinField,
  BuiltinFieldId,
  BuiltinFieldItem,
  CustomFieldItem,
  DefaultFormLayout,
  FormLayout,
  FormLayoutCategory,
  FormLayoutId,
  FormLayoutName,
  FormLayoutWidth,
  SectionTitle,
  SectionTitleItem
} from "app/model/form-layout/form-layout";
import {ServerApi} from "#infrastructure/api/server-api";
import {map} from "rxjs/operators";
import {FieldType} from "app/model/custom-field/field-type";
import {FormLayoutOutline} from "#application/services/form-layout-query.service";
import {GetDefaultFormLayoutResponse} from "#infrastructure/api/server-default-form-layout-api";
import {
  GetFormLayoutResponse,
  ListFormLayoutResponse
} from "#infrastructure/api/server-form-layout-api";
import {CustomFieldId} from "app/model/custom-field/custom-field";

@Injectable({
  providedIn: 'root'
})
export class OpportunityBuiltinFieldQueryServiceImpl extends OpportunityBuiltinFieldQueryService {
  constructor(
    private readonly serverApi: ServerApi,
  ) {
    super();
  }

  list(): Observable<BuiltinField[]> {
    return this.serverApi.builtinFieldApi.list('opportunity')
      .pipe(
        map(response => response.map(item => new BuiltinField(
          BuiltinFieldId.valueOf(item.id, 'opportunity'),
          FieldType[item.fieldType],
          item.needsToDisplay
        )))
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class OpportunityFormLayoutQueryServiceImpl extends OpportunityFormLayoutQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }
  get(id: FormLayoutId): Observable<FormLayout> {
    return this.serverApi.formLayoutApi.get('opportunity', id.value)
      .pipe(
        map(value => responseToFormLayout(FormLayoutCategory.OPPORTUNITY, value))
      );
  }

  getDefaultLayout(): Observable<DefaultFormLayout> {
    return this.serverApi.defaultFormLayoutApi.get('opportunity')
      .pipe(
        map(value => responseToDefaultFormLayout(FormLayoutCategory.OPPORTUNITY, value))
      );
  }

  list(): Observable<FormLayoutOutline[]> {
    return this.serverApi.formLayoutApi.list('opportunity')
      .pipe(
        map(responseToFormLayoutOutlines)
      );
  }
}

const responseToDefaultFormLayout = (category: FormLayoutCategory, response: GetDefaultFormLayoutResponse): DefaultFormLayout => {
  const items = response.items.map(e => {
    switch (e.type) {
      case "BUILTIN_FIELD":
        return new BuiltinFieldItem(FormLayoutWidth[e.width], BuiltinFieldId.valueOf(e.builtinFieldID, category));
      case "SECTION_TITLE":
        return new SectionTitleItem(new SectionTitle(e.title));
      default:
        const unexpected: never = e;
        throw Error(`${unexpected} is unexpected value`);
    }
  });
  return new DefaultFormLayout(items);
};

const responseToFormLayout = (category: FormLayoutCategory, response: GetFormLayoutResponse): FormLayout => {
  const items = response.items.map(e => {
    switch (e.type) {
      case "BUILTIN_FIELD":
        return new BuiltinFieldItem(FormLayoutWidth[e.width], BuiltinFieldId.valueOf(e.builtinFieldID, category));
      case "CUSTOM_FIELD":
        return new CustomFieldItem(FormLayoutWidth[e.width], new CustomFieldId(e.customFieldID));
      case "SECTION_TITLE":
        return new SectionTitleItem(new SectionTitle(e.title));
      default:
        const unexpected: never = e;
        throw Error(`${unexpected} is unexpected value`);
    }
  });
  return new FormLayout(
    new FormLayoutId(response.id),
    new FormLayoutName(response.name),
    items
  );
};

const responseToFormLayoutOutlines = (response: ListFormLayoutResponse): FormLayoutOutline[] => response.map(e => ({
  id: new FormLayoutId(e.id),
  name: new FormLayoutName(e.name)
}));
