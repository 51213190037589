/**
 * 失注理由ID
 */
export class LostReasonID {
  constructor(public readonly value: string) {}
}

/**
 * 失注理由名
 */
export class LostReasonName {
  static readonly MAX_LENGTH = 30;

  constructor(public readonly value: string) {}
}

/**
 * 失注理由説明
 */
export class LostReasonDescription {
  static readonly MAX_LENGTH = 100;

  constructor(public readonly value: string) {}
}

/**
 * 失注理由
 */
export class LostReason {
  constructor(
    public readonly id: LostReasonID,
    public readonly name: LostReasonName,
    public readonly description: LostReasonDescription
  ) {}
}

/**
 * 失注理由リスト
 */
export type LostReasonList = LostReason[];
