import { TopBarModule } from '#presentation/components/top-bar/top-bar.module';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'sn-not-found',
  standalone: true,
  imports: [
    CommonModule,
    TopBarModule,
    RouterOutlet,
  ],
  template: `
    <sn-top-bar [showMenuToggle]="false"></sn-top-bar>
    <div class="contents">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [`.contents { height: calc(100svh - 48px); }`]
})
export class ErrorComponent {
}
