import { LocalizeKey } from '#application/services/authorize.service';
import { ConfirmService } from '#application/services/confirm.service';
import { LocalizedNameService } from '#application/services/localized-name.service';
import { MessageDialogService } from '#application/services/message-dialog.service';
import { OpportunityGroupTypeCommandService, OpportunityGroupTypeInputs, SortOpportunityGroupTypeInputs } from '#application/services/opportunity-group-type-command.service';
import { SnackBarService } from '#application/services/snack-bar.service';
import { OpportunityGroupTypeID } from 'app/model/opportunity-group/opportunity-group-type';
import { ServerApiError, ServerApiErrorCode } from '#infrastructure/api/error';
import { ServerApi } from '#infrastructure/api/server-api';
import { messageFn } from '#utils/messages';
import { Injectable, inject } from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import { NEVER, Observable, catchError, concatMap, filter, map, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpportunityGroupTypeCommandServiceServiceImpl implements OpportunityGroupTypeCommandService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _confirmService = inject(ConfirmService);
  private readonly _snackBarService = inject(SnackBarService);
  private readonly _messageDialogService = inject(MessageDialogService);
  private readonly _localizedNameService = inject(LocalizedNameService);

  add(inputs: OpportunityGroupTypeInputs): Observable<Result<
    OpportunityGroupTypeID,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
  >> {
    const fieldName = `${this._localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}タイプ`;
    return this._serverApi.opportunityGroupTypeApi
      .create({
        name: inputs.name,
        description: inputs.description,
        workflowID: inputs.workflowID,
        formLayoutID: inputs.formLayoutID?.value,
      })
      .pipe(
        tap(() => {
          this._snackBarService.show(messageFn(fieldName, 'CREATE_SUCCESS').message);
        }),
        map(res => Success(OpportunityGroupTypeID(res.id))),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.BadRequest:
              return of(Failure(GeneralFailure.BadRequest));
            case ServerApiErrorCode.Conflict:
              return of(Failure(GeneralFailure.Conflict));
            default:
              this._messageDialogService.notice({
                errorTarget: `${ fieldName }の登録`
              });
              return NEVER;
          }
        }),
      );
  }

  update(id: OpportunityGroupTypeID, inputs: OpportunityGroupTypeInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.NotFound
  >> {
    const fieldName = `${this._localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}タイプ`;
    return this._serverApi.opportunityGroupTypeApi
      .update(id, {
        name: inputs.name,
        description: inputs.description,
        workflowID: inputs.workflowID,
        formLayoutID: inputs.formLayoutID?.value,
      })
      .pipe(
        tap(() => {
          this._snackBarService.show(messageFn(fieldName, 'UPDATE_SUCCESS').message);
        }),
        map(v => Success<void>(v)),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.BadRequest:
              return of(Failure(GeneralFailure.BadRequest));
            case ServerApiErrorCode.Conflict:
              return of(Failure(GeneralFailure.Conflict));
            case ServerApiErrorCode.NotFound:
              return of(Failure(GeneralFailure.NotFound));
            default:
              this._messageDialogService.notice({
                errorTarget: `${ fieldName }の更新`
              });
              return NEVER;
          }
        }),
      );
  }

  delete(id: OpportunityGroupTypeID): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Conflict
    | typeof GeneralFailure.NotFound
  >> {
    const fieldName = `${this._localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}タイプ`;
    return this._confirmService
      .confirm({
        title: messageFn(fieldName, 'DELETE_CONFIRM').title,
        message: messageFn(fieldName, 'DELETE_CONFIRM').message
      })
      .pipe(
        filter(r => r.isOK()),
        concatMap(() => this._serverApi.opportunityGroupTypeApi.delete(id)),
        tap(() => {
          this._snackBarService.show(messageFn(fieldName, 'DELETE_SUCCESS').message);
        }),
        map(v => Success<void>(v)),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.BadRequest:
              return of(Failure(GeneralFailure.BadRequest));
            case ServerApiErrorCode.Conflict:
              return of(Failure(GeneralFailure.Conflict));
            case ServerApiErrorCode.NotFound:
              return of(Failure(GeneralFailure.NotFound));
            default:
              this._messageDialogService.notice({
                errorTarget: `${ fieldName }の削除`
              });
              return NEVER;
          }
        }),
      );
  }

  sort(inputs: SortOpportunityGroupTypeInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >> {
    const fieldName = `${this._localizedNameService.get(LocalizeKey.OPPORTUNITY_GROUP)}タイプ`;
    return this._serverApi.opportunityGroupTypeApi
      .sort(inputs)
      .pipe(
        tap(() => {
          this._snackBarService.show(messageFn(fieldName, 'SORT_SUCCESS').message);
        }),
        map(v => Success<void>(v)),
        catchError((e: ServerApiError) => {
          switch (e.code) {
            case ServerApiErrorCode.BadRequest:
              return of(Failure(GeneralFailure.BadRequest));
            case ServerApiErrorCode.NotFound:
              return of(Failure(GeneralFailure.NotFound));
            default:
              this._messageDialogService.notice({
                errorTarget: `${ fieldName }の並び替え`
              });
              return NEVER;
          }
        }),
      );
  }
}
