import { DefaultBodyType, MockedRequest, RestHandler } from "msw";
import { eventHandlers } from "./event-handlers";
import { scheduleHandlers } from "./schedule-handlers";
import { opportunityPerPhaseHandlers } from "./opportunity-per-phase-handlers";

/**
 * 各自が作成したモックAPIハンドラーを集約するハンドラー
 */
export const mockHandlers: RestHandler<MockedRequest<DefaultBodyType>>[] = [
  // ...countHandlers,
  // ...unreportedActivityHandlers,
  // ...followHandlers,
  // ...tokenHandlers,
  // ...opportunityGroupHandlers,
  // ...customFieldHandlers,
  // ...userGroupForSelectHandlers,
  // ...opportunityTypesHandlers,
  // ...meetingBoardHandlers,
  // ...opportunityHandlers,
  // ...scheduleHandlers,
  // ...eventHandlers,
  // ...opportunityPerPhaseHandlers,
];
