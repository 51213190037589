import { SecurityRole } from "app/model/security-role/security-role";
import { Observable } from "rxjs";
import { ApiSecurityRole } from "../../model/storage/authorize-info";

export type AuthorizeInfo = {
  id: string,
  organizationName?: string,
  loginID: string,
  displayName: string,
  mailAddress: string,
  fiscalYearBegin?: number,
  securityRoles: SecurityRole[],
  isCoachingAvailable?: boolean,
  isProposalEnabled?: boolean,
  isLinkageEnabled?: boolean,
  isOpportunityGroupEnabled?: boolean,
  localize?: Record<LocalizeKey, string>,
}
export const LocalizeKey = {
  NURTURING: 'NURTURING',
  OPPORTUNITY_GROUP: 'OPPORTUNITY_GROUP',
} as const;
export type LocalizeKey = typeof LocalizeKey[keyof typeof LocalizeKey];

export class NoAuthInfoError extends Error {}

/**
 * 認証サービス
 */
export abstract class AuthorizeService {
  abstract login(loginID: string, password: string): Observable<boolean>;
  abstract logout(): Observable<void>;
  abstract isAuthenticated(): boolean;
  /**
   * 認証情報を取得する
   *
   * @throws {NoAuthInfoError} 認証情報が存在しない場合
   */
  abstract getAuthInfo(): AuthorizeInfo;
  abstract isCoachingAvailable(): boolean;
  abstract isProposalEnabled(): boolean;
  abstract isLinkageEnabled(): boolean;
  abstract isOpportunityGroupEnabled(): boolean;
  abstract getSecurityRoles(roles: ApiSecurityRole[]): SecurityRole[];
}
