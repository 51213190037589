import {ServerApi} from '#infrastructure/api/server-api';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import { OpportunityContextList, SearchOpportunityContextListParams } from '#application/services/base-opportunity-context-query.service';
import { OpportunityContext, OpportunityContextDescription, OpportunityContextID, OpportunityContextLabelName, OpportunityContextName } from 'app/model/opportunity-context/opportunity-context';
import { OpportunityContextQueryService } from '#application/services/opportunity-context-query.service';

@Injectable({
  providedIn: 'root',
})
export class OpportunityContextQueryServiceImpl extends OpportunityContextQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  findBy(params: SearchOpportunityContextListParams): Observable<OpportunityContextList> {
    return this.serverApi.opportunityContextApi.search(params).pipe(
      map(data => ({
        totalCount: data.totalCount,
        results: data.results.map(result => ({
          id: new OpportunityContextID(result.id),
          name: new OpportunityContextName(result.name),
          description: new OpportunityContextDescription(result.description),
          readonly: result.readonly,
          labelNames: result.labelNames.map(v => new OpportunityContextLabelName(v)),
        }))
      }))
    );
  }

  get(id: OpportunityContextID): Observable<OpportunityContext> {
    return this.serverApi.opportunityContextApi.get(id.value).pipe(
      map(data => new OpportunityContext(
        new OpportunityContextID(data.id),
        new OpportunityContextName(data.name),
        new OpportunityContextDescription(data.description),
        data.labelNames.map(v => new OpportunityContextLabelName(v)),
      ))
    );
  }
}
