import { AuthorizeCheckGuard } from '#application/guard/authorize-check.guard';
import { DoesNotHaveSubDomainGuard } from "#application/guard/does-not-have-sub-domain.guard";
import { HasSubDomainGuard } from "#application/guard/has-sub-domain.guard";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginAsConsultantComponent } from './pages/auth/login-as-consultant/login-as-consultant.component';
import { LoginComponent } from './pages/auth/login/login.component';

const routes: Routes = [
  {
    path: 'sign-in',
    component: LoginComponent,
    canActivate: [HasSubDomainGuard]
  },
  {
    path: 'sign-in-as-consultant',
    component: LoginAsConsultantComponent,
    canActivate: [DoesNotHaveSubDomainGuard]
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./pages/error/error-routes').then((m) => m.ErrorRoutes)
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./pages/admin/admin.module').then((m => m.AdminModule)),
    canLoad: [DoesNotHaveSubDomainGuard, AuthorizeCheckGuard]
  },
  {
    path: 'consulting',
    loadChildren: () =>
      import('./pages/consulting/consulting.module').then((m) => m.ConsultingModule),
    canLoad: [DoesNotHaveSubDomainGuard, AuthorizeCheckGuard]
  },
  {
    path: 'single-sign-on',
    loadChildren: () =>
      import('./pages/single-sign-on/single-sign-on.module').then((m) => m.SingleSignOnModule),
    canLoad: [HasSubDomainGuard]
  },
  {
    path: '',
    canActivate: [HasSubDomainGuard, AuthorizeCheckGuard],
    loadChildren: () =>
      import('./organization-user-routes').then((m) => m.OrganizationUserRoutes),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
