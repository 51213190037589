import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

/**
 * 商談ヒアリング設定保存用データ
 */
export type SaveOpportunityHearingSettingRequest =
  OpportunityHearingSettingSaveData[];

interface OpportunityHearingSettingSaveData {
  customFieldID: string;
  values: {
    phaseID: string;
    before: 'none' | 'hearing';
    after: 'none' | 'hearing';
  }[];
}

/**
 * 商談ヒアリング設定データ
 */
export type ListOpportunityHearingSettingsResponse =
  OpportunityHearingSettingData[];

interface OpportunityHearingSettingData {
  customFieldID: string
  customFieldName: string;
  values: {
    phaseID: string;
    before: 'none' | 'hearing';
    after: 'none' | 'hearing';
  }[];
}

/**
 * 商談ヒアリング設定API
 */
export class OpportunityHearingSettingApi {
  constructor(private readonly http: HttpClient) {
  }

  /**
   * 商談タイプに紐付く商談ヒアリング設定を取得する
   *
   * @param opportunityTypeId 取得対象の商談タイプID
   * @returns 引数で指定した商談タイプIDに紐づく商談ヒアリング設定データ
   */
  list(
    opportunityTypeId: string
  ): Observable<ListOpportunityHearingSettingsResponse> {
    return this.http.get<ListOpportunityHearingSettingsResponse>(
      `/api/opportunity-types/${opportunityTypeId}/opportunity-hearing-settings`
    );
  }

  /**
   * 商談タイプに紐付く商談ヒアリング設定を保存する
   *
   * @param opportunityTypeId 保存先の商談タイプ
   * @param request 保存する商談ヒアリング設定データ
   * @returns
   */
  saveAll(
    opportunityTypeId: string,
    request: SaveOpportunityHearingSettingRequest
  ): Observable<void> {
    return this.http.post<void>(
      `/api/opportunity-types/${opportunityTypeId}/opportunity-hearing-settings/save-all`,
      request
    );
  }
}
