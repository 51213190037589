import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  IsCoachingAvailable,
  FiscalYearBegin,
  Organization,
  OrganizationCode,
  OrganizationID,
  OrganizationName, IsProposalEnabled, IsLinkageEnabled, IsOpportunityGroupEnabled
} from "app/model/organization/organization";
import { OrganizationRepository } from "app/model/organization/organization.repository";
import { ServerApi } from "#infrastructure/api/server-api";
import { OrganizationData } from "#infrastructure/api/server-organization-api";

const convertFromData = (data: OrganizationData): Organization => new Organization(
  new OrganizationID(data.id),
  new OrganizationCode(data.code),
  new OrganizationName(data.name),
  new FiscalYearBegin(data.fiscalYearBegin),
  new IsCoachingAvailable(data.isCoachingAvailable),
  new IsProposalEnabled(data.isProposalEnabled),
  new IsLinkageEnabled(data.isLinkageEnabled),
  new IsOpportunityGroupEnabled(data.isOpportunityGroupEnabled),
);

@Injectable({ providedIn: 'root' })
export class OrganizationRepositoryImpl implements OrganizationRepository {
  constructor(private readonly api: ServerApi) {
  }

  findByID(id: OrganizationID): Observable<Organization> {
    return this.api.organization.get(id.value).pipe(
      map(convertFromData)
    );
  }

  findAll(): Observable<Organization[]> {
    return this.api.organization.list().pipe(
      map(organizations => organizations.map(convertFromData))
    );
  }

  delete(id: OrganizationID): Observable<void> {
    return this.api.organization.delete(id.value);
  }

  save(organization: Organization): Observable<void> {
    return this.api.organization.update(
      organization.id.value,
      {
        name: organization.name.value,
        fiscalYearBegin: organization.fiscalYearBegin.value,
        isCoachingAvailable: organization.isCoachingAvailable.value,
        isProposalEnabled: organization.isProposalEnabled.value,
        isLinkageEnabled: organization.isLinkageEnabled.value,
        isOpportunityGroupEnabled: organization.isOpportunityGroupEnabled.value,
      }
    );
  }
}
