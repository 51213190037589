import { Observable } from "rxjs";
import { SearchFilter, SearchFilterCategory, SearchFilterId } from "./search-filter";

export abstract class SearchFilterRepository {
  abstract delete(category: SearchFilterCategory, id: SearchFilterId): Observable<void>;

  abstract save(category: SearchFilterCategory, filter: SearchFilter): Observable<void>;

  abstract deleteBookmark(category: SearchFilterCategory, id: SearchFilterId): Observable<void>;
}
