import { SearchFilterCategory } from "app/model/search-filter/search-filter";
import {Observable} from "rxjs";
import {ItemType} from "#infrastructure/application/filter.service";

export type PropertyType = 'text' | 'date' | 'number' | 'list' | 'user' | 'file' | 'client' | 'checkbox';
export type SearchFilterProperty =
  {
    itemName: string,
    itemType: ItemType,
    propertyId: `item:${string}`,
    propertyName: string,
    propertyType: PropertyType,
    predicates: SearchFilterPredicates[]
  }

export type SearchFilterPredicates =
  {
    type: 'contains' | 'greaterThanOrEqualTo' | 'lessThanOrEqualTo' | 'empty',
    valueType: 'text' | 'date',
  } |
  {
    type: 'contains' | 'greaterThanOrEqualTo' | 'lessThanOrEqualTo' | 'empty',
    valueType: 'number',
    unit: string,
  } |
  {
    type: 'anyMatch',
    valueType: 'list' | 'user',
    listValues?: string[],
  }

export abstract class SearchFilterPropertyQueryService {
  abstract list(category: SearchFilterCategory): Observable<SearchFilterProperty[]>;
}
