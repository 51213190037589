import {ClientID} from "app/model/client/client";
import {ContactID} from "app/model/contact/contact";
import {Observable} from "rxjs";

export type RelatedIDs = {
  contactID: ContactID,
  clientID: ClientID,
}

export abstract class ClientRelatedWithContactRepository {

  abstract delete(relation: RelatedIDs): Observable<void>;

  abstract saveAsMain(relation: RelatedIDs): Observable<void>;
}
