import {ServerApi} from '#infrastructure/api/server-api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ContactHistoriesList} from '#application/services/contact-history-query.service';
import {LoginID} from 'app/model/user/user';
import {ContactHistoryContent, ContactHistoryID} from 'app/model/contact-history/contact-history';
import {DateTime} from 'luxon';
import {ClientID} from 'app/model/client/client';
import {OpportunityID, OpportunityName, OpportunityNumber} from "app/model/opportunity/opportunity";
import {OpportunityContactHistoryQueryService} from "#application/services/opportunity-contact-history-query.service";

@Injectable({
  providedIn: 'root',
})
export class OpportunityContactHistoryQueryServiceImpl extends OpportunityContactHistoryQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  list(clientID: ClientID, opportunityID: OpportunityID): Observable<ContactHistoriesList> {
    return this.serverApi.opportunityContactHistoryApi.list(clientID.value, opportunityID.value).pipe(
      map(histories =>
        histories.map(history => ({
          id: new ContactHistoryID(history.id),
          authorName: new LoginID(history.authorName),
          content: new ContactHistoryContent(history.content),
          opportunityID: history.opportunityID ? new OpportunityID(history.opportunityID) : undefined,
          opportunityNumber: history.opportunityNumber ? new OpportunityNumber(history.opportunityNumber) : undefined,
          opportunityName: history.opportunityName ? new OpportunityName(history.opportunityName) : undefined,
          createdDateTime: DateTime.fromJSDate(new Date(history.createdDateTime)),
          isEdited: history.isEdited,
          editedDateTime: DateTime.fromJSDate(new Date(history.editedDateTime)),
        }))
      )
    );
  }
}
