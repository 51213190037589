import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ServerApi} from "#infrastructure/api/server-api";
import { ProductTypeRepository, UpdateProductTypeParam } from "app/model/product-type/product-type.repository";
import { ProductTypeId } from "app/model/product-type/product-type";

@Injectable({providedIn: 'root'})
export class ProductTypeRepositoryImpl extends ProductTypeRepository {
  constructor(
    private readonly serverApi: ServerApi,
  ) {
    super();
  }

  save(param: UpdateProductTypeParam): Observable<void> {
    return this.serverApi.productTypeApi.update(
      param.id.value,
      {
        name: param.name.value,
        description: param.description.value,
        formLayoutID: param.formLayoutID ? param.formLayoutID.value : undefined
      }
    );
  }

  remove(id: ProductTypeId): Observable<void> {
    return this.serverApi.productTypeApi.delete(id.value);
  }

  saveOrders(ids: ProductTypeId[]): Observable<void> {
    const request = { ids: ids.map(id => id.value) };
    return this.serverApi.productTypeApi.sort(request);
  }
}
