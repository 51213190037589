import {inject, Injectable} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {SnackBarService} from "#application/services/snack-bar.service";
import {catchError, filter, concatMap, tap} from "rxjs/operators";
import { InsightID } from 'app/model/insight/insight';
import { IncorporatedInsightByFilterInputs, IncorporatedInsightCommandService } from '#application/services/incorporated-insight-command.service';
import { ServerApi } from '#infrastructure/api/server-api';
import { ConfirmService } from '#application/services/confirm.service';
import { InBulkIncorporatedInsightByFilterRequest } from '#infrastructure/api/server-incorporated-insight-api';

@Injectable({
  providedIn: 'root'
})
export class IncorporatedInsightCommandServiceImpl implements IncorporatedInsightCommandService {

  private readonly serverApi = inject(ServerApi);
  private readonly snackBarService = inject(SnackBarService);
  private readonly confirmService = inject(ConfirmService);

  register(id: InsightID): Observable<void> {
    return this.serverApi.incorporatedInsightApi
    .register(id.value)
    .pipe(
      catchError((err) => {
        this.snackBarService.show('共通インサイトの取込みに失敗しました。');
        return throwError(err);
      })
    );
  }

  unregister(id: InsightID): Observable<void> {
    return this.serverApi.incorporatedInsightApi
    .unregister(id.value)
    .pipe(
      catchError((err) => {
        this.snackBarService.show('共通インサイトの取込み解除に失敗しました。');
        return throwError(err);
      })
    );
  }

  registerInBulk(inputs: IncorporatedInsightByFilterInputs): Observable<void> {
    return this.confirmService.confirm(
      {
        title: '一括取込み確認',
        message: `抽出されている${inputs.resultTotal}件の共通インサイトを一括で取り込みます。\nよろしいですか。`
      }
    )
    .pipe(
      filter(result => result.isOK()),
      concatMap(() => this.serverApi.incorporatedInsightApi.registerInBulk(convertIncorporatedInsightByFilterInputs(inputs))),
      tap(() => this.snackBarService.show(`${inputs.resultTotal}件の共通インサイトを一括で取り込みました。`)),
      catchError((err) => {
        this.snackBarService.show('共通インサイトの一括取込みに失敗しました。');
        return throwError(err);
      })
    );
  }

  unregisterInBulk(inputs: IncorporatedInsightByFilterInputs): Observable<void> {
    return this.confirmService.confirm(
      {
        title: '一括取込み解除確認',
        message: `抽出されている${inputs.resultTotal}件の共通インサイトの取込みを一括で解除します。\nよろしいですか。`
      }
    )
    .pipe(
      filter(result => result.isOK()),
      concatMap(() => this.serverApi.incorporatedInsightApi.unregisterInBulk(convertIncorporatedInsightByFilterInputs(inputs))),
      tap(() => this.snackBarService.show(`${inputs.resultTotal}件の共通インサイトを一括で取込み解除しました。`)),
      catchError((err) => {
        this.snackBarService.show('共通インサイトの一括取込み解除に失敗しました。');
        return throwError(err);
      })
    );
  }
}

const convertIncorporatedInsightByFilterInputs = (
  inputs: IncorporatedInsightByFilterInputs
): InBulkIncorporatedInsightByFilterRequest => {
  return {
    question: inputs.filter.question.value.length === 0
      ? undefined
      : inputs.filter.question,
    opportunityContextID: inputs.filter.opportunityContextID.values.length === 0
      ? undefined
      : {
        type: inputs.filter.opportunityContextID.type,
        values: inputs.filter.opportunityContextID.values,
      },
    incorporated: inputs.filter.incorporated.values.length === 0
      ? undefined
      : inputs.filter.incorporated,
    coachingTiming: inputs.filter.coachingTiming.values.length === 0
      ? undefined
      : inputs.filter.coachingTiming,
  };
};
