import {
  RestAuthTokenID,
  RestAuthTokenKeyType,
  RestAuthTokenName,
  RestAuthTokenString,
  RestAuthTokenSubjectID
} from "app/model/rest-auth-token/rest-auth-token";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import {Observable} from "rxjs";

export type AddRestAuthTokenInputs = {
  name: RestAuthTokenName,
  subjectID: RestAuthTokenSubjectID,
}

export type UpdateRestAuthTokenInputs = {
  name: RestAuthTokenName,
  subjectID: RestAuthTokenSubjectID,
}

export type AddRestAuthTokenOutputs = {
  tokenString: RestAuthTokenString,
}

export type AddRestAuthTokenKeyOutputs = {
  tokenString: RestAuthTokenString,
}

export type DeleteRestAuthTokenKeyInputs = {
  id: RestAuthTokenID,
  key: RestAuthTokenKeyType,
};

export abstract class RestAuthTokenCommandService {
  abstract add(inputs: AddRestAuthTokenInputs): Observable<AddRestAuthTokenOutputs>;

  abstract update(id: RestAuthTokenID, inputs: UpdateRestAuthTokenInputs): Observable<Result<
    void,
    | typeof GeneralFailure.NotFound
    | typeof GeneralFailure.Conflict
  >>;

  abstract delete(id: RestAuthTokenID): Observable<void>;

  abstract addKey(id: RestAuthTokenID): Observable<AddRestAuthTokenKeyOutputs>;

  abstract deleteKey(inputs: DeleteRestAuthTokenKeyInputs): Observable<void>;
}
