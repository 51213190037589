import { AddClientOutput, AddCorporationInputs, AddIndividualInputs, ClientCommandV2Service, UpdateClientInputs } from '#application/services/client-command-v2.service';
import { ConfirmService } from '#application/services/confirm.service';
import { MessageDialogService } from '#application/services/message-dialog.service';
import { SnackBarService } from '#application/services/snack-bar.service';
import { ServerApiError, ServerApiErrorCode } from '#infrastructure/api/error';
import { ServerApi } from '#infrastructure/api/server-api';
import { Target, messageFn } from '#utils/messages';
import { Injectable, inject } from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import { ClientCategory } from 'app/model/client-info-type/client-type';
import { ClientID, FirstName } from 'app/model/client/client';
import { ClientFactory } from 'app/model/client/client.factory';
import { NEVER, Observable, catchError, concatMap, filter, map, of, pipe, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientCommandV2ServiceImpl extends ClientCommandV2Service {
  private readonly clientFactory = inject(ClientFactory);
  private readonly serverApi = inject(ServerApi);
  private readonly snackBar = inject(SnackBarService);
  private readonly message = inject(MessageDialogService);
  private readonly confirm = inject(ConfirmService);

  addCorporation(inputs: AddCorporationInputs): Observable<AddClientOutput> {
    return this.clientFactory
      .create({
        type: 'CORPORATION',
        name: inputs.name,
        clientTypeID: inputs.clientTypeID,
        corporationName: inputs.corporationName,
        corporationNameKana: inputs.corporationNameKana,
        corporateNumber: inputs.corporateNumber,
        address: inputs.address,
        phoneNumber: inputs.phoneNumber?.value.length === 0 ? undefined : inputs.phoneNumber,
        established: inputs.established,
        numberOfEmployees: inputs.numberOfEmployees,
        initialCapital: inputs.initialCapital,
        fiscalYearEnd: inputs.fiscalYearEnd,
        website: inputs.website,
        items: inputs.items,
      })
      .pipe(
        tap(() =>
          this.snackBar.show(messageFn(Target.CLIENT, 'CREATE_SUCCESS').message)
        ),
        catchError(err => {
          return throwError(() => err);
        })
      );
  }

  addIndividual(inputs: AddIndividualInputs): Observable<AddClientOutput> {
    return this.clientFactory
      .create({
        type: 'INDIVIDUAL',
        name: inputs.name,
        clientTypeID: inputs.clientTypeID,
        firstName: inputs.firstName ?? new FirstName(''),
        lastName: inputs.lastName ?? new FirstName(''),
        firstNameKana: inputs.firstNameKana,
        lastNameKana: inputs.lastNameKana,
        items: inputs.items,
        address: inputs.address,
        phoneNumber: inputs.phoneNumber?.value.length === 0 ? undefined : inputs.phoneNumber,
        sns1: inputs.sns1,
        sns2: inputs.sns2
      })
      .pipe(
        tap(() =>
          this.snackBar.show(messageFn(Target.CLIENT, 'CREATE_SUCCESS').message)
        ),
        catchError(err => {
          return throwError(() => err);
        })
      );
  }

  update(inputs: UpdateClientInputs): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >> {
    const category = inputs.category;
    switch (category) {
      case ClientCategory.Corporation:
        return this.serverApi.clientApi.updateCorporation(
          inputs.id.value,
          {
            name: inputs.name.value,
            corporationName: inputs.corporationName.value,
            corporationNameKana: inputs.corporationNameKana?.value ?? '',
            corporateNumber: inputs.corporateNumber?.value,
            address: inputs.address?.value,
            phoneNumber: inputs.phoneNumber?.value.length === 0 ? undefined : inputs.phoneNumber?.value,
            established: inputs.established?.value,
            numberOfEmployees: inputs.numberOfEmployees?.value,
            initialCapital: inputs.initialCapital?.value,
            fiscalYearEnd: inputs.fiscalYearEnd?.value,
            website: inputs.website?.value,
            items: inputs.items.reduce((acc, item) => {
              acc[item.fieldId.value] = item.fieldValue.value;
              return acc;
            }, {} as Record<string, string>)
          }
        )
        .pipe(
          processUpdated(this.snackBar, this.message),
        );
      case ClientCategory.Individual:
        return this.serverApi.clientApi.updateIndividual(
          inputs.id.value,
          {
            name: inputs.name.value,
            firstName: inputs.firstName?.value ?? '',
            lastName: inputs.lastName?.value ?? '',
            firstNameKana: inputs.firstNameKana?.value ?? '',
            lastNameKana: inputs.lastNameKana?.value ?? '',
            address: inputs.address?.value,
            phoneNumber: inputs.phoneNumber?.value.length === 0 ? undefined : inputs.phoneNumber?.value,
            sns1: inputs.sns1?.value,
            sns2: inputs.sns2?.value,
            items: inputs.items.reduce((acc, item) => {
              acc[item.fieldId.value] = item.fieldValue.value;
              return acc;
            }, {} as Record<string, string>)
          })
          .pipe(
            processUpdated(this.snackBar, this.message),
          );
      default:
        const unexpected: never = category;
        throw Error(`${unexpected} is unexpected value`);
    }
  }

  delete(id: ClientID): Observable<Result<
    void,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.NotFound
  >> {
    return this.confirm.confirm({
      title: messageFn(Target.CLIENT, 'DELETE_CONFIRM').title,
      message: messageFn(Target.CLIENT, 'DELETE_CONFIRM').message
    })
    .pipe(
      filter(result => result.isOK()),
      concatMap(() => this.serverApi.clientApi.delete(id.value)),
      tap(() => this.snackBar.show(
        messageFn(Target.CLIENT, 'DELETE_SUCCESS').message
      )),
      map(() => Success(undefined)),
      catchError((e: ServerApiError) => {
        switch (e.code) {
          case ServerApiErrorCode.BadRequest:
            return of(Failure(GeneralFailure.BadRequest));
          case ServerApiErrorCode.NotFound:
            return of(Failure(GeneralFailure.NotFound));
        }
        this.message.notice({
          errorTarget: `${Target.CLIENT}の削除`,
        });
        return NEVER
      }),
    );
  }
}

function processUpdated(snackBar: SnackBarService, message: MessageDialogService) {
  return pipe(
    tap(() => snackBar.show(
      messageFn(Target.CLIENT, 'UPDATE_SUCCESS').message
    )),
    map(() => Success(undefined)),
    catchError((e: ServerApiError) => {
      switch (e.code) {
        case ServerApiErrorCode.BadRequest:
          return of(Failure(GeneralFailure.BadRequest));
        case ServerApiErrorCode.NotFound:
          return of(Failure(GeneralFailure.NotFound));
      }
      message.notice({
        errorTarget: `${Target.CLIENT}の更新`,
      });
      return NEVER
    }),
  );
}