import { DisplayName, UserID } from 'app/model/user/user';
import { CsvExportCategory } from '#presentation/pages/config/shared/csv-export/csv-export';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';

export type CsvExportStatus = 'WAITING' | 'IN_PROGRESS' | 'COMPLETED' | 'FAILED' | 'DELETED'

export interface CsvExportHistory {
  id: string;
  status: CsvExportStatus;
  category: string;
  typeName: string;
  userId: UserID;
  userName: DisplayName;
  size: number;
  exportStartAt: DateTime;
  exportEndAt: DateTime | null;
}

export interface CsvExportHistories {
  totalCount: number;
  results: CsvExportHistory[];
}

export abstract class CsvExportHistoriesQueryService {
  abstract getCsvExportHistories(
    category: CsvExportCategory,
    perPage: number,
    page: number
  ): Observable<CsvExportHistories>;
}
