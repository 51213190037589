import {Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';

export interface IssueOnetimeTokenRequest {
  organizationID: string,
}

export class OnetimeTokenApi {
  constructor(private readonly http: HttpClient) {}

  issue(request: IssueOnetimeTokenRequest): Observable<HttpResponse<void>> {
    return this.http.post<void>('/api/onetime-token', request, { observe: 'response' });
  }
}
