import {Observable} from "rxjs";
import {PayloadUrl, Webhook, WebhookCommands, WebhookID, WebhookName, WebhookObject} from "app/model/webhook/webhook";

export type AddWebhookInput = Readonly<{
  name: WebhookName,
  uri: PayloadUrl,
  object: WebhookObject,
  commands: WebhookCommands,
}>

export type UpdateWebhookInput = Readonly<{
  id: WebhookID,
  name: WebhookName,
  uri: PayloadUrl,
  object: WebhookObject,
  commands: WebhookCommands,
}>
export abstract class WebhookCommandService {
  abstract add(input: AddWebhookInput): Observable<void>;
  abstract update(input: UpdateWebhookInput): Observable<void>;
  abstract delete(id: WebhookID): Observable<void>;
}
