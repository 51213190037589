import { HttpClient } from "@angular/common/http";
import { GoalPeriodType } from "app/model/report/report-goal";
import { Observable } from "rxjs";

export type GetForSettingUpMeetingBoardResponse = {
  name: string,
  editability: 'PUBLIC' | 'PRIVATE',
  ownerID: string,
  filterConditionLiteral: string,
}

type UserData = {
  userID: string,
  userDisplayName: string,
};

type GoalData = {
  goalCategory: 'OPPORTUNITY_WON_COUNT' | 'ACTUAL_AMOUNT' | 'SALES_RECORDING_AMOUNT',
  goal: number,
  achievement: number,
  achievementRate: number,
};

type HeaderData = {
  displayUser: UserData,
  wonAmount: number | undefined,
  wonCount: number | undefined,
  lostAmount: number | undefined,
  lostCount: number | undefined,
  goals: GoalData[],
};

export type GetHeaderMeetingBoardRequest = {
  meetingBoardSettingID: string,
  duration: {
    goalPeriodType: GoalPeriodType,
    startYearMonth: string,
    months: number,
  },
};

export type GetHeaderMeetingBoardResponse = {
  headers: HeaderData[],
};

export class MeetingBoardApi {
  constructor(private readonly http: HttpClient) {}

  getForSettingUp(id: string): Observable<GetForSettingUpMeetingBoardResponse> {
    return this.http.get<GetForSettingUpMeetingBoardResponse>(`/api/meeting-board/${id}/for-setting-up`);
  }

  getHeader(request: GetHeaderMeetingBoardRequest): Observable<GetHeaderMeetingBoardResponse> {
    return this.http.post<GetHeaderMeetingBoardResponse>(`/api/meeting-board/header`, request);
  }
}
