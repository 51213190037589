import { Injectable } from '@angular/core';
import {ServerApi} from "#infrastructure/api/server-api";
import {MessageDialogService} from "#application/services/message-dialog.service";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {
  DeclineReason,
  DeclineReasonDescription,
  DeclineReasonID,
  DeclineReasonName
} from "app/model/decline-reason/decline-reason";
import {DeclineReasonList, DeclineReasonQueryService} from "#application/services/decline-reason-query.service";

@Injectable({
  providedIn: 'root'
})
export class DeclineReasonQueryServiceImpl extends DeclineReasonQueryService {

  constructor(
    private readonly serverApi: ServerApi,
    private readonly messageDialogService: MessageDialogService,
  ) {
    super();
  }

  list(): Observable<DeclineReasonList> {
    return this.serverApi.declineReasonApi.list()
      .pipe(
        map(res => res.map(v => ({
          id: new DeclineReasonID(v.id),
          name: new DeclineReasonName(v.name),
          description: new DeclineReasonDescription(v.description)
        }))),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '却下理由一覧の取得'
          });
          return throwError(err);
        })
      );
  }

  get(id: DeclineReasonID): Observable<DeclineReason> {
    return this.serverApi.declineReasonApi.get(id.value)
      .pipe(
        map(res => ({
          id: new DeclineReasonID(res.id),
          name: new DeclineReasonName(res.name),
          description: new DeclineReasonDescription(res.description)
        })),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '却下理由の取得'
          });
          return throwError(err);
        })
      );
  }
}
