import { LocalizeKey } from "#application/services/authorize.service";
import { ClientNurturingQueryService, NurturingListOfClient } from "#application/services/client-nurturing-query.service";
import { LocalizedNameService } from "#application/services/localized-name.service";
import { MessageDialogService } from "#application/services/message-dialog.service";
import { ClientID } from "app/model/client/client";
import { NurturingTypeID, NurturingTypeName } from "app/model/nurturing-type/nurturing-type";
import {
  NurturingID,
  NurturingMinutesDuration,
  NurturingStartDateTime,
  NurturingTitle
} from "app/model/nurturing/nurturing";
import { DisplayName, UserID } from "app/model/user/user";
import { ServerApi } from "#infrastructure/api/server-api";
import { Injectable, inject } from "@angular/core";
import { DateTime } from "luxon";
import { Observable, catchError, map, throwError } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class ClientNurturingQueryServiceImpl implements ClientNurturingQueryService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _messageDialogService = inject(MessageDialogService);
  private readonly _localizedNameService = inject(LocalizedNameService);

  list(clientID: ClientID): Observable<NurturingListOfClient> {
    const nurturingAlias = this._localizedNameService.get(LocalizeKey.NURTURING);
    return this._serverApi.clientNurturingApi.list(clientID.value)
      .pipe(
        map(res => res.map(data => ({
          id: new NurturingID(data.id),
          title: new NurturingTitle(data.title),
          status: data.status,
          duration: {
            startDateTime: new NurturingStartDateTime(DateTime.fromFormat(data.duration.startDateTime, 'yyyy-MM-dd HH:mm:ss').toJSDate()),
            minutes: new NurturingMinutesDuration(data.duration.minutes),
          },
          nurturingType: {
            id: new NurturingTypeID(data.nurturingType.id),
            name: new NurturingTypeName(data.nurturingType.name),
          },
          owner: {
            id: new UserID(data.owner.id),
            name: new DisplayName(data.owner.name),
          },
        }))),
        catchError(e => {
          this._messageDialogService.notice({
            errorTarget: `顧客ごとの${nurturingAlias}一覧の取得`
          });
          return throwError(() => e);
        })
      );
  }
}
