import {ContactRepository} from "app/model/contact/contact.repository";
import {ContactID, Contact} from "app/model/contact/contact";
import {Observable} from "rxjs";
import {ServerApi} from "#infrastructure/api/server-api";
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class ContactRepositoryImpl extends ContactRepository {
  constructor(
    private readonly serverApi: ServerApi,
  ) {
    super();
  }

  delete(id: ContactID): Observable<void> {
    return this.serverApi.contactApi.delete(id.value);
  }

  save(contact: Contact): Observable<void> {
    return this.serverApi.contactApi.update(contact.id.value, {
      department: contact.departmentName.value,
      role: contact.roleName.value,
      displayName: contact.displayName.value,
      firstName: contact.firstName.value,
      lastName: contact.lastName.value,
      firstNameKana: contact.firstNameKana.value,
      lastNameKana: contact.lastNameKana.value,
      sex: contact.sex.value,
      phoneNumbers: contact.phoneNumberInfoList.list.map(value => ({
        label: value.label.value,
        phoneNumber: value.phoneNumber.value
      })),
      emailAddresses: contact.emailInfoList.list.map(value => ({
        label: value.label.value,
        emailAddress: value.email.value
      })),
      note: contact.note.value,
    });
  }
}
