import {ServerApi} from '#infrastructure/api/server-api';
import {LostReasonRepository, UpdateLostReasonParam} from 'app/model/opportunity/lost-reason/lost-reason.repository';
import {Observable} from 'rxjs';
import {LostReasonID} from 'app/model/opportunity/lost-reason/lost-reason';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class LostReasonRepositoryImpl extends LostReasonRepository {
  constructor(
    private readonly serverApi: ServerApi
  ) {
    super();
  }

  save(param: UpdateLostReasonParam): Observable<void> {
    return this.serverApi.lostReasonApi.update(
      param.id.value,
      {
        name: param.name.value,
        description: param.description.value,
      }
    );
  }

  remove(id: LostReasonID): Observable<void> {
    return this.serverApi.lostReasonApi.delete(id.value);
  }

  saveOrders(ids: LostReasonID[]): Observable<void> {
    const request = {ids: ids.map(id => id.value)};
    return this.serverApi.lostReasonApi.sort(request);
  }
}
