/**
 * 活動タイプのリスト
 */
export class ActionTypes {
  constructor(
    public readonly list: ActionType[],
  ) {
  }

  public getDefault(): ActionType {
    return this.list[0];
  }

  public clone(): ActionTypes {
    return new ActionTypes(
      this.list.map(actionType => actionType)
    );
  }
}

/**
 * 活動タイプ
 */
export class ActionType {
  constructor(
    public readonly id: ActionTypeID,
    public readonly name: ActionTypeName,
    public readonly description: ActionTypeDescription,
  ) {
  }

  public clone(): ActionType {
    return new ActionType(
      this.id,
      this.name,
      this.description,
    )
  }

  public isUnregistered(): boolean {
    return this.id.value === "";
  }
}

/**
 * 活動タイプを一意に識別する値
 */
export class ActionTypeID {
  constructor(
    public readonly value: string,
  ) {
  }

  equals(obj: ActionTypeID | undefined) {
    return obj?.value === this.value
  }
}

/**
 * 活動タイプの名称
 */
export class ActionTypeName {
  static readonly MAX_LENGTH = 30;

  constructor(
    public readonly value: string,
  ) {
  }
}

/**
 * 活動タイプの説明
 */
export class ActionTypeDescription {
  static readonly MAX_LENGTH = 100;

  constructor(
    public readonly value: string,
  ) {
  }
}
