<sn-admin-container>
  <sn-feature-title-bar
    titleArea
    title="コンサルタント一覧"
    description="登録されているコンサルタントの一覧です。"
  ></sn-feature-title-bar>
  <div headerArea class="header">
    <button
      class="add-button"
      mat-raised-button
      color="primary"
      (click)="onAddClick()"
    >
      新しいコンサルタントを登録する
    </button>
  </div>

  <table contentsArea mat-table [dataSource]="tableData">
    <ng-container matColumnDef="loginID">
      <th mat-header-cell class="login-id" *matHeaderCellDef>ログインID</th>
      <td mat-cell *matCellDef="let element">
        <a (click)="onDetailClick(element.id.value)" class="consul-detail">
          {{ element.loginID.value }}
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="displayName">
      <th mat-header-cell class="display-name" *matHeaderCellDef>コンサルタント名</th>
      <td mat-cell *matCellDef="let element">
        {{ element.displayName.value }}
      </td>
    </ng-container>
    <ng-container matColumnDef="mailAddress">
      <th mat-header-cell *matHeaderCellDef>メールアドレス</th>
      <td mat-cell *matCellDef="let element">
        {{ element.mailAddress.value }}
      </td>
    </ng-container>
    <ng-container matColumnDef="assignedOrganization">
      <th mat-header-cell class="organization" *matHeaderCellDef>担当組織</th>
      <td mat-cell *matCellDef="let element"class="long-text">
        {{ element.assignedOrganization }}
      </td>
  </ng-container>
    <ng-container matColumnDef="lastLoginDateTime">
      <th mat-header-cell class="date-time" *matHeaderCellDef>最終ログイン日時</th>
      <td mat-cell *matCellDef="let element">
        {{ element.lastLoginDateTime }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</sn-admin-container>

