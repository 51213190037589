import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Result } from "app/lib/result/result";
import { GoalCategory, GoalPeriodType, MonthlyGoal } from "app/model/report/report-goal";
import { DisplayName, UserID } from "app/model/user/user";
import { DateTime } from "luxon";
import { Observable } from "rxjs";

export type FilterByUserGroupGoalSettingInput = {
  fiscalYear: DateTime,
  goalPeriodType: GoalPeriodType,
  userIDs?: UserID[],
};

export type FilterByPersonalGoalSettingInput = {
  fiscalYear: DateTime,
};

export type FilterByUserGroupGoalSettingOutput = {
  goalsMonthlyTotal: {
    monthlyGoals: MonthlyGoal[],
    totalGoal: number,
  },
  goalsPerUsers: {
    user: {
      id: UserID,
      name: DisplayName,
      isActive: boolean,
    },
    monthlyGoals: MonthlyGoal[],
  }[],
};

export type FilterByPersonalGoalSettingOutput = {
  goalSettings: MonthlyGoal[],
};

export abstract class GoalSettingQueryService {
  abstract filterByUserGroup(category: GoalCategory, input: FilterByUserGroupGoalSettingInput): Observable<Result<
    FilterByUserGroupGoalSettingOutput,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >>;

  abstract filterByPersonal(category: GoalCategory, input: FilterByPersonalGoalSettingInput): Observable<Result<
    FilterByPersonalGoalSettingOutput,
    | typeof GeneralFailure.BadRequest
    | typeof GeneralFailure.Unexpected
  >>;
}
