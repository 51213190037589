import {
  AssignedOrganizationList,
  AssignedOrganizationQueryService
} from "#application/services/assigned-organization-query.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ServerApi} from "#infrastructure/api/server-api";
import {map} from "rxjs/operators";
import {
  FiscalYearBegin,
  OrganizationCode,
  OrganizationID,
  OrganizationName
} from "app/model/organization/organization";

@Injectable( { providedIn: "root" })
export class AssignedOrganizationQueryServiceImpl extends AssignedOrganizationQueryService {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }
  list(): Observable<AssignedOrganizationList> {
    return this.serverApi.assignedOrganizationApi.list()
      .pipe(
        map(response => response.map(responseItem => ({
          organizationID: new OrganizationID(responseItem.organizationID),
          organizationCode: new OrganizationCode(responseItem.organizationCode),
          organizationName: new OrganizationName(responseItem.organizationName),
          fiscalYearBegin: new FiscalYearBegin(responseItem.fiscalYearBegin),
        })))
      );
  }
}
