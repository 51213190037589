import {
  BuiltinFieldQueryService,
  FormLayoutQueryService
} from "#application/services/form-layout-query.service";

export abstract class CorporationClientBuiltinFieldQueryService extends BuiltinFieldQueryService {
}

export abstract class CorporationClientFormLayoutQueryService extends FormLayoutQueryService {
}

export abstract class IndividualClientBuiltinFieldQueryService extends BuiltinFieldQueryService {
}

export abstract class IndividualClientFormLayoutQueryService extends FormLayoutQueryService {
}
