import {
  FollowUpActionListItem,
  DoneAssignedFollowUpActionList,
  FollowUpActionQueryService,
  GetFollowUpActionError,
  GetFollowUpActionNotFoundError,
  GetFollowUpActionUnexpectedError,
  UndoneAssignedFollowUpActionList,
  RequestedFollowUpActionListItem,
  UndoneRequestedFollowUpActionList,
  DoneRequestedFollowUpActionList
} from '#application/services/follow-up-action-query.service';
import {
  FollowUpAction,
  FollowUpActionDue,
  FollowUpActionID,
  FollowUpActionIsDone,
  FollowUpActionSummary
} from 'app/model/follow-up-action/follow-up-action';
import { OpportunityID, OpportunityName, } from 'app/model/opportunity/opportunity';
import { DisplayName, UserID } from 'app/model/user/user';
import { ServerApiError, ServerApiErrorCode } from '#infrastructure/api/error';
import { ServerApi } from '#infrastructure/api/server-api';
import {
  ListFollowUpActionData, ListRequestedFollowUpActionData
} from '#infrastructure/api/server-follow-up-action-api';
import { Injectable, inject } from '@angular/core';
import { GeneralFailure } from 'app/lib/general-failure/general-failure';
import { Failure, Result, Success } from 'app/lib/result/result';
import { DateTime } from 'luxon';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class FollowUpActionQueryServiceImpl implements FollowUpActionQueryService {
  private readonly serverApi = inject(ServerApi);

  getFollowUpAction(followUpActionID: FollowUpActionID): Observable<FollowUpAction | GetFollowUpActionError> {
    return this.serverApi.followUpActionApi
      .get(followUpActionID.value)
      .pipe(
        map(res => FollowUpAction(res)),
        catchError((err: ServerApiError) => {
          if (err.code === ServerApiErrorCode.NotFound) {
            return of(GetFollowUpActionNotFoundError);
          }
          return of(GetFollowUpActionUnexpectedError);
        }),
      );
  }

  listUndoneAssigned(): Observable<UndoneAssignedFollowUpActionList> {
    return this.serverApi.followUpActionApi
      .listUndoneAssigned()
      .pipe(map(res => res.map(v => toAssignedFollowUpAction(v))));
  }

  listDoneAssigned(pagination: {perPage: number, page: number}): Observable<DoneAssignedFollowUpActionList> {
    return this.serverApi.followUpActionApi
      .listDoneAssigned(pagination)
      .pipe(map(res => ({
        totalCount: res.totalCount,
        results: res.results.map(v => toAssignedFollowUpAction(v)),
      })));
  }

  listUndoneAssignedV2(): Observable<Result<
    UndoneAssignedFollowUpActionList,
    typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.followUpActionApi
      .listUndoneAssigned()
      .pipe(
        map(res => Success(res.map(v => toAssignedFollowUpAction(v)))),
        catchError(() => of(Failure(GeneralFailure.Unexpected))),
      );
  }

  listDoneAssignedV2(pagination: {perPage: number, page: number}): Observable<Result<
    DoneAssignedFollowUpActionList,
    typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.followUpActionApi
      .listDoneAssigned(pagination)
      .pipe(
        map(res => Success({
          totalCount: res.totalCount,
          results: res.results.map(v => toAssignedFollowUpAction(v)),
        })),
        catchError(() => of(Failure(GeneralFailure.Unexpected))),
      );
  }

  listUndoneRequested(): Observable<Result<
    UndoneRequestedFollowUpActionList,
    typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.followUpActionApi
      .listUndoneRequested()
      .pipe(
        map(res => Success(res.map(v => toRequestedFollowUpAction(v)))),
        catchError(() => of(Failure(GeneralFailure.Unexpected))),
      );
  }

  listDoneRequested(pagination: {perPage: number, page: number}): Observable<Result<
    DoneRequestedFollowUpActionList,
    typeof GeneralFailure.Unexpected
  >> {
    return this.serverApi.followUpActionApi
      .listDoneRequested(pagination)
      .pipe(
        map(res => Success({
          totalCount: res.totalCount,
          results: res.results.map(v => toRequestedFollowUpAction(v)),
        })),
        catchError(() => of(Failure(GeneralFailure.Unexpected))),
      );
  }
}

const toAssignedFollowUpAction = (res: ListFollowUpActionData): FollowUpActionListItem => {
  return {
    id: new FollowUpActionID(res.id),
    createdBy: {
      id: new UserID(res.createdBy.id),
      name: new DisplayName(res.createdBy.name),
    },
    opportunity: {
      id: new OpportunityID(res.opportunity.id),
      name: new OpportunityName(res.opportunity.name),
    },
    due: new FollowUpActionDue(DateTime.fromFormat(res.due, 'yyyy-MM-dd HH:mm:ss').toJSDate()),
    assignee: {
      id: new UserID(res.assignee.id),
      name: new DisplayName(res.assignee.name),
    },
    contentsSummary: new FollowUpActionSummary(res.contentsSummary),
    isDone: new FollowUpActionIsDone(res.isDone),
  }
}

const toRequestedFollowUpAction = (res: ListRequestedFollowUpActionData): RequestedFollowUpActionListItem => {
  return {
    id: new FollowUpActionID(res.id),
    opportunity: {
      id: new OpportunityID(res.opportunity.id),
      name: new OpportunityName(res.opportunity.name),
    },
    due: new FollowUpActionDue(DateTime.fromFormat(res.due, 'yyyy-MM-dd HH:mm:ss').toJSDate()),
    assignee: {
      id: new UserID(res.assignee.id),
      name: new DisplayName(res.assignee.name),
    },
    contentsSummary: new FollowUpActionSummary(res.contentsSummary),
    isDone: new FollowUpActionIsDone(res.isDone),
  }
}
