import { HttpClient } from "@angular/common/http";
import { GoalCategory, GoalPeriodType } from "app/model/report/report-goal";
import { Observable } from "rxjs";

export type GoalGoalSetting = {
  goalPeriodType: GoalPeriodType,
  goals: {
    [date: string]: number,
  },
};

export type UserGoalSetting = {
  id: string,
  name: string,
  isActive: boolean,
};

export type PeriodGoalGoalSetting = {
  [date: string]: number,
};

export type GoalsPeriodTotalGoalSetting = {
  total: number,
  periodGoals: PeriodGoalGoalSetting,
};

export type GoalPerUserGoalSetting = {
  user: UserGoalSetting,
  periodGoals: PeriodGoalGoalSetting,
};

export type filterByUserGroupGoalSettingRequest = {
  fiscalYear: string,
  goalPeriodType: GoalPeriodType,
  userIDs?: string[],
};

export type filterByPersonalGoalSettingRequest = {
  fiscalYear: string,
};

export type SaveGoalSettingRequest = {
  fiscalYear: string,
  goalsPerPeriodType: GoalGoalSetting[],
};

export type FilterByUserGroupGoalSettingResponse = {
  goalsPeriodTotal: GoalsPeriodTotalGoalSetting,
  goalsPerUser: GoalPerUserGoalSetting[],
};

export type FilterByPersonalGoalSettingResponse = {
  goalsPerPeriodType: GoalGoalSetting[],
};

export class GoalSettingApi {
  constructor(private readonly http: HttpClient) {}

  filterByUserGroup(category: GoalCategory, request: filterByUserGroupGoalSettingRequest): Observable<FilterByUserGroupGoalSettingResponse> {
    return this.http.post<FilterByUserGroupGoalSettingResponse>(`/api/goal-settings/user-group/${ category }/filter`, request);
  }

  filterByPersonal(category: GoalCategory, request: filterByPersonalGoalSettingRequest): Observable<FilterByPersonalGoalSettingResponse> {
    return this.http.post<FilterByPersonalGoalSettingResponse>(`/api/my-goal-settings/${ category }/filter`, request);
  }

  save(category: GoalCategory, request: SaveGoalSettingRequest): Observable<void> {
    return this.http.post<void>(`/api/my-goal-settings/${ category }`, request);
  }
}
