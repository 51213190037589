import {
  ClientCategory,
  ClientTypeDescription,
  ClientTypeID,
  ClientTypeName
} from 'app/model/client-info-type/client-type';
import {FormLayoutId, FormLayoutName} from 'app/model/form-layout/form-layout';
import {Observable} from 'rxjs';

export class ClientTypeList {
  constructor(public readonly values: ClientTypeListItem[]) {
  }

  isEmpty(): boolean {
    return this.values.length === 0;
  }

  copy(): ClientTypeList {
    return new ClientTypeList(this.values.slice(0, this.values.length));
  }

  findItemByID(id: ClientTypeID): (ClientTypeListItem | undefined) {
    return this.values.find(item => item.id.value === id.value);
  }
}

export class ClientTypeListItem {
  constructor(
    public readonly id: ClientTypeID,
    public readonly category: ClientCategory,
    public readonly name: ClientTypeName,
    public readonly description: ClientTypeDescription,
    public readonly formLayoutId?: FormLayoutId,
    public readonly formLayoutName?: FormLayoutName,
  ) {
  }
}

export interface ClientTypeDetail {
  id: ClientTypeID,
  name: ClientTypeName,
  description: ClientTypeDescription,
  formLayoutID?: FormLayoutId,
}

export abstract class ClientTypeQueryService {

  abstract get(id: ClientTypeID): Observable<ClientTypeDetail>

  abstract listClientType(): Observable<ClientTypeList>;
}
