import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiFilterRequest, ApiPaginationRequest } from "./server-filter-api";

export type FilterOpportunityPerPhaseResponse = {
  totalCount: number,
  results: FilterOpportunityPerPhaseResult[],
};

export type FilterOpportunityPerPhaseResult = {
  opportunityID: string,
  opportunityNumber: number,
  opportunityName: string,
  expectedAmount?: number,
  latestActionDate?: string,
};

export class OpportunityPerPhaseApi {
  constructor(private readonly http: HttpClient) {}

  filter(request: ApiPaginationRequest & ApiFilterRequest): Observable<FilterOpportunityPerPhaseResponse> {
    return this.http.post<FilterOpportunityPerPhaseResponse>(`/api/opportunities-per-phase/filter`, request);
  }
}
