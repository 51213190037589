import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import { ApiFormLayoutCategory } from './server-form-layout-api';
import { SectionFormItemData, BuiltinFormItemData } from './server-form-items-api'

export type GetDefaultFormItemsResponse = GetDefaultFormItemsResponseData;

export interface GetDefaultFormItemsResponseData {
  items: (SectionFormItemData | BuiltinFormItemData)[]
}

export class DefaultFormItemsApi {
  constructor(
    private readonly http: HttpClient
  ) {}

  get(category: ApiFormLayoutCategory): Observable<GetDefaultFormItemsResponse> {
    return this.http.get<GetDefaultFormItemsResponse>(`/api/default-form-items/${category}`);
  }
}
