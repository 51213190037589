import { UserID } from "app/model/user/user";

/**
 * フォローの操作権限
 */
export type FollowUpActionAuthority = Readonly<{
  updatable: boolean,
  deletable: boolean,
  enableDone: boolean,
  enableUndone: boolean,
}>

/**
 * 操作権限の判断材料となるフォローに紐づくユーザーの情報
 */
export type UsersOfFollowUpAction = Readonly<{
  creatorID: UserID,
  assigneeID: UserID,
}>

export abstract class FollowUpActionAuthorityService {
  abstract getAuthorityBy(usersOfFollow: UsersOfFollowUpAction): FollowUpActionAuthority;
}
