import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

interface SearchOpportunityContextLabelListRequestData {
  perPage: number,
  page: number,
  filter?: {
    labelName: {
      type: 'contains',
      value: string,
    }
  }
}
export type SearchOpportunityContextLabelListRequest
  = SearchOpportunityContextLabelListRequestData;

interface SearchOpportunityContextLabelListResponseData {
  totalCount: number,
  results: string[],
}
export type SearchOpportunityContextLabelListResponse
  = SearchOpportunityContextLabelListResponseData;

export class OpportunityContextLabelApi {
  constructor(private readonly http: HttpClient) {}

  search(request: SearchOpportunityContextLabelListRequest): Observable<SearchOpportunityContextLabelListResponse> {
    return this.http.post<SearchOpportunityContextLabelListResponse>(`/api/opportunity-context-label-list/search`, request);
  }
}
