import { HttpClient } from "@angular/common/http"
import { Observable } from "rxjs";

export type CreateUserGroupRequest = {
  name: string,
  groups: string[],
  users: string[],
}

export type UpdateUserGroupRequest = CreateUserGroupRequest

export type ListUserGroupRequest = {
  perPage: number,
  page: number,
}

export type ListItemUserGroupResponse = {
  id: string,
  name: string,
  groups: {
    id: string,
    name: string,
  }[],
  users: {
    id: string,
    name: string,
  }[],
  userCount: number,
  creator: {
    id: string,
    name: string,
  },
  updatedAt: string,
}

export type ListUserGroupResponse = {
  totalCount: number,
  results: ListItemUserGroupResponse[]
}

export type GetUserGroupResponse = {
  id: string,
  name: string,
  groups: {
    id: string,
    name: string,
  }[],
  users: {
    id: string,
    name: string,
  }[],
}

export type GetAllItemUserGroupResponse = Pick<
  ListItemUserGroupResponse,
  'id' | 'name' | 'userCount'
>

export type GetAllUserGroupResponse = GetAllItemUserGroupResponse[]

export class UserGroupApi {
  constructor(private readonly http: HttpClient) {}

  create(request: CreateUserGroupRequest): Observable<void> {
    return this.http.post<void>('/api/user-groups', request);
  }

  list(request: ListUserGroupRequest): Observable<ListUserGroupResponse> {
    return this.http.post<ListUserGroupResponse>('/api/user-groups/list', request);
  }

  get(id: string): Observable<GetUserGroupResponse> {
    return this.http.get<GetUserGroupResponse>(`/api/user-groups/${id}`);
  }

  update(id: string, request: UpdateUserGroupRequest): Observable<void> {
    return this.http.put<void>(`/api/user-groups/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/user-groups/${id}`);
  }

  getAll(): Observable<GetAllUserGroupResponse> {
    return this.http.get<GetAllUserGroupResponse>('/api/user-groups/all');
  }
}
