import { ServerApi } from '#infrastructure/api/server-api';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { CreateFormLayoutParam, FormLayoutFactory } from 'app/model/form-layout/form-layout.factory';
import { CustomFieldItem, FormLayoutItemType, FormLayoutWidth, SectionTitleItem } from 'app/model/form-layout/form-layout';
import { BuiltinFieldItemData, CustomFieldItemData, SectionTitleItemData } from '#infrastructure/api/server-form-layout-api';

@Injectable({ providedIn: 'root' })
export class FormLayoutFactoryImpl extends FormLayoutFactory {
  constructor(private readonly serverApi: ServerApi) {
    super();
  }

  create(param: CreateFormLayoutParam): Observable<void> {
    return this.serverApi.formLayoutApi.create(param.category, {
      name: param.name.value,
      items: param.items.map(item => {
        if (item instanceof SectionTitleItem) {
          const section: SectionTitleItemData = {
            type: FormLayoutItemType.SECTION_TITLE,
            width: FormLayoutWidth.FULL,
            title: item.title.value
          }
          return section;
        } else if (item instanceof CustomFieldItem) {
          const custom: CustomFieldItemData = {
            type: FormLayoutItemType.CUSTOM_FIELD,
            width: item.width,
            customFieldID: item.fieldId.value
          }
          return custom;
        } else {
          const builtin: BuiltinFieldItemData = {
            type: FormLayoutItemType.BUILTIN_FIELD,
            width: item.width,
            builtinFieldID: item.builtinFieldId
          }
          return builtin;
        }
      })
    });
  }
}
