
/**
 * ページのパス
 */
export const Page = {
  SignIn: '/sign-in',
  SignInAsConsultant: '/sign-in-as-consultant',
  OpportunityBoard: '/main/opportunity-board',
  OpportunityBoardActionAdd: '/main/opportunity-board/action/add',
  MeetingBoard: '/main/meeting-board/top',
  MeetingBoardList: '/main/meeting-board/list',
  MeetingBoardAdd: '/main/meeting-board/add',
  MeetingBoardEdit: '/main/meeting-board/edit',
  Coaching: '/main/coaching',
  CoachingAddAction: '/main/coaching/add-action',
  CoachingReportAction: '/main/coaching/report-action',
  CoachingGet: '/main/coaching/get-coaching',
  CoachingWinOpportunity: '/main/coaching/win-opportunity',
  CoachingLoseOpportunity: '/main/coaching/lose-opportunity',
  CoachingAddOpportunity: '/main/coaching/add-opportunity',
  CoachingAddClient: '/main/coaching/add-client',
  Action: '/main/action-management',
  ActionAdd: '/main/action-management/action/add',
  ActionDetail: '/main/action-management/action/detail',
  ActionEdit: '/main/action-management/action/edit',
  ActionReport: '/main/action-management/action/report',
  ActionReportResult: '/main/action-management/action/report-result',
  EventAdd: '/main/action-management/event/add',
  EventDetail: '/main/action-management/event/detail',
  EventEdit: '/main/action-management/event/edit',
  EventCopy: '/main/action-management/event/copy',
  NurturingAdd: '/main/action-management/nurturing/add',
  NurturingDetail: '/main/action-management/nurturing/detail',
  NurturingEdit: '/main/action-management/nurturing/edit',
  NurturingReport: '/main/action-management/nurturing/report',
  NurturingReportResult: '/main/action-management/nurturing/report-result',
  Opportunity: '/main/opportunity-management',
  OpportunitySearchFilter: '/main/opportunity-management/search-filter',
  OpportunitySearchFilterList: '/main/opportunity-management/search-filter/list',
  OpportunityAdd: '/main/opportunity-management/add',
  OpportunityDetail: '/main/opportunity-management/detail',
  OpportunityActionAdd: '/main/opportunity-management/action/add',
  OpportunityActionDetail: '/main/opportunity-management/action/detail',
  OpportunityActionEdit: '/main/opportunity-management/action/edit',
  OpportunityActionReport: '/main/opportunity-management/action/report',
  OpportunityActionReportResult: '/main/opportunity-management/action/report-result',
  OpportunityFollowUpActionAdd: '/main/opportunity-management/follow-up-action/add',
  OpportunityFollowUpActionDetail: '/main/opportunity-management/follow-up-action/detail',
  OpportunityFollowUpActionEdit: '/main/opportunity-management/follow-up-action/edit',
  OpportunityGroupSearchFilterList: '/main/opportunity-group-management/search-filter/list',
  OpportunityGroupOpportunitySearchFilterList: '/main/opportunity-group-management/opportunity-search-filter/list',
  OpportunityGroupAdd: '/main/opportunity-group-management/add',
  OpportunityGroupEdit: '/main/opportunity-group-management/edit',
  Client: '/main/client-management',
  ClientSearchFilter: '/main/client-management/search-filter',
  ClientSearchFilterList: '/main/client-management/search-filter/list',
  ClientAdd: '/main/client-management/add',
  ClientDetail: '/main/client-management/detail',
  ClientNurturingAdd: '/main/client-management/nurturing/add',
  ClientNurturingDetail: '/main/client-management/nurturing/detail',
  ClientNurturingEdit: '/main/client-management/nurturing/edit',
  ClientNurturingReport: '/main/client-management/nurturing/report',
  ClientNurturingReportResult: '/main/client-management/nurturing/report-result',
  Product: '/main/product-management',
  ProductSearchFilter: '/main/product-management/search-filter',
  ProductSearchFilterList: '/main/product-management/search-filter/list',
  ProductAdd: '/main/product-management/add',
  ProductDetail: '/main/product-management/detail',
  Contact: '/main/contact-management',
  ContactAdd: '/main/contact-management/add',
  ContactDetail: '/main/contact-management/detail',
  ProposalDetail: '/main/proposal-management/detail',
  OpportunityGroup: '/main/opportunity-group-management',
  MyDataGoal: '/my-data/goal',
  MyDataGoalSetting: '/my-data/goal/setting',
  MyDataGoalOpportunity: '/my-data/goal/opportunity',
  MyDataActivity: '/my-data/activity',
  MyDataCvr: '/my-data/cvr',
  MyDataOpportunity: '/my-data/opportunity',
  MyDataOpportunityOpportunityList: '/my-data/opportunity/opportunity',
  ReportsGoal: '/reports/goal',
  ReportsGoalPerUser: '/reports/goal/per-user',
  ReportsGoalGoalConfirmation: '/reports/goal/goal-confirmation',
  ReportsGoalOpportunity: '/reports/goal/opportunity',
  ReportsActivity: '/reports/activity',
  ReportsActivityPerUser: '/reports/activity/per-user',
  ReportsCvr: '/reports/cvr',
  ReportsOpportunity: '/reports/opportunity',
  ReportsOpportunityPerUser: '/reports/opportunity/per-user',
  ReportsOpportunityOpportunityList: '/reports/opportunity/opportunity',
  ConfigUser: '/config/user/list',
  ConfigUserAdd: '/config/user/add',
  ConfigUserDetail: '/config/user/detail',
  ConfigUserGroup: '/config/user-group/list',
  ConfigUserGroupAdd: '/config/user-group/add',
  ConfigUserGroupDetail: '/config/user-group/detail',
  ConfigSalesPhaseList: '/config/sales-phase/list',
  ConfigSalesPhaseDetail: '/config/sales-phase/detail',
  ConfigSalesPhaseEdit: '/config/sales-phase/edit',
  ConfigSalesPhaseFix: '/config/sales-phase/fix',
  ConfigOpportunity: '/config/opportunity',
  ConfigOpportunityGroup: '/config/opportunity-group',
  ConfigAction: '/config/action',
  ConfigFollowUpAction: '/config/follow-up-action',
  ConfigClient: '/config/client',
  ConfigProduct: '/config/product',
  ConfigProposal: '/config/proposal',
  ConfigRole: '/config/role',
  ConfigRoleAdd: '/config/role/add',
  ConfigRoleDetail: '/config/role/detail',
  ConfigNurturing: '/config/nurturing',
  ConfigLocalize: '/config/localize',
  LinkageWebhook: '/linkage/webhook',
  LinkageWebhookAdd: '/linkage/webhook/add',
  LinkageWebhookDetail: '/linkage/webhook/detail',
  LinkageRestAuthToken: '/linkage/rest-auth-token',
  InsightOpportunityContext: '/insight/opportunity-context',
  InsightOpportunityContextAdd: '/insight/opportunity-context/add',
  InsightOpportunityContextDetail: '/insight/opportunity-context/detail',
  InsightManagement: '/insight/management',
  InsightManagementAdd: '/insight/management/add',
  InsightManagementDetail: '/insight/management/detail',
  InsightHint: '/insight/hint',
  InsightHintAdd: '/insight/hint/add',
  InsightHintDetail: '/insight/hint/detail',
  InsightContextSettingOpportunityType: '/insight/context-setting/opportunity-type',
  InsightIncorporation: '/insight/incorporation',
  ConsultingAssignedOrganizationList: '/consulting/assigned-organization-list',
  ConsultingCommonOpportunityContext: '/consulting/common-opportunity-context',
  ConsultingCommonOpportunityContextAdd: '/consulting/common-opportunity-context/add',
  ConsultingCommonOpportunityContextDetail: '/consulting/common-opportunity-context/detail',
  ConsultingCommonInsight: '/consulting/common-insight',
  ConsultingCommonInsightAdd: '/consulting/common-insight/add',
  ConsultingCommonInsightDetail: '/consulting/common-insight/detail',
  ConsultingCommonHint: '/consulting/common-hint',
  ConsultingCommonHintAdd: '/consulting/common-hint/add',
  ConsultingCommonHintDetail: '/consulting/common-hint/detail',
  AdminOrganization: '/admin/organization',
  AdminOrganizationAdd: '/admin/organization/add',
  AdminOrganizationDetail: '/admin/organization/detail',
  AdminConsultant: '/admin/consultant',
  AdminConsultantAdd: '/admin/consultant/add',
  AdminConsultantDetail: '/admin/consultant/detail',
  ErrorNotFound: '/error/not-found',
  CalendarSetting: '/user-menu/calendar-setting',
} as const;

export type Page = typeof Page[keyof typeof Page];

/**
 * @property {string} requiredParam - '.../detail/:id'等のページ遷移に必須のパラメータ
 * @property {object} optionalParams - クエリパラメータ等
 */
export type PathOptions = {
  requiredParam?: string,
  optionalParams?: {
    [key: string]: string
  }
}

/**
 * ページ遷移サービス
 */
export abstract class PageNavigateService {
  /**
   * ページ遷移する
   * @param page ページパス
   * @returns 遷移成功したか
   */
  abstract navigate(page: Page, options?: PathOptions): Promise<boolean>;

  /** 別タブでページを開く */
  abstract openInAnotherTab(page: Page, options?: PathOptions): void;
}
