import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ServerApi} from '#infrastructure/api/server-api';
import { ContactHistoryTemplateRepository } from 'app/model/contact-history-template/contact-history-template.repository';
import { ContactHistoryTemplate } from 'app/model/contact-history-template/contact-history-template';

@Injectable({providedIn: 'root'})
export class ContactHistoryTemplateRepositoryImpl implements ContactHistoryTemplateRepository {
  constructor(
    private readonly serverApi: ServerApi
  ) {
  }

  save(template: ContactHistoryTemplate): Observable<void> {
    return this.serverApi.contactHistoryTemplateApi.update(template.id.value, {
      name: template.name.value,
      content: template.content.value,
    });
  }

  delete(template: ContactHistoryTemplate): Observable<void> {
    return this.serverApi.contactHistoryTemplateApi.delete(template.id.value);
  }

  saveOrders(templates: ContactHistoryTemplate[]): Observable<void> {
    return this.serverApi.contactHistoryTemplateApi.sortOrders({
      ids: templates.map(template => template.id.value)
    });
  }
}
