import { OpportunityTypeID, OpportunityTypeName } from "app/model/opportunity-type/opportunity-type";
import { UserGroupID } from "app/model/user-group/user-group";
import { DisplayName, UserID } from "app/model/user/user";
import { DateTime } from "luxon";
import { Observable } from "rxjs";

type OpportunityStatusType = 'WON_AND_OPEN' | 'WON' | 'OPEN' | 'LOST';

type AsDaily = {
  type: 'DAILY',
  startDate: DateTime,
  days: number,
};

type AsMonthly = {
  type: 'MONTHLY',
  startYearMonth: DateTime,
  months: number,
};

type AsUserGroup = {
  type: 'USER_GROUP',
  userGroupID: UserGroupID,
};

type AsAllUsers = {
  type: 'ALL_USERS',
};

type User = {
  id: UserID,
  name: DisplayName,
  isActive: boolean,
};

type Data = {
  date: DateTime,
  value: number,
};

type OpportunityStatusInAmount = {
  name: OpportunityStatusType,
  amounts: Data[],
  totalAmount: number,
}

type OpportunityStatusInCount = {
  name: OpportunityStatusType,
  counts: Data[],
  totalCount: number,
}

export type AmountReportOpportunityOutputs = {
  opportunityTypes: {
    id: OpportunityTypeID,
    name: OpportunityTypeName,
    opportunityStatuses: OpportunityStatusInAmount[],
  }[],
  opportunityTypeTotal: {
    opportunityStatuses: OpportunityStatusInAmount[],
  }
}

export type CountReportOpportunityOutputs = {
  opportunityTypes: {
    id: OpportunityTypeID,
    name: OpportunityTypeName,
    opportunityStatuses: OpportunityStatusInCount[],
  }[],
  opportunityTypeTotal: {
    opportunityStatuses: OpportunityStatusInCount[],
  }
}

export type AmountPerUserReportOpportunityOutputs = {
  user: User,
  amount: AmountReportOpportunityOutputs,
};

export type CountPerUserReportOpportunityOutputs = {
  user: User,
  count: CountReportOpportunityOutputs,
};

export type GetReportOpportunityInputs = {
  duration: AsDaily | AsMonthly,
  opportunityTypeIDs: OpportunityTypeID[],
  assignee: AsUserGroup | AsAllUsers,
  status: Exclude<OpportunityStatusType, 'WON_AND_OPEN'>[],
};

export type GetReportOpportunityOutputs = {
  amount: AmountReportOpportunityOutputs,
  amountsPerUser: AmountPerUserReportOpportunityOutputs[],
  count: CountReportOpportunityOutputs,
  countsPerUser: CountPerUserReportOpportunityOutputs[],
};

export type GetPerUserReportOpportunityInputs = {
  duration: AsDaily | AsMonthly,
  opportunityTypeIDs: OpportunityTypeID[],
  userID: UserID,
  status: Exclude<OpportunityStatusType, 'WON_AND_OPEN'>[],
};

export type GetPerUserReportOpportunityOutputs = {
  amount: AmountReportOpportunityOutputs,
  count: CountReportOpportunityOutputs,
};

export type GetPersonalReportOpportunityInputs = Omit<GetPerUserReportOpportunityInputs, 'userID'>;

export type GetPersonalReportOpportunityOutputs = GetPerUserReportOpportunityOutputs;

export type GetBySalesRecordReportOpportunityOutputs = Omit<GetReportOpportunityOutputs, 'count' | 'countsPerUser'>;

export type GetPerUserBySalesRecordReportOpportunityOutputs = Omit<GetPerUserReportOpportunityOutputs, 'count'>;

export type GetPersonalBySalesRecordReportOpportunityOutputs = Omit<GetPersonalReportOpportunityOutputs, 'count'>;

export abstract class ReportOpportunityV2QueryService {
  /** 受注単位 */
  abstract get(input: GetReportOpportunityInputs): Observable<GetReportOpportunityOutputs>;
  abstract getPerUser(input: GetPerUserReportOpportunityInputs): Observable<GetPerUserReportOpportunityOutputs>;
  abstract getPersonal(input: GetPersonalReportOpportunityInputs): Observable<GetPersonalReportOpportunityOutputs>;

  /** 売上単位 */
  abstract getBySalesRecord(input: GetReportOpportunityInputs): Observable<GetBySalesRecordReportOpportunityOutputs>;
  abstract getPerUserBySalesRecord(input: GetPerUserReportOpportunityInputs): Observable<GetPerUserBySalesRecordReportOpportunityOutputs>;
  abstract getPersonalBySalesRecord(input: GetPersonalReportOpportunityInputs): Observable<GetPersonalBySalesRecordReportOpportunityOutputs>;
}
