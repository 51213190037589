import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

export type CreateDeclineReasonRequest = {
  name: string,
  description: string,
}

export type UpdateDeclineReasonRequest = {
  name: string,
  description: string,
}

export type SortDeclineReasonRequest = {
  ids: string[],
}

type DeclineReasonData = {
  id: string,
  name: string,
  description: string,
}

export type ListDeclineReasonResponse =  DeclineReasonData[];

export type GetDeclineReasonResponse = DeclineReasonData;

export class DeclineReasonApi {

  constructor(private readonly http: HttpClient) {}

  create(request: CreateDeclineReasonRequest): Observable<void> {
    return this.http.post<void>(`/api/decline-reasons`, request);
  }

  update(id: string, request: UpdateDeclineReasonRequest): Observable<void> {
    return this.http.put<void>(`/api/decline-reasons/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/decline-reasons/${id}`);
  }

  sort(request: SortDeclineReasonRequest): Observable<void> {
    return this.http.patch<void>(`/api/decline-reasons/sort`, request);
  }

  list(): Observable<ListDeclineReasonResponse> {
    return this.http.get<ListDeclineReasonResponse>(`/api/decline-reasons/list`);
  }

  get(id: string): Observable<GetDeclineReasonResponse> {
    return this.http.get<GetDeclineReasonResponse>(`/api/decline-reasons/${id}`);
  }

}
