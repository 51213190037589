import { UserList } from "#application/services/user-query.service";
import { DateTime } from "luxon";
import { MembersFilter } from "./members-filter";

export const SCHEDULE_PC_FILTER_KEY_NAME = 'SCHEDULE_PC_FILTER';

export const SchedulePcDisplayType = {
  INDIVIDUAL: 'INDIVIDUAL',
  GROUP: 'GROUP',
} as const;
export type SchedulePcDisplayType = typeof SchedulePcDisplayType[keyof typeof SchedulePcDisplayType];

export const TimeframeType = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
} as const;
export type TimeframeType = typeof TimeframeType[keyof typeof TimeframeType]

export type SchedulePcFilterState = Readonly<{
  scheduleType: SchedulePcDisplayType,
  individual?: {
    timeframeType: TimeframeType,
  },
  group?: {
    timeframeType: TimeframeType,
  },
  targetDate: DateTime,
  targetUser?: {
    id: string,
    name: string,
  },
  targetUsersAndGroups: MembersFilter,
  allUsers: UserList,
}>;
