import { UserGroupID } from "../user-group/user-group";
import { DisplayName, LoginID, UserID } from "../user/user";

type UserOption = {
  selected: boolean,
  id: UserID,
  loginID: LoginID,
  name: DisplayName,
}

export const MembersFilterType = {
  USER: 'USER',
  GROUP: 'GROUP',
} as const;
export type MembersFilterType = typeof MembersFilterType[keyof typeof MembersFilterType];

export type MembersFilter = {
  users: ReadonlyArray<UserOption>,
  userGroupIDs: ReadonlyArray<UserGroupID>,
  membersType: MembersFilterType,
}