import {
  ClientRelatedWithContactList,
  ContactDetail,
  ContactList,
  ContactListBySearch,
  ContactListItem,
  ContactQueryService,
  SearchContactInputs
} from "#application/services/contact-query.service";
import {ServerApi} from "#infrastructure/api/server-api";
import {FilterRequestConverter, FilterService} from "#infrastructure/application/filter.service";
import {Injectable, inject} from '@angular/core';
import {ClientCategory, ClientTypeName} from 'app/model/client-info-type/client-type';
import {ClientID, ClientName, ClientNumber} from "app/model/client/client";
import {
  ContactID,
  ContactNumber,
  ContactRoleName,
  DepartmentName,
  Email,
  EmailInfo,
  EmailInfoList,
  EmailLabel,
  ContactDisplayName,
  FirstName,
  FirstNameKana,
  LastName,
  LastNameKana,
  Note,
  PhoneNumber,
  PhoneNumberInfo,
  PhoneNumberInfoList,
  PhoneNumberLabel,
  Sex, CreatedBy, UpdatedBy
} from "app/model/contact/contact";
import {Observable, mergeMap} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ContactQueryServiceImpl extends ContactQueryService {
  private readonly _serverApi = inject(ServerApi);
  private readonly _filterService = inject(FilterService);

  listBy(clientID: ClientID): Observable<ContactList> {
    return this._serverApi.contactApi.listBy(clientID.value).pipe(
      map(response => response.map(data => new ContactListItem(
        new ContactID(data.id),
        new ContactNumber(data.contactNumber),
        new DepartmentName(data.department),
        new ContactRoleName(data.role),
        new ContactDisplayName(data.displayName),
        new LastName(data.lastName),
        new FirstName(data.firstName),
        new LastNameKana(data.lastNameKana),
        new FirstNameKana(data.firstNameKana),
        new Sex(data.sex),
        new PhoneNumberInfoList(data.phoneNumbers.map(phoneNumber => new PhoneNumberInfo(
          new PhoneNumberLabel(phoneNumber.label),
          new PhoneNumber(phoneNumber.phoneNumber),
        ))),
        new EmailInfoList(data.emailAddresses.map(emailAddress => new EmailInfo(
          new EmailLabel(emailAddress.label),
          new Email(emailAddress.emailAddress)
        ))),
        new Note(data.note),
      ))),
      map(list => new ContactList(list))
    );
  }

  get(id: ContactID): Observable<ContactDetail> {
    return this._serverApi.contactApi.get(id.value).pipe(
      map(data => (
        {
          id: new ContactID(data.id),
          client: data.client === undefined
            ? undefined
            : {
              id: new ClientID(data.client.id),
              name: new ClientName(data.client.name),
              number: new ClientNumber(data.client.number),
              category: data.client.category === 'CORPORATION'
                ? ClientCategory.Corporation
                : ClientCategory.Individual,
            },
          contactNumber: new ContactNumber(data.contactNumber),
          departmentName: new DepartmentName(data.department),
          roleName: new ContactRoleName(data.role),
          displayName: new ContactDisplayName(data.displayName),
          lastName: new LastName(data.lastName),
          firstName: new FirstName(data.firstName),
          lastNameKana: new LastNameKana(data.lastNameKana),
          firstNameKana: new FirstNameKana(data.firstNameKana),
          sex: new Sex(data.sex),
          phoneNumberInfoList: new PhoneNumberInfoList(data.phoneNumbers.map(e => new PhoneNumberInfo(
            new PhoneNumberLabel(e.label),
            new PhoneNumber(e.phoneNumber)
          ))),
          emailInfoList: new EmailInfoList(data.emailAddresses.map(e => new EmailInfo(
            new EmailLabel(e.label),
            new Email(e.emailAddress)
          ))),
          note: new Note(data.note),
          createdBy: data.createdBy ? new CreatedBy(data.createdBy) : undefined,
          updatedBy: data.updatedBy ? new UpdatedBy(data.updatedBy) : undefined,
        }
      ))
    );
  }

  findBy(inputs: SearchContactInputs): Observable<ContactListBySearch> {
    return this._filterService.getSchema('contacts')
      .pipe(
        mergeMap(schema => {
          const filterRequest = FilterRequestConverter.toFilterRequest(schema, inputs);
          return this._serverApi.contactApi.filter(filterRequest);
        }),
        map(data => ({
          totalCount: data.totalCount,
          results: data.results.map(v => ({
            id: new ContactID(v.id),
            client: v.client === undefined
              ? undefined
              : {
                id: new ClientID(v.client.id),
                name: new ClientName(v.client.name),
                category: v.client.category === 'CORPORATION'
                  ? ClientCategory.Corporation
                  : ClientCategory.Individual,
              },
            relatedClientCount: v.relatedClientCount,
            contactNumber: new ContactNumber(v.contactNumber),
            departmentName: new DepartmentName(v.department),
            roleName: new ContactRoleName(v.role),
            lastName: new LastName(v.lastName),
            firstName: new FirstName(v.firstName),
            lastNameKana: new LastNameKana(v.lastNameKana),
            firstNameKana: new FirstNameKana(v.firstNameKana),
            displayName: new ContactDisplayName(v.displayName),
            sex: new Sex(v.sex),
            phoneNumberInfoList: new PhoneNumberInfoList(v.phoneNumbers.map(num => {
              return new PhoneNumberInfo(
                new PhoneNumberLabel(num.label),
                new PhoneNumber(num.phoneNumber),
              );
            })),
            emailInfoList: new EmailInfoList(v.emailAddresses.map(email => {
              return new EmailInfo(
                new EmailLabel(email.label),
                new Email(email.emailAddress),
              )
            })),
            note: new Note(v.note),
          }))
        }))
      );
  }

  listRelatedClient(id: ContactID): Observable<ClientRelatedWithContactList> {
    return this._serverApi.contactApi
      .listRelatedClient(id.value)
      .pipe(
        map(v => v.map(v => (
          {
            contactID: new ContactID(v.contactID),
            clientID: new ClientID(v.clientID),
            isMain: v.isMain,
            clientNumber: new ClientNumber(v.clientNumber),
            clientName: new ClientName(v.clientName),
            clientCategory: v.clientCategory === 'CORPORATION'
              ? ClientCategory.Corporation
              : ClientCategory.Individual,
            clientTypeName: new ClientTypeName(v.clientTypeName)
          }
        )))
      );
  }
}
