import {Injectable} from '@angular/core';
import {DeclineReasonCommandService, DeclineReasonInputs} from "#application/services/decline-reason-command.service";
import {Observable, throwError} from "rxjs";
import {DeclineReasonID} from "app/model/decline-reason/decline-reason";
import {ServerApi} from "#infrastructure/api/server-api";
import {catchError, concatMap, filter, tap} from "rxjs/operators";
import {SnackBarService} from "#application/services/snack-bar.service";
import {MessageDialogService} from "#application/services/message-dialog.service";
import {ConfirmService} from "#application/services/confirm.service";
import {messageFn, Target} from "#utils/messages";

@Injectable({
  providedIn: 'root'
})
export class DeclineReasonCommandServiceImpl extends DeclineReasonCommandService {

  constructor(
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
    private readonly messageDialogService: MessageDialogService,
    private readonly confirmService: ConfirmService,
  ) {
    super();
  }

  add(inputs: DeclineReasonInputs): Observable<void> {
    return this.serverApi.declineReasonApi
      .create({
        name: inputs.name.value,
        description: inputs.description.value,
      })
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(Target.DECLINE_REASON, 'CREATE_SUCCESS').message);
        }),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '却下理由の登録'
          });
          return throwError(err);
        })
      );
  }

  update(id: DeclineReasonID, inputs: DeclineReasonInputs): Observable<void> {
    return this.serverApi.declineReasonApi
      .update(
        id.value,
        {
          name: inputs.name.value,
          description: inputs.description.value
        }
      )
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(Target.DECLINE_REASON, 'UPDATE_SUCCESS').message);
        }),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '却下理由の更新'
          });
          return throwError(err);
        })
      );
  }

  delete(id: DeclineReasonID): Observable<void> {
    return this.confirmService.confirm({
      title: messageFn(Target.DECLINE_REASON, 'DELETE_CONFIRM').title,
      message: messageFn(Target.DECLINE_REASON, 'DELETE_CONFIRM').message
    })
      .pipe(
        filter(r => r.isOK()),
        concatMap(() => this.serverApi.declineReasonApi.delete(id.value)),
        tap(() => {
          this.snackBarService.show(messageFn(Target.DECLINE_REASON, 'DELETE_SUCCESS').message);
        }),
        catchError((err) => {
          this.messageDialogService.notice({
            title: messageFn(Target.DECLINE_REASON, 'DELETE_ERROR').title,
            message: messageFn(Target.DECLINE_REASON, 'DELETE_ERROR').message
          });
          return throwError(err);
        })
      );
  }

  sort(ids: DeclineReasonID[]): Observable<void> {
    return this.serverApi.declineReasonApi
      .sort({
        ids: ids.map(id => id.value)
      })
      .pipe(
        tap(() => {
          this.snackBarService.show(messageFn(Target.DECLINE_REASON, 'SORT_SUCCESS').message);
        }),
        catchError((err) => {
          this.messageDialogService.notice({
            errorTarget: '却下理由の並び替え'
          });
          return throwError(err);
        })
      );
  }


}
