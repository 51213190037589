import { MessageDialogService } from "#application/services/message-dialog.service";
import { ListUserGroupInputs, UserGroupQueryService } from "#application/services/user-group-query.service";
import { AllUserGroupList, AllUserGroupListItem, UserGroup, UserGroupID, UserGroupListItem, UserGroupListResult, UserGroupListTotalCount, UserGroupName, UserGroupUpdatedAt, UserGroupUserCount } from "app/model/user-group/user-group";
import { DisplayName, UserID } from "app/model/user/user";
import { ServerApi } from "#infrastructure/api/server-api";
import { GetAllItemUserGroupResponse, ListItemUserGroupResponse } from "#infrastructure/api/server-user-group-api";
import { fromUTCStrToDate } from "#utils/date";
import { Target } from "#utils/messages";
import { Injectable, inject } from "@angular/core";
import { GeneralFailure } from "app/lib/general-failure/general-failure";
import { Failure, Result, Success } from "app/lib/result/result";
import { Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class UserGroupQueryServiceImpl implements UserGroupQueryService {
  private readonly serverApi = inject(ServerApi);
  private readonly messageDialogService = inject(MessageDialogService);

  list(inputs: ListUserGroupInputs): Observable<UserGroupListResult> {
    return this.serverApi.userGroupApi.list(inputs)
    .pipe(
      map(r => ({
        totalCount: new UserGroupListTotalCount(r.totalCount),
        list: r.results.map(v => this._toListItem(v)),
      })),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: `${Target.USER_GROUP}一覧の取得`
        });
        return throwError(err);
      }),
    );
  }

  get(id: UserGroupID): Observable<UserGroup> {
    return this.serverApi.userGroupApi.get(id.value)
    .pipe(
      map(r => ({
        id: new UserGroupID(r.id),
        name: new UserGroupName(r.name),
        groups: r.groups.map(g => ({
          id: new UserGroupID(g.id),
          name: new UserGroupName(g.name),
        })),
        users: r.users.map(u => ({
          id: new UserID(u.id),
          name: new DisplayName(u.name),
        })),
      })),
      catchError((err) => {
        this.messageDialogService.notice({
          errorTarget: `${Target.USER_GROUP}の取得`
        });
        return throwError(err);
      }),
    );
  }

  getAll(): Observable<Result<AllUserGroupList, typeof GeneralFailure.Unexpected>> {
    return this.serverApi.userGroupApi.getAll()
    .pipe(
      map(r => Success(r.map(v => this._toGetAllItem(v)))),
      catchError((err) => {
        return of(Failure(GeneralFailure.Unexpected));
      }),
    );
  }

  private _toListItem(resItem: ListItemUserGroupResponse): UserGroupListItem {
    return {
      id: new UserGroupID(resItem.id),
      name: new UserGroupName(resItem.name),
      groups: resItem.groups.map(g => ({
        id: new UserGroupID(g.id),
        name: new UserGroupName(g.name),
      })),
      users: resItem.users.map(u => ({
        id: new UserID(u.id),
        name: new DisplayName(u.name),
      })),
      userCount: new UserGroupUserCount(resItem.userCount),
      creator: {
        id: new UserID(resItem.creator.id),
        name: new DisplayName(resItem.creator.name),
      },
      updatedAt: new UserGroupUpdatedAt(fromUTCStrToDate(resItem.updatedAt)),
    };
  }

  private _toGetAllItem(resItem: GetAllItemUserGroupResponse): AllUserGroupListItem {
    return {
      id: new UserGroupID(resItem.id),
      name: new UserGroupName(resItem.name),
      userCount: new UserGroupUserCount(resItem.userCount),
    };
  }
}
