import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

export type ApiClientCategory = 'CORPORATION' | 'INDIVIDUAL';

export type ListClientTypeResponse = ListClientTypeResponseItemData[];

export interface ListClientTypeResponseItemData {
  id: string;
  category: ApiClientCategory;
  name: string;
  description: string;
  formLayoutID?: string;
  formLayoutName?: string;
}

export type CreateClientTypeRequest = CreateClientTypeRequestData;

export interface CreateClientTypeRequestData {
  category: ApiClientCategory;
  name: string;
  description: string;
  formLayoutID?: string;
}

export type UpdateClientTypeRequest = UpdateClientTypeRequestData;

export interface UpdateClientTypeRequestData {
  name: string;
  description: string;
  formLayoutID?: string;
}

export type SortClientTypeOrderRequest = SortClientTypeOrderRequestData;

export interface SortClientTypeOrderRequestData {
  ids: string[];
}

export type GetClientTypeResponse = GetClientTypeResponseData;

interface GetClientTypeResponseData {
  id: string,
  name: string,
  description: string,
  formLayoutID?: string,
}

export class ClientTypeApi {
  constructor(
    private readonly http: HttpClient,
  ) {
  }

  list(): Observable<ListClientTypeResponse> {
    return this.http.get<ListClientTypeResponse>('/api/client-type-list');
  }

  create(request: CreateClientTypeRequest): Observable<void> {
    return this.http.post<void>('/api/client-type', request);
  }

  update(id: string, request: UpdateClientTypeRequest): Observable<void> {
    return this.http.put<void>(`/api/client-type/${id}`, request);
  }

  sortOrders(request: SortClientTypeOrderRequest): Observable<void> {
    return this.http.post<void>(`/api/client-type/sort-order`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/client-type/${id}`);
  }

  get(id: string): Observable<GetClientTypeResponse> {
    return this.http.get<GetClientTypeResponse>(`/api/client-types/${id}`)
  }
}
