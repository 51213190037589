import { WonReason, WonReasonID } from "app/model/opportunity/won-reason/won-reason";
import { Observable } from "rxjs";

export type WonReasonList = WonReason[];

export abstract class WonReasonQueryService {
  abstract list(): Observable<WonReasonList>;

  abstract get(id: WonReasonID): Observable<WonReason>;
}
