import { CoachingTiming } from 'app/model/coaching/coaching';
import { InsightID, InsightQuestion } from 'app/model/insight/insight';
import { OpportunityContextID, OpportunityContextName } from 'app/model/opportunity-context/opportunity-context';
import { FilterPredicateType } from 'app/model/search-filter/search-filter';
import { Observable } from 'rxjs';

export type SearchInsightListInputs = {
  perPage: number,
  page: number,
  filter: {
    question: {
      type: typeof FilterPredicateType.contains,
      value: string,
    },
    opportunityContextID: {
      type: typeof FilterPredicateType.anyMatch,
      values: string[],
    },
    incorporated: {
      type: typeof FilterPredicateType.anyMatch,
      values: ('TRUE' | 'FALSE')[],
    },
    coachingTiming: {
      type: typeof FilterPredicateType.anyMatch,
      values: CoachingTiming[],
    },
  },
};

export type InsightListForIncorporation = Readonly<{
  totalCount: number;
  results: readonly Readonly<{
    id: InsightID,
    coachingTiming: CoachingTiming,
    question: InsightQuestion,
    incorporated: boolean,
    triggeringOpportunityContexts: readonly Readonly<{
      id: OpportunityContextID,
      name: OpportunityContextName,
    }>[],
  }>[]
}>

export abstract class IncorporatedInsightQueryService {
  abstract findBy(inputs: SearchInsightListInputs): Observable<InsightListForIncorporation>;
}
