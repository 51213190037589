import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ServerApi} from "#infrastructure/api/server-api";
import { ClientRelatedWithContactRepository, RelatedIDs } from "app/model/contact/client-related-with-contact.repository";

@Injectable({providedIn: "root"})
export class ClientRelatedWithContactRepositoryImpl extends ClientRelatedWithContactRepository {
  constructor(
    public readonly serverApi: ServerApi,
  ) {
    super();
  }

  delete(relation: RelatedIDs): Observable<void> {
    return this.serverApi.contactApi.deleteRelatedClient(
      relation.contactID.value,
      relation.clientID.value
    )
  }

  saveAsMain(relation: RelatedIDs): Observable<void> {
    return this.serverApi.contactApi.updateMainRelatedClient(
      relation.contactID.value,
      relation.clientID.value
    )
  }
}
