import { CustomFieldDescription, CustomFieldId, CustomFieldName, NumberSettings, SelectSettings } from 'app/model/custom-field/custom-field';
import { FieldType } from 'app/model/custom-field/field-type';
import {
  BuiltinFieldId,
  FormLayoutCategory,
  FormLayoutId,
  FormLayoutItemType,
  FormLayoutWidth,
  SectionTitle,
} from 'app/model/form-layout/form-layout';
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const FORM_LAYOUT_CATEGORY = new InjectionToken<FormLayoutCategory>('FormLayoutCategory');

export type FormItemsList = FormItem[];

export type FormItem =
  | SectionItem
  | BuiltinFormItem
  | CustomFormItem;

export interface SectionItem {
  layoutItemType: FormLayoutItemType,
  width: FormLayoutWidth,
  title: SectionTitle
}

export interface BuiltinFormItem {
  layoutItemType: FormLayoutItemType,
  builtinFieldID: BuiltinFieldId,
  width: FormLayoutWidth,
  fieldType: FieldType,
}

export interface CustomFormItem {
  layoutItemType: FormLayoutItemType,
  customFieldID: CustomFieldId,
  width: FormLayoutWidth,
  name: CustomFieldName,
  description: CustomFieldDescription,
  fieldType: FieldType,
  required: boolean,
  numberSetting?: NumberSettings,
  selectSetting?: SelectSettings
}

export namespace FormItemsMatcher {
  export const isSection = (item: FormItem): item is SectionItem => {
    return item.layoutItemType === FormLayoutItemType.SECTION_TITLE;
  };

  export const isBuiltin = (item: FormItem): item is BuiltinFormItem => {
    return item.layoutItemType === FormLayoutItemType.BUILTIN_FIELD;
  };

  export const isCustom = (item: FormItem): item is CustomFormItem => {
    return item.layoutItemType === FormLayoutItemType.CUSTOM_FIELD;
  };
}

export abstract class FormItemsQueryService {
  /**
   * フォームレイアウトを取得する
   *
   * @param category フォームレイアウトのカテゴリ
   * @param layoutID フォームレイアウトID。未指定の場合、デフォルトの項目を返却する
   */
  abstract getFormItems(category: FormLayoutCategory, layoutID: FormLayoutId | undefined): Observable<FormItemsList>;
}
