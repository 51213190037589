import { DisplayName, UserID } from "app/model/user/user";

export type Role = Readonly<{
  id: RoleID,
  name: RoleName,
  parentRole?: {
    id: RoleID,
    name: RoleName,
  },
  ownedClientsCount: number,
  childRoles: ReadonlyArray<{
    id: RoleID,
    name: RoleName,
  }>,
  members: ReadonlyArray<{
    id: UserID,
    name: DisplayName,
  }>,
}>;

export class RoleID {
  constructor(public readonly value: string) {}
}

export class RoleName {
  static readonly MAX_LENGTH = 30;
  constructor(public readonly value: string) {}
}

export class RoleDepth {
  constructor(public readonly value: number) {}
}

export class RoleUpdatedAt {
  constructor(public readonly value: Date) {}
}
