import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

export interface ListFollowUpActionTypeData {
  id: string,
  name: string,
  description: string,
}

export type ListFollowUpActionTypeResponse = ListFollowUpActionTypeData[];

export interface SaveAllFollowUpActionTypeData {
  id?: string;
  name: string;
  description: string;
}

export type SaveAllFollowUpActionTypeRequest = SaveAllFollowUpActionTypeData[];

interface CreateFollowUpActionTypeRequestData {
  name: string,
  description: string,
}

export type CreateFollowUpActionTypeRequest = CreateFollowUpActionTypeRequestData;

interface GetFollowUpActionTypeData {
  id: string,
  name: string,
  description: string,
}

export type GetFollowUpActionTypeResponse = GetFollowUpActionTypeData;

interface UpdateFollowUpActionTypeRequestData {
  name: string,
  description: string,
}

export type UpdateFollowUpActionTypeRequest = UpdateFollowUpActionTypeRequestData;

interface SortFollowUpActionTypeRequestData {
  ids: string[]
}

export type SortFollowUpActionTypeRequest = SortFollowUpActionTypeRequestData;

export class FollowUpActionTypeApi {
  constructor(
    private readonly http: HttpClient
  ) {
  }

  list(): Observable<ListFollowUpActionTypeResponse> {
    return this.http.get<ListFollowUpActionTypeResponse>('/api/follow-up-action-types');
  }

  saveAll(request: SaveAllFollowUpActionTypeRequest): Observable<void> {
    return this.http.put<void>('/api/follow-up-action-types', request);
  }

  create(request: CreateFollowUpActionTypeRequest): Observable<void> {
    return this.http.post<void>(`/api/follow-up-action-types`, request);
  }

  get(id: string): Observable<GetFollowUpActionTypeResponse> {
    return this.http.get<GetFollowUpActionTypeResponse>(`/api/follow-up-action-types/${id}`);
  }

  update(id: string, request: UpdateFollowUpActionTypeRequest): Observable<void> {
    return this.http.put<void>(`/api/follow-up-action-types/${id}`, request);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`/api/follow-up-action-types/${id}`);
  }

  sort(request: SortFollowUpActionTypeRequest): Observable<void> {
    return this.http.patch<void>(`/api/follow-up-action-types`, request);
  }
}
