import { MetaUserAuthorizeService } from '#application/services/meta-user-authorize.service';
import { SnackBarService } from '#application/services/snack-bar.service';
import { ClientApi } from '#infrastructure/api/client-api';
import { ServerApiError, ServerApiErrorCode } from '#infrastructure/api/error';
import { ServerApi } from '#infrastructure/api/server-api';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {AUTH_INFO_KEY_NAME} from "../../model/storage/authorize-info";

@Injectable({providedIn: 'root'})
export class MetaUserAuthorizeServiceImpl implements MetaUserAuthorizeService {
  constructor(
    private clientApi: ClientApi,
    private serverApi: ServerApi,
    private snackBarService: SnackBarService,
  ) {}

  login(userID: string, password: string): Observable<boolean> {
    return this.serverApi.metaUserAuthApi.signIn({
      userID,
      password,
    }).pipe(
      map(response => {
        this.clientApi.storeLocalStorage(AUTH_INFO_KEY_NAME, {
          id: response.userID,
          loginID: response.userName,
          displayName: response.name,
          mailAddress: response.mailAddress,
          securityRoles: response.securityRoles,
        });
        this.snackBarService.show('ログイン認証に成功しました。');
        return true;
      }),
      catchError((error: unknown) => {
        if ((error instanceof ServerApiError) && (error.code === ServerApiErrorCode.Unauthorized)) {
          this.snackBarService.show('ログイン認証に失敗しました。');
          return of(false);
        }
        throw error;
      })
    );
  }
}
