import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CsvExportRequestCategory } from './server-csv-export';

export type CsvExportRequest = CsvExportRequestData;

interface CsvExportRequestData {
  typeId: string;
  charset: string;
}

export type SignedUrlResponse = SignedUrlResponseData;

interface SignedUrlResponseData {
  preSignedUrl: string;
}

export class CsvExportApi {
  constructor(private readonly httpClient: HttpClient) { }

  requestExportCsv(
    category: CsvExportRequestCategory,
    request: CsvExportRequestData,
  ): Observable<void> {
    return this.httpClient.post<void>(
      `/api/csv-exports/${category}`,
      request
    );
  }

  getPreSignedUrl(
    category: CsvExportRequestCategory,
    requestId: string,
  ): Observable<SignedUrlResponse> {
    return this.httpClient.get<SignedUrlResponse>(
      `/api/csv-exports/${category}/${requestId}`,
    )
  }
}
