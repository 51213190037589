import {OnetimeTokenData, SingleSignOnService} from "#application/services/single-sign-on.service";
import {SnackBarService} from "#application/services/snack-bar.service";
import {OrganizationID} from "app/model/organization/organization";
import {ClientApi} from "#infrastructure/api/client-api";
import {ServerApi} from "#infrastructure/api/server-api";
import {HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, of, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {AUTH_INFO_KEY_NAME} from "../../model/storage/authorize-info";

@Injectable({
  providedIn: 'root',
})
export class SingleSignOnServiceImpl extends SingleSignOnService {
  constructor(
    private readonly serverApi: ServerApi,
    private readonly snackBarService: SnackBarService,
    private readonly clientApi: ClientApi,
    ) {
    super();
  }

  issueOnetimeToken(id: OrganizationID): Observable<OnetimeTokenData> {
    return this.serverApi.onetimeTokenApi.issue({ organizationID: id.value })
    .pipe(
      map(data => convertHeadersToOnetimeTokenData(data.headers)),
      catchError(err => {
        this.snackBarService.show('担当組織へのサインインに失敗しました。');
        return throwError(err);
      })
    );
  }

  signOn(data: OnetimeTokenData): Observable<boolean> {
    return this.serverApi.singleSignOnApi.signOn(data)
    .pipe(
      map(res => {
        this.clientApi.storeLocalStorage(AUTH_INFO_KEY_NAME,
          {
            id: res.userID,
            organizationName: res.organizationName,
            loginID: res.userName,
            displayName: res.displayName,
            mailAddress: res.mailAddress,
            fiscalYearBegin: res.fiscalYearBegin,
            securityRoles: res.securityRoles,
            isCoachingAvailable: res.isCoachingAvailable,
            isProposalEnabled: res.isProposalEnabled,
            isLinkageEnabled: res.isLinkageEnabled,
            isOpportunityGroupEnabled: res.isOpportunityGroupEnabled,
            localize: res.localize,
          }
        );
        return true;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }
}

export const convertHeadersToOnetimeTokenData = (data: HttpHeaders): OnetimeTokenData => {
  const result: OnetimeTokenData = {
    'x-sn-user-id': '',
    'x-sn-sso-token': '',
  };
  data.keys().forEach(key => {
    const value = data.get(key);
    if (key === 'x-sn-user-id') {
      result[key] = value === null ? '' : value;
    } else if (key === 'x-sn-sso-token') {
      result['x-sn-sso-token'] = value === null ? '' : value;
    }
  });
  return result;
};
