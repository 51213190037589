import { ClientID } from "app/model/client/client";
import { ContactID } from "app/model/contact/contact";
import { Result } from "app/lib/result/result";
import { Observable } from "rxjs";

export const AddContactRelatedClientFailure = {
  ClientDuplicated: 'ClientDuplicated',
  ClientNotFound: 'ClientNotFound',
} as const

export abstract class ContactRelatedClientCommandService {
  abstract add(contactID: ContactID, clientID: ClientID): Observable<Result<
    void,
    | typeof AddContactRelatedClientFailure.ClientDuplicated
    | typeof AddContactRelatedClientFailure.ClientNotFound
  >>
}