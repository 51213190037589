import {Action, ActionHistory, ActionID} from 'app/model/action/action';
import {OpportunityID, OpportunityItem} from 'app/model/opportunity/opportunity';
import {Observable} from 'rxjs';

export abstract class ActionRepository {
  abstract findByID(actionID: ActionID): Observable<Action>;

  abstract findByOpportunityID(opportunityID: OpportunityID): Observable<ActionHistory>;

  abstract save(action: Action, items: OpportunityItem[]): Observable<void>;

  abstract report(action: Action, items: OpportunityItem[], shouldUpdatePhase: boolean): Observable<void>;

  abstract delete(action: Action): Observable<void>;

  abstract cancelReport(actionID: ActionID): Observable<void>;
}
