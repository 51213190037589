import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

// 登録用のタイプエイリアス
export type CreateActionRequest = CreateActionData;

interface CreateActionData {
  opportunityID: string;
  companions?: {userID: string}[]
  attendees?: {contactID: string}[]
  currentPhaseID: string;
  startPhaseID: string;
  targetPhaseID: string;
  actionTypeID: string;
  actionStartDateTime: string;
  actionEndDateTime: string;
  actionNote: string;
  items?: {
    [customFieldId: string]: string
  }
}

export type CreateActionResponse = {
  actionID: string,
}

export type GetActionResponse = GetActionResponseData;

interface GetActionResponseData {
  actionID: string;
  userID: string;           // 作成者
  userDisplayName: string;  // 作成者
  opportunityID: string;
  companions?: {
    userID: string,
    userDisplayName: string,
  }[]
  attendees?: {
    contactID: string,
    contactDisplayName: string,
    departmentName: string,
    roleName: string,
  }[]
  currentPhaseID: string;
  startPhaseID: string;
  targetPhaseID: string;
  actionTypeID: string;
  actionTypeName: string;
  actionStartDateTime: string;
  actionEndDateTime: string;
  isDone: boolean;
  actualStartPhaseID?: string;
  actualPhaseID?: string;
  actionNote: string;
}

export type UpdateActionRequest = UpdateActionRequestData;

interface UpdateActionRequestData {
  companions?: {userID: string}[]
  attendees?: {contactID: string}[]
  startPhaseID: string;
  targetPhaseID: string;
  actionTypeID: string;
  actionStartDateTime: string;
  actionEndDateTime: string;
  actionNote: string;
  items?: {
    [customFieldId: string]: string
  }
}

export type ReportActionRequest = ReportActionRequestData;

interface ReportActionRequestData {
  companions?: {userID: string}[]
  attendees?: {contactID: string}[]
  actualStartPhaseID: string;
  actualPhaseID: string;
  actionTypeID: string;
  actionStartDateTime: string;
  actionEndDateTime: string;
  actionNote: string;
  items?: {
    [customFieldId: string]: string
  }
}

export class ActionApi {
  constructor(private readonly http: HttpClient) {
  }

  createAction(request: CreateActionRequest): Observable<CreateActionResponse> {
    return this.http.post<CreateActionResponse>('/api/actions', request);
  }

  get(actionID: string): Observable<GetActionResponse> {
    return this.http.get<GetActionResponse>(`/api/actions/${actionID}`);
  }

  update(actionID: string, request: UpdateActionRequest): Observable<void> {
    return this.http.put<void>(`/api/actions/${actionID}`, request);
  }

  report(actionID: string, request: ReportActionRequest, shouldUpdatePhase: boolean): Observable<void> {
    return this.http.put<void>(`/api/actions/${actionID}/report?updatePhase=${shouldUpdatePhase}`, request);
  }

  delete(actionID: string): Observable<void> {
    return this.http.delete<void>(`/api/actions/${actionID}`);
  }

  cancelReport(actionID: string): Observable<void> {
    return this.http.post<void>(`/api/actions/${actionID}/report-cancel`, undefined);
  }
}
