import { FileID, FileName, FileProfile, FileUpdatedAt, ParentID } from "app/model/file/file";
import { DisplayName } from "app/model/user/user";
import { Observable } from "rxjs";

export type DownloadFile = Blob;

export type ListFileInputs = {
  parentID: ParentID,
  perPage: number,
  page: number,
}

export type FileListByParent = {
  totalCount: number,
  results: {
    fileID: FileID,
    fileName: FileName,
    updatedAt: FileUpdatedAt,
    displayUserName: DisplayName,
  }[]
}

export abstract class FileQueryService {
  abstract getProfile(id: FileID): Observable<FileProfile>;
  abstract download(id: FileID): Observable<DownloadFile>;
  abstract list(inputs: ListFileInputs): Observable<FileListByParent>;
}
